import { SVGProps } from "react";

export const PhoneIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M26.5794 18.9391L20.9483 16.4158L20.9327 16.4086C20.6404 16.2836 20.3215 16.2334 20.0049 16.2626C19.6883 16.2919 19.384 16.3996 19.1194 16.576C19.0883 16.5966 19.0584 16.6189 19.0298 16.6429L16.1204 19.1232C14.2772 18.2279 12.3743 16.3393 11.479 14.52L13.9629 11.5664C13.9868 11.5365 14.0095 11.5067 14.031 11.4744C14.2036 11.2106 14.3084 10.9082 14.3359 10.5941C14.3634 10.2801 14.3129 9.96406 14.1888 9.67425V9.65991L11.6583 4.01923C11.4942 3.64063 11.2121 3.32525 10.8541 3.12016C10.496 2.91507 10.0813 2.83128 9.6717 2.88129C8.05201 3.09442 6.5653 3.88986 5.48922 5.11903C4.41314 6.3482 3.8213 7.92705 3.82423 9.5607C3.82423 19.0515 11.5459 26.7732 21.0367 26.7732C22.6704 26.7761 24.2492 26.1843 25.4784 25.1082C26.7076 24.0321 27.503 22.5454 27.7161 20.9257C27.7662 20.5163 27.6826 20.1016 27.4778 19.7436C27.2729 19.3856 26.9578 19.1034 26.5794 18.9391ZM21.0367 24.8607C16.9803 24.8563 13.0912 23.2429 10.2229 20.3745C7.35454 17.5062 5.74116 13.6171 5.73673 9.5607C5.73223 8.39346 6.15276 7.26449 6.91977 6.38464C7.68679 5.50478 8.74784 4.9342 9.90478 4.77945C9.90431 4.78422 9.90431 4.78902 9.90478 4.79379L12.4149 10.4118L9.94423 13.369C9.91915 13.3978 9.89637 13.4286 9.8761 13.461C9.69622 13.737 9.5907 14.0548 9.56976 14.3836C9.54881 14.7124 9.61316 15.041 9.75657 15.3376C10.8395 17.5526 13.0712 19.7675 15.31 20.8492C15.6088 20.9913 15.9393 21.0534 16.2693 21.0295C16.5993 21.0056 16.9174 20.8966 17.1926 20.713C17.2233 20.6923 17.2528 20.6699 17.2811 20.646L20.1869 18.1669L25.8048 20.6831H25.818C25.6651 21.8417 25.0954 22.9048 24.2154 23.6738C23.3354 24.4427 22.2053 24.8646 21.0367 24.8607Z"
      fill="#313131"
    />
  </svg>
);
