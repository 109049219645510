import PaymentForm from "modules/dealer/features/shipping-quotes/PaymentForm";
import { PaymentFormNewShipping } from "../payment-form";
import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import {
  useAcceptPriceRateShipping,
  useAddPaymenTransactiontMethod,
  useConfirmShippingPayment,
  useGetCreditCardMethodStatusData,
  useSaveShippingQuotesInfo,
  useTransactionsCheckForPaymentMethod,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { IShippingPaymentInfoNew } from "./new-order.form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  AddCardMethodPayload,
  PaymentCardInfo,
  ShippingByIDResponse,
} from "services/endpoints/shipping-quotes/shipping.type";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import { isThisDealer } from "utils/get-user-id";

interface Props {
  from?: string;
  vehicleName?: string;
  payModalStep: number | null;
  setPayModalStep: (num: number | null) => void;
  shippingPaymentInfo: IShippingPaymentInfoNew | null;
  forHistoryOrder?: boolean;
  orderHistoryShippindData?: ShippingByIDResponse | null;
  vin?:string
}

export const ShippingPaymentComponent = ({
  from,
  vehicleName,
  payModalStep,
  setPayModalStep,
  shippingPaymentInfo,
  orderHistoryShippindData,
  forHistoryOrder = false,
  vin
}: Props) => {
  const shippingData: any | null = forHistoryOrder
    ? {
        form: {
          shippingZip: orderHistoryShippindData?.shippingZip || "N/A",
          shippingStreetAddress:
            orderHistoryShippindData?.shippingStreetAddress || "N/A",
          destinationZip: orderHistoryShippindData?.destinationZip || "N/A",
          destinationStreetAddress:
            orderHistoryShippindData?.destinationStreetAddress || "N/A",
        },
        pay: [
          {
            referenceNumbers: [
              {
                number: orderHistoryShippindData?.refNumber || "N/A",
                type: {
                  value: 12,
                  label: "VIN",
                  shortLabel: "VIN",
                },
              },
            ],
            price: {
              ...(orderHistoryShippindData?.price || {}),
              label: "$" + (orderHistoryShippindData?.price?.value || "0"),
            },
            calculatedPrices: {
              ushipServiceCharge: {
                ...(orderHistoryShippindData?.calculatedPrices
                  ?.ushipServiceCharge || {}),
                label:
                  "$" +
                  (orderHistoryShippindData?.calculatedPrices
                    ?.ushipServiceCharge?.value || "0"),
              },
            },
            quoteId: orderHistoryShippindData?.quoteId || "",
          },
        ],
      }
    : shippingPaymentInfo;

  // const [paymentSaved, setPaymentSaved] = useState<PaymentCardInfo | null>(
  //   null
  // );
  const navigate = useNavigate();

  const {
    mutateAsync: mutateAsyncSaveShippingQuotes,
    isPending: isPendingSaveShippingQuetues,
  } = useSaveShippingQuotesInfo();

  // const {
  //   mutateAsync: mutateAsyncAccceptPayment,
  //   isPending: isPendingToAccceptPayment,
  // } = useTransactionsCheckForPaymentMethod();
  
  const {  data: checkIfPaymentExist, refetch: mutateAsyncAccceptPayment ,isLoading: loadingPaymentCardStatus } =
  useGetCreditCardMethodStatusData(true);

  const {
    mutateAsync: mutateAsyncAcceptPriceRateShipping,
    isPending: isPendingToAcceptPriceRateShipping,
    error: acceptShipRateError
  } = useAcceptPriceRateShipping();
  const {
    mutateAsync: mutateConfirmShippingPayment,
    isPending: isPendingToConfirmShippingPayment,
    error: confirrmShipPaymentError
  } = useConfirmShippingPayment();

  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: addPaymentCardError
  } = useAddPaymenTransactiontMethod();

  const handleSaveQuotes = async () => {
    try {
      const res = await mutateAsyncSaveShippingQuotes(
        shippingPaymentInfo?.pay?.QuoteID || ""
      );
      console.log("saved Data", res);
      navigate(
        `${isThisDealer() ? "" : "/user"}/shipping-quotes/order-history`
      );
      setPayModalStep(null);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handleOnProceedPayment = async () => {
    try {
      await mutateAsyncAccceptPayment();
      // setPaymentSaved(isPaymentExistRes);
      setPayModalStep(2);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handleConfirmPayment = async (data: AddCardMethodPayload) => {
    try {
      if (checkIfPaymentExist) {
        const confirmShippingPayment = await mutateConfirmShippingPayment(
          shippingPaymentInfo?.pay?.QuoteID || ""
        );
        const res = await mutateAsyncAcceptPriceRateShipping(
          shippingPaymentInfo?.pay?.QuoteID || ""
        );
        console.log("confirmShippingPayment", confirmShippingPayment);
        if (res !== "Invalid quote id" && confirmShippingPayment >= 0) {
          setPayModalStep(3);
        }
      } else {
        const paymentData = await mutateAddPaymenTransactiontMethod(data);
        const confirmShippingPayment = await mutateConfirmShippingPayment(
          shippingPaymentInfo?.pay?.QuoteID || ""
        );

        const res = await mutateAsyncAcceptPriceRateShipping(
          shippingPaymentInfo?.pay?.QuoteID || ""
        );
        mutateAsyncAccceptPayment()
        console.log("paymentData", paymentData);
        if (res !== "Invalid quote id" && confirmShippingPayment >= 0) {
          setPayModalStep(3);
        }
      }
    } catch (e) {
      console.log("Error :", e);
    }
  };
  return (
    <div>
      {payModalStep === 1 ? (
        <PaymentFormNewShipping
          from={from}
          vehicleName={vehicleName ?? ""}
          handleProceed={handleOnProceedPayment}
          vin={forHistoryOrder?  (orderHistoryShippindData?.refNumber||""): (vin||"")}
          payData={shippingData}
          showSaveQuotes={!forHistoryOrder}
          isPendingToProceed={loadingPaymentCardStatus}
          handleSaveQuotes={handleSaveQuotes}
          isSaveQuotesPending={isPendingSaveShippingQuetues}
        />
      ) : payModalStep === 2 ? (
        <PaymentFormWithDetails
          showmodal={true}
          handleNext={handleConfirmPayment}
          isPending={
            isPendingToConfirmShippingPayment ||
            isPendingToAddPaymenTransactiontMethod ||
            isPendingToAcceptPriceRateShipping
          }
          payAmmount={(shippingPaymentInfo?.pay?.TotalPrice || "0") as string}
          savedMethod={checkIfPaymentExist}
          errorMessage={confirrmShipPaymentError?.message || addPaymentCardError?.message || acceptShipRateError?.message}
        />
      ) : (
        <PaymentSuccess
          navigatePath={`${
            isThisDealer() ? "" : "/user"
          }/shipping-quotes/transit-status/${shippingPaymentInfo?.pay?.QuoteID}`}
        />
      )}
    </div>
  );
};
