import { SVGProps } from "react";

export const ChatQouteIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5228 4.375C31.9096 4.375 32.2805 4.52865 32.554 4.80214C32.8275 5.07563 32.9811 5.44656 32.9811 5.83333V26.25C32.9811 26.6368 32.8275 27.0077 32.554 27.2812C32.2805 27.5547 31.9096 27.7083 31.5228 27.7083H10.3113L3.81445 32.8125V5.83333C3.81445 5.44656 3.9681 5.07563 4.24159 4.80214C4.51508 4.52865 4.88601 4.375 5.27279 4.375H31.5228ZM30.0645 7.29167H6.73112V26.8115L9.30216 24.7917H30.0645V7.29167ZM16.2322 10.8092L16.884 11.8125C14.4515 13.1294 14.4938 15.2425 14.4938 15.699C14.7199 15.6669 14.9576 15.6625 15.1938 15.6844C15.833 15.7379 16.4289 16.0289 16.8643 16.4999C17.2996 16.9709 17.5429 17.5878 17.5461 18.2292C17.5461 18.906 17.2772 19.5552 16.7986 20.0338C16.32 20.5124 15.6709 20.7812 14.994 20.7812C14.2109 20.7812 13.4628 20.424 12.9903 19.9208C12.2392 19.1246 11.8353 18.2292 11.8353 16.7781C11.8353 14.226 13.6261 11.9394 16.2322 10.8092ZM23.5238 10.8092L24.1757 11.8125C21.7432 13.1294 21.7855 15.2425 21.7855 15.699C22.0115 15.6669 22.2492 15.6625 22.4855 15.6844C23.1246 15.7379 23.7206 16.0289 24.1559 16.4999C24.5913 16.9709 24.8346 17.5878 24.8378 18.2292C24.8378 18.906 24.5689 19.5552 24.0903 20.0338C23.6117 20.5124 22.9626 20.7812 22.2857 20.7812C21.5026 20.7812 20.7545 20.424 20.282 19.9208C19.5309 19.1246 19.127 18.2292 19.127 16.7781C19.127 14.226 20.9178 11.9394 23.5238 10.8092Z"
      fill="white"
    />
  </svg>
);
