import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "assets/svgs/logo";
import { MdOutlineCopyright } from "react-icons/md";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { UserAvatarIcon } from "assets/svgs/icons/user-avatar-icon";
import { EyeIcon } from "assets/svgs/icons/eye-icon";
import { EyeSlashedIcon } from "assets/svgs/icons/eye-slash-icon";
import ForgotPass from "modules/dealer/features/forgot-password/ForgotPass";
import { Button } from "@nextui-org/react";
import { useLoginUserMutation } from "hooks/api/mutations/use-auth-mutation";
import { UserLoginSchema } from "schemas/auth.schema";
import { z } from "zod";
import { LockIcon } from "assets/svgs/icons/lock-icon";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { useForgetPassword } from "hooks/redirect/use-forget-pwd";

export default function UserLogin() {
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();

  const [showForgot, setShowForgot] = useState(false);
  
  const { handleAddParam, handleRemoveParam } = useForgetPassword(
    "reset-password",
    () => setShowForgot(true)
  );
  const navigateToForgot = () => {
    setShowForgot(true);
    handleAddParam("true");
  };
  const {
    mutate: loginMutation,
    isPending,
    isError,
    error,
  } = useLoginUserMutation();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleLogin = async (data: z.infer<typeof UserLoginSchema>) => {
    await loginMutation(data);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-3 px-4 py-2 md:gap-0 md:py-5 md:px-8 md:flex-row">
        <div
          className="flex w-full flex-col gap-8 shadow-xl md:shadow-none md:gap-2 px-4 bg-cover rounded-2xl text-white justify-between md:rounded-e-none md:rounded-s-3xl items-center text-center [text-shadow:_0_4px_4px_rgb(0_0_0_/_25%)] md:w-[440px] md:h-[500px] py-14"
          style={{ backgroundImage: "url('/assets/images/panel_left.png')" }}
        >
          <div
            className="pb-8 cursor-pointer"
            onClick={() => navigate("/listing/landing-page")}
          >
            <Logo className="w-40 h-16" />
          </div>
          <div className="items-center h-full w-full flex flex-col gap-4">
            <div className="text-3xl lg:text-4xl font-thin">Welcome Aboard!!</div>
            <div className="text-xs max-w-80">
              The future of automotive inventory and online listing management
              is here, and it&apos;s all in one place.
              <div className="text-xs">Start a free account with us today!</div>
            </div>
            <div className="text-xs">
              Create an account to add vehicles to your garage!
            </div>
            <div>
              <div className="text-xs text-start">
                Things you can do with a free account:
              </div>
              <div className="text-xs text-left self-start space-y-3 mt-2">
                <li>Track Your Cars Value</li>
                <li>Create Free Listings</li>
                <li>Get Quotes for Shipping and DMV Transfers</li>
                <li>24/7 Chat and Create Support Tickets</li>
              </div>
            </div>
            <div
              className="flex gap-1 text-xs cursor-pointer"
              onClick={() => navigate("/")}
            >
              findmyoptions.com <MdOutlineCopyright className="size-2" /> 2024
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-[440px] md:h-[500px] md:py-14 rounded-e-3xl  md:bg-[#f1f1f1]/50 md:border border-[#dbdbdb] justify-center items-center gap-2">
          <div className="py-4 text-xl text-center"> User Login</div>
          <div className="w-full px-4 md:px-12">
            <Form schema={UserLoginSchema} onSubmit={handleLogin}>
              {({ register, formState: { errors } }) => (
                <div className="flex flex-col gap-4">
                  <Input
                    isDisabled={isPending}
                    variant="form"
                    radius="xl"
                    size="lg"
                    placeholder="Username"
                    isInvalid={!!errors.username}
                    errorMessage={errors?.username?.message}
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16",
                      inputWrapper: !!errors.username
                        ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                        : "",
                    }}
                    startContent={
                      <span className="absolute left-3">
                        <UserAvatarIcon />
                      </span>
                    }
                    {...register("username")}
                  />
                  <Input
                    isDisabled={isPending}
                    variant="form"
                    radius="xl"
                    size="lg"
                    startContent={
                      <span className="absolute left-3">
                        <LockIcon />
                      </span>
                    }
                    placeholder="Password"
                    type={isVisible ? "text" : "password"}
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16",
                      inputWrapper: !!errors.password
                        ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                        : "",
                    }}
                    isInvalid={!!errors.password}
                    errorMessage={errors?.password?.message}
                    endContent={
                      <button
                        className="absolute right-3 focus:outline-none"
                        type="button"
                        onClick={toggleVisibility}
                      >
                        {isVisible ? (
                          <EyeIcon className="text-3xl pointer-events-none text-default-600" />
                        ) : (
                          <EyeSlashedIcon className="text-3xl pointer-events-none text-default-600" />
                        )}
                      </button>
                    }
                    {...register("password")}
                  />
                  {isError && (
                    <div className=" text-red-500 flex items-center text-sm">
                      <ErrorInfoIcon className="min-w-5 min-h-5 mr-1" />
                      {error.message}
                    </div>
                  )}
                  <div className="flex justify-end cursor-pointer">
                    <div
                      onClick={navigateToForgot}
                      className="pr-4 text-[#018ffd] text-sm"
                    >
                      Forgot password?
                    </div>
                  </div>
                  {showForgot && (
                    <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-black bg-opacity-50 z-[9999]">
                      <ForgotPass
                        setCurrScreen={() => {setShowForgot(false); handleRemoveParam()}}
                        handleScreenChange={() =>{ setShowForgot(false);handleRemoveParam()}}
                      />
                    </div>
                  )}
                  <div className="flex justify-center">
                    <Button
                      className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-6 tracking-wide px-6 rounded-3xl"
                      isLoading={isPending}
                      type="submit"
                    >
                      Login
                    </Button>
                  </div>

                  <div className="flex justify-center text-sm">
                    <div>Not registered yet?</div>
                    <Link to={"/register/user"} className="text-[#018ffd] pl-2">
                      {" "}
                      Create a free account
                    </Link>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
