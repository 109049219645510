import TierCards from "pages/public/dealer-services-pricing/TierCards";
import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tabs,
  Tab,
  Card,
  CardBody,
} from "@nextui-org/react";

interface Props {
  onCallBack?: (tier: TierProps) => void;
}

interface TierProps {
  id: string;
  name: string;
  type: string;
  note: string;
  price: number;
  features: string[];
}
export default function ChoosePlanSelection(props: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("md");

  return (
    <div>
      <div className="text-center text-xl mb-16">Choose a Plan</div>
      <TierCards onCallBack={props.onCallBack} />
      <div
        className="text-center text-xl mb-5 cursor-pointer hover:scale-105 mt-10"
        onClick={onOpen}
      >
        View All Plan Details
      </div>
      <Modal size={"5xl"} isOpen={isOpen} onClose={onClose} className="p-5">
        <ModalContent>
          {(onClose) => (
            <>
              <div className="text-center">
                <p>findmyoptions.com LLC Dealer Software -</p>
                <div className="text-4xl font-semibold">
                  Tier 1: The Basics Agreement
                </div>
              </div>
              <ModalBody>
                <Tabs aria-label="Options" variant="underlined">
                  <Tab
                    key="tier1"
                    title={<div className="text-lg">Tier 1</div>}
                  >
                    <Card
                      className="h-[600px] overflow-scroll -mt-5"
                      shadow="none"
                    >
                      <CardBody>
                        This agreement ("Agreement") governs your use of
                        findmyoptions.com LLC's Tier 1: The Basics ("Tier 1")
                        dealer software ("Software"). By accessing or using the
                        Software, you agree to be bound by the terms and
                        conditions of this Agreement. 1. Grant of License.
                        findmyoptions.com LLC grants you a non-exclusive,
                        non-transferable, revocable license to access and use
                        the Tier 1 Software for internal business purposes at
                        your dealership. 2. Tier 1 Features. Under Tier 1, you
                        are entitled to the following features: Cloud-based
                        inventory management: Manage your inventory data and
                        track stock levels, vehicle details, and sales
                        performance in real-time. Built-in profit and loss
                        calculators: Gain insights into your dealership's
                        financial performance and optimize your pricing and
                        sales strategies. Proprietary listing creation tool:
                        Create compelling vehicle listings with high-quality
                        images, detailed descriptions, and prominent calls to
                        action. Additional features: This Agreement is not
                        exhaustive and may be updated by findmyoptions.com LLC
                        with additional features or functionalities under Tier 1
                        at its sole discretion. 3. Optional In-App Purchases:
                        Tier 1 allows you to purchase additional features and
                        services within the Software, including but not limited
                        to:  Vehicle history reports: Gain instant access to
                        comprehensive vehicle history reports powered by
                        VinAudit.com, a trusted provider of vehicle history
                        data. Build transparency and trust with potential buyers
                        by presenting detailed information on a vehicle's past,
                        including accident history, title status, odometer
                        readings, and more. Please note that we are not
                        responsible for the accuracy or completeness of the
                        information contained in these reports. It is your
                        responsibility to review the reports carefully and
                        verify all information with the vehicle owner and other
                        reliable sources.  Seamless Vehicle Shipping: Get
                        competitive quotes for vehicle transport across the
                        nation, right within our platform. No need for separate
                        searches or calls. Pay and track your shipments directly
                        on our site for complete transparency and peace of mind.
                        It's your responsibility to independently research and
                        select the best shipping options for your needs. 
                        Streamline DMV & Titling: Cut staffing costs and ditch
                        the hassle with our fully online platform. Handle DMV
                        and Titling electronically, saving you time, money, and
                        paperwork. Enhanced Listing Options: Drive buyer
                        engagement and stand out from the competition with
                        comprehensive vehicle information and captivating
                        visuals. Our system seamlessly integrates data from:
                        Proprietary Databases: Access authoritative vehicle
                        details like specifications, features, and valuation
                        estimates sourced from reliable proprietary databases.
                         AI-Driven Insights: Enhance your listings with
                        AI-powered insights, including market trends, pricing
                        suggestions, and buyer preferences, helping you optimize
                        your presentation and capture more attention.  Important
                        Disclaimer: While we strive to provide comprehensive and
                        reliable information through our platform, including
                        data from government and proprietary databases,
                        AI-powered insights, and market trends, we cannot
                        guarantee absolute accuracy or completeness. Some
                        information, especially those generated by AI, may not
                        be perfect. Therefore, it's your responsibility to
                        independently verify all data and information, including
                        vehicle details, specifications, features, valuations,
                        pricing, and offers, before making any business
                        decisions or publishing listings. Remember, you are
                        ultimately responsible for the accuracy and reliability
                        of the information you present to potential buyers. We
                        encourage you to utilize external resources and
                        double-check all information before relying on it.
                        Please note that we cannot be held responsible for any
                        inaccuracies or errors displayed on our platform.  4.
                        Fees and Payment. For Tier 1 features, there is no
                        monthly subscription fee.  Any in-app purchases you
                        choose to make beyond the bundled packages will be
                        subject to associated fees displayed within the
                        Software.  5. Restrictions. You agree not to: Share your
                        login credentials with any third party. Modify, reverse
                        engineer, decompile, or disassemble the Software. Use
                        the Software for any illegal or unauthorized purpose.
                        Violate any applicable laws or regulations. Use the
                        listing export feature in a way that violates the terms
                        of service of any other platform. 6. Termination.
                        findmyoptions.com LLC may terminate this Agreement and
                        your access to the Software at any time for any reason
                        or no reason. 7. Disclaimer of Warranties. THE SOFTWARE
                        IS PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES OF ANY
                        KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, AND NON-INFRINGEMENT. FINDMYOPTIONS.COM MAKES
                        NO WARRANTY THAT THE SOFTWARE WILL BE UNINTERRUPTED,
                        ERROR-FREE, OR SECURE. 8. Limitation of Liability. IN NO
                        EVENT WILL FINDMYOPTIONS.COM BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR
                        EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                        YOUR USE OF THE SOFTWARE. 9. Governing Law. This
                        Agreement shall be governed by and construed in
                        accordance with the laws of the State of Kentucky,
                        without regard to its conflict of laws provisions.  10.
                        Entire Agreement. This Agreement constitutes the entire
                        agreement between you and findmyoptions.com LLC with
                        respect to your use of the Tier 1 Software.  11.
                        Updates. findmyoptions.com LLC reserves the right to
                        update this Agreement at any time by posting the amended
                        Agreement on its website.  12. Contact Us. If you have
                        any questions about this Agreement, please contact
                        findmyoptions.com LLC at support@findmyoptions.com  12.
                        Dealer Integrity: Our platform operates under a strict
                        zero-tolerance policy for dealer fraud and
                        misrepresentation. We encourage users to report any
                        suspected issues promptly. Upon receiving such reports,
                        we will conduct a thorough investigation and take
                        appropriate action, including but not limited to: -
                        Account suspension or termination. - Reporting to
                        relevant authorities. - Assisting users in seeking legal
                        recourse. Users remain responsible for exercising due
                        diligence and verifying information independently before
                        engaging in transactions. 13. Dispute Resolution: We
                        encourage amicable resolution of disputes between
                        dealers and buyers through direct communication before
                        seeking formal action.  Our platform may offer optional
                        mediation or arbitration services to facilitate
                        resolution. Participation is voluntary and not binding. 
                        This Agreement includes mandatory arbitration for
                        disputes arising from it or platform usage. Arbitration
                        will follow the rules and procedures set forth by the
                        state of Kentucky. The arbitrator's decision will be
                        final and binding on both parties.  14. Damage
                        Resolution: If a shipped vehicle exhibits undisclosed
                        damage upon arrival, the buyer must immediately notify
                        the dealer and document the issues with photos and
                        videos. Both parties are encouraged to collaborate on an
                        amicable solution. This could involve:  -
                        Dealer-arranged repairs.  - Digital due bills issued for
                        repairs.  - Vehicle return at buyer's expense for return
                        shipping, followed by a dealer refund.  Unresolved
                        disputes may lead to legal action by the buyer, subject
                        to the mandatory arbitration provision mentioned above.
                        By clicking I agree, I agree to the terms of this dealer
                        agreement and also the terms of use of the
                        findmyoptions.com LLC website. I Agree to the Terms and
                        Conditions Confirm
                      </CardBody>
                    </Card>
                  </Tab>
                  <Tab
                    key="tier2"
                    title={<div className="text-lg">Tier 2</div>}
                  >
                    <Card
                      className="h-[600px] overflow-scroll -mt-5 "
                      shadow="none"
                    >
                      <CardBody>
                        This agreement ("Agreement") governs your use of
                        findmyoptions.com LLC's Tier 2: Dealer Essentials ("Tier
                        2") dealer software ("Software"). By accessing or using
                        the Software, you agree to be bound by the terms and
                        conditions of this Agreement.  1. Grant of License. In
                        addition to the Tier 1 license, findmyoptions.com LLC
                        grants you a non-exclusive, non-transferable, revocable
                        license to access and use the Tier 2 features ("Tier 2
                        Features") of the Software for internal business
                        purposes at your dealership Tier 1 Features. Under Tier
                        1, you are entitled to the following features:
                        Cloud-based inventory management: Manage your inventory
                        data and track stock levels, vehicle details, and sales
                        performance in real-time.  Built-in profit and loss
                        calculators: Gain insights into your dealership's
                        financial performance and optimize your pricing and
                        sales strategies.  Intuitive listing creation tool:
                        Create compelling vehicle listings with high-quality
                        images, detailed descriptions, and prominent calls to
                        action.  Additional features: This Agreement is not
                        exhaustive and may be updated by findmyoptions.com LLC
                        with additional features or functionalities under Tier 1
                        at its sole discretion. 2. Tier 2 Features. In addition
                        to all Tier 1 features, Tier 2 includes the following:
                        Mobile app for sales team: Access vehicle information,
                        specifications, options, value data, and history reports
                        with a VIN scan or search.  Bundled pricing for options
                        and reports: Enjoy cost-effective packages for
                        frequently used features like basic options,
                        vehicle-specific features, and history reports. Listing
                        export to multiple platforms: Effortlessly export
                        listings to popular automotive websites, social media
                        platforms, and online marketplaces. Maximum Vehicles.
                        Tier 2 has a maximum limit of 50 vehicles per month. If
                        you exceed this limit, you may be subject to additional
                        fees or may be required to upgrade to a higher tier.  3.
                        Tier 2 Fees and Payment. Tier 2 requires a monthly
                        subscription fee of $49 USD. A one-time setup fee of $49
                        USD applies upon subscribing to Tier 2. Any in-app
                        purchases you choose to make beyond the bundled packages
                        will be subject to associated fees displayed within the
                        Software.  4. Optional In-App Purchases: Tier 2 allows
                        you to purchase additional features and services within
                        the Software, Enhanced Listing Options: Drive buyer
                        engagement and stand out from the competition with
                        comprehensive vehicle information and captivating
                        visuals. Our system seamlessly integrates data from: 
                        including but not limited to:  Vehicle history reports:
                        Gain instant access to comprehensive vehicle history
                        reports powered by VinAudit.com, a trusted provider of
                        vehicle history data. Build transparency and trust with
                        potential buyers by presenting detailed information on a
                        vehicle's past, including accident history, title
                        status, odometer readings, and more. Please note that we
                        are not responsible for the accuracy or completeness of
                        the information contained in these reports. It is your
                        responsibility to review the reports carefully and
                        verify all information with the vehicle owner and other
                        reliable sources.  Seamless Vehicle Shipping: Get
                        competitive quotes for vehicle transport across the
                        nation, right within our platform. No need for separate
                        searches or calls. Pay and track your shipments directly
                        on our site for complete transparency and peace of
                        mind.It's your responsibility to independently research
                        and select the best shipping options for your needs.
                        Streamline DMV & Titling: Cut staffing costs and ditch
                        the hassle with our fully online platform. Handle DMV
                        and Titling electronically, saving you time, money, and
                        paperwork. Enhanced Listing Options: Drive buyer
                        engagement and stand out from the competition with
                        comprehensive vehicle information and captivating
                        visuals. Our system seamlessly integrates data from: 
                        Government & Proprietary Databases: Access authoritative
                        vehicle details like specifications, features, and
                        valuation estimates sourced from reliable government and
                        industry databases. AI-Driven Insights: Enhance your
                        listings with AI-powered insights, including market
                        trends, pricing suggestions, and buyer preferences,
                        helping you optimize your presentation and capture more
                        attention. Important Disclaimer: While we strive to
                        provide comprehensive and reliable information through
                        our platform, including data from government and
                        proprietary databases, AI-powered insights, and market
                        trends, we cannot guarantee absolute accuracy or
                        completeness. Some information, especially those
                        generated by AI, may not be perfect.  Therefore, it's
                        your responsibility to independently verify all data and
                        information, including vehicle details, specifications,
                        features, valuations, pricing, and offers, before making
                        any business decisions or publishing listings. Remember,
                        you are ultimately responsible for the accuracy and
                        reliability of the information you present to potential
                        buyers. We encourage you to utilize external resources
                        and double-check all information before relying on it.
                        Please note that we cannot be held responsible for any
                        inaccuracies or errors displayed on our platform.  5.
                        Restrictions. You agree not to: Share your login
                        credentials with any third party.  Modify, reverse
                        engineer, decompile, or disassemble the Software. Use
                        the Software for any illegal or unauthorized purpose.
                        Violate any applicable laws or regulations. Use the
                        listing export feature in a way that violates the terms
                        of service of any other platform. 6. Termination.
                        findmyoptions.com LLC may terminate this Agreement and
                        your access to the Software at any time for any reason
                        or no reason. 7. Disclaimer of Warranties. The
                        additional features in Tier 2 are provided "AS IS" and
                        without any warranties of any kind, express or implied,
                        including, but not limited to, warranties of
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NON-INFRINGEMENT. FINDMYOPTIONS.COM MAKES NO WARRANTY
                        THAT THE TIER 2 FEATURES WILL BE UNINTERRUPTED,
                        ERROR-FREE, OR SECURE.  8. Limitation of Liability. IN
                        NO EVENT WILL FINDMYOPTIONS.COM BE LIABLE FOR ANY
                        DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR
                        EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                        YOUR USE OF THE TIER 2 FEATURES. 9. Governing Law. This
                        Agreement shall be governed by and construed in
                        accordance with the laws of the State of Kentucky,
                        without regard to its conflict of laws provisions. 10.
                        Entire Agreement. This Agreement constitutes the entire
                        agreement between you and findmyoptions.com LLC with
                        respect to your use of the Tier 2 features of the
                        Software. 11. Updates. findmyoptions.com LLC reserves
                        the right to update this Agreement at any time by
                        posting the amended Agreement on its website.  12.
                        Contact Us. If you have any questions about this
                        Agreement, please contact findmyoptions.com LLC at
                        support@findmyoptions.com  13. Dealer Integrity:  Our
                        platform operates under a strict zero-tolerance policy
                        for dealer fraud and misrepresentation. We encourage
                        users to report any suspected issues promptly. Upon
                        receiving such reports, we will conduct a thorough
                        investigation and take appropriate action, including but
                        not limited to:  - Account suspension or termination.  -
                        Reporting to relevant authorities. - Assisting users in
                        seeking legal recourse. Users remain responsible for
                        exercising due diligence and verifying information
                        independently before engaging in transactions.  14.
                        Dispute Resolution: We encourage amicable resolution of
                        disputes between dealers and buyers through direct
                        communication before seeking formal action.  Our
                        platform may offer optional mediation or arbitration
                        services to facilitate resolution. Participation is
                        voluntary and not binding. This Agreement includes
                        mandatory arbitration for disputes arising from it or
                        platform usage. Arbitration will follow the rules and
                        procedures set forth by the state of Kentucky. The
                        arbitrator's decision will be final and binding on both
                        parties.  15. Damage Resolution: If a shipped vehicle
                        exhibits undisclosed damage upon arrival, the buyer must
                        immediately notify the dealer and document the issues
                        with photos and videos. Both parties are encouraged to
                        collaborate on an amicable solution. This could involve:
                        - Dealer-arranged repairs.  - Digital due bills issued
                        for repairs. - Vehicle return at buyer's expense for
                        return shipping, followed by a dealer refund. Unresolved
                        disputes may lead to legal action by the buyer, subject
                        to the mandatory arbitration provision mentioned above. 
                        This Agreement governs your use of findmyoptions.com LLC
                        dealer software. By clicking "I Agree" and completing
                        the electronic signature process through Adobe eSign,
                        you acknowledge that you have read and understood its
                        terms and agree  to be bound by them. This agreement
                        will be stored electronically for future reference. I
                        Agree to the Terms and Conditions Confirm
                      </CardBody>
                    </Card>
                  </Tab>
                  <Tab
                    key="tier3"
                    title={<div className="text-lg">Tier 3</div>}
                  >
                    <Card
                      className="h-[600px] overflow-scroll -mt-5"
                      shadow="none"
                    >
                      <CardBody>
                        This agreement ("Agreement") governs your use of
                        findmyoptions.com LLC's Tier 3: Dealer Complete ("Tier
                        3") dealer software ("Software"). By accessing or using
                        the Software, you agree to be bound by the terms and
                        conditions of this Agreement. 1. Grant of License. In
                        addition to the Tier 1 and Tier 2 licenses,
                        findmyoptions.com LLC grants you a non-exclusive,
                        non-transferable, revocable license to access and use
                        the Tier 3 features ("Tier 3 Features") of the Software
                        for internal business purposes at your dealership.  Tier
                        1 Features. Under Tier 1, you are entitled to the
                        following features: Cloud-based inventory management:
                        Manage your inventory data and track stock levels,
                        vehicle details, and sales performance in real-time.
                        Built-in profit and loss calculators: Gain insights into
                        your dealership's financial performance and optimize
                        your pricing and sales strategies.  Intuitive listing
                        creation tool: Create compelling vehicle listings with
                        high-quality images, detailed descriptions, and
                        prominent calls to action.  Additional features: This
                        Agreement is not exhaustive and may be updated by
                        findmyoptions.com LLC with additional features or
                        functionalities under Tier 1 at its sole discretion.  2.
                        Tier 2 Features. In addition to all Tier 1 features,
                        Tier 2 includes the following: Mobile app for sales
                        team: Access vehicle information, specifications,
                        options, value data, and history reports with a VIN scan
                        or search. Bundled pricing for options and reports:
                        Enjoy cost-effective packages for frequently used
                        features like basic options, vehicle-specific features,
                        and history reports. Listing export to multiple
                        platforms: Effortlessly export listings to popular
                        automotive websites, social media platforms, and online
                        marketplaces. Maximum Vehicles. Tier 2 has a maximum
                        limit of 50 vehicles per month. If you exceed this
                        limit, you may be subject to additional fees or may be
                        required to upgrade to a higher tier. 2. Tier 3
                        Features. In addition to all Tier 1 and Tier 2 features,
                        Tier 3 includes the following: Dedicated Listing
                        Manager: A personalized expert handles ad creation and
                        platform posting for your vehicles, requiring only VIN
                        and image input. FMO Custom Dealer CRM: An integrated
                        CRM with sales automation tools, including customizable
                        follow-up systems, and an optional integrated softphone
                        for an additional monthly fee  3. Tier 3 Fees and
                        Payment. Tier 3 requires a monthly subscription fee of
                        $99 USD. A one-time setup fee of $99 USD applies upon
                        subscribing to Tier 3. An additional monthly fee of $110
                        USD applies for each user  Any in-app purchases you
                        choose to make beyond the bundled packages will be
                        subject to separate terms and conditions and associated
                        fees displayed within the Software.  An additional
                        integrated softphone can be purchased for the additional
                        current market price based on findmyoptions.com LLC
                        agreement with telecom provider 4. Optional In-App
                        Purchases: Tier 3 allows you to purchase additional
                        features and services within the Software, Enhanced
                        Listing Options: Drive buyer engagement and stand out
                        from the competition with comprehensive vehicle
                        information and captivating visuals. Our system
                        seamlessly integrates data from: including but not
                        limited to:  Vehicle history reports: Gain instant
                        access to comprehensive vehicle history reports powered
                        by VinAudit.com, a trusted provider of vehicle history
                        data. Build transparency and trust with potential buyers
                        by presenting detailed information on a vehicle's past,
                        including accident history, title status, odometer
                        readings, and more. Please note that we are not
                        responsible for the accuracy or completeness of the
                        information contained in these reports. It is your
                        responsibility to review the reports carefully and
                        verify all information with the vehicle owner and other
                        reliable sources. Seamless Vehicle Shipping: Get
                        competitive quotes for vehicle transport across the
                        nation, right within our platform. No need for separate
                        searches or calls. Pay and track your shipments directly
                        on our site for complete transparency and peace of
                        mind.It's your responsibility to independently research
                        and select the best shipping options for your needs.
                        Streamline DMV & Titling: Cut staffing costs and ditch
                        the hassle with our fully online platform. Handle DMV
                        and Titling electronically, saving you time, money, and
                        paperwork. Enhanced Listing Options: Drive buyer
                        engagement and stand out from the competition with
                        comprehensive vehicle information and captivating
                        visuals. Our system seamlessly integrates data from:
                        Government & Proprietary Databases: Access authoritative
                        vehicle details like specifications, features, and
                        valuation estimates sourced from reliable government and
                        industry databases. AI-Driven Insights: Enhance your
                        listings with AI-powered insights, including market
                        trends, pricing suggestions, and buyer preferences,
                        helping you optimize your presentation and capture more
                        attention.  Important Disclaimer: While we strive to
                        provide comprehensive and reliable information through
                        our platform, including data from government and
                        proprietary databases, AI-powered insights, and market
                        trends, we cannot guarantee absolute accuracy or
                        completeness. Some information, especially those
                        generated by AI, may not be perfect.  Therefore, it's
                        your responsibility to independently verify all data and
                        information, including vehicle details, specifications,
                        features, valuations, pricing, and offers, before making
                        any business decisions or publishing listings. Remember,
                        you are ultimately responsible for the accuracy and
                        reliability of the information you present to potential
                        buyers. We encourage you to utilize external resources
                        and double-check all information before relying on it.
                        Please note that we cannot be held responsible for any
                        inaccuracies or errors displayed on our platform.  5.
                        Restrictions. You agree not to: Share access to your
                        Dedicated Listing Manager with any third party. Share
                        your login credentials with any third party.  Modify,
                        reverse engineer, decompile, or disassemble the
                        Software. Use the Software for any illegal or
                        unauthorized purpose. Violate any applicable laws or
                        regulations.  Use the listing export feature in a way
                        that violates the terms of service of any other
                        platform.  Use the CRM or sales automation tools for any
                        illegal or unauthorized purpose. Share account access
                        credentials for the CRM with unauthorized users. 6.
                        Maximum Users. Tier 3 includes one user license by
                        default. Additional users require a monthly fee of $110
                        USD each. 7. Disclaimer of Warranties. The additional
                        features in Tier 3 are provided "AS IS" and without any
                        warranties of any kind, express or implied, including,
                        but not limited to, warranties of MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                        FINDMYOPTIONS.COM MAKES NO WARRANTY THAT THE TIER 3
                        FEATURES WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.
                        8. Limitation of Liability. IN NO EVENT WILL
                        FINDMYOPTIONS.COM BE LIABLE FOR ANY DIRECT, INDIRECT,
                        INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES
                        ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE
                        TIER 3 FEATURES. 9. Governing Law. This Agreement shall
                        be governed by and construed in accordance with the laws
                        of the State of Kentucky, without regard to its conflict
                        of laws provisions. 10. Entire Agreement. This Agreement
                        constitutes the entire agreement between you and
                        findmyoptions.com LLC with respect to your use of the
                        Tier 3 features of the Software. 11. Updates.
                        findmyoptions.com LLC reserves the right to update this
                        Agreement at any time by posting the amended Agreement
                        on its website. 12. Contact Us. If you have any
                        questions about this Agreement, please contact
                        findmyoptions.com LLC at support@findmyoptions.com 13.
                        Dealer Integrity: Our platform operates under a strict
                        zero-tolerance policy for dealer fraud and
                        misrepresentation. We encourage users to report any
                        suspected issues promptly. Upon receiving such reports,
                        we will conduct a thorough investigation and take
                        appropriate action, including but not limited to: -
                        Account suspension or termination. - Reporting to
                        relevant authorities. - Assisting users in seeking legal
                        recourse. Users remain responsible for exercising due
                        diligence and verifying information independently before
                        engaging in transactions.  14. Dispute Resolution: We
                        encourage amicable resolution of disputes between
                        dealers and buyers through direct communication before
                        seeking formal action. Our platform may offer optional
                        mediation or arbitration services to facilitate
                        resolution. Participation is voluntary and not binding.
                        This Agreement includes mandatory arbitration for
                        disputes arising from it or platform usage. Arbitration
                        will follow the rules and procedures set forth by the
                        state of Kentucky. The arbitrator's decision will be
                        final and binding on both parties.  15. Damage
                        Resolution: If a shipped vehicle exhibits undisclosed
                        damage upon arrival, the buyer must immediately notify
                        the dealer and document the issues with photos and
                        videos. Both parties are encouraged to collaborate on an
                        amicable solution. This could involve: - Dealer-arranged
                        repairs. - Digital due bills issued for repairs. -
                        Vehicle return at buyer's expense for return shipping,
                        followed by a dealer refund. Unresolved disputes may
                        lead to legal action by the buyer, subject to the
                        mandatory arbitration provision mentioned above.  By
                        clicking "I Agree" or using the Tier 3 features, you
                        acknowledge that you have read and understood this
                        Agreement and agree to be bound by its terms. I Agree to
                        the Terms and Conditions
                      </CardBody>
                    </Card>
                  </Tab>
                  <Tab
                    key="tier4"
                    title={<div className="text-lg">Tier 4</div>}
                  >
                    <Card
                      className="h-[600px] overflow-scroll -mt-5"
                      shadow="none"
                    >
                      <CardBody>
                        This Agreement ("Agreement") governs your use of
                        findmyoptions.com LLC's Tier 4: Dealer Enterprise ("Tier
                        4") dealer software ("Software"). By accessing or using
                        the Software, you agree to be bound by the terms and
                        conditions of this Agreement. 1. Grant of License. In
                        addition to the Tier 1, Tier 2, and Tier 3 licenses,
                        findmyoptions.com LLC grants you a non-exclusive,
                        non-transferable, revocable license to access and use
                        the Tier 4 features ("Tier 4 Features") of the Software
                        for internal business purposes at your dealership. Tier
                        1 Features. Under Tier 1, you are entitled to the
                        following features:  Cloud-based inventory management:
                        Manage your inventory data and track stock levels,
                        vehicle details, and sales performance in real-time.
                        Built-in profit and loss calculators: Gain insights into
                        your dealership's financial performance and optimize
                        your pricing and sales strategies.  Intuitive listing
                        creation tool: Create compelling vehicle listings with
                        high-quality images, detailed descriptions, and
                        prominent calls to action.   Additional features: This
                        Agreement is not exhaustive and may be updated by
                        findmyoptions.com LLC with additional features or
                        functionalities under Tier 1 at its sole discretion.  2.
                        Tier 2 Features. In addition to all Tier 1 features,
                        Tier 2 includes the following: Mobile app for sales
                        team: Access vehicle information, specifications,
                        options, value data, and history reports with a VIN scan
                        or search. Bundled pricing for options and reports:
                        Enjoy cost-effective packages for frequently used
                        features like basic options, vehicle-specific features,
                        and history reports.  Listing export to multiple
                        platforms: Effortlessly export listings to popular
                        automotive websites, social media platforms, and online
                        marketplaces. Maximum Vehicles. Tier 2 has a maximum
                        limit of 50 vehicles per month. If you exceed this
                        limit, you may be subject to additional fees or may be
                        required to upgrade to a higher tier. 2. Tier 3
                        Features. In addition to all Tier 1 and Tier 2 features,
                        Tier 3 includes the following:  Dedicated Listing
                        Manager: A personalized expert handles ad creation and
                        platform posting for your vehicles, requiring only VIN
                        and image input. FMO Custom Dealer CRM: An integrated
                        CRM with sales automation tools, including customizable
                        follow-up systems, and an optional integrated softphone
                        for an additional monthly fee  2. Tier 4 Features. In
                        addition to all Tier 1, Tier 2, and Tier 3 features,
                        Tier 4 includes the following: Dedicated Account
                        Manager: Personalized support and strategic guidance
                        from a dedicated Account Manager assigned to your
                        dealership. Online Inquiry Management: FMO manages all
                        inquiries from listings we manage, connecting you with
                        qualified buyers and filtering out tire kickers. Web
                        Hosting & Integrated Inventory Management: Seamlessly
                        integrated website hosted on FMO platform with mobile
                        app inventory management.  3. Tier 4 Fees and Payment.
                        Tier 4 requires a monthly subscription fee of $149 USD. 
                        A one-time setup fee of $149 USD applies upon
                        subscribing to Tier 3. An additional monthly fee of $99
                        USD applies for each user  Any in-app purchases you
                        choose to make beyond the bundled packages will be
                        subject to separate terms and conditions and associated
                        fees displayed within the Software.  An additional
                        integrated softphone can be purchased for the additional
                        current market price based on findmyoptions.com LLC
                        agreement with telecom provider  4. Optional In-App
                        Purchases: Tier 4 allows you to purchase additional
                        features and services within the Software, Enhanced
                        Listing Options: Drive buyer engagement and stand out
                        from the competition with comprehensive vehicle
                        information and captivating visuals. Our system
                        seamlessly integrates data from: including but not
                        limited to: Vehicle history reports: Gain instant access
                        to comprehensive vehicle history reports powered by
                        VinAudit.com, a trusted provider of vehicle history
                        data. Build transparency and trust with potential buyers
                        by presenting detailed information on a vehicle's past,
                        including accident history, title status, odometer
                        readings, and more. Please note that we are not
                        responsible for the accuracy or completeness of the
                        information contained in these reports. It is your
                        responsibility to review the reports carefully and
                        verify all information with the vehicle owner and other
                        reliable sources.  Seamless Vehicle Shipping: Get
                        competitive quotes for vehicle transport across the
                        nation, right within our platform. No need for separate
                        searches or calls. Pay and track your shipments directly
                        on our site for complete transparency and peace of
                        mind.It's your responsibility to independently research
                        and select the best shipping options for your needs. 
                        Streamline DMV & Titling: Cut staffing costs and ditch
                        the hassle with our fully online platform. Handle DMV
                        and Titling electronically, saving you time, money, and
                        paperwork. Enhanced Listing Options: Drive buyer
                        engagement and stand out from the competition with
                        comprehensive vehicle information and captivating
                        visuals. Our system seamlessly integrates data from:
                        Government & Proprietary Databases: Access authoritative
                        vehicle details like specifications, features, and
                        valuation estimates sourced from reliable government and
                        industry databases. AI-Driven Insights: Enhance your
                        listings with AI-powered insights, including market
                        trends, pricing suggestions, and buyer preferences,
                        helping you optimize your presentation and capture more
                        attention.  Important Disclaimer: While we strive to
                        provide comprehensive and reliable information through
                        our platform, including data from government and
                        proprietary databases, AI-powered insights, and market
                        trends, we cannot guarantee absolute accuracy or
                        completeness. Some information, especially those
                        generated by AI, may not be perfect. Therefore, it's
                        your responsibility to independently verify all data and
                        information, including vehicle details, specifications,
                        features, valuations, pricing, and offers, before making
                        any business decisions or publishing listings. Remember,
                        you are ultimately responsible for the accuracy and
                        reliability of the information you present to potential
                        buyers. We encourage you to utilize external resources
                        and double-check all information before relying on it.
                        Please note that we cannot be held responsible for any
                        inaccuracies or errors displayed on our platform. 5.
                        Restrictions. In addition to Tier 1, Tier 2, and Tier 3
                        restrictions, you may not: Share access to your
                        Dedicated Listing Manager with any third party. Share
                        your login credentials with any third party.  Modify,
                        reverse engineer, decompile, or disassemble the
                        Software. Use the Software for any illegal or
                        unauthorized purpose. Violate any applicable laws or
                        regulations. Use the listing export feature in a way
                        that violates the terms of service of any other
                        platform.  Use the CRM or sales automation tools for any
                        illegal or unauthorized purpose.Share access to your
                        Dedicated Account Manager with any third party. Utilize
                        the Online Inquiry Management system for any illegal or
                        unauthorized purpose. Modify or tamper with the hosted
                        website provided by FMO. 6. Disclaimer of Warranties.
                        The additional features in Tier 4 are provided "AS IS"
                        and without any warranties of any kind, express or
                        implied, including, but not limited to, warranties of
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NON-INFRINGEMENT. FINDMYOPTIONS.COM MAKES NO WARRANTY
                        THAT THE TIER 4 FEATURES WILL BE UNINTERRUPTED,
                        ERROR-FREE, OR SECURE. 7. Limitation of Liability. IN NO
                        EVENT WILL FINDMYOPTIONS.COM BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR
                        EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                        YOUR USE OF THE TIER 4 FEATURES. 8. Governing Law. This
                        Agreement shall be governed by and construed in
                        accordance with the laws of the State of Kentucky,
                        without regard to its conflict of laws provisions. 9.
                        Entire Agreement. This Agreement constitutes the entire
                        agreement between you and findmyoptions.com LLC with
                        respect to your use of the Tier 4 features of the
                        Software. 10. Updates. findmyoptions.com LLC reserves
                        the right to update this Agreement at any time by
                        posting the amended Agreement on its website. 11.
                        Contact Us. If you have any questions about this
                        Agreement, please contact findmyoptions.com LLC at
                        support@findmyoptions.com. 12. Dealer Integrity: Our
                        platform operates under a strict zero-tolerance policy
                        for dealer fraud and misrepresentation. We encourage
                        users to report any suspected issues promptly. Upon
                        receiving such reports, we will conduct a thorough
                        investigation and take appropriate action, including but
                        not limited to: -  Account suspension or termination. -
                        Reporting to relevant authorities.  - Assisting users in
                        seeking legal recourse.  Users remain responsible for
                        exercising due diligence and verifying information
                        independently before engaging in transactions.  13.
                        Dispute Resolution: We encourage amicable resolution of
                        disputes between dealers and buyers through direct
                        communication before seeking formal action.  Our
                        platform may offer optional mediation or arbitration
                        services to facilitate resolution. Participation is
                        voluntary and not binding. This Agreement includes
                        mandatory arbitration for disputes arising from it or
                        platform usage. Arbitration will follow the rules and
                        procedures set forth by the state of Kentucky. The
                        arbitrator's decision will be final and binding on both
                        parties. 14. Damage Resolution: If a shipped vehicle
                        exhibits undisclosed damage upon arrival, the buyer must
                        immediately notify the dealer and document the issues
                        with photos and videos. Both parties are encouraged to
                        collaborate on an amicable solution. This could involve:
                        - Dealer-arranged repairs.  - Digital due bills issued
                        for repairs.  - Vehicle return at buyer's expense for
                        return shipping, followed by a dealer refund.
                         Unresolved disputes may lead to legal action by the
                        buyer, subject to the mandatory arbitration provision
                        mentioned above. By clicking "I Agree" or using the Tier
                        4 features, you acknowledge that you have read and
                        understood this Agreement and agree to be bound by its
                        terms. I Agree to the Terms and Conditions
                      </CardBody>
                    </Card>
                  </Tab>
                </Tabs>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
