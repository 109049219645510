import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserResponse } from 'services'

export interface UserState {
  firstname: string | null
  info: UserResponse | null
  openTicket: string | null
}

const initialState: UserState = {
  firstname:"",
  info: null,
  openTicket: null
}

export const userInfo = createSlice({
  name: 'user-information',
  initialState,
  reducers: {
    changeUserName: (state, action: PayloadAction<string|null>) => {
      state.firstname= action.payload
    },
    changeUserInfo: (state, action: PayloadAction< UserResponse |null>) => {
      state.info = action.payload
    },
    changeTicketView: (state,action: PayloadAction<string|null>) => {
      state.openTicket = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {  changeUserName,changeUserInfo,changeTicketView } = userInfo.actions

export default userInfo.reducer