import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";

import "@react-pdf-viewer/core/lib/styles/index.css";

import { useGetPriceAdvanceDataPdf } from "services";
import type { Plugin, RenderViewer } from "@react-pdf-viewer/core";

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (
  props: PageThumbnailPluginProps
): Plugin => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps: RenderViewer) => {
      let { slot } = renderProps as any;

      slot.children = PageThumbnail;
      // Reset the sub slot
      slot.subSlot.attrs = {};
      slot.subSlot.children = <></>;

      return slot;
    },
  };
};
interface Props {
  url?: string;
}

interface DisplayThumbnailExampleProps {
  fileUrl?: string;
  // pageIndex?: number;
}

const PDFThumbnail: React.FC<DisplayThumbnailExampleProps> = (
  {
    fileUrl
  }
) => {
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);

  const thumbnailPluginInstance = thumbnailPlugin({ });
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover width={451} getPageIndex={() => 0} />,
  });
  // const {
  //   data: priceAdvanceData,
  //   refetch: refetchPriceAdvanceData,
  //   isLoading,
  // } = useGetPriceAdvanceDataPdf("5GADT13S862340782" || "", true);

  // React.useEffect(() => {
  //   if (priceAdvanceData) {
  //     const blob = new Blob([priceAdvanceData], { type: "application/pdf" });
  //     console.log(blob,URL.createObjectURL(blob))
  //     setPdfUrl(URL.createObjectURL(blob));
  //   }
  //   return () => {
  //     if (pdfUrl) {
  //       URL.revokeObjectURL(pdfUrl);
  //     }
  //   };
  // }, [priceAdvanceData]);
   React.useEffect(()=>{
    if(fileUrl){
      setPdfUrl(createPDFUrlFromBase64(fileUrl))
    }
   },[fileUrl])

  return (
    <div className=" h-[13.8rem]  pdf-thumbnail">
      {pdfUrl && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfUrl}
            plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
          />{" "}
        </Worker>
      )}
    </div>
  );
};

export default PDFThumbnail;

function createPDFUrlFromBase64(base64String:string) {
  // Decode the Base64 string
  const decodedData = atob(base64String);
  
  // Convert the decoded data into a Uint8Array
  const byteArray = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
  }
  
  // Create a Blob from the Uint8Array
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  
  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);
  
  return url;
}