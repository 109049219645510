import React, { useState } from "react";
import VehicleConditionItem from "./VehicleConditionItem";
import { useDispatch, useSelector } from "react-redux";
import { updateVehicleCondition } from "modules/dealer/store/vehicle-condition";
import { RootState } from "modules/dealer/store/store";

const vehicle_condition = [
  {
    keyToggle: "mechnical_Issue",
    keyDescription: "mechanicalIssueDescription",
    title: "Mechanical Issues, leaks, squeeks, abnormal noises",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "missing_Original_Key",
    keyDescription: "missingOriginalKeyDescription",
    title:
      "Are you missing any original keys and fobs, floor mats, tool kits, spare tire, wheel lock, owners manuals?",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "bumper_repair",
    keyDescription: "bumperRepairDescription",
    title: "Has the vehicle ever been painted? Bumper repair? Other paint?",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "interior_Cracks",
    keyDescription: "interiorCracksDescription",
    title: "Interior cracks, rips, burns, tears, broken parts, headliner",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "exterior_scratches",
    keyDescription: "exteriorScratchesDescription",
    title: "Exterior scratches, dents, fading paint, broken parts",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "lights_on_in_dash",
    keyDescription: "lightsOnInDashDescription",
    title: "Lights on in dash",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "items_functionality",
    keyDescription: "itemsFunctionalityDescription",
    title: "Items not functioning properly",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "do_tires_50_remaining",
    keyDescription: "tires50RemainingDescription",
    title: "Do the tires have less than 50% tread remaining?",
    isSelected: false,
    description: "",
  },
  {
    keyToggle: "transmission_slipping",
    keyDescription: "transmissionSlippingDescription",
    title: "Transmission slipping?",
    isSelected: false,
    description: "",
  },
  {
    key: "broken_glass",
    keyToggle: "broken_glass",
    keyDescription: "brokenGlassDescription",
    title: "Cracked or broken glass",
    isSelected: false,
    description: "",
  },
];

interface VehicleConditionState {
  [key: string]: boolean | string;
}

interface ToggleHandler {
  (toggleKey: string, descriptionKey: string): void;
}

interface DescriptionChangeHandler {
  (toggleKey: string, descriptionKey: string, description: string): void;
}

interface ConditionChangeHandler {
  (
    toggleKey: string,
    descriptionKey: string,
    isSelected: boolean,
    description: string
  ): void;
}

interface Props {
  vehicleConditionProps?: any;
  isManageListing?: boolean;
}
export default function VehicleConditions({
  vehicleConditionProps,
  isManageListing,
}: Props) {
  const dispatch = useDispatch();

  const initialVehicleConditions: VehicleConditionState = {
    mechnical_Issue: false,
    mechanicalIssueDescription: "",
    missing_Original_Key: false,
    missingOriginalKeyDescription: "",
    bumper_repair: false,
    bumperRepairDescription: "",
    interior_Cracks: false,
    interiorCracksDescription: "",
    exterior_scratches: false,
    exteriorScratchesDescription: "",
    lights_on_in_dash: false,
    lightsOnInDashDescription: "",
    items_functionality: false,
    itemsFunctionalityDescription: "",
    do_tires_50_remaining: false,
    tires50RemainingDescription: "",
    transmission_slipping: false,
    transmissionSlippingDescription: "",
    broken_glass: false,
    brokenGlassDescription: "",
  };
  const [vehicleCondition, setVehicleCondition] = useState(
    vehicleConditionProps ?? initialVehicleConditions
  );
  const handleDescriptionChange: DescriptionChangeHandler = (
    toggleKey,
    descriptionKey,
    description
  ) => {
    const currentSelected = vehicleCondition[toggleKey] as boolean; // Cast as boolean for safety
    handleConditionChange(
      toggleKey,
      descriptionKey,
      currentSelected,
      description
    );
  };
  const handleConditionChange: ConditionChangeHandler = (
    toggleKey,
    descriptionKey,
    isSelected,
    description
  ) => {
    const updatedVehicleCondition = {
      ...vehicleCondition,
      [toggleKey]: isSelected, // This correctly updates the toggle state
      [descriptionKey]: isSelected ? description : "", // This line is problematic
    };

    setVehicleCondition(updatedVehicleCondition);

    dispatch(updateVehicleCondition(updatedVehicleCondition));
  };

  const handleToggle: ToggleHandler = (
    toggleKey: string,
    descriptionKey: string
  ) => {
    const currentSelected = vehicleCondition[toggleKey];
    const currentDescription = vehicleCondition[`${descriptionKey}`] as string; // Cast as string for safety
    console.log({ currentSelected });
    console.log({ currentDescription });
    handleConditionChange(
      toggleKey,
      descriptionKey,
      !currentSelected,
      !currentSelected ? "" : currentDescription
    );
  };
  return (
    <div>
      {vehicle_condition.map((vehicle) => (
        <VehicleConditionItem
          key={vehicle.title}
          isManageListing={isManageListing}
          keyToggle={vehicle.keyToggle ?? ""}
          keyDescription={vehicle.keyDescription ?? ""}
          title={vehicle.title}
          isSelected={Boolean(vehicleCondition[vehicle?.keyToggle ?? ""])}
          description={String(vehicleCondition[`${vehicle.keyDescription}`])}
          onToggle={handleToggle}
          onDescriptionChange={handleDescriptionChange}
        />
      ))}
    </div>
  );
}
