import React, { useState } from "react";
import { PaymentCardInfo } from "services/endpoints/shipping-quotes/shipping.type";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { Button, Checkbox } from "@nextui-org/react";
import { PhoneNumberInput, Typography } from "components";
import { useGetZipCodeDetails } from "services";
import { useDebounceValue } from "usehooks-ts";
import { Controller } from "react-hook-form";
import { UsedCarReviewValidationSchema } from "./used-car.validation";
import { useGetWarrantyLead } from "hooks/api/queries/view-cars/view-cars";
import { useNavigate } from "react-router-dom";

interface Props {
  handleNext?: () => void;
  savedMethod?: PaymentCardInfo | null;
  isPending?: boolean;
}

const UsedCardForm: React.FC<Props> = ({ handleNext, isPending }) => {
  const [checked,setChecked] = useState(false)
  const navigate = useNavigate();
  const [place, setPlace] = React.useState({
    city: "",
    county: "",
    state: "",
    stateAbbr: "",
  });
  const [zipValue, setZipValue] = React.useState("");
  const {
    mutateAsync: getWarrentyLeadAsync,
    isPending: isPendingToGetWarrantyLead,
  } = useGetWarrantyLead();
  const [debouncedValue] = useDebounceValue(zipValue, 500);
  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
    isPending: isPendingToFindZipCode,
  } = useGetZipCodeDetails(debouncedValue);
  React.useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (zipcodeData && !isPendingToFindZipCode) {
      setPlace({
        city: zipcodeData?.city ?? "",
        county: zipcodeData?.county ?? "",
        stateAbbr: zipcodeData?.stateAbbr ?? "",
        state: zipcodeData?.state ?? "",
      });
    }
  }, [zipcodeData]);

  const handleSubmit = (e: any) => {
    const shouldGoNext = zipcodeData;
    if (handleNext && shouldGoNext) {
      // getWarrentyLeadAsync({
      //   name: e.fname + " " + e.lname,
      //   zipCode: zipValue,
      //   email: e.email,
      //   listingId: "0",
      //   phone: e.phone,
      //   vin: "",
      // });
      handleNext();
    }
  };

  const handleNavigate = (forPage: "privacy" | "terms") => {
    window.open(
      window.location.href + "?policy=" + forPage,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div className="w-full ">
      <div className="m-auto flex flex-col  max-w-screen-sm py-6 md:py-10  ">
        {/* <Typography
          variant="custom"
          className="font-[Metropolis] text-3xl font-[700] md:text-3xl xl:text-[40px] text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text"
        >
          Used Car Warranty
        </Typography> */}
        <Typography
          variant="custom"
          className="mb-6 mt-4 text-2xl font-bold  bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text m-auto"
        >
          Enter Contact Details
        </Typography>
        <div className={`rounded-xl px-6`}>
          <Form
            id="new-order-shipping-form"
            schema={UsedCarReviewValidationSchema}
            onSubmit={handleSubmit}
            className="md:px-10"
          >
            {({ formState: { errors, isSubmitted }, register, control }) => {
              console.log("errors", errors);
              return (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-wrap -mx-2 mb-1">
                    {[
                      {
                        id: "fname",
                        label: "First Name",
                        style: "w-full md:w-1/2 px-2 mb-1 md:mb-0",
                        placeholder: "",
                        type: "text",
                      },
                      {
                        id: "lname",
                        label: "Last Name",
                        style: "w-full md:w-1/2 px-2",
                        placeholder: "",
                        type: "text",
                      },
                    ].map(({ id, label, style, placeholder, type }) => {
                      return (
                        <div className={style} key={id}>
                          <label
                            htmlFor={id}
                            className="text-xs block mb-1 font-bold"
                          >
                            {label}
                          </label>
                          <Controller
                            control={control}
                            name={id as any}
                            render={({ field }) => (
                              <Input
                                variant="form"
                                size="compact"
                                errorMessage={
                                  errors[id as keyof typeof errors]?.message
                                }
                                type={type}
                                placeholder={placeholder}
                                id={id}
                                // {...register(id as any)}
                                {...field}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const formattedValue = inputValue.replace(
                                    /[^a-zA-Z]/g,
                                    ""
                                  );

                                  return field.onChange(formattedValue);
                                }}
                                classNames={{
                                  input:
                                    "font-bold  w-full focus:outline-none text-center",
                                  inputWrapper: !!errors[
                                    id as keyof typeof errors
                                  ]
                                    ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                    : "",
                                }}
                              />
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="">
                    <label
                      htmlFor={"email"}
                      className="text-xs block mb-1 font-bold"
                    >
                      Email
                    </label>
                    <Input
                      variant="form"
                      size="compact"
                      errorMessage={errors["email"]?.message}
                      type="text"
                      id={"email"}
                      {...register("email")}
                      classNames={{
                        input:
                          "font-bold  w-full focus:outline-none text-center",
                        inputWrapper: !!errors["email"]
                          ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                          : "",
                      }}
                    />
                  </div>

                  <div className="flex flex-wrap -mx-2 mb-1">
                    <div className="w-full md:w-1/2 px-2 mb-2 md:mb-0 ">
                      <label
                        htmlFor={"phone"}
                        className="text-xs block mb-1 font-bold"
                      >
                        Phone
                      </label>
                      <PhoneNumberInput
                        key={"phone"}
                        control={control}
                        id={"phone"}
                        placeholder=""
                        error={!!errors.phone}
                        InputProps={{
                          classNames: {
                            input:
                              "font-bold w-full focus:outline-none text-center",
                            inputWrapper: !!errors.phone
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          },
                          errorMessage: errors.phone?.message,
                        }}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-2">
                      <label
                        htmlFor={"zipcode"}
                        className="text-xs block mb-1 font-bold"
                      >
                        ZIP Code
                      </label>

                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={
                          !zipcodeData && isSubmitted && "ZIP Code is Invalid"
                        }
                        type="text"
                        id={"zipcode"}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper:
                            !zipcodeData && isSubmitted
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                        }}
                        value={zipValue}
                        maxLength={5}
                        onChange={(e) => {
                          const formattedValue = e.target.value?.replace(
                            /\D/g,
                            ""
                          );
                          setZipValue(formattedValue);
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-center">
                    <div className="flex justify-center  items-center mx-2 md:mx-8">
                      <Checkbox onChange={(e)=>setChecked(e.target.checked)} checked={checked} size="lg"  className="border-black  left-2" radius="sm" />{" "}
                      <Typography variant="custom" className="text-[9.5px] text-center font-[500]">
                        {" "}
                        I agree to receive marketing messaging from{" "}
                        <span
                          onClick={()=> window.open("https://findmyoptions.com/", "_blank",
                          "noopener,noreferrer"
                        )}
                          className="underline cursor-pointer"
                        >
                          findmyoptions.com
                        </span>{" "}
                        at the phone number provided above data rates may apply.
                        Reply STOP to opt out.
                      </Typography>
                    </div>
                    <Button
                      type="submit"
                      isLoading={isPending || isPendingToGetWarrantyLead}
                      isDisabled={!checked}
                      className="bg-gradient-to-r w-[75%]  mb-6 mt-2 from-[#018ffd] to-[#070c84] text-white text-lg  py-6 tracking-wide px-6 rounded-xl "
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
        <div className="-mt-6  mb-2 px-[60px] flex justify-center gap-4 text-center   flr">
          
            <Typography
              variant="custom"
              className="text-[9.5px] text-[#018FFD] cursor-pointer"
            >
              {" "}
              <span onClick={() => handleNavigate("privacy")}>
                {" "}
                Privacy Policy
              </span>
            </Typography>

            <Typography
              variant="custom"
              className="text-[9.5px] text-[#018FFD] cursor-pointer"
            >
              <span onClick={() => handleNavigate("terms")}>Terms of Use</span>
            </Typography>
          
        </div>
      </div>
    </div>
  );
};

export default UsedCardForm;
