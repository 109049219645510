import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

const SidebarNavLinks = [
  {
    href: "",
    label: "Buy",
    // icon: <CarSideIcon className="size-7" />,
    subnav: [
      {
        label: "New/Used Search",
        href: "/listing/search-car",
      },
      {
        label: "Nationwide Shipping Quotes",
        href: "/listing/tools/vehicle-shipping-quote",
      },
      {
        label: "DMV/Titling Services",
        href: "/listing/private-party-services/titling-services",
      },
      {
        label: "Free Basic Vin Search",
        href: "/listing/tools/free-basic-vin-search",
      },
      {
        label: "Factory Installed Options",
        href: "/listing/factory-installed-options",
      },
      {
        label: "Vehicle History Reports",
        href: "/listing/vehicle-history-reports",
      },
    ],
  },
  {
    href: "",
    label: "Sell",
    // icon: <StarIcon className="size-7" />,
    subnav: [
      {
        label: "List Your Car For Sale",
        href: "/listing/finance/list-your-car-for-sale",
      },
      {
        label: "Sell Your Car Instant Quote",
        href: "/listing/private-party-services/sell-instant-quote",
      },
      
      {
        label: "Loan or Lien on Title",
        href: "/listing/private-party-services/loan-and-lien",
      },
      {
        label: "Dealer Services",
        // href: "dealer-services/what-we-do"
        subLink: [
          {
            label: "What We Do",
            href: "/listing/dealer-services/what-we-do",
          },
          // {
          //   label: "Pricing",
          //   href: "/listing/dealer-services/dealer-services-pricing",
          // },
          

          {
            label: "How to Become Dealer",
            href: "/listing/dealer-services/become-a-dealer",
          },
        ],
      },
    ],
  },
  {
    href: "",
    label: "Finance",
    // icon: <SteeringWheelIcon className="size-7" />,
    subnav: [
      {
        label: "Bank/Lender Financing",
        href: "/listing/finance/buy-here-pay-here-financing",
      },
      {
        label: "In House Financing",
        href: "/listing/finance/traditional-financing",
      },
      {
        label: "Get Pre Approved",
        href: "/listing/finance/pre-approval",
      },
      
      {
        label: "Auto Loan and Lease Calculator",
        href: "/listing/tools/auto-loan-lease-calculator",
      },
    ],
  },
];

export default function PublicMobileSidebar() {
  const navigate = useNavigate();
  const toggleSidebarMenu = () => {
    const sidebar = document.querySelector("#application-sidebar");
    const sidebarOverlay = document.querySelector(
      "#application-sidebar-backdrop"
    );
    sidebar?.classList.toggle("translate-x-0");
    sidebarOverlay?.classList.toggle("hidden");
  };

  function MobileHamburgerMenu() {
    return (
      <div className="flex gap-4 me-5  ">
        <button
          type="button"
          className="text-gray-500 hover:text-gray-600 mt-auto"
          aria-label="Toggle navigation"
          onClick={toggleSidebarMenu}
        >
          <span className="sr-only">Toggle Navigation</span>
          <BlackCloseXIcon
            className="h-7 w-7"
            //  onClick={handleToggle}
          />
        </button>
      </div>
    );
  }

  // const winToggle = toggle || width < 1023 || hoverOnSidebar;

  return (
    <>
      <div
        id="application-sidebar"
        className={`xl:hidden -translate-x-full ease-in-out transition-all duration-300 transform fixed top-0 start-0 bottom-0 z-50 ${
          // winToggle ? "w-56" : "w-16"
          "w-56"
        } overflow-scroll h-[100%] bg-white border-e border-gray-200 pt-7 pb-4 overflow-y-auto xl:block xl:translate-x-0 xl:end-auto xl:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 `}
      >
        <div className="flex items-end justify-between px-4 pr-12">
          <MobileHamburgerMenu />
          {/* <Link
            className="flex-none text-xl font-semibold"
            to="/listing/landing-page"
            aria-label="Brand"
          >
            {
            // winToggle && 
            <LogoColoured />}
          </Link> */}
        </div>

        <nav className="flex flex-col flex-wrap w-full pt-6 pb-3 xl:pt-10">
          <ul>
            {SidebarNavLinks.map(({ href, label, subnav }, index) => {
              const hasSubmenu = subnav && subnav.length > 0;

              return (
                <div key={index}>
                  {hasSubmenu ? (
                    <Accordion className="px-0">
                      <AccordionItem
                        aria-label={label}
                        title={
                          // winToggle ?
                          label
                          // : ""
                        }
                        classNames={{
                          title:
                            "bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text  text-base",
                          indicator:
                            "-rotate-[90deg] data-[open=true]:rotate-90", //To rotate the indicator icon
                        }}
                        className="w-full py-1 pl-3.5 pr-2 border-gray-200 opacity-100 pointer-events-auto hover:bg-gray-100 xl:py-2 border-b-1"
                        // startContent={<span
                        //   // onClick={openSideBar}
                        //   >{icon}</span>}
                        hideIndicator={!hasSubmenu}
                        isDisabled={!hasSubmenu}
                      >
                        {subnav.map(
                          (
                            { href: subHref, label: subLabel, subLink },
                            subIndex
                          ) => {
                            if (subLink) {
                              return (
                                <p>
                                  {/* {winToggle && ( */}
                                  {/* <li className="px-1 hover:bg-gray-200 xl:py-2"> */}
                                  <div className="w-full text-start flex items-center text-base gap-x-3.5 py-1 px-2.5 text-slate-700 rounded-lg">
                                    {subLabel}
                                  </div>
                                  <ul className="list-disc list-inside ml-2 ">
                                    {subLink &&
                                      subLink.map(
                                        (
                                          { href: subHref, label: subLabel },
                                          subIndex
                                        ) => (
                                          <li
                                            key={subIndex}
                                            onClick={() => {
                                              if (subHref) {
                                                navigate(`${subHref}`);
                                                window.scrollTo(0, 0);
                                                toggleSidebarMenu();
                                              }
                                            }}
                                            className=" cursor-pointer px-1 hover:bg-gray-200 xl:py-2"
                                          >
                                            <span className="w-full text-start  items-center text-base   text-slate-700 rounded-lg">
                                              {subLabel}
                                            </span>
                                          </li>
                                        )
                                      )}
                                  </ul>
                                  {/* </li> */}
                                  {/* )} */}
                                </p>
                              );
                            } else {
                              return (
                                <p
                                  className="cursor-pointer"
                                  key={subIndex}
                                  onClick={() => {
                                    if (subHref) {
                                      navigate(`${subHref}`);
                                      window.scrollTo(0, 0);
                                      toggleSidebarMenu();
                                    }
                                  }}
                                >
                                  <li className="px-1 hover:bg-gray-200 xl:py-2">
                                    <div className="w-full text-start flex items-center text-base gap-x-3.5 py-1 px-2.5 text-slate-700 rounded-lg">
                                      {subLabel}
                                    </div>
                                  </li>
                                </p>
                              );
                            }
                          }
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <Link to={href} onClick={toggleSidebarMenu}>
                      <div className="py-2 border-gray-200 hover:bg-gray-100 xl:py-4 border-b-1">
                        <li className="px-1">
                          <div className="w-full text-start flex items-center text-base gap-x-3.5 py-2 px-2.5 text-slate-700 rounded-lg">
                            {/* {icon} */}
                            {
                              // winToggle &&
                              label
                            }
                          </div>
                        </li>
                      </div>
                    </Link>
                  )}
                </div>
              );
            })}
          </ul>
        </nav>
      </div>
      <div
        id="application-sidebar-backdrop"
        className="fixed z-[49] inset-0 hidden transition bg-gray-900 bg-opacity-50 duration dark:bg-opacity-80"
        onClick={() => {
          const sidebar = document.querySelector("#application-sidebar");
          const sidebarOverlay = document.querySelector(
            "#application-sidebar-backdrop"
          );
          sidebar?.classList.toggle("translate-x-0");
          sidebarOverlay?.classList.toggle("hidden");
        }}
      ></div>
    </>
  );
}
