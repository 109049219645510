
// import React from 'react';


// type Stage = {
//   id: number;
//   name: string;
//   completed: boolean;
// };

// const stages: Stage[] = [
//   { id: 1, name: 'Order Received', completed: true },
//   { id: 2, name: 'Driver Assigned', completed: true },
//   { id: 3, name: 'In Transit', completed: false },
//   { id: 4, name: 'Delivered', completed: false },
// ];

// const TransitStatus: React.FC = () => {
//   return (
//     <div className="flex justify-between items-center p-4">
//       {stages.map((stage, index) => (
//         <React.Fragment key={stage.id}>
//           <div className="flex flex-col items-center">
//             <div className={`w-10 h-10 rounded-full flex items-center justify-center ${stage.completed ? 'bg-blue-500' : 'bg-indigo-200'}`}>
//               <span className="text-white text-xl">&#10003;</span>
//             </div>
//             {/* Placeholder for your icon, replace with your actual icon component */}
//             <div className="mt-2">
//               {/* Assuming you have an icon component or image. Use it here. */}
//               {/* <YourIconComponent /> */}
//               <span>Icon</span>
//             </div>
//             <div className="text-sm text-gray-700 mt-1">{stage.name}</div>
//           </div>
//           {index < stages.length - 1 && (
//             <div className="flex-auto border-t-2 border-dashed border-gray-400"></div>
//           )}
//         </React.Fragment>
//       ))}
//     </div>
//   );
// };

// export default TransitStatus;


import React from 'react';
import note_icon from './assets/note-icon.png';
import driver_icon from './assets/driver-icon (2).png';
import transit_icon from './assets/transit-icon (2).png';
import delivered_icon from './assets/delivered-icon.png';
import whitetick_icon from './assets/whitetick-icon.png';
import bluetick_icon from './assets/bluetick-icon.png';
import Rectangle from './assets/Rectangle.png';

const TransitStatus: React.FC = () => {
  return (
  <>
    <div className=" w-3/4 h-5/6 max-[640px]:w-5/6 max-[640px]:h-2/3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[51.99px] bg-whitesmoke-200 shadow-[6.2px_9.4px_11.85px_rgba(0,_0,_0,_0.15)] ">
      
  
      <button className="flex m-auto mt-[90px] rounded-[15px] [background:linear-gradient(224.68deg,_#018ffd,_#070c84)] w-48 h-12 max-[640px]:mt-[70px]">
      <div className=" mt-[5%] m-auto text-lg text-white" style={{fontFamily:'Metropolis-SemiBold'}}>
        Transit Statuses
      
    </div>
      </button>
    
    
   <div className="overflow-visible w-full h-3/5 absolute top-1/2  transform -translate-y-1/2" style={{fontFamily:'Metropolis-SemiBold'}}>
   <div className="absolute mt-60 ml-[6%] lg:text-[20px] text-indigo-800 sm:text-[15px] max-[640px]:text-xs">
        Order Received
      </div>
      <div className="absolute mt-60 ml-[32%] text-gray-300 lg:text-[17px] sm:text-[12px] max-[640px]:invisible">
        Driver Assigned
      </div>
      <div className="absolute mt-60 ml-[60%] text-gray-300 lg:text-[17px] sm:text-[12px] max-[640px]:invisible">
        In Transit
      </div>
      <div className="absolute mt-60 ml-[84%] text-gray-300 lg:text-[17px] sm:text-[12px] max-[640px]:invisible">
        Delivered
      </div>
      
      <img
        className="absolute mt-48 ml-[11%] lg:w-[40px] lg:h-[40px] sm:w-[30px] sm:h-[30px] max-[640px]:h-10 max-[640px]:w-10"
        alt=""
        src={note_icon}
      />
          <img
        className="absolute mt-48 ml-[36%] lg:w-[36px] lg:h-[36px] sm:w-[26px] sm:h-[26px] max-[640px]:h-8 max-[640px]:w-8"
        alt=""
        src={driver_icon}
      />
      <img
        className="absolute  mt-48 ml-[62%] lg:w-[36px] lg:h-[36px] sm:w-[26px] sm:h-[26px] max-[640px]:h-8 max-[640px]:w-8"
        alt=""
        src={transit_icon}
      />
      <img
        className="absolute  mt-48 ml-[86%] lg:w-[36px] lg:h-[36px] sm:w-[26px] sm:h-[26px] max-[640px]:h-8 max-[640px]:w-8"
        alt=""
        src={delivered_icon}
      />
      <img
       className="w-4/5 mt-32 lg:ml-24 sm:ml-14 max-[640px]:ml-16"
        alt="" 
        src={Rectangle}
        />
      <img
        className="absolute -mt-12 ml-[8%] lg:w-24 lg:h-24 sm:w-20 sm:h-20 max-[640px]:h-16 max-[640px]:w-16 max-[640px]:-mt-10"
        alt=""
        src={bluetick_icon}
      />
      <img
        className="absolute -mt-8 ml-[35%] lg:w-14 lg:h-14 sm:w-12 sm:h-12 max-[640px]:h-12 max-[640px]:w-12"
        alt=""
        src={whitetick_icon}
      />
      <img
        className="absolute -mt-8 ml-[60%] lg:w-14 lg:h-14 sm:w-12 sm:h-12 max-[640px]:h-12 max-[640px]:w-12"
        alt=""
        src={whitetick_icon}
      />
      <img
        className="absolute -mt-8 ml-[85%] lg:w-14 lg:h-14 sm:w-12 sm:h-12 max-[640px]:h-12 max-[640px]:w-12"
        alt=""
        src={whitetick_icon}
      />
  
    </div>

      </div> 

  </>
  );
};

export default TransitStatus;
