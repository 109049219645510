import { SVGProps } from "react";

export const IdeaIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {

  return (<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" { ...props }>
  <path d="M12.5 50H16.6667M50 12.5V16.6667M83.3333 50H87.5M23.3333 23.3333L26.25 26.25M76.6667 23.3333L73.75 26.25M40.4167 70.8333H59.5833M37.5 66.6667C34.002 64.0431 31.4181 60.3855 30.1143 56.2118C28.8105 52.0382 28.853 47.5601 30.2358 43.4119C31.6185 39.2638 34.2713 35.6558 37.8185 33.0991C41.3657 30.5424 45.6274 29.1667 50 29.1667C54.3726 29.1667 58.6343 30.5424 62.1815 33.0991C65.7287 35.6558 68.3815 39.2638 69.7642 43.4119C71.147 47.5601 71.1894 52.0382 69.8857 56.2118C68.5819 60.3855 65.998 64.0431 62.5 66.6667C60.8732 68.277 59.6483 70.2477 58.9245 72.4193C58.2006 74.5908 57.9981 76.9023 58.3333 79.1667C58.3333 81.3768 57.4554 83.4964 55.8926 85.0592C54.3298 86.622 52.2101 87.5 50 87.5C47.7899 87.5 45.6702 86.622 44.1074 85.0592C42.5446 83.4964 41.6667 81.3768 41.6667 79.1667C42.0019 76.9023 41.7994 74.5908 41.0755 72.4193C40.3517 70.2477 39.1268 68.277 37.5 66.6667Z" stroke="white" stroke-width="8.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );

};
