import { Typography } from "components";
import React from "react";
import checkIcon from "../careers/assets/svg/check-icon.svg";
export default function InvestorsPage() {
  return (
    <div className="flex items-center justify-center">
      <div className="md:p-4 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            For Investors
          </p>
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-2xl font-bold mb-5">
            Fuel the Future of Automotive Technology.
          </p>
          <p className=" text-xl text-start ">
            Invest in a rapidly growing marketplace poised to revolutionize the
            car buying experience. Join us in shaping the future of the
            automotive industry and capitalize on the immense potential of
            online car sales.
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Why Invest in findmyoptions.com?:
          </p>

          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                {" "}
                Large and Growing Market: 
              </span>
              <span className="font-semibold">
                Our platform caters to a massive and underserved market with
                significant growth potential. We're a multinational corporation
                looking to bring our software to every country that buys and
                sells vehicles. 
              </span>
            </p>
          </div>

          <div className="flex items-start mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p
              className={`px-3 pt-3 text-black font-light text-xl font-semibold`}
            >
               Seamless online transactions nationwide for the everyday average
              person as well as dealerships.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Experienced Team: {" "}
              </span>
              <span className="font-semibold">
                Our team consists of seasoned professionals with extensive
                expertise in the automotive and technology sectors.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Disruptive Technology: {" "}
              </span>
              <span className="font-semibold">
                We leverage cutting-edge technology to offer a superior user
                experience and drive industry innovation.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p
              className={`px-3 pt-3 text-black font-light text-xl font-semibold`}
            >
              Superior used car dealer software, we help the little guys become
              the big guys!
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-5 text-wrap">
          <p className="text-xl font-bold pt-5 ">Ready to Join Our Journey?</p>
          <p className="text-xl  ">
            Contact our investor relations team at 
            <span
              className="underline cursor-pointer"
              onClick={() => {
                window.location.href = "mailto:support@findmyoptions.com";
              }}
            >
              support@findmyoptions.com
            </span>
            to learn more about investment opportunities and become part of our
            success story.
          </p>
        </Typography>
      </div>
    </div>
  );
}
