import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";

import "./index.scss";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

interface PaymentModalProps {
  isOpen: boolean;
  onOpen: () => void;
  Component: React.ReactNode;
  isPublic?: boolean;
  hideHeader?: boolean;

}
export const PaymentModal: React.FC<PaymentModalProps> = ({
  isOpen,
  onOpen,
  Component,
  isPublic = false,
  hideHeader = false
}) => {
  return (
    <div>
      <Modal
        isDismissable={false}
        isOpen={isOpen}
        onOpenChange={onOpen}
        scrollBehavior="inside"
        shadow="lg"
        backdrop="opaque"
        placement="center"
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
          base: " lg:mb-40 ",
          wrapper: `${isPublic ? "" : "ps-[0rem] md:ps-[8rem]"}`,
        }}
        size="2xl"
        closeButton={<BlackCloseXIcon width={"32px"} height={"32px"} />}
      >
        <ModalContent className="overflow-auto h-fit">
           <ModalHeader
            className="flex justify-center bg-fixed bg-no-repeat bg-center bg-cover "
            style={{
              backgroundImage: "url(assets/images/payment-bg-1.png)",
            }}
          >
           {!hideHeader ? <Image
              src="assets/icons/new-logo.svg"
              className="w-20 h-20 mt-auto"
              removeWrapper
              radius="none"
              shadow="none"
            />:<div className="h-32"></div>}
          </ModalHeader>
          <ModalBody className={`${hideHeader?"": "-mt-[30px]"} bg-transparent`}>{Component}</ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
