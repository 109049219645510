import { Card, CardBody, Image, Button } from "@nextui-org/react";
import { Typography } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { NoGarageIcon } from "./assets/pana";
import { useGetMyGarage } from "services";
import { getUserId } from "utils/get-user-id";
import GarageDetails from "./component/GarageDetails";
import SearchCarCard from "modules/shared/components/car-card/SearchCarCard";
import { useState } from "react";
import Car from "pages/dealer/manage-inventory/components/Car";

export default function UserGarage() {
  const userId = getUserId();
  const { data, refetch, isPending } = useGetMyGarage(userId);
  
  const location = useLocation();
  const [selectedType, setSelectedType] = useState<number | null>( location.state?.active || 0);

  const navigate = useNavigate();
  const filteredCar =
    selectedType !== null
      ? data?.filter((car) => car.status === selectedType)
      : data;
  return (
    <div className="flex flex-col gap-8">
      <div className=" text-center w-full">
        <Typography
          variant="pageTitle"
          className="gradient-text text-white text-3xl font-bold"
        >
          My Garage
        </Typography>
      </div>
      <div
        className={`w-full ${
          data && data.length ? "flex flex-col md:flex-row" : "hidden md:flex"
        }  justify-between`}
      >
        <div className="w-full flex  items-center gap-4 mb-4 md:mb-0">
          <Button
            // name="Archived"
            className={`
                    ${
                      selectedType === 0 ? "selected" : "default"
                    }  text-[15px] lg:text-[20px] px-5  text-center  rounded-xl w-full md:w-40
                `}
            onClick={() => {
              setSelectedType(0);
            }}
          >
            My Cars
          </Button>
          <div className="flex items-center justify-cente r   h-[54px] w-full">
            <Button
              // name=""
              className={`${
                selectedType === 1 ? "selected" : "default"
              } text-[15px] lg:text-[20px] px-5 text-center  rounded-xl w-full md:w-40`}
              onClick={() => {
                setSelectedType(1);
              }}
            >
              Active Listings
            </Button>
          </div>
        </div>
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
          type="button"
          size="md"
          onClick={() => navigate("/user/enter-vin")}
        >
          <Typography className="font-bold" variant="body">
            {" "}
            Add Vehicle/Create Listing
          </Typography>
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 gap-4">
        {data && data.length ? (
          filteredCar && filteredCar?.length > 0 ? (
            filteredCar?.map((carData, index) =>
              selectedType === 0 ? (
                <GarageDetails
                  from="garage"
                  car={carData}
                  key={index}
                  btnLabel="Remove From Garage"
                  userId={userId}
                  refetch={refetch}
                  loading={isPending}
                  fromGarage={true}
                  status={
                    carData.status === 0
                      ? ""
                      : carData.status === 1
                      ? "active"
                      : ""
                  }
                />
              ) : (
                <Car key={index} car={carData} refetch={refetch} />
              )
              // <SearchCarCard
              //   car={car}
              //   line1={`${car?.year ?? ""} ${car?.make ?? ""} ${car?.model ?? ""}`}
              //   line2={car?.trim ?? ""}
              //   line3={`${car.mileage} mi`}
              //   onSelect={() => {}}
              //   btnLabel="Remove From Garage"
              // />
            )
          ) : selectedType === 0 ? (
            <div className="col-span-8 border-2 md:border-3 border-blue-400 rounded-3xl w-full bg-white md:bg-gray-100 h-[425px] min-h-content md:h-[425px] flex flex-col items-center justify-center p-2">
              <NoGarageIcon className="md:hidden mb-6" />
              <Typography
                variant="custom"
                className="text-md md:text-[16px] text-center text-[#313131] w-full md:w-1/2"
              >
                <h2 className="text-2xl font-[600] mb-6">
                  Your garage is empty.
                </h2>
                <p>
                  Add cars you own, want, or have driven to your garage and
                  compare features and track the market value over time.
                </p>
              </Typography>
              <Button
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl md:hidden mt-6"
                type="button"
                size="md"
                onClick={() => navigate("/user/enter-vin")}
              >
                <Typography className="font-bold" variant="body">
                  {" "}
                  Add Vehicle/Create Listing
                </Typography>
              </Button>
            </div>
          ) : (
            <div className="flex justify-center col-span-8 items-center p-4">
              <Typography variant="subtitle">No Live Car Found!</Typography>
            </div>
          )
        ) : (
          <div className="col-span-8 border-2 md:border-3 border-blue-400 rounded-3xl w-full bg-white md:bg-gray-100 h-[425px] min-h-content md:h-[425px] flex flex-col items-center justify-center p-2">
            <NoGarageIcon className="md:hidden mb-6" />
            <Typography
              variant="custom"
              className="text-md md:text-[16px] text-center text-[#313131] w-full md:w-1/2"
            >
              <h2 className="text-2xl font-[600] mb-6">
                Your garage is empty.
              </h2>
              <p>
                Add cars you own, want, or have driven to your garage and
                compare features and track the market value over time.
              </p>
            </Typography>
            <Button
              className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl md:hidden mt-6"
              type="button"
              size="md"
              onClick={() => navigate("/user/enter-vin")}
            >
              <Typography className="font-bold" variant="body">
                {" "}
                Add Vehicle/Create Listing
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
