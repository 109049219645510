import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Image,
} from "@nextui-org/react";
import "./archived-and-sold.scss";
import Button from "modules/shared/components/button/Button";
import Input from "components/ui/input";
import editIcon from "../assets/svg/edit-icon.svg";
import viewIcon from "../assets/svg/view-icon.svg";
import searchIcon from "../assets/svg/search-icon-gray.svg";
import {
  useGetListingArchiveAndSold,
  useGetListingArchiveAndSoldSearch,
} from "services/listings.service";
import Loading from "components/Loading/Loading";
import { money } from "utils/money";
import {
  changeMileageForManageList,
  changeVinNumberForManageList,
} from "modules/dealer/store/manage-list";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography } from "components";
import { getUserId } from "utils/get-user-id";
import SearchCarCard from "modules/shared/components/car-card/SearchCarCard";
import { env } from "config/env";

export default function ArchivedAndSoldPage() {
  const userId = getUserId();
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [searchCars, setSearchCars] = useState<any[]>([]);
  const {
    data: listArchive,
    refetch: refetchList,
    isRefetching: isListRefetching,
  } = useGetListingArchiveAndSold(userId, 40, page, selectedType);
  const {
    data: listSearchArchive,
    refetch: refetchSearch,
    isRefetching: isSearchRefetching,
  } = useGetListingArchiveAndSoldSearch(searchValue, selectedType);
  const infiniteScroll = useRef<HTMLDivElement>(null);

  const columns = [
    { name: "VIN", uid: "vin" },
    { name: "Year", uid: "year" },
    { name: "Make", uid: "make" },
    { name: "Model", uid: "model" },
    { name: "Price", uid: "price" },
    { name: "Action", uid: "action" },
  ];

  useEffect(() => {
    if (searchValue.length > 0) {
      refetchSearch();
    }
  }, [searchValue, selectedType]);

  useEffect(() => {
    if (!searchValue.length) {
      refetchList();
    }
  }, [selectedType, page]);

  useEffect(() => {
    const newCars = (listArchive as any[]) || [];
    const oldCars = Array.isArray(searchCars) ? searchCars : [];
    const uniqueCars = Array.from(new Set([...oldCars, ...newCars]));
    setSearchCars(uniqueCars);
  }, [listArchive, page]);

  useEffect(() => {
    setSearchCars(listSearchArchive as any[]);
  }, [listSearchArchive]);

  useEffect(() => {
    setSearchCars(listArchive as any[]);
    return () => {
      refetchList();
    };
  }, []);
  const editListing = (vinNumber: string, listingId: number) => {
    dispatch(changeVinNumberForManageList(vinNumber || null));
    dispatch(changeMileageForManageList(200000));
    window.open(
      `/#/dealer/manage-listing/${vinNumber}/200000/${listingId}/`,
      "_blank"
    );
  };
  const viewListing = (listingId: number) => {
    window.open(`/#/listing/view-listing/${listingId}/`, "_blank");
  };

  const handleScroll = () => {
    if (
      infiniteScroll.current &&
      window.innerHeight + window.scrollY >=
        infiniteScroll.current.offsetTop + infiniteScroll.current.clientHeight
    ) {
      let count = page + 1;
      setPage(count);
      refetchList();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col">
        <Typography variant="pageTitle" className="pb-[64px]">
          Sold and Archived
        </Typography>

        <div className="md:flex items-center justify-between gap-3">
          <div className="flex gap-3">
            <div className="w-full flex items-center mb-4 md:mb-0">
              <Button
                name="Archived"
                className={`
                    ${
                      selectedType === 0 ? "selected" : "default"
                    }  text-[15px] lg:text-[20px] px-5  text-center  rounded-xl w-40
                `}
                onClick={() => {
                  setSearchCars([]);
                  setSelectedType(0);
                }}
              />
              <div className="flex items-center justify-center px-4 md:px-5 h-[54px]">
                <Button
                  name="Sold"
                  className={`${
                    selectedType === 2 ? "selected" : "default"
                  } text-[15px] lg:text-[20px] px-5 text-center  rounded-xl w-40`}
                  onClick={() => {
                    setSearchCars([]);
                    setSelectedType(2);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:flex justify-between input-container">
            <div></div>
            <Input
              variant="custom"
              className="w-full min-[1312px]:w-[400px] "
              classNames={{ inputWrapper: "h-[54px] ", input:"placeholder:px-0", base:"placeholder:px-0" }}
              placeholder="Search Keywords"
              onKeyPress={(event: any) => {
                const search = event.target.value;
                if (event.key === "Enter") {
                  setSearchValue(search);
                }
              }}
              startContent={
                <Image
                  shadow="none"
                  removeWrapper
                  radius="lg"
                  className="w-4 h-4 lg:w-7 lg:h-7 cursor-pointer"
                  src={searchIcon}
                />
              }
            />
          </div>
        </div>
      </div>
    );
  }, [selectedType]);

  const renderCell = React.useCallback((car: any, columnKey: any) => {
    switch (columnKey) {
      case "vin":
        return <span className="uppercase">{car.vin}</span>;
      case "year":
        return <span>{car.year ?? "2024"}</span>;
      case "make":
        return <span className="capitalize">{car.make.toLowerCase()}</span>;
      case "model":
        return <span className="capitalize">{car.model.toLowerCase()}</span>;
      case "price":
        return <span>${money(car.price)}</span>;
      case "action":
        return (
          <div className="relative flex items-center justify-center">
            <Image
              alt="Card background"
              shadow="none"
              removeWrapper
              radius="lg"
              className="w-5 h-5 lg:w-10 lg:h-10 cursor-pointer"
              src={viewIcon}
              onClick={() => viewListing(car.listingId)}
            />
            <div className="p-0 lg:pl-3">
              <Image
                alt="Card background"
                shadow="none"
                removeWrapper
                radius="lg"
                className="w-5 h-5 lg:w-10 lg:h-10 cursor-pointer"
                src={editIcon}
                onClick={() => editListing(car.vin, car.listingId)}
              />
            </div>
          </div>
        );
      default:
        return car[columnKey];
    }
  }, []);

  return (
    <div>
      {isSearchRefetching && <Loading />}
      <div className="p-0">
        {topContent}
        {/* <Table
          aria-label="table with dynamic content border-none"
          shadow={"none"}
          radius="none"
          border={0}
          classNames={{
            thead: "[&>tr:last-child]:hidden",
            wrapper: "p-0",
            table: "bg-[#070C84] rounded-t-xl border-0",
            td: "bg-white",
          }}
          topContent={topContent}
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn
                className={`font-bold text-xs md:text-md xl:text-xl text-white bg-[#070C84] rounded-t-xl ${
                  column.uid !== "action" && "border-r border-r-gray-500"
                } p-3 text-center`}
                key={column.uid}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody items={searchCars ?? []} className="break-all">
            {(item) => (
              <TableRow key={item.listingId} className="text-center">
                {(columnKey) => (
                  <TableCell
                    className={`break-all text-xs md:text-sm xl:text-lg border-t-black/50 border-2  ${
                      columnKey === "action" ? "md:w-40" : ""
                    } `}
                  >
                    {renderCell(item, columnKey)}
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table> */}
        <div className="col-span-4  pb-20 overflow-y-auto mt-8">
          {searchCars && searchCars.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 5xl:grid-cols-6 gap-4">
              {searchCars?.map((car) => {
                return (
                  <SearchCarCard
                    car={car}
                    imgURl={`${env.BASE_API_URL}Listings/image/${car.listingId}`}
                    line1={`${car?.year || ""} ${car.make} ${car.model}`}
                    line2={`$${car?.price ? money(+car?.price) : "0"}`}
                    line3={`VIN - ${car.vin}`}
                    btnLabel={"soldAndArchived"}
                    onSelect={() => {
                      viewListing(car.listingId);
                    }}
                    onSelect2={() => {
                      editListing(car.vin, car.listingId);
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center mt-8">
              <Typography variant="subtitle" className="pb-16">
                No {selectedType === 0 ? "Archived" : "Sold"} data available
              </Typography>
            </div>
          )}
        </div>
      </div>
      {40 % (searchCars?.length ?? 0) === 0 && <div ref={infiniteScroll} />}
    </div>
  );
}
