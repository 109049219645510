import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FMO_logo from "./assets/FMO_logo.png";
import axios from "axios";
import { env } from "config/env";
const SendOTP: React.FC<{ isInModal?: boolean; handleNext?: () => void }> = ({
  isInModal = false,
  handleNext,
}) => {
  const [otp, setOtp] = useState("");
  const qoute = JSON.parse(localStorage.getItem("qoute") || "{}");
  const navigate = useNavigate();

  const handlePayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isInModal && handleNext) {
      handleNext();
    } else {
      const payload = {
        terrapass: false,
        insurance: {
          coverage: {
            amount: 0,
            currencyType: "USD",
          },
        },
        paymentMethodId: "src_1OEvIhLJiXQewpv8eLrQxTDa",
        postpay: false,
      };

      try {
        const response = await axios.post(
          `${env.BASE_API_URL}Shipping/AcceptRate?quoteId=${qoute.quoteId}`,
          payload
        );

        if (response.data.message === "Accepted") {
          navigate("/shipping-quotes/payment-success");
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };

  return (
    <div
      className={`flex flex-col items-center justify-center ${
        isInModal ? "" : "h-screen"
      }`}
    >
      <div className="mb-10">
        <img className="h-20 w-auto" src={FMO_logo} alt="" />
      </div>
      <div className="text-sm font-bold text-center mt-4">
        Payment Amount
        <div className="text-4xl font-bold text-center text-blue-900">
          ${qoute.calculatedPrices.ushipServiceCharge.value + qoute.price.value}
        </div>
      </div>
      <form className="w-full max-w-2xl py-10 px-4" onSubmit={handlePayment}>
        <div className="flex flex-col w-full mb-4">
          <label htmlFor="otp" className="mb-2 text-sm">
            Enter OTP
          </label>
          <input
            type="password"
            id="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="border-2 border-black rounded-xl px-3 py-3 focus:outline-none text-center"
            required
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-gradient-to-r from-[#018FFD] to-[#070C84] w-full text-white text-md font-bold py-3 px-4 rounded-xl focus:outline-none"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendOTP;
