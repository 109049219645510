import { useMutation, useQuery } from "@tanstack/react-query";
import { shouldRetryOnError } from "config/retryConfig";
import { ListingZipCodeDetailsResponse } from "services";
import { shippingEndpoint } from "services/endpoints/shipping-quotes/shipping.endpoint";
import {
  AddCardMethodPayload,
  CarShippignData,
  GetShippingDetailsResponse,
  PaymentCardInfo,
  SUB_KEY_TYPE_FOR_PAYMENT,
  ShippingByIDResponse,
  ShippingDetailsPayload,
} from "services/endpoints/shipping-quotes/shipping.type";
import { FormatedShippingResponseType } from "utils/shipping";

export const useGetShippingQuotesInfo = () => {
  return useMutation<
  FormatedShippingResponseType,
    Error,
    ShippingDetailsPayload & {
      vin: string;
      listingId: number;
      ship: ListingZipCodeDetailsResponse;
      dest: ListingZipCodeDetailsResponse;
      carYear: number;
      make: string;
      model: string;
    }
  >({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.GetShippingRates(payload);
      return res;
    },
  });
};


export const useGetShippingQuotesInfoPublic = () => {
  return useMutation<
  FormatedShippingResponseType,
    Error,
    ShippingDetailsPayload & {
      vin: string;
      listingId: number;
      ship: ListingZipCodeDetailsResponse;
      dest: ListingZipCodeDetailsResponse;
      carYear: number;
      make: string;
      model: string;
    }
  >({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.GetShippingRatesPublic(payload);
      return res;
    },
  });
};
// export const useQueryShippingQuotesInfo = (payload:ShippingDetailsPayload& {
//   vin: string;
//   listingId: number;
// }) => {
//   return useQuery<GetShippingDetailsResponse, Error>({
//     queryKey: ["shipping-api-rate"],
//     queryFn: async () => {
//       const res = await shippingEndpoint.GetShippingRates(payload);
//       return res;
//       },
//     enabled: !!payload,
//     refetchOnWindowFocus: false,
//     refetchOnMount: true,
//     refetchInterval: false,
//   });
// };

export const useSaveShippingQuotesInfo = () => {
  return useMutation<any, Error, string>({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.saveShippingQuotes(payload);
      return res;
    },
  });
};
export const useGetShippingQuotesById = () => {
  return useMutation<ShippingByIDResponse, Error, string>({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.getShippingQuotesById(payload);
      return res;
    },
  });
};

export const useAcceptPriceRateShipping = () => {
  return useMutation<any, Error, string>({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.AcceptPriceRateShipping(payload);
      return res;
    },
  });
};

export const useTransactionsCheckForPaymentMethod = () => {
  return useMutation<PaymentCardInfo, Error>({
    mutationFn: async () => {
      const res = await shippingEndpoint.TransactionsCheckForPaymentMethod();
      return res;
    },
  });
};

export const useGetCreditCardMethodStatusData = (
  enabled = false
) => {
  return useQuery<PaymentCardInfo, Error>({
    queryKey: ["credit-data"],
    queryFn: async () => {
      const res = await shippingEndpoint.TransactionsCheckForPaymentMethod();
      return res;
    },
    enabled: enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    retry: shouldRetryOnError
  });
};


export const useConfirmShippingPayment = () => {
  return useMutation<any, Error, string>({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.ConfirmShippingPayment(payload);
      return res;
    },
  });
};

export const useAddPaymenTransactiontMethod = () => {
  return useMutation<any, Error, AddCardMethodPayload>({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.AddPaymenTransactiontMethod(payload);
      return res;
    },
  });
};

export const useAddPaymentOptionForPublicUserMethod = (vin:string,sub_key:SUB_KEY_TYPE_FOR_PAYMENT) => {
  return useMutation<any, Error, AddCardMethodPayload>({
    mutationFn: async (payload) => {
      const res = await shippingEndpoint.AddPaymentOptionForPublicUserMethod(payload,vin,sub_key);
      return res;
    },
  });
};

export const useAddPaymenTransactiontMethodRegistration = () => {
  return useMutation<any, Error, AddCardMethodPayload>({
    mutationFn: async (payload) => {
      const res =
        await shippingEndpoint.AddPaymenTransactiontMethodRegistration(payload);
      return res;
    },
  });
};

export const useShippingOrderHistory = (
  page: number,
  updateState: (newData: CarShippignData[]) => void
) => {
  return useQuery<CarShippignData[], Error>({
    queryKey: ["shipping-order-data"],
    queryFn: async () => {
      const res = await shippingEndpoint.getShippingOrderHistory(page);
      updateState(res);
      return res;
    },
    enabled: !!page,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: false,
  });
};
