import { useQuery } from "@tanstack/react-query";
import { api } from "utils/api";
import { z } from "zod";

const CarListingApiSchema = z.array(
  z.object({
    userId: z.number(),
    listingId: z.number(),
    tenantId: z.number(),
    description: z.string(),
    name: z.string(),
    make: z.string(),
    model: z.string(),
    vin: z.string(),
    year: z.string().or(z.number()),
    // trim: z.string(),
    licensePlate: z.string().nullable(),
    price: z.number(),
    purchasePrice: z.number(),
    repairCost: z.number(),
    reconditioningCost: z.number(),
    actualSalePrice: z.number(),
    status: z.number(),
    mileage:z.string(),
    
    trim: z.string().or(z.number()),
  
    financingPrice: z.number(),
    downPayment: z.number(),
    interestRate: z.number(),
    financeType: z.number(),
    term: z.number(),
    monthlyPayment: z.number(),
  })
);

type CarListings = z.infer<typeof CarListingApiSchema>;

const fetchTodos = async (
  tenantId: number,
  status: number,
  page: number,
  pageSize: number
): Promise<CarListings> => {
  const response = await api
    .get(
      `Listings/GetList?tenantId=${tenantId}&status=${status}&page=${page}&pageSize=${pageSize}`
    )
    .json();

  // const validatedResponse = CarListingApiSchema.safeParse(response);

  // if (!validatedResponse.success) {
  //   throw new Error(
  //     `Failed to fetch todos. ${validatedResponse.error.message}`
  //   );
  // }
  const sortedData = (response as CarListings).sort((a, b) => {
    return b.listingId - a.listingId;
  });
  return sortedData;
};

export function useCarListingsQuery(
  tenantId: number,
  status: number,
  page: number,
  pageSize: number
) {
  return useQuery({
    queryKey: ["listings", tenantId],
    queryFn: () => fetchTodos(tenantId, status, page, pageSize),
    refetchOnWindowFocus:false
  });
}
