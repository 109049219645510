import React from "react";
import { useNavigate } from "react-router-dom";
import done_icon from "../../../../modules/dealer/features/shipping-quotes/assets/done-icon.png";

interface Props {
  buttonText?: string;
  navigatePath?: string;
  onClick?: () => void;
  activePlanType?: boolean;
}
const PaymentSuccessForVehicleContract: React.FC<Props> = ({
  buttonText = "Transit Status",
  navigatePath = "/shipping-quotes/transit-status",
  onClick,
  activePlanType
}) => {
  const navigate = useNavigate();
  const transitStatus = () => {
    onClick && onClick();
    if (navigatePath) {
      navigate(navigatePath);
    }
  };

  return (
    <>
      <div className="mb-12">
        <img
          className="m-auto h-56 w-56 animate-bounce"
          src={done_icon}
          alt=""
        />
        <div
          className="text-xl -mt-8 font-bold text-center text-blue-900"
          style={{ fontFamily: "Metropolis-Bold" }}
        >
          Payment Successful
        </div>
        <div
          className="text-4xl -mt-0 font-bold text-center text-blue-900"
          style={{ fontFamily: "Metropolis-Bold" }}
        >
          Thank You
        </div>


        <div
          className="flex justify-center mt-4 flex-col items-center"
          style={{ fontFamily: "Metropolis-SemiBold" }}
        >
          <div className="border-1  text-xl md:text-4xl border-black rounded-lg p-2 md:p-8 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
             <h2 className="text-center" >Your Active Plan is</h2>
             <h2 className="text-center" >{activePlanType?"3 year 10k miles/year $99/mo":"3 year 15k miles/year $129/mo"}</h2>

          </div>
          <button
            className="mt-12 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 w-2/5 text-white font-bold py-3 px-4  rounded-xl focus:outline-none"
            onClick={transitStatus}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessForVehicleContract;
