import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import Form from "components/ui/form";
import { Typography } from "components";
import { AutoLoanLeaseCalculatorSchema } from "../AutoLoanLeaseCalculatorSchema";
import Input from "components/ui/input";

const FORM_FIELDS_DATA = [
  {
    label: "Total Cost of Vehicle",
    name: "totalCostOfVehicle",
    disabled: false,
    icon: "$",
  },
  {
    label: "Sales Tax ( % )",
    name: "countrySalesTaxRate",
    disabled: false,
    icon: "%",
  },
  {
    label: "Enter Discounts, Rebates, Capital Cost Reduction",
    name: "discounts",
    disabled: false,
    icon: "$",
  },
  {
    label: "Estimated Down Payment",
    name: "estimatedDownPayment",
    disabled: false,
    icon: "$",
  },
  {
    label: "Interest Rate ( % )",
    name: "interestRate",
    disabled: false,
    icon: "%",
  },
  {
    label: "Loan Term ( months )",
    name: "loanTerm",
    disabled: false,
    icon: " ",
  },

  {
    label: "Monthly Payment",
    name: "estimatedMonthlyPayment",
    disabled: true,
    icon: "$",
  },
  {
    label: "Total Loan Amount",
    name: "totalLoanAmount",
    disabled: false,
    icon: "$",
  },
];

interface Props {
  handleCallBack: (e: any) => void;
}
export default function LoanCalculator(props: Props) {
  const [totalLoan, setTotalLoan] = useState(0);
  const [loanAndInterest, setLoanAndInterest] = useState(0);
  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState(0);

  const [currentLoanDetails, setCurrentLoanDetails] = useState({
    totalCostOfVehicle: 0,
    countrySalesTaxRate: 0,
    discounts: 0,
    estimatedDownPayment: 0,
    loanTerm: 0,
    interestRate: 0,
    monthlyPayment: 0,
  });
  useEffect(() => {
    const totalCostOfVehicle = Number(currentLoanDetails.totalCostOfVehicle);
    const discounts = Number(currentLoanDetails.discounts);
    const estimatedDownPayment = Number(
      currentLoanDetails.estimatedDownPayment
    );
    const loanTerm = Number(currentLoanDetails.loanTerm);
    const interestRate = Number(currentLoanDetails.interestRate);
    const countrySalesTaxRate = Number(currentLoanDetails.countrySalesTaxRate);

    const loanAmount = totalCostOfVehicle;

    const totalLoanAmountPlusInterest =
      loanAmount * (1 + countrySalesTaxRate / 100) -
      discounts -
      estimatedDownPayment;

    const monthlyInterestRate = interestRate / 100 / 12;
    setTotalLoan(totalLoanAmountPlusInterest);

    const monthlyPayment =
      (totalLoanAmountPlusInterest * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTerm));

    setLoanAndInterest(totalLoanAmountPlusInterest);

    setEstimatedMonthlyPayment(monthlyPayment);
  }, [
    currentLoanDetails.discounts,
    currentLoanDetails.countrySalesTaxRate,
    currentLoanDetails.estimatedDownPayment,
    currentLoanDetails.interestRate,
    currentLoanDetails.loanTerm,
    currentLoanDetails.totalCostOfVehicle,
  ]);
  return (
    <div
      className="rounded-2xl  p-10 md:p-16 border-1 w-full xl:max-w-[950px]"
      style={{
        boxShadow: "5.65px 5.475px 19.65px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="flex items-center justify-center">
        <Typography
          variant="custom"
          className="text-center text-lg xl:text-3xl p-2  font-bold bg-gradient-to-r from-[#018ffd] to-[#070c84] w-full md:w-1/2 rounded-xl text-white "
        >
          Loan Calculator
        </Typography>
      </div>

      <Form
        schema={AutoLoanLeaseCalculatorSchema}
        onSubmit={(e) => {
          const data = {
            totalLoanAndInterest: totalLoan,
            loanTerm: e.loanTerm,
            interestRate: e.interestRate,
          };
          props.handleCallBack(data);
        }}
      >
        {({ formState: { errors, defaultValues }, register }) => (
          <div className="mt-10 grid gap-x-5 ">
            {FORM_FIELDS_DATA.map(({ name, label, disabled, icon }, idx) => {
              return (
                <div
                  key={idx}
                  className="md:flex items-center justify-around mb-5"
                >
                  <Typography
                    variant="textFieldLabel"
                    className={`pt-2 text-bold mr-5 w-full md:w-[200px] `}
                  >
                    <div>{label}</div>
                  </Typography>
                  {name !== "estimatedMonthlyPayment" &&
                  name !== "totalLoanAmount" ? (
                    <div>
                      <div className="flex items-center justify-center">
                        <Input
                          variant="calculator"
                          type="number"
                          disabled={disabled}
                          errorMessage={
                            errors[name as keyof typeof errors]?.message
                          }
                          startContent={
                            icon !== "%" && (
                              <div className="pointer-events-none flex items-center w-0">
                                <span>{icon}</span>
                              </div>
                            )
                          }
                          endContent={
                            icon === "%" && (
                              <div className="pointer-events-none flex items-center w-0">
                                <span>{icon}</span>
                              </div>
                            )
                          }
                          classNames={{
                            input:
                              icon === "$" || ""
                                ? "data-[has-start-content=true]:ps-3 data-[has-end-content=true]:ps-7 text-left"
                                : " data-[has-start-content=true]:ps-3 data-[has-end-content=true]:ps-7 -mr-1 text-right",
                            inputWrapper: !!errors[name as keyof typeof errors]
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          className="w-full md:w-[250px] align-end"
                          {...register(name as keyof typeof defaultValues)}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            if (
                              name === "estimatedDownPayment" &&
                              value > currentLoanDetails.totalCostOfVehicle
                            )
                              return (e.target.value = "0");
                            const data = {
                              ...currentLoanDetails,
                              [name]: value,
                            };
                            setCurrentLoanDetails(data);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full md:w-[250px]">
                      {name === "totalLoanAmount" && (
                        <div className="p-2 border-2 h-full pl-3 rounded-2xl min-h-14 min-h-12 border-2  w-full md:w-[250px] ">
                          <div className="pt-2 text-black/50">
                            <span className="-mr-1">$</span>{" "}
                            {loanAndInterest === 0
                              ? "0.00"
                              : loanAndInterest.toFixed(2)}
                          </div>
                        </div>
                      )}
                      {name === "estimatedMonthlyPayment" && (
                        <div className="p-2 border-2 h-full pl-3 rounded-2xl min-h-14 border-2 w-full md:w-[250px] ">
                          <div className="pt-2 text-black/50 w-full">
                            <span className="-mr-1">$</span>{" "}
                            {estimatedMonthlyPayment
                              ? estimatedMonthlyPayment.toFixed(2)
                              : "0.00"}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}

            <div className="flex items-center justify-center">
              <Button
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-xl w-full lg:w-1/2 py-6 tracking-wide xl:px-8 2xl:px-6 rounded-xl mt-5"
                type="submit"
                size="sm"
                isLoading={false}
              >
                <div>Generate Amortization Table</div>
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
}

// const FormControl = ({ children }: { children: React.ReactNode }) => {
//   return (
//     <div className="flex items-center justify-center px-10">
//       <div className=" mb-5   w-full ">{children}</div>
//     </div>
//   );
// };
