import { Typography } from "components/typography";
import { SearchOrderHistory } from "./search-order-history.component";
import { ListOrderHistory } from "./list-order-history.component";
import { useGetDmvOrderHistoryAsync } from "services/tiling.service";
import Loading from "components/Loading/Loading";

export const OrderHistoryDmvPage = () => {
  const { data, isLoading } = useGetDmvOrderHistoryAsync();

  if (!data && isLoading) return <Loading />;
  return (
    <div className="grid grid-cols-1 pb-3 w-full h-full">
      <div className="flex flex-col gap-7 items-center justify-center mb-10">
        <Typography variant="pageTitle">Order History</Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 items-end justify-end mb-5 md:justify-items-center xl:justify-items-end">
        <SearchOrderHistory />
      </div>
      <div>
        <ListOrderHistory data={data ?? []} />
      </div>
    </div>
  );
};
