import {
  DateTextField2,
  SelectNextUi,
  TextErrorHelper,
  Typography,
} from "components";
import Input from "components/ui/input";
import { formatDateToShortDate } from "utils";

const vehicleType = [
  {
    label: "Passenger Vehicle",
    value: "PV",
  },
  {
    label: "Pickup Truck",
    value: "LT",
  },
  {
    label: "Motorcycle",
    value: "M",
  },
  { label: "Recreational Vehicle", value: "RV" },
  { label: "SUV", value: "UV" },
  { label: "5th Wheel", value: "T" },
  { label: "Cab & Chassis", value: "CC" },
  { label: "Delivery Vehicle", value: "DV" },
  { label: "Other", value: "O" },
];

const transType = [
  {
    label: "Lease",
    value: "Lease",
  },
  {
    label: "Purchase",
    value: "Purchase",
  },
  {
    label: "Title-Only",
    value: "Title-Only",
  },
  {
    label: "State Transfer",
    value: "State Transfer",
  },
  {
    label: "Duplicate",
    value: "Duplicate",
  },
  {
    label: "Lease Buyout",
    value: "Lease Buyout",
  },
];

interface RequiredDetailsProps {
  register: any;
  errors: any;
  vehiclePrice: number;
}
export const RequiredDetails: React.FC<RequiredDetailsProps> = ({
  register,
  errors,
  vehiclePrice,
}) => {
  return (
    <>
      {/* <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Transaction Date
        </Typography>
        <div className="col-span-3 flex flex-col">
          <DateTextField2
            aria-label="Select Transaction Date"
            onChange={(event) => {
              const date = new Date(event.target.value);
              register("transDt").onChange({
                target: {
                  name: "transDt",
                  value: formatDateToShortDate(date),
                },
              });
            }}
          />
          <TextErrorHelper className="ml-1">
            {errors.errors.transDt?.message && "Transaction Date is required"}
          </TextErrorHelper>
        </div>
      </FormControl> */}
      {/* <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Uploaded date
        </Typography>
        <div className="col-span-3 flex flex-col">
          <DateTextField2
            aria-label="Select Uploaded Date"
            onChange={(event) => {
              const date = new Date(event.target.value);
              register("uploadedDate").onChange({
                target: {
                  name: "uploadedDate",
                  value: formatDateToShortDate(date),
                },
              });
            }}
          />
          <TextErrorHelper className="ml-1">
            {errors.errors.uploadedDate?.message && "Uploaded Date is required"}
          </TextErrorHelper>
        </div>
      </FormControl> */}
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Transfer Type
        </Typography>
        <div className="col-span-3 flex flex-col gap-2">
          <SelectNextUi
            placeholder="Select Transfer Type"
            className="col-span-3"
            aria-label="Select Transfer Type"
            data={transType}
            register={register("transType")}
            portalId="dmvformdatails"
          />
          <TextErrorHelper className="ml-1">
            {errors.errors.transType?.message}
          </TextErrorHelper>
        </div>
      </FormControl>
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Vehicle Type
        </Typography>
        <div className="col-span-3 flex flex-col gap-2">
          <SelectNextUi
            placeholder="Select Vehicle Type"
            className="col-span-3"
            aria-label="Select Vehicle Type"
            data={vehicleType}
            register={register("vehicleType")}
            portalId="dmvformdatails"
          />
          <TextErrorHelper className="ml-1">
            {errors.errors.vehicleType?.message}
          </TextErrorHelper>
        </div>
      </FormControl>
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Vehicle Base Price
        </Typography>
        <div className="col-span-3 flex flex-col gap-2">
          <Input
            type="number"
            variant="form"
            size="lg"
            className="col-span-3"
            classNames={{
              input: "px-4 text-center",
            }}
            defaultValue={""}
            errorMessage={errors.errors.vehicleBasePrice?.message}
            {...register("vehicleBasePrice")}
          />
        </div>
      </FormControl>
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
