import { Typography } from "components/typography";
import { useNavigate } from "react-router-dom";

export const AppFooter = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  return (
    <div className="-mt-10 w-full flex justify-center items-center md:pl-[50px]">
      <div className="">
        <Typography
          variant="custom"
          className="text-xs text-[#263238] font-[500] mb-5"
        >
          © {year}{" "}
          <a
            onClick={() => navigate("")}
            className="text-[#018ffd] cursor-pointer"
          >
            findmyoptions.com
          </a>{" "}
          LLC, All Rights Reserved.
        </Typography>
      </div>
    </div>
  );
};
