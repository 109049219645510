import React, { useState } from "react";
import "./index.css";
import { Col, Row } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { Button, Card, Image } from "@nextui-org/react";
import { z } from "zod";

import Input from "components/ui/input";
import Form from "components/ui/form";
import {
  UserUpdateRequest,
  useGetDealerById,
  useGetUserProfilePicture,
  useGetZipCodeDetails,
  usePostUserProfilePicture,
  useUpdateDealerById,
} from "services";
import { getUserId } from "utils/get-user-id";
import { env } from "config/env";
import { DealerProfileSchema } from "./validation";
import Loading from "components/Loading/Loading";
import { Controller } from "react-hook-form";
import { formatPhoneNumber } from "utils";
import { useDebounceValue } from "usehooks-ts";
import { api } from "utils/api";
import { AddressInput } from "components";
import {
  changeUserInfo,
  changeUserName,
} from "modules/dealer/store/user-information";
import { useDispatch, useSelector } from "react-redux";
import { AddressMapIcon } from "assets/svgs/address-map.svg";
import { ZipcodeIcon } from "assets/svgs/zipcode-icon.svg";
import { EditProfileNameIcon } from "assets/svgs/edit-profile-name.svg";

export const DealerProfilePage: React.FC = () => {
  const userId = getUserId();
  const [editDetails, setEditDetails] = useState(false);
  const [place, setPlace] = React.useState({
    city: "",
    county: "",
    state: "",
    stateAbbr: "",
  });

  const { data: dealearData, isLoading: isLoadingDealerData } =
    useGetDealerById(userId);
  const dispatch = useDispatch();

  const [zipValue, setZipValue] = React.useState(dealearData?.zipCode || "");
  const [debouncedValue] = useDebounceValue(zipValue, 500);
  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
    isPending: isPendingToFindZipCode,
  } = useGetZipCodeDetails(debouncedValue);

  React.useEffect(() => {
    if(zipValue){

      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (zipcodeData && !isPendingToFindZipCode) {
      setPlace({
        city: zipcodeData?.city ?? "",
        county: zipcodeData?.county ?? "",
        stateAbbr: zipcodeData?.stateAbbr ?? "",
        state: zipcodeData?.state ?? "",
      });
    }
  }, [isPendingToFindZipCode, zipcodeData]);

  const { data: profilePictureData, isLoading: isLoadingProfilePicture } =
    useGetUserProfilePicture(userId);

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File[]>([]);
  const {
    mutateAsync: updateProfilePicMutateAsync,
    isPending: isPendingProfileUpload,
  } = usePostUserProfilePicture(userId);

  React.useEffect(() => {
    const uploadProfilePic = async () => {
      if (file.length > 0) {
        const formData = new FormData();
        formData.append("file", file[0]);
        await updateProfilePicMutateAsync(formData);
      }
    };
    uploadProfilePic();
  }, [file, updateProfilePicMutateAsync]);

  React.useEffect(()=>{
    if(dealearData?.zipCode){
      setZipValue(dealearData.zipCode)
    }
  },[dealearData?.zipCode])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFile = Array.from(event.target.files);
      setFile((prevFile) => [...prevFile, ...newFile]);
    }
  };

  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const {
    mutateAsync: updateDealerMutateAsync,
    isPending: isPendingUpdateDealear,
  } = useUpdateDealerById();

  const handleOnSubmit = async (
    formData: z.infer<typeof DealerProfileSchema>
  ) => {
    if ((dealearData?.zipCode !== zipValue && !zipcodeData) ){
       return
      };

    const payload: UserUpdateRequest = {
      id: userId,
      firstName: formData.firstName,
      username: formData.username || "",
      lastName: formData.lastName,
      email: formData.email,
      contactPerson: "",
      phoneNumber: formData.contactPerson,
      address: formData.address,
      licenseNumber: formData.licenseNumber,
      inventorySize: formData.inventorySize,
      city: place.city,
      state: place.state,
      // zipCode: formData.zipCode,
      // username: dealearData?.username || "",
      zipCode: zipValue,
      description: "",
      dateOfBirth: new Date().toISOString(),
      dealershipType: "",
      dealerWebsite: formData.dealerWebsite,
      legalBusinessName: formData.legalBusinessName,
    };
    await updateDealerMutateAsync(payload).then((res) => {
      dispatch(changeUserInfo(res));
      dispatch(changeUserName(formData.firstName));
    });
  };
  const userInfo = useSelector((state: any) => state.user.info);

  React.useEffect(() => {
    if (!isPendingUpdateDealear) {
      setEditDetails(false);
    }
  }, [isPendingUpdateDealear]);

  if (isLoadingDealerData) return <Loading />;

  console.log()
  return (
    <>
      <div className="flex">
        <Row gutter={[16, { xs: 16, sm: 16, md: 16, lg: 16 }]}></Row>
      </div>
      <div className="profile-container">
        <Form schema={DealerProfileSchema} onSubmit={handleOnSubmit}>
          {({
            register,
            formState: { errors, dirtyFields, isSubmitted, defaultValues },
            getValues,
            reset,
            control,
            setValue,
          }) => {
            console.log("error-------", errors);
            return (
              <Row gutter={[16, { xs: 16, sm: 16, md: 16, lg: 16 }]}>
                <div></div>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <div className="profile-image flex justify-center">
                    <Card>
                      <Image
                        className="z-0 h-[300px] max-h-[300px] min-w-[400px] bg-black/90 object-cover"
                        src={
                          file.length > 0
                            ? URL.createObjectURL(file[0])
                            : profilePictureData
                            ? `${env.BASE_API_URL}User/image/${userId}`
                            : "/assets/images/avatar/user.jpg"
                        }
                        alt="profile-image"
                        isLoading={
                          isPendingProfileUpload || isLoadingProfilePicture
                        }
                      />
                      <div className="upload-button absolute bottom-2 left-5">
                        <CameraOutlined onClick={onHandleFileClick} />
                      </div>
                    </Card>
                    <input
                      onChange={handleFileChange}
                      type="file"
                      style={{ display: "none" }}
                      className=""
                      ref={fileInputRef}
                    />
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={16}
                  xl={16}
                  className="mt-auto"
                >
                  <Row gutter={[16, { xs: 16, sm: 16, md: 16, lg: 16 }]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="">
                      <div className="absolute z-50 lg:bottom-20 text-center md:text-start w-full overflow-hidden whitespace-nowrap overflow-ellipsis text-xl md:text-4xl font-bold capitalize text-nowrap">
                          {userInfo?.firstName ?? dealearData?.firstName}{" "}{userInfo?.lastName ?? dealearData?.lastName}
                        </div>
                      <div className="mt-14 lg:mt-0">
                        <Button
                          type="submit"
                          className="custom-button"
                          isLoading={isPendingUpdateDealear}
                        >
                          Save
                        </Button>
                        <Button
                          type="button"
                          className="custom-button mt-2 block lg:hidden"
                          onClick={(e) => {
                            e.preventDefault();
                            reset(getValues());
                            setEditDetails(!editDetails);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="">
                      <p className="input">
                        Edit your profile. Please make sure that your legal
                        information is accurate for agreement purposes.
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                      className="hidden lg:block"
                    >
                      <Button
                        type="button"
                        className="custom-button"
                        onClick={(e) => {
                          e.preventDefault();
                          reset(getValues());
                          setEditDetails(!editDetails);
                        }}
                      >
                        Edit
                      </Button>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                      className="px-0"
                    >
                      <Input
                        startContent={
                          <Image
                            src="/assets/icons/dealer-inventory-count.svg"
                            className="w-[20px] h-[20px]"
                            removeWrapper
                            radius="none"
                          />
                        }
                        size="lg"
                        isDisabled={!editDetails}
                        isInvalid={!!errors?.inventorySize?.message}
                        placeholder="Average Inventory Count"
                        errorMessage={errors?.inventorySize?.message}
                        defaultValue={dealearData?.inventorySize || ""}
                        {...register("inventorySize")}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-user.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    placeholder="First Name"
                    isDisabled={!editDetails}
                    isInvalid={!!errors?.firstName?.message}
                    errorMessage={errors?.firstName?.message}
                    defaultValue={dealearData?.firstName || ""}
                    {...register("firstName")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-user.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    isDisabled={!editDetails}
                    // placeholder="Middle Name"
                    // defaultValue={dealearData?.middleName || ""}
                    // {...register("middleName")}
                    placeholder="Last Name"
                    defaultValue={dealearData?.lastName || ""}
                    isInvalid={!!errors?.lastName?.message}
                    errorMessage={errors?.lastName?.message}
                    {...register("lastName")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <EditProfileNameIcon
                        className="w-[20px] h-[20px]"
                      />
                    }
                    size="lg"
                    isDisabled={!editDetails}
                    placeholder="Profile Name"
                    isInvalid={!!errors?.username?.message}
                    errorMessage={errors?.username?.message}
                    defaultValue={dealearData?.username || ""}
                    {...register("username")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-email.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    type="email"
                    isDisabled={!editDetails}
                    placeholder="sample@gmail.com"
                    isInvalid={!!errors?.email?.message}
                    errorMessage={errors?.email?.message}
                    defaultValue={dealearData?.email || ""}
                    {...register("email")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Controller
                    name="contactPerson"
                    control={control}
                    defaultValue={dealearData?.phoneNumber || ""}
                    render={({ field }) => (
                      <Input
                        startContent={
                          <Image
                            src="/assets/icons/dealer-phone.svg"
                            className="w-[20px] h-[20px]"
                            removeWrapper
                            radius="none"
                          />
                        }
                        size="lg"
                        isDisabled={!editDetails}
                        placeholder="+1 (000) 000 0000"
                        maxLength={14}
                        isInvalid={!!errors?.contactPerson?.message}
                        errorMessage={errors?.contactPerson?.message}
                        defaultValue={dealearData?.contactPerson || ""}
                        {...field}
                        onChange={(e) => {
                          formatPhoneNumber(e.target.value, field);
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-website.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    isDisabled={!editDetails}
                    placeholder="Dealer Website"
                    isInvalid={!!errors?.dealerWebsite?.message}
                    errorMessage={errors?.dealerWebsite?.message}
                    defaultValue={dealearData?.dealerWebsite || ""}
                    {...register("dealerWebsite")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-legal-busineness.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    isDisabled={!editDetails}
                    placeholder="Legal Business Name"
                    isInvalid={!!errors?.legalBusinessName?.message}
                    errorMessage={errors?.legalBusinessName?.message}
                    defaultValue={dealearData?.legalBusinessName || ""}
                    {...register("legalBusinessName")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-driver-license.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    isDisabled={!editDetails}
                    placeholder="State Dealer License Number"
                    isInvalid={!!errors?.licenseNumber?.message}
                    errorMessage={errors?.licenseNumber?.message}
                    defaultValue={dealearData?.licenseNumber || ""}
                    {...register("licenseNumber")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <AddressInput
                    zipData={zipcodeData}
                    handleSelect={(value) => setValue("address", value)}
                    placeholder=""
                    InputComponent={(props) => {
                      return (
                        <Input
                          startContent={
                            <AddressMapIcon
                              className="w-[20px] h-[20px]"
                              // removeWrapper
                              // radius="none"
                            />
                          }
                          size="lg"
                          isDisabled={!editDetails}
                          placeholder="Address"
                          isInvalid={!!errors?.licenseNumber?.message}
                          errorMessage={errors?.address?.message}
                          defaultValue={dealearData?.address || ""}
                          value={getValues(
                            "address" as keyof typeof defaultValues
                          )}
                          {...register("address")}
                          onChange={(e) => {
                            props.onChange(e);

                            return register(
                              "address" as keyof typeof defaultValues
                            ).onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-city.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    isDisabled={true}
                    // isDisabled={!editDetails}
                    placeholder="City"
                    // errorMessage={errors?.city?.message}
                    isInvalid={!zipcodeData && isSubmitted}
                    errorMessage={
                      !zipcodeData && isSubmitted && "Enter Zip to get city"
                    }
                    value={place?.city || dealearData?.city || ""}
                    // defaultValue={dealearData?.city || ""}
                    // {...register("city")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <Image
                        src="/assets/icons/dealer-state.svg"
                        className="w-[20px] h-[20px]"
                        removeWrapper
                        radius="none"
                      />
                    }
                    size="lg"
                    isDisabled={true}
                    // isDisabled={!editDetails}
                    placeholder="State"
                    errorMessage={
                      !zipcodeData && isSubmitted && "Enter Zip to get state"
                    }
                    value={place?.state || dealearData?.state || ""}
                    // defaultValue={dealearData?.state  || place?.state || ""}
                    // {...register("state")}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <Input
                    startContent={
                      <ZipcodeIcon
                       className="w-[20px] h-[20px]"
                      />
                    }
                    size="lg"
                    isDisabled={!editDetails}
                    placeholder="ZIP Code"
                    // errorMessage={errors?.state?.message}
                    isInvalid={!zipcodeData && isSubmitted}
                    errorMessage={
                      !zipcodeData && isSubmitted && "ZIP Code is Invalid"
                    }
                    defaultValue={dealearData?.zipCode || ""}
                    // {...register("zipCode")}
                    // value={zipValue}
                    maxLength={5}
                    onChange={(e) => {
                      setZipValue(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            );
          }}
        </Form>
      </div>
    </>
  );
};
