import { Typography } from "components";

interface VehicleHistoryReportProps {
  data: any;
}
export const VehicleHistoryReport: React.FC<VehicleHistoryReportProps> = ({
  data,
}) => {
  return (
    <div className="grid grid-cols-1 max-h-[400px] overflow-y-auto">
      {data.length > 0 &&
        data.map((vin: any, idx: number) => {
          return (
            <div className="col-span-1 px-5" key={idx}>
              <div className="grid grid-cols-1 md:grid-cols-6 gap-1 md:gap-20 mb-5">
                <div className="col-span-2 md:mb-6">
                  <Typography
                    variant="custom"
                    className="gradient-text font-[600] text-medium"
                  >
                    {vin?.key ?? vin?.variable}
                  </Typography>
                </div>
                <div className="col-span-4 ">
                  <Typography
                    variant="custom"
                    className="font-[400] md:font-[500] text-xs md:text-sm ml-2 md:ml-0"
                  >
                    {vin?.value ? vin?.value : "-"}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
