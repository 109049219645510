import * as React from "react";
import { Card, Modal, ModalContent, Image } from "@nextui-org/react";

import { env } from "config/env";

interface ModalViewCarProps {
  isOpen: boolean;
  onOpen: () => void;
  cars: string[];
}
export const ModalViewCar: React.FC<ModalViewCarProps> = ({
  isOpen,
  onOpen,
  cars,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpen}
      scrollBehavior="outside"
      shadow="lg"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
        base: "mb-40",
      }}
      size="5xl"
    >
      <ModalContent>
        {(onClose) => {
          return cars.map((car, idx) => {
            return (
              <Card
                key={idx}
                className={`h-full xl:w-full`}
                radius="none"
                shadow="none"
                isHoverable
              >
                {idx === 0 && (
                  <div
                    className="flex justify-end z-50 absolute right-3 pb-6 w-16 h-16 cursor-pointer"
                    onClick={onClose}
                  >
                    <img
                      src="assets/icons/close-white.svg"
                      alt="close button"
                      width={20}
                      height={20}
                    />
                  </div>
                )}
                <Image
                  removeWrapper
                  radius="none"
                  alt="Relaxing app background"
                  className="z-0 w-full h-full object-cover"
                  src={`${env.BASE_API_URL}Listings/GetListingImage?fileName=${car}`}
                />
              </Card>
            );
          });
        }}
      </ModalContent>
    </Modal>
  );
};
