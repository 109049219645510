import { AddressInput, Typography } from "components";
import {
  dmvDocumentServiceSignal,
  enterMissingInfoDefaultSignal,
} from "../store";
import Input from "components/ui/input";

interface BuyerOneDetailsProps {
  register: any;
  errors: any;
  setValue: any;
  getValues: any;
}
export const BuyerOneDetails: React.FC<BuyerOneDetailsProps> = ({
  register,
  errors,
  setValue,
  getValues,
}) => {
  return (
    <>
      <Typography
        variant="custom"
        className="font-[800] text-[20px] text-[#313131] my-10"
      >
        Buyer 1 Details:
      </Typography>
      {enterMissingInfoDefaultSignal.value.buyerOneDetails.map((field, idx) => {
        return (
          <FormControl key={`${field}-${idx}`}>
            <Typography
              variant="custom"
              className="text-[#263238] font-[600] text-left col-span-2"
            >
              {field.label}
            </Typography>
            {["fbAddress", "fbAddress2"].includes(field.name) ? (
              <div className="col-span-3">
                <AddressInput
                  // zipData={zipcodeData}
                  handleSelect={(value) =>
                    setValue(`buyerOneDetails.${field.name}`, value)
                  }
                  placeholder=""
                  InputComponent={(props) => {
                    return (
                      <Input
                        variant="form"
                        size="lg"
                        className="col-span-3"
                        classNames={{
                          input: "px-4 text-center",
                        }}
                        defaultValue={
                          (dmvDocumentServiceSignal.value as any)?.[
                            field.name
                          ] ?? ""
                        }
                        errorMessage={
                          (errors.errors.buyerOneDetails as any)?.[field.name]
                            ?.message
                        }
                        {...register(`buyerOneDetails.${field.name}` as any)}
                        value={getValues(
                          `buyerOneDetails.${field.name}` as any
                        )}
                        onChange={(e) => {
                          props.onChange(e);
                          setValue(
                            `buyerOneDetails.${field.name}`,
                            e.target.value
                          );
                        }}
                      />
                    );
                  }}
                />
              </div>
            ) : (
              <Input
                variant="form"
                size="lg"
                className="col-span-3"
                classNames={{
                  input: "px-4 text-center",
                }}
                defaultValue={
                  (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
                }
                errorMessage={
                  (errors.errors.buyerOneDetails as any)?.[field.name]?.message
                }
                {...register(`buyerOneDetails.${field.name}` as any)}
              />
            )}
          </FormControl>
        );
      })}
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};

// {field.name === "fbzip" ? (
//   <Input
//     variant="form"
//     size="lg"
//     className="col-span-3"
//     classNames={{
//       input: "px-4 text-center",
//     }}
//     defaultValue={dmvDocumentServiceSignal.value?.fbzip ?? ""}
//     errorMessage={errors.errors.buyerOneDetails?.zipcode?.message}
//     {...register("buyerOneDetails.zipcode")}
//     onChange={(e) => setZipValueBuyerOne(e.target.value)}
//   />
// ) : field.name === "fbCity" || field.name === "fbState" ? (
//   <Input
//     isReadOnly
//     variant="form"
//     size="lg"
//     className="col-span-3"
//     classNames={{
//       input: "px-4 text-center",
//     }}
//     defaultValue={
//       (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
//     }
//     errorMessage={
//       (errors.errors.buyerOneDetails as any)?.[field.name]?.message
//     }
//     {...register(`buyerOneDetails.${field.name}` as any)}
//   />
// ) : (
//   <Input
//     variant="form"
//     size="lg"
//     className="col-span-3"
//     classNames={{
//       input: "px-4 text-center",
//     }}
//     defaultValue={
//       (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
//     }
//     errorMessage={
//       (errors.errors.buyerOneDetails as any)?.[field.name]?.message
//     }
//     {...register(`buyerOneDetails.${field.name}` as any)}
//   />
// )}
