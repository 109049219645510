import apiClient from "modules/dealer/utils/apiClient";

export default async function GetPriceData(
  vin: string,
  period?: number,
  mileage?: number
) {
  return await apiClient
    .get(
      `/Listings/GetPriceData?vinNumber=${vin}&mileage=${mileage}&period=${period}`,
      {
        headers: {
          Accept: "*/*",
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      console.log({ err });
    });
}
