import { Typography } from "components";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import {
  BenefitsOfPreApproval,
} from "./pre-approval.data";
import ManPreApprovaingCar from "./assets/Car-preapproveal-bg.png";
import DealerExplainingPreloan from "./assets/car-dealer-explaining-pre-loan.png";

import HowtoGetPreApproved from "./components/cards.component";
import { Button, Card } from "@nextui-org/react";
import { WhiteCheckTick } from "../traditional-financing/assets/white-check-tick.icon";
import { useNavigate } from "react-router-dom";

function PreApproval() {
  const navigate = useNavigate()

  const handleCreateAccount = () =>{
    navigate("/login/user")
  }
  return (
    <div className="flex flex-col gap-16 py-12 ">
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        <Typography variant="pageTitle">Pre-Approval</Typography>
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[rgb(7,12,132)] text-transparent bg-clip-text">
          Get Pre-Approved for Your New Car Today!
        </h2>
        <p className="font-[Metropolis] font-[600] text-left">
          Pre-approval is a powerful tool that can simplify your car-buying
          journey. We created a quick and convenient pre-approval process for
          traditional financing, and BHPH loans, empowering you to shop with
          confidence.
        </p>
      </div>
      <div>
        <div className="flex flex-col items-center justify-center mb-16 sm:mb-24 text-center">
          <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
            Types of Pre-Approvals:
          </h2>
        </div>

        <div className=" relative  ">
          <Card className="w-full sm:w-[75%] h-full min-h-[25rem] cursor-default	 relative items-left px-8 py-6 text-left bg-gradient-to-b from-[#018ffd] to-[#070c84] text-white gap-4 ">
            <div className="w-full sm:w-[60%] 3xl:w-[75%]">

            <div className="flex items-start   relative">
              <WhiteCheckTick
                height={16}
                width={16}
                className="absolute top-1"
                />

              <Typography
                variant="custom"
                className={`px-6  text-white font-light text-xl`}
                >
                <span className="font-semibold text-white bg-clip-text">
                  {"Traditional Financing: "}
                </span>
                <span className="font-[300] text-lg">
                  For approvals through Banks, Credit Unions, and Finance
                  Lenders, create an account and search for a vehicle, then
                  click save listing, that listing will show up in your account
                  under saved vehicles in your Garage, then you can click Apply
                  for Financing
                </span>
              </Typography>
            </div>

            <div className="flex items-start  mt-12 relative">
              <WhiteCheckTick
                height={16}
                width={16}
                className="absolute top-1"
                />

              <Typography
                variant="custom"
                className={`px-6  text-white font-light text-xl`}
                >
                <span className="font-semibold text-white bg-clip-text">
                  {"In House Financing: "}
                </span>
                <span className="font-[300] text-lg">
                  For approvals directly from one of our approved dealerships,
                  the process is the same as traditional financing except when
                  you click Apply for Financing, make sure to click In House
                  Financing.
                </span>
              </Typography>
            </div>
          </div>
          </Card>
          <img
            alt="dealer-explaining"
            src={DealerExplainingPreloan}
            className="w-1/2 hidden sm:block absolute right-0 -top-16 max-w-[32rem] "
            />
        </div>
      </div>

      <Typography variant="custom" className=" ">
        <p className="pb-5 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl 2xl:text-3xl font-bold pt-5 text-left">
          Benefits of Pre-Approval:
        </p>
        {BenefitsOfPreApproval.map((res) => (
          <div className="flex items-start  ">
            <img
              src={checkIcon}
              height={16}
              alt="Y"
              width={16}
              className="mt-3.5"
            />

            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {res.name}
              </span>
              {res.description}
            </p>
          </div>
        ))}
      </Typography>

      <HowtoGetPreApproved />

    

      <div className="w-full h-96 my-12">
        <div className=" flex absolute w-full h-96 right-0 from-[#018ffd] -z-10 to-[#070c84] text-white bg-gradient-to-r">
          <div
            style={{
              backgroundImage: `url(${ManPreApprovaingCar})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className=" w-full absolute  right-0 h-96 "
          >
            <div className="w-full  flex items-center justify-center text-center ">
              <Typography variant="custom" className="text-white my-auto px-4 mt-24">
                <h1 className="text-lg md:text-2xl font-bold mb-4 mt-16">
                  Pre-Approval Doesn't Mean You're Locked In:
                </h1>

                <p className="text-center">
                  Pre-approval is not a commitment to finance a car through a
                  dealership. It simply gives you a clear picture of your
                  financing options and allows you to shop with confidence.
                </p>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-col items-center justify-center gap-4 text-center text-lg font-[Metropolis]">
          <Typography variant="pageTitle" className="text-left">
            Get Pre-Approved Today!
          </Typography>

          <p className="font-[Metropolis] font-[600] text-left">
            Ready to take the first step towards your new car? Get pre-approved
            for traditional financing.
          </p>
        </div>
        <div className="flex mt-6 items-center justify-center  ">
          <Button onClick={handleCreateAccount} className="text-center  text-xs sm:text-lg md:text-xl font-[700] bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text border-[#070C84]  border-2">
            Create an Account to get Pre-Approved today!
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PreApproval;
