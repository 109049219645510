import React, { useState, useRef, useEffect } from "react";
import CloseIcon from "../../../shared/components/CloseIcon";
import BackIcon from "../../../shared/components/BackIcon";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import axios from "axios";
import Input from "components/ui/input";
import Textarea from "components/ui/textarea";
import Form from "components/ui/form";
import { CreateTicketSchema } from "schemas/ticket.schema";
import { env } from "config/env";
import { useSelector } from "react-redux";
const Ticket = ({ setCurrScreen, handleScreenChange }) => {
  const userInfo = useSelector((state) => state.user.info);
  const [formData, setFormData] = useState({
    subject: "",
    email: userInfo?.email||"" ,
    phone: userInfo?.phoneNumber||"",
    description: "",
  });
  const [success, setSuccess] = useState(false);

  const ticketContentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      ticketContentRef.current &&
      !ticketContentRef.current.contains(event.target)
    ) {
      handleScreenChange();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ticketContentRef, handleScreenChange]);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${env.BASE_API_URL}Support/SupportRequest`,
        formData
      );
      console.log("Response:", response.data);
      setSuccess(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const [errors] = useState({});
  return (
    <div className="flex ">
      <div
        ref={ticketContentRef}
        id="ticket-content"
        className="w-full md:w-[400px] bg-white bg-[#fff] min-[768px]:rounded-[25px] m-0"
      >
        <div className="relative flex md:h-[70px] grad min-[768px]:rounded-t-[25px] ">
          <div className="ml-[9px] mt-[15px] md:ml-[39px] md:mt-[39px] "></div>

          <div className="max-[767px]:p-4 md:p-0 md:ml-[-45px] flex items-center justify-center w-full text-[15px] max-[767px]:text-[20px] md:text-[20px] font-extrabold text-white gap-2 max-[767px]:gap-1 ">
            <span className="">
              <img
                className=" w-[20px] h-[20px] md:w-[32px] md:h-[32px] max-[767px]:w-[32px] max-[767px]:h-[32px]"
                src={process.env.PUBLIC_URL + "/assets/icons/ticket.svg"}
                alt=""
              />
            </span>
            <div>Create a Ticket</div>
          </div>
          <div className="absolute right-0 mr-[20px] mt-[26px] ">
            <div
              className="inline-block w-[15px] h-[15px]  relative cursor-pointer"
              onClick={handleScreenChange}
            >
              <CloseIcon stroke="#FFF" strokeWidth="4.028" className="" />
            </div>
          </div>
        </div>
        {success ? (
          <div className="overflow-y-auto mt-[0px] h-[450px] rounded-b-[25px] flex flex-col justify-center items-center w-full text-white bg-gradient-to-r from-blue-500 to-indigo-700">
            <div className="flex items-center justify-center text-white text-[150px] -mt-10 max-[767px]:text-[120px]">
              <IoIosCheckmarkCircleOutline />
            </div>
            <div className="relative text-[22px] text-center w-2/3 text-white mt-8 font-[Metropolis-Bold]">
              Your ticket has been raised. We'll get back to you shortly.
            </div>
          </div>
        ) : (
          <div className="overflow-y-auto max-h-[70vh] pb-1">
            <Form schema={CreateTicketSchema} onSubmit={handleSubmit}>
              {({ register, formState: { errors } }) => (
                <div className="pb-[80px]">
                  <div className="flex flex-col gap-5 my-5 w-full overflow-hidden">
                    <div className=" relative  w-full">
                      <div className="relative flex items-center mx-[5%] w-full h-full">
                        <Input
                          variant="ticket"
                          type="text"
                          classNames={{
                            input: "data-[has-start-content=true]:ps-12",
                            inputWrapper: !!errors.subject
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          placeholder="Subject"
                          startContent={
                            <span
                              className={`absolute pl-4 ${
                                errors?.subject?.length > 0
                                  ? "pb-3 md:pb-0"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/subject.svg"
                                }
                                className="md:h-[20px] md:w-[20px]"
                                alt="car"
                              />
                            </span>
                          }
                          value={formData.subject}
                          name="subject"
                          errorMessage={errors?.subject?.message}
                          {...register("subject")}
                          isInvalid={!!errors.subject}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="relative  w-full">
                      <div className="relative flex items-center mx-[5%] w-full h-full">
                        <Input
                          variant="ticket"
                          type="text"
                          classNames={{
                            input: "data-[has-start-content=true]:ps-12",
                            inputWrapper: !!errors.email
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          placeholder="Email"
                          startContent={
                            <span
                              className={`absolute pl-4 ${
                                errors?.email?.length > 0 ? "pb-3 md:pb-0" : ""
                              }`}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/email-gray.svg"
                                }
                                className="md:h-[20px] md:w-[20px]"
                                alt="car"
                              />
                            </span>
                          }
                          value={formData.email}
                          name="email"
                          errorMessage={errors?.email?.message}
                          {...register("email")}
                          isInvalid={!!errors.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className=" relative  w-full">
                      <div className="relative flex items-center mx-[5%] w-full h-full">
                        <Input
                          variant="ticket"
                          type="text"
                          classNames={{
                            input: "data-[has-start-content=true]:ps-12",
                            inputWrapper: !!errors.phone
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          placeholder="Phone"
                          startContent={
                            <span
                              className={`absolute pl-4  ${
                                errors?.phone?.length > 0 ? "pb-3 md:pb-0" : ""
                              }`}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/phone-gray.svg"
                                }
                                className="md:h-[20px] md:w-[20px]"
                                alt="car"
                              />
                            </span>
                          }
                          value={formData.phone}
                          name="phone"
                          errorMessage={errors?.phone?.message}
                          {...register("phone")}
                          onChange={handleInputChange}
                          isInvalid={!!errors.phone}
                        />
                      </div>
                    </div>

                    <div className="relative  w-full">
                      <div className="relative flex items-center mx-[5%] w-full h-full">
                        <Textarea
                          rows={6}
                          cols={50}
                          minRows={6}
                          maxRows={6}
                          variant="ticket"
                          type="text"
                          classNames={{
                            input: "data-[has-start-content=true]:ps-12",
                            inputWrapper: !!errors.description
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          placeholder="Description"
                          startContent={
                            <span
                              className={`absolute top-[10px] pl-4  ${
                                errors?.description?.length > 0
                                  ? "pb-3 md:pb-0"
                                  : ""
                              }`}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/description.svg"
                                }
                                className="md:h-[20px] md:w-[20px]"
                                alt="car"
                              />
                            </span>
                          }
                          errorMessage={errors?.description?.message}
                          value={formData.description}
                          name="description"
                          isInvalid={!!errors.description}
                          {...register("description")}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {!success && (
                    <div className="absolute bottom-10 left-0 right-0 flex justify-center">
                      <button
                        type="submit"
                        className="md:mt-[0px] text-center text-white w-[90%] h-[50px] md:h-[60px]  md:text-[15px] font-bold  rounded-[12px] md:rounded-[18px] chatsuppbtn "
                      >
                        Send
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ticket;
