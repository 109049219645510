import { Button } from "@nextui-org/react";
import { Typography } from "components";
import { useRef, useState } from "react";
import BhphfContractOne from "./contract-1-bhphf";
import BhphfContractTwo from "./contract-2-bhphf";
import BhphfContractThree from "./contract-3-bhphf";
import BhphfContractFour from "./contract-4-bhphf";
import BhphfContractFive from "./contract-5-bhphf";
import BhphfContractSixSales from "./contract-6-bhphf";

export const ShowBhphAppovalFlow = ({
  makeModalBig,
}: {
  makeModalBig: () => void;
}) => {
  const [step, setStep] = useState<string | null>(null);
  const validationMessageRef = useRef<null | HTMLDivElement>(null);
  const handleNextContract = (idx: string ) =>{
    setStep(idx);
    validationMessageRef.current?.scrollIntoView({
      block: "nearest",
    });
  }

  
  return (
    <div>
      <div ref={validationMessageRef} />
      {(() => {
        switch (step) {
          case "1": {
            return (
              <BhphfContractOne
                handleContinue={() =>handleNextContract("2") }
              />
            );
          }
          case "2": {
            return (
              <BhphfContractTwo
                handleContinue={() =>handleNextContract("3")}
              />
            );
          }

          case "3": {
            return (
              <BhphfContractThree
                handleContinue={() => handleNextContract("4")}
              />
            );
          }

          case "4": {
            return (
              <BhphfContractFour
                handleContinue={() => handleNextContract("5")}
              />
            );
          }
          case "5": {
            return <BhphfContractFive handleContinue={() => handleNextContract("6")} />;
          }

          case "6": {
            return <BhphfContractSixSales handleContinue={() =>handleNextContract("7")} />;
          }
          default: {
            return (
              <div className="w-full px-4 md:px-20 py-32 flex flex-col items-center">
                <Typography
                  variant="custom"
                  className=" text-xl font-[Metropolis] font-semibold text-center"
                >
                  Approved
                </Typography>
                <div className="w-full max-w-52">
                  <Button
                    onClick={() => {
                      makeModalBig();
                      setStep("1");
                    }}
                    className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            );
          }
        }
      })()}
    </div>
  );
};
