// src/cometdConfig.js
import { CometD } from "cometd";

// Create a new instance of CometD
const accessToken =
  "00DDx0000008rlq!AREAQNW_SD.dNO1vnrXdLd2g1CwKfUYuXknikK0vjX74ip9puYgIdduj7oGAIxCPlAxp9toEPTVmD4duImb6iHIFTSihlQDV";

const cometd = new CometD();

cometd.configure({
  url: "https://findmyoptions--fmodev2.sandbox.my.salesforce.com/cometd/47.0",
  requestHeaders: { Authorization: `Bearer ${accessToken}` },
});

// Function to initialize CometD connection
export const initCometD = () => {
  // cometd.handshake((response) => {
  //   if (response.successful) {
  //     console.log("CometD connection established successfully");
  //     // callback();
  //   } else {
  //     console.log("Failed to establish CometD connection", response);
  //   }
  // });
};

// Function to subscribe to a channel
export const subscribeToChannel = (channel: any, callback: any) => {
  cometd.subscribe(channel, (message) => {
    callback(message);
  });
};

// Export cometd instance for other usages
export default cometd;
