import * as React from "react";
import { Image, Listbox, ListboxItem, Progress } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Typography } from "components";
import { useGetDocumentServiceQuotes } from "services/tiling.service";
import { dmvDocumentServiceSignal, selectedProcedureSignal } from "./store";
import Loading from "components/Loading/Loading";
import { removeHtmlTags } from "utils";
import { DocumentServiceQuotesResponse } from "services";

interface Props {
  showInternalSubmit: boolean;
}
export const DmvFeeAndViewPdfDocs = ({ showInternalSubmit }: Props) => {
  const navigate = useNavigate();
  const [dmvData, setDMVData] = React.useState<DocumentServiceQuotesResponse[]>(
    []
  );

  const params = useParams();
  const { mutateAsync, isPending, error } = useGetDocumentServiceQuotes(
    selectedProcedureSignal.value?.quoteId || "0",
    params?.listingId as string
  );

  React.useEffect(() => {
    const fetchATCFeeQuotes = async () => {
        try {
        if (selectedProcedureSignal.value?.quoteId) {
          const data = await mutateAsync(dmvDocumentServiceSignal.value as any);
          if (data) {
            setDMVData(data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
      fetchATCFeeQuotes();
  }, []);

  if (isPending) return <Loading />;

  return (
    // <>{dataDocumentQuotes?

    <>
      <Listbox
        aria-label="order-history-list"
        variant="light"
        disallowEmptySelection
        selectionMode="none"
        items={dmvData || []}
        classNames={{
          emptyContent: "text-center text-black text-xl py-12 md:text-2xl",
        }}
        emptyContent={"No Documents Found!"}
      >
        {(item) => (
          <ListboxItem key={item.name}>
            <div className="grid grid-cols-1 md:grid-cols-8 gap-10 border border-blue-400 rounded-lg p-8 items-center">
              <div className="col-span-1 md:col-span-5 ml-auto md:ml-0">
                <div className="grid justify-items-center md:justify-items-center xl:justify-items-start">
                  <Typography
                    variant="custom"
                    className="font-bold text-[#263238] text-[22px] md:text-wrap"
                  >
                    {item.formNumber} {item.formNumber ? "-" : ""} {item.name}
                  </Typography>
                  <Typography
                    variant="custom"
                    className="font-semibold text-[#263238] text-[13px] md:text-wrap mt-2"
                  >
                    {removeHtmlTags(item.description)}
                  </Typography>
                </div>
              </div>
              <div className="col-span-8 md:col-span-3">
                <div className="grid gap-4 justify-items-center">
                  {item.fileSpec ? (
                    <a
                      className="col-span-1 flex flex-col items-center gap-2 w-[30px] h-[30px]"
                      href={item.fileSpec}
                      download={item.fileName}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src="/assets/icons/download.svg" />
                      <Typography
                        variant="custom"
                        className="font-bold text-[#263238] text-[12px]"
                      >
                        Download
                      </Typography>
                    </a>
                  ) : (
                    <div className="col-span-1 flex flex-col items-center gap-2 w-[30px] h-[30px] cursor-not-allowed">
                      <Image src="/assets/icons/download.svg" />
                      <Typography
                        variant="custom"
                        className="font-bold text-gray-300 text-[12px]"
                      >
                        Download
                      </Typography>
                    </div>
                  )}

                  <div className="col-span-1 flex flex-col items-center gap-2 w-[50px] h-[50px] ">
                    <Image src="/assets/icons/upload.svg" />
                    <Typography
                      variant="custom"
                      className="font-bold text-[#263238] text-[12px]"
                    >
                      Upload
                    </Typography>
                  </div>
                  <div className="col-span-1 flex flex-col items-center gap-2 w-[30px] h-[30px]">
                    <Image src="/assets/icons/completed.svg" />
                    <Typography
                      variant="custom"
                      className="font-bold text-[#263238] text-[12px]"
                    >
                      Completed
                    </Typography>
                  </div>
                  <Progress
                    aria-label="Downloading..."
                    size="md"
                    value={80}
                    color="success"
                    className="max-w-xl col-span-4"
                  />
                </div>
              </div>
            </div>
          </ListboxItem>
        )}
      </Listbox>
      {error?.message && (
        <Typography variant="error">{error.message}</Typography>
      )}
      {dmvData && showInternalSubmit && dmvData?.length > 0 && (
        <div className="flex items-center justify-end">
          <Button
            className="md:max-w-[12rem] mr-2 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
            type="button"
            onClick={() => navigate("/dealer/dmv-titling/dvm-transit-status")}
          >
            Submit
          </Button>
        </div>
      )}
    </>
    //     :<Typography variant="custom" className="text-lg mt-10 font-bold text-red-400 text-center">
    //     We’re on it! An automated Documents were not available for this vin,
    //     however, we are looking to find the options for you, a support ticket
    //     has been opened
    //   </Typography>
    // }
    // </>
  );
};
