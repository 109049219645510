import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "../../../shared/components/CloseIcon";
import Input from "../../../../../src/components/ui/input";

import "./chathelp.css";

const SupportChat = ({ setCurrScreen, handleScreenChange }) => {
  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    phone: "",
    description: "",
  });
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      message: "Hello, how can i help you?",
      type: "reciever",
      name: "Support Team",
    },
  ]);
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = () => {
    if (message !== "")
      setMessages((prev) => [
        ...prev,
        { message: message, type: "sender", name: "You" },
      ]);
    setMessage("");
  };
  console.log("messages", messages);
  const [errors, setErrors] = useState({});
  const chatMessagesRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Assuming `messages` is your state containing chat messages

  // Function to scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  // const chatContainerRef = useRef(null);
  // useEffect(() => {
  //   // Function to initialize Embedded Messaging
  //   const initEmbeddedMessaging = () => {
  //     if (window.embeddedservice_bootstrap) {
  //       window.embeddedservice_bootstrap.settings.language = "en_US";
  //       // ... Other settings as needed
  //       window.embeddedservice_bootstrap.init(
  //         "00DDx0000008rlq",
  //         "FMOWebChatforTestingSite",
  //         "https://findmyoptions--fmodev2.sandbox.my.site.com/ESWFMOWebChatforTestingSi1708941017806",
  //         {
  //           scrt2URL:
  //             "https://findmyoptions--fmodev2.sandbox.my.salesforce-scrt.com",
  //         }
  //       );
  //     }
  //   };

  //   // Load the Salesforce script dynamically
  //   const script = document.createElement("script");
  //   script.src = "../../../../assets/external/salesforce.js"; // Ensure this path is correct
  //   script.onload = () => {
  //     initEmbeddedMessaging();
  //   };
  //   script.onerror = () => {
  //     console.error("Failed to load the Salesforce script.");
  //   };
  //   document.body.appendChild(script);

  //   // Cleanup function for when the component is unmounted
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (window.Sb && chatContainerRef.current) {
      window.Sb((iframe) => {
        console.log({ iframe });
        // iframe is the loaded iframe element
        chatContainerRef.current.appendChild(iframe); // Append it to the container
      });
    }
  }, [chatContainerRef]);
  return (
    <div
      className="flex justify-end"
      style={{ fontFamily: "Metropolis-SemiBold" }}
    >
      <div className="w-full h-[550px] bg-[#fff] rounded-none m-0 md:m-0 min-[768px]:w-[400px] min-[768px]:h-[550px] min-[768px]:rounded-[25px]">
        <div className="relative flex h-[100px] grad md:rounded-t-[25px]">
          <div className="flex items-center justify-center w-full text-[20px] font-extrabold text-white gap-2">
            <span>
              <img
                className="w-[32px] h-[32px]"
                src={`${process.env.PUBLIC_URL}/assets/icons/live-chat.svg`}
                alt=""
              />
            </span>
            <div>Live Chat</div>
          </div>
          <div className="absolute right-0 mr-[39px] mt-[39px]">
            <CloseIcon
              stroke="#FFF"
              strokeWidth="4.028"
              onClick={() => setCurrScreen("help")}
            />
          </div>
        </div>

        <iframe ref={chatContainerRef} id="FmoSalesforce"></iframe>
      </div>
    </div>
  );
};

export default SupportChat;
