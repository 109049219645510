import React, { useEffect, useState } from "react";

import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { useGetVindataDecodeMut } from "services";
import { EnterMileage } from "modules/dealer/components/enter-vin/type";
import { Image, useDisclosure } from "@nextui-org/react";
import { SelectNextUi } from "components";
import { Modal } from "modules/shared";

import VehicleServiceContract from "./vehicle-service-contract";
import {
  useAddPaymenTransactiontMethod,
  useGetCreditCardMethodStatusData,
  useTransactionsCheckForPaymentMethod,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { AddCardMethodPayload } from "services/endpoints/shipping-quotes/shipping.type";
import { Modal as NextModal, ModalContent } from "@nextui-org/react";
import paymentBg from "../../../dealer/offer-accepted/assets/payment-bg.png";
import PaymentSuccessForVehicleContract from "./PaymentSuccessForVehicleContract";
import {
  useProcessVehicleServiceContract,
  useSFRepairAgreement,
  useSubmitVehicleServiceContract,
} from "modules/user/hooks/api/mutations/benefits/repair-agreement";
import { getUserId } from "utils/get-user-id";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import { useNavigate } from "react-router-dom";
import { ResponsiveModal } from "modules/shared/components/modal/ResponsiveModalHeader";

const USA_STATE = [
  { label: "Alaska", value: "AK" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Indiana", value: "IN" },
  { label: "Maine", value: "ME" },
  { label: "Nebraska", value: "NE" },
  { label: "New Hampshire", value: "NH" },
  { label: "North Dakota", value: "ND" },
  { label: "Oregon", value: "OR" },
  { label: "South Dakota", value: "SD" },
  { label: "Vermont", value: "VT" },
  { label: "District of Columbia", value: "DC" },
];

type EnterVINForRepaire = {
  data: string;
  error: boolean;
  selectedState: boolean;
  phanType: boolean;
};

const RepairAgreement = () => {
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const { data, mutateAsync: decondeVINAsync } = useGetVindataDecodeMut();
  // const [rfChecked,setRFChecked]= useState({checked:false,data:"",error:false});
  const [vinNumber, setVinNumber] = useState<EnterVINForRepaire>({
    data: "",
    error: false,
    selectedState: false,
    phanType: false,
  });
  const [mileage, setMileage] = useState<EnterMileage>({
    mileage: 0,
    error: false,
  });
  const [state, setState] = useState<{ data: string; error: boolean }>({
    data: "",
    error: false,
  });
  // const {
  //   mutateAsync: mutateAsyncAccceptPayment,
  //   data: checkIfPaymentExist,
  //   isPending: isPendingToAccceptPayment,
  // } = useTransactionsCheckForPaymentMethod();
  
  const {  data: checkIfPaymentExist, refetch: mutateAsyncAccceptPayment, isLoading:isPendingToAccceptPayment } =
  useGetCreditCardMethodStatusData(true);
  const {
    // data: addResponseData,
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: addResponseDataErrorMeg,
  } = useAddPaymenTransactiontMethod();

  const {
    mutateAsync: ProcessVehicleServiceContractAsync,
    isPending: isPendingToProcessVehicleServiceContract,
    error: paymentProccessWarrentyMessage,
  } = useProcessVehicleServiceContract(vinNumber.data);
  const {
    mutateAsync: SubmitVehicleServiceContractAsync,
    isPending: isPendingToSubmitVehicleServiceContract,
    error: submitWarrentyErrMes,
  } = useSubmitVehicleServiceContract();

  const {
    mutateAsync: SFRepairAgreementAsync,
    isPending: isPendingToSFRepairAgreement,
    error: sfErrorMessageForWarrenty,
  } = useSFRepairAgreement();

  const handleConfirmPayment = async (data: AddCardMethodPayload) => {
    try {
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getFullYear() + 3,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      console.log("data - confirm payment", data);
      const payloadData = {
        userId: getUserId(),
        vin: vinNumber.data,
        state: state.data,
        agreedMiles: mileage.mileage,
        agreementAmount: vinNumber.phanType ? "99" : "129",
        listingId: 0,
        paymentStatus: 0,
        oilChangeInspection: currentDate.toISOString(),
        contractStartDate: currentDate.toISOString(),
        expiryDate: futureDate.toISOString(),
        subscriptionKey: vinNumber.phanType
          ? "FMO_REPAIR_AGREEMENT_10K_99_PM"
          : "FMO_REPAIR_AGREEMENT_15K_129_PM",
        monthsRemaining: 36,
        isOdometerVerified: false,
      };
      if (checkIfPaymentExist) {
        await SubmitVehicleServiceContractAsync(payloadData);
        await ProcessVehicleServiceContractAsync();
        await SFRepairAgreementAsync({
          ...payloadData,
          referenceNumber: data?.ref_num || "",
          listingId: "0",
          paymentStatus: 1,
        });
      } else {
        const paymentData = await mutateAddPaymenTransactiontMethod(data);
        mutateAsyncAccceptPayment()
        await SubmitVehicleServiceContractAsync(payloadData);
        await ProcessVehicleServiceContractAsync();
        await SFRepairAgreementAsync({
          ...payloadData,
          referenceNumber: data?.ref_num || "",
          listingId: "0",
          paymentStatus: 1,
        });
        console.log("paymentData", paymentData);
      }
      // await getVscStatusFetch();
      navigate("/user/benefits/repair-agreement", { state: vinNumber.data });

      onOpen();
      setStep(0);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const acceptContractForVehicle = async () => {
    setStep(3);
    mutateAsyncAccceptPayment();
  };

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber((prev) => ({ ...prev, data: e.target.value.trim().toLocaleUpperCase() }));
    const len = e.target.value.trim().length;
    if (len !== 17) {
      setVinNumber((prev) => ({ ...prev, error: len !== 0 }));
    } else {
      setVinNumber((prev) => ({ ...prev, error: false }));
    }
  };

  const handleRepairAgreement = async (nextStep: number) => {
    if (
      vinNumber.data.length === 17 &&
      mileage.mileage > 0 &&
      state.data !== "" &&
      mileage.mileage <= 120000
    ) {
      const vinData = await decondeVINAsync(vinNumber.data);
      const err =
        (vinData?.message || [])?.find((data) => data.code === "ErrorCode")
          ?.value !== "0";
      setVinNumber((prev) => ({
        ...prev,
        phanType: nextStep === 1,
        error: err,
      }));
      if (!err) {
        setStep(nextStep);
      }
    } else {
      setVinNumber((prev) => ({
        ...prev,
        error: vinNumber.data.length !== 17,
      }));
      setMileage((prev) => ({
        ...prev,
        error: mileage.mileage === 0 || mileage.mileage > 120000,
      }));
      setState((prev) => ({ ...prev, error: state.data === "" }));
      // setRFChecked((prev) => ({ ...prev, error: prev.data.length === 0 }));
    }
  };

  // useEffect(() => {
  //   if (vscStatus && vscStatus.length > 0) {
  //     setStep(4);
  //   }
  // }, [vscStatus]);

  return (
    <div id="repair-ageement-div-select" className="relative">
      <div className="flex items-center m-auto enter-vin">
        <div className="flex items-center px-4 mx-auto bg-fixed bg-center bg-cover ">
          <form className="flex flex-col px-8 py-12 rounded-lg shadow-md gradiant-box-vin md:px-8 xl:max-w-[48rem]">
            <div className="min-w-0 xl:min-w-[650px] flex flex-col justify-center mb-12">
              <label
                htmlFor="yourInput1"
                className={`text-center mb-4 ${
                  vinNumber.error ? "text-red-500" : "text-white"
                } font-bold  md:text-2xl text-lg `}
              >
                {"Enter VIN Number"}
              </label>
              <div className="relative w-full text-center">
                <input
                  type="text"
                  id="yourInput1"
                  name="yourInput1"
                  onChange={onChangeVIN}
                  value={vinNumber.data}
                  className={`w-full md:w-3/4 text-center border-none focus:outline-none bg-transparent ${
                    vinNumber.error ? "text-red-500" : "text-white"
                  } font-semibold text-lg md:text-4xl `}
                />
                <div
                  className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-full md:w-3/4 h-[1.5px]  ${
                    vinNumber.error ? "bg-red-500" : "bg-white"
                  }`}
                ></div>
                {vinNumber.error && (
                  <div
                    className={`absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-full md:w-3/4
                  flex  items-center justify-center text-[#FF0A0A]`}
                  >
                    <ErrorInfoIcon className="-mt-[4px] w-[16px] h-[16px] mr-[2px]" />
                    <label className="text-[16px]">
                      Please enter Valid VIN
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-center w-3/4 mx-auto mb-4">
              {/* <label
                  htmlFor="yourInput1"
                  className={`text-start  mb-1 text-white font-bold  md:text-lg text-md `}
                >
                  Reference Number
                </label> */}
              {/* <div
                    // key={id}
                    className="flex items-center justify-between md:mb-0 mb-2"
                  >
                    <h5 className="mb-1 text-sm text-white md:text-xl">
                      {"Reference Number"}
                    </h5>
                    <label className="flex items-center cursor-pointer">
                      <div className="relative ">
                        <input
                          id="toggle"
                          type="checkbox"
                          className="sr-only"
                          // disabled={disable}
                          checked={rfChecked.checked}
                          onChange={() => setRFChecked((p)=>({...p,checked:!p.checked}))}
                        />
                        <div className="block bg-white  h-5 w-14 md:h-6 rounded-full "></div>
                        <div
                          className={`dot absolute top-1 ${
                            rfChecked.checked
                              ? "left-[calc(100%-1.1rem)] bg-[#018FFD]"
                              : "left-1 bg-[#B5B5B5]"
                          } w-3 h-3 md:w-4 md:h-4 rounded-full transition`}
                        ></div>
                      </div>
                    </label>
                  </div>
                {rfChecked.checked&&<div className="flex  justify-center w-full mt-2 ">
                  <input
                    type="number"
                    id="rf"
                    name="rf"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (Number(value) && Number(value) > 0) {
                        setRFChecked((prev) => ({
                          ...prev,
                          data: value,
                          error: Number(value) === 0,
                        }));
                      } else return (e.target.value = "");
                    }}
                    className={` text-center w-full ${
                      !rfChecked.error
                        ? "border-white"
                        : "border-red-500 border-3"
                    } border focus:outline-none rounded-xl h-[46px] font-semibold text-xl md:text-2xl`}
                  />
                </div>} */}
            </div>

            <div className="flex flex-col justify-center w-3/4 mx-auto mb-8">
              <label
                htmlFor="yourInput1"
                className={`text-start  mb-1 text-white font-bold  md:text-lg text-md `}
              >
                Enter Mileage
              </label>
              <div className="flex  justify-center w-full  mb-4">
                <input
                  type="number"
                  id="yourInput1"
                  name="yourInput1"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (Number(value) && Number(value) > 0) {
                      setMileage((prev) => ({
                        ...prev,
                        mileage: Number(value),
                        error: Number(value) === 0,
                      }));
                    } else return (e.target.value = "");
                  }}
                  className={` text-center w-full ${
                    !mileage.error ? "border-white" : "border-red-500 border-3"
                  } border focus:outline-none rounded-xl h-[46px] font-semibold text-xl md:text-2xl`}
                />
              </div>
              {mileage.error && (
                <div
                  className={`flex  items-center justify-center text-[#FF0A0A]`}
                >
                  <ErrorInfoIcon className="mr-1 size-4" />
                  <label className="text-[16px]">
                    The mileage should not exceed 120,000.
                  </label>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center w-3/4 mx-auto mb-8 relative">
              <label
                htmlFor="yourInput1"
                className={`text-start  mb-1 text-white font-bold  md:text-lg text-md `}
              >
                State
              </label>
              <div className="flex  justify-center w-full  mb-4">
                <SelectNextUi
                  placeholder="Select Vehicle Type"
                  className={
                    state.error ? ` border-3 border-red-500 rounded-2xl` : ""
                  }
                  aria-label="Select Vehicle Type"
                  data={USA_STATE}
                  register={{
                    onChange: (e: any) => {
                      setState((prev) => ({
                        ...prev,
                        data: e.target.value as string,
                        error: false,
                      }));
                    },
                    size: "md",
                    placeholder: "Only available in the following states:",
                  }}
                  portalId="repair-ageement-div-select"
                />
              </div>
            </div>

            <h6 className="text-xs text-white md:text-base text-center  mx-4">
              Vehicle Must be registered in this state and this must be your
              state or residence or this agreement is not valid
            </h6>
            <h5
              className="text-white text-center text-sm md:text-xl font-bold-[Metropolis] mt-4"
              style={{ fontFamily: "Metropolis" }}
            >
              Select Repair Agreement
            </h5>
            <div className="flex flex-col items-center justify-center w-full text-center md:text-left">
              <button
                className=" md:mr-6 mt-5 w-3/4  py-2 text-lg text-white rounded-lg  confirm-btn-fin focus:outline-none focus:shadow-outline md:text-2xl hover:scale-105"
                type="button"
                onClick={() => {
                  handleRepairAgreement(1);
                }}
              >
                {"3 year 10k miles/year $99/mo"}
              </button>
              <button
                className=" md:mr-6 mt-5 w-3/4  py-2 text-lg text-white rounded-lg  confirm-btn-fin focus:outline-none focus:shadow-outline md:text-2xl hover:scale-105"
                type="button"
                onClick={() => {
                  handleRepairAgreement(2);
                }}
              >
                {"3 year 15k miles/year $129/mo"}
              </button>
            </div>
          </form>

          <NextModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            isDismissable={false}
            isKeyboardDismissDisabled={true}
            placement="center"
            size="3xl"
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <div
                    className="p-14"
                    style={{
                      backgroundImage: `url(${paymentBg})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <PaymentSuccessForVehicleContract
                      navigatePath={``}
                      buttonText="Continue"
                      activePlanType={vinNumber.phanType}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        // setActiveSteps(steps[activeSteps.step]);
                        // navigate("/user/benefits/repair-agreement-details");
                        onClose();
                      }}
                    />
                  </div>
                </>
              )}
            </ModalContent>
          </NextModal>
          <ResponsiveModal
            isOpen={step !== 0}
            onClose={() => {
              setStep(0);
            }}
            ContentComponent={
              step === 3 ? (
                <div>
                  <div className="w-full flex justify-center">
                    <Image
                      src="assets/icons/new-logo.svg"
                      removeWrapper
                      radius="none"
                      shadow="none"
                    />
                  </div>
                  <PaymentFormWithDetails
                    payAmmount={vinNumber.phanType ? "99" : "129"}
                    showmodal={true}
                    isPending={
                      isPendingToAddPaymenTransactiontMethod ||
                      isPendingToProcessVehicleServiceContract ||
                      isPendingToSubmitVehicleServiceContract ||
                      isPendingToSFRepairAgreement
                    }
                    savedMethod={checkIfPaymentExist}
                    handleNext={(e) => {
                      // setShowPayModal(false);
                      // onOpen();
                      handleConfirmPayment(e);
                    }}
                    errorMessage={
                      addResponseDataErrorMeg?.message ||
                      paymentProccessWarrentyMessage?.message ||
                      submitWarrentyErrMes?.message ||
                      sfErrorMessageForWarrenty?.message
                    }
                  />
                </div>
              ) : (
                <VehicleServiceContract
                  vin={vinNumber.data}
                  milage={mileage.mileage}
                  state={{
                    label: findLabelByValue(state.data),
                    code: state.data,
                  }}
                  vinData={data}
                  payAmmount={vinNumber.phanType ? "99" : "129"}
                  isLoading={isPendingToAccceptPayment}
                  activePlanType={vinNumber.phanType}
                  handleConfirm={acceptContractForVehicle}
                />
              )
            }
            headerTitle={
              step === 3 ? "" : "Findmyoptions.com LLC Vehicle Service Contract"
            }
            children={<></>}
          />

        </div>
      </div>
    </div>
  );
};

function findLabelByValue(value: string) {
  for (let i = 0; i < USA_STATE.length; i++) {
    if (USA_STATE[i].value === value) {
      return USA_STATE[i].label;
    }
  }
  return null; // Return null if value not found
}
export default RepairAgreement;
