import { changeUserInfo } from "modules/dealer/store/user-information";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {  useGetCurrentUserForBoth } from "services";
import { getUserId, isThisDealer } from "utils/get-user-id";


const useUserData = () => {
    const userId = getUserId();
    const dispatch =  useDispatch();
    const { data, isLoading,isError  } = useGetCurrentUserForBoth(userId,isThisDealer());
    useEffect(()=>{
        if(data){
            dispatch(changeUserInfo(data))
        }
    },[data])
    return {  isLoading, isError };
};

export default useUserData;