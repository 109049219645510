import LabelInfoCardGradiant from "./card.component";
import { cards } from "../list-your-car-for-sale.data";

const SellYourCarNationwide = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center mb-8 text-center">
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
        How to Sell Your Car Nationwide with Ease:
        </h2>
      </div>
  
  
      <div className="flex flex-col lg:flex-row gap-8">
       
      {cards.map((card) => (
        <LabelInfoCardGradiant card={card} />
      ))}
    </div>
    </div>
  );
};

export default SellYourCarNationwide;
