import "@fontsource/metropolis"; // Defaults to weight 400
import "@fontsource/metropolis/100.css";
import "@fontsource/metropolis/200.css";
import "@fontsource/metropolis/300.css";
import "@fontsource/metropolis/400.css";
import "@fontsource/metropolis/500.css";
import "@fontsource/metropolis/600.css";
import "@fontsource/metropolis/700.css";
import "@fontsource/metropolis/800.css";
import "@fontsource/metropolis/900.css";
import { useHttpsRedirect } from "hooks/redirect";
import { Providers } from "providers";

import { AppRoutes } from "routes";
import { useCometdHandshake } from "services/cometd/use-cometd-handshake";
import { useSFChat } from "services/sf-chat/use-sf-chat";

export default function App() {
  useHttpsRedirect()
  useCometdHandshake()
  useSFChat()
  return (
    <Providers>
      {/* <RouterProvider
        router={baseRouter}
        fallbackElement={<p>Initial Load...</p>}
      /> */}
      <AppRoutes />
    </Providers>
  );
}

// import "@fontsource/metropolis";
// import {
//   Navigate,
//   Route,
//   HashRouter as Router,
//   Routes,
// } from "react-router-dom";
// import "./App.css";
// import DealerDashboad from "./modules/dealer/components/dashboard";
// import DmvTitling from "./modules/dealer/components/dmv-title";
// import DealerLogin from "./modules/dealer/components/login";
// import Profile from "./modules/dealer/components/profile/Profile";
// import ProfitLoss from "./modules/dealer/features/profit-loss/ProfitLoss";
// import Layout from "./modules/dealer/layout";
// import DealerRegister from "./modules/dealer/components/register";
// import ForgotPass from "./modules/dealer/features/forgot-password/ForgotPass";
// import PassChange from "./modules/dealer/features/forgot-password/PassChange";
// import ResetPassword from "./modules/dealer/features/forgot-password/ResetPassword";
// import UserRegister from "./modules/user/components/Register";
// import EnterVINNumber from "./modules/dealer/components/enter-vin/EnterVIN";
// import Listings from "./modules/dealer/features/Listings/Listings";
// import ShippingQuotes from "./modules/dealer/features/shipping-quotes/ShippingQuotes";
// import ShowQuotes from "./modules/dealer/features/shipping-quotes/ShowQuotes";
// import PaymentForm from "./modules/dealer/features/shipping-quotes/PaymentForm";
// import SendOTP from "./modules/dealer/features/shipping-quotes/SendOTP";
// import PaymentSuccess from "./modules/dealer/features/shipping-quotes/PaymentSuccess";
// import TransitStatus from "./modules/dealer/features/shipping-quotes/TransitStatus";
// import DealerMembership from "./modules/dealer/components/dealer-membership/DealerMembership";
// import ManageListing from "./modules/dealer/components/manage-listing/ManageListing";
// import DmvEnterMissingDetails from "./modules/dealer/components/dmv-title/DmvEnterMissingDetails";
// import DmvFeeDetails from "./modules/dealer/components/dmv-title/DmvFeeDetails";
// import DmvDocStatus from "./modules/dealer/components/dmv-title/DmvDocStatus";
// import DmvTransitStatus from "./modules/dealer/components/dmv-title/DmvTransitStatus";
// import EmailTheme from "./modules/dealer/features/forgot-password/EmailTheme";
// import { PATH_APP, PaymentCalculator } from "./modules/dealer";

// const App: React.FC = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Navigate to="/dealer-login" />} />
//         <Route path="/dealer-login" element={<DealerLogin />} />
//         <Route path="/forgot-password" element={<ForgotPass />} />
//         <Route path="/email" element={<EmailTheme />} />
//         <Route path="/forgot-password/changed" element={<PassChange />} />
//         <Route path="/reset" element={<ResetPassword />} />
//         <Route path="/dealer-dashboard" element={<DealerDashboad />} />
//         <Route
//           path="/dealer-dmvTitling/enter-missing-details"
//           element={<DmvEnterMissingDetails />}
//         />
//         <Route
//           path="/dealer-dmvTitling/dvm-document-status"
//           element={<DmvDocStatus />}
//         />
//         <Route
//           path="/dealer-dmvTitling/dvm-transit-status"
//           element={<DmvTransitStatus />}
//         />

//         <Route path="/dealer-dmvTitling" element={<DmvTitling />} />
//         <Route path="/dealer-dashboard/profit-loss" element={<ProfitLoss />} />
//         <Route
//           path="/dealer-dashboard/enter-vin"
//           element={<EnterVINNumber />}
//         />
//         <Route path="/register" element={<DealerRegister />} />
//         <Route path="/user/register" element={<UserRegister />} />
//         <Route
//           path="/dealer-dashboard/manage-inventory"
//           element={<Listings />}
//         />
//         <Route
//           path="/dealer-dashboard/manage-listing"
//           element={<ManageListing />}
//         />
//         <Route
//           path="/dealer-dashboard/fee-details"
//           element={<DmvFeeDetails />}
//         />

//         <Route
//           path="/user-profile"
//           element={
//             <Layout>
//               <Profile />
//             </Layout>
//           }
//         />
//         <Route
//           path="/user-membership"
//           element={
//             <Layout>
//               <DealerMembership />
//             </Layout>
//           }
//         />
//         <Route path="/shipping-quotes" element={<ShippingQuotes />} />
//         <Route path="/shipping-quotes/show-quotes" element={<ShowQuotes />} />
//         <Route path="/shipping-quotes/payment-form" element={<PaymentForm />} />
//         <Route path="/shipping-quotes/send-otp" element={<SendOTP />} />

//         <Route
//           path="/shipping-quotes/payment-success"
//           element={<PaymentSuccess />}
//         />
//         <Route
//           path="/shipping-quotes/transit-status"
//           element={<TransitStatus />}
//         />

//         <Route
//           path={PATH_APP.tools.paymentCalculator}
//           element={<PaymentCalculator />}
//         />
//       </Routes>
//     </Router>
//   );
// }
