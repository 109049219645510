import React, { useEffect, useState } from "react";
import "./../../../modules/dealer/components/enter-vin/EnterVIN.scss";
import {
  EnterVIN,
  VinDataType,
} from "../../../modules/dealer/components/enter-vin/type";
import apiClient from "../../../modules/dealer/utils/apiClient";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { NewOrderForm } from "pages/shipping-quotes/new-order/new-order.form";
import { changeCarForShipping } from "modules/dealer/store/shipping";
import { message } from "antd";
import { capitalizeForDashOrWhiteSpace, capitalizeAllBeforeDash } from "utils";
import { findVehicleBy } from "utils/find-vehicle-by";
import { useDispatch } from "react-redux";
import { PublicShippingForm } from "./public-shipping-form.form";
import { Typography } from "components";

export default function VehicleShippingQuotePage() {
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [vinNumber, setVinNumber] = useState<EnterVIN>({
    data: "",
    error: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let interval: any;
    if (step === 1) {
      interval = setInterval(() => {
        if (progress < 100) {
          setProgress((p) => p + 20);
        } else {
          clearInterval(interval);
          setProgress(100);
          if (vinNumber.error) {
            setStep(0);
          } else {
            setStep(2);
          }
        }
      }, 500);
    }
    return () => clearInterval(interval);
  }, [progress, step]);

  const fetchVinData = async () => {
    try {
      if (vinNumber.data.length <= 0 || vinNumber.error) {
        throw new Error("len not right");
      }

      const response = await apiClient.get(
        `/VinData/DecodeVinData?vin=${vinNumber.data}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );
      const message = response.data.message;
      const make = findVehicleBy(message ?? [], "Make")?.value ?? "";
      const model = findVehicleBy(message ?? [], "Model")?.value ?? "";
      const year = findVehicleBy(message ?? [], "ModelYear")?.value ?? "";
      const name = `${year} ${capitalizeForDashOrWhiteSpace(
        make
      )} ${capitalizeAllBeforeDash(model)}`;
      console.log({ name });
      const vin = vinNumber.data;
      const listingId = 0;
      dispatch(changeCarForShipping({ name, vin, listingId, year,make,model }));
      setStep(1);

      const err =
        response.data?.message?.find(
          (data: VinDataType) => data.code === "ErrorCode"
        ).value !== "0";
      setVinNumber((prev) => ({
        ...prev,
        error: err,
      }));
    } catch (error) {
      console.error("Error fetching VIN data:", error);
      setVinNumber((prev) => ({ ...prev, error: true }));
    }
  };

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber((prev) => ({ ...prev, data: e.target.value.trim().toLocaleUpperCase() }));
    const len = e.target.value.trim().length;
    if (len !== 17) {
      setVinNumber((prev) => ({ ...prev, error: len !== 0 }));
    } else {
      setVinNumber((prev) => ({ ...prev, error: false }));
    }
  };

  return (
    <React.Fragment>
      {step === 2 ? (
        <div className="w-full  flex items-center justify-center">
          <div className="w-full md:w-3/4">
            <PublicShippingForm from="vehicle-shipping" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-12 md:p-2 mt-10">
          <Typography variant="custom" className="text-center">
            <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text 2xl:text-5xl text-3xl font-bold mb-10">
              Vehicle Shipping Quote
            </p>
          </Typography>
          <div className="flex items-center w-full md:w-auto m-auto enter-vin ">
            <div className="flex items-center w-full md:w-auto mx-auto bg-fixed bg-center bg-cover">
              <form className="flex flex-col px-8 py-12 rounded-lg shadow-md gradiant-box-vin md:px-8">
                <div className="min-w-0 xl:min-w-[650px] flex flex-col justify-center mb-12">
                  <label
                    htmlFor="yourInput1"
                    className={`text-center mb-4 ${
                      vinNumber.error ? "text-red-500" : "text-white"
                    } font-bold  md:text-2xl text-lg `}
                  >
                    {"Enter VIN Number"}
                  </label>
                  <div className="relative w-full text-center">
                    <input
                      type="text"
                      id="yourInput1"
                      name="yourInput1"
                      onChange={onChangeVIN}
                      value={vinNumber.data}

                      className={`w-full md:w-4/5 text-center border-none focus:outline-none bg-transparent ${
                        vinNumber.error ? "text-red-500" : "text-white"
                      } font-semibold lg:text-4xl text-lg`}
                    />
                    <div
                      className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-full md:w-3/4 h-[1.5px]  ${
                        vinNumber.error ? "bg-red-500" : "bg-white"
                      }`}
                    ></div>
                    {vinNumber.error && (
                      <div
                        className={`absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-full md:w-3/4
                    flex  items-center justify-center text-[#FF0A0A]`}
                      >
                        <ErrorInfoIcon className="-mt-[4px] w-[16px] h-[16px] mr-[2px]" />
                        <label className="text-[16px]">
                          Please enter Valid VIN
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:flex items-center justify-center w-full text-center md:text-left">
                  <button
                    onClick={() => {
                      fetchVinData();
                    }}
                    className=" md:mr-6 mt-5 w-3/4 max-w-[200px] py-2 text-lg text-white rounded-lg  confirm-btn-fin focus:outline-none focus:shadow-outline md:text-2xl"
                    type="button"
                  >
                    {"Confirm"}
                  </button>
                </div>
              </form>
              {step == 1 && (
                <div className="flex overflow-y-auto p-4 overflow-x-auto fixed items-center justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full">
                  <div className="absolute inset-0 bg-black opacity-50 z-999"></div>
                  <div className="relative gradiant-modal-loading h-[450px] py-24 px-10  md:px-24  mx-auto  rounded-lg shadow-md ">
                    <div className="flex justify-center mb-4 text-lg font-bold text-white md:text-2xl">
                      Searching for Data
                    </div>
                    <div className="flex justify-center mb-16 text-2xl font-bold text-white md:text-4xl">
                      {vinNumber.data}
                    </div>

                    <div className="flex justify-center mb-2">
                      <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-base">
                        {" "}
                        Please wait while findmyoptions.com queries your VIN
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <div className="loader-container">
                        <div
                          className="loader-progress"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
