import { SVGProps } from "react";

export const UserAvatarIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="tdesign:user">
      <path
        id="Vector"
        d="M15.0703 5.84505C13.9039 5.84505 12.7854 6.30839 11.9606 7.13313C11.1359 7.95788 10.6725 9.07647 10.6725 10.2428C10.6725 11.4092 11.1359 12.5278 11.9606 13.3525C12.7854 14.1773 13.9039 14.6406 15.0703 14.6406C16.2367 14.6406 17.3553 14.1773 18.18 13.3525C19.0048 12.5278 19.4681 11.4092 19.4681 10.2428C19.4681 9.07647 19.0048 7.95788 18.18 7.13313C17.3553 6.30839 16.2367 5.84505 15.0703 5.84505ZM8.1595 10.2428C8.1595 8.40998 8.88761 6.65219 10.1836 5.35616C11.4797 4.06013 13.2375 3.33203 15.0703 3.33203C16.9032 3.33203 18.661 4.06013 19.957 5.35616C21.253 6.65219 21.9811 8.40998 21.9811 10.2428C21.9811 12.0757 21.253 13.8335 19.957 15.1295C18.661 16.4255 16.9032 17.1536 15.0703 17.1536C13.2375 17.1536 11.4797 16.4255 10.1836 15.1295C8.88761 13.8335 8.1595 12.0757 8.1595 10.2428ZM3.76172 24.6927C3.76172 23.0265 4.42363 21.4285 5.60184 20.2503C6.78004 19.0721 8.37803 18.4102 10.0443 18.4102H20.0964C21.7626 18.4102 23.3606 19.0721 24.5388 20.2503C25.717 21.4285 26.3789 23.0265 26.3789 24.6927V28.4622H3.76172V24.6927ZM10.0443 20.9232C9.04453 20.9232 8.08573 21.3203 7.37881 22.0272C6.67189 22.7342 6.27474 23.693 6.27474 24.6927V25.9492H23.8659V24.6927C23.8659 23.693 23.4687 22.7342 22.7618 22.0272C22.0549 21.3203 21.0961 20.9232 20.0964 20.9232H10.0443Z"
        fill="#313131"
      />
    </g>
  </svg>
);
