import React from "react";
import grayArrow from "../../assets/svgs/arrow-down-icon-gray.svg";
import activeArrow from "../../assets/svgs/arrow-down-icon-blue.svg";
import arrowRight from "../../assets/svgs/arrow-right-icon-blue.svg";
import { Typography } from "components";
interface Props {
  activeStepCount?: number;
  stepsArray?: any[];
}
export default function PendingSteps(props: Props) {
  const { activeStepCount, stepsArray } = props;
  return (
    <div className="flex justify-center md:justify-normal mb-2 md:flex-col">
      {activeStepCount !== 0 &&
        stepsArray!.map((res, index) => (
          <div key={index} className="">
            <div className="flex items-center justify-center gap-[4px] md:gap-3 ">
              <div
                className={`${
                  index + 1 > activeStepCount!
                    ? "bg-[#68686880]"
                    : "bg-gradient-to-r from-[#018FFD] to-[#070C84]"
                } text-white  rounded-full min-w-8 min-h-8 flex items-center justify-center md:ml-3`}
              >
                {res.step}
              </div>
              <div className="block md:hidden">
                {index + 1 !== stepsArray!.length && (
                  <img
                    src={
                      index + 1 > stepsArray!.length ? arrowRight : arrowRight
                    }
                    className="min-w-3 md:min-w-8 "
                    alt="arrow"
                  />
                )}
              </div>
              <div className="block md:hidden "></div>
              <div className="hidden md:block">
                <div className="w-40 h-10">
                  <Typography variant="custom" className="text-md font-[600]">
                    Step {res.step}
                  </Typography>
                  <Typography
                    variant="custom"
                    className="text-[8.481px] font-[600] text-[#313131] text-left"
                  >
                    {res.description}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="hidden md:block h-10 ml-5">
              {index + 1 !== stepsArray!.length && (
                <img
                  src={index + 1 > activeStepCount! ? grayArrow : activeArrow}
                  className=""
                  alt="Arorrw"
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
