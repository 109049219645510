import { useMutation, useQuery } from "@tanstack/react-query";
// import { fmomembershipEndpoint } from "modules/user/services/endpoints/fmo-membership/fmo-membership.endpoint";
import { vehicleContractEndpoint } from "modules/user/services/endpoints/repair/repair-agreement.endpoint";
import { VehicleRepairSubscriptionPayload } from "modules/user/services/endpoints/repair/repair-agreement.type";
// import { FMOMemberShipType } from "modules/user/services/endpoints/fmo-membership/fmo-membership.type";

export const useSubmitVehicleServiceContract= () => {
    return useMutation<any, Error ,VehicleRepairSubscriptionPayload>({
      mutationFn: async (payload) => {
        const res = await vehicleContractEndpoint.SubmitVehicleServiceContract(payload);
        return res;
      },
    });
  };

  export const useSFRepairAgreement= () => {
    return useMutation<any, Error ,VehicleRepairSubscriptionPayload>({
      mutationFn: async (payload) => {
        const res = await vehicleContractEndpoint.SFRepairAgreement(payload);
        return res;
      },
    });
  };
  export const useSFUpdateOdometerStatus= (userId:number,vin:string) => {
    return useMutation<any, Error ,FormData>({
      mutationFn: async (payload) => {
        const res = await vehicleContractEndpoint.SFUpdateOdometerStatus(userId,vin,payload);
        return res;
      },
    });
  };

  
export const useUpdateVehicleServiceContract= () => {
  return useMutation<any, Error ,VehicleRepairSubscriptionPayload>({
    mutationFn: async (payload) => {
      const res = await vehicleContractEndpoint.UpdateVehicleServiceContract(payload);
      return res;
    },
  });
};



  export const useProcessVehicleServiceContract= (vin:string) => {
    return useMutation<any, Error >({
      mutationFn: async () => {
        const res = await vehicleContractEndpoint.ProcessVehicleServiceContract(vin);
        return res;
      },
    });
  };

  export const useGetVehicleServiceContract = (isPrivate:boolean) => {
    return useQuery<VehicleRepairSubscriptionPayload[], Error>({
      queryKey: ["vehicle-service-contract"],
      queryFn: async () => {
        const res = await vehicleContractEndpoint.GetVehicleServiceContract();
        return res;
      },
      enabled: isPrivate,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchInterval: false,
      retry:0
    });
  };

  export const useGetSFOdometerFile = (vin:string) => {
    return useQuery<string, Error>({
      queryKey: ["vin-warrenty-file",vin],
      queryFn: async () => {
        const res = await vehicleContractEndpoint.GetSFOdometerFile(vin);
        return res;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchInterval: false,
      retry:0
    });
  };