import { ChatIcon } from "./assets/chat.icon";
import { TicketIcon } from "./assets/ticket.icon";
import { TurboIcon } from "./assets/turbo.icon";

export const cards = [
  {
    cardNumber: "01",
    title: "Support Ticket",
    subtitle:
      "Whether it's a technical issue, a question about your purchase, or a suggestion for improvement, head over to the top right corner of any page and submit a ticket.",
    icon: <TicketIcon className="absolute bottom-8 w-12 h-auto object-cover" />,
  },
  {
    cardNumber: "02",
    title: "Live Chat",
    subtitle:
      "Click the chat icon in the top right corner of any page and chat instantly with our friendly support team.",
    icon: <ChatIcon className="absolute bottom-8 w-12 h-auto object-cover" />,
    extra: "Available 24/7",
  },

  {
    cardNumber: "03",
    title: "Turbo",
    subtitle:
      "General Questions about anything with a motor? Use Turbo! Our Chat bot, Turbo, is constantly improving to provide you with top notch information.",
    icon: <TurboIcon className="absolute bottom-8 w-12 h-auto object-cover" />,
  },
];

export const accordions = [
  {
    title:
      "FAQs: Explore our comprehensive list of frequently asked questions for quick answers to common concerns.",
    accordion: [
      {
        q: "What is findmyoptions.com?",
        a: "Findmyoptions.com was created to revolutionize the automotive industry in all aspects. We help dealers create seamless and accurate listings and users take advantage of a full online digital car buying experience.",
      },
      {
        q: "Can I ship a car even if it’s not part of a sale?",
        a: (
          <>
            Yes, we are an online vehicle shipping platform, get a quote here(
            <a
              href="#/listing/tools/vehicle-shipping-quote"
              className="text-[#018FFD] hover:text-[#3a80b6]"
            >
              link
            </a>
            ), you can ship standalone without a sale, or have a vehicle shipped
            through a transaction. You can also opt out for shipping if you want
            to do a local pickup from a seller. 
          </>
        ),
      },
      {
        q: "Is it true that I don’t have to goto the DMV to register my vehicle anymore?",
        a: "Yes, we have created a digital process for you to skip the line at the DMV! ISN’T THAT GREAT NEWS!!! No more 3 hour lines. You can do this for a private party transaction or for a dealer transaction.",
      },
      {
        q: "What does the FMO membership include?",
        a: (
          <>
            You can get more information about our membership here(
            <a
              href="#/listing/membership-advantage/fmo-membership"
              className="text-[#018FFD] hover:text-[#3a80b6]"
            >
              link
            </a>
            )
          </>
        ),
      },
    ],
  },
  {
    title: "For Buyers:",
    accordion: [
      {
        q: "How do I find the car I'm looking for?",
        a: "You can simplify your search by using Turbo our chatbot",
      },
      {
        q: "What is the buying process like?",
        a: "It’s a completely digital process and can be completed on our website including, shipping, payment, and DMV.",
      },
      {
        q: "Is it safe to buy a car online?",
        a: "Safety is what we built our site on, we use escrow.com as a third party to transactions as well as having our own insurance for every single transaction.",
      },
      {
        q: "How do I finance my car purchase?",
        a: "You can fill out a digital credit application on our site and send it directly to any dealer you are working with.",
      },
      {
        q: "What happens if I don't like the car after I buy it?",
        a: "You have a 3 day money back guarantee and inspection period. After the inspection period, the vehicle is yours. If you are not satisfied with the vehicle within the inspection period you will have to pay to ship the vehicle back to the dealership. If you feel there is any gross negligence or misrepresentation involved please reach out to our customer service team to assist you. ",
      },
      {
        q: "Do you accept trade-ins?",
        a: "That will depend on the dealer you are working with, there will be more time involved in the transaction and also more shipping costs if needed.",
      },
      {
        q: "How do you protect my privacy?",
        a: (
          <>
            Please visit our privacy policy page here(
            <a href="#" className="text-[#018FFD] hover:text-[#3a80b6]">
              link
            </a>
            )
          </>
        ),
      },
    ],
  },
  {
    title: "For Dealers:",
    accordion: [
      {
        q: "What are the benefits of using your dealer software?",
        a: (
          <>
            Visit our dealer information page here (
            <a
              href="#/listing/dealer-services/what-we-do"
              className="text-[#018FFD] hover:text-[#3a80b6]"
            >
              Link
            </a>
            )
          </>
        ),
      },
      {
        q: "Does the software integrate with my existing systems?",
        a: "We encompass most systems that our dealers use, you can get rid of the software you’re currently using. If you have a specific integration that you would like to complete we can possibly create you a custom option if it’s warranted.",
      },
      {
        q: "How do I train my staff on the software?",
        a: "We pride ourselves on making our software so easy to use that a baby can navigate it, please reach out to our support team if you have questions.",
      },
      {
        q: "What kind of customer support do you offer?",
        a: "We offer 24/7 Live Chat and Support Tickets for non urgent issues.",
      },
      {
        q: "Schedule Demo",
        a: "Interested in seeing our dealer software in action? Schedule a personalized demo with our experts.",
      },
      {
        q: "Request a Quote",
        a: "Contact our sales team via live chat or support ticket to discuss your specific needs and get a tailored quote for our software solutions.",
      },
    ],
  },
];

export const socialMedia = [
  {
    src: "/assets/icons/fb-footer.svg",
    alt: "facebook",
    url: "https://www.facebook.com/findmyoptions",
  },
  {
    src: "/assets/icons/insta-footer.svg",
    alt: "insta",
    url: "https://www.instagram.com/findmyoptions/",
  },
  {
    src: "assets/icons/x-footer.svg",
    alt: "x",
    url: "https://twitter.com/findmyoptions",
  },
  {
    url: "https://www.linkedin.com/company/findmyoptions",
  },
  {
    src: "/assets/icons/youtube-footer.svg",
    alt: "youtube",
    url: "https://www.youtube.com/@findmyoptions",
  },
];
