import { Modal, ModalContent } from "@nextui-org/react";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

interface GradientModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  children?: React.ReactNode;
  size?: "3xl" | "2xl"
}
export const GradientModal: React.FC<GradientModalProps> = ({
  isOpen,
  onOpenChange,
  children,
  size = "3xl"
}) => {
  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior="normal"
      shadow="lg"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
        base: "mb-40",
        closeButton:
          "hover:bg-transparent mt-2 mr-2 text-white border border-white rounded-full  cursor-pointer",
      }}
      className="border border-blue-600 rounded-3xl"
      size={size}
      closeButton={<BlackCloseXIcon width={"32px"} tabIndex={-1} height={"32px"} color="white" fill="white" className="hover:bg-transparent" />}
     
    >
      <ModalContent>
        {(onClose) => {
          return (
            <div
              className={`h-[500px] xl:w-full bg-gradient-to-r from-[#070c84] to-[#018ffd] overflow-y-auto xl:overflow-y-hidden`}
            >
              {children}
            </div>
          );
        }}
      </ModalContent>
    </Modal>
  );
};
