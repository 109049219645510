import { Typography } from "components";
import { Button, Card } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { env } from "config/env";
import { ContractIcon } from "./assets/ContractIcon";
import { GarageIcon } from "./assets/GarageIcon";
import { StarIcon } from "./assets/StarIcon";
import { ChatQouteIcon } from "./assets/ChatQuoteIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import { changeTicketView } from "modules/dealer/store/user-information";
import { RequestedLoanTerms } from "../apply-finance/RequestedLoanTerms";

interface RssItem {
  title: string;
  link: string;
  guid: string;
  pubDate: string;
  description: string;
  source: string;
}
const cardsData = [
  {
    id: "1",
    link: "/user/benefits/repair-agreement",
    imageSrc: <GarageIcon className="min-w-12 h-auto" />,
    mainText: "List your Car for Sale/ ",
    subText: "Instant offer",
  },
  {
    id: "2",
    link: "/user/benefits/fmo-membership",
    imageSrc: <StarIcon className="min-w-12 h-auto" />,
    mainText: "View Active Listings",
    subText: "",
  },
  {
    id: "3",
    link: "/user/enter-vin",
    imageSrc: <ContractIcon className="min-w-12 h-auto" />,
    mainText: "Financing",
    subText: "",
  },
  {
    id: "4",
    link: "/listing/private-party-services/sell-instant-quote",
    imageSrc: <ChatQouteIcon className="min-w-12 h-auto" />,
    mainText: "Request Service/",
    subText: "Repair",
  },
];
export const UserDashboardPage = () => {
  const userInfo = useSelector((state: RootState) => state.user.info);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";
  const CORS_PROXY = "https://api.allorigins.win/raw?url=";

  const [items, setItems] = useState<RssItem[]>(
    JSON.parse(localStorage.getItem("news") || "[]")
  );
  const [openModal, setOpenModal] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const perPage = 5;
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const formatRelativeTime = (pubDateStr: string) => {
    if (!pubDateStr) {
      return "Date unknown";
    }

    const pubDate = new Date(pubDateStr);
    if (isNaN(pubDate.getTime())) {
      return "Invalid date";
    }

    const now = new Date();
    const differenceInMs = now.getTime() - pubDate.getTime();

    const minutes = Math.floor(differenceInMs / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [isLoading, hasMore]);
  useEffect(() => {
    fetchRss();
  }, [page]);
  const fetchRss = async () => {
    if (!hasMore) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${CORS_PROXY}${env.NEWS_API_URL}search?q=car+and+driver&hl=en-IN&gl=IN&ceid=IN:en`
      );
      if (response.status !== 200) throw new Error("error fetching");

      const text = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, "text/xml");
      const rawItems = xmlDoc.querySelectorAll("item");

      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1); // Set the date to one day ago

      const newItems = Array.from(rawItems)
        .map((item) => {
          const titleElement = item.querySelector("title");
          const linkElement = item.querySelector("link");
          const guidElement = item.querySelector("guid");
          const pubDateElement = item.querySelector("pubDate");
          const descriptionElement = item.querySelector("description");
          const sourceElement = item.querySelector("source");

          const descriptionHtml = descriptionElement
            ? descriptionElement.textContent || ""
            : "";
          const descriptionDoc = parser.parseFromString(
            `<div>${descriptionHtml}</div>`,
            "text/html"
          );
          const descriptionText = descriptionDoc.body.textContent || "";

          return {
            title: titleElement ? titleElement.textContent || "" : "",
            link: linkElement ? linkElement.textContent || "" : "",
            guid: guidElement ? guidElement.textContent || "" : "",
            pubDate: pubDateElement ? pubDateElement.textContent || "" : "",
            description: descriptionText,
            source: sourceElement
              ? sourceElement.getAttribute("url") || ""
              : "",
          };
        })
        .filter((item) => new Date(item.pubDate) > oneDayAgo); // Filter items from the last day

      newItems.sort(
        (a, b) => new Date(b.pubDate).getTime() - new Date(a.pubDate).getTime()
      );
      console.log("updated new to local");
      localStorage.setItem("news", JSON.stringify(newItems));

      // Implement pagination logic
      const itemsToDisplay = newItems.slice(
        page * perPage,
        (page + 1) * perPage
      );
      setItems((prevItems) => [...prevItems, ...itemsToDisplay]);
      setHasMore(itemsToDisplay.length === perPage);
    } catch (error) {
      console.error("RSS Fetch Error: ", error);
      const localNews = JSON.parse(localStorage.getItem("news") || "[]");
      if (localNews.length > 0) {
        const itemsToDisplay = localNews.slice(
          page * perPage,
          (page + 1) * perPage
        );
        setItems((prevItems) => [...prevItems, ...itemsToDisplay]);
        setHasMore(itemsToDisplay.length === perPage);
      } else {
        setHasMore(false);
      }
    }

    setIsLoading(false);
  };
  const handleNavigateCard = (item: (typeof cardsData)[0]) => {
    // navigate(item.link)
    switch (item.id) {
      case "1": {
        setOpenModal(item.id);
        break;
      }
      case "2": {
        navigate("/user/garage", {state : { active : 1 }});
        break;
      }
      case "3": {
        setOpenModal(item.id);
        break;
      }
      case "4": {
        dispatch(changeTicketView("1"));
        break;
      }
    }
  };

  return (
    <>
      <div>
        <Typography variant="pageTitle">
          Welcome {userInfo?.firstName}!
        </Typography>
        <div className="grid gap-5 cols grid-cols-1 md:grid-cols-2 xl:grid-cols-4 pt-8 justify-items-center">
          {cardsData.map((card, index) => (
            <div
              onClick={() => handleNavigateCard(card)}
              className="w-full max-w-[485px]"
            >
              <Card
                key={index}
                // onClick={() => navigate(``)}
                className="hover:scale-105 cursor-pointer bg-gradient-to-b from-[#018ffd] to-[#070c84] h-[190px]  rounded-lg flex flex-col items-center justify-center"
              >
                {card.imageSrc}
                <div className="text-xl text-white mt-5">{card.mainText}</div>
                <div className="text-xl text-white">{card.subText}</div>
              </Card>
            </div>
          ))}
        </div>
        <Typography variant="pageTitle" className="py-3 text-start pt-24">
          <div>Automotive News Feed</div>
        </Typography>
        {items.map((res) => {
          return (
            <div className="mt-5 ">
              <div className="border border-blue-400 p-2 lg:p-5 lg:px-7 rounded-2xl">
                <div className="hidden lg:block">
                  {/* <div className="flex items-center">
                  <Image src={wbay} />{" "}
                  <span className="text-xs ml-2">WBAY</span>
                </div> */}
                  <div className="flex items-center">
                    <div className="w-full">
                      <div className="text-2xl font-bold  w-[95%]">
                        {res.title}
                      </div>
                      <div className="mt-5 w-[95%]">{res.description}</div>
                      <a
                        className="text-blue-400 cursor-pointer"
                        href={res.link}
                        target="_blank"
                      >
                        Read more...
                      </a>
                      <div className="mt-10 ">
                        {formatRelativeTime(res.pubDate)}.
                      </div>
                    </div>
                    {/* <img
                    src={tempCar}
                    className=" min-w-[300px] 2xl:min-w-[350px] h-[300px]  object-cover rounded-2xl"
                  /> */}
                  </div>
                </div>
                <div className="block lg:hidden">
                  {/* <div className="flex items-center">
                  <Image src={wbay} />{" "}
                  <span className="text-xs ml-2">WBAY</span>
                </div> */}
                  <p className=" text-2xl font-bold font-poppins text-start mb-5">
                    {res.title}
                  </p>
                  {/* <img
                  src={tempCar}
                  alt="ghost"
                  className=" h-[200px] md:h-[250px] w-[220px] md:w-[300px] float-right custom-shape p-2 object-cover rounded-2xl"
                /> */}

                  <p className="font-poppins text-start text-[15px] break-all	">
                    {res.description}
                  </p>
                  <div className="mt-10 ">
                    {formatRelativeTime(res.pubDate)}.
                  </div>
                  <a
                    className="text-blue-400 cursor-pointer"
                    href={res.link}
                    target="_blank"
                  >
                    Read more...
                  </a>
                </div>
              </div>
            </div>
          );
        })}
        <div className="text-center p-5 text-2xl" ref={loadMoreRef}>
          {isLoading && <div>Loading...</div>}
        </div>
      </div>
      <CustomizableHeaderModel
        size={openModal === "In_House_Financing" || openModal === "Standard_Financing"? "5xl":"2xl"}
        isOpen={openModal !== null}
        onOpen={() => {
          setOpenModal(null);
        }}
        Component={
          openModal === "In_House_Financing" || openModal === "Standard_Financing" ? (
            <div className="relative ">
            <RequestedLoanTerms financeOption={openModal as any} vin={""} listing={{} as any} isPreRequest={true} />
            </div>
          ):<>
            <ShowStatusForFinaceApplication
              linkPaths={Path_ONE_OPTS[openModal || "1"]}
              handleNext={(path: string) => {
                if (path === "5" || path === "In_House_Financing" || path === "Standard_Financing" ) {
                  
                  setOpenModal(path);
                } else {
                  navigate(path);
                }
              }}
            />
          </>
        }
      />
    </>
  );
};

const Path_ONE_OPTS: {
  [key: string]: Array<{
    label: string;
    path: string;
    note?: string;
  }>;
} = {
  "1": [
    { label: "List Your Car for Sale", path: "/user/enter-vin" },
    {
      label: "Instant Offer",
      path: "/listing/private-party-services/sell-instant-quote",
    },
  ],
  "3": [
    { label: "Get Pre Approved", path: "5" }, //5 -> secone
    { label: "Apply For Saved Vehicle", path: "/user/garage/saved-vehicles" },
    { label: "View My Loan", path: "/user/garage/loan-payments" },
  ],
  "5": [
    { label: "Standard Financing", path: "Standard_Financing" },
    {
      label: "In House Financing",
      path: "In_House_Financing",
      note: "(for bad/no credit)",
    },
  ],
};

const ShowStatusForFinaceApplication = ({
  linkPaths,
  handleNext,
}: {
  linkPaths: (typeof Path_ONE_OPTS)["1"][0][];
  handleNext: (path: string) => void;
}) => {
  return (
    <div className="w-full px-4 md:px-20 py-32 flex flex-col items-center">
      <div className="w-full max-w-64">
        {linkPaths.map(({ path, label, note }) => {
          return (
            <>
              <Button
                type="button"
                onClick={() => handleNext(path)}
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm sm:text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
              >
                {label}
              </Button>
              {note && (
                <Typography
                  variant="custom"
                  className={`font-semibold text-center text-base md:text-lg mt-5`}
                >
                  {note}
                </Typography>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
