import React from "react";
import card_logo from "../../../dealer/features/shipping-quotes/assets/card-logo.png";
import master_card_logo from "../../../dealer/features/shipping-quotes/assets/Mastercard_Logo.svg";
import {
  AddCardMethodPayload,
  PaymentCardInfo,
} from "services/endpoints/shipping-quotes/shipping.type";
import Form from "components/ui/form";
import { AddPaymentValidationSchema } from "pages/shipping-quotes/new-order/new-order.validation";
import Input from "components/ui/input";
import { Button } from "@nextui-org/react";
import { cc_expires_format, getFormatedDateToday } from "utils";
import { AddressInput, Toggle, Typography } from "components";
// import { env } from "config/env";
// import { api } from "utils/api";
import { useGetZipCodeDetails } from "services";
import { useDebounceValue } from "usehooks-ts";
import { Controller } from "react-hook-form";
import { getUserCaimData } from "utils/get-user-id";
import { z } from "zod";
import AmericanExpLogo from "./AmericanExpressLogo.png";
import VisaLogo from "./Visa-logo.png";
const emailSchema = z.string().email();

interface Props {
  showmodal?: boolean;
  handleNext?: (data: AddCardMethodPayload) => void;
  savedMethod?: PaymentCardInfo | null;
  payAmmount?: string;
  isPending?: boolean;
  showAmmount?: boolean;
  showFooterNote?: boolean;
  discountPrice?: string;
  errorMessage?: string;
  lableBtn?:string
}

const PaymentFormWithDetails: React.FC<Props> = ({
  showmodal = false,
  handleNext,
  savedMethod,
  payAmmount,
  isPending,
  showAmmount = true,
  showFooterNote = true,
  discountPrice,
  errorMessage,
  lableBtn=""
}) => {
  const emailFromCaim = getUserCaimData("email");
  const [email, setEmail] = React.useState({
    data: emailFromCaim,
    error: !emailFromCaim,
  });

  const [rfChecked, setRFChecked] = React.useState({
    checked: false,
    data: "",
    error: false,
  });
  const [zipValue, setZipValue] = React.useState("");
  const [debouncedValue] = useDebounceValue(zipValue, 300);
  const { mutateAsync: zipcodeMutateAsync, data: zipcodeData } =
    useGetZipCodeDetails(debouncedValue);

  React.useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  const handleSubmit = (e: any) => {
    try {
      savedMethod && e.preventDefault();
      if (email.data.trim().length === 0) {
        setEmail((p) => ({ ...p, error: true }));
        return;
      }
      if (rfChecked.checked && Number(rfChecked.data) < 1) {
        setRFChecked((prev) => ({ ...prev, error: true }));
        return;
      }
      const shouldGoNext = savedMethod || zipcodeData;
      if (handleNext && shouldGoNext) {
        const payload = {
          zipcode: zipValue,
          ...e,
          city: zipcodeData?.city ?? "",
          county: zipcodeData?.county ?? "",
          stateAbbr: zipcodeData?.stateAbbr ?? "",
          state: zipcodeData?.state ?? "",
          ref_num: rfChecked.data,
          email: email.data,
          cardNumber: e?.cardNumber?.replace(/ /g, ""),
        };
        handleNext(payload);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="w-full ">
      <div className="m-auto  max-w-screen-sm px-4 ">
        {showAmmount && (
          <div className="text-xs font-bold text-center mb-4">
            Payment Amount
            <div className="text-3xl font-bold  text-blue-900">
              {`$${payAmmount}`}
            </div>
          </div>
        )}
        <div className={`rounded-xl px-6 ${savedMethod ? "py-8" : ""}`}>
          {savedMethod ? (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="cardNumber"
                  className="text-xs block mb-2 font-bold"
                >
                  Saved Payment Methods
                </label>
                <div className=" flex items-center justify-between px-4 border-2 border-black rounded-xl py-2 w-full focus:outline-none ">
                  <div className="flex  items-center ">
                    <input
                      id="cardNumber"
                      name="cardNumber"
                      type="radio"
                      value=""
                      checked={true}
                      // onChange={handleChange}
                      className="w-6 h-6 outline-none"
                    />
                    {savedMethod?.payment?.Item?.cardType ===
                    "AmericanExpress" ? (
                      <img
                        src={AmericanExpLogo}
                        alt="AmericanExpLogo-card"
                        className="max-w-16 max-h-16 p-2 "
                      />
                    ) : savedMethod?.payment?.Item?.cardType === "Visa" ? (
                      <img
                        src={VisaLogo}
                        alt="VisaLogo-card"
                        className="max-w-16 max-h-16 p-2 "
                      />
                    ) : (
                      <img
                        src={master_card_logo}
                        alt="master-card"
                        className="max-w-16 max-h-16"
                      />
                    )}
                  </div>
                  <p className="font-bold text-base md:text-2xl text-center">
                    {addSpaceEveryFourChars(
                      savedMethod?.payment?.Item?.cardNumber || "N/A"
                    )}
                  </p>
                </div>
              </div>
              <div className="text-right mb-4">
                <button
                  type="button"
                  className="text-gray-500 text-xs font-bold"
                >
                  Use another Card
                </button>
              </div>

              <div
                // key={id}
                className="flex items-center justify-between  my-4"
              >
                <label
                  htmlFor={"Reference_Number"}
                  className="text-xs  block mb-1 font-bold"
                >
                  {"Reference Number"}
                </label>
                <Toggle
                  size="sm"
                  checked={rfChecked.checked}
                  onChange={() =>
                    setRFChecked((p) => ({
                      ...p,
                      checked: !p.checked,
                    }))
                  }
                />
              </div>
              {rfChecked.checked && (
                <div className="flex  justify-center w-full my-4 " tabIndex={0}>
                  <Input
                    variant="form"
                    size="compact"
                    errorMessage={
                      rfChecked.error && "Reference Number is required"
                    }
                    type="text"
                    id={"Reference Number"}
                    
                    classNames={{
                      input: "font-bold  w-full focus:outline-none text-center",
                      inputWrapper: rfChecked.error
                        ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                        : "",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (Number(value) && Number(value) > 0) {
                        setRFChecked((prev) => ({
                          ...prev,
                          data: value,
                          error: Number(value) === 0,
                        }));
                      } else return (e.target.value = "");
                    }}
                  />
                </div>
              )}
              <div className="mb-4">
                <Button
                  type="submit"
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl "
                  isLoading={isPending}
                  tabIndex={0}
                >
                  {payAmmount&& "Pay "}
                  {discountPrice && (
                    <span>
                      (<span className="line-through ">${discountPrice}</span>)
                    </span>
                  )}{" "}
                  {payAmmount&&"  $"}
                  {payAmmount && `${payAmmount}`}
                  {lableBtn}

                </Button>
                {errorMessage && !isPending && (
                  <p className="text-sm  block mt-4 font-bold text-center text-red-500">
                    {errorMessage}
                  </p>
                )}
              </div>
            </form>
          ) : (
            <Form
              id="new-order-shipping-form"
              schema={AddPaymentValidationSchema}
              onSubmit={handleSubmit}
              className="md:px-10"
            >
              {({
                formState: { errors, isSubmitted, isSubmitSuccessful },
                register,
                control,
                setValue,
                getValues,
              }) => {
                return (
                  <>
                    {[{ id: "cardNumber", label: "Card Number" }].map(
                      ({ id, label },index) => {
                        return (
                          <div className="" key={id}>
                            <label
                              htmlFor={id}
                              className="text-xs block mb-1 font-bold"
                            >
                              {label}
                            </label>
                            <Controller
                              control={control}
                              name={id as any}
                              render={({ field }) => (
                                <Input
                                  variant="form"
                                  size="compact"
                                  tabIndex={index}
                                  errorMessage={
                                    errors[id as keyof typeof errors]?.message
                                  }
                                  type="text"
                                  id={id}
                                  maxLength={19}
                                  {...field}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const formattedValue = inputValue
                                      .replace(/\D/g, "")
                                      .replace(/(\d{4})(?!$)/g, "$1 "); // Remove non-numeric characters and add 4 spaces
                                    return field.onChange(formattedValue);
                                  }}
                                  classNames={{
                                    input:
                                      "font-bold  w-full focus:outline-none text-center",
                                    inputWrapper: !!errors[
                                      id as keyof typeof errors
                                    ]
                                      ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                      : "",
                                  }}
                                />
                              )}
                            />
                          </div>
                        );
                      }
                    )}
                    <div className="flex flex-wrap -mx-2 mb-1">
                      <div className="w-full md:w-1/2 px-2 mb-2 md:mb-0">
                        <label
                          htmlFor="expiryDate"
                          className="text-xs block mb-1 font-bold"
                        >
                          Expiry Date
                        </label>
                        <Controller
                          control={control}
                          name="expiryDate"
                          render={({ field }) => (
                            <Input
                              variant="form"
                              size="compact"
                              errorMessage={errors.expiryDate?.message}
                              type="text"
                              placeholder="MM/YY"
                              tabIndex={2}
                              maxLength={5}
                              id="expiryDate"
                              // {...register("expiryDate")}
                              {...field}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /\D/g,
                                  ""
                                );
                                const result =
                                  cc_expires_format(formattedValue);
                                return field.onChange(result);
                              }}
                              classNames={{
                                input:
                                  "font-bold w-full focus:outline-none text-center",
                                inputWrapper: !!errors.expiryDate
                                  ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                  : "",
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-2">
                        <label
                          htmlFor="cvv"
                          className="text-xs block mb-1 font-bold"
                        >
                          CVV
                        </label>
                        <Controller
                          control={control}
                          name="cvv"
                          render={({ field }) => (
                            <Input
                              variant="form"
                              size="compact"
                              errorMessage={errors.cvv?.message}
                              type="password"
                              placeholder=""
                              maxLength={4}
                              tabIndex={3}
                              id="cvv"
                              // {...register("cvv")}
                              {...field}
                              classNames={{
                                input:
                                  "font-bold w-full focus:outline-none text-center",
                                inputWrapper: !!errors.cvv
                                  ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                  : "",
                              }}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedValue = inputValue.replace(
                                  /\D/g,
                                  ""
                                ); // Remove non-numeric characters

                                return field.onChange(formattedValue);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <Typography variant="custom" className="font-bold">
                      Billing Information
                    </Typography>
                    <div className="flex flex-wrap -mx-2 mb-1">
                      {[
                        {
                          id: "fname",
                          label: "First Name",
                          style: "w-full md:w-1/2 px-2 mb-1 md:mb-0",
                          placeholder: "",
                          type: "text",
                        },
                        {
                          id: "lname",
                          label: "Last Name",
                          style: "w-full md:w-1/2 px-2",
                          placeholder: "",
                          type: "text",
                        },
                      ].map(({ id, label, style, placeholder, type },index) => {
                        return (
                          <div className={style} key={id}>
                            <label
                              htmlFor={id}
                              className="text-xs block mb-1 font-bold"
                            >
                              {label}
                            </label>
                            <Controller
                              control={control}
                              name={id as any}
                              render={({ field }) => (
                                <Input
                                  variant="form"
                                  size="compact"
                                  errorMessage={
                                    errors[id as keyof typeof errors]?.message
                                  }
                                  type={type}
                                  placeholder={placeholder}
                                  id={id}
                                  // {...register(id as any)}
                                  tabIndex={index+5}
                                  {...field}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const formattedValue = inputValue.replace(
                                      /[^a-zA-Z]/g,
                                      ""
                                    );

                                    return field.onChange(formattedValue);
                                  }}
                                  classNames={{
                                    input:
                                      "font-bold  w-full focus:outline-none text-center",
                                    inputWrapper: !!errors[
                                      id as keyof typeof errors
                                    ]
                                      ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                      : "",
                                  }}
                                />
                              )}
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className="">
                      <label
                        htmlFor={"email"}
                        className="text-xs block mb-1 font-bold"
                      >
                        Email
                      </label>
                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={
                          isSubmitted &&
                          email.error &&
                          "Please provide a valid Email"
                        }
                        type="text"
                        defaultValue={email.data}
                        isDisabled={!!emailFromCaim}
                        id={"email"}
                        tabIndex={7}
                        // {...register("email")}
                        onChange={(e) => {
                          const formatedValue = e.target.value.trim();
                          try {
                            setEmail((p) => ({ ...p, data: formatedValue }));
                            emailSchema.parse(formatedValue);
                            setEmail((p) => ({ ...p, error: false }));
                          } catch (e) {
                            setEmail((p) => ({ ...p, error: isSubmitted }));
                          }
                        }}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper:
                            isSubmitted && email.error
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                        }}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor={"zipcode"}
                        className="text-xs block mb-1 font-bold"
                      >
                        ZIP Code
                      </label>
                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={
                          !zipcodeData && isSubmitted && "ZIP Code is Invalid"
                        }
                        type="text"
                        id={"zipcode"}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper:
                            !zipcodeData && isSubmitted
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                        }}
                        maxLength={5}
                        tabIndex={8}
                        value={zipValue}
                        onChange={(e) => {
                          const formattedValue = e.target.value?.replace(
                            /\D/g,
                            ""
                          );
                          setZipValue(formattedValue);
                        }}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor={"address"}
                        className="text-xs block mb-1 font-bold"
                      >
                        Address
                      </label>
                      <AddressInput
                        zipData={zipcodeData}
                        handleSelect={(value) => setValue("address", value)}
                        placeholder=""
                        InputComponent={(props) => {
                          return (
                            <Controller
                              control={control}
                              name="address"
                              render={({ field }) => (
                                <Input
                                  key={"address"}
                                  variant="form"
                                  size="compact"
                                  errorMessage={
                                    errors["address" as keyof typeof errors]
                                      ?.message
                                  }
                                  type="text"
                                  tabIndex={9}
                                  classNames={{
                                    input:
                                      "font-bold  w-full focus:outline-none text-center",
                                    inputWrapper: !!errors[
                                      "address" as keyof typeof errors
                                    ]
                                      ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                      : "",
                                  }}
                                  {...props}
                                  id={"address"}
                                  value={getValues("address")}
                                  onChange={(e) => {
                                    props.onChange(e);
                                    return field.onChange(e);
                                  }}
                                />
                              )}
                            />
                          );
                        }}
                      />

                      {/* {suggestions.length > 0 && (
                        <div className="z-50 w-[70%]  absolute text-start border-3 rounded-md">
                          {suggestions.map((result, index) => (
                            <div
                              key={index}
                              className="w-full p-2 pl-10 bg-white rounded-md cursor-pointer hover:bg-gray-200"
                              onClick={() => {
                                register("address").onChange({
                                  target: { value: result, name: "address" },
                                } as any);
                                setSuggestions([]);
                              }}
                            >
                              {result}
                            </div>
                          ))}
                        </div>
                      )} */}

                      <div
                        // key={id}
                        className="flex items-center justify-between mt-2 md:mb-0 mb-2"
                      >
                        <label
                        tabIndex={10}
                          htmlFor={"Reference_Number"}
                          className="text-xs  block mb-1 font-bold"
                        >
                          {"Reference Number"}
                        </label>
                        <Toggle
                          size="sm"
                          checked={rfChecked.checked}
                          
                          id="Reference_Number"
                          
                          onChange={() =>
                            setRFChecked((p) => ({
                              ...p,
                              checked: !p.checked,
                            }))
                          }
                        />
                      </div>
                      {rfChecked.checked && (
                        <div className="flex  justify-center w-full mt-2 ">
                          <Input
                            variant="form"
                            size="compact"
                            errorMessage={
                              rfChecked.error && "Reference Number is required"
                            }
                            type="text"
                            id={"Reference Number"}
                            classNames={{
                              input:
                                "font-bold  w-full focus:outline-none text-center",
                              inputWrapper: rfChecked.error
                                ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                : "",
                            }}
                            tabIndex={11}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (Number(value) && Number(value) > 0) {
                                setRFChecked((prev) => ({
                                  ...prev,
                                  data: value,
                                  error: Number(value) === 0,
                                }));
                              } else return (e.target.value = "");
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="">
                      <Button
                        type="submit"
                        isLoading={isPending}
                        tabIndex={12}
                        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl "
                      >
                        {showAmmount ? "Pay" : (lableBtn?lableBtn : "Add Card")}{" "}
                        {discountPrice && (
                          <span>
                            (
                            <span className="line-through ">
                              ${discountPrice}
                            </span>
                            )
                          </span>
                        )}{" "}
                        {showAmmount && `$${payAmmount}`}
                      </Button>
                      {isSubmitSuccessful && errorMessage && (
                        <p className="text-sm  block mt-2 font-bold text-center text-red-500">
                          {errorMessage}
                        </p>
                      )}
                    </div>
                  </>
                );
              }}
            </Form>
          )}
          {(showFooterNote || savedMethod) && (
            <div className="text-xs text-center">
              By Selecting Pay, I accept the Terms of Service and have read and
              acknowledge the Privacy Statement. I also allow Intuit to charge $
              {payAmmount} to my card on {getFormatedDateToday()}
            </div>
          )}
        </div>
        {(showFooterNote || savedMethod) && (
          <div className="mt-4 text-center mb-8">
            <img className="h-12 inline-block" src={card_logo} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentFormWithDetails;
export function addSpaceEveryFourChars(input: string) {
  let output = "";
  for (let i = 0; i < input.length; i++) {
    if (i > 0 && i % 4 === 0) {
      output += " ";
    }
    output += input.charAt(i);
  }
  return output;
}
