import { SVGProps } from "react";

export const CircleRounded = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <rect
        x="2.1539"
        y="2.15194"
        width="9.69231"
        height="9.69231"
        rx="4.84615"
        stroke="#263238"
        stroke-width="0.717949"
      />
    </svg>
  );
};
