import { Accordion, AccordionItem, accordion } from "@nextui-org/react";
import { FaMinus, FaPlus } from "react-icons/fa6";

interface AccordionProp {
  item: any;
}

const ContactUsAccordion = ({ item }: AccordionProp) => {
  const { title, accordion } = item;
  return (
    <div>
      <h1 className="font-[Metropolis] font-bold  pb-4">{title}</h1>
      <Accordion
        className="flex flex-col px-0 gap-4"
        showDivider={false}
        selectionMode="multiple"
      >
        {accordion.map(({ q, a }: any, index: any) => (
          <AccordionItem
            key={index}
            aria-label={q}
            title={q}
            indicator={({ isOpen }) =>
              isOpen ? (
                <FaMinus color="black" className="rotate-90" />
              ) : (
                <FaPlus color="black" />
              )
            }
            className="bg-[#EFEFEF] rounded-xl px-6 py-2 md:text-lg text-start"
          >
            <p className="text-medium font-[Metropolis] lg:text-start">{a}</p>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default ContactUsAccordion;
