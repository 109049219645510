import { Typography } from "components";
import { VinDataDecodeResponse } from "services";
import { Button } from "@nextui-org/react";
import { getFormatedDateToday } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";

interface Props {
  contractType?: boolean;
  handleConfirm: () => void;
  vinData?: VinDataDecodeResponse;
  vin: string;
  milage: number;
  state: { label: string | null; code: string };
  isLoading: boolean;
  activePlanType:boolean,
  payAmmount:string
}

export default function VehicleServiceContract({
  handleConfirm,
  vinData,
  vin,
  milage,
  state,
  contractType = false,
  payAmmount,
  isLoading,
  activePlanType
}: Props) {
  const userInfo = useSelector((state: RootState) => state.user.info);
  
  // const [userDetails, setUserDetails] = useState<any>({});
  // const { data:userDetails  } = useGetCurrentUser(getUserId())
  // useEffect(() => {
  //   async function fetchUserInfo() {
  //     const response = await authApi
  //       .get(`User/GetUserById?id=${getUserId()}`)
  //       .json();
  //     setUserDetails(response);
  //   }
  //   fetchUserInfo();
  // }, []);
  function getValueByVinCode(code: string) {
    if (!vinData?.message) {
      return "N/A";
    }
    const item = vinData.message.find((obj: any) => obj.code === code);
    return item ? item.value : "N/A";
  }
  return (
    <div className=" p-4">
      <Typography className="text-justify">
        {" "}
        This contract IS NOT insurance and is ONLY AVAILABLE in the following
        states:
      </Typography>
      <Typography className="text-justify mb-4">
        Alaska, Connecticut, Delaware, Hawaii, Idaho, Indiana, Maine,
        Nebraska, New Hampshire, North Dakota, Oregon, South Dakota, Vermont,
        and the District of Columbia. If your state of residence is not one of
        these states AND your vehicle is not registered in the same state of
        your residence this agreement is NULL AND VOID.
      </Typography>
      <Typography className="text-justify mb-4">
        **COVERAGE DATE DOES NOT BEGIN UNTIL 30 DAYS AFTER WE RECEIVE YOUR
        VERIFIED ODOMETER READING. YOUR VERIFIED ODOMETER READING WILL COME FROM
        AN OIL AND FILTER CHANGE WITH INSPECTION WITHIN 5 DAYS OF PAYING FOR
        THIS CONTRACT** You are required to pay for this service. This is
        required every one year anniversary of this agreement. Findmyoptions.com
        LLC must approve and can assist in finding a shop for this oil and
        filter change and inspection and may also offer discounts.
      </Typography>
      <Typography className="text-justify mb-4">
        Please Note: Maximum vehicle age is 20 years as of the date of this
        agreement. Maximum Mileage is 120,000 miles as of the date of this
        agreement. Vehicle may not have any non-factory upgrades, options,
        features, or anything other than factory or dealer installed components.
        This contract terminates when the contract expiration months or maximum
        odometer miles is reached, or when the findmyoptions.com LLC has paid
        the maximum benefit amount, whichever occurs first.
      </Typography>
      <Typography className="text-justify mb-4">
        This Vehicle Service Contract ("Contract") is made and entered into as
        of {getFormatedDateToday()} by and between findmyoptions.com LLC, a
        Kentucky corporation with its principal place of business at 6844
        Bardstown Rd #5178 Louisville, KY 40291, and {userInfo?.firstName as string},
        residing at {userInfo?.address || "N/A"} further referred to as
        ("Customer").
      </Typography>
      <Typography className="text-justify mb-4">
        <span className="font-bold"> WHEREAS,</span> Customer desires to
        purchase a vehicle service contract to cover certain repairs and
        services for their vehicle, and findmyoptions.com LLC is willing to
        provide such a contract;
      </Typography>
      <Typography className="text-justify mb-6">
        NOW, THEREFORE, in consideration of the mutual covenants and agreements
        contained herein, the parties agree as follows:
      </Typography>
      <Typography variant="custom" className="text-justify mb-2 font-bold ">
        1. Covered Vehicle:
      </Typography>
      <Typography className="text-justify mb-4">
        This Contract covers the following vehicle:
      </Typography>
      <ul className="list-disc list-inside ml-2 mb-">
        <li>Year: {getValueByVinCode("ModelYear")}</li>
        <li>Make: {getValueByVinCode("Make")}</li>
        <li>Model: {getValueByVinCode("Model")}</li>
        <li>VIN: {vin}</li>
        <li>Mileage: {milage}</li>
        <li>
          Owner of Record State of Residence: {state.label}, {state.code}
        </li>
        <li>
          Vehicle Registration State: {state.label}, {state.code}
        </li>
      </ul>
      <Typography variant="custom" className="text-justify mb-2 mt-4 font-bold">
        2. Term:
      </Typography>
      <Typography className="text-justify mb-4">
        This Contract is effective for 36 months from the date of purchase.
        Mileage is limited to {activePlanType? "30,000" :"45,000"} miles. Not to exceed {activePlanType ?"10,000" : "15,000"} miles per year.
      </Typography>

      <Typography variant="custom" className="text-justify mb-2 font-bold">
        3. Monthly Payment:
      </Typography>
      <Typography className="text-justify mb-4">
        Customer agrees to pay a monthly premium of ${payAmmount} to findmyoptions.com
        LLC during the term of this Contract. Payments are due on the first day
        of each month and must be received by findmyoptions.com LLC within 5
        days of the due date.
      </Typography>
      <Typography variant="custom" className="text-justify mb-2 font-bold">
        4. Waiting Period
      </Typography>
      <Typography className="text-justify mb-4">
        This agreement has a 30 day waiting period for any authorized repairs.
        Any mechanical issues that arise during or before this waiting period
        will not be covered. This waiting period begins when the odometer
        reading is verified and approved by findmyoptions.com findmyoptions.com
        LLC will not pay for repairs to components that have failed, or begun to
        fail, prior to the expiration of the Waiting Period{" "}
      </Typography>
      <Typography variant="custom" className="text-justify mb-2 font-bold">
        5. Maximum Benefit Amounts:
      </Typography>
      <Typography className="text-justify mb-4">
        The maximum benefit amount for all repairs is $4,500
      </Typography>

      <Typography variant="custom" className="text-justify mb-2 font-bold">
        6. Coverage:
      </Typography>
      <Typography className="text-justify mb-4">
        {" "}
        This Contract provides the following coverage: <br />
        <ul className="list-disc list-inside mx-2 ">
          <li>
            {" "}
            Engine: Coverage for repairs to the engine, including internal
            components, gaskets, and seals.
          </li>
          <li>
            {" "}
            Transmission: Coverage for repairs to the transmission, including
            internal components, gaskets, and seals.
          </li>
          <li>
            Drive Axle: Coverage for repairs to the drive axle, including
            differential and axle shafts.
          </li>
          <li>
            Electrical: Coverage for repairs to the electrical system, including
            alternator, starter, and wiring harness.{" "}
          </li>
          <li>
            {" "}
            Air Conditioning: Coverage for repairs to the air conditioning
            system, including compressor, condenser, and evaporator.{" "}
          </li>
        </ul>
      </Typography>
      <Typography variant="custom" className="text-justify mb-2 font-bold">
        7. Additional Benefits{" "}
      </Typography>
      <Typography className="text-justify mb-4">
        <p>
          Roadside Assistance: Coverage for the following roadside assistance
          services:
        </p>

        <ul className="list-disc list-inside mx-2 ">
          <li> Towing to the nearest repair facility up to 25 miles </li>
          <li>Jump start for a dead battery.</li>
          <li>Flat tire change. Using Spare tire. </li>
          <li>Lockout assistance. </li>
          <li>Maximum amount to be paid for roadside assistance is $150 </li>
        </ul>

        <p className="mt-4">
          {" "}
          **Please contact findmyoptions.com 24/7 support in the event of
          needing roadside assistance.
        </p>
      </Typography>

      <Typography variant="custom" className="text-justify mb-2 font-bold">
        8. Exclusions:
      </Typography>
      <Typography className="text-justify mb-4">
        <p>This Contract DOES NOT cover the following:</p>

        <ul className="list-disc ml-4 mx-2 ">
          <li> Normal wear and tear </li>
          <li> Damage caused by negligence, abuse, or misuse </li>
          <li>Pre-existing conditions </li>
          <li>
            Repairs to components that have failed, or begun to fail, prior to
            the expiration of the Waiting Period{" "}
          </li>

          <li>
            Cosmetic or body damage Routine maintenance or parts related to
          </li>
          <li> Glass breakage </li>
          <li>Tires</li>
          <li>Upholstery </li>
          <li>Audio system </li>

          <li>Modifications not approved by the manufacturer </li>
          <li>Electric or Hybrid Vehicle Batteries </li>
          <li>
            This agreement does not cover any vehicle that has been issued a
            salvage or rebuilt title, has ever been issued a branded title for
            assembled, dismantled, scrap, fire, flood, saltwater, junk or parts
            only, or declared a “lemon”, has been declared true mileage unknown,
            has a failed, broken, disconnected or altered odometer, or which the
            actual accumulated mileage cannot be determined for any reason.
          </li>
          <li>Towing Vehicles</li>
          <li>Commercial use Vehicles</li>
        </ul>
      </Typography>
      <Typography className="text-justify mb-2 font-bold" variant="custom">
        9. Claims Procedure:
      </Typography>
      <Typography className="text-justify mb-4">
        <p>In the event of a covered breakdown, Customer must:</p>
        <ul className="list-disc ml-4 mx-2 ">
          <li>
            Contact findmyoptions.com LLC immediately by creating a support
            ticket online or through live chat, or by emailing{" "}
            <span className="underline">support@findmyoptions.com</span>
          </li>
          <li>
            Stop operating the vehicle, this contract will not cover extra
            damage done from continued operation of the vehicle after a
            breakdown of mechanical parts is detected.
          </li>
        </ul>
        <p className="font-bold my-4">
          {" "}
          **NO CLAIMS WILL BE PAID WITHOUT PRIOR AUTHORIZATION FROM
          FINDMYOPTIONS.COM LLC, OR WITHOUT FOLLOWING THIS CLAIMS PROCEDURE**
        </p>
        Communication is key. In the event of mechanical breakdown
        findmyoptions.com LLC will refer you to a vehicle repair shop of its
        choice or hire the services of a mobile mechanic. You agree to allow up
        to 3 days for findmyoptions.com LLC to negotiate terms and costs of
        repair for your vehicle if necessary. You agree to allow
        findmyoptions.com LLC find the lowest cost option to repair your
        vehicle, this Includes but is not limited to: Changing repair or service
        providers and in rare circumstances allowing repairs using
        remanufactured or used parts for certain repairs.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        10. Safety:
      </Typography>
      <Typography className="text-justify mb-4">
        Your safety is our number one priority. If you feel that repairs that
        were completed on your vehicle are unsafe please do not operate the
        vehicle and reach out to us immediately. If you ever feel unsafe at a
        repair facility or with a mobile mechanic please reach out to us
        immediately. If it’s an emergency situation please contact your local
        emergency services.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        11. Vehicle Maintenance and Requirement to Cooperate:
      </Typography>
      <Typography className="text-justify mb-4">
        You are required to keep up with the maintenance schedule as per the
        schedule shown in your vehicles owners manual. You are also responsible
        to cooperate with and communicate with findmyoptions.com and provide
        documentation when it is requested if it pertains to vehicle maintenance
        or repairs, title or registration information, odometer info, or
        anything else vehicle or registration related. Failure to do so will
        result in a non refundable cancellation of this agreement.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        12. Cancellation:
      </Typography>
      <Typography className="text-justify mb-4">
        Customer may cancel this Contract at any time by providing written
        notice to findmyoptions.com LLC. If the cancellation is requested within
        the initial 30 day waiting period, FMO will refund the first month's
        premium. If the cancellation is requested after the waiting period,
        Customer will NOT be refunded for any portion of the unused premiums
        paid.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        13. Disclaimer of Warranties:
      </Typography>
      <Typography className="text-justify mb-4">
        THIS CONTRACT IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EXPRESS
        OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        14. Limitation of Liability:
      </Typography>
      <Typography className="text-justify mb-4">
        findmyoptions.com LLC'S LIABILITY UNDER THIS CONTRACT IS LIMITED TO THE
        TOTAL AMOUNT OF PREMIUMS PAID BY CUSTOMER. findmyoptions.com LLC SHALL
        NOT BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES
        ARISING OUT OF OR RELATING TO THIS CONTRACT.{" "}
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        {" "}
        15. Entire Agreement:
      </Typography>
      <Typography className="text-justify mb-4">
        This Contract constitutes the entire agreement between the parties with
        respect to the subject matter hereof and supersedes all prior or
        contemporaneous communications, representations, or agreements, whether
        oral or written.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        16. Governing Law:
      </Typography>
      <Typography className="text-justify mb-4">
        This Contract shall be governed by and construed in accordance with the
        laws of the State of Kentucky.
      </Typography>

      <Typography className="text-justify mb-2 font-bold" variant="custom">
        17. Severability:
      </Typography>
      <Typography className="text-justify mb-4">
        If any provision of this Contract is held to be invalid or
        unenforceable, such provision shall be struck and the remaining
        provisions shall remain in full force and effect.
      </Typography>
      <Typography className="text-justify mb-2 font-bold" variant="custom">
        {" "}
        18. Arbitration Clause
      </Typography>
      <Typography className="text-justify mb-4">
        <p className="mb-4">
          PLEASE READ THIS CAREFULLY. IT AFFECTS YOUR RIGHTS.
        </p>
        <p className="mb-4">
          All disputes or claims arising out of or relating to this Contract,
          except for those Claims that are not arbitrable under applicable law,
          will be settled by binding arbitration administered by the Federal
          Arbitration Association ("FAA") in accordance with its Consumer
          Arbitration Rules ("Rules"). The Rules are available online at
          http://www.adr.org/consumer or by calling the FAA at 1-800-AAA-SOAR
          (1-800-222-7627). You and findmyoptions.com agree that the arbitration
          will be held in Lexington, KY or online via video conference.
        </p>
        <p className="mb-6">
          IN WITNESS WHEREOF, the parties have executed this Contract as of the
          date first written above.
        </p>
        <p className="mb-8">[CUSTOMER NAME]</p>
        <p className="mb-8">[CUSTOMER SIGNATURE]</p>
      </Typography>
      <div className="flex justify-center items-center flex-col">
        {!isLessThan20YearsOld(Number(getValueByVinCode("ModelYear"))) && (
          <Typography variant="error">
            The car's manufacturing year is over 20 years
          </Typography>
        )}
        <Button
          onClick={() => {
            handleConfirm();
          }}
          isDisabled={
            !isLessThan20YearsOld(Number(getValueByVinCode("ModelYear")))
          }
          className=" md:mr-6 mt-5 w-3/4 max-w-[200px] py-2 text-lg bg-gradient-to-r from-[#018ffd] to-[#070c84]  text-white rounded-lg   focus:outline-none focus:shadow-outline md:text-2xl"
          type="button"
          isLoading={isLoading}
        >
          {"Confirm"}
        </Button>
      </div>
    </div>
  );
}

function isLessThan20YearsOld(year: number) {
  // Get the current year
  var currentYear = new Date().getFullYear();

  // Calculate the difference between the current year and the given year
  var difference = currentYear - year;

  // Check if the difference is less than 20
  if (difference < 20) {
    return true;
  } else {
    return false;
  }
}

