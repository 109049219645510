import { Typography } from "components";
import { listOptionsBHPHF } from "./bhphf.data";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import BhphfMainImg from "./assets/bhphf-img.png";
import "./bhphf.scss";
import AbboutBHPHF from "./components/footer.component";
import WhatNeedForBHPHFCard from "./components/cards.component";
function BuyHerePayHereFinancing() {
  return (
    <div className="flex flex-col gap-16 py-12 bhphf">
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        <Typography variant="pageTitle">Buy Here Pay Here Financing</Typography>
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
          Drive Away Today with Buy Here Pay Here Loans!
        </h2>
        <p className="font-[Metropolis] font-[600] text-left">
          We believe everyone deserves the opportunity to drive a reliable car.
          That's why we partner with dealerships that offer Buy Here Pay Here
          (BHPH) financing options, making it easier to get the car you need,
          regardless of your past credit history.
        </p>
      </div>

      <Typography variant="custom" className=" ">
        <p className="pb-5 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl 2xl:text-3xl font-bold pt-5 text-left">
          Benefits of Buy Here Pay Here Financing:
        </p>
        {listOptionsBHPHF.map((res) => (
          <div className="flex items-start  ">
            <img
              src={checkIcon}
              height={16}
              alt="Y"
              width={16}
              className="mt-3.5"
            />

            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {res.name}
              </span>
              {res.description}
            </p>
          </div>
        ))}
      </Typography>

      <div className="flex w-full ">
        <div className="z-30 w-full flex   md:w-[95%] triangle-gradient relative right-4 sm:right-6 md:right-8 lg:right-[60px] xl:right-[150px] 2xl:right-[250px]">
          <div className="relative my-auto  py-4 sm:py-24 sm:pr-32 left-4 sm:left-6 md:left-8 lg:left-[60px] xl:left-[150px] 2xl:left-[250px]">
            <Typography variant="custom" className="text-white text-lg sm:text-2xl md:texl-3xl font-bold">
              How Does BHPH Financing Work?
            </Typography>
            <Typography variant="custom" className="text-white text-sm xl:text-lg mt-2  w-[95%] sm:w-3/4 max-w-[24rem]">
              BHPH financing allows you to finance the purchase of your car
              directly through one of our participating dealerships. They will
              consider various factors when determining loan approval, such as
              your income and down payment.
            </Typography>
          </div>
        </div>
        <img
          className="w-[60%] h-[32rem] bg-cover   absolute  bg-gray-300 mt-4 right-0"
          src={BhphfMainImg}
          alt="Y"
        />
      </div>


      <WhatNeedForBHPHFCard />
      <AbboutBHPHF />
    </div>
  );
}

export default BuyHerePayHereFinancing;
