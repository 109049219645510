import LabelInfoCardGradiant from "./card.component";
import { cards } from "../traditional-financing.data";

const BenefitsOfTraditionalFinancing = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center mb-8 text-center">
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
          Beyond Rates: The Benefits of Our Traditional Financing:
        </h2>
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2 lg:flex-row gap-8">
        <div className="my-auto">
          <LabelInfoCardGradiant card={cards[0]} />
        </div>
        <div className="flex flex-col gap-4">
          <LabelInfoCardGradiant card={cards[1]} />
          <LabelInfoCardGradiant card={cards[2]} />
        </div>
      </div>
    </div>
  );
};

export default BenefitsOfTraditionalFinancing;
