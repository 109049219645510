import React from "react";
import TierCards from "pages/public/dealer-services-pricing/TierCards";

const TierDetails = () => {
  return (
    <div className="border shadow-lg rounded-3xl bg-white mh-1/2 m-2">
      <h1 className="text-[#070C84] m-4 text-2xl">Tier Details</h1>
      <div className="m-4">
      <TierCards />
      </div>
    </div>
  );
};

export default TierDetails;
