import { CashStackSquaredIcon } from "assets/svgs/icons/cash-stack-squared-icon";
import { DownArrowIcon } from "assets/svgs/icons/down-arrow-icon";
import { Typography } from "components";

const PROFIT_LOSS_DATA = [
  {
    label: "This Month",
    price: "$33,000",
    isProfitable: true,
  },
  {
    label: "Last Month",
    price: "-$8,000",
    isProfitable: false,
  },
  {
    label: "Year to Date",
    price: "$143,000",
    isProfitable: true,
  },
];

const ProfitLoss = () => {
  return (
    <div className="">
      <div className="">
        <div className="container mx-auto px-16 font-[metropolis]">
          <div className="flex justify-center my-4">
            <h1 className="my-4 text-2xl font-bold text-center text-black">
              <Typography variant="pageTitle">Total Profit</Typography>
            </h1>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 ">
            {PROFIT_LOSS_DATA.map((item, key) => {
              return (
                <div
                  key={key}
                  className="border-2 border-[018ffd] rounded-xl bg-[#f1f1f1]/50 p-4 min-h-44"
                >
                  <div className="flex justify-between">
                    <h5 className="font-bold text-blue-500">{item.label}</h5>
                    <CashStackSquaredIcon className="size-8" />
                  </div>
                  <div className="text-center mt-9">
                    <h1
                      className={`${
                        item.isProfitable ? "text-green-500" : "text-red-500"
                      } text-4xl font-bold`}
                    >
                      {item.price}
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-2">
            <div className="border-2 border-[018ffd] rounded-xl bg-[#f1f1f1]/50 p-4">
              <div className="flex justify-between rounded-xl bg-gradient-to-br from-[#018ffd] to-[#070c84] py-6 px-2 text-lg md:text-2xl">
                <h2 className="font-bold text-white ">Total Revenue</h2>
                <h2 className="font-bold text-white">$43,000</h2>
              </div>
              <div className="px-2 pt-4">
                <h5 className="font-bold text-gray-600">Details</h5>
                <div className="grid grid-cols-1 gap-2 mt-2">
                  <div className="flex justify-between">
                    <h5 className="font-bold text-gray-600">
                      2004 Toyota Rav4
                    </h5>
                    <h4 className="font-bold text-gray-600">$16,000</h4>
                  </div>
                  <div className="flex justify-between">
                    <h5 className="font-bold text-gray-600">
                      2020 Ford Bronco
                    </h5>
                    <h4 className="font-bold text-gray-600">$27,000</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-2 border-[018ffd] rounded-xl bg-[#f1f1f1]/50 p-4">
              <div className="flex justify-between rounded-xl bg-gradient-to-br from-[#018ffd] to-[#070c84] py-6 px-2 text-lg md:text-2xl">
                <h2 className="font-bold text-white">Total Loss</h2>
                <h2 className="font-bold text-white">$32,000</h2>
              </div>
              <div className="px-2 pt-4">
                <h5 className="font-bold text-gray-600">Details</h5>
                <div className="grid grid-cols-1 gap-2 mt-2">
                  <div className="flex justify-between">
                    <h5 className="font-bold text-gray-600">Repair Costs</h5>
                    <h4 className="font-bold text-gray-600">$5,000</h4>
                  </div>
                  <div className="flex justify-between">
                    <h5 className="font-bold text-gray-600">
                      Reconditioning Costs
                    </h5>
                    <h4 className="font-bold text-gray-600">$7,000</h4>
                  </div>
                  <div className="flex justify-between">
                    <h5 className="font-bold text-gray-600">
                      Cost of Inventory
                    </h5>
                    <h4 className="font-bold text-gray-600">$20,000</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 py-2 text-sm md:grid-cols-2 md:text-xl">
            <div className="p-4 border-2 border-[018ffd] rounded-xl bg-[#f1f1f1]/50 flex justify-between px-6">
              <h6 className="text-transparent font-bold bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text flex justify-between items-center">
                Breakdown by Vehicle{" "}
              </h6>
              <button>
                <DownArrowIcon className="size-6" />
              </button>
            </div>
            <div className="p-4 border-2 border-[018ffd] rounded-xl bg-[#f1f1f1]/50 flex justify-between px-6">
              <h6 className="text-transparent font-bold bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text flex justify-between items-center">
                Breakdown by Salesperson{" "}
              </h6>
              <button>
                <DownArrowIcon className="size-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitLoss;
