import { Button } from "@nextui-org/react";

interface Props {
  handleContinue: () => void;
}
export default function BhphfContractThree({ handleContinue }: Props) {
  return (
    <div className="p-2 md:p-8">
      <h2 className="mt-4 font-semibold text-center">
        Pre-Contract Optional Products and Services Disclosure
      </h2>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full table-fixed border-collapse border border-gray-400">
          <tbody>
            {[
              { fvalue: "I. Service Contract" },

              { fvalue: "I. Service Contract" },
              { fvalue: "Il. Insurance Products" },
              {
                fvalue:
                  "III. Debt Cancellation Agreement or Guaranteed Asset Protection Waiver Agreement",
              },
              { fvalue: "IV. A Theft Deterrent Device" },
              { fvalue: "V. A Surface Protection Product" },
              {
                fvalue: "VI. A Vehicle Contract Cancellation Option Agreement",
              },
              { fvalue: "OTHER.", notAddNextRow: true },
            ].map(({ fvalue, notAddNextRow }, key) => {
              return (
                <>
                  <tr key={key}>
                    <td className="border border-gray-400 p-2">{fvalue}</td>

                    <td className="border border-gray-400 p-4" />
                    <td className="border border-gray-400 p-4 ">$ </td>
                  </tr>
                  {!notAddNextRow && <EmpltyRowCreate arr={[`${key}-1-e`]} />}
                </>
              );
            })}
            <tr>
              <td className="border border-gray-400 p-2">GPS Tracker</td>

              <td className="border border-gray-400 p-4" />
              <td className="border border-gray-400 p-4 ">$ </td>
            </tr>
            <EmpltyRowCreate arr={["end1", "end2"]} />

            <tr>
              <td className="border border-gray-400 p-2">TOTAL:</td>

              <td className="border border-gray-400 p-4" />
              <td className="border border-gray-400 p-4 ">$ </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto my-8">
        <table className="min-w-full table-fixed border-collapse border border-gray-400">
          <tbody>
            <tr>
              <td className="border border-gray-400 p-4">
                Installment Payment EXCLUDING Listed Items
              </td>

              <td className="border border-gray-400 p-4">
                $(Payment from Contract Adjusted to Total Cash Price Minus Total
                from Above)
              </td>
            </tr>

            <tr>
              <td className="border border-gray-400 p-4">
                Installment Payment INCLUDING Listed Items
              </td>

              <td className="border border-gray-400 p-4">
                $(Payment from Contract)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="my-10">
        <h2>Date:</h2>
        <p className="text-left flex  gap-6 flex-wrap ">
          <p>
            Buyer1 Signature
            <span className="border-gray-500 border-b px-12 sm:px-28" />
          </p>

          <p>
            {" "}
            Buyer2 Signature
            <span className="border-gray-500 border-b px-12 sm:px-28"></span>
          </p>
        </p>
      </div>

      <div className="my-6 flex justify-center">
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
          type="button"
          size="md"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

const EmpltyRowCreate = ({ arr }: { arr: string[] }) => {
  return (
    <>
      {arr.map((item) => {
        return (
          <tr key={item}>
            <td className="border border-gray-400 p-6" />
            <td className="border border-gray-400 p-4" />
            <td className="border border-gray-400 p-4" />
          </tr>
        );
      })}
    </>
  );
};
