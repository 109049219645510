import { ListingPaymentCustomOpt } from "pages/public/view-car/accordion-view-car.component";
import { http } from "../../http/service.http";
import {
  FMOListingPaymentReportResponse,
  ISellerLeadPayload,
  PostSellerFinanceOfferPayload,
  SellerDetailsData,
  SendSellerCustomCashOfferPayload,
  WaretyLead,
} from "./view-car.type";
import { throwNotErrorSuccessResponse } from "config/retryConfig";

class ViewCarEndpoint {
  public async SendSellerCustomCashOffer(
    data?: SendSellerCustomCashOfferPayload
  ): Promise<string> {
    try {
      const res = await http.post(`Listings/SendSellerCustomCashOffer`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(
        res,
        res.data || "Could Not Send Custom Offer!"
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  public async PostSellerFinanceOffer(
    data?: PostSellerFinanceOfferPayload
  ): Promise<string> {
    const res = await http.post(`Listings/PostSellerFinanceOffer`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });
    return res.data;
  }

  public async getSellerDetailsByListingId(
    listingId: string
  ): Promise<SellerDetailsData> {
    try {
      const res = await http.post(
        `Listings/SellerDetailsByListingId?listingId=${listingId}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  public async PostSellerLead(
    payload:ISellerLeadPayload
  ): Promise<any> {
    try {
      const res = await http.post(
        `Listings/PostSellerLead`,payload,
        {
          headers: {
            Accept: "*/*",
          "Content-Type": "application/json",
          },
        }
      );
      throwNotErrorSuccessResponse(res,res.data ||"failed to send Contact Details. please try again")
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  public async getWarrantyLead(
    payload: WaretyLead
  ): Promise<SellerDetailsData> {
    const res = await http.post(`Listings/WarrantyLead`, payload, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    return res.data;
  }

  public async getUserDetails(): Promise<any> {
    const res = await http.get(`User`);
    return res.data;
  }
  public async processFMOReportSubscription(
    listingId: string,
    vin: string,
    subKey: ListingPaymentCustomOpt
  ): Promise<FMOListingPaymentReportResponse> {
    const res = await http
      .post(
        `Transactions/ProcessFMOReportSubscription?vin=${vin}&subscriptionKey=${subKey}&listingId=${listingId}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      )
      .catch((e) => {
        throw e;
      });

    if (res.status !== 200) {
      throw new Error(
        res?.data ||
          "Process Payment for report failed! Please contact support!"
      );
    }
    return res.data;
  }
}

export const viewCarEndpoint = new ViewCarEndpoint();
