import { Control, Controller } from "react-hook-form";
import Input from "./input";
import PlacesAutocomplete from "react-places-autocomplete";
import React from "react";
import { ListingZipCodeDetailsResponse } from "services";
interface PhoneNumberInputProps {
  control: Control<any>;
  error: boolean;
  icon?: JSX.Element;
  placeholder?: string;
  id: string;
  iconClass?: string;
  InputProps?: any;
  errorMessage?: string;
}
export const PhoneNumberInput = ({
  id,
  control,
  error,
  icon,
  placeholder,
  iconClass,
  InputProps,
  errorMessage,
}: PhoneNumberInputProps) => {
  return (
    <>
      <Controller
        control={control}
        name={id}
        render={({ field }) => (
          <Input
            variant="form"
            size="compact"
            radius="base"
            placeholder={placeholder}
            // isInvalid={!!errors.phoneNumber}
            isInvalid={error}
            maxLength={14}
            classNames={{
              inputWrapper: error
                ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                : "",
            }}
            startContent={
              icon && <span className={iconClass || ""}>{icon}</span>
            }
            {...field}
            onChange={(e) => {
              console.log(e.target.value);
              const inputValue = e.target.value;
              const formattedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

              if (formattedValue.length < 4) {
                return field.onChange(formattedValue);
              } else if (formattedValue.length < 7) {
                return field.onChange(
                  `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`
                );
              } else {
                return field.onChange(
                  `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
                    3,
                    6
                  )}-${formattedValue.slice(6)}`
                );
              }
            }}
            {...InputProps}
          />
        )}
      />
      {errorMessage && <small className="text-red-500">{errorMessage}</small>}
    </>
  );
};

interface AddressInputProps {
  zipData?: ListingZipCodeDetailsResponse | undefined;
  placeholder?: string;
  InputComponent: React.ElementType<any>;
  handleSelect: (value: string) => void;
}

export const AddressInput: React.FC<AddressInputProps> = ({
  zipData,
  placeholder = "",
  InputComponent,
  handleSelect,
}) => {
  const [address, setAddress] = React.useState("");

  const center = { lat: zipData?.latitude || 0, lng: zipData?.longitude || 0 };
  const bounds = {
    north: center.lat + 0.5,
    south: center.lat - 0.5,
    east: center.lng + 0.5,
    west: center.lng - 0.5,
  };
  // console.log(zipData )
  return (
    <div className="w-full">
      <PlacesAutocomplete
      value={address}
        // value={address + zipData?.zipCode ?`${zipData?.city}, ${zipData?.county}, ${zipData?.state}, ${zipData?.zipCode} `:"" }
        searchOptions={{
          ...(zipData?.latitude ? { locationRestriction: bounds } : {}),
          componentRestrictions: { country: "us" },
        }}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <InputComponent
              {...getInputProps({ placeholder: "", maxLength: 60 })}
            />

            {suggestions.length > 0 && (
              <div className="z-50 w-full  absolute text-start border-1 rounded-md">
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                  };

                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, { style })}
                      className="cursor-pointer p-1"
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};
