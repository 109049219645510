import React, { useEffect, useState } from "react";

import "../index.css";
import CloseIcon from "../../../../shared/components/CloseIcon";
import { Chip } from "@nextui-org/react";
interface Props {
  // icon: React.ReactNode;
  file?: File;
  name?: string;
  parts?: string | boolean;
  handleRemove?: () => void;
  isFirst?: boolean;
  handleMakeFirst?: () => void;
}
export const PreviewSelectedListing = (props: Props) => {
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (props?.file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setImageUrl(reader.result);
        }
      };
      reader.readAsDataURL(props.file);
    }
  }, [props.file]);

  return (
    <div
      onClick={props?.handleMakeFirst}
      className={ `flex h-[8rem] cursor-pointer  modal-preview-files relative  ${props.isFirst ? "border-blue-500 border-1.5 ":""}`}
    >
      <div className="">
        <img
          src={imageUrl}
          alt="car1"
          width={120}
          className="h-full object-cover "
          style={{
            borderTopLeftRadius: "18px",
            borderBottomLeftRadius: "18px",
          }}
        />
      </div>
      <div className="w-2/3 p-4 ">
        <div className="">
          <h3 className="w-11/12  overflow-hidden whitespace-nowrap overflow-ellipsis">
            {props?.file && (props.file?.name || "test.jpg")}
          </h3>
          {props?.parts ? (
            <p className="mt-auto">{props.parts}</p>
          ) : (
            <div className="w-full bg-gray-200 rounded-full h-1.5 mt-4 dark:bg-gray-600">
              <div className="loader-line-manage-list-img h-full w-full"></div>
            </div>
          )}
        
        </div>
        
        <div className="flex flex-col  items-center justify-center">
        {props.isFirst && (
        <Chip className=" text-xs m-2 bg-white border border-blue-500  font-[Metropolis]">
          Cover Photo
        </Chip>
      )}
        </div>
      </div>

      <div className="absolute modal-border-radius right-4 top-2 bg-white shadow">
        <button
          type="button"
          className="absolute top-[10] right-[0] hover:scale-125 w-3 h-3"
          data-modal-hide="default-modal"
          onClick={() => {
            props?.handleRemove && props.handleRemove();
          }}
        >
          <CloseIcon />
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      
    </div>
  );
};
