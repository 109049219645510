import { SVGProps } from "react";

export const EditIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="15.2682"
      cy="14.9948"
      r="14.6461"
      fill="url(#paint0_linear_9177_17612)"
      stroke="white"
      stroke-width="0.603667"
    />
    <path
      d="M19.8095 8.62109L21.6452 10.4568L20.2458 11.8568L18.4101 10.0211L19.8095 8.62109ZM13.0859 17.1804H14.9216L19.3806 12.7215L17.5449 10.8857L13.0859 15.3447V17.1804Z"
      fill="white"
    />
    <path
      d="M19.8178 19.0131H13.1835C13.1676 19.0131 13.1511 19.0192 13.1352 19.0192C13.115 19.0192 13.0948 19.0137 13.074 19.0131H11.2512V10.4465H15.4409L16.6647 9.22266H11.2512C10.5762 9.22266 10.0273 9.77092 10.0273 10.4465V19.0131C10.0273 19.6887 10.5762 20.2369 11.2512 20.2369H19.8178C20.1424 20.2369 20.4537 20.108 20.6832 19.8785C20.9127 19.649 21.0416 19.3377 21.0416 19.0131V13.7091L19.8178 14.9329V19.0131Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9177_17612"
        x1="3.46724"
        y1="0.0468754"
        x2="26.807"
        y2="33.8764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#018FFD" />
        <stop offset="1" stop-color="#070C84" />
      </linearGradient>
    </defs>
  </svg>
);
