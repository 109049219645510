import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useGetVindataDecodeMut,
  useInitiateListingVINMut,
  usePostSubmitResearchVehicleForm,
} from "services";
import { useDebounceValue } from "usehooks-ts";
import { z } from "zod";
import { VinDataType } from "../enter-vin/type";
import { findVehicleBy } from "utils/find-vehicle-by";
import { capitalizeAllBeforeDash, capitalizeForDashOrWhiteSpace } from "utils";
import { getUserId } from "utils/get-user-id";
import { useDispatch } from "react-redux";
import { saveCarResearchInfo } from "modules/dealer/store/manage-list";

const validationSchema = z
  .object({
    name: z.string(),
    vin: z.string(), // VIN is optional
    mileage: z.string().optional(), // VIN is optional
    phone: z
      .string()
      .max(0, { message: "Not a valid phone" })
      .or(
        z
          .string()
          .regex(/^[0-9\-\(\)\s]+$/, {
            message:
              "String can only contain numbers, hyphens, and parentheses",
          })
          .optional()
      ), // VIN is optional
    // email: z.string().optional(),
    email: z
      .string()
      .max(0, { message: "Not a valid Email" })
      .or(z.string().email("Not valid email")), // VIN is optional
    link: z.string().optional(), // VIN is optional
  })
  .partial()
  .refine((data) => data.name || data.vin, {
    message: "Either name or vin should be filled in.",
    path: ["vin"],
  });

export type SchemaReacher = z.infer<typeof validationSchema>;

const StartReasearchComponent = ({}: {}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
    trigger,
    getValues,
  } = useForm<SchemaReacher>({
    resolver: zodResolver(validationSchema),
  });
  const [vinValue, setVinValue] = useState("");
  const [debouncedValue] = useDebounceValue(vinValue, 500);
  const {
    mutateAsync: initListingMutate,
    isPending: initIsPending,
    error: errInitMes,
  } = useInitiateListingVINMut();
  const {
    mutateAsync: submitResearchMutate,
    isPending: initIsPendingTosubmitReseach,
    error: errResearch,
  } = usePostSubmitResearchVehicleForm();

  const { mutateAsync: vincodeMutateAsync } = useGetVindataDecodeMut();
  useEffect(() => {
    try {
      if (vinValue.length === 17) {
        const fetchZipCode = async () => {
          const data = await vincodeMutateAsync(vinValue);
          console.log("data", data);

          const err =
            data?.message?.find(
              (data: VinDataType) => data.code === "ErrorCode"
            )?.value !== "0";
          if (err) {
            setError("vin", { message: "Not valid vin number!" });
          } else {
            const make =
              findVehicleBy(data?.message ?? [], "Make")?.value ?? "";
            const model =
              findVehicleBy(data?.message ?? [], "Model")?.value ?? "";
            const year =
              findVehicleBy(data?.message ?? [], "ModelYear")?.value ?? "";
            const trim =
              findVehicleBy(data?.message ?? [], "Trim")?.value ??
              findVehicleBy(data?.message ?? [], "Series")?.value ??
              "";

            const nameWithTrim = `${year} ${capitalizeForDashOrWhiteSpace(
              make
            )} ${capitalizeAllBeforeDash(model)} ${trim?.toUpperCase()}`;
            setValue("name", nameWithTrim);
          }
          //  if(data){
          //    setValue(`${type}.top.State`,data.state)
          //    setValue(`${type}.top.City`,data.city)
          //  }
        };
        fetchZipCode();
      } else if (vinValue?.trim()?.length > 0) {
        setError("vin", { message: "Not valid vin number!" });
      }
    } catch (e) {
      console.log(e);
    }
  }, [debouncedValue]);

  const onSubmit = async (data: SchemaReacher) => {
    try {
      const uid = getUserId();
      let response;
      if (data?.vin) {
        response = await initListingMutate({
          vinNumber: data?.vin || "",
          mileage: String(data?.mileage || 0),
          userId: uid,
        });
      }
      const payload = {
        email: data?.email || "",
        id: 0,
        linkToListing: data?.link || "",
        mileage: String(data?.mileage || 0),
        name: data?.name || "",
        phoneNumber: data?.phone || "",
        userId: uid,
        vin:  (data.vin || "").toLocaleUpperCase(),
        listingId: response || 0,
      };
      await submitResearchMutate(payload);
      if (data?.vin) {
        dispatch(saveCarResearchInfo(payload));
        navigate(
          `/dealer/start-car-research/${data?.vin || 0}/${
            data?.mileage || 0
          }/${response}`
        );
      } else {
        // navigate(`/dealer/enter-vin-only`)
        navigate("/dealer/car-research");
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log("error", errors);
  return (
    <form className={`  flex`} onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          backgroundImage: "url(assets/images/payment-bg-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="flex pt-4 m-auto py-6 sm:pt-6 px-4 sm:px-12 max-w-[38rem] bg-transparent shadow-md rounded-lg  my-auto flex-col gap-2 mg:gap-4  w-full"
      >
        <div className="font-bold">Either VIN or Name Required*</div>
        {[
          { label: "Enter VIN", id: "vin", type: "vin" },
          { label: "Name", id: "name" },
          { label: "Mileage", id: "mileage", type: "number" },
          { label: "Phone Number", id: "phone", type: "number" },
          { label: "Email", id: "email" },
          { label: "Link to Listing", id: "link" },
        ].map(({ label, id, type }, ind) => {
          return (
            <div>
              <div
                key={id}
                className="flex justify-between gap-4 flex-col sm:flex-row items-center"
              >
                <label
                  htmlFor={id}
                  className="text-[#263238] font-bold  w-full sm:w-[20rem]"
                >
                  {label}
                </label>
                {id === "vin" ? (
                  <input
                    className={`bg-transparent  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left `}
                    {...register(id as keyof SchemaReacher)}
                    value={getValues("vin")}
                    onChange={(e: any) => {
                      // register(`vin`)?.onChange(e);
                      setVinValue(e.target?.value?.trim().toLocaleUpperCase());
                      trigger("vin");
                      setValue(`vin`, e.target?.value?.trim().toLocaleUpperCase());

                    }}
                  />
                ) : id === "phone" ? (
                  <input
                    className={`bg-transparent  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left `}
                    {...register(id as keyof SchemaReacher)}
                    maxLength={14}
                    value={getValues("phone")}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      let formattedValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      if (formattedValue.length < 4) {
                        formattedValue = inputValue;
                      } else if (formattedValue.length < 7) {
                        formattedValue = `(${formattedValue.slice(
                          0,
                          3
                        )}) ${formattedValue.slice(3)}`;
                      } else {
                        formattedValue = `(${formattedValue.slice(
                          0,
                          3
                        )}) ${formattedValue.slice(
                          3,
                          6
                        )}-${formattedValue.slice(6)}`;
                      }

                      setValue(`phone`, formattedValue);
                      trigger(`phone`);
                    }}
                  />
                ) : (
                  <input
                    type={type || "text"}
                    className={`bg-transparent  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left `}
                    {...register(id as keyof SchemaReacher)}
                  />
                )}
              </div>

              {errors[id as keyof SchemaReacher]?.message && (
                <p className="text-red-500 text-right">
                  {errors[id as keyof SchemaReacher]?.message}
                </p>
              )}
            </div>
          );
        })}
        {(errResearch?.message || errInitMes?.message) && (
          <p className="text-red-500 text-center">
            {errResearch?.message || errInitMes?.message}
          </p>
        )}
        <Button
          className="bg-gradient-to-r max-w-[12rem] ml-auto from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
          type="submit"
          size="md"
          isLoading={initIsPendingTosubmitReseach || initIsPending}
          // onClick={() => navigate("/dealer/car-research/5GADT13S862340782/5000")}
        >
          {" "}
          Continue
        </Button>
      </div>
    </form>
  );
};
export default StartReasearchComponent;
