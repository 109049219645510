import React from "react";
import { Image } from "@nextui-org/react";

import { Typography } from "components";
import { useNavigate } from "react-router-dom";

interface DmvFeeQuoteSubmitModalProps {
  open: boolean;
  handleClose: () => void;
}

export const DmvFeeQuoteSubmitModal: React.FC<DmvFeeQuoteSubmitModalProps> = ({
  open,
  handleClose,
}) => {
  const navigate = useNavigate();

  const handleContinue = () => {
    handleClose();
    navigate("/dealer/dmv-titling/dvm-transit-status");
  };

  return (
    <React.Fragment>
      <div className="flex items-center m-auto dmv-title">
        <div className="flex items-center  bg-cover bg-center mx-auto px-4 bg-fixed">
          {open && (
            <div
              style={{ zIndex: 150 }}
              className="flex overflow-y-auto p-4 overflow-x-hidden fixed items-center  modal-container-vin pt-[130px] justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full"
            >
              <div className="absolute inset-0 bg-black opacity-50 z-999"></div>

              <div className="relative gradiant-modal-loading h-[450px] py-24 px-24   md:px-12  mx-auto  rounded-lg shadow-md ">
                <img
                  onClick={handleClose}
                  src="assets/icons/close-icon.svg"
                  alt="x"
                  className="absolute right-8 top-10 border-white border-2 p-1 rounded-full cursor-pointer"
                />
                <div className="flex items-center justify-center">
                  <Image
                    className="m-auto mb-8"
                    height={120}
                    width={120}
                    src="/assets/icons/checkmark_success.svg"
                    alt="yes"
                  />
                </div>
                <Typography
                  variant="custom"
                  className="text-white justify-center text-center mb-12 text-2xl md:text-3xl font-[600] flex"
                >
                  Successfully Submitted
                </Typography>

                <div className="flex justify-center flex-col items-center mb-2 gap-8">
                  <button
                    onClick={handleContinue}
                    className=" text-white confirm-btn-fin py-2  rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-2xl"
                    type="button"
                  >
                    Titling Status
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
