import * as React from "react";
interface TypographyProps {
  children: React.ReactNode;
  variant?: Variant;
  color?: string;
  className?: string;
}

type Variant =
  | "title"
  | "pageTitle"
  | "subtitle"
  | "body"
  | "titleWithGradient"
  | "textFieldLabel"
  | "error"
  | "label"
  | "custom";

export const Typography: React.FC<TypographyProps> = ({
  children,
  variant = "body",
  className = "",
}) => {
  if (variant === "custom")
    return <div className={`font-[Metropolis] ${className}`}>{children}</div>;
  return <div className={`${mapStyle(variant)} ${className}`}>{children}</div>;
};

const mapStyle = (variant: Variant) => {
  switch (variant) {
    case "title":
      return "font-[Metropolis] text-4xl font-[700]";
    case "pageTitle":
      return "font-[Metropolis] text-3xl font-[700] md:text-4xl xl:text-[40px] text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text";
    case "subtitle":
      return "font-[Metropolis] text-3xl font-[500] leading-snug";
    case "body":
      return "font-[Metropolis] text-sm font-normal";
    case "titleWithGradient":
      return "font-[Metropolis] text-5xl font-bold rounded-2xl text-white bg-gradient-to-r from-[#018ffd] to-[#070c84]";
    case "textFieldLabel":
      return "font-[Metropolis] text-base font-normal font-bold";
    case "error":
      return "font-[Metropolis] text-sm font-normal font-medium text-red-500";
    case "label":
      return "font-[Metropolis] text-lg font-normal leading-8 text-gray-500";
    default:
      return "";
  }
};
