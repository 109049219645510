import { Toggle, Typography } from "components";
import {
  dmvDocumentServiceSignal,
  enterMissingInfoDefaultSignal,
} from "../store";
import Input from "components/ui/input";

const FORQOUTESINPUTFIELD = [
  {
    label: "Does Vehicle Have Loan?",
    name: "doesVehicleHaveLoan",
  },
  {
    label: "Lienholder Company",
    name: "lienholderCompany",
    isDisabled: true,
  },
  { label: "Lienholder Name", name: "lienholderName", isDisabled: true },
  // { label: "Policy Number", name: "policyNumber", isDisabled: false },
  // { label: "Insurance Company", name: "insuranceCompany", isDisabled: false },
];

interface DoesVehicleHaveLoanProps {
  register: any;
  errors: any;
  doesHaveLoan: boolean;
  setDoesHaveLoan: (value: boolean) => void;
  fullService?: boolean;
}
export const DoesVehicleHaveLoan: React.FC<DoesVehicleHaveLoanProps> = ({
  register,
  errors,
  doesHaveLoan,
  setDoesHaveLoan,
  fullService,
}) => {
  return (
    <>
      {(fullService
        ? enterMissingInfoDefaultSignal.value.doesVehicleHaveLoan
        : FORQOUTESINPUTFIELD
      ).map((field, idx) => {
        return (
          <FormControl key={`${field}-${idx}`}>
            {!doesHaveLoan && field.isDisabled ? null : (
              <Typography
                variant="custom"
                className="text-[#263238] font-[600] text-left col-span-2"
              >
                {field.label}
              </Typography>
            )}
            {field.name === "doesVehicleHaveLoan" ? (
              <Toggle
                size="md"
                checked={doesHaveLoan}
                color="default"
                {...register(`doesVehicleHaveLoan.${field.name}` as any)}
                onChange={() => setDoesHaveLoan(!doesHaveLoan)}
              />
            ) : !doesHaveLoan && field.isDisabled ? null : (
              <Input
                variant="form"
                size="lg"
                className="col-span-3"
                classNames={{
                  input: "px-4 text-center",
                  inputWrapper: `${
                    field.isDisabled && !doesHaveLoan ? "border-gray-500" : ""
                  }`,
                }}
                defaultValue={
                  (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
                }
                isDisabled={field.isDisabled && !doesHaveLoan}
                errorMessage={
                  (errors.errors.doesVehicleHaveLoan as any)?.[field.name]
                    ?.message
                }
                {...register(`doesVehicleHaveLoan.${field.name}` as any)}
              />
            )}
          </FormControl>
        );
      })}
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
