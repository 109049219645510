import { z } from "zod";

export const LoginDetailsValidationSchema = z.object({
  userName: z.string().trim().optional(),
  oldPassword: z.string().trim().min(1, "Old Password is Required"),
  newPassword: z
    .string()
    .trim()
    .min(1, "New Password is Required")
    .refine((value) => {
      if (!value) return true;
      const passwordRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      return passwordRegex.test(value);
    }, "At least 8 Characters with 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Characters is Required"),
    confirmPassword: z
    .string()
    .trim()
    .min(1, "Confirm Password is Required")
}).refine(data => data.newPassword === data.confirmPassword, {
  message: "New Password and Confirm Password must match",
  path: ["confirmPassword"],
});
