import { http } from "../../http/service.http";
import {
  EscrowTransaction,
  pendingSalesData,
} from "./pending-sales.type";

class PendingSalesEndpoint {
  public async getPendingSalesData(page?: number): Promise<pendingSalesData[]> {
    const res = await http.get(
      `Listings/PendingSalesGetOfferListByUserId?page=${page}&pagesize=16` );
    return res.data;
  }

  public async getInitiateEscrowTransaction(listId: number): Promise<EscrowTransaction> {
    const res = await http.get(
      `/Transactions/InitiateEscrowTransaction?listingId=${listId}` );
    return res.data;
  }

}

export const pendingSalesEndpoint = new PendingSalesEndpoint();
