import * as React from "react";
import { Button, Card, CardBody, CardFooter } from "@nextui-org/react";
import { useParams } from "react-router-dom";

import { Typography } from "components";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { DmvFeeDetailsValidationSchema } from "./validation";
import { useGetATCFeeQuotes } from "services/tiling.service";
import { dmvtitlingSignal } from "./store";
import Loading from "components/Loading/Loading";
import { DmvFeeQuoteModal } from "./dmv-fee-quote-modal.component";
import { DmvFeeQuoteView } from "./dmv-fee-quote-view.component";
import { makeDecimalAPercentage, removeHTMLTags } from "utils";

export const DmvFeeQuoteForm = () => {
  const params = useParams();
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const [isProceed, setIsProceed] = React.useState<boolean>(false);

  const { mutateAsync, isPending, data } = useGetATCFeeQuotes(
    params?.listingId as string
  ); 
  
  const handleOnSubmit = () => {
    setOpenConfirmationModal(true)
  };

  React.useEffect(() => {
    const fetchATCFeeQuotes = async () => {
      const res = await mutateAsync(dmvtitlingSignal.value as any);
      // if (res) setOpenConfirmationModal(true);
    };
    fetchATCFeeQuotes();
  }, [mutateAsync]);

  if (isPending) return <Loading />;

  return (
    <><div className="px-5 md:px-10 xl:px-32  min-h-[50rem] relative">
      <div className="flex flex-col gap-4 items-center justify-center">
        <Typography variant="pageTitle">
          {/* {isProceed ? "Full Service" : "Fee Quote"} */}
          DMV/Titling Fees and Documents
        </Typography>
        <Typography
          variant="custom"
          className="text-medium text-[#263238] font-[500] text-center"
        >
          {data?.notes &&
            (removeHTMLTags(data?.notes?.note[0]?.noteText) ?? "")}
        </Typography>
        { isProceed &&
        <Typography
          variant="custom"
          className="text-medium text-red-500 font-[400] text-center"
        >
          Please note: If some documents are not available for this VIN, we could provide them to you. Please create a support ticket for assistance.
        </Typography>}
      </div>
      <div className="mt-10">
        {isProceed ? (
          <DmvFeeQuoteView />
        ) : (
          <Form
            id="dmv-missing-details-form"
            schema={DmvFeeDetailsValidationSchema}
            onSubmit={() => { } }
          >
            {({ formState: { errors } }) => {
              return (
                <Card
                  isPressable={false}
                  radius="lg"
                  className="max-w-[40rem] bg-[#F1F1F180] m-auto bg-no-repeat flex items-center justify-center"
                  style={{
                    backgroundImage: "url(assets/images/header-background.png)",
                  }}
                >
                  <CardBody className="flex my-6 px-16">
                    {data?.feeDetails?.feeDetail?.map((feeDetail, index) => {
                      if (!feeDetail) return null;
                      return (
                        <FormControl key={index}>

                          <div className="max-w-[12rem]">
                            {feeDetail?.feeName === "State Tax" ? (
                              <>
                                <Typography
                                  variant="custom"
                                  className="text-[#263238] font-[600] text-center md:text-left text-sm"
                                >
                                  {feeDetail?.feeName}
                                </Typography>
                                <Typography
                                  variant="custom"
                                  className="text-[#263238] font-[400] text-center md:text-left text-sm"
                                >
                                  {makeDecimalAPercentage(
                                    Number(data?.totalSalesTax?.rate)
                                  )}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  variant="custom"
                                  className="text-[#263238] font-[600] text-center md:text-left text-sm"
                                >
                                  {feeDetail?.feeType}
                                </Typography>
                                {Number(feeDetail?.feeTaxRate) > 0 ? (
                                  <Typography
                                    variant="custom"
                                    className="text-[#263238] font-[400] text-center md:text-left text-sm"
                                  >
                                    {makeDecimalAPercentage(
                                      Number(feeDetail?.feeTaxRate)
                                    )}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="custom"
                                    className="text-[#263238] font-[400] text-center md:text-left text-sm"
                                  >
                                    {feeDetail?.feeName}
                                  </Typography>
                                )}
                              </>
                            )}
                          </div>
                          <Input
                            variant="form"
                            size="lg"
                            className="max-w-[12rem]"
                            classNames={{
                              input: "px-4 text-center border-[#263238]",
                              inputWrapper: "border-[#263238]",
                            }}
                            isDisabled
                            defaultValue={feeDetail?.feeName === "State Tax"
                              ? `$${Number(
                                data?.totalSalesTax?.amount
                              ).toFixed(2)}`
                              : `$${feeDetail?.amount}`} />
                        </FormControl>
                      );
                    })}
                    <FormControl>

                      <Typography
                        variant="custom"
                        className="max-w-[12rem] text-[#018FFD] font-[600] text-center md:text-left"
                      >
                        Total
                      </Typography>
                      <Input
                        variant="form"
                        size="lg"
                        className="max-w-[12rem]"
                        classNames={{
                          input: "px-4 text-center",
                        }}
                        isDisabled
                        defaultValue={`$${data?.feeTotal}` ?? ""}
                        errorMessage={errors.transferFee?.message} />
                    </FormControl>
                    <div className="flex justify-center">

                      <Button
                        className="max-w-[12rem] bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
                        type="button"
                        onClick={handleOnSubmit}
                      >
                        Proceed
                      </Button>
                    </div>
                  </CardBody>
                  <CardFooter
                    className="h-60 bg-cover bg-center bg-[#F1F1F180] -mt-64"
                    style={{
                      backgroundImage: "url(assets/images/footer-background.png)",
                    }}
                  ></CardFooter>
                </Card>
              );
            } }
          </Form>
        )}
      </div>
      <DmvFeeQuoteModal
        open={openConfirmationModal}
        handleClose={() => { setOpenConfirmationModal(false); setIsProceed(true); } }
        selectedData={undefined} />
    </div>
    {openConfirmationModal && <div
      style={{ zIndex: 150, height:"100vh" }}
      className="fixed inset-0 bg-black opacity-50 z-999"
    ></div>}
    </>
  
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between gap-2 mb-6  md:max-w-[18rem] md:ml-[4rem]">
      {children}
    </div>
  );
};
