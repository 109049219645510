import * as z from "zod";

const MONTTS = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM = {
  PersonalInformation: [
    {
      label: "First Name *",
      name: "FirstName",
    },
    {
      label: "Last Name *",
      name: "LastName",
    },

    {
      label: "Email *",
      name: "Email",
    },

    {
      label: "Contact Number *",
      name: "ContactNumber",
    },
    {
      label: "Date of Birth *",
      name: "DateOfBirth",
      type: "date",
    },
    {
      label: "SSN *",
      name: "SSN",
    },

    {
      label: "Driver's License Number",
      name: "DriverLicenseNumber",
    },
    {
      label: "Driver's License State",
      name: "DriverLicenseState",
    },
    {
      label: "Driver's License Issue Date",
      name: "DriverLicenseIssueDate",
      type: "date",
    },
    {
      label: "Driver's License Expiry Date",
      name: "DriverLicenseExpiryDate",
      type: "date",
    },
  ],

  ResidentialInformation: {
    top: [
      {
        label: "Street Address *",
        name: "StreetAddress",
      },
      {
        label: "Zip Code *",
        name: "ZipCode",
      },
      {
        label: "City *",
        name: "City",
      },
      {
        label: "State *",
        name: "State",
      },
    ],
    bottom: [
      {
        label: "Housing Type *",
        name: "HousingType",
        type: "Options",
        options: ["Rent", "Own", "Live Rent Free"],
      },
      {
        label: "Monthly Rent/Mortgage Amount *",
        name: "MonthlyRentOrMortgageAmount",
      },
      {
        label: "Years at Address *",
        name: "YearsAtAddress",
        type: "Years",
        optionsWithLabel: MONTTS,
      },
    ],
  },
  EmploymentInformation: {
    top: [
      {
        label: "Employer Name *",
        name: "EmployerName",
      },
      {
        label: "Title/Position *",
        name: "TitlePosition",
      },
      {
        label: "Employer Phone Number *",
        name: "EmployerPhoneNumber",
      },
    ],
    bottom: [
      {
        label: "Monthly Gross Income *",
        name: "MonthlyGrossIncome",
      },
      {
        label: "Years at Job *",
        name: "YearsAtJob",
        type: "Years",
        optionsWithLabel: MONTTS,
      },
    ],
  },
  InterestedVehicle: [
    {
      label: "VIN",
      name: "vin",
    },
    {
      label: "Year",
      name: "year",
    },
    {
      label: "Make",
      name: "make",
    },

    {
      label: "Model",
      name: "model",
    },
  ],
};

const PersonalInformationSchema = z.object({
  FirstName: z.string().min(1, { message: "First Name is required" }),
  Email: z.string().min(1, { message: "Email is required" }),
  DateOfBirth: z.date(),
  DriverLicenseNumber: z.string().optional(),
  DriverLicenseIssueDate: z.date().optional().nullish(),
  LastName: z.string().min(1, { message: "Last Name is required" }),
  ContactNumber: z.string().min(1, { message: "Contact Number is required" }),
  SSN: z.string().min(1, { message: "SSN is required" }),
  DriverLicenseState: z.string().optional(),
  DriverLicenseExpiryDate: z.date().optional().nullish(),
});

// Define the schema for ResidentialInformation
const ResidentialInformationSchema = z.object({
  top: z.object({
    StreetAddress: z.string().min(1, { message: "Street Address is required" }),
    City: z.string().min(1, { message: "City is required" }),
    State: z.string().min(1, { message: "State is required" }),
    ZipCode: z.string().min(1, { message: "Zip Code is required" }),
  }),
  bottom: z.object({
    HousingType: z.string().min(1, { message: "Housing Type is required" }),
    MonthlyRentOrMortgageAmount: z
      .string()
      .min(1, { message: "Monthly Rent/Mortgage Amount is required" }),
    YearsAtAddress: z.object({
      month: z.string().min(1, { message: "Month is required" }),
      years: z.string().min(1, { message: "Years is required" }),
    }),
  }),
});

// Define the schema for EmploymentInformation
const EmploymentInformationSchema = z.object({
  top: z.object({
    EmployerName: z.string().min(1, { message: "Employer Name is required" }),
    TitlePosition: z.string().min(1, { message: "Title/Position is required" }),
    EmployerPhoneNumber: z
      .string()
      .min(1, { message: "Employer Phone Number is required" }),
  }),
  bottom: z.object({
    MonthlyGrossIncome: z
      .string()
      .min(1, { message: "Monthly Gross Income is required" }),
    YearsAtJob: z.object({
      month: z.string().min(1, { message: "Month is required" }),
      years: z.string().min(1, { message: "Years is required" }),
    }),
  }),
});

//   export const schema = z.object({
//     PersonalInformation: PersonalInformationSchema,
//     ResidentialInformation: ResidentialInformationSchema,
//     AddPreviousAddress : z.boolean().optional(),
//     PreviousResidentialInformation: ResidentialInformationSchema
//       ,

//     EmploymentInformation: EmploymentInformationSchema,
//   })

const buyer1 = z.discriminatedUnion("AddPreviousAddress", [
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    AddPreviousAddress: z.literal(true),
    PreviousResidentialInformation: ResidentialInformationSchema,
  }),
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    AddPreviousAddress: z.literal(false),
  }),
]);

const buyer2 = z.discriminatedUnion("PreviousEmployment", [
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    PreviousEmployment: z.literal(true),
    PreviousEmploymentInformation: EmploymentInformationSchema,
  }),
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    PreviousEmployment: z.literal(false),
  }),
]);

// const cobuyer1 = z.discriminatedUnion("AddPreviousAddressCo", [
//   z.object({
//     PersonalInformation: PersonalInformationSchema,
//     ResidentialInformation: ResidentialInformationSchema,
//     EmploymentInformation: EmploymentInformationSchema,
//     RelationshipToBuyer: z
//       .string()
//       .min(1, { message: "Relationship to Buyer is required" }),
//     AddPreviousAddressCo: z.literal(true),
//     PreviousResidentialInformationCo: ResidentialInformationSchema,
//   }),
//   z.object({
//     PersonalInformation: PersonalInformationSchema,
//     ResidentialInformation: ResidentialInformationSchema,
//     EmploymentInformation: EmploymentInformationSchema,
//     RelationshipToBuyer: z
//       .string()
//       .min(1, { message: "Relationship to Buyer is required" }),
//     AddPreviousAddressCo: z.literal(false),
//   }),
// ]);

const cobuyer2 = z.discriminatedUnion("AddPreviousAddress", [
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    AddPreviousAddress: z.literal(true),
    RelationshipToBuyer: z
      .string()
      .min(1, { message: "Relationship to Buyer is required" }),
    PreviousResidentialInformation: ResidentialInformationSchema,
  }),
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    RelationshipToBuyer: z
      .string()
      .min(1, { message: "Relationship to Buyer is required" }),
    AddPreviousAddress: z.literal(false),
  }),
]);

const cobuyer3 = z.discriminatedUnion("PreviousEmployment", [
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    PreviousEmployment: z.literal(true),
    RelationshipToBuyer: z
      .string()
      .min(1, { message: "Relationship to Buyer is required" }),
    PreviousEmploymentInformation: EmploymentInformationSchema,
  }),
  z.object({
    PersonalInformation: PersonalInformationSchema,
    ResidentialInformation: ResidentialInformationSchema,
    EmploymentInformation: EmploymentInformationSchema,
    RelationshipToBuyer: z
      .string()
      .min(1, { message: "Relationship to Buyer is required" }),
    PreviousEmployment: z.literal(false),
  }),
]);

export const schema = z.union([buyer1, buyer2]);

export type Schema = z.infer<typeof schema>;

export const schemaCoBuyer = z.union([ cobuyer2, cobuyer3]);

export type SchemaCoBuyer = z.infer<typeof schemaCoBuyer>;

export interface IFormDataForFinance {
  buyer: z.infer<typeof IStateBuyer> | null;
  cobuyer: z.infer<typeof IStateCoBuyer> | null;
}

export type IFormTypeForFinance = "BUYER" | "CO_BUYER";


const IStateBuyer =  z.object({
  PersonalInformation: PersonalInformationSchema,
  ResidentialInformation: ResidentialInformationSchema,
  EmploymentInformation: EmploymentInformationSchema,
  AddPreviousAddress: z.boolean(),
  PreviousResidentialInformation: ResidentialInformationSchema.partial(),
  PreviousEmployment: z.boolean(),
  PreviousEmploymentInformation: EmploymentInformationSchema.partial(),
})

const IStateCoBuyer =  z.object({
  PersonalInformation: PersonalInformationSchema,
  ResidentialInformation: ResidentialInformationSchema,
  EmploymentInformation: EmploymentInformationSchema,
  AddPreviousAddress: z.boolean(),
  PreviousResidentialInformation: ResidentialInformationSchema.partial(),
  PreviousEmployment: z.boolean(),
  RelationshipToBuyer: z
    .string()
    .min(1, { message: "Relationship to Buyer is required" }),
  PreviousEmploymentInformation: EmploymentInformationSchema.partial(),
})
