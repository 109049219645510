import { Typography } from "components";
import React, { useEffect } from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
export default function LienAndLoanPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selling = [
    {
      name: "Initiate the Sale: ",
      description:
        "List your car on our user-friendly platform, attracting a wide pool of potential buyers.",
    },
    {
      name: "Make a Real Difference: ",
      description:
        "Your work will touch the lives of countless car buyers and dealers, connecting them and fulfilling their dreams.",
    },
    {
      name: "Lien Information Collection: ",
      description:
        "As part of the process, we'll securely collect your lienholder information, ensuring a smooth transaction.",
    },
    {
      name: "Lienholder Contact: ",
      description:
        "Our team will directly communicate with your lender to obtain the payoff amount for your outstanding loan.",
    },
    {
      name: "Secure Escrow: ",
      description:
        "When a buyer is found and the sale is finalized, the purchase money is held securely in escrow until the transaction is complete.",
    },
    {
      name: "Delivery & Inspection: ",
      description:
        "findmyoptions.com has a designated period (5 days) to inspect the vehicle.",
    },
    {
      name: "Loan Payoff & Title Transfer: ",
      description:
        "Upon successful inspection and buyer approval, the escrow funds are used to pay off your loan directly to your lienholder. We then handle the title transfer process efficiently.",
    },
  ];

  const benefitsSelling = [
    {
      name: "Convenience: ",
      description:
        "We take care of the complexities, allowing you to focus on finding the right buyer",
    },
    {
      name: "Wider Audience: ",
      description:
        "Reach a larger pool of potential buyers who may not be able to afford an outright purchase with a lien involved.",
    },
    {
      name: "Security: ",
      description:
        "Our secure escrow system protects both you and the buyer throughout the transaction.",
    },
    {
      name: "Transparency: ",
      description:
        "Clear communication and upfront information about the payoff amount ensure a smooth process.",
    },
    {
      name: "Efficiency: ",
      description:
        "We handle lienholder communication, title transfer, and loan payoff, saving you valuable time and effort.",
    },
  ];

  return (
    <div className="flex items-center justify-center">
      <div className="md:p-2 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            Loan or Lien on Title
          </p>
          <p className=" text-2xl font-bold pt-5">
            Selling Your Car with a Loan or Lien on it? No Problem! Hassle-Free
            Solutions at findmyoptions.com
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-center ">
          <p className="text-xl">
            Trying to sell your car online but worried about a lingering loan or
            lien? At findmyoptions.com, we understand the complexities of
            selling a financed vehicle. Unlike many online marketplaces, we
            offer a streamlined solution to sell your car{" "}
            <span className="font-bold">even if it has a lien</span>.
          </p>
        </Typography>

        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Selling Made Simple, Even with a Lien:
          </p>
          <p className="text-xl pb-8 mt-2">
            We take the hassle out of selling your car with a lien by handling
            the entire process for you:
          </p>
          {selling.map((res) => (
            <div className="flex items-start  ">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="mt-[16px]"
              />
              <p className={`px-3 pt-3 text-black font-light text-xl`}>
                <span className="font-semibold text-black bg-clip-text">
                  {res.name}
                </span>
                {res.description}
              </p>
            </div>
          ))}
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Benefits of Selling Your Car with a Lien on findmyoptions.com:
          </p>
          {benefitsSelling.map((res) => (
            <div className="flex items-start  ">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="mt-[16px]"
              />
              <p className={`px-3 pt-3 text-black font-light text-xl`}>
                <span className="font-semibold text-black bg-clip-text">
                  {res.name}
                </span>
                {res.description}
              </p>
            </div>
          ))}
        </Typography>
        <Typography variant="custom" className="mt-10 mb-10 text-wrap">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Peace of Mind When Selling with a Lien:
          </p>
          <p className="text-xl mt-2">
            At findmyoptions.com, we understand that selling a car with a lien
            can be stressful.  Our streamlined process ensures a secure and
            hassle-free experience.
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 mb-10 text-wrap">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Ready to Sell Your Car?
          </p>
          <p className="text-xl mt-2">
            Don't let a lien hold you back from getting a great deal.  List your
            car for sale on our platform today and experience the convenience of
            selling your car with confidence!
          </p>
        </Typography>
      </div>
    </div>
  );
}
