import { SVGProps } from "react";

export const ContractIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.64844 32.0827C8.43316 32.0827 7.40017 31.6573 6.54948 30.8066C5.69878 29.9559 5.27344 28.923 5.27344 27.7077V23.3327H9.64844V2.91602H31.5234V27.7077C31.5234 28.923 31.0981 29.9559 30.2474 30.8066C29.3967 31.6573 28.3637 32.0827 27.1484 32.0827H9.64844ZM27.1484 29.166C27.5616 29.166 27.9082 29.026 28.1882 28.746C28.4682 28.466 28.6077 28.1199 28.6068 27.7077V5.83268H12.5651V23.3327H25.6901V27.7077C25.6901 28.1209 25.8301 28.4675 26.1101 28.7475C26.3901 29.0275 26.7362 29.167 27.1484 29.166ZM14.0234 13.1243V10.2077H27.1484V13.1243H14.0234ZM14.0234 17.4993V14.5827H27.1484V17.4993H14.0234ZM9.64844 29.166H22.7734V26.2493H8.1901V27.7077C8.1901 28.1209 8.3301 28.4675 8.6101 28.7475C8.8901 29.0275 9.23622 29.167 9.64844 29.166ZM9.64844 29.166H8.1901H22.7734H9.64844Z"
      fill="white"
    />
  </svg>
);
