import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListingListResponse, useUpdateListingStatusMulti } from "services";

export const RowComponentForDelete = ({
  listingDetailsData,
  selectedStatus,
  refetchListingData,
}: {
  listingDetailsData?: ListingListResponse;
  selectedStatus: number;
  refetchListingData: () => void;
}) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(0);
  const {
    mutateAsync: softDeleteListingMutAsyc,
    isPending: isPendingSoftDeleteListing,
  } = useUpdateListingStatusMulti(Number(listingDetailsData?.listingId || 0));
  const { vin, mileage, name, listingId, status, licensePlate } =
    listingDetailsData || {};
  const handleCancelReseatch = async (status: 4 | 1) => {
    setShowLoader(status);
    await softDeleteListingMutAsyc(status);
    refetchListingData();
  };
  return (
    <tr
      className="bg-white text-black border-b border-[#C9C9C9] text-base md:text-xl font-semibold"
      id={`reserach-id-cancel-${listingId}`}
    >
      <th
        scope="row"
        className="px-6 py-4 text-center text-base border-l border-[#C9C9C9]  whitespace-nowrap"
      >
        {vin}
      </th>
      <td className="px-6 py-4 text-center border-l text-base border-[#C9C9C9]">
        {mileage}
      </td>
      <td className="px-6 py-4 text-center border-l  break-words text-base border-[#C9C9C9]">
        {name || "-"}
      </td>
      <td className="overflow-hidden max-w-64 text-base  h-16 text-center border-l border-[#C9C9C9] cursor-pointer ">
        {licensePlate || "-"}
      </td>

      {/* <td className="overflow-hidden max-w-64 text-base items-center  h-16 text-center border-l border-[#C9C9C9] cursor-pointer ">
          <div className="flex px-2 w-full min-w-[10rem] justify-around ">
            <p
              className="hover:text-blue-500"
            >
              {showLoader === 1 && isPendingSoftDeleteListing
                ? "Loading..."
                : "Restore"}
            </p>
            <p
              className="hover:text-red-500"
              onClick={() => handleCancelReseatch(4)}
            >
              {showLoader === 4 && isPendingSoftDeleteListing
                ? "Loading..."
                : "Delete"}
            </p>
          </div>
        </td> */}
      <td className="  text-base  h-16 text-center border-l border-[#C9C9C9] cursor-pointer ">
        {/* <Dropdown
          portalContainer={
            document.getElementById(`reserach-id-cancel-${listingId}`) ||
            document.body
          }
          style={{
            position: "absolute",
            zIndex: 9999, // Adjust as needed
          }}
          size="sm"
          placement="bottom"
        >
          <DropdownTrigger>
            <Button
              className="bg-transparent text-base"
              isLoading={isPendingSoftDeleteListing}
            >
              {"View"}
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem
              key="view-research"
              className="text-center"
              onClick={() => handleCancelReseatch(1)}
            >
              {"Restore"}
            </DropdownItem>
            <DropdownItem
              className="text-center"
              key="cancel-research"
              onClick={() => handleCancelReseatch(4)}
            >
              {"Delete"}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
         <div className="flex px-2 w-full min-w-[10rem] justify-around ">
            <p
              className="hover:text-blue-500"
              onClick={() => handleCancelReseatch(1)}
            >
              {showLoader === 1 && isPendingSoftDeleteListing
                ? "Loading..."
                : "Restore"}
            </p>
            <p
              className="hover:text-red-500"
              onClick={() => handleCancelReseatch(4)}
            >
              {showLoader === 4 && isPendingSoftDeleteListing
                ? "Loading..."
                : "Delete"}
            </p>
          </div>
      </td>
    </tr>
  );
};
