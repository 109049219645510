import { SVGProps } from "react";

export const TurboIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="71"
    height="70"
    viewBox="0 0 71 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_2463_12553)">
      <path
        d="M63.457 37.3145C63.457 43.2479 61.6976 49.0481 58.4011 53.9816C55.1047 58.915 50.4193 62.7602 44.9375 65.0308C39.4558 67.3015 33.4238 67.8956 27.6043 66.738C21.7849 65.5805 16.4394 62.7232 12.2438 58.5277C8.04827 54.3321 5.19104 48.9866 4.03349 43.1672C2.87593 37.3477 3.47003 31.3157 5.74066 25.8339C8.01129 20.3522 11.8565 15.6668 16.7899 12.3704C21.7234 9.07392 27.5236 7.31445 33.457 7.31445M3.45704 37.3145H38.072"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.458 7.31445C26.5266 15.8373 22.4852 26.3436 21.918 37.3145C22.4852 48.2853 26.5266 58.7916 33.458 67.3145C38.8911 60.633 42.5722 52.702 44.168 44.2394"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.918 19.8504C36.168 19.5504 36.168 17.0304 37.918 16.7254C41.0263 16.1855 43.9034 14.732 46.1822 12.5502C48.461 10.3684 50.0383 7.55731 50.713 4.47541L50.813 3.99041C51.198 2.25541 53.663 2.24042 54.063 3.97542L54.188 4.54041C54.8885 7.60836 56.4801 10.4008 58.7629 12.567C61.0456 14.7332 63.9176 16.1764 67.018 16.7154C68.778 17.0204 68.778 19.5554 67.018 19.8654C63.9176 20.4044 61.0456 21.8476 58.7629 24.0138C56.4801 26.18 54.8885 28.9725 54.188 32.0404L54.063 32.6004C53.663 34.3304 51.198 34.3204 50.813 32.5854L50.713 32.1054C50.0383 29.0235 48.461 26.2124 46.1822 24.0306C43.9034 21.8489 41.0263 20.3903 37.918 19.8504Z"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2463_12553">
        <rect
          width="70"
          height="70"
          fill="white"
          transform="translate(0.898438)"
        />
      </clipPath>
    </defs>
  </svg>
);
