import { FunctionComponent } from "react";
import MainFrame from "./MainFrame";
import vector_1 from "./assets/images/Vector1.png";
import vector_2 from "./assets/images/Vector2.png";

const EmailTheme: FunctionComponent = () => {
  return (
    <div className="w-full h-full relative bg-white overflow-hidden flex flex-col items-center justify-center px-5 box-border tracking-[normal]">
      <main className="w-full h-full absolute my-0 mx-[!important] top-[-341px] left-[-306px]">
        <div className="absolute top-[1044px] left-[1669px] w-[561.9px] h-[593.4px] flex items-center justify-center">
          <img
            className="w-full h-full object-contain absolute right-[700px] bottom-[450px] [transform:scale(1.712)]"
            alt=""
            src={vector_2}
          />
        </div>
   
        <div className="absolute top-[0px] left-[0px] w-[927px] h-[787px] z-[2] flex items-center justify-center">
          <img
            className="w-[full] h-full z-[2] object-contain absolute left-[100px] top-[100px] [transform:scale(1.508)]"
            alt=""
            src={vector_1}
          />
        </div>
      </main>
      <MainFrame name={""} />
    </div>
  );
};

export default EmailTheme;
