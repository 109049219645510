import { SVGProps } from "react";

export const CheckIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 2.1875C8.96875 2.1875 2.1875 8.96875 2.1875 17.5C2.1875 26.0312 8.96875 32.8125 17.5 32.8125C26.0312 32.8125 32.8125 26.0312 32.8125 17.5C32.8125 8.96875 26.0312 2.1875 17.5 2.1875ZM17.5 30.625C10.2812 30.625 4.375 24.7188 4.375 17.5C4.375 10.2812 10.2812 4.375 17.5 4.375C24.7188 4.375 30.625 10.2812 30.625 17.5C30.625 24.7188 24.7188 30.625 17.5 30.625Z"
      fill="#00DC58"
    />
    <path
      d="M11.7578 18.3203L15.5859 22.1484L23.2422 13.9453"
      stroke="#00DC58"
      stroke-width="2.1875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
