import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Image,
} from "@nextui-org/react";

import {
  UploadPhotosModal,
  filesSignalLintingImg,
  signalhasDoneBulkUpload,
} from "./upload-photos.component";
import { TextErrorHelper, Typography } from "components";
import { ListingDetails, ListingDetailsSnapshot } from "services";
import { signalHasClickedMakeLiveListing } from "./manage-listing";
import { isThisDealer } from "utils/get-user-id";
import "./index.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";
import { useNavigate } from "react-router-dom";
import { findImage } from "utils";
import { env } from "config/env";
import { useDispatch } from "react-redux";
import { changeTicketView } from "modules/dealer/store/user-information";
import { UploadDocumentsModal } from "./upload-documents.component";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";
interface ActionListingProps {
  isItCreate: boolean;
  listing: ListingDetails | undefined;
  params: any;
  isPendingUpdateListing: boolean;
  actionBtn: number;
  seActionType: React.Dispatch<React.SetStateAction<number>>;
  aiOnState: boolean;
  setAiOnState: React.Dispatch<React.SetStateAction<boolean>>;
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  snapshot?: ListingDetailsSnapshot[];
  refetchListingData: ()=> void;
  updateListingAPICall:( hasPositionalImages:boolean,replaceImages:boolean)=>void
}

export const ActionListing: React.FC<ActionListingProps> = ({
  isItCreate,
  listing,
  params,
  isPendingUpdateListing,
  seActionType,
  actionBtn,
  aiOnState,
  setAiOnState,
  showPreview,
  setShowPreview,
  snapshot,
  refetchListingData,
  updateListingAPICall
}) => {
  // const { isOpen, onOpen, onOpenChange, } = useDisclosure({onClose:()=>setShowPreview(false)});
  const [isOpen, setIsOpen] = useState(showPreview);
  const [modalType, setModalType] = useState<"photo" | "document" | null>(null);

  const [images, setImages] = useState<File[]>([]);
  const [initDoc, setInitDoc] = useState<string[]>([]);

  const intiat_cover = useRef<File | null>(null);
  const userType = isThisDealer();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNoPhotosUploaded =
    signalHasClickedMakeLiveListing.value === true &&
    // signalhasDoneBulkUpload.value === false &&
    filesSignalLintingImg.value.length < 4 &&
    (isThisDealer() ? actionBtn === 1 : true );

  useEffect(() => {
    if (showPreview) {
      setIsOpen(true);
    }
  }, [showPreview]);

  useEffect(() => {
    if (!isItCreate) {
      plotImages(snapshot);
    }
  }, [snapshot]);

  const plotImages = useCallback(
    (snapshot: ListingDetailsSnapshot[] | undefined) => {
      if (snapshot) {
        for (const ss of snapshot) {
          const filteredImages = findImage(ss?.value);
          if (
            filteredImages &&
            (ss.groupName?.startsWith("Images") ||
              ss.code.startsWith("Primary_Image"))
          ) {
            fetch(
              `${env.BASE_API_URL}Listings/GetListingImage?fileName=${filteredImages}`
            )
              .then((res) => res.blob())
              .then((data) => {
                let metadata = {
                  type: "image/jpeg",
                };
                let file = new File([data], filteredImages, metadata);
                //not sure why but there is tre same img
                if (ss.code === "Primary_Image") {
                  intiat_cover.current = file;
                } else {
                  setImages((prev: any[]) => prev.concat(file));
                }
              });
          } else if (ss.code.startsWith("Document") && ss.value) {
            setInitDoc((prev: any[]) => prev.concat(ss.value));
          }
        }
      }
    },
    []
  );

  useEffect(() => {
    if (images) {
      filesSignalLintingImg.value = images.map((file) => ({
        file: file,
        part: "",
      }));
    }
  }, [images]);

  const handleReportInaccuracy = () => {
    dispatch(changeTicketView("1"));
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 justify-items-center gap-4 mt-10">
        {isNoPhotosUploaded && (
          <div className="col-span-3 -mb-2">
            <div className="flex flex-row gap-2 mr-2">
              <Image src="/assets/icons/error-outline.svg" alt="error" />
              <TextErrorHelper>Please Upload Images</TextErrorHelper>
            </div>
          </div>
        )}

        <Button
          type="button"
          onClick={() => {
            setIsOpen(true);
            setModalType("document");
          }}
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
        >
          Upload/View Documents
        </Button>
        <Button
          type="button"
          className={`bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl ${
            isNoPhotosUploaded && "border-3 border-[#f31260] text-[#f31260]"
          } `}
          onClick={() => {
            setIsOpen(true);
            setModalType("photo");
          }}
          isDisabled={
            isPendingUpdateListing || (isItCreate && listing?.status === 1)
          }
        >
          {isItCreate ? "Add" : " Add/Edit"} Photos
        </Button>
        <Button
          type="button"
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
          onClick={handleReportInaccuracy}
        >
          Report Inaccuracy
        </Button>
        <Button
          type="submit"
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
          onClick={() => seActionType(2)}
          isLoading={actionBtn === 2 && isPendingUpdateListing}
          isDisabled={isPendingUpdateListing || listing?.status === 2}
        >
          Mark Vehicle Sold
        </Button>
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
          isDisabled={
            isPendingUpdateListing || (isItCreate && listing?.status === 1)
          }
          isLoading={actionBtn === 1 && isPendingUpdateListing}
          type="submit"
          onClick={() => {
            seActionType(1);
          }}
          endContent={
            <img
              src="assets/icons/external-link.png"
              alt="external-link"
              className="bg-transparent mb-2 ml-2 w-6 h-6"
            />
          }
        >
          {isItCreate ? "Make Listing Live" : "Update Live Listing"}
        </Button>
        <Button
          type="submit"
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
          onClick={() => seActionType(0)}
          isLoading={actionBtn === 0 && isPendingUpdateListing}
          isDisabled={
            isPendingUpdateListing || (listing?.status === 0 && !!listing?.year)
          }
        >
          {userType ? "Archive Listing" : "Add To Garage"}
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen((p) => !p);
          setShowPreview(false);
          setModalType(null);
        }}
        scrollBehavior="outside"
        shadow="lg"
        backdrop="opaque"
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
          base: " max-[768px]:mt-0",
        }}
        size="3xl"
        closeButton={<BlackCloseXIcon tabIndex={-1} width={"32px"} height={"32px"} />}
      >
        <ModalContent className="add-photo-model">
          {(onClose) => (
            <>
              {showPreview ? (
                <div className="h-full min-h-[600] pt-36">
                  <ThankYou
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(`/listing/view-listing/${listing?.listingId}`);
                    }}
                    buttonText="Preview"
                    textNote="Listing published successfully"
                  />
                </div>
              ) : modalType === "photo" ? (
                <>
                  <ModalHeader className="flex items-center justify-center">
                    <Typography
                      variant="custom"
                      className="text-2xl font-[600] mt-2 -mb-2"
                    >
                      {isItCreate ? "Add" : " Add/Edit"} Photos
                    </Typography>
                  </ModalHeader>
                  <ModalBody className="">
                    <UploadPhotosModal
                      aiOnState={aiOnState}
                      setAiOnState={setAiOnState}
                      updateListingAPICall={updateListingAPICall}
                      onClose={onClose}
                      isItCreate={isItCreate}
                      listingId={Number(params?.listingId)}
                      images={images}
                      coverImg={intiat_cover.current}
                    />
                  </ModalBody>
                </>
              ) : (
                <>
                  <ModalHeader className="flex items-center justify-center">
                    <Typography
                      variant="custom"
                      className="text-2xl font-[600] mt-2 -mb-4"
                    >
                      View/Upload Documents
                    </Typography>
                  </ModalHeader>
                  <ModalBody className="">
                    <UploadDocumentsModal
                      // aiOnState={aiOnState}
                      // setAiOnState={setAiOnState}
                      onClose={onClose}
                      isItCreate={isItCreate}
                      listingId={Number(params?.listingId)}
                      initDoc={initDoc}
                      onSubmit={refetchListingData}
                      // images={images}
                      // coverImg={intiat_cover.current}
                    />
                  </ModalBody>
                </>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
