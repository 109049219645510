import * as React from "react";
import { Button, Card, CardBody, CardHeader, Image } from "@nextui-org/react";
import { Typography } from "components";
import { addSpaceEveryFourChars } from "modules/shared/features/payments/PaymentWithDetailsForm";
import VisaLogo from "../../../modules/shared/features/payments/Visa-logo.png";
import AmericanExpLogo from "../../../modules/shared/features/payments/AmericanExpressLogo.png";

interface CreditCardProps {
  handleAddCard: () => void;
  cardData?: any;
  username?: string;
}

export const CreditCard: React.FC<CreditCardProps> = ({
  handleAddCard,
  cardData,
  username,
}) => {
  
  return (
    <Card fullWidth isPressable={false}>
      <CardBody className="h-[360px] md:h-[355px]">
        <div className="grid grid-cols-8 gap-5">
          <div className="col-span-8">
            <CardHeader className="flex flex-row justify-between px-4 mb-4">
              <Typography
                variant="custom"
                className="text-xl md:text-2xl font-[600] text-[#070C84]"
              >
                Saved Cards
              </Typography>
              {/* <Button
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:mr-2 h-full py-2"
                size="md"
                disableAnimation
                type="submit"
                onClick={handleAddCard}
              >
                <Typography variant="custom" className="text-xs font-[600]">
                  Add New Card +
                </Typography>
              </Button> */}
            </CardHeader>
          </div>
        </div>
        <div className="max-w-md mx-auto bg-white overflow-hidden">
          {cardData ? (
            <div className="bg-gradient-to-r from-[#018FFD] to-[#070C84] rounded-lg p-4">
              <div className="grid grid-cols-8 gap-4">
                <div className="col-span-6 flex flex-row items-center">
                  <Image
                    src="/assets/icons/cc-card.svg"
                    alt="Bank logo"
                    className="h-6 w-6 mr-2"
                    removeWrapper
                    radius="none"
                  />
                  {/* <Typography
                  variant="custom"
                  className="text-white text-md font-[700]"
                >
                  ABC BANK
                </Typography> */}
                </div>
                <div className="col-span-2 flex flex-row items-center justify-center">
                  <Typography
                    variant="custom"
                    className=" text-white text-sm font-[700]"
                  >
                    CREDIT
                  </Typography>
                </div>
              </div>
              <div className="grid grid-cols-8 gap-4 mt-8">
                <div className="col-span-6 flex flex-row items-center">
                  <Typography
                    variant="custom"
                    className="text-white text-md font-[700]"
                  >
                    XXXX XXXX{" "}
                    {addSpaceEveryFourChars(
                      cardData?.payment?.Item?.cardNumber || ""
                    )}
                  </Typography>
                </div>
                <div className="col-span-2 flex flex-row items-center">
                  <Image
                    src="/assets/icons/wifi-outline.svg"
                    alt="Wifi icon"
                    className="h-8 w-12"
                    removeWrapper
                    radius="none"
                  />
                </div>
              </div>
              <div className="grid grid-cols-8 gap-4 mt-5">
                <div className="col-span-6 flex flex-row items-end">
                  <Typography
                    variant="custom"
                    className="text-white text-xl font-[600]"
                  >
                    {username || "N/A"}
                  </Typography>
                </div>
                <div className="col-span-2 flex flex-col items-center">
                  {cardData?.payment?.Item?.cardType === "AmericanExpress" ? (
                    <img
                      src={AmericanExpLogo}
                      alt="AmericanExpLogo-card"
                      className="max-w-16 max-h-16 p-2 "
                    />
                  ) : cardData?.payment?.Item?.cardType === "Visa" ? (
                    <img
                      src={VisaLogo}
                      alt="VisaLogo-card"
                      className="max-w-16 max-h-16 p-2 "
                    />
                  ) : cardData?.payment?.Item?.cardType === "MasterCard" ? (
                    <Image
                      src="/assets/icons/master-card.svg"
                      alt="Mastercard logo"
                      className="h-8 w-10"
                      removeWrapper
                      radius="none"
                    />
                  ) : (
                    ""
                  )}

                  <Typography
                    variant="custom"
                    className="text-white text-xs font-[500] text-center"
                  >
                    {cardData?.payment?.Item?.cardType || ""}
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center flex-col text-center gap-2">
              <Typography variant="custom" className="font-bold text-lg md:text-2xl" >No Linked Card</Typography>
              <Typography variant="custom" className="font-semibold text-base md:text-xl">
                Enter your card details below to securely save your payment
                information.
              </Typography>
              <Button
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-2xl h-full py-2 md:py-4 md:px-4 mt-4"
                size="md"
                disableAnimation
                type="submit"
                onClick={handleAddCard}
              >
                <Typography variant="custom" className="font-[600]">
                  Add New Card +
                </Typography>
              </Button>
            </div>
          )}
          {/* <button className=" text-black py-2 w-full font-font-Metropolis flex justify-end">
            Remove Card
          </button> */}
        </div>
      </CardBody>
    </Card>
  );
};
