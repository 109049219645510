import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


export const useForgetPassword = ( paramsName:string, handleOpenModal: ()=>void ) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const paramData = searchParams.get(paramsName);
  const handleRemoveParam = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(paramsName);

    setSearchParams(newSearchParams); // Update component state (optional)
    // navigate({ search: newSearchParams.toString(), replace: true });
  };

  const handleAddParam = ( paramValue: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.append(paramsName, paramValue);
    setSearchParams(newSearchParams); // Update component state (optional)
    navigate({ search: newSearchParams.toString() });
  };
  useEffect(() => {
    if (paramData) {
      handleOpenModal();
    }
  }, [paramData]);
  return {
    handleAddParam,
    handleRemoveParam,
  };
};
