import { Typography } from "components/typography";
import { SearchOrderHistory } from "./search-order-history.component";
import { ListOrderHistory } from "./list-order-history.component";
import { useEffect, useState } from "react";
import { useShippingOrderHistory } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { CarShippignData } from "services/endpoints/shipping-quotes/shipping.type";

export const OrderHistoryShippingPage = () => {
  const [page, setPage] = useState<number | null>(1);
  const [items, setItem] = useState<CarShippignData[]>([]);
  const { refetch } = useShippingOrderHistory(page || 1, (newData) => {
    setPage((prev) => (newData.length > 0 ? prev && prev + 1 : null));
    setItem((prev) => [...prev, ...newData]);
  });

  return (
    <div className="grid grid-cols-1 pb-3 w-full h-full">
      <div className="flex flex-col gap-7 items-center justify-center mb-10">
        <Typography variant="pageTitle">Order History</Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 items-end justify-end mb-5 sm:justify-items-center lg:justify-items-end w-full">
        <SearchOrderHistory />
      </div>
      <div>
        <ListOrderHistory
          page={page}
          items={items || []}
          next={() => {
            refetch();
          }}
        />
      </div>
    </div>
  );
};
