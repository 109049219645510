import { LoginDetails } from "./login-details.component";
import { CreditCard } from "./credit-card.component";
import { TierDetails } from "./tier-details.component";
import { useGetDealerById } from "services";
import { getUserId } from "utils/get-user-id";
import Loading from "components/Loading/Loading";
import { UserTierOpt } from "services/endpoints/user-membership/user-membership.type";
import { PaymentModal } from "pages/shared";
import { useEffect, useState } from "react";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import { useProcessUserTier } from "hooks/api/mutations/user-membeship/use-user-membership";
import {
  useAddPaymenTransactiontMethod,
  useGetCreditCardMethodStatusData,
  useTransactionsCheckForPaymentMethod,
} from "hooks/api/mutations/shipping/use-shipping-quotes";

export const DealerMembership = () => {
  // const [select, setSelect] = useState<UserTierOpt>("FMO_DEALER_BASIC_TIER");
  const [showPayModal, setShowPayModal] = useState({
    show: false,
    ammount: "",
    tierId: "",
  });
  //payment related hooks

  // const { mutateAsync: mutateAsyncCheckPayment, data: checkIfPaymentExist } =
  //   useTransactionsCheckForPaymentMethod();
  
  const {  data: checkIfPaymentExist, refetch: mutateAsyncCheckPayment } =
  useGetCreditCardMethodStatusData(true);

  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: errorAddCard
  } = useAddPaymenTransactiontMethod();

  //tiers related hooks
  // const { data: dealearData, isLoading: isLoadingDealerData } =
  //   useGetDealerById(getUserId());
  const {
    mutateAsync: mutateProcessUserTierAsync,
    isPending: isLoadingProcessUserTierAsync,
    error: processTierError
  } = useProcessUserTier();

  // const handlePaymentOnUserMembership = async (
  //   ammount: string,
  //   key: UserTierOpt
  // ) => {
  //   setShowPayModal({ ammount: ammount, show: true, tierId: key });
  // };
  const handleConfirmPaymentForTiers = async (e: any) => {
    try {
      if (checkIfPaymentExist) {
        mutateProcessUserTierAsync(showPayModal.tierId as UserTierOpt);
      } else {
        mutateAddPaymenTransactiontMethod(e);
        mutateProcessUserTierAsync(showPayModal.tierId as UserTierOpt);
        mutateAsyncCheckPayment()
      }
      // setSelect(showPayModal.tierId as UserTierOpt);
      setShowPayModal((p) => ({ ...p, show: false }));
    } catch (e) {
      console.log("Error :", e);
    }
  };

  
  // useEffect(() => {
  //   if (dealearData) {
  //     setSelect(
  //       (dealearData?.subscriptionPackage ||
  //         "FMO_DEALER_BASIC_TIER") as UserTierOpt
  //     );
  //   }
  // }, [dealearData]);

  // if (isLoadingDealerData) return <Loading />;

  return (
    <div className="px-5 lg:px-10 xl:px-22">
      <div className=" md:grid grid-cols-12 gap-5">
        <div className="col-span-6 h-full">
          <LoginDetails />
        </div>
        <div className="col-span-6 mt-5 md:mt-0">
          <CreditCard
            handleAddCard={() => {
              setShowPayModal((p) => ({ ...p, show: true, tierId: "" }));
            }}
            cardData={checkIfPaymentExist}
          />
        </div>
        {/* <div className="col-span-12">
          <TierDetails
            handlePaymentOnUserMembership={handlePaymentOnUserMembership}
            selectedTier={select}
          />
        </div> */}
      </div>
      <PaymentModal
        isOpen={showPayModal.show}
        onOpen={() => {
          setShowPayModal((p) => ({ ...p, show: false }));
          // setSelect(
          //   (dealearData?.subscriptionPackage ||
          //     "FMO_DEALER_BASIC_TIER") as UserTierOpt
          // );
        }}
        Component={
          <PaymentFormWithDetails
            handleNext={handleConfirmPaymentForTiers}
            payAmmount={showPayModal.ammount}
            savedMethod={checkIfPaymentExist}
            isPending={
              isLoadingProcessUserTierAsync ||
              isPendingToAddPaymenTransactiontMethod
            }
            showAmmount={showPayModal.tierId !== ""}
            errorMessage={errorAddCard?.message || processTierError?.message}
          />
        }
      />
    </div>
  );
};
