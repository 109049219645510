import React from "react";
import closeIcon from "./assets/close-icon.svg";
import checkmark_success from "./assets/checkmark_success.svg";

interface DmvTitleModalsProps {
  open: boolean;
  handleClose: () => void;
  handleContinue: () => void;
}

const DmvTitleSuccess: React.FC<DmvTitleModalsProps> = ({
  open,
  handleClose,
  handleContinue,
}) => {
  return (
    <React.Fragment>
      <div className="flex items-center m-auto dmv-title">
        <div className="flex items-center  bg-cover bg-center mx-auto px-4 bg-fixed">
          {open && (
            <div
              style={{ zIndex: 150 }}
              className="flex overflow-y-auto p-4 overflow-x-hidden fixed items-center  modal-container-vin pt-[130px] justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full"
            >
              <div className="absolute inset-0 bg-black opacity-50 z-999"></div>

              <div className="relative gradiant-modal-loading h-[524px] py-24 px-24   md:px-12  mx-auto  rounded-lg shadow-md ">
                <img
                  onClick={handleClose}
                  src={closeIcon}
                  alt="x"
                  className="absolute right-8 top-10 border-white border-2 p-1 rounded-full cursor-pointer"
                />

                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <img
                    className="m-auto mb-8 h-56 w-56"
                    src={checkmark_success}
                    alt="yes"
                  />
                  <div
                    className="text-lg mb-12 md:text-4xl text-nowrap  text-white font-bold text-center"
                    style={{ fontFamily: "Metropolis-Bold" }}
                  >
                    Successfully Submitted
                  </div>
                  <div
                    className="flex justify-center mt-4"
                    style={{ fontFamily: "Metropolis-SemiBold" }}
                  >
                    <button
                      className=" text-white confirm-btn-fin py-2  rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-2xl"
                      onClick={handleContinue}
                    >
                      Transit Status
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DmvTitleSuccess;
