import { z } from "zod";

const envSchema = z.object({
  ADDRESS_VALIDATION: z.string(),
  BASE_API_URL: z.string().url(),
  RECAPTCHA_SITE_KEY: z.string(),
  NEWS_API_URL: z.string().url(),
  AI_URL: z.string().url(),
  SF_HOSTNAME: z.string().optional(),
  SF_SESSIONID: z.string().optional(),
  ENABLE_COMETD: z.string(),
  SF_CHAT_ID: z.string(),
  SF_CHAT_ENVLABEL: z.string(),
  SF_CHAT_URL: z.string().url(),
  SF_CHAT_SCRTURL: z.string().url(),
  SF_CHAT_BOOTSCRIPT_URL: z.string().url(),

});

const envObj = {
  ADDRESS_VALIDATION: process.env.REACT_APP_ADDRESS_VALIDATION,
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  NEWS_API_URL: process.env.REACT_APP_NEWS_API_URL,
  AI_URL: process.env.REACT_APP_AI_URL,
  SF_HOSTNAME: process.env.REACT_APP_SF_HOSTNAME,
  SF_SESSIONID: process.env.REACT_APP_SF_SESSIONID,
  ENABLE_COMETD: process.env.REACT_APP_ENABLE_COMETD,
  SF_CHAT_ID: process.env.REACT_APP_SF_CHAT_ID,
  SF_CHAT_ENVLABEL: process.env.REACT_APP_SF_CHAT_ENVLABEL,
  SF_CHAT_URL: process.env.REACT_APP_SF_CHAT_URL,
  SF_CHAT_SCRTURL: process.env.REACT_APP_SF_CHAT_SCRTURL,
  SF_CHAT_BOOTSCRIPT_URL: process.env.REACT_APP_SF_CHAT_BOOTSCRIPT_URL,
};

export const env = envSchema.parse(envObj);
