import { Typography } from "components";
import React, { useEffect } from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router";
export default function NationWideShippingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const benefitsOfShipping = [
    {
      name: "Nationwide Coverage: ",
      description:
        "We provide car shipping services to all 48 contiguous states, ensuring your vehicle can be delivered anywhere in the US.",
    },
    {
      name: "Transparent Pricing: ",
      description:
        "Get upfront quotes and pay directly through our platform, eliminating hidden fees or surprise charges.",
    },
    {
      name: "Convenience: ",
      description:
        "Simplify the car buying process by handling both purchase and shipping through our user-friendly website.",
    },
    {
      name: "Real-Time Tracking: ",
      description:
        "Stay informed throughout the entire shipping process with our convenient tracking portal.",
    },
    {
      name: "Reliable Carriers: ",
      description:
        "We partner with reputable and insured auto transporters to ensure safe and secure delivery of your vehicle.",
    },
    ,
    {
      name: "Seamless Integration: ",
      description:
        "Our full-service order processing integrates seamlessly with your car purchase on our marketplace, offering a one-stop solution.",
    },
  ];

  return (
    <div className="flex  text-wrap justify-center">
      <div className="md:p-2 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text 2xl:text-5xl text-3xl font-bold mb-10">
            Nationwide Shipping
          </p>
          <p className=" text-2xl font-bold pt-5 ">
            Get Your Car Delivered Across the Country with Ease
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 ">
          <p className="text-xl text-center">
            Buying a car from out of state? At findmyoptions.com, we make the
            entire process seamless, from browsing our extensive marketplace to
            getting your new car delivered right to your doorstep. We offer a
            convenient solution for both vehicle shipping and full-service order
            processing, ensuring a stress-free experience.
          </p>
        </Typography>

        <Typography variant="custom" className="mt-10 ">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Seamless Nationwide Shipping:
          </p>

          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl text-wrap`}>
              <span className="font-semibold text-black bg-clip-text">
                StandAlone Quotes:{" "}
              </span>
              Need a quick estimate for shipping a car? Get a transparent and
              competitive quote for transporting your vehicle across any
              distance in the USA. Simply enter your pick-up and delivery
              locations, and we'll provide you with an upfront cost for reliable
              and secure shipping.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <div>
              <p
                className={`px-3 pt-3 text-black font-light text-xl text-wrap`}
              >
                <span className="font-semibold text-black bg-clip-text">
                  Full-Service Order Processing:{" "}
                </span>
                Found the perfect car on our marketplace, but located hundreds
                of miles away? Let us handle everything! We offer a hassle-free,
                full-service order processing system for your vehicle shipping
                needs. You can:
              </p>
              <ul className="list-none flex flex-col justify-center mt-5 text-lg px-[5%]">
                <li className="mb-2 flex items-center relative text-wrap">
                  <span className="inline-block w-2 h-2 rounded-full bg-black absolute top-2.5 -left-4"></span>
                  <span>
                    <span className="font-semibold">Initiate Your Order: </span>
                    Complete your car purchase through our secure marketplace
                    platform.
                  </span>
                </li>
                <li className="mb-2 flex items-center relative text-wrap">
                  <span className="inline-block w-2 h-2 rounded-full bg-black absolute top-2.5 -left-4"></span>
                  <span>
                    <span className="font-semibold">
                      Integrated Shipping Options:{" "}
                    </span>
                    Select and pay for your preferred shipping method directly
                    during checkout.
                  </span>
                </li>
                <li className="mb-2 flex items-center relative text-wrap">
                  <span className="inline-block w-2 h-2 rounded-full bg-black absolute top-2.5 -left-4"></span>
                  <span>
                    <span className="font-semibold">Real-Time Tracking:</span>
                    Our user-friendly portal allows you to conveniently track
                    your vehicle's progress every step of the way, from pick-up
                    to final delivery.
                  </span>
                </li>
                <li className="mb-2 flex items-center relative text-wrap">
                  <span className="inline-block w-2 h-2 rounded-full bg-black absolute top-2.5 -left-4"></span>
                  <span>
                    <span className="font-semibold">Peace of Mind:</span> Relax
                    knowing your car is in the hands of experienced and insured
                    transporters. We prioritize safe and secure delivery,
                    ensuring your vehicle arrives in the same condition it left
                    the seller.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-[100px]">
          <p className=" text-2xl font-bold  bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Benefits of Shipping with Us:
          </p>
          {benefitsOfShipping.map((res: any) => (
            <div className="flex items-start  ">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="mt-[16px]"
              />
              <p className={`px-3 pt-3 text-black font-light text-xl`}>
                <span className="font-semibold text-black bg-clip-text">
                  {res.name}
                </span>
                {res.description}
              </p>
            </div>
          ))}
        </Typography>
        <Typography variant="custom" className="mt-[80px] mb-10 ">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Get Your Car Delivered Today!{" "}
          </p>
          <p className="text-xl mt-2 text-wrap">
            Don't let distance deter you from finding your dream car. At
            findmyoptions.com, we make coast-to-coast car buying and shipping a
            breeze. Get a standalone quote for your shipping needs or explore
            our full-service order processing during your next car purchase.
          </p>
        </Typography>
        <Typography variant="custom" className="mt-[80px] mb-10 ">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Ready to get started?
          </p>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              Browse our extensive car listings and find the perfect vehicle for
              you.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              Get a free quote for your desired shipping route.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl text-wrap`}>
              Explore the full-service order processing option during checkout.
            </p>
          </div>
          <p className={` pt-10 text-black font-bold text-xl text-center `}>
            We are committed to providing you with a smooth and convenient car
            buying experience, no matter the distance involved.
          </p>
        </Typography>
        <div className="text-center">
          <Button
            className="bg-gradient-to-r  from-[#018ffd] to-[#070c84] text-white text-lg  py-8 tracking-wide px-6 rounded-xl mt-5"
            type="submit"
            onClick={() => navigate("/listing/tools/vehicle-shipping-quote")}
          >
            Get Shipping Quote
          </Button>
        </div>
      </div>
    </div>
  );
}
