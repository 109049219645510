import { MainSectionLandingPage } from "./main-section-landing.component";
import "./index.scss";
import { Typography } from "components";
import { ManualSearch } from "./manual-search.component";
import { WhyChooseFMO } from "./why-choose-fmo.component";
import { useEffect } from "react";
const LandingPage = () => {
  return (
    <div className="public-landing-page">
      <MainSectionLandingPage />
      <ManualSearch />

      <Typography variant="custom" className="mt-28 ">
        <p className=" text-2xl md:text-4xl mb-2 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text  font-bold pt-5 ">
          Become a Member Today!
        </p>
        <p className=" text-lg md:text-2xl mb-2 bg-gradient-to-  font-bold  ">
          What FMO membership offers:
        </p>

        <p className="text-sm md:text-md xl:text-xl xs:text-center md:text-left">
          Becoming an FMO member is a way to revolutionize the way you get help
          for car repairs and service. Instead of going to a mechanic first to
          get a diagnosis you can consult with our online team live 24/7 with
          questions about anything related to your vehicle, including break
          downs, stereo not working, fluids leaking, battery issues, etc! No
          more going to the mechanic and getting a huge bill for maintenance and
          service you didn’t ask for or need! Our membership is free and
          includes live chat with an actual person as well as ability to create
          support tickets! Join us today and help us revolutionize the way you
          consult for repairs!
        </p>
      </Typography>

      <Typography variant="custom" className="mt-14 ">
        <Typography
          className="my-4 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text"
          variant="title"
        >
          <div className="text-2xl md:text-4xl ">Mission</div>
        </Typography>

        <p className="text-sm md:text-md xl:text-xl xs:text-center md:text-left">
          The typical stereotype for a used car salesperson is fading away.
          These days sales people have gone digital. You can now customize your
          own deals online or in the palm of your hand. You know when you have
          questions you’re getting honest answers from a verified source. You
          can rest assured that the dmv paperwork will be handled correctly and
          the title will be transferred and taxes are paid correctly. You can
          organize to have the car shipped to your door from anywhere in the
          nation. You know your money is safe and secure by a reputable source.
          That's how buying a car should work in today's day and age and not
          just for the big corporations but for the everyday average Joe as
          well. That’s our mission here at FMO.
        </p>
      </Typography>

      <WhyChooseFMO />
    </div>
  );
};

export default LandingPage;
