import { useMutation, useQuery } from "@tanstack/react-query";
import { appyForLoanApplication } from "modules/user/services/endpoints/apply-finance/apply-finance.endpoint";
import { ISubmitVehiclePurchaseApplicationPayload, IVehicleBuyerApplicationPayloadAppyFinance } from "modules/user/services/endpoints/apply-finance/apply-finance.type";



  export const useSubmitVehiclePurchaseApplication= () => {
    return useMutation<any, Error ,ISubmitVehiclePurchaseApplicationPayload>({
      mutationFn: async (payload) => {
        const res = await appyForLoanApplication.SubmitVehiclePurchaseApplication(payload);
        return res;
      },
    });
  };

  export const useSubmitBuyerInformation= () => {
    return useMutation<any, Error ,IVehicleBuyerApplicationPayloadAppyFinance>({
      mutationFn: async (payload) => {
        const res = await appyForLoanApplication.SubmitBuyerInformation(payload);
        return res;
      },
    });
  };
  