import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Checkbox } from "@nextui-org/react";
import { DateTextField,  Typography } from "components";
import paymentBg from "../../../pages/dealer/offer-accepted/assets/payment-bg.png";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetCurrentUserForBoth } from "services";
import { getUserId, isThisDealer } from "utils/get-user-id";
import { CustomInput, FormControl } from "./components/custome";
import Input from "components/ui/input";
import {
  APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM,
  IFormDataForFinance,
  IFormTypeForFinance,
  SchemaCoBuyer,
  schema,
  Schema,
  schemaCoBuyer,
} from "./components/schema.type";
import {
  EmploymentInformationForm,
  ResidentialInformationForm,
} from "./components/ApplyOnlineFormSections.component";

interface Props {
  onCallback: (data: IFormDataForFinance) => void;
  onUploadDocuments: () => void;
  SalesData: any | null;
  sumbitError?: string;
  isPendingToSumbit?: boolean;
  isPreRequest?: boolean;

}
export default function AppyOnlineVT({
  SalesData,
  onCallback,
  isPendingToSumbit,
  sumbitError,
  onUploadDocuments,
  isPreRequest
}: Props) {
  const userId = getUserId();
  const [buyerType, setBuyerType] = useState<IFormTypeForFinance>("BUYER");
  const [formState, setFormState] = useState<IFormDataForFinance | null>(null);
  const [checkTerm, setCheckTerm] = useState<boolean>(false);
  const [customError, setCustomError] = useState<string>("");

  const { data: userData } = useGetCurrentUserForBoth(userId, isThisDealer());

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
    getValues,
  } = useForm<Schema & SchemaCoBuyer>({
    resolver: zodResolver(buyerType === "BUYER" ? schema : schemaCoBuyer),
  });

  const onSubmit = (data: Schema | SchemaCoBuyer) => {
    console.log(data);
    const  coBuyerdata =  schemaCoBuyer.safeParse(formState?.cobuyer)
    if(!coBuyerdata.success && formState?.cobuyer != null ){
      setCustomError("please fill the co-buyer form too.")
      return 0;
    } else{
      setCustomError("")
      
    }
    // if (formState) {
      if (buyerType === "BUYER") {
        onCallback({ buyer: data as any, cobuyer: formState?.cobuyer || null });
      } else {
        onCallback({ buyer: formState?.buyer || null, cobuyer: data as any });
      }
    // }
  };

  useEffect(() => {
    if (userData?.username) {
      setValue("PersonalInformation.FirstName", userData.firstName);
      setValue(
        "PersonalInformation.DateOfBirth",
        new Date(userData.dateOfBirth)
      );
      setValue("PersonalInformation.Email", userData.email);
      setValue("PersonalInformation.LastName", userData.lastName);
      setValue("PersonalInformation.DriverLicenseState", userData.state);
      setValue("PersonalInformation.ContactNumber", userData.phoneNumber);
      setValue("ResidentialInformation.top.StreetAddress", userData.address);
      setValue("ResidentialInformation.top.City", userData.city);
      setValue("ResidentialInformation.top.State", userData.state);
      setValue("ResidentialInformation.top.ZipCode", userData.zipCode);
      // setValue("ResidentialInformation.top.City", userData.phoneNumber);
      // setValue("AddPreviousAddress",true)
      trigger("PersonalInformation.Email");
    }
  }, [userData]);

  const handleChangeForm = async (type: IFormTypeForFinance) => {
    if (type === "BUYER") {
      //save co-buyuer
      setFormState((prev) => ({
        buyer: prev?.buyer || (null as any),
        cobuyer: getValues() as any,
      }));
      await setBuyerType("BUYER");
      reset((prev) => ({ ...((formState?.buyer || prev) as any) }));
    } else {
      setFormState((prev) => ({
        buyer: getValues() as any,
        cobuyer: prev?.cobuyer || null,
      }));
      setBuyerType(type);
      reset((prev) => ({ ...((formState?.cobuyer || prev) as any) }));
    }
  };


  return (
    <Card
      className="w-full p-4 rounded-[4rem]"
      style={{
        backgroundImage: `url(${paymentBg})`,
        backgroundSize: "cover",
        backgroundPosition: "-18 0",
      }}
    >
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full flex ml-auto  gap-6 my-2">
            <Button
              // name="Archived"
              className={`
                    ${
                      buyerType === "BUYER" ? "selected" : "default"
                    }  text-[15px] lg:text-[20px] px-5  text-center ml-auto  rounded-xl w-full md:w-40
                `}
              onClick={() => handleChangeForm("BUYER")}
            >
              Buyer
            </Button>
            <Button
              // name=""
              className={`${
                buyerType === "CO_BUYER" ? "selected" : "default"
              } text-[15px] lg:text-[20px] px-5 text-center  rounded-xl w-full md:w-40`}
              onClick={() => handleChangeForm("CO_BUYER")}
            >
              Co-Buyer
            </Button>
          </div>
          <div className="flex my-2 justify-end">
            <Typography variant="custom">
              All fields marked with an asterisk (*) are required
            </Typography>
          </div>

          {buyerType === "CO_BUYER" && (
            <section className="my-6 lg:my-8">
              <div className="grid grid-cols-1 md:grid-cols-2 my-6 place-items-center gap-x-10">
                <div className="w-full">
                  <div className="flex   items-center ">
                    <input
                      className="border-black "
                      onClick={() => trigger("AddPreviousAddress")}
                      checked={getValues("AddPreviousAddress")}
                      onChange={(e) => {
                        setValue("AddPreviousAddress", e.currentTarget.checked);
                      }}
                      type="checkbox"
                    />{" "}
                    <Typography
                      variant="custom"
                      className=" text-center text-[#018FFD] mx-2  font-[600]"
                    >
                      Add Previous Address
                    </Typography>
                  </div>
                  <Typography
                    variant="custom"
                    className=" text-left text-sm  font-[600]"
                  >
                    If less than 2 years of residence history please add
                  </Typography>
                </div>

                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                  >
                    Relationship to Buyer *:
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    placeholder="Relationship Type"
                    className="col-span-3"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    errorMessage={(errors as any)?.RelationshipToBuyer?.message}
                    {...register("RelationshipToBuyer")}
                  />
                </FormControl>
              </div>

              {/* {getValues("AddPreviousAddressCo") && (
                <ResidentialInformationForm
                  register={register}
                  getValues={getValues}
                  //@ts-ignore
                  errorObj={errors?.PreviousResidentialInformationCo}
                  type="PreviousResidentialInformationCo"
                />
              )} */}
            </section>
          )}

          <section className="my-6 lg:my-8">
            <Typography
              className="text-xl md:text-3xl font-[Metropolis] font-semibold my-3"
              variant="custom"
            >
              Personal Information
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
              {/* <div className="col-span-2"> */}
              {APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM.PersonalInformation.map(
                (field, id) => {
                  return (
                    <FormControl key={id}>
                      <Typography
                        variant="custom"
                        className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                      >
                        {field.label}
                      </Typography>
                      {field?.type === "date" ? (
                        <>
                          <DateTextField
                            classNames={{
                              input: "px-4 text-center",
                            }}
                            errorMessage={
                              errors?.PersonalInformation?.[
                                field.name as keyof Schema["PersonalInformation"]
                              ]?.message
                            }
                            {...register(
                              `PersonalInformation.${field.name}` as any
                            )}
                            initDate={getValues(
                              `PersonalInformation.${field.name}` as any
                            )}
                            onDateChange={(date) => {
                              register(
                                `PersonalInformation.${field.name}` as any
                              ).onChange({
                                target: {
                                  name: `PersonalInformation.${field.name}` as any,
                                  value: date,
                                },
                              });
                            }}
                          />
                        </>
                      ) : field.name ==="ContactNumber" ?(<>
                        <CustomInput
                          errorMessage={
                            errors?.PersonalInformation?.[
                              field.name as keyof Schema["PersonalInformation"]
                            ]?.message
                          }
                          errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                          inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                          defaultValue={getValues(
                            `PersonalInformation.${field.name}` as any
                          )}
                          inputProp={{...register(
                            `PersonalInformation.${field.name}` as any
                          ),maxLength:14, onChange:(e:any)=>{
                            const inputValue = e.target.value;
                            let formattedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
                            
                            if (formattedValue.length < 4) {
                              formattedValue =inputValue
                            } else if (formattedValue.length < 7) {
                              formattedValue = 
                                `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`
                              
                            } else {
                              formattedValue= 
                                `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
                                  3,
                                  6
                                )}-${formattedValue.slice(6)}`
                              
                            }
                            
                            setValue(`PersonalInformation.${field.name}` as any,formattedValue)
                           trigger( `PersonalInformation.${field.name}` as any)

                          }}}
                        />
                      </>): field.name ==="SSN" ?(<>
                        <CustomInput
                          errorMessage={
                            errors?.PersonalInformation?.[
                              field.name as keyof Schema["PersonalInformation"]
                            ]?.message
                          }
                          errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                          inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                          defaultValue={getValues(
                            `PersonalInformation.${field.name}` as any
                          )}
                          inputProp={{...register(
                            `PersonalInformation.${field.name}` as any
                          ),maxLength:11, onChange:(e:any)=>{
                            const rawSSN = e.target.value?.replace(/\D/g, "");
                            let formattedValue = ""; // Remove non-numeric characters
                            
                            if (rawSSN.length > 3) {
                              formattedValue += rawSSN.substr(0, 3) + '-';
                              if (rawSSN.length > 5) {
                                formattedValue += rawSSN.substr(3, 2) + '-';
                                formattedValue += rawSSN.substr(5);
                              } else {
                                formattedValue += rawSSN.substr(3);
                              }
                            } else {
                              formattedValue = rawSSN;
                            }
                        
                            
                            setValue(`PersonalInformation.${field.name}` as any,formattedValue)
                           trigger( `PersonalInformation.${field.name}` as any)

                          }}}
                        />
                      </>): (
                        <>
                          <CustomInput
                            errorMessage={
                              errors?.PersonalInformation?.[
                                field.name as keyof Schema["PersonalInformation"]
                              ]?.message
                            }
                            errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                            inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                            defaultValue={getValues(
                              `PersonalInformation.${field.name}` as any
                            )}
                            inputProp={register(
                              `PersonalInformation.${field.name}` as any
                            )}
                          />
                        </>
                      )}
                    </FormControl>
                  );
                }
              )}
            </div>

            {/* </div> */}
          </section>

          <section className="my-6 lg:my-8" id="Residential_Information_select">
            <Typography
              className="text-xl md:text-3xl font-[Metropolis] font-semibold my-3"
              variant="custom"
            >
              Residential Information
            </Typography>
            <ResidentialInformationForm
              register={register}
              getValues={getValues}
              setValue={setValue}
              errorObj={errors?.ResidentialInformation}
              type="ResidentialInformation"
            />
            <div className="flex  mt-4 mb-2 items-center ">
              <input
                className="border-black "
                {...register("AddPreviousAddress")}
                onClick={() => trigger("AddPreviousAddress")}
                checked={getValues("AddPreviousAddress")}
                onChange={(e) => {
                  setValue("AddPreviousAddress", e.currentTarget.checked);
                }}
                type="checkbox"
              />
              <Typography
                variant="custom"
                className=" text-center  font-[600] mx-2 text-blue-500"
              >
                Add Previous Address
              </Typography>
            </div>

            {getValues("AddPreviousAddress") && (
              <ResidentialInformationForm
                register={register}
                setValue={setValue}
                getValues={getValues}
                //@ts-ignore
                errorObj={errors?.PreviousResidentialInformation}
                type="PreviousResidentialInformation"
              />
            )}
          </section>

          <section className="my-6 lg:my-8">
            <Typography
              className="text-xl md:text-3xl font-[Metropolis] font-semibold my-3"
              variant="custom"
            >
              Employment Information
            </Typography>
            <EmploymentInformationForm
              type="EmploymentInformation"
              register={register}
              getValues={getValues}
              errorObj={errors?.EmploymentInformation}
            />
            <div className="flex mt-4 mb-2   items-center ">
              <input
                className="border-black  "
                {...register("PreviousEmployment")}
                onClick={() => trigger("PreviousEmployment")}
                type="checkbox"
              />{" "}
              <Typography
                variant="custom"
                className=" text-center text-blue-500  mx-2 font-[600]"
              >
                Previous Employment
              </Typography>
            </div>

            {getValues("PreviousEmployment") && (
              <EmploymentInformationForm
                type="PreviousEmploymentInformation"
                register={register}
                getValues={getValues}
                //@ts-ignore
                errorObj={errors?.PreviousEmploymentInformation}
              />
            )}
          </section>
          {buyerType === "BUYER" && (
            <>
              {isPreRequest &&<section className="my-6 lg:my-8">
                <Typography
                  className="text-xl md:text-3xl font-[Metropolis] font-semibold my-3"
                  variant="custom"
                >
                  Interested Vehicle
                </Typography>
                <div className="">
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10">
                    {APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM.InterestedVehicle.map(
                      (field, id) => {
                        return (
                          <FormControl key={id}>
                            <Typography
                              variant="custom"
                              className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                            >
                              {field.name}
                            </Typography>
                            <Input
                              variant="form"
                              size="compact"
                              className="col-span-3"
                              classNames={{
                                input: "px-4 text-center",
                              }}
                              value={String(SalesData?.[field.name]) || "N/A"}
                              isDisabled={true}
                            />
                          </FormControl>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>}
              <section className="my-6 lg:my-8">
                <div className="my-6 flex items-center justify-between">
                  <Button
                    type="button"
                    // isDisabled={!checkTerm}
                    onClick={onUploadDocuments}
                    // isLoading={isPendingToSumbit}
                    className="bg-gradient-to-r max-w-[22rem] mx-auto from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                  >
                    Upload Documents
                  </Button>
                </div>
                <Typography variant="custom" className="my-6">
                  By clicking "Submit", I, the undersigned, (a) for the purpose
                  of securing credit, certify the below representations to be
                  correct; (b) authorize financial institutions, as they
                  consider necessary and appropriate, to obtain consumer credit
                  reports on me periodically and to gather employment history,
                  and (c) understand that we, or any financial institution to
                  whom this application is submitted, will retain this
                  application whether or not it is approved, and that it is the
                  applicant's responsibility to notify the creditor of any
                  change of name, address, or employment. We and any financial
                  institution to whom this application is submitted, may share
                  certain non-public personal information about you with your
                  authorization or as provided by law. I consent to be contacted
                  by the dealer at any telephone number or Email I provide,
                  including, without limitation, communications sent via text
                  message to my cell phone or communications sent using an
                  autodialer or prerecorded message. This acknowledgment
                  constitutes my written consent to receive such communications.
                  I have read and agree to the Privacy Policy of this dealer.
                </Typography>
                <div className="flex   items-center my-4">
                  <Checkbox
                    size="md"
                    className="border-black  "
                    isSelected={checkTerm}
                    onChange={(e) => setCheckTerm(e.currentTarget.checked)}
                    radius="sm"
                  />{" "}
                  <Typography
                    variant="custom"
                    className=" text-center  font-[600]"
                  >
                    I accept all the above terms
                  </Typography>
                </div>
                <div className="flex flex-col items-center justify-center">
                  {(sumbitError || customError.length > 0)  && (
                    <Typography
                      variant="custom"
                      className=" text-center text-red-500 font-[500]"
                    >
                      {sumbitError || customError}
                    </Typography>
                  )}
                  
                  <Button
                    type="submit"
                    isDisabled={!checkTerm}
                    isLoading={isPendingToSumbit}
                    className="bg-gradient-to-r max-w-[32rem] from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                  >
                    Sumbit
                  </Button>
                </div>
              </section>
            </>
          )}
        </form>
      </CardBody>
    </Card>
  );
}
