import { Typography } from "components";
import React from "react";
// import checkIcon from "../../careers/assets/svg/check-icon.svg";
import checkIcon from "../../../public/careers/assets/svg/check-icon.svg";
import { localStorageFn } from "utils/localstorage";

export default function FmoMembershipAcceptPage() {
  const firstName = localStorageFn.getItem("firstName");
  return (
    <div className="flex items-center justify-center">
      <div className=" mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r   from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            Your are a Member
          </p>
          <p className="bg-gradient-to-r   from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-2xl font-bold pt-5">
            Welcome to the findmyoptions.com Membership!
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 ">
          <p className="text-xl">Hi {firstName?.toString()}</p>
          <p className="text-xl">
            Thanks for joining the findmyoptions.com membership! We're thrilled
            to have you on board. Now, you can say goodbye to car troubles
            leaving you feeling frustrated and confused.
          </p>
        </Typography>
        <div className="flex justify-center items-center"></div>
        <Typography variant="custom" className="mt-10 ">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
          Here's what your membership unlocks:
          </p>

          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Unlimited access to expert advisors:{" "}
              </span>
              Our network of vetted mechanics and automotive pros are available
              24/7 via live chat or support tickets, ready to answer your
              vehicle questions.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Peace of mind, anytime, anywhere:{" "}
              </span>
              No more waiting for business hours or feeling stranded. Get expert
              assistance whenever you need it.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Clear, personalized advice:{" "}
              </span>
              Our advisors tailor their guidance to your specific car and
              situation, giving you step-by-step instructions you can
              understand.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Save money and avoid costly mistakes:{" "}
              </span>
              Get informed advice before making repair decisions, preventing
              unnecessary fixes and saving you cash.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Become a car care pro:{" "}
              </span>
              Gain the confidence to tackle minor maintenance tasks and
              troubleshoot car issues effectively.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-10">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Ready to put your membership to the test? Here's how it works:
          </p>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Become a Member:{" "}
              </span>
              You're already covered there!
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Connect with an Advisor:{" "}
              </span>
              Choose live chat for immediate interaction or submit a detailed
              support ticket for a focused response.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Get Expert Advice:{" "}
              </span>
              Our certified advisors will analyze your issue and provide
              professional recommendations.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Fix Your Car with Confidence:{" "}
              </span>
              Use their guidance to diagnose the problem, perform basic repairs,
              or make informed decisions about professional services.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-10">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            With findmyoptions.com, you'll enjoy:
          </p>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Cost-effective:{" "}
              </span>
              Our affordable membership beats individual consultations or
              dealership repairs any day.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Convenience:{" "}
              </span>
              Get instant access to expert advice from the comfort of your home,
              24/7.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Trustworthy Advice:{" "}
              </span>
              Our advisors are highly qualified to provide reliable and accurate
              information.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Empowerment:{" "}
              </span>
              Gain the knowledge and confidence to handle car maintenance and
              troubleshoot issues like a pro.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checked"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Avoid Unnecessary Repairs:{" "}
              </span>
              Our expert guidance can help you avoid costly fixes and
              unnecessary replacements.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-16 mb-10 text-center">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Welcome to the world of stress-free car ownership! We're here to
            keep you rolling.
          </p>
          <p className="mt-8 mb-4 bg-gradient-to-r   from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold ">
            Happy Driving
          </p>
          <p className="text-xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            The findmyoptions.com Team
          </p>
        </Typography>
      </div>
    </div>
  );
}
