import { http } from "services/http/service.http";
import {
  ChangePasswordRequest,
  UserResponse,
  UserUpdateRequest,
  UsersResponse,
} from "./user.type";
import { isThisDealer } from "utils/get-user-id";

class UserEndpoint {
  public async getUsersAsync(): Promise<UsersResponse[]> {
    const res = await http.get("User");
    return res.data;
  }

  public async getCurrentUserByIdAsync(id: number): Promise<UserResponse> {
    const res = await http.get(`User/GetUserById?id=${id}`);
    return res.data;
  }

  public async updateUserByIdAsync(
    payload: UserUpdateRequest
  ): Promise<UserResponse> {
    const urlEndpoint = isThisDealer() ? "UpdateDealerById" : "UpdateUserById"
    const res = await http.post(`User/${urlEndpoint}`, payload);
    return res.data;
  }

  public async postUserProfilePictureAsync(
    payload: FormData,
    id: number
  ): Promise<UserResponse> {
    const res = await http.post(
      `User/PostUserProfilePicture?id=${id}`,
      payload
    );
    return res.data;
  }

  public async getUserProfilePictureAsync(id: number): Promise<UserResponse> {
    const res = await http.get(`User/image/${id}`);
    return res.data;
  }

  public async getDealerByIdAsync(id: number): Promise<UserResponse> {
    const res = await http.get(`User/GetDealerById?id=${id}`);
    return res.data;
  }

  public async updateDealerByIdAsync(
    payload: UserUpdateRequest
  ): Promise<UserResponse> {
    const res = await http.post(`User/UpdateDealerById`, payload);
    return res.data;
  }

  public async changePasswordAsync(
    payload: ChangePasswordRequest,
    type: "user" | "dealer" = "user"
  ): Promise<any> {
    const res = await http.post(`User/change-${type}-password`, payload);
    return res.data;
  }
}

export const userEndpoint = new UserEndpoint();
