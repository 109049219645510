
export const cards = [
  {
    id: "01",
    title: "Create an Account: ",
    description:"Signing up is quick and free!",
  
  },
  {
    id: "02",
    title: "List Your Car: " ,
    description: "Fill out the details about your car, including high-quality photos and a compelling description."  
  },
  {
    id: "03",
    title: "Set Your Price: ",
    description:"Research the market value and choose a competitive price to attract buyers."
  },
];

export const cardsDataForNationSell = [
  {
    id: "01",
    title: "Nationwide Reach: ",
    description:"List your car for sale to unlimited potential buyers across the country, increasing your chances of finding the right match.",
  
  },
  {
    id: "02",
    title: "Effortless Shipping: " ,
    description: "We’re integrated with a major shipping provider, from pick-up to secure delivery to the buyer all online!"  
  },
  {
    id: "03",
    title: "DMV Paperwork Simplified: ",
    description:"Relax, we help with the DMV paperwork for you, ensuring a smooth and stress-free transaction"
  },
  {
    id: "04",
    title: "Secure Online Payments: ",
    description:"Choose secure online payment processing through Escrow.com for added peace of mind (mention any associated fees). Local pickups with cash payments are also an option for your convenience.",
  
  },
  {
    id: "05",
    title: "Competitive Selling Platform: " ,
    description: "Our user-friendly platform makes listing your car and managing inquiries a breeze."  
  },
  {
    id: "06",
    title: "Safe and Secure Transactions: ",
    description:"We offer features to streamline communication and ensure a secure transaction process."
  },
];




