import { useMutation, useQuery } from "@tanstack/react-query";
import { fmomembershipEndpoint } from "modules/user/services/endpoints/fmo-membership/fmo-membership.endpoint";
import { FMOMemberShipType } from "modules/user/services/endpoints/fmo-membership/fmo-membership.type";

export const useSubmitFMOMembershipPayment= () => {
    return useMutation<any, Error >({
      mutationFn: async () => {
        const res = await fmomembershipEndpoint.SubmitFMOMembershipPayment();
        return res;
      },
    });
  };
  export const useProcessFMOMembershipPayment= () => {
    return useMutation<any, Error >({
      mutationFn: async () => {
        const res = await fmomembershipEndpoint.ProcessFMOMembershipPayment();
        return res;
      },
    });
  };

  export const useGetFMOMembershipStatus = (isPrivate:boolean) => {
    return useQuery<FMOMemberShipType, Error>({
      queryKey: ["fmo-membership-status"],
      queryFn: async () => {
        const res = await fmomembershipEndpoint.GetFMOMembershipStatus();
        return res;
      },
      enabled:isPrivate,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchInterval: false,
      retry:0
      
    });
  };