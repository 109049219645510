import { Typography } from "components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import { useNavigate, useParams } from "react-router-dom";
import { NewOrderForm } from "pages/shipping-quotes/new-order/new-order.form";
import AppyOnlineVT from "components/form/vehicle-transacttion-payments/AppyOnlineVT";
import { useGetListingDetails } from "services";
import { useSubmitBuyerInformation } from "modules/user/hooks/api/mutations/finance/use-finance";
import { IVehicleBuyerApplicationPayloadAppyFinance } from "modules/user/services/endpoints/apply-finance/apply-finance.type";
import { IFormDataForFinance } from "components/form/vehicle-transacttion-payments/components/schema.type";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import { UploadDocumentsBhph } from "./components/UploadDocumentBhphf.component";
import { CustomInput } from "components/form/vehicle-transacttion-payments/components/custome";
import { Button } from "@nextui-org/react";

const steps = [
  {
    step: "AppyOnlineVT",
    title: "Apply Online",
    isSuccessPayment: false,
  },
];

interface Props {
  isPreRequest: boolean
}

export default function ApplyFinanceLoan({isPreRequest=false}: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { bnphOffer } = useSelector((state: RootState) => state.sellerOffer);
  console.log("bnphOffer", bnphOffer);
  const [activeSteps, setActiveSteps] = useState<(typeof steps)[number]>({
    step: "AppyOnlineVT",
    title: "Apply Online",
    isSuccessPayment: false,
  });

  const [openModal, setOpenModal] = useState<string | null>(null);
  // const [acceptData, setAcceptData] = useState<{ ship: any } | null>(null);
  const { data: listingDetailsData } = useGetListingDetails(
    Number(params?.listingId) ?? 0
  );

  // buyer information

  // offer related hooks
  const {
    mutateAsync: mutateSubmitBuyerInformation,
    isPending: isPendingToSendBuyerInformation,
    error: sendOfferError,
  } = useSubmitBuyerInformation();

  const handleSumbmitBuyerInformation = (data: IFormDataForFinance) => {
    try {
      const listing = listingDetailsData?.listing;
      const payload: IVehicleBuyerApplicationPayloadAppyFinance = {
        vehicleBuyerApplicationHeader: {
          applicationId: 0,
          buyerApplicationId: 0,
          acceptTerms: true,
          vin: listing?.vin || "",
          year: +(listing?.year || 0),
          make: listing?.make || "",
          model: listing?.model || "",
          listingId: Number(params?.listingId) ?? 0,
          status: "",
          hasCoBuyer: !!data.cobuyer?.RelationshipToBuyer,
        },
        buyerResidentialInformation: {
          id: 0,
          buyerApplicationId: 0,
          isCoBuyer: false,
          streetAddress:
            data.buyer?.ResidentialInformation.top.StreetAddress || "",
          city: data.buyer?.ResidentialInformation.top.City || "",
          state: data.buyer?.ResidentialInformation.top.State || "",
          zipCode: data.buyer?.ResidentialInformation.top.ZipCode || "",
          housingType:
            data.buyer?.ResidentialInformation.bottom.HousingType || "",
          monthlyRentOrMortgageAmount: Number(
            data.buyer?.ResidentialInformation.bottom
              .MonthlyRentOrMortgageAmount || 0
          ),
          yearsAtAddress: Number(
            data.buyer?.ResidentialInformation.bottom.YearsAtAddress.years || 0
          ),
          monthsAtAddress: Number(
            data.buyer?.ResidentialInformation.bottom.YearsAtAddress.month || 0
          ),
          previousStreetAddress:
            data.buyer?.PreviousResidentialInformation?.top?.StreetAddress ||
            "",
          previousCity:
            data.buyer?.PreviousResidentialInformation?.top?.City || "",
          previousState:
            data.buyer?.PreviousResidentialInformation?.top?.State || "",
          previousZipCode:
            data.buyer?.PreviousResidentialInformation?.top?.ZipCode || "",
          previousHousingType:
            data.buyer?.PreviousResidentialInformation?.bottom?.HousingType ||
            "",
          previousMonthlyRentOrMortgageAmount: Number(
            data.buyer?.PreviousResidentialInformation?.bottom
              ?.MonthlyRentOrMortgageAmount || 0
          ),
          previousMonthsAtAddress: Number(
            data.buyer?.PreviousResidentialInformation?.bottom?.YearsAtAddress
              ?.month || 0
          ),
          previousYearsAtAddress: Number(
            data.buyer?.PreviousResidentialInformation?.bottom?.YearsAtAddress
              ?.years || 0
          ),
        },
        buyerEmploymentInformation: {
          id: 0,
          buyerApplicationId: 0,
          isCoBuyer: false,
          employerName:
            data.buyer?.EmploymentInformation.top.EmployerName || "",
          titleOrPosition:
            data.buyer?.EmploymentInformation.top.TitlePosition || "",
          employerPhoneNumber:
            data.buyer?.EmploymentInformation.top.EmployerPhoneNumber || "",
          monthlyGrossIncome: Number(
            data.buyer?.EmploymentInformation.bottom.MonthlyGrossIncome || 0
          ),
          yearsAtJob: Number(
            data.buyer?.EmploymentInformation.bottom.YearsAtJob.years || 0
          ),
          monthsAtJob: Number(
            data.buyer?.EmploymentInformation.bottom.YearsAtJob.month || 0
          ),
          previousEmployerName:
            data.buyer?.PreviousEmploymentInformation?.top?.EmployerName || "",
          previousTitleOrPosition:
            data.buyer?.PreviousEmploymentInformation?.top?.TitlePosition || "",
          previousEmployerPhoneNumber:
            data.buyer?.PreviousEmploymentInformation?.top
              ?.EmployerPhoneNumber || "",
          previousMonthlyGrossIncome: Number(
            data.buyer?.PreviousEmploymentInformation?.bottom
              ?.MonthlyGrossIncome || 0
          ),
          previousMonthsAtJob: Number(
            data.buyer?.PreviousEmploymentInformation?.bottom?.YearsAtJob
              ?.month || 0
          ),
          previousYearsAtJob: Number(
            data.buyer?.PreviousEmploymentInformation?.bottom?.YearsAtJob
              ?.years || 0
          ),
        },
        buyerPersonalInformation: {
          id: 0,
          buyerApplicationId: 0,
          isCoBuyer: false,
          firstName: data.buyer?.PersonalInformation.FirstName || "",
          lastName: data.buyer?.PersonalInformation.LastName || "",
          email: data.buyer?.PersonalInformation.Email || "",
          contactNumber: data.buyer?.PersonalInformation.ContactNumber || "",
          dateOfBirth:
            data.buyer?.PersonalInformation.DateOfBirth?.toISOString() || "0001-01-01",
          ssn: data.buyer?.PersonalInformation.SSN || "",
          driversLicenseNumber:
            data.buyer?.PersonalInformation.DriverLicenseNumber || "",
          driversLicenseState:
            data.buyer?.PersonalInformation.DriverLicenseState || "",
          driversLicenseIssueDate:
            data.buyer?.PersonalInformation.DriverLicenseIssueDate?.toISOString() ||
            "0001-01-01",
          driversLicenseExpiryDate:
            data.buyer?.PersonalInformation.DriverLicenseExpiryDate?.toISOString() ||
            "0001-01-01",
          cobuyerRelationshipWithBuyer: data.cobuyer?.RelationshipToBuyer || "",
        },
        cobuyerResidentialInformation: {
          id: 0,
          buyerApplicationId: 0,
          isCoBuyer: true,
          streetAddress:
            data.cobuyer?.ResidentialInformation.top.StreetAddress || "",
          city: data.cobuyer?.ResidentialInformation.top.City || "",
          state: data.cobuyer?.ResidentialInformation.top.State || "",
          zipCode: data.cobuyer?.ResidentialInformation.top.ZipCode || "",
          housingType:
            data.cobuyer?.ResidentialInformation.bottom.HousingType || "",
          monthlyRentOrMortgageAmount: Number(
            data.cobuyer?.ResidentialInformation.bottom
              .MonthlyRentOrMortgageAmount || 0
          ),
          yearsAtAddress: Number(
            data.cobuyer?.ResidentialInformation.bottom.YearsAtAddress.years ||
              0
          ),
          monthsAtAddress: Number(
            data.cobuyer?.ResidentialInformation.bottom.YearsAtAddress.month ||
              0
          ),
          previousStreetAddress:
            data.cobuyer?.PreviousResidentialInformation?.top?.StreetAddress ||
            "",
          previousCity:
            data.cobuyer?.PreviousResidentialInformation?.top?.City || "",
          previousState:
            data.cobuyer?.PreviousResidentialInformation?.top?.State || "",
          previousZipCode:
            data.cobuyer?.PreviousResidentialInformation?.top?.ZipCode || "",
          previousHousingType:
            data.cobuyer?.PreviousResidentialInformation?.bottom?.HousingType ||
            "",
          previousMonthlyRentOrMortgageAmount: Number(
            data.cobuyer?.PreviousResidentialInformation?.bottom
              ?.MonthlyRentOrMortgageAmount || 0
          ),
          previousMonthsAtAddress: Number(
            data.cobuyer?.PreviousResidentialInformation?.bottom?.YearsAtAddress
              ?.month || 0
          ),
          previousYearsAtAddress: Number(
            data.cobuyer?.PreviousResidentialInformation?.bottom?.YearsAtAddress
              ?.years || 0
          ),
        },
        cobuyerEmploymentInformation: {
          id: 0,
          buyerApplicationId: 0,
          isCoBuyer: true,
          employerName:
            data.cobuyer?.EmploymentInformation.top.EmployerName || "",
          titleOrPosition:
            data.cobuyer?.EmploymentInformation.top.TitlePosition || "",
          employerPhoneNumber:
            data.cobuyer?.EmploymentInformation.top.EmployerPhoneNumber || "",
          monthlyGrossIncome: Number(
            data.cobuyer?.EmploymentInformation.bottom.MonthlyGrossIncome || 0
          ),
          yearsAtJob: Number(
            data.cobuyer?.EmploymentInformation.bottom.YearsAtJob.years || 0
          ),
          monthsAtJob: Number(
            data.cobuyer?.EmploymentInformation.bottom.YearsAtJob.month || 0
          ),

          previousEmployerName:
            data.cobuyer?.PreviousEmploymentInformation?.top?.EmployerName ||
            "",
          previousTitleOrPosition:
            data.cobuyer?.PreviousEmploymentInformation?.top?.TitlePosition ||
            "",
          previousEmployerPhoneNumber:
            data.cobuyer?.PreviousEmploymentInformation?.top
              ?.EmployerPhoneNumber || "",
          previousMonthlyGrossIncome: Number(
            data.cobuyer?.PreviousEmploymentInformation?.bottom
              ?.MonthlyGrossIncome || 0
          ),
          previousMonthsAtJob: Number(
            data.cobuyer?.PreviousEmploymentInformation?.bottom?.YearsAtJob
              ?.month || 0
          ),
          previousYearsAtJob: Number(
            data.cobuyer?.PreviousEmploymentInformation?.bottom?.YearsAtJob
              ?.years || 0
          ),
        },
        cobuyerPersonalInformation: {
          id: 0,
          buyerApplicationId: 0,
          isCoBuyer: true,
          firstName: data.cobuyer?.PersonalInformation.FirstName || "",
          lastName: data.cobuyer?.PersonalInformation.LastName || "",
          email: data.cobuyer?.PersonalInformation.Email || "",
          contactNumber: data.cobuyer?.PersonalInformation.ContactNumber || "",
          dateOfBirth:
            data.cobuyer?.PersonalInformation.DateOfBirth?.toISOString() || "0001-01-01",
          ssn: data.cobuyer?.PersonalInformation.SSN || "",
          driversLicenseNumber:
            data.cobuyer?.PersonalInformation.DriverLicenseNumber || "",
          driversLicenseState:
            data.cobuyer?.PersonalInformation.DriverLicenseState || "",
          driversLicenseIssueDate:
            data.cobuyer?.PersonalInformation.DriverLicenseIssueDate?.toISOString() ||
            "0001-01-01",
          driversLicenseExpiryDate:
            data.cobuyer?.PersonalInformation.DriverLicenseExpiryDate?.toISOString() ||
            "0001-01-01",
          cobuyerRelationshipWithBuyer: data.cobuyer?.RelationshipToBuyer || "",
        },
      };

      mutateSubmitBuyerInformation(payload);
    } catch (e) {
      console.log(e);
    }
    setOpenModal("PendingReview");
  };

  useEffect(() => {
    if (!isPreRequest && !bnphOffer?.requestType) {
      navigate("/user/garage/saved-vehicles");
    }
  }, []);
  return (
    <>
      <div className="md:flex ">
        <div className="md:ml-5 pb-4  w-full flex flex-col items-center justify-center relative ">
          <div className="w-full flex justify-center flex-col items-center">
            <Typography
              variant="custom"
              className={`text-center  my-4 md:mb-16 md:ml-8 font-bold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
            >
              {activeSteps.title}
            </Typography>
            <div className={`${"md:pl-8"} w-full`}>
              {(() => {
                switch (activeSteps.step) {
                  case "AppyOnlineVT":
                    return (
                      <div className="flex items-end justify-center w-full max-w-[68rem] m-auto ">
                        <AppyOnlineVT
                          onCallback={(data) => {
                            handleSumbmitBuyerInformation(data);
                          }}
                          onUploadDocuments={() => {
                            setOpenModal(
                              bnphOffer?.requestType === "In_House_Financing"
                                ? "IncomeAssetsLiabilities"
                                : "Upload_Documents"
                            );
                          }}
                          SalesData={listingDetailsData?.listing}
                          isPendingToSumbit={isPendingToSendBuyerInformation}
                          sumbitError={sendOfferError?.message}
                          isPreRequest={isPreRequest}
                        />
                      </div>
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      <CustomizableHeaderModel
        size={"2xl"}
        isOpen={openModal !== null}
        onOpen={() => {
          setOpenModal(null);
        }}
        Component={
          openModal === "IncomeAssetsLiabilities" ? (
            <EnterIncomeAssetsLiabilities
              handleNext={() => setOpenModal("Upload_Documents")}
            />
          ) : openModal === "Upload_Documents" ? (
            <UploadDocumentsBhph
              typeOfferBhph={bnphOffer?.requestType}
              listingId={1}
              initDoc={[]}
              isItCreate={true}
              onClose={() => {
                setOpenModal(null);
              }}
            />
          ) : (
            <ShowStatusForFinaceApplication
              handleNext={() => setOpenModal(null)}
            />
          )
        }
      />
    </>
  );
}

const EnterIncomeAssetsLiabilities = ({
  handleNext,
}: {
  handleNext: () => void;
}) => {
  return (
    <div className="w-full h-full my-2 py-8">
      <div>
        <Typography
          variant="custom"
          className="font-[Metropolis] text-xl my-4 font-[700] md:text-2xl  text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text"
        >
          Income, Assets and Liabilities
        </Typography>
      </div>
      <div className="w-full  my-12 mb-8 flex ">
        <div className=" grid grid-cols-1 gap-0 md:gap-2 md:grid-cols-2  mx-auto  mb-4">
          {[
            "Monthly income from all sources",
            "Total Liquid Assets",
            "Monthly Expenses",
          ].map((item) => {
            return (
              <>
                <div className="text-left text-[14px] font-bold h-full flex items-end md:items-center">
                  {item}
                </div>{" "}
                <div className="md:mt-0 col-span-1   w-full md:w-auto flex gap-2   ">
                  <CustomInput
                    // errorMessage={
                    //   errors?.PersonalInformation?.[
                    //     field.name as keyof Schema["PersonalInformation"]
                    //   ]?.message
                    // }
                    wrapperClass="w-full mt-2 mb-4"
                    errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                    inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                    // defaultValue={getValues(
                    //   `PersonalInformation.${field.name}` as any
                    // )}
                    // inputProp={register(
                    //   `PersonalInformation.${field.name}` as any
                    // )}
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="mt-2 md:mt-10 pb-10 md:pb-5 flex flex-col gap-2 items-center justify-center">
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg py-6 md:py-8 tracking-wide px-10 rounded-xl"
          // isLoading={isLoading}
          // isDisabled={
          //   isItCreate
          //     ? isLoading || files.length < 1
          //     : isLoading || files.length < 1 || !isEdited
          // }
          // isDisabled={isItCreate ?((aiOnState?aiCarDetails.length <4 :files.length < 4) || isLoading || !canCreateList) : ((mainImg?.name === coverImg?.name && !isEdited ) || isLoading  || (aiOnState?aiCarDetails.length <4 :files.length < 4))}
          onClick={handleNext}
        >
          {"Continue"}
        </Button>
      </div>
    </div>
  );
};

const ShowStatusForFinaceApplication = ({
  handleNext,
}: {
  handleNext: () => void;
}) => {
  return (
    <div className="w-full px-4 md:px-20 py-32 flex flex-col items-center">
      <Typography
        variant="custom"
        className=" text-xl font-[Metropolis] font-semibold text-center"
      >
        Pending Review
      </Typography>
      <div className="w-full max-w-52">
        <Button
          onClick={handleNext}
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
