import { Button } from "@nextui-org/react";

interface Props {
  // handleContinue: () => void;
}
export default function BhphfContractHalfSixSalesDEtails({}: Props) {
  return (
    <div className="pt-28">
      {/* page 1 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  mb-24">
        <div className="">
          <h1 className="font-bold mb-10">
            Additional Terms of this Contract and Security Agreement
          </h1>
          <div>
            <span className="font-bold"> Finance Charge and Payments.</span> You
            agree to pay the Amount Financed and Finance Charge as provided in
            the Payment Schedule in the TRUTH-IN-LENDING DISCLOSURE on Page 1 of
            this Contract. You also agree to pay the Late Charge described on
            Page 1, and all other amounts required under this Contract. Finance
            Charge will be computed each day. Finance Charge is computed at the
            Annual Percentage Rate shown on Page 1 of this Contract. We may
            apply each payment to the Finance Charge, the unpaid part of the
            Amount Financed, and other amounts due under this Contract in any
            order we choose.
            <br className=""></br>
            <br className=""></br>
            <span className="font-bold">Security.</span> To secure your payment
            and performance under the terms of this Contract, you give us a
            security interest in the Vehicle, all accessions, attachments,
            accessories, and equipment placed in or on the Vehicle, and all
            proceeds. You also assign to us and give us a security interest in
            proceeds and refunds under any insurance policy, service contract or
            other contract purchased with this Contract as the law allows.
            <br className=""></br>
            <br className=""></br>
            <span className="font-bold">General Terms.</span> You have been
            given the opportunity to purchase the Vehicle and described services
            for the Total Cash Price or the Total Sale Price. The Total Sale
            Price is the total price of the Vehicle and any services if you buy
            them over time. You chose to purchase the Vehicle and services over
            time. The Total Sale Price shown in the TRUTH-IN-LENDING DISCLOSURE
            assumes that all payments will be made as scheduled. The actual
            amount you will pay will be more if you pay late and less if you pay
            early.
            <br className=""></br>
            <br className=""></br>
            We do not intend to charge or collect, and you do not agree to pay,
            any finance charge or fee that is more than the maximum amount
            permitted for this sale by state or federal law. If you pay a
            finance charge or fee that is contrary to this provision, we will,
            instead, apply it first to reduce the Amount Financed balance, and
            when the Amount Financed has been paid in full, refund it to you.
            You understand and agree that we (or our affiliate) will earn
            commissions or fees on any insurance products, and may earn such
            fees on other goods and services that you buy through us or our
            affiliate. If any section or provision of this Contract is not
            enforceable, the other terms will remain part of this Contract. The
            law of California will govern this transaction. Applicable federal
            law and regulations also govern it. The entire agreement between you
            and us is contained in this Contract. There are no unwritten
            agreements regarding this Contract. Any change to this Contract must
            be in writing and signed by you and us.
          </div>
        </div>
        <div>
          <span className="font-bold">Name and Location.</span> Your name and
          address indicated on Page 1 are your exact legal name and your
          principal residence. You will provide us with at least 30 days' notice
          prior to changing your name or principal residence.
          <br />
          <br />
          You agree that the Vehicle will not be used as a dwelling.
          <br />
          <br />
          <span className="font-bold">Prepayment.</span> You may prepay this
          Contract in full or in part at any time without penalty. Any partial
          prepayment will not excuse any later scheduled payments. If we get a
          refund of any unearned insurance premiums that you paid, you agree
          that we may subtract the refund from the amount you owe, unless
          otherwise provided by law.
          <br />
          <br />
          <span className="font-bold">
            Ownership and Duties Toward Vehicle.
          </span>
          By giving us a security interest in the Vehicle, you represent and
          agree to the following:
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">A.</span>
              The security interest you are giving us in the Vehicle and other
              property comes ahead of the claim of any other of your general or
              secured creditors. You agree to sign any additional documents or
              provide us with any additional information we may require to keep
              our claim to the Vehicle and other property ahead of the claim of
              anyone else. You will not do anything to change our interest in
              the Vehicle and other property.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">B.</span>
              You will defend our interests in the Vehicle and other property
              against claims made by anyone else. You will do whatever is
              necessary to keep our claim to the Vehicle and other property
              ahead of the claim of anyone else.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">C.</span>You will keep the
              Vehicle in your possession in good condition and repair. You will
              use the Vehicle for its intended and lawful purposes. You agree
              not to remove the Vehicle from the U.S. or Canada, or to sell,
              rent, lease, or transfer any interest in the Vehicle or this
              Contract without our written permission. You agree to keep the
              Vehicle free from seizure, confiscation, or involuntary transfer.
              Unless otherwise agreed in writing, the Vehicle will be located
              primarily at your address listed on Page1 of this Contract.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">D.</span>You will pay all taxes
              and assessments on the Vehicle as they become due.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">E.</span>
              You will notify us of any loss or damage to the Vehicle. You will
              provide us reasonable access to the Vehicle for the purpose of
              inspection. Our entry and inspection must be accomplished
              lawfully, and without breaching the peace.
            </li>
          </ol>
        </div>
      </div>
      {/* page 2 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  mb-24">
        <div>
          <span className="font-bold">Default.</span>
          You will be in default on this Contract if any one of the following
          occurs (except as prohibited by law):
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">A.</span>
              You fail to perform any obligation that you have undertaken in
              this Contract.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">B.</span>
              You become a debtor in any bankruptcy proceeding, or others take
              legal action to collect money or repossess the Vehicle from you.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">C.</span>
              You fail to pay all you owe under this Contract if the Vehicle
              becomes a total loss or is missing or stolen.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">D.</span>
              You give inaccurate, incomplete, or misleading information on your
              credit application or in this Contract (See the information you
              provide to us section of this contract)
            </li>
          </ol>
          If you default, you agree to pay our costs for collecting amounts
          owing, including, without limitation, court costs, attorneys' fees,
          and fees for repossession, repair, storage and sale of the Vehicle
          securing this Contract.
          <br />
          <br />
          If an event of default occurs as to any one of you, we may exercise
          our remedies against any or all of you.
          <br />
          <br />
          <span className="font-bold">Gap Liability Notice.</span> In the event
          of theft or damage to your Vehicle that results in a total loss, there
          may be a gap between the amount you owe under this Contract and the
          proceeds of your insurance settlement and deductible. THIS CONTRACT
          PROVIDES THAT YOU ARE LIABLE FOR THE GAP AMOUNT. Optional Gap Waiver
          may be offered for an additional charge.
          <br />
          <br />
          <span className="font-bold">Remedies.</span>
          If you default on this Contract, we may exercise the remedies provided
          by law and this Contract subject to any right the law gives you to
          reinstate this Contract. Those remedies include:
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">A.</span>
              We may require you to immediately pay us, subject to any refund or
              reinstatement rights, the remaining unpaid balance of the Amount
              Financed, finance charges and all other agreed charges.{" "}
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">B.</span>
              We may pay taxes, assessments, or other liens or make repairs to
              the Vehicle if you have not done so. We are not required to do so.
              You will repay us that amount when we tell you to do so. That
              amount will earn finance charges from the date we pay it at the
              Annual Percentage Rate stated in the of this Contract, not to
              exceed the highest rate permitted by law.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">C.</span>
              We may require you to make the Vehicle available to us at a place
              we designate that is reasonably convenient to you and us.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">D.</span>
              We may immediately take possession of the Vehicle by legal process
              or self-help, but in doing so we may not breach the peace or
              unlawfully enter onto your premises. We may then sell the Vehicle
              and apply what we receive as provided by law to our reasonable
              expenses and then toward your obligations. Our reasonable expenses
              can include, among other things, costs of repossession, storage,
              sale preparation, and sale.
            </li>
          </ol>
        </div>

        <div>
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">E.</span>
              If the Vehicle is repossessed, a total loss, confiscated or
              stolen, we may claim benefits under or cancel any insurance,
              maintenance, service, or other contract purchased under this
              Contract and use the resulting proceeds or refund of unearned
              charges to reduce what you owe under this Contract.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">F.</span>
              Except when prohibited by law, we may sue you for remaining
              amounts if the proceeds of a sale do not pay all of the amounts
              you owe us. You will pay interest on these remaining amounts until
              paid at the Annual Percentage Rate shown on Page 1 of this
              Contract, not to exceed the highest rate permitted by law.
            </li>
          </ol>
          By choosing any one or more of these remedies, we do not waive our
          right to later use another remedy. By deciding not to use any remedy,
          we do not give up our right to consider the event a default if it
          happens again. You agree that, subject to your right to recover such
          property, we may take possession of personal property left in or on
          the Vehicle securing this Contract and taken into possession as
          provided above.
          <br />
          <br />
          If the Vehicle has an electronic tracking device, you agree that we
          may use the device to find the Vehicle.
          <br />
          <br />
          <span className="font-bold">Returned Check Charge.</span>
          You may be liable for collection costs incurred in connection with
          this Contract. This includes a fee, not to exceed $15, for each check,
          share draft or negotiable order of withdrawal offered as payment that
          is returned dishonored by a depository institution.
          <br />
          <br />
          <span className="font-bold">Property Insurance.</span>
          You agree to buy property insurance on the Vehicle protecting against
          loss and physical damage and otherwise acceptable to us. You will name
          us as loss payee on any such policy. In the event of loss or damage to
          the Vehicle, we may require additional security or assurances of
          payment before we allow insurance proceeds to be used to repair or
          replace the Vehicle. You agree that if the insurance proceeds do not
          cover the amounts you still owe us, you will pay the difference. You
          may purchase or provide the insurance through any insurance company
          reasonably acceptable to us. You will keep the insurance in full force
          and effect until this Contract is paid in full. If you fail to obtain
          or maintain this insurance, or name us as a loss payee, we may obtain
          insurance to protect our interest in the Vehicle or, at our option,
          your and our interests in the Vehicle. This insurance may include
          coverages not required of you. This insurance may be written by a
          company other than one you would choose. It may be written at a rate
          higher than a rate you could obtain if you purchased the property
          insurance required by this Contract. We will add the premium for this
          insurance to the amount you owe us. Any amount we pay will be due
          immediately. This amount will earn finance charges from the date paid
          at the Annual Percentage Rate stated in the Truth-In-Lending
          Disclosure of this Contract or, at our option, the highest rate the
          law permits.
        </div>
      </div>

      {/* page 3 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  mb-24">
        <div>
          <span className="font-bold">Obligations Independent.</span>
          Each person who signs this Contract agrees to pay this Contract
          according to its terms. This means the following:
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">A.</span>
              You must pay this Contract even if someone else has also signed
              it.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">B.</span>
              We may release any Co-Buyer or Guarantor and you will still be
              obligated to pay this Contract.{" "}
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">C.</span>
              We may release any security and you will still be obligated to pay
              this Contract.{" "}
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">D.</span>
              If we give up any of our rights, it will not affect your duty to
              pay this Contract.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">E.</span>
              If we extend new credit, it will not affect your duty to pay this
              Contract.
            </li>
          </ol>
          <span className="font-bold">Telephone Monitoring and Calling.</span>
          From time to time you agree we may monitor and record telephone calls
          made or received by us or our agents regarding your account to assure
          the quality of our service. In order for us to service the account or
          to collect any amounts you may owe, and subject to applicable law, you
          agree that we may from time to time make calls and send text messages
          to you using prerecorded/artificial voice messages or through the use
          of an automatic dialing device at any telephone number you provide to
          us in connection with your account, including a mobile telephone
          number that could result in charges to you.
          <br />
          <br />
          <span className="font-bold">Information You Provide to Us.</span> We
          have based our decision to enter into the Contract on the information
          you supplied in any credit applications and in this Contract,
          including the payoff amount on any trade-in. We relied on the
          information because you assured us it is accurate. You now repeat that
          assurance, and you warrant and represent as follows:
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              All that information is accurate, complete and not misleading.{" "}
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You believe that information will remain correct and will not
              change. You have no reason to suspect otherwise.
            </li>
            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              Unless you tell us in writing before you sign this Contract, Buyer
              and Co-Buyer will each use and own the Vehicle, even if that is
              not shown on the vehicle title.
            </li>
          </ol>
          You will provide us with documents and other information we request to
          verify that any items of that information are correct.
          <p className="font-bold">
            Our Warranties: UNLESS WE AS SELLER, IN A SALE FOR PERSONAL USE,
            EITHER EXTEND A WRITTEN WARRANTY OR SERVICE CONTRACT WITHIN 90 DAYS
            OF THE DATE OF THIS CONTRACT, OR SELL THE VEHICLE WITH A WRITTEN
            WARRANTY, THERE ARE NO WARRANTIES, EXPRESS OR IMPLIED, CONCERNING
            THE VEHICLE, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY OR
            FITNESS FOR A PARTICULAR PURPOSE.
            <br />
            <br />
            This provision does not affect any warranties of the Vehicle
            provided by the vehicle manufacturer. If the Vehicle was sold as a
            certified used vehicle, the warranty of merchantability is not
            disclaimed.
          </p>
        </div>

        <div className="">
          <h1 className="font-bold mb-8">Claim Procedure</h1>
          <div>
            <p className="font-bold">
              If you have purchased credit disability insurance in connection
              with this Contract, the following claim procedure applies.
            </p>
            If you become disabled, tell us (your creditor) right away. (We
            advise you to send this information to the same address to which you
            are normally required to send your payments, unless a different
            address or telephone number is given to you in writing by us as the
            location where we would like to be notified.) We will tell you where
            to get claim forms. Send in the completed form to the insurance
            company as soon as possible and tell us as soon as you do.
            <br className=""></br>
            <br className=""></br>
            If your disability insurance covers all of your missed payments,
            <span className="font-bold">
              {" "}
              WE CANNOT TRY TO COLLECT WHAT YOU OWE OR FORECLOSE UPON OR
              REPOSSESS ANY COLLATERAL UNTIL THREE CALENDAR MONTHS AFTER
            </span>{" "}
            your first missed payment is due or until the insurance company pays
            or rejects your claim, whichever comes first. We can, however, try
            to collect, foreclose, or repossess if you have money due and owing
            us or are otherwise in default when your disability claim is made or
            if a senior mortgage or lien holder is foreclosing.
            <br />
            <br />
            If the insurance company pays the claim within the three calendar
            months, we must accept the money as though you paid on time. If the
            insurance company rejects the claim within the three calendar months
            or accepts the claim within the three calendar months as a partial
            disability and pays less than for a total disability, you will have
            35 days from the date that the rejection or the acceptance of the
            partial disability claim is sent to pay past due payments, or the
            difference between past due payments and what the insurance company
            pays for the partial disability, plus late charges. You can contact
            us, and we will tell you how much you owe. After that time, we can
            take action to collect or foreclose or repossess any collateral you
            may have given.
            <br />
            <br />
            If the insurance company accepts your claim but requires that you
            send in additional forms to remain eligible for continued payments,
            you should send in these completed additional forms no later than
            required. If you do not send in these forms on time, the insurance
            company may stop paying, and we will then be able to take action to
            collect or foreclose or repossess any collateral you may have given.
          </div>
        </div>
      </div>

      {/* page 4 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  mb-24">
        <div className="">
          <h1 className="font-bold mb-10">Owner Agreement</h1>
          <div>
            In this section only, " " means only the person signing this
            section. By signing below you agree to give us a security interest
            in the Property described in the section. You also agree to the
            terms of this Contract except that you will not be liable for the
            payments it requires. Your Interest in the Property may be used to
            satisfy the Buyer's obligation. You agree that we may renew, extend
            or change this Contract, or release any party or Property without
            releasing you from this Contract. We may take these steps without
            notice or demand upon you.
            <br className=""></br>
            <br className=""></br>
            <p className="font-bold">
              You acknowledge receipt of a completed copy of this Contract.
            </p>
            <div className="border-gray-600 border mt-6 my-4 mr-12 " />
            <p className="flex justify-around gap-12">
              <span>Buyer1</span>
              <span>Date</span>
            </p>
            <div className="border-gray-600 border mt-6 my-4 mr-12 " />
            <p className="flex justify-around gap-12 mb-8">
              <span>Buyer2</span>
              <span>Date</span>
            </p>
            <p className="font-bold">
              Note: If the primary use of the Vehicle is not personal, family or
              household use, then this is not a consumer contract, and the
              following notice does not apply.
              <br />
              <br />
              NOTICE: ANY HOLDER OF THIS CONSUMER CREDIT CONTRACT IS SUBJECT TO
              ALL CLAIMS AND DEFENSES WHICH THE DEBTOR COULD ASSERT AGAINST THE
              SELLER OF GOODS OR SERVICES OBTAINED PURSUANT HERETO OR WITH THE
              PROCEEDS HEREOF. RECOVERY HEREUNDER BY THE DEBTOR SHALL NOT EXCEED
              AMOUNTS PAID BY THE DEBTOR HEREUNDER.
              <br />
              <br />
              Used Car Buyer's Guide
              <br />
              Used Car Buyer's Guide. The information you see on the window form
              for this Vehicle is part of this Contract. Information on the
              window form overrides any contrary provisions in the contract of
              sale.
              <br />
              <br />
              Guía para compradors de vehículos usados
              <br />
              Guía para compradors de vehículos usados. La información que
              aparece en la ventanilla de este vehículo forma parte de este
              contrato. La información contenida en el formulario de la
              ventanilla anula cualquier estipulación que establezca lo
              contrario y que aparezca en el contrato de venta.
            </p>
          </div>
        </div>
        <div className="">
          <h1 className="font-bold mb-10">Arbitration Provision</h1>
          <div className="font-bold">
            Arbitration Provision. PLEASE READ CAREFULLY! By agreeing to this
            arbitration provision you are giving up your right to go to court
            for claims and disputes arising from this Contract:
            <ol className="list-none">
              <li className="relative pl-6">
                <span className="absolute left-0">❖</span>
                EITHER YOU OR WE MAY CHOOSE TO HAVE ANY DISPUTE BETWEEN YOU AND
                US DECIDED BY ARBITRATION, AND NOT BY A COURT OR BY JURY TRIAL.
              </li>

              <li className="relative pl-6">
                <span className="absolute left-0">❖</span>
                YOU GIVE UP ANY RIGHT THAT YOU MAY HAVE TO PARTICIPATE AS A
                CLASS REPRESENTATIVE OR CLASS MEMBER IN ANY CLASS ACTION OR
                CLASS ARBITRATION AGAINST US IF A DISPUTE IS ARBITRATED.
              </li>

              <li className="relative pl-6">
                <span className="absolute left-0">❖</span>
                IN ARBITRATION, DISCOVERY AND RIGHTS TO APPEAL ARE GENERALLY
                MORE LIMITED THAN IN A JUDICIAL PROCEEDING, AND OTHER RIGHTS
                THAT YOU WOULD HAVE IN COURT MAY NOT BE AVAILABLE.
              </li>
            </ol>
          </div>
          <br />
          <br />
          <div className="">
            You or we (including any assignee) may elect to resolve any Claim by
            neutral, binding arbitration and not by a court action. " " means
            any claim, dispute or controversy between you and us or our
            employees, agents, successors, assigns or affiliates arising from or
            relating to:
            <ol className="list-none">
              <li className="relative pl-6">
                <span className="absolute left-0">1.</span>
                the credit application;
              </li>
              <li className="relative pl-6">
                <span className="absolute left-0">2.</span>
                the purchase of the Property;
              </li>
              <li className="relative pl-6">
                <span className="absolute left-0">3.</span>
                the condition of the Property;
              </li>
              <li className="relative pl-6">
                <span className="absolute left-0">4.</span>
                this Contract;
              </li>
              <li className="relative pl-6">
                <span className="absolute left-0">5.</span>
                any insurance, maintenance, service or other contracts you
                purchased in connection with this Contract; or
              </li>{" "}
              <li className="relative pl-6">
                <span className="absolute left-0">6.</span>
                any related transaction, occurrence or relationship.
              </li>
            </ol>
          </div>
          <br />
          This includes any Claim based on common or constitutional law,
          contract, tort, statute, regulation, or other ground. To the extent
          allowed by law, the validity, scope, and interpretation of this
          arbitration provision are to be decided by neutral, binding
          arbitration.
          <br />
          <br />
          If either party elects to resolve a Claim through arbitration, you and
          we agree that no trial by jury or other judicial proceeding will take
          place. Rather, the Claim will be arbitrated on an individual basis,
          and not on a class or representative basis.
          <br />
          <br />
          The party electing arbitration may choose any of the following
          arbitration organizations and its applicable rules, provided it is
          willing and able to handle the arbitration: American Arbitration
          Association, 1633 Broadway, Floor 10, New York, NY 10019
        </div>
      </div>

      {/* page 5 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  mb-24">
        <div>
          (www.adr.org); JAMS, 1920 Main Street, Suite 300, Irvine CA 92614
          ("www.jamsadr.com); or National Arbitration and Mediation (NAM), 990
          Stewart Ave., Garden City, NY 11530 (www.namadr.com). You may get a
          copy of the applicable rules of these organizations by contacting them
          or visiting their websites. If the chosen arbitration organization's
          rules conflict with this arbitration provision, then the terms of this
          arbitration provision will govern the Claim. However, to address a
          conflict with the selected arbitration organization's rules, the
          parties may agree to change the terms of this arbitration provision by
          written amendment signed by the parties. If none of these arbitration
          organizations is willing or able to handle the arbitration, the
          arbitrator can be selected pursuant to 9 U.S.C. Sections 5 and 6.
          <br />
          <br />
          The arbitration hearing will be carried out in the federal district
          where you reside, unless you and we otherwise agree. Or, if you and we
          consent, the arbitration hearing can be by telephone. The arbitration
          filing fee, arbitrator's compensation and other arbitration costs will
          be paid in the amounts and by the parties according to the rules of
          the chosen arbitration organization. Some arbitration organizations'
          rules require us to pay most or all of these amounts. If the rules of
          the chosen arbitration organization do not specify how fees must be
          allocated, we will pay the filing fee, arbitrator's compensation, and
          other arbitration costs up to $5,000, unless the law requires us to
          pay more. Each party is responsible for the fees of its own attorneys,
          witnesses, and any related costs, if any, that it incurs to prepare
          and present its Claim or response. In limited circumstances, the
          arbitrator may have the authority to award payment of certain
          arbitration costs or fees to a party, but only if the law and
          arbitration organization rules allow it.
          <br />
          <br />
          An arbitrator must be a lawyer with at least ten (10) years experience
          and familiar with consumer credit law or a retired state or federal
          court judge. Except as provided below, the arbitration will be by a
          single arbitrator. In making an award, an arbitrator shall follow
          governing substantive law and any applicable statute of limitations.
          The arbitrator will decide any dispute regarding the arbitrability of
          a Claim. An arbitrator has the authority to order specific
          performance, compensatory damages, punitive damages, and any other
          relief allowed by applicable law. An arbitrator's authority to make
          awards is limited to awards to you or us alone. Furthermore, Claims
          brought by you against us, or by us against you, may not be joined or
          consolidated in arbitration with claims brought by or against someone
          other than you, unless agreed to in writing by all parties. No
          arbitration award or decision will have any preclusive effect as to
          issues or claims in any dispute with anyone who is not a named party
          to the arbitration.
          <br />
          <br />
          Any arbitration award shall be in writing, shall include a written
          reasoned opinion, and will be final and binding subject only to any
          right to appeal under the Federal Arbitration Act ("FAA"), 9 U.S.C.
          Sections 1, et seq. Any court having jurisdiction can enforce a final
          arbitration award.
        </div>
        <div className="">
          You or we can do the following without giving up the right to require
          arbitration:
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              Seek remedies in small claims court for Claims within the small
              claims court's jurisdiction, or
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              Seek judicial provisional remedies.
            </li>
          </ol>
          If a party does not exercise the right to elect arbitration in
          connection with any particular Claim, that party still can require
          arbitration in connection with any other Claim.
          <br />
          <br />
          This arbitration provision survives any (i) termination, payoff,
          assignment or transfer of this Contract, (ii) any legal proceeding by
          you or us to collect a debt owed by the other, and (iii) any
          bankruptcy proceeding in which you or we are the debtor.
          <br />
          <br />
          With but one exception, if any part of this arbitration provision is
          deemed or found to be unenforceable for any reason, the remainder of
          this arbitration provision will remain in full force and effect. The
          one exception is that, if a finding of partial unenforceability would
          allow arbitration to proceed on a class-wide basis then this
          arbitration provision will be unenforceable in its entirety.
          <br />
          <br />
          You and we expressly agree that this arbitration provision is governed
          by the FAA to the exclusion of any different or inconsistent state or
          local law.
          <br />
          <br />
          <p className="font-bold mb-4">
            By signing this Contract you are agreeing to the terms of this
            arbitration provision, unless you reject it as provided in the next
            paragraph.
            <br />
            <br />
            Caution: It is important that you read this arbitration provision
            thoroughly before you sign this Contract. By signing this Contract,
            you are acknowledging that you have read and understand this
            arbitration provision. If you do not understand something in this
            arbitration provision, do not sign this Contract; instead ask your
            lawyer. You can reject this arbitration provision by checking the
            box in the section of this Contract before you sign this Contract.
            If you do so, this arbitration provision will not be a part of this
            Contract, but all the rest of this Contract will continue to be
            binding and effective.
          </p>
          <span className="font-bold text-lg">ASSIGNMENT:</span> This Contract
          and Security Agreement is assigned to:
          <span className="border-gray-500 border-b px-12 sm:px-32" />
          <br />
          <span className="border-gray-500 border-b px-8 sm:px-16" />, The
          assignee, Phone
          <span className="border-gray-500 border-b px-8 sm:px-16" />. This
          assignment is made under the terms of a separate agreement made
          between the Seller and Assignee.
          <span className="border-gray-500 border-b px-4 sm:px-8" />
          This Assignment is made with recourse. Seller (CHECK BOX)
          <br />
          <br />
          <p className="flex gap-4">
            <p>
              <span className="border-gray-500 border-b px-8 sm:px-16" />
              <br />
              <span>By</span>
            </p>

            <p>
              <span className="border-gray-500 border-b px-8 sm:px-16" />
              <br />
              <span>Date</span>
            </p>
          </p>
        </div>
      </div>

      {/* page 6 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6  mb-24">
        <div className="">
          <h1 className="font-bold mb-10">Right of Seller to Cancel</h1>
          If you sign the Right of Seller to Cancel in the section of this
          Contract, you agree to the following:
          <ol className="list-none">
            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You acknowledge that it may take a few days for the Seller to
              verify your credit and assign the Contract to a financial
              institution.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              If the Seller is unable to get a financial institution it
              regularly does business with to accept an assignment of the
              Contract on terms that are satisfactory to the Seller, the Seller
              may elect to cancel (rescind) the Contract by giving you a notice
              of cancellation within 10 days after the date you signed this
              Contract.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              Upon receipt of a notice of the cancellation within 10 days after
              you signed this Contract, you agree to immediately return the
              Vehicle to the Seller free of all liens apart from this Contract
              in the same condition as it was in when you received it, except
              for reasonable wear and tear.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              After you return the Vehicle, the Seller agrees to return to you
              any trade-in vehicle and all amounts you paid to the Seller under
              this Contract.{" "}
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You agree that, if you do not immediately return the Vehicle to
              the Seller, the Seller will be entitled to recover all amounts you
              owe the Seller under this Contract to the extent permitted by law.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You also agree that, if you do not immediately return the Vehicle
              to the Seller, the Seller will be entitled to repossess the
              Vehicle, and you will pay the Seller all its expenses (including
              attorneys' fees) incurred in repossessing the Vehicle, to the
              extent permitted by law.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You agree that, until the Seller receives the Vehicle back from
              you, you will continue to have all the Buyer's obligations under
              this Contract, including, but not limited to, the provisions
              regarding insurance and use of the Vehicle; you will have all the
              risk of loss or damage to the Vehicle; and you will be responsible
              for any injuries or property damage caused by use of the Vehicle.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You agree that if the Vehicle is damaged before it is returned to
              the Seller you will pay the Seller the cost of repairing it.
            </li>

            <li className="relative pl-6">
              <span className="absolute left-0">❖</span>
              You agree that the provisions of this Right of Seller to Cancel
              will continue to be effective even after this contract is
              cancelled.
            </li>
          </ol>
          <br />
          <br />
          <h1 className="font-bold mb-10">Waiver of Confidentiality</h1>
          California Vehicle Code Section 1808.21 provides, among other things,
          that your resident address in any record of the California Department
          of Motor Vehicles is confidential.
          <br />
          <br />
          You waive this provision and authorize the California Department of
          Motor Vehicles to furnish your residence address to us.
          <br />
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1
              <span className="border-gray-500 border-b px-8 sm:px-14" />
            </p>

            <p>
              {" "}
              Buyer2
              <span className="border-gray-500 border-b px-8 sm:px-14"></span>
            </p>
          </p>
        </div>
        <div>
          <p className="font-bold text-center">
            Notice To Buyer.
            <br />
            (1) Do not sign this agreement before you read it or if it contains
            any blank spaces to be filled in. (2) You are entitled to a
            completely filled-in copy of this agreement. (3) You can prepay the
            full amount due under this agreement at any time. (4) If you default
            in the performance of your obligations under this agreement, the
            vehicle may be repossessed and you may be subject to suit and
            liability for the unpaid indebtedness evidenced by this agreement.
          </p>
          <p className="text-center">
            <span className="border-gray-500 border-b px-6 sm:px-12" />
            If this box is checked, you will owe no Finance Charge if you pay
            the Amount Financed on
            <br />
            or before{" "}
            <span className="border-gray-500 border-b px-6 sm:px-10" />
            (DATE)
            <p className="text-left flex   gap-6 flex-wrap ">
              <p>
                Buyer1
                <span className="border-gray-500 border-b px-8 sm:px-14" />
              </p>

              <p>
                {" "}
                Buyer2
                <span className="border-gray-500 border-b px-8 sm:px-14"></span>
              </p>
            </p>
          </p>
          <br />
          <span className="font-bold"> Arbitration.</span> This Contract
          contains an Arbitration Provision that affects your rights.
          <br />
          <br />
          <p className="font-bold">
            The Annual Percentage Rate may be negotiable with the Seller. The
            Seller may assign this Contract and retain its right to receive a
            part of the Finance Charge.
          </p>
          <br />
          <br />
          <h1 className="mb-2 font-bold">Signatures</h1>
          Changes to this Contract must be in writing and signed by both you and
          our authorized representative. Oral changes are not binding.
          <p className="text-left flex my-4   gap-6 flex-wrap ">
            <p>
              Buyer1
              <span className="border-gray-500 border-b px-8 sm:px-14" />
            </p>

            <p>
              {" "}
              Buyer2
              <span className="border-gray-500 border-b px-8 sm:px-14"></span>
            </p>
          </p>
          <span className="font-bold"> Right of Seller to Cancel.</span> By
          signing below, you agree that the section of this Contract will apply.
          This provision gives us the right to cancel this Contract if we are
          unable to assign the Contract.
          <p className="text-left flex  my-4 gap-6 flex-wrap ">
            <p>
              Buyer1
              <span className="border-gray-500 border-b px-8 sm:px-14" />
            </p>

            <p>
              {" "}
              Buyer2
              <span className="border-gray-500 border-b px-8 sm:px-14"></span>
            </p>
          </p>
          <p className="font-bold">
            You agree to the terms of this Contract. By signing below, you
            become legally obligated to perform the terms of this Contract and
            acknowledge that (1) before you signed it, we gave you the
            completely filled-in Contract and you had a chance to take it and
            thoroughly read and review it, and (2) you received a completely
            filled-in copy of the Contract.
            <div className="mt-6">
              <p className="text-right">
                Date
                <span className="border-gray-500 border-b px-8 sm:px-14" />
              </p>

              <p className="text-right flex  my-4 gap-6 flex-wrap justify-between ">
                <p>
                  Buyer1
                  <span className="border-gray-500 border-b px-8 sm:px-14" />
                </p>

                <p className="text-right">
                  {" "}
                  Buyer2
                  <span className="border-gray-500 border-b px-8 sm:px-14"></span>
                </p>
              </p>
              <p>
                Seller
                <span className="border-gray-500 border-b px-16 sm:px-28" />
              </p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
