import { ShipResponseDataNewPrivate, ShippingResponseProd } from "services/endpoints/shipping-quotes/shipping.type";

export interface FormatedShippingResponseType  {
    QuoteID: string,
    Price: number,
    ServiceCharge: number,
    TotalPrice: number
}
export const FormateShippingResponse = (data:ShippingResponseProd) : FormatedShippingResponseType =>{
    const { items } = data;
    let minPricedQuotes = items[0];
    for(let i = 1;i < items.length;i++){
        const currentPrice = items[i].totalPrice.value;
        if(currentPrice < minPricedQuotes.totalPrice.value  ){
            minPricedQuotes= items[i]
        }
    }
    const total = minPricedQuotes.totalPrice.value;
    const service = minPricedQuotes.ushipServiceFee.value;
    return {
        QuoteID: minPricedQuotes.id,
        Price: total - service,
        ServiceCharge: service,
        TotalPrice:total

    }
}

export const FormateShippingResponseNew = (data:ShipResponseDataNewPrivate): FormatedShippingResponseType  =>{
    const { rates } = data.items[0];
    let minPricedQuotes = rates[0];
    for(let i = 1;i < rates.length;i++){
        const currentPrice = rates[i].amountDue.value;
        if(currentPrice < minPricedQuotes.amountDue.value  ){
            minPricedQuotes= rates[i]
        }
    }
    
    
    return {
        QuoteID: getqidFromUrl(minPricedQuotes.checkoutUrl,'qid'),
        Price: minPricedQuotes.items.find(data => data.reason.value === "AmountToCarrier")?.price?.value || 0,
        ServiceCharge: minPricedQuotes.items.find(data => data.reason.value === "ServiceCharge")?.price?.value || 0,
        TotalPrice: minPricedQuotes.amountDue.value

    }
}
const getqidFromUrl = (urlString: string,id:string):string => {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);    
    
    return params.get(id)||"N/A";
}