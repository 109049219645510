import { Navigate, Outlet } from "react-router-dom";
import { localStorageFn } from "utils/localstorage";
import { safeDecode } from "utils/jwt";

export default function PublicRoutes({AllowedROLES}:{AllowedROLES:string[]}) {
  const userToken:any = String(localStorageFn.getItem('access_token'))
  const userData:any=safeDecode(userToken)
return AllowedROLES?.includes(userData?.role) ? <Navigate to={userData?.role==='Dealer'?"/dealer/dashboard":"/user/dashboard"} />:<Outlet /> ;
}


