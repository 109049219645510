import * as React from "react";
import { Card, CardBody, Divider } from "@nextui-org/react";

import { Typography } from "components";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { UseFormSetValue } from "react-hook-form";
import { IManageListingValidationSchema } from "./manage-listing.validation";
import { ListingDetailsResponse } from "services";

interface ProfitLossCalculatorProps {
  setValue: UseFormSetValue<IManageListingValidationSchema>;
  listingDetailsData?: ListingDetailsResponse;
}

export const ProfitLossCalculator: React.FC<ProfitLossCalculatorProps> = ({
  setValue,
  listingDetailsData,
}) => {
  console.log(
    "listingDetailsData?.listing?.repairCost",
    listingDetailsData?.listing?.repairCost
  );
  const [priceData, setPriceData] = React.useState<{
    date?: any;
    price?: any;
    calculated?: any;
    vehicle?: string;
  }>({
    calculated: {
      left: {
        reconditioning_cost: "",
        repair_cost: "",
        purchase_price: "",
        total_vehicle_expenses: "",
      },
      right: {
        // less_total_vehicle_expenses: "",
        actual_sale_price: "",
        total_profit_loss_vehicle: "",
      },
    },
    vehicle: "",
  });

  const handleChangeProfiteLossCalculatorLeft = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPriceData((prev) => {
      return {
        ...prev,
        calculated: {
          ...prev.calculated,
          left: {
            ...prev.calculated.left,
            [`${e.target.name}`]: e.target.value,
          },
        },
      };
    });
    setPriceData((prev) => {
      return {
        ...prev,
        calculated: {
          ...prev.calculated,
          left: {
            ...prev.calculated.left,
            total_vehicle_expenses:
              Number(prev.calculated.left.reconditioning_cost) +
              Number(prev.calculated.left.repair_cost) +
              Number(prev.calculated.left.purchase_price),
          },
        },
      };
    });
  };

  const handleChangeProfiteLossCalculatorRight = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPriceData((prev) => {
      return {
        ...prev,
        calculated: {
          ...prev.calculated,
          right: {
            ...prev.calculated.right,
            [`${e.target.name}`]: e.target.value,
          },
        },
      };
    });
    setPriceData((prev) => {
      return {
        ...prev,
        calculated: {
          ...prev.calculated,
          right: {
            ...prev.calculated.right,
            total_profit_loss_vehicle:
              Number(prev.calculated.right.actual_sale_price) -
              Number(prev.calculated.left.total_vehicle_expenses),
          },
        },
      };
    });
  };

  React.useEffect(() => {
    if (priceData?.calculated?.left?.purchase_price) {
      setValue(
        "purchasePrice",
        +priceData?.calculated?.left?.purchase_price || 0
      );
    }
    if (priceData?.calculated?.left?.reconditioning_cost) {
      setValue(
        "reconditioningCost",
        +priceData?.calculated?.left?.reconditioning_cost || 0
      );
    }
    if (priceData?.calculated?.left?.repair_cost) {
      setValue("repairCost", +priceData?.calculated?.left?.repair_cost || 0);
    }
    if (priceData?.calculated?.right?.actual_sale_price) {
      setValue(
        "actualSalePrice",
        +priceData?.calculated?.right?.actual_sale_price || 0
      );
    }
  }, [
    priceData?.calculated?.left?.purchase_price,
    priceData?.calculated?.left?.reconditioning_cost,
    priceData?.calculated?.left?.repair_cost,
    priceData?.calculated?.right?.actual_sale_price,
  ]);

  React.useEffect(() => {
    if (listingDetailsData?.listing) {
      const { repairCost, purchasePrice, reconditioningCost, actualSalePrice } =
        listingDetailsData.listing;

      setPriceData({
        calculated: {
          left: {
            reconditioning_cost: reconditioningCost ? reconditioningCost : "0",
            repair_cost: repairCost ? repairCost : "0",
            purchase_price: purchasePrice ? purchasePrice : "0",
            total_vehicle_expenses:
              (reconditioningCost || 0) +
              (repairCost || 0) +
              (purchasePrice || 0),
          },
          right: {
            // less_total_vehicle_expenses: "",
            actual_sale_price: actualSalePrice ? actualSalePrice : 0,
            total_profit_loss_vehicle:
              (actualSalePrice || 0) -
              (reconditioningCost || 0) -
              (repairCost || 0) -
              (purchasePrice || 0),
          },
        },
        vehicle: "",
      });
    }
  }, [listingDetailsData?.listing]);
  return (
    <Card className="mt-10 bg-[#F1F1F180]" fullWidth isPressable={false}>
      <CardBody className="flex justify-center items-center mt-2 px-10 md:px-20">
        <Typography
          variant="titleWithGradient"
          className="px-6 py-3 text-lg xl:text-2xl font-[600] text-center"
        >
          Profit And Loss Calculator
        </Typography>
        <Form
          id="profit-loss-calculator-form"
          schema={{} as any}
          onSubmit={() => {}}
          className=""
        >
          {({ formState: { errors, defaultValues }, register }) => (
            <div className="grid grid-cols-1 md:grid-cols-5 py-10 h-full">
              <div className="col-span-2 ml-auto">
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Purchase Price
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    defaultValue="0"
                    name="purchase_price"
                    value={priceData?.calculated?.left["purchase_price"]}
                    onChange={handleChangeProfiteLossCalculatorLeft}
                  />
                </FormControl>
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Repair Cost
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    defaultValue="0"
                    name="repair_cost"
                    value={priceData?.calculated?.left["repair_cost"]}
                    onChange={handleChangeProfiteLossCalculatorLeft}
                  />
                </FormControl>
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Reconditioning Cost
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    defaultValue="0"
                    name="reconditioning_cost"
                    value={priceData?.calculated?.left["reconditioning_cost"]}
                    onChange={handleChangeProfiteLossCalculatorLeft}
                  />
                </FormControl>
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Total Vehicle Expenses
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    isDisabled={true}
                    defaultValue="0"
                    name="total_vehicle_expenses"
                    value={priceData.calculated.left.total_vehicle_expenses}
                  />
                </FormControl>
              </div>
              <div className="col-span-1 m-auto">
                <Divider orientation="vertical" />
              </div>
              <div className="col-span-2">
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Actual Sale Price
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    defaultValue="0"
                    name={"actual_sale_price"}
                    value={priceData?.calculated?.right["actual_sale_price"]}
                    onChange={handleChangeProfiteLossCalculatorRight}
                  />
                </FormControl>
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Less Total Vehicle Expenses
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    isDisabled={true}
                    defaultValue="0"
                    name={"total_vehicle_expenses"}
                    value={
                      priceData?.calculated?.left["total_vehicle_expenses"]
                    }
                    // onChange={handleChangeProfiteLossCalculatorRight}
                  />
                </FormControl>
                {/* <FormControl>
                  <div></div>
                  <div></div>
                </FormControl> */}
                <FormControl>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-2 text-left"
                  >
                    Total Vehicle{" "}
                    {priceData.calculated.right.total_profit_loss_vehicle >= 0
                      ? "Profit"
                      : "Loss"}
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-2"
                    isDisabled={true}
                    classNames={{
                      input: `px-4 text-center ${
                        priceData.calculated.right.total_profit_loss_vehicle >=
                        0
                          ? "text-blue-500"
                          : "text-red-500"
                      }`,
                    }}
                    defaultValue="0"
                    value={priceData.calculated.right.total_profit_loss_vehicle}
                  />
                </FormControl>
              </div>
            </div>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4 justify-items-stretch mb-5 align-baseline items-center">
      {children}
    </div>
  );
};
