import React, { useState } from "react";
import LoginCard from "./Login";
import CreditCard from "./CreditCard";
import TierDetails from "./TierDetails";
import { PaymentModal } from "pages/shared";
import AddPaymentForm from "modules/dealer/features/shipping-quotes/AddPaymentForm";

const DealerMembership = () => {
  const [addCard, setAddCard] = useState(false);
  return (
    <>
      <div className="flex">
        <LoginCard />
        <CreditCard handleAddCard={()=>setAddCard(true)} />
      </div>
      <div>
        <TierDetails />
      </div>
      <PaymentModal
        isOpen={addCard}
        onOpen={() => {
          setAddCard(false)
        }}
        Component={
          <><AddPaymentForm
            showmodal={true} 
            /></>
          
        }
      />
    </>
  );
};

export default DealerMembership;
