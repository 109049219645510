import {
  ContactUsHeader,
  ContactUsAccordions,
  ContactUsCards,
  ContactUsFooter,
} from "./components";

function ContactUs() {
  return (
    <div className="flex flex-col gap-24 py-12">
      <ContactUsHeader />
      <ContactUsCards />
      <ContactUsAccordions />
      <ContactUsFooter />
    </div>
  );
}

export default ContactUs;
