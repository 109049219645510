import ReactDOM from 'react-dom';
import cometdLib from "cometd";

/* Parameters Explanation
    refElement - 
        refs used in React to represent an element to which the custom event is binded
    protocol - 
        protocol to connect to Salesforce - most probably https:
    hostname - 
        hostname to connect to Salesforce - Most probably the instanceURL of Salesforce
    sessionId - 
        sessionId to authenticate from Salesforce
*/

export const configureCometD = (refElement, protocol, hostname, sessionId) => {
  let cometd = new cometdLib.CometD();

  /* List of components to update once platform event is triggered */

  let listOfComponentsToUpdate = ['myComponent1'];
  
  /* Configuration of cometD library */

  cometd.configure({
      url: protocol+'//'+hostname+'/cometd/40.0/',
      requestHeaders: { Authorization: 'OAuth '+sessionId},
      logLevel: 'debug',
      appendMessageTypeToURL : false
  });
  cometd.websocketEnabled = false;

  /* Try for a handshake to connect to Salesforce through cometD library */

  cometd.handshake(function(handshakeReply) {
    console.log("test --> calling fn handshake")
    if (handshakeReply.successful) { /* Successful handshake */
      /* Connection Successful - Subscribe to platform event */
        console.log("handshake success")
      cometd.subscribe('/event/Notification_Event__e',
        function(platformEvent) {
          /* Platform event received */
          if(platformEvent && platformEvent.data.payload) {

            /* Create a customEvent */

            let customEvent = new CustomEvent("updateComponent",  {
              detail: {
                        componentsToUpdate : listOfComponentsToUpdate,
                        data: {
                          /* data to be used from platform events received, 
                             can be used for manipulating the component or any required funtion */
                        }
                      },
              bubbles: true
            });

            /* Dispatch the CustomEvent to the html element from where it will be triggered */

            ReactDOM.findDOMNode(refElement).dispatchEvent(customEvent);

          }
        }
      );
    }
    else
      console.error('Failed to connected to CometD.');
  });
}