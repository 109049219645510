import { Outlet } from "react-router-dom";

import { PublicFooter, Navbar } from "components";
import PublicMobileSidebar from "./public-mobile-sidebar";

export const PublicLayout = () => {
  return (
    <main className="min-h-dvh flex flex-col font-[Metropolis-SemiBold]">
      <Navbar />
      <PublicMobileSidebar />
      <div className="w-full px-4 pt-4 sm:px-6 md:px-8 lg:px-[60px] xl:px-[150px] 2xl:px-[250px]">
        <Outlet />
      </div>
      <PublicFooter />
    </main>
  );
};
