import * as React from "react";
import { InputProps } from "@nextui-org/react";

import Input from "components/ui/input";
import { SearchIcon } from "assets/svgs/icons/search-icon";

interface SearchFieldProps extends InputProps {
  placeholderText?: string
}
export const SearchField = React.forwardRef<
  HTMLInputElement,
  SearchFieldProps
>((props, ref) => {
  return (
    <Input
      type="text"
      placeholder={props?.placeholderText|| "Search"}
      startContent={
        <span className="absolute left-3">
          <SearchIcon />
        </span>
      }
      {...props}
      classNames={{
        input: "data-[has-start-content=true]:ps-16 p-5",
        ...props.classNames,
      }}
      ref={ref}
    />
  );
});
