import axios from "axios";
import { http } from "../../http/service.http";
import {
  ListingDetailsPriceAndAdvanceDataResponse,
  ListingDetailsResponse,
  ListingDataRequest,
  HistoryDataResponse,
  PriceDataResponse,
  ListingDescriptionResponse,
  CarPartResponse,
  ListingAdvanceData,
  ListingListResponse,
  ListingDescriptionRequest,
  ListingSellerDetailsResponse,
  ListingZipCodeDetailsResponse,
  ListingVehicleResponse,
  ListingVehicleRequest,
  VehicleCondition,
  ListingDetails,
  SFInstantQuoteLead,
  SaveUserListing,
  ISubmitResearchVehicleFormPayload,
  IGetDashboardData,
} from "./listings.type";
import { localStorageFn } from "utils/localstorage";
import { throwNotErrorSuccessResponse } from "config/retryConfig";
import { env } from "config/env";

class ListingsEndpoint {

  public async getListingDetailsAsync(
    id: number
  ): Promise<ListingDetailsResponse> {
    const res = await http.get(`Listings/GetListingDetails?listingId=${id}`);
    return res.data;
  }

  public async getMyGarageAsync(userId: number): Promise<ListingDetails[]> {
    const res = await http.get(`Listings/MyGarage?userId=${userId}`);
    return res.data;
  }

  public async getPurchasedReportsAsync(
    userId: number,
    subscriptionKey: string
  ): Promise<any[]> {
    const res = await http.get(
      `Listings/MyPurchasedReports?userId=${userId}&subscriptionKey=${subscriptionKey}`
    );
    return res.data;
  }

  public async getListingDetailsImageAsync(fileName: string): Promise<string> {
    const res = await http.get(`Listings/GetListingImage?fileName=${fileName}`);
    return res.data;
  }

  public async getListingDetailsPriceAndAdvanceDataAsync(
    vinNumber: string
  ): Promise<ListingDetailsPriceAndAdvanceDataResponse> {
    const res = await http.get(
      `Listings/GetPriceAdvanceData?vinNumber=${vinNumber}`
    );
    return res.data;
  }

  public async initiateListingAsync(
    vinNumber: string,
    userId?: number,
    mileage?: string
  ): Promise<number> {
    const res = await http.post(
      `Listings/InitiateListing?VIN=${vinNumber}&UserId=${userId}&mileage=${mileage}`
    );
    throwNotErrorSuccessResponse(res, "failed to intiate listing.")
    return res.data;
  }

  public async updateListingDataAsync(payload: ListingDataRequest,hasPositionalImages = false,replaceImages= false) {
    try {
     
    const res = await http.put(
      `Listings/UpdateListingData?hasPositionalImages=${hasPositionalImages}&replaceImages=${replaceImages}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 60000
      }
    );
    if(res.status !== 200){
      throw new Error( res?.data?.title || res?.data || "Fail to updated the listing! Please try Again") 
    }
    return res.data;
  } catch (e) {
    throw e;
  }
  }

  public async UpdateListingStatusAsync(listingId : number,status : number) {
    const res = await http.put(
      `Listings/UpdateListingStatus?listingId=${listingId}&status=${status}`
    );
    return res.data;
  }

  public async saveListingDataAsync(payload: ListingDataRequest) {
    const res = await http.post(
      "Listings/SaveListingData?hasPositionalImages=false",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  }
  public async createVehicleCondition(payload: VehicleCondition) {
    const res = await http.post("Listings/CreateVehicleCondition", payload, {
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
    });
    return res.data;
  }
  public async createSFInstantQuoteLeadAsync(payload: SFInstantQuoteLead) {
    const res = await http.post("Listings/SFInstantQuoteLead", payload, {
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
    });
    return res.data;
  }

  public async getPriceAdvanceDataPdfAsync(vin: string): Promise<string> {
    const res = await http.get(
      `Listings/GetPriceAdvanceDataPdf?vinNumber=${vin}`,
      {},
      {
        responseType: "arraybuffer",
      }
    );
    
    return res.data;
  }

  public async getVehicleHistoryDataAsync(
    vin: string
  ): Promise<HistoryDataResponse> {
    let res;
    try {
      res = await http.get(`Listings/GetHistoryData?vinNumber=${vin}`);
      return res?.data;
    } catch (e) {
      throw e;
    }
  }

  public async getPriceDataAsync(
    period: number,
    vin: string,
    mileage: string
  ): Promise<PriceDataResponse> {
    const res = await http.get(
      `Listings/GetPriceData?vinNumber=${vin}&mileage=${mileage}&period=${period}`,
      {},
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async getAdvanceDataAsync(vin: string): Promise<ListingAdvanceData> {
    const res = await http.get(
      `Listings/GetPriceAdvanceData?vinNumber=${vin}`,
      {},
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async getListingDescriptionAsync(
    payload: ListingDescriptionRequest
  ): Promise<ListingDescriptionResponse> {
    const { carModel, year, make, model, trim } = payload;

    const res = await axios.post(
      `${env.AI_URL}get-listing-description?car=${carModel}&year=${year}&make=${make}&model=${model}&trim=${trim}`
    );

    return res.data;
  }

  public async getVehicleDescriptionAsync(
    payload: ListingDescriptionRequest
  ): Promise<ListingDescriptionResponse> {
    try{
      const { year, make, model, trim } = payload;

      const res = await axios.post(
        `${env.AI_URL}get-vehicle-description?year=${year}&make=${make}&model=${model}&trim=${trim}`
      );
      throwNotErrorSuccessResponse(res,res.data || "Failed to fetch the details! Please try again.")
      return res.data;
    } catch(e){
      throw e
    }
  }

  public async uploadFilesAsync(formData: FormData) {
    const res = await http.post(
      "Listings/UploadFiles",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "accept": "*/*",
        },
      }
    );
    return res.data;
  }

  public async uploadCoverFiles(listId:string,fileName: string) {
    try{
    const res = await http.post(
      `Listings/SetImageAsPrimary?listingId=${listId}&fileName=${fileName}`,{}
    );
    if(res.status !== 200){
      throw new Error( res?.data || "Could not upload Cover photo! Please try again")
    }
    return res.data;
  }catch(e){
    throw e;
  }
}
  public async getUserSavedListingsAsync(): Promise<any> {
    const res = await http.get(`Listings/GetUserSavedListings`);
    return res.data;
  }

  public async removeUserListingAsync(payload: SaveUserListing) {
    const res = await http.post("Listings/RemoveUserListing", payload, {});
    return res.data;
  }

  public async saveUserListingAsync(payload: SaveUserListing) {
    const res = await http.post("Listings/SaveUserListing", payload, {});
    return res.data;
  }

  public async getCarPartUploadedAsync(
    listId: string
  ): Promise<CarPartResponse[]> {

    const res = await axios.post(

      `${env.AI_URL}get-car-part`,
      {
        listingId: listId,
      }
    );

    return res.data;
  }

  public async getListingSearchAsync(search: string) {
    const res = await http.get(`Listings/Search?query=${search}`);
    return res.data;
  }
  public async getListingArchiveAndSoldSync(
    tenantId?: number,
    pageSize?: number,
    page?: number,
    status?: number
  ) {
    const res = await http.get(
      `Listings/GetListingArchiveAndSold?tenantId=${tenantId}&pageSize=${pageSize}&page=${page}&status=${status}`
    );
    return res.data;
  }
  public async getListingArchiveAndSoldSearchAsync(
    search: string,
    status: number
  ) {
    const res = await http.get(
      `Listings/SearchListingArchiveAndSold?query=${search}&status=${status}`
    );
    return res.data;
  }

  public async getListingListAsync(
    tenantId: number,
    status: number,
    page: number,
    pageSize: number
  ): Promise<ListingListResponse[]> {
    const res = await http.get(
      `Listings/GetList?tenantId=${tenantId}&status=${status}&page=${page}&pagesize=${pageSize}`
    );
    return res.data;
  }

  public async GetDashboardData(
    month:string
  ): Promise<IGetDashboardData> {
    const year = new Date().getFullYear()
    const res = await http.get(
      `Listings/GetDashboardData?month=${month}&year=${year}`
    );
    return res.data;
  }
  public async searchListingListAsync(
    query: string
  ): Promise<ListingListResponse[]> {
    const res = await http.get(`Listings/Search?query=${query}`);
    return res.data;
  }

  public async getSellerDetailsByListingIdAsync(
    listingId: number
  ): Promise<ListingSellerDetailsResponse> {
    const res = await http.post(
      `Listings/SellerDetailsByListingId?listingId=${listingId}`
    );
    return res.data;
  }

  public async getZipCodeDetailsAsync(
    zipcode: number
  ): Promise<ListingZipCodeDetailsResponse> {
    const res = await http.post(
      `Listings/GetZipCodeDetails?zipcode=${zipcode}`
    );
    return res.data;
  }

  public async postSubmitResearchVehicleForm(
    payload: ISubmitResearchVehicleFormPayload
  ): Promise<string> {
    const res = await http.post(
      `Listings/SubmitResearchVehicleForm`,payload,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
      }
    );
     throwNotErrorSuccessResponse(res,"failded to submit the research data!")
    return res.data;
  }

  
  public async getResearchVehicleForms(
  ): Promise<ISubmitResearchVehicleFormPayload[]> {
    const res = await http.get(`Listings/GetResearchVehicleForms`);
    return res.data;
  }

  public async putDeleteResearchListing(
    researchId: number
  ): Promise<any> {
    const res = await http.put(`Listings/DeleteResearchListing?researchId=${researchId}`);
    return res.data;
  }

  public async getListingsVehiclesAsync(
    payload: ListingVehicleRequest[],
    page: number,
    pageSize: number
  ): Promise<ListingVehicleResponse[]> {
    const latlong: any = localStorageFn.getItem("latlong");
    const res = await http.post(
      `Listings/VehicleSearch?page=${page}&pagesize=${pageSize}&userLatitude=${
        latlong?.lat || 0
      }&userLongitude=${latlong?.long || 0}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async getListingOfTheDay() {
    const res = await http.post(`Listings/GetListingOfTheDay`);
    throwNotErrorSuccessResponse(res,"No listing of day found!")
    return res.data;
  }
}

export const listingsEndpoint = new ListingsEndpoint();
