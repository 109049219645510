import { SVGProps } from "react";

export const CarIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M24.0967 7.65C23.8417 6.885 23.0767 6.375 22.3117 6.375H8.28672C7.39422 6.375 6.75672 6.885 6.50172 7.65L3.82422 15.3V25.5C3.82422 26.1375 4.46172 26.775 5.09922 26.775H6.37422C7.13922 26.775 7.64922 26.1375 7.64922 25.5V24.225H22.9492V25.5C22.9492 26.1375 23.5867 26.775 24.2242 26.775H25.4992C26.1367 26.775 26.7742 26.1375 26.7742 25.5V15.3L24.0967 7.65ZM8.66922 8.925H21.8017L23.2042 12.75H7.39422L8.66922 8.925ZM24.2242 21.675H6.37422V15.3H24.2242V21.675ZM9.56172 16.575C10.5817 16.575 11.4742 17.4675 11.4742 18.4875C11.4742 19.5075 10.5817 20.4 9.56172 20.4C8.54172 20.4 7.64922 19.5075 7.64922 18.4875C7.64922 17.4675 8.54172 16.575 9.56172 16.575ZM21.0367 16.575C22.0567 16.575 22.9492 17.4675 22.9492 18.4875C22.9492 19.5075 22.0567 20.4 21.0367 20.4C20.0167 20.4 19.1242 19.5075 19.1242 18.4875C19.1242 17.4675 20.0167 16.575 21.0367 16.575Z"
      fill="#313131"
    />
  </svg>
);
