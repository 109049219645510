import React, { useRef } from "react";

import { Button } from "@nextui-org/react";

interface Props {
  label: string;
  handleFileChange?: (file: File[], part: string) => void;
  file?: File;
}

export default function SingleUploadSectionBhph({
  label,
  handleFileChange,
  file,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFileChange && handleFileChange(Array.from(e.target.files), label);
    }
  };
  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className=" grid grid-cols-1 gap-0 md:gap-2 md:grid-cols-3 justify-items-left h-[80] md:h-[40] formControl mb-4">
      <div className="text-left text-[14px] font-bold h-full flex items-end md:items-center">
        {label}
      </div>{" "}
      <div className="md:mt-0 col-span-1  md:col-span-2 w-full md:w-auto flex gap-2   ">
        <div className="px-3 w-full md:w-34 border border-black bg-white text-slate-300 rounded-2xl flex justify-self-start items-center h-full">
          <span className="flex overflow-hidden  whitespace-nowrap overflow-ellipsis items-center text-[10px] md:text-[12px] h-full text-slate-400">
            {file?.name ? file.name : "Select Document"}
          </span>
        </div>
        <input
          type="file"
          id="fileInput"
          multiple={false}
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChangeLocal}
        />

        <Button
          name="Browse files"
          data-modal-hide="default-modal"
          className=" bg-gradient-to-r from-[#018ffd] to-[#070c84]  w-[85px] md:w-[85px] h-full border rounded-xl text-white font-semibold text-[10px] md:text-[12px]"
          onClick={onHandleFileClick}
        >
          Upload
        </Button>

        <Button
          name="Browse files"
          data-modal-hide="default-modal"
          className=" bg-gradient-to-r from-[#018ffd] to-[#070c84]  w-[85px] md:w-[85px] h-full border rounded-xl text-white font-semibold text-[10px] md:text-[12px]"
          onClick={onHandleFileClick}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}
