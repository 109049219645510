import { Card, CardBody, Skeleton } from "@nextui-org/react";

import { Button, Typography } from "components";
import { useEffect, useState } from "react";
import { ListingDescriptionResponse } from "services";

interface VehicleListingDescriptionProps {
  listingDescription: ListingDescriptionResponse | null;
  carModel: string;
  showRefetch?: boolean;
  isLoading?: boolean;
  handleTryAgain?: () => void;
  errorMessage?:string
}
export const VehicleListingDescription = ({
  listingDescription,
  carModel,
  showRefetch = false,
  isLoading = false,
  handleTryAgain,
  errorMessage
}: VehicleListingDescriptionProps) => {
  const [carDetails, setCarDetails] = useState<any[]>([]);
  useEffect(() => {
    setCarDetails([
      {
        id: 0,
        data: `Gas mileage : ${listingDescription?.gas_mileage || ""}`,
        init:listingDescription?.gas_mileage
      },
      {
        id: 1,
        data: `0-60 time : ${listingDescription?.zero_to_60 || ""}`,
        init:listingDescription?.zero_to_60
      },
      {
        id: 2,
        data: `Factory Warranty Information : ${
          listingDescription?.warranty_information || ""
        }`,
        init:
        listingDescription?.warranty_information
      },
      {
        id: 3,
        data: `Open recalls : ${listingDescription?.open_recalls || ""}`,
        init:listingDescription?.open_recalls
      },
    ]);
  }, [listingDescription]);

  return (
    <Card
      className="mt-10 grid grid-cols-1 justify-items-center bg-[F1F1F1] lg:bg-[#fff]"
      fullWidth
      isPressable={false}
    >
      {/* {listingDescription ? ( */}
      <CardBody className="flex justify-center items-center mt-2 mb-5">
        <Typography
          variant="titleWithGradient"
          className="px-6 py-3 text-lg xl:text-2xl font-[600] text-center"
        >
          Listing Description
        </Typography>

        <Typography
          variant="custom"
          className="mb-10 mt-10 text-center text-lg lg:text-2xl font-[600] text-[#263238]"
        >
          {carModel}
        </Typography>
        <div className="w-full lg:w-3/4 px-5 lg:px-0">
          <ul className="list-disc text-blue-500 text-3xl">
            {carDetails.map((car) => {
              return (
                <>
                  {car?.init || !isLoading ? (
                    (car?.init && <li
                      key={car.id}
                      className="flex list-none items-start space-x-3 mb-5 relative"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        className="w-2 h-2 absolute top-[9px] -left-1 "
                        fill="none"
                      >
                        <circle cx="4" cy="4" r="4" fill="#018FFD" />
                      </svg>
                      <p className="text-[#263238] text-medium md:text-large  xs:text-center md:text-left">
                        {car.data}
                      </p>
                    </li>)
                  ) : (
                    <Loader />
                  )}
                </>
              );
            })}
          </ul>
        </div>
        <div className="w-full lg:w-3/4 px-5 lg:px-0">
          <p className="mt-5 text-[#263238] text-medium md:text-large xs:text-center md:text-left">
            {listingDescription?.car_description || !isLoading ? (
              listingDescription?.car_description
            ) : (
              <Loader />
            )}
          </p>
        </div>
        <Typography variant="error">{errorMessage}</Typography>
        {showRefetch && (
          <div
            className={` w-full  lg:w-3/4 px-5 lg:px-0 mt-4 flex justify-between `}
          >
            <div></div>
            <Button
              className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl"
              type="button"
              size="md"
              isLoading={isLoading}
              onClick={handleTryAgain}
            >
              <Typography className="font-bold" variant="body">
                {" "}
                Try Again
              </Typography>
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export function capitalizeWords(input: string) {
  if (!input) return input;
  const words = input.split(" ");

  const capitalizedWords = words.map((word: string) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
  return capitalizedWords.join(" ");
}

export default function Loader({}) {
  return (
    <Skeleton className="rounded-lg my-2">
      <div className="h-8 rounded-lg bg-default-300"></div>
    </Skeleton>
  );
}
