import { Typography } from "components";
import React, { useEffect } from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router";
export default function DmvTitlingServicesPage() {
  const navigate = useNavigate();

  const titlingServices = [
    {
      name: "Save Time: ",
      description:
        "Skip the DMV lines and complete the entire process from your computer or phone at your convenience.",
    },
    {
      name: "Increased Accuracy: ",
      description:
        "Our system guides you through the process and minimizes the risk of errors.",
    },
    {
      name: "Peace of Mind: ",
      description:
        "Our full-service option provides expert assistance and ensures everything is handled correctly.",
    },
    {
      name: "Transparency: ",
      description:
        "Upfront cost calculations ensure you know exactly what to expect.",
    },
    {
      name: "Convenience: ",
      description:
        "Integrated with our car buying platform for a seamless experience.",
    },
    ,
    {
      name: "Stand-Alone Service: ",
      description: "Use it independently for non-marketplace car transfers.",
    },
    {
      name: "Nationwide Coverage: ",
      description: "Transfer titles regardless of the car's location.",
    },
    {
      name: "Custom Solutions:",
      description: " Get help with complex DMV title-related issues.",
    },
  ];

  return (
    <div className="flex items-center justify-center">
      <div className="md:p-2 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text 2xl:text-5xl text-3xl font-bold mb-10">
            DMV/Titling Services
          </p>
          <p className=" text-2xl font-bold pt-5">
            Skip the Lines, Streamline the Process: Online Title Transfer Made
            Easy with findmyoptions.com
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-center">
          <p className="text-xl">
            At findmyoptions.com, we understand the hassle of navigating the DMV
            and waiting in long lines for title transfers. That's why we offer a
            convenient and secure online portal to handle the entire process
            from the comfort of your couch. 
            <span className="font-bold">
              Whether you're buying a car through our marketplace or
              transferring a title independently, we've got you covered.
            </span>
          </p>
        </Typography>

        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Our Streamlined Online Title Transfer Solution:
          </p>

          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Effortless Calculations:{" "}
              </span>
              Use our user-friendly online tool to calculate taxes and title
              transfer fees for your specific situation, ensuring complete
              transparency before you proceed.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <div>
              <p className={`px-3 pt-3 text-black font-light text-xl`}>
                <span className="font-semibold text-black bg-clip-text">
                  Full-Service Convenience:{" "}
                </span>
                Don't have the time or prefer expert assistance? Our
                full-service option takes care of everything. One of our
                dedicated representatives will:
              </p>
              <ul className="list-none flex flex-col justify-center mt-5 text-lg px-[5%]">
                <li className="mb-2 flex items-center relative">
                  <span className="inline-block w-2 h-2 rounded-full bg-black absolute top-2.5 -left-4"></span>
                  <span>
                    <span className="font-semibold">
                      Review all Documents:{" "}
                    </span>
                    Our team will meticulously review all required documents for
                    accuracy and completeness.
                  </span>
                </li>
                <li className="mb-2 flex items-center relative">
                  <span className="inline-block w-2 h-2 rounded-full bg-black absolute top-2.5 -left-4"></span>
                  <span>
                    <span className="font-semibold">
                      Electronically Submit to DMV:{" "}
                    </span>
                    We handle the electronic submission to your state's DMV,
                    ensuring a faster and more efficient process.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Integrated Platform:{" "}
              </span>
              Our online title transfer service seamlessly integrates with your
              car purchase on our marketplace. No need to juggle multiple
              platforms!
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Standalone Service:{" "}
              </span>
              Need to transfer the title of a car you sold outside our
              marketplace? No problem! Utilize our standalone title transfer
              service for a quick and convenient solution.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Nationwide Coverage:{" "}
              </span>
              We handle out-of-state title transfers as well, simplifying the
              process regardless of the vehicle's location.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Custom DMV Services:{" "}
              </span>
              Need help with more complex title-related issues beyond basic
              transfers? Submit a support ticket and our team will explore
              custom solutions to get your DMV needs addressed.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-[80px] text-wrap">
          <p className=" text-2xl font-bold  bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Benefits of Using Our Online Title Transfer Service:
          </p>
          {titlingServices.map((res: any) => (
            <div className="flex items-start  ">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="mt-[16px]"
              />
              <p className={`px-3 pt-3 text-black font-light text-xl`}>
                <span className="font-semibold text-black bg-clip-text">
                  {res.name}
                </span>
                {res.description}
              </p>
            </div>
          ))}
        </Typography>

        <Typography variant="custom" className="mt-[80px] mb-10 text-wrap">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Get Started Today!
          </p>
          <p className="text-xl mt-5 mb-10">
            Ready to experience the ease and convenience of online title
            transfer? Visit our online portal and:
          </p>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-bold text-xl`}>
              Calculate taxes and title transfer fees.
            </p>
          </div>
          <div className="flex items-start  ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-bold text-xl`}>
              Choose our full-service package for expert assistance.
            </p>
          </div>
          <p className={` pt-10 text-black font-light text-xl text-center`}>
            At findmyoptions.com, we're committed to making the car buying and
            ownership process as smooth and efficient as possible. Our
            innovative online title transfer solution is just one way we help
            you save time, minimize hassle, and get back on the road faster.
          </p>
        </Typography>
        <div className="text-center">
          <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg  py-8 tracking-wide px-6 rounded-xl mt-5"
            type="submit"
            onClick={() => {
              localStorage.clear();
              navigate("/register/user");
            }}
          >
            Create Account
          </Button>
        </div>
      </div>
    </div>
  );
}
