
export const cards = [
  {
    id: "01",
    title: "Experienced Finance Team: ",
    description:"Our dealer finance professionals are dedicated to guiding you through the entire financing process. They'll answer your questions, explain different loan options, and work tirelessly to secure the best possible financing terms for you.",
  
  },
  {
    id: "02",
    title: "Transparency Throughout the Process: " ,
    description:" Our partners believe in clear communication. You'll receive a detailed breakdown of all loan terms, interest rates, and monthly payments before you commit, ensuring you make an informed decision.",
  
  },
  {
    id: "03",
    title: "Online Approval Process: ",
    description:"We created a platform for a fully online application process to save time and make things easy for you!"
  },
];




export const listOptionsTraditionalFinancingWHY = [
  {
    name: "Competitive Rates: ",
    description:
      "Our dealer partners work diligently with reputable lenders to secure financing with attractive interest rates, potentially saving you thousands of dollars over the life of your loan compared to alternative financing options.",
  },
  {
    name: "Building a Strong Credit Score: ",
    description:
      "Consistent and on-time payments on your car loan contribute positively to your credit history, opening doors to better loan options in the future, like lower interest rates on mortgages or personal loans.",
  },
  {
    name: "Tailored Loan Terms: ",
    description:
      "Whether you prioritize a shorter loan term for faster payoff or a longer term for lower monthly payments, Our dealers offer a variety of loan programs to accommodate your financial goals and budget.",
  }
];

export const listOptionsTraditionalFinancingGettingStarted = [
  {
    name: "Browse Our Inventory Online: ",
    description:
    "Find your perfect car from our extensive online inventory, complete with detailed descriptions, high-quality photos, and upfront pricing.",
  },
  {
    name: "Save the listing you want in your account: ",
    description:
      "Create an account and save the listing you want, when you look in your saved listings section in your garage after you login there will be a link to apply for that vehicle."
  },
  {
    name: "Explore Financing Options: ",
    description:
      "Our dealers will work with you to understand your budget and financial goals, then present a variety of traditional financing options tailored to your situation.",
  },
  {
    name: "Secure Your Loan: ",
    description:
    "Once you've chosen the ideal loan program, we'll guide you through the application process and work diligently with our network of lenders to secure your financing approval.",
  }

];