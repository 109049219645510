import { Typography } from "components";
import { useSelector } from "react-redux";
import { localStorageFn } from "utils/localstorage";
import SoldExpenseChart from "./components/sold-expense-chart";
import { useEffect, useState } from "react";
import { useGetDashboardData } from "services";

export default function DealerDashboard() {
  const [month, setMonth] = useState({ val: "1", inv: true, exp: true });
  const {
    data: dealerData,
    refetch: refetchDealerdata,
    isFetched,
  } = useGetDashboardData(month.val);
  const [DashboardChartContent, setDashboardChartContent] = useState([
    {
      title: "Inventory vs Sold",
      month: 1,
      total: {
        title: "Total Vehicles Sold",
        amount: 0,
      },
      inventoryRevenue: {
        title: "Inventory",
        amount: 0,
        percent: 0,
      },
      soldExpense: {
        title: "Sold",
        amount: 0,
        percent: 0,
      },
    },
    {
      title: "Revenue vs Expenses",
      month: 1,
      total: {
        title: "Total Profit",
        amount: 0,
      },
      inventoryRevenue: {
        title: "Revenue",
        amount: 0,
        percent: 0,
      },
      soldExpense: {
        title: "Expense",
        amount: 0,
        percent: 0,
      },
    },
  ]);
  const [cardData, setCardData] = useState({
    numberOfVehiclesInInventory: "0",
    numberOfVehiclesSold: "0",
    totalRevenue: "$ 0",
    totalExpenses: "$ 0",
  });
  const DashboardCardContent = [
    {
      title: "Number of Vehicles In Inventory (Monthly)",
      amount: cardData.numberOfVehiclesInInventory,
      icon: (
        <img
          src="/assets/icon/material-symbols_inventory.svg"
          alt="material_inventory"
        />
      ),
    },
    {
      title: "Number of Vehicles Sold (Monthly)",
      amount: cardData.numberOfVehiclesSold,
      icon: <img src="/assets/icon/mdi_car.svg" alt="mdi_icon_car" />,
    },
    {
      title: "Total Revenue",
      amount: cardData.totalRevenue,
      icon: (
        <img src="/assets/icon/mdi_cash-multiple.svg" alt="mdi_cash-multiple" />
      ),
    },
    {
      title: "Total Expenses",
      amount: cardData.totalExpenses,
      icon: <img src="/assets/icon/expense_1.svg" alt="expense_1" />,
    },
  ];

  const handleSoldSelect = (newMonth: number) => {
    const dashboardChart = [...DashboardChartContent];
    const soldChart = { ...dashboardChart[0] };
    soldChart.month = newMonth;
    dashboardChart[0] = soldChart;
    setDashboardChartContent(dashboardChart);
    if (isFetched) {
      setMonth({ val: newMonth.toString(), inv: true, exp: false });
    }
  };

  const handleExpenseSelect = (newMonth: number) => {
    const dashboardChart = [...DashboardChartContent];
    const soldChart = { ...dashboardChart[1] };
    soldChart.month = newMonth;
    dashboardChart[1] = soldChart;
    setDashboardChartContent(dashboardChart);
    if (isFetched) {
      setMonth({ val: newMonth.toString(), inv: false, exp: true });
    }
  };

  const userName: any = String(localStorageFn.getItem("firstName"));
  const userFirstname = useSelector((state: any) => state.user.firstname);

  useEffect(() => {
    if (dealerData) {
      const totalInventory =
        (dealerData.numberOfVehiclesSold || 0) +
        (dealerData.numberOfVehiclesInInventory || 0);
      const InventoryPerc =
        ((dealerData.numberOfVehiclesInInventory || 0) /
          (totalInventory || 1)) *
        100;
      const soldPerc =
        ((dealerData.numberOfVehiclesSold || 0) / (totalInventory || 1)) * 100;

      const totalMon =
        (dealerData.totalRevenue || 0) + (dealerData.totalExpenses || 0); //+ (dealerData.totalProfit ||0)
      const totalRevenuePerc =
        ((dealerData.totalRevenue || 0) / (totalMon || 1)) * 100;
      const totalExpensesPerc =
        ((dealerData.totalExpenses || 0) / (totalMon || 1)) * 100;
      // const totalProfitPerc = ((dealerData.totalProfit || 0)/(totalMon||1))*100;

      setDashboardChartContent((prev) => [
        month.inv
          ? {
              title: "Inventory vs Sold",
              month: 1,
              total: {
                title: "Total Vehicles Sold",
                amount: dealerData.numberOfVehiclesSold || 0,
              },
              inventoryRevenue: {
                title: "Inventory",
                amount: dealerData.numberOfVehiclesInInventory || 0,
                percent: InventoryPerc,
              },
              soldExpense: {
                title: "Sold",
                amount: dealerData.totalVehiclesSold || 0,
                percent: soldPerc,
              },
            }
          : prev[0],
        month.exp
          ? {
              title: "Revenue vs Expenses",
              month: 1,
              total: {
                title: "Total Profit",
                amount: dealerData.totalProfit || 0,
              },
              inventoryRevenue: {
                title: "Revenue",
                amount: dealerData.totalRevenue || 0,
                percent: totalRevenuePerc,
              },
              soldExpense: {
                title: "Expense",
                amount: dealerData.totalExpenses || 0,
                percent: totalExpensesPerc,
              },
            }
          : prev[1],
      ]);
      if(month.inv && month.exp ){
        setCardData({
          numberOfVehiclesInInventory:
            dealerData?.numberOfVehiclesInInventory?.toString() || "0",
          numberOfVehiclesSold:
            dealerData?.numberOfVehiclesSold?.toString() || "0",
          totalRevenue: dealerData?.totalRevenue
            ? `$ ${dealerData?.totalRevenue}`
            : "$ 0",
          totalExpenses: dealerData?.totalExpenses
            ? `$ ${dealerData?.totalExpenses}`
            : "$ 0",
        });
      }
      
    }
  }, [dealerData]);
  useEffect(() => {
    if (isFetched) {
      refetchDealerdata();
    }
  }, [month]);

  return (
    <>
      <div className="font-[Metropolis] flex flex-col justify-center items-center gap-8  pb-12">
        <Typography variant="pageTitle">
          Welcome {userFirstname || userName}!
        </Typography>
        <div className="flex justify-center flex-col gap-8">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
            {DashboardCardContent.map(({ title, amount, icon }, index) => (
              <DashboardCard
                key={index}
                title={title}
                amount={amount}
                icon={icon}
              />
            ))}
          </div>
          <div className="mt-2 grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 w-full">
            <SoldExpenseChart
              item={DashboardChartContent[0]}
              isProfit={false}
              onSelect={handleSoldSelect}
            />
            <SoldExpenseChart
              item={DashboardChartContent[1]}
              isProfit={true}
              onSelect={handleExpenseSelect}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function DashboardCard({
  title,
  icon,
  amount,
}: {
  title: string;
  icon: React.ReactNode;
  amount: string;
}) {
  return (
    <div className="h-[190px] font-[Metropolis] flex flex-col gap-10 lg:gap-2 justify-between p-4 items-center text-white bg-gradient-to-b from-[#018ffd] to-[#070c84] rounded-lg">
      <div className="flex items-center justify-between w-full">
        <div className="w-4/5 text-xl font-semibold leading-5">{title}</div>
        <div className="flex items-start justify-center size-10 lg:size-14">
          {icon}
        </div>
      </div>
      <div className="pb-2 text-5xl font-semibold md:text-3xl xl:text-5xl">
        {amount}
      </div>
    </div>
  );
}
