import { http } from "services/http/service.http";
import { VehicleRepairSubscriptionPayload } from "./repair-agreement.type";
import { responseTypes } from "ky/distribution/core/constants";
// import { FMOMemberShipType } from "./fmo-membership.type";

class VehicleContractEndpoint {
  public async SubmitVehicleServiceContract(payload:VehicleRepairSubscriptionPayload): Promise<any> {
    const res = await http.post(
      `/Transactions/SubmitVehicleServiceContract`,
      payload,
      {
        headers: {
          Accept: "*/*",
        },
      }
    ).catch(e=>{
      throw e;
    });
    if(res.status !== 200){
      throw new Error( res?.data || "Could not Submit used car warrenty! Please Contact Support")
    }
    return res.data;
  }

  public async SFRepairAgreement(payload:VehicleRepairSubscriptionPayload): Promise<any> {
    const res = await http.post(
      `/Listings/SFRepairAgreement`,
      payload,
      {
        headers: {
          Accept: "*/*",
        },
      }
    ).catch(e=>{
      throw e;
    });
    if(res.status !== 200){
      throw new Error( res?.data || "Could not add car warrenty to salesforce! Please Contact Support")
    }
    return res.data;
  }

  public async SFUpdateOdometerStatus( userId:number,vin:string, file:FormData): Promise<any> {
    const res = await http.post(
      `/Listings/SFUpdateOdometerStatus?userId=${userId}&vin=${vin}`,
      file,
      {
        headers: {
          Accept: "*/*",
          'Content-Type': 'multipart/form-data'
        },
      }
    ).catch(e=>{
      throw e;
    });
    if(res.status !== 200){
      throw new Error( res?.data || "Could not upload the file! Please try again")
    }
    return res.data;
  }


  public async UpdateVehicleServiceContract(payload:VehicleRepairSubscriptionPayload): Promise<any> {
    const res = await http.post(
      `/Transactions/UpdateVehicleServiceContract`,
      payload,
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async ProcessVehicleServiceContract(vin:string): Promise<any> {
    const res = await http.post(`/Transactions/ProcessVehicleServiceContract?vin=${vin}`, {
      headers: {
        Accept: "*/*",
      },
    }).catch(e=>{
      throw e;
    });

    if(res.status !== 200){
      throw new Error( res?.data || "Payment process for used car warrenty failed! Please try again")
    }
    return res.data;
  }

  public async GetVehicleServiceContract(): Promise<VehicleRepairSubscriptionPayload[]> {
    const res = await http.get(`/Transactions/GetVehicleServiceContracts`);
    return res.data;
  }

  public async GetSFOdometerFile(vin:string): Promise<any> {
    const res = await http.get(`/Listings/GetSFOdometerFile?vin=${vin}`,{ 
      responseTypes:"blob"
    });
    // const blob = new Blob([res.data]);
    // const url = window.URL.createObjectURL(blob);
    // console.log(vin,"0000000000>>>>>",isBase64Image(res.data))
    return  res.data
    // return isBase64Image(res.data) ?"data:image/jpeg;base64,": "data:application/pdf;base64, " + res.data;
  }
}

export const vehicleContractEndpoint = new VehicleContractEndpoint();
