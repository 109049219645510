import { Typography } from "components";
import React from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";

export default function GivingBackPage() {
  return (
    <div className="flex justify-center items-center">
      <div className="md:p-4 mt-10">
        <Typography variant="custom" className="text-center">
          <p className=" bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            Giving Back
          </p>
          <p className=" text-2xl font-bold pt-5">
            Empowering Our Community: Giving Back on the Road
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-center">
          <p className="text-xl">
            At findmyoptions.com, we believe in extending a helping hand to
            those in our community facing transportation challenges. Through our
            "Helping Hands, Rolling Wheels" program, we aim to ease the burden
            of car repairs and, in specific circumstances, even assist with
            acquiring reliable used cars through crowdfunding initiatives.
          </p>
        </Typography>

        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Helping Hands on the Road Ahead:
          </p>
          <p className="text-xl">
            "Helping Hands, Rolling Wheels" focuses on two key pillars:
          </p>

          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Repair Assistance:{" "}
              </span>
              We help cover the cost of essential repairs for qualified
              applicants, ensuring their existing vehicles remain safe and
              functional. This relieves them of unexpected financial strain and
              allows them to continue relying on their car for daily needs.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Crowdfunding for Used Cars:{" "}
              </span>
              In exceptional situations, we launch crowdfunding campaigns to
              raise funds for individuals in dire need of a reliable used car.
              These collaborative efforts leverage the generosity of our
              community to directly impact those facing significant
              transportation barriers.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Making a Difference, One Mile at a Time:
          </p>
          <p className="text-xl">
            Our commitment goes beyond simply fixing cars. We aim to:
          </p>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Empower individuals:{" "}
              </span>
              Reliable transportation unlocks opportunities for individuals and
              families, allowing them to access jobs, education, healthcare, and
              essential services.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Strengthen communities:{" "}
              </span>
              When transportation barriers are removed, everyone benefits from a
              more vibrant and connected community.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Foster compassion:{" "}
              </span>
              By joining forces, we cultivate a sense of unity and compassion,
              demonstrating the power of collective action.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            How You Can Help:
          </p>
          <p className="text-xl">
            We invite you to become an active participant in creating positive
            change. Here are ways you can contribute:
          </p>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Nominate someone in need:{" "}
              </span>
              Do you know someone in your community struggling with car troubles
              and limited resources? Nominate them for our program by reaching
              out to us at support@findmyoptions.com or filling out a support
              ticket here.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Spread the word:{" "}
              </span>
              Share information about "Helping Hands, Rolling Wheels" with your
              friends, family, and network to raise awareness about our
              initiative.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Contribute to a crowdfunding campaign:{" "}
              </span>
              When an active crowdfunding campaign appears on our platform,
              consider contributing to directly support an individual in need.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-16 mb-10 text-center">
          <p className="text-lg mt-2 font-bold">
            Together, we can make a real difference on the road to a more
            empowered and connected community!
          </p>
        </Typography>
      </div>
    </div>
  );
}
