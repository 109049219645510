import { Button, Listbox, ListboxItem } from "@nextui-org/react";

import { Typography } from "components";
import { CloseXIcon } from "assets/svgs/icons/circle-x-icon";
import { CheckIcon } from "assets/svgs/icons/check-icon";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CarShippignData,
  ShippingByIDResponse,
} from "services/endpoints/shipping-quotes/shipping.type";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useGetShippingQuotesById } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { PaymentModal } from "pages/shared";
import { ShippingPaymentComponent } from "../new-order/shipping-payment.component";
import { getUserRoles, isThisDealer } from "utils/get-user-id";

interface Props {
  next: () => void;
  items: CarShippignData[];
  page: number | null;
}
export const ListOrderHistory = ({ items, next, page }: Props) => {
  const navigate = useNavigate();
  const handleTransitStatus = (qId: string) => {
    navigate(
      `${isThisDealer() ? "" : "/user"}/shipping-quotes/transit-status/${qId}`
    );
  };
  const [payModalStep, setPayModalStep] = useState<number | null>(null);
  const [shippingPaymentInfo, setShippingPaymentInfo] =
    useState<ShippingByIDResponse | null>(null);

  const {
    mutateAsync: mutateAsyncGetShippingQuotesByID,
    isPending: isPendingGetShippingQuetuesByID,
  } = useGetShippingQuotesById();

  const handleContinue = async (qId: string) => {
    try {
      const res = await mutateAsyncGetShippingQuotesByID(qId);
      console.log("res---OO 111", res);
      setShippingPaymentInfo(res);
      setPayModalStep(1);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  return (
    <div>
      <InfiniteScroll
        dataLength={items.length}
        next={next}
        hasMore={page !== null && items.length >= 9}
        scrollThreshold={0.8}
        loader={
          <p className="flex justify-center items-center">
            <b>Loading...</b>
          </p>
        }
      >
        <Listbox
          aria-label="order-history-list"
          variant="light"
          disallowEmptySelection
          selectionMode="none"
        >
          {items.map((orderHistory) => (
            <ListboxItem key={orderHistory.quoteId}>
              <div className="xl:flex  border border-blue-400 rounded-lg p-2 md:p-8 items-center justify-between">
                <div className="grid justify-items-center md:justify-items-center xl:justify-items-start">
                  <Typography
                    variant="subtitle"
                    className="text-sm md:text-3xl"
                  >
                    {orderHistory.name.length > 40
                      ? orderHistory.name.slice(0, 40) + "..."
                      : orderHistory.name}
                  </Typography>
                  <Typography
                    variant="body"
                    className="font-semibold text-xs md:text-md"
                  >
                    VIN - {orderHistory.vin}
                  </Typography>
                </div>
                <div className="md:flex items-center justify-around mt-5 md:mt-0 ">
                  <div className="grid grid-cols-1 justify-items-center md:justify-items-center xl:justify-items-end">
                    <div className="flex flex-col justify-center items-center mr-1 xl:mr-5  md:w-[84px]">
                      <Typography variant="body">
                        {orderHistory.status < 3 ? (
                          <CloseXIcon className="" />
                        ) : (
                          <CheckIcon />
                        )}
                      </Typography>
                      <Typography variant="body">
                        {orderHistory.status < 3 ? "Pending" : "Completed"}
                      </Typography>
                    </div>
                  </div>
                  <div className="grid grid-flow-row md:grid-flow-col  gap-4">
                    <Button
                      className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 tracking-wide px-2 md:px-6 rounded-xl text-sm md:text-lg w-full"
                      type="button"
                      size="md"
                      onClick={() => handleTransitStatus(orderHistory.quoteId)}
                    >
                      <Typography variant="body">Transit Status</Typography>
                    </Button>
                    <Button
                      className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 tracking-wide px-2 md:px-6 rounded-xl text-sm md:text-lg w-full"
                      type="button"
                      size="md"
                      onClick={() => handleContinue(orderHistory.quoteId)}
                    >
                      <Typography variant="body">Continue</Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </ListboxItem>
          ))}
        </Listbox>
      </InfiniteScroll>
      <PaymentModal
        isOpen={payModalStep !== null}
        onOpen={() => {
          setPayModalStep(null);
        }}
        Component={
          <ShippingPaymentComponent
            payModalStep={payModalStep}
            setPayModalStep={(num) => setPayModalStep(num)}
            shippingPaymentInfo={null}
            forHistoryOrder={true}
            orderHistoryShippindData={shippingPaymentInfo}
          />
        }
      />
    </div>
  );
};
