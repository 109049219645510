export interface TransformData {
  data: {
    Color: string;
    Interior: string;
    Options: string[];
  };
}

export const transformData = (factoryData: any): TransformData => {
  let newArray: any = {
    Color: "",
    Interior: "",
    Options: [],
  };

  let isParsingTranslationEn = false;
  for (const item of factoryData) {
    if (
      item.value === "Color" &&
      isParsingTranslationEn === false &&
      newArray.Color === ""
    ) {
      newArray.Color = "";
      isParsingTranslationEn = true;
    } else if (
      isParsingTranslationEn &&
      item.key === "translation_en" &&
      newArray.Color === ""
    ) {
      newArray.Color = item.value;
      isParsingTranslationEn = false;
    } else if (
      item.value === "Interior" &&
      isParsingTranslationEn === false &&
      newArray.Interior === ""
    ) {
      newArray.Interior = "";
      isParsingTranslationEn = true;
    } else if (
      isParsingTranslationEn &&
      item.key === "translation_en" &&
      newArray.Interior === ""
    ) {
      newArray.Interior = item.value;
      isParsingTranslationEn = false;
    } else if (item.value === "Options" && isParsingTranslationEn === false) {
      isParsingTranslationEn = true;
    } else if (isParsingTranslationEn && item.key === "translation_en") {
      newArray.Options.push(item.value);
    }
  }
  return newArray;
};

export function traverse(target: any) {
  const result: any = [];

  function explore(obj: any) {
    for (const key in obj) {
      if (key !== "e" && typeof obj[key] === "object") {
        explore(obj[key]);
      } else {
        result.push({ key, value: obj[key] });
      }
    }
  }

  explore(target);
  return result;
}
