import React, { useState } from "react";
import closeIcon from "./assets/close-icon.svg";
import shield_yes from "./assets/shield_yes.svg";
import "./DmvTitle.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { changePricingForDmv } from "../../store/dmv-slice";

interface DmvTitleModalsProps {
  open: boolean;
  handleClose: (select?: string) => void;
}

const DmvPriceingModals: React.FC<DmvTitleModalsProps> = ({
  open,
  handleClose,
}) => {
  // const [selectedPricing, setSelectedPricing] = useState<string | undefined>()
  const selectedPricing = useSelector((state: RootState) => state.dmv.pricing);
  
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleContinue = () => {
    if (selectedPricing === "0") {
      navigate("/dealer-dashboard/fee-details", {
        state: { priceOptId: selectedPricing },
      });
      handleClose();
    } else if (selectedPricing === "2") {
      setStep(1);
    } else if (selectedPricing === "1") {
      handleClose("1");
    }
  };

  const AcceptPayment = () => {
    navigate("/dealer-dashboard/fee-details", {
      state: { priceOptId: selectedPricing },
    });
    handleClose();
  };

  const handlePricingCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(changePricingForDmv(e.target.name));
      // setSelectedPricing(e.target.name)
    } else {
      dispatch(changePricingForDmv(null));
    }
  };
  console.log("selectedPricing", selectedPricing);
  return (
    <React.Fragment>
      <div className="flex items-center m-auto dmv-title">
        <div className="flex items-center  bg-cover bg-center mx-auto px-4 bg-fixed">
          {open && (
            <div
              style={{ zIndex: 150 }}
              className="flex overflow-y-auto p-4 overflow-x-hidden fixed items-center  modal-container-vin pt-[130px] justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full"
            >
              <div className="absolute inset-0 bg-black opacity-50 z-999"></div>

              <div className="relative max-w-[70rem] gradiant-modal-loading  min-h-[380px] py-24 px-4   md:px-12  mx-auto  rounded-lg shadow-md ">
                <img
                  onClick={() => handleClose()}
                  src={closeIcon}
                  alt="x"
                  className="absolute right-8 top-10 border-white border-2 p-1 rounded-full cursor-pointer"
                />
                {step === 0 ? (
                  <>
                    <div className="text-white justify-center  font-bold mb-4  md:text-2xl flex text-lg">
                      Choose Your Plan
                    </div>
                    <div className="my-8">
                      {[
                        { label: "Quote Only? Free", id: "0" },
                        {
                          label: "Quote and PDF Documents for Self Service?",
                          id: "1",
                        },
                        { label: "Full Service $149", id: "2" },
                      ].map((list) => (
                        <div
                          key={list.id}
                          className="flex justify-between items-center my-2"
                        >
                          <div className="flex   gap-2">
                            <img src={shield_yes} alt="Y" className="pb-4" />{" "}
                            <div>
                              <h5 className="text-white text-sm md:text-xl  mb-1">
                                {list.label}
                              </h5>

                              {list.id === "1" && (
                                <h6 className="text-white text-xs md:text-base">
                                  (Free tier is $19.99, any other its include.)
                                </h6>
                              )}
                            </div>
                          </div>
                          <label className="relative inline-flex items-center mb-1 cursor-pointer">
                            <input
                              type="checkbox"
                              checked={selectedPricing === list.id}
                              onChange={handlePricingCheck}
                              className="sr-only peer"
                              name={list.id}
                            />
                            <div className="relative w-11 h-6 bg-red peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-[#ffffffbf] peer-checked:bg-[#ffffffbf] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-400 peer-checked:after:bg-blue-500 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-center flex-col items-center mb-2 gap-8">
                      <button
                        onClick={handleContinue}
                        className=" text-white confirm-btn-fin py-2  rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-2xl"
                        type="button"
                      >
                        {"Confirm"}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-white justify-center  font-bold mb-4  md:text-2xl flex text-lg">
                      Please Confirm Payment
                    </div>
                    <div className="text-white justify-center  font-bold mb-4  md:text-4xl flex text-2xl">
                      $10.50
                    </div>
                    <div className="text-white justify-center mb-8  font-bold   md:text-base flex ">
                      <p className=" text-sm text-center max-w-[24rem]">
                        {" "}
                        "By clicking ACCEPT, you agree to the charge listed
                        below to your credit card on file"
                      </p>
                    </div>
                    <div className="flex justify-center flex-col items-center mb-2 gap-8">
                      <button
                        onClick={AcceptPayment}
                        className=" text-white confirm-btn-fin py-2  rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-2xl"
                        type="button"
                      >
                        {"ACCEPT"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DmvPriceingModals;
