import { Typography } from "components";
import { useRef } from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import { CarTierDetails } from "../fmo-membership/car-tier-details.component";
import { MainSectionWarrenty } from "./main-section-warrenty";
import "./repair-assistance.scss";
import { AffortableIcon } from "./assets/affortable.svg";
import { RecycleIcon } from "./assets/recycle";
import { IdeaIcon } from "./assets/idea-icon";
import { HeadphoneIcon } from "./assets/headphone-icon";

export const WarrentyBenefic = [
  {
    id: 0,
    label: "Transparent and Affordable:",
    content:
      "We offer clear and straightforward pricing with no hidden fees. You'll know exactly what you're getting for your money.",
    icon: <AffortableIcon />,
  },
  {
    id: 1,
    label: "Easy to Understand:",
    content:
      "We explain our plans in clear and concise language, so you understand what's covered and what's not.",
    icon: <RecycleIcon />,
  },
  {
    id: 2,
    label: "Hassle-Free Claims Process:",
    content:
      "We make filing a claim simple and stress-free. We work directly with repair shops to ensure your car gets the repairs it needs quickly and efficiently.",
    icon: <IdeaIcon className="size-40" />,
  },
  {
    id: 3,
    label: "Excellent Customer Service:",
    content:
      "Our dedicated customer service team is here to answer your questions and assist you throughout the process.",
    icon: <HeadphoneIcon />,
  },
];

export default function RepairAssistancePage() {
  // const navigate = useNavigate();
  const targetRef = useRef(null);

  const handleClickQueotes = () => {
    // Scroll to the top of the target element
    (targetRef?.current as any).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="flex items-center justify-center repair-assistance">
      <div className=" mt-10">
        <MainSectionWarrenty handleClickQueotes={handleClickQueotes} />

        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            Repair Agreements/Roadside Assistance
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 ">
          <p className="text-xl text-center">
            Peace of mind on the road shouldn't be a luxury. At
            findmyoptions.com, we offer a range of used car Repair Agreements to
            fit your needs and budget. Whether you're buying a pre-owned vehicle
            or want to extend the coverage on your existing one, we've got you
            covered.
          </p>
        </Typography>
        <Typography variant="custom" className=" mt-10 text-center">
          <p className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Secure Your Journey with findmyoptions Warranties
          </p>
        </Typography>
        <div
          className="text-center flex justify-center items-center py-10"
          ref={targetRef}
        >
          <CarTierDetails />
        </div>
        <div className=" grid grid-cols-1 xl:grid-cols-2  ">
          <Typography
            variant="pageTitle"
            className="col-span-1 mb-16 text-center xl:col-span-2 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 "
          >
            Why Choose Our Used Car Service Warranties
          </Typography>
          <div className="flex justify-center relative w-full h-full items-center mb-10 xl:mb-0 p-0">
            <div className="absolute w-full h-full second-warrenty-img "></div>
            <img
              className="absolute  w-full h-full rounded-none object-cover xl:to my-auto xl:right-16 top-16"
              src="assets/images/warrenty-assistent-why.png"
              alt="w2"
            />
          </div>
          <Typography variant="custom" className="text-wrap p-4 md:p-0">
            <div className="flex items-start relative mt-20">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="absolute top-1.5 -left-6"
                alt="check"
              />
              <p className={` text-black font-light text-xl`}>
                <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent  bg-clip-text">
                  {" "}
                  Comprehensive Coverage:{" "}
                </span>
                Our VSC plans cover a wide range of components, from the engine
                and transmission to electrical systems and air conditioning. You
                can choose a plan that best suits the age, mileage, and specific
                needs of your vehicle.
              </p>
            </div>
            <div className="flex items-start mt-2 relative">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="absolute top-1.5 -left-6"
                alt="check"
              />
              <p className={` text-black font-light text-xl`}>
                <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent  bg-clip-text">
                  Roadside Assistance Included:{" "}
                </span>
                Most of our plans come with comprehensive roadside assistance,
                including:
              </p>
            </div>
            <ul className="list-none flex flex-col justify-center mt-2 text-lg px-[5%]">
              <li className="mb-2 flex items-center relative">
                <span className="inline-block w-2 h-2 rounded-full bg-blue-900 absolute top-2.5 -left-4"></span>
                <span>
                  <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text ">
                    24/7 Towing:{" "}
                  </span>
                  Get help getting your car to the nearest repair shop, no
                  matter the time of day or night.
                </span>
              </li>
              <li className="mb-2 flex items-center relative">
                <span className="inline-block w-2 h-2 rounded-full bg-blue-900 absolute top-2.5 -left-4"></span>
                <span>
                  <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
                    Flat Tire Service:{" "}
                  </span>
                  A technician will come to you and change your flat tire,
                  getting you back on the road quickly.
                </span>
              </li>
              <li className="mb-2 flex items-center relative">
                <span className="inline-block w-2 h-2 rounded-full bg-blue-900 absolute top-2.5 -left-4"></span>
                <span>
                  <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
                    Battery Jump Start:{" "}
                  </span>
                  If your battery dies, a technician will provide a jump start
                  to get your car running again.
                </span>
              </li>
              <li className="mb-2 flex items-center relative">
                <span className="inline-block w-2 h-2 rounded-full bg-blue-900 absolute top-2.5 -left-4"></span>
                <span>
                  <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
                    Lockout Assistance:{" "}
                  </span>
                  A If you lock your keys in your car, a technician will help
                  you regain access.
                </span>
              </li>
              <li className="mb-2 flex items-center relative">
                <span className="inline-block w-2 h-2 rounded-full bg-blue-900 absolute top-2.5 -left-4"></span>
                <span>
                  <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
                    Fuel Delivery Service:{" "}
                  </span>
                  Run out of gas? We can arrange fuel delivery to get you back
                  on the road.
                </span>
              </li>
            </ul>
            <div className="flex items-start mt-2 relative">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="absolute top-1.5 -left-6"
                alt="check"
              />
              <p className={` text-black font-light text-xl`}>
                <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
                  Multiple Coverage Options:{" "}
                </span>
                We offer a variety of plans with different coverage levels and
                durations to suit your budget and vehicle's needs.
              </p>
            </div>
            <div className="flex items-start  mt-5 relative">
              <img
                src={checkIcon}
                height={16}
                width={16}
                className="absolute top-1.5 -left-6"
                alt="check"
              />
              <p className={` text-black font-light text-xl`}>
                <span className="font-semibold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
                  Peace of Mind:{" "}
                </span>
                Knowing you're covered against unexpected repairs can give you
                peace of mind and help you avoid costly breakdowns.
              </p>
            </div>
          </Typography>
        </div>
        {/* <div className="warrenty-third-sec py-2 flex flex-col">
          <Typography
            variant="custom"
            className="font-[Metropolis] text-xl font-[700] md:text-4xl mb-4 mt-4 text-center text-white"
          >
            Benefits of Choosing Us
          </Typography>

          <div className=" m-auto grid grid-cols-1 md:grid-cols-2 justify-items-center border-none shadow-none">
            {WarrentyBenefic.map((item, i) => {
              return (
                <div
                  className={` flex items-center justify-center gap-4 border-2 border-white w-[98%] rounded-3xl p-5 my-2 ml-0 mr-0 ${
                    i % 2 ? "md:ml-2" : "md:mr-2"
                  }`}
                  key={i}
                >
                  {item.icon}
                  <Typography
                    variant="custom"
                    className="text-[12px] md:text-[14px] font-[500] text-white mt-2 md:mt-4 "
                  >
                    {item.label}<br/>
                    {item.content}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
}
