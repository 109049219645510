import { SearchField } from "components";
import Form from "components/ui/form";
import { SearchOrderHistoryValidationSchema } from "./search-order-history.validations";

export const SearchOrderHistory = () => {
  return (
    <Form
      id="search-order-history-form"
      schema={SearchOrderHistoryValidationSchema}
      onSubmit={() => {}}
      className="px-4"
    >
      {({ register }) => (
        <SearchField {...register("search")} className="w-full lg:w-[400px]" />
      )}
    </Form>
  );
};
