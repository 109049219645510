import { createBrowserRouter } from "react-router-dom";

import { PublicLayout } from "components";
// import { ViewSingleCarPage } from "pages/public";
import AuthLayout from "components/layouts/auth-layout";
import DealerLogin from "pages/(auth)/dealer-login";
import DealerRegister from "pages/(auth)/dealer-register";
// import PassChange from "modules/dealer/features/forgot-password/PassChange";
import EmailTheme from "modules/dealer/features/forgot-password/EmailTheme";
import ResetPassword from "modules/dealer/features/forgot-password/ResetPassword";

// import ForgotPass from "modules/dealer/features/forgot-password/ForgotPass";

import DealerDashboard from "pages/dealer/dashboard";
import DmvTitling from "pages/dealer/dmv-titling";
import DmvEnterMissingDetails from "modules/dealer/components/dmv-title/DmvEnterMissingDetails";
import DmvDocStatus from "modules/dealer/components/dmv-title/DmvDocStatus";
import DmvTransitStatus from "modules/dealer/components/dmv-title/DmvTransitStatus";
import ProfitLoss from "pages/dealer/profit-loss";
import EnterVINNumber from "pages/dealer/enter-vin";
import ManageInventory from "pages/dealer/manage-inventory/live-inventory";
import DmvFeeDetails from "modules/dealer/components/dmv-title/DmvFeeDetails";
import ShowQuotes from "modules/dealer/features/shipping-quotes/ShowQuotes";
import {
  NewOrderShippingPage,
  OrderHistoryShippingPage,
} from "pages/shipping-quotes";
import PaymentForm from "modules/dealer/features/shipping-quotes/PaymentForm";
import SendOTP from "modules/dealer/features/shipping-quotes/SendOTP";
import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import TransitStatus from "modules/dealer/features/shipping-quotes/TransitStatus";
import DealerMembership from "modules/dealer/components/dealer-membership/DealerMembership";
import DealerDashboardLayout from "components/layouts/dealer-dashboard-layout";

export const baseRouter = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: PublicLayout,
    loader() {
      return { user: { name: "John Doe", email: "doe@me.com" } };
    },
    children: [
      {
        index: true,
        Component: AuthLayout,
      },
    ],
  },
  {
    id: "auth",
    path: "/auth",
    Component: AuthLayout,
    children: [
      {
        path: "login",
        Component: DealerLogin,
        index: true,
      },
      {
        path: "register",
        Component: DealerRegister,
      },
      {
        path: "forgot-password",

        // Component: ForgotPass,
      },
      {
        path: "reset",
        Component: ResetPassword,
      },
      {
        path: "email",
        Component: EmailTheme,
      },
      {
        path: "forgot-password/changed",
        // Component: PassChange,
      },
    ],
  },
  {
    id: "dealer",
    path: "/dealer",
    Component: DealerDashboardLayout,
    children: [
      {
        path: "dashboard",
        Component: DealerDashboard,
        index: true,
      },
      {
        path: "dmv-titling",
        children: [
          {
            path: "new-order",
            index: true,
          },
          {
            path: "order-history",
          },
          {
            path: "enter-missing-details",
            Component: DmvEnterMissingDetails,
          },
          {
            path: "dvm-document-status",
            Component: DmvDocStatus,
          },
          {
            path: "dvm-transit-status",
            Component: DmvTransitStatus,
          },
        ],
      },
      {
        path: "profit-loss",
        Component: ProfitLoss,
      },
      {
        path: "enter-vin",
        Component: EnterVINNumber,
      },
      {
        path: "manage-inventory",
        Component: ManageInventory,
      },
      {
        path: "manage-listing",
        // Component: ManageListing,
      },
      {
        path: "fee-details",
        Component: DmvFeeDetails,
      },
      {
        path: "shipping-quotes",
        children: [
          {
            path: "show-quotes",
            Component: ShowQuotes,
            index: true,
          },
          {
            path: "new-order",
            Component: NewOrderShippingPage,
          },
          {
            path: "order-history",
            Component: OrderHistoryShippingPage,
          },
          {
            path: "payment-form",
            Component: PaymentForm,
          },
          {
            path: "send-otp",
            Component: SendOTP,
          },
          {
            path: "payment-success",
            Component: PaymentSuccess,
          },
          {
            path: "transit-status",
            Component: TransitStatus,
          },
        ],
      },
      // {
      //   path: "user-profile",
      //   Component: Profile,
      // },
      {
        path: "user-membership",
        Component: DealerMembership,
      },
    ],
  },
]);
