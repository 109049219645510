import { jwtDecode } from "jwt-decode"

export function safeDecode(token:string) {

  try {
    return jwtDecode(token)
  } catch (error) {
    console.log("Error while decoding JWT",error)
    return undefined
    
  }

}
