import { useNavigate } from "react-router-dom";

import "./index.scss";
import { PATH_APP } from "../../../routers/path.router";

export const ToolsMenu = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="tools-menu-content-details bg-white w-full h-15 p-5">
        <button
          className="btn-detail-text"
          type="button"
          onClick={() => navigate(PATH_APP.tools.paymentCalculator)}
        >
          Payment Calculator
        </button>
      </div>
    </>
  );
};
