import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Input from "../../../../../src/components/ui/input";
import "./chathelp.css";
import CloseIcon from "../../../shared/components/CloseIcon";
import { useTurboChatMutation } from "hooks/api/mutations/chat/turbo-ai/use-turbo-mutation";

export const TurboChat = forwardRef((prop, ref) => {
  const { handleScreenChange, setOpenChat } = prop;
  const [message, setMessage] = useState("");

  const initialState = [
    {
      content: "Hello, I'm Turbo, how can i help you?",
      role: "assistant",
    },
  ];

  const [messages, setMessages] = useState(initialState);

  const { mutate } = useTurboChatMutation();

  const [turbochatLoading, setturbochatLoading] = useState(false);
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = async (message = " ") => {
    setturbochatLoading(true);
    if (message !== " ") {
      const updatedMessages = [...messages, { content: message, role: "user" }];
      setMessages((prev) => [...prev, { content: message, role: "user" }]);
      mutate(updatedMessages, {
        onSuccess: (response) => {
          setMessages((prev) => [
            ...prev,
            { content: response.answer, role: "assistant" },
          ]);
          setturbochatLoading(false);
        },
        onError: (error) => {
          console.error("Error:", error);
          setturbochatLoading(false);
        },
      });
    }
    setMessage("");
  };

  useImperativeHandle(ref, () => {
    return {
      handleSubmit: (messageAi) => handleSubmit(messageAi),
    };
  });

  const chatMessagesRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Assuming `messages` is your state containing chat messages

  // Function to scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };
  return (
    <div
      className="flex justify-end max-[768px]:min-h-[90vh]"
      style={{ fontFamily: "Metropolis-SemiBold" }}
    >
      <div className="w-full rounded-none m-0  bg-[#fff] min-[768px]:w-[400px] min-[768px]:h-[550px] min-[768px]:rounded-[25px]">
        <div className="relative flex h-[100px] md:h-[100px] grad md:rounded-t-[25px]">
          <div className=" ml-[9px] mt-[15px] md:ml-[39px] md:mt-[39px]"></div>
          {/* <div className='absolute top-10 left-20'>
            <img src='assets/icons/chat-logo.svg' alt='' />
          </div> */}
          <div className="-ml-5 md:-ml-14 flex items-center justify-center w-full text-[15px] md:text-[20px] text-white gap-2">
            <span>
              <img
                className="w-[20px] h-[20px] md:w-[32px] md:h-[32px]"
                src={process.env.PUBLIC_URL + "/assets/icons/turbo.svg"}
                alt=""
              />
            </span>
            <div>Turbo-AI Support</div>
          </div>
          <div className="absolute bottom-[24px] md:bottom-[15px] right-[50px] md:right-[90px]">
            <img
              className="w-[3px] h-[3px] md:w-auto md:h-auto"
              src="assets/icons/dot.svg"
              alt=""
            />
          </div>
          <div className="absolute bottom-[10px] right-[20px] md:right-[40px] text-[8px] md:text-[14px] font-extrabold text-white">
            Online
          </div>
          <div className="absolute right-0 mr-[20px] mt-[40px] ">
            <div
              className="inline-block w-[15px] h-[15px]  relative cursor-pointer"
              onClick={handleScreenChange}
            >
              <CloseIcon stroke="#FFF" strokeWidth="4.028" className="" />
            </div>
          </div>
        </div>

        {/* chat message */}
        <div class="chat-messages" id="chatMessages" ref={chatMessagesRef}>
          {messages.length > 0 &&
            messages.map((mes, ind) => {
              return (
                <React.Fragment key={ind}>
                  {mes.role === "user" ? (
                    <div className="-mt-[2px] relative w-full px-2 md:px-7 chat-message ">
                      <div className="flex items-center justify-end mb-[10px]">
                        <div
                          className={`break-normal flex flex-col px-[10px] md:px-[20px] py-2 md:pt-1 md:pb-5 max-w-[70%] md:max-w-[80%] min-h-[50px] md:min-h-[79px] justify-center chat-sender `}
                          name="user"
                        >
                          <div className="flex justify-end text-[#070C84] font-bold text-[9px] md:text-[14px]">
                            {"You"}
                          </div>
                          <div className="text-[10px] md:text-[15px]">
                            {mes.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="-mt-[2px] relative w-full px-2 md:px-7 chat-message ">
                      <div className="flex items-center justify-start mb-[10px]">
                        <div
                          className={`break-normal flex flex-col px-[10px] md:px-[20px] py-2 md:pt-1 md:pb-5 max-w-[70%] md:max-w-[80%] min-h-[50px] min-md:h-[79px] justify-center chat-reciever `}
                          name="assistant"
                        >
                          <div className="flex justify-start text-[#070C84] font-bold text-[9px] md:text-[14px]">
                            {"Turbo"}
                          </div>

                          <div className="text-[10px] md:text-[15px]">
                            {mes.content}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          {turbochatLoading && (
            <div className="flex items-center justify-start mb-[10px]">
              <div
                className={`break-normal flex flex-col px-[10px] md:px-[20px] py-2 md:pt-1 md:pb-5 max-w-[70%] md:max-w-[80%] min-h-[50px] min-md:h-[79px] justify-center chat-reciever `}
                name="assistant"
              >
                <div className="flex justify-start text-[#070C84] font-bold text-[9px] md:text-[14px]">
                  {"Turbo"}
                </div>

                <div className="text-[10px] md:text-[15px]">
                  ...Searching for the answer
                </div>
              </div>
            </div>
          )}
        </div>

        {/* chat input */}
        <div className="flex justify-center">
          <Input
            value={message}
            variant="form"
            radius="md"
            size="xl"
            className="px-6"
            placeholder="Write your message"
            classNames={{
              input: "data-[has-start-content=true]:ps-16",
              mainWrapper: "px-4",
              inputWrapper: "px-4",
            }}
            onChange={handleChange}
            endContent={
              <img
                ref={ref}
                className="cursor-pointer"
                src={process.env.PUBLIC_URL + "/assets/icons/send.svg"}
                alt="car"
                onClick={() => handleSubmit(message)}
              />
            }
          />
        </div>
      </div>
    </div>
  );
});
