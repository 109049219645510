import { z } from "zod";

export const DealerProfileSchema = z.object({
  firstName: z.string().min(1, {
    message: "First name is required",
  }),
  lastName: z.string().min(1, {
    message: "Last name is required",
  }),
  email: z.string().email().min(1, {
    message: "Email is required",
  }),
  username: z.string().min(1, {
    message: "Profile Name is required",
  }),
  contactPerson: z
    .string({ required_error: "Phone Number is Required" })
    .trim()
    .min(1, "Phone Number is Required")
    .refine(
      (value) =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
      "Please provide valid US Phone Number"
    ),
  address: z.string().min(1, {
    message: "Address is required",
  }),
  licenseNumber: z.string().min(1, {
    message: "License number is required",
  }),
  inventorySize: z.string(),
  // zipCode: z.string().min(1, {
  //   message: "Zip code is required",
  // }),
  // city: z.string().min(1, {
  //   message: "City is required",
  // }),
  legalBusinessName: z.string().min(1, {
    message: "Legal business name is required",
  }),
  // state: z.string().min(1, {
  //   message: "State is required",
  // }),
  dealerWebsite: z.string(),
});
