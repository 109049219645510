import { http } from "services/http/service.http";
import {
  DocumentServiceQuotesRequest,
  DocumentServiceQuotesResponse,
  FeeQuotesResponse,
  TilingRequest,
  TilingSelectAtcProcedureRequest,
  TiltingOrderHistory,
} from "./tiling.type";
import { throwNotErrorSuccessResponse } from "config/retryConfig";

class TilingEndpoint {
  public async getATCFeeQuotesAsync(
    payload: TilingRequest,
    quotesId: string,
    listingId: string
  ): Promise<FeeQuotesResponse> {
    try{

      const res = await http.post(
        `Tiling/GetFeeQuotes?quoteId=${quotesId}&listingId=${listingId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     
      throwNotErrorSuccessResponse(res,res.data || "Failed to get DMV & Taxs Rate!")
       return res.data;
    }catch(e){
      throw e;
    }
  }

  public async selectAtcProcedureAsync(
    payload: TilingSelectAtcProcedureRequest
  ) {
    const res = await http.post(`Tiling/SelectAtcProcedure`, {
      ...payload,
      driverLicenseFileName: "0",
      currentInsuranceFileName: "0",
    });
    return res.data;
  }

  public async uploadDMVTitlingFiles(payload: any, qId: string) {
    const res = await http.post(
      `Tiling/UploadDMVTitlingFiles?quoteId=${qId}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  }

  public async getDmvOrderHistoryAsync(
    page = 1,
    pageSize = 10
  ): Promise<TiltingOrderHistory[]> {
    const res = await http.get(
      `Tiling/GetDmvOrderHistory?page=${page}&pageSize=${pageSize}`
    );
    return res.data;
  }
  public async getProcessTitlingPayment(quotesId: string): Promise<any> {
    try {
      const res = await http.post(
        `/Transactions/ProcessTitlingPayment?quoteId=${quotesId}`
      );
      throwNotErrorSuccessResponse(res,res.data || "Fail to process titling payment! please try again.")
      return res.data;
    } catch (e) {
      throw e;
    }
  }
  public async getDocumentServiceQuotesAsync(
    payload: DocumentServiceQuotesRequest,
    quotesId: string,
    listingId: string
  ): Promise<DocumentServiceQuotesResponse[] | undefined> {
    try {
      const res = await http.post(
        `Tiling/GetDocumentServiceQuotes?quoteId=${quotesId}&listingId=${listingId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (e) {
      // return [];
      throw e;
    }
  }
  public async getDocumentServiceQuotesIdAsync(
    quotesId: string
  ): Promise<DocumentServiceQuotesResponse[] | undefined> {
    try {
      const res = await http.post(
        `Tiling/GetDocumentServiceByQuoteId?quoteId=${quotesId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (e) {
      // return [];
      console.log(e);
    }
  }
}

export const tilingEndpoint = new TilingEndpoint();
