import { Typography } from "components";
import BuyingNewCar from "./assets/buying-new-car.png";
import DealerExplainingPreloan from "./assets/car-dealer-explaining-pre-loan.png";

import { Button } from "@nextui-org/react";
import { WhiteCheckTick } from "../traditional-financing/assets/white-check-tick.icon";
import { useNavigate } from "react-router-dom";
import SellYourCarNationwide from "./components/cards.component";
import { cardsDataForNationSell } from "./list-your-car-for-sale.data";

function ListYourCarForSale() {
  const navigate = useNavigate();

  const handleCreateAccount = () => {
    navigate("/login/user");
  };
  return (
    <div className="flex flex-col gap-16 py-12 ">
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        <Typography variant="pageTitle">
          Sell Your Car Nationwide with Ease - Secure Payments & Hassle-Free
          Shipping!!
        </Typography>
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[rgb(7,12,132)] text-transparent bg-clip-text">
          Reach Unlimited Potential Buyers Across the USA
        </h2>
        <p className="font-[Metropolis] font-[600] text-left">
          Thinking of selling your car privately but want a wider audience and a
          secure transaction process? findmyoptions.com is the answer! We
          connect you with a nationwide network of serious car buyers,
          maximizing your chances of a quick and profitable sale, all while
          ensuring a smooth and secure experience.
        </p>
      </div>
      <div className="w-full  min-h-[30rem] xl:min-h-[48rem] 3xl:min-h-[38rem]  mt-12 mb-24 3xl:mb-8">
        <div className=" flex flex-col absolute w-full  min-h-[30rem] xl:min-h-[48rem] 3xl:min-h-[38rem]   right-0 from-[#018ffd] -z-10 to-[#070c84] text-white bg-gradient-to-r">

        <h2 className="text-lg text-center p-2 mx-auto mt-4  md:mt-6 sm:text-2xl ">
        Here's what makes us the perfect platform to sell your car anywhere in the USA:
        </h2>
        <div className="my-auto 3xl:my-16  flex ">
          <div className="w-full left-0 sm:px-6 md:px-8 lg:px-[60px] xl:px-0 xl:left-[150px] 2xl:left-[250px] pl-4   
             xl:w-1/2 py-4 flex flex-col relative     items-center justify-center text-center ">
            
            {cardsDataForNationSell.map(({id,title,description})=>{
              return(<div key={id} className="w-full ">
                <div className="flex items-start text-left    relative">
                  <WhiteCheckTick
                    height={16}
                    width={16}
                    className="absolute top-1"
                  />
  
                  <Typography
                    variant="custom"
                    className={`px-6  text-white font-light text-base md:text-xl`}
                  >
                    <span className="font-semibold text-white bg-clip-text">
                      {title}
                    </span>
                    <span className="font-[300] text-sm md:text-lg ">
                      {description}
                    </span>
                  </Typography>
                </div>
  
  
              </div>)
            })}
          </div>
          <img
            alt="dealer-explaining"
            src={BuyingNewCar}
            className="w-1/2 hidden xl:block  right-0  absolute   max-w-[32rem] "
            />
            
        </div>
        </div>
      </div>
      
      <SellYourCarNationwide />
      <div>
        <div className="flex flex-col items-center justify-center gap-4 text-center text-lg font-[Metropolis]">
          <Typography variant="pageTitle" className="text-left">
            Sell Your Car with Confidence and Flexibility!
          </Typography>
        </div>
        <div className="flex mt-6 items-center justify-center  ">
          <Button
            onClick={handleCreateAccount}
            className="text-center  text-xs sm:text-lg md:text-xl font-[700] bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text border-[#070C84]  border-2"
          >
            Create an Account
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ListYourCarForSale;
