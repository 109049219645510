import ContactUsCard from "./card.component";
import { cards } from "../bhphf.data";

const WhatNeedForBHPHFCard = () => {

  return (
    <div >
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
        Ready to Get Started?
        </h2>
        <p className="font-[Metropolis] font-[600] text-left">
        Visit one of our BHPH dealerships today! They can help you find the perfect car, and their finance department will guide you through the BHPH financing process quickly and efficiently.
        </p>
      </div>
      
      <p className="pb-5 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl 2xl:text-3xl font-bold pt-5 text-left">
        Here's What You'll Need:
        </p>
      <div className="flex flex-col lg:flex-row gap-8">
       
      {cards.map((card) => (
        <ContactUsCard card={card} />
      ))}
      </div>
      
    </div>
  );
};

export default WhatNeedForBHPHFCard;
