import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ShippingState {
  carData: { name: string; vin: string; listingId: number, year:string,make: string,model:string } | null;
}

const initialState: ShippingState = {
  carData: null,
};

export const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    changeCarForShipping: (
      state,
      action: PayloadAction<{ name: string; vin: string; listingId: number,year:string, make: string,model:string }>
    ) => {
      state.carData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCarForShipping } = shippingSlice.actions;

export default shippingSlice.reducer;
