import { SVGProps } from "react";

export const UserIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M15.0781 5.02474C13.9118 5.02474 12.7932 5.48808 11.9684 6.31282C11.1437 7.13757 10.6803 8.25616 10.6803 9.42253C10.6803 10.5889 11.1437 11.7075 11.9684 12.5322C12.7932 13.357 13.9118 13.8203 15.0781 13.8203C16.2445 13.8203 17.3631 13.357 18.1878 12.5322C19.0126 11.7075 19.4759 10.5889 19.4759 9.42253C19.4759 8.25616 19.0126 7.13757 18.1878 6.31282C17.3631 5.48808 16.2445 5.02474 15.0781 5.02474ZM8.16732 9.42253C8.16732 7.58967 8.89542 5.83188 10.1914 4.53585C11.4875 3.23982 13.2453 2.51172 15.0781 2.51172C16.911 2.51172 18.6688 3.23982 19.9648 4.53585C21.2608 5.83188 21.9889 7.58967 21.9889 9.42253C21.9889 11.2554 21.2608 13.0132 19.9648 14.3092C18.6688 15.6052 16.911 16.3333 15.0781 16.3333C13.2453 16.3333 11.4875 15.6052 10.1914 14.3092C8.89542 13.0132 8.16732 11.2554 8.16732 9.42253ZM3.76953 23.8724C3.76953 22.2062 4.43144 20.6082 5.60965 19.43C6.78786 18.2518 8.38585 17.5898 10.0521 17.5898H20.1042C21.7704 17.5898 23.3684 18.2518 24.5466 19.43C25.7248 20.6082 26.3867 22.2062 26.3867 23.8724V27.6419H3.76953V23.8724ZM10.0521 20.1029C9.05234 20.1029 8.09355 20.5 7.38662 21.2069C6.6797 21.9139 6.28255 22.8727 6.28255 23.8724V25.1289H23.8737V23.8724C23.8737 22.8727 23.4766 21.9139 22.7696 21.2069C22.0627 20.5 21.1039 20.1029 20.1042 20.1029H10.0521Z"
      fill="#313131"
    />
  </svg>
);
