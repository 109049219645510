import React, { SVGProps } from "react";

export const Warrentystroke = ({ ...props }: SVGProps<SVGSVGElement>) => {

  return (
    <svg
      width="643"
      height="302"
      viewBox="0 0 643 302"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      
      {...props}
    >
      <g
        // className="cursor-pointer hover:scale-125 transition-transform duration-800 ease-in-out"
      >
      <path
        d="M206.238 4.71062H197.79V8.01461H205.44V10.9638H197.79V16.9953H194.508V1.76142H206.238V4.71062Z"
        fill="white"
      />
      <path d="M208.473 16.9953V1.49532H211.71V16.9953H208.473Z" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M219.659 5.26498C222.652 5.26498 224.692 7.14981 224.692 10.1434V16.9953H221.566V15.8422C220.745 16.7735 219.437 17.2614 218.195 17.2614C215.756 17.2614 213.871 15.7978 213.871 13.6026C213.871 11.3408 215.978 9.78857 218.639 9.78857C219.57 9.78857 220.59 9.98814 221.566 10.3651V10.1434C221.566 8.92376 220.812 7.85939 218.927 7.85939C217.862 7.85939 216.865 8.21418 215.955 8.67984L214.869 6.52892C216.488 5.70847 218.062 5.26498 219.659 5.26498ZM218.927 15.0217C220.169 15.0217 221.344 14.4452 221.566 13.3808V12.3165C220.834 12.0504 219.991 11.8951 219.104 11.8951C217.929 11.8951 216.998 12.5825 216.998 13.5139C216.998 14.4452 217.818 15.0217 218.927 15.0217Z"
        fill="white"
      />
      <path
        d="M233.452 14.1569L234.228 16.2635C233.563 16.8622 232.521 17.2614 231.59 17.2614C229.572 17.2614 228.153 15.82 228.153 13.7578V8.14766H226.578V5.53107H228.153V2.3823H231.257V5.53107H233.741V8.14766H231.257V13.2478C231.257 14.0017 231.745 14.5339 232.344 14.5339C232.787 14.5339 233.231 14.3787 233.452 14.1569Z"
        fill="white"
      />
      <path
        d="M246.485 16.9953V4.71062H241.74V1.76142H254.512V4.71062H249.767V16.9953H246.485Z"
        fill="white"
      />
      <path
        d="M258.23 3.95669C257.232 3.95669 256.456 3.15841 256.456 2.18273C256.456 1.27358 257.232 0.453125 258.23 0.453125C259.184 0.453125 259.96 1.27358 259.96 2.18273C259.96 3.15841 259.184 3.95669 258.23 3.95669Z"
        fill="white"
      />
      <path
        d="M256.589 16.9953V5.53107H259.827V16.9953H256.589Z"
        fill="white"
      />
      <path
        d="M265.891 7.65982C266.644 6.17413 268.064 5.26498 269.815 5.26498V7.99244C267.421 7.99244 265.891 9.36725 265.891 11.5182V16.9953H262.653V5.53107H265.891V7.65982Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M277.094 14.4009C278.003 14.4009 279.112 13.9795 279.732 13.3808L281.795 15.4874C280.775 16.5518 278.801 17.2614 277.116 17.2614C273.701 17.2614 271.04 14.7556 271.04 11.2299C271.04 7.77069 273.59 5.26498 276.938 5.26498C280.531 5.26498 282.637 7.99244 282.637 12.3165H274.366C274.743 13.5582 275.741 14.4009 277.094 14.4009ZM277.071 8.12548C275.852 8.12548 274.832 8.83506 274.411 9.98814H279.533C279.222 8.85724 278.38 8.12548 277.071 8.12548Z"
        fill="white"
      />
      <path
        d="M200.273 44.2614C197.634 44.2614 195.284 43.3744 193.51 41.7335L195.395 39.4939C196.836 40.7356 198.477 41.4895 200.406 41.4895C202.047 41.4895 202.89 40.7356 202.89 39.7821C202.89 38.6956 202.003 38.363 199.674 37.8308C196.459 37.099 194.175 36.1899 194.175 33.2407C194.175 30.4245 196.481 28.4953 199.807 28.4953C202.291 28.4953 204.22 29.2714 205.772 30.6019L204.065 32.9524C202.712 31.8658 201.182 31.2671 199.719 31.2671C198.322 31.2671 197.479 31.9989 197.479 32.908C197.479 34.0168 198.388 34.3716 200.739 34.9037C204.021 35.6355 206.194 36.6112 206.194 39.4273C206.194 42.3322 203.998 44.2614 200.273 44.2614Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M213.862 41.4009C214.771 41.4009 215.88 40.9795 216.501 40.3808L218.563 42.4874C217.543 43.5518 215.569 44.2614 213.884 44.2614C210.469 44.2614 207.808 41.7556 207.808 38.2299C207.808 34.7707 210.358 32.265 213.707 32.265C217.299 32.265 219.405 34.9924 219.405 39.3165H211.134C211.511 40.5582 212.509 41.4009 213.862 41.4009ZM213.84 35.1255C212.62 35.1255 211.6 35.8351 211.179 36.9881H216.301C215.991 35.8572 215.148 35.1255 213.84 35.1255Z"
        fill="white"
      />
      <path
        d="M224.833 34.6598C225.587 33.1741 227.006 32.265 228.758 32.265V34.9924C226.363 34.9924 224.833 36.3673 224.833 38.5182V43.9953H221.596V32.5311H224.833V34.6598Z"
        fill="white"
      />
      <path
        d="M234.462 43.9953L229.495 32.5311H232.888L235.97 40.27L238.985 32.5311H242.356L237.455 43.9953H234.462Z"
        fill="white"
      />
      <path
        d="M245.692 30.9567C244.694 30.9567 243.918 30.1584 243.918 29.1827C243.918 28.2736 244.694 27.4531 245.692 27.4531C246.646 27.4531 247.422 28.2736 247.422 29.1827C247.422 30.1584 246.646 30.9567 245.692 30.9567Z"
        fill="white"
      />
      <path
        d="M244.051 43.9953V32.5311H247.289V43.9953H244.051Z"
        fill="white"
      />
      <path
        d="M255.725 44.2614C252.332 44.2614 249.472 41.5117 249.472 38.2521C249.472 34.9924 252.355 32.265 255.747 32.265C257.61 32.265 259.14 33.0189 260.249 34.2163L258.098 36.0568C257.521 35.4137 256.612 34.9924 255.725 34.9924C254.04 34.9924 252.621 36.4781 252.621 38.2299C252.621 40.026 254.04 41.5339 255.747 41.5339C256.634 41.5339 257.566 41.0904 258.164 40.4252L260.315 42.2435C259.184 43.4852 257.632 44.2614 255.725 44.2614Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M267.674 41.4009C268.583 41.4009 269.692 40.9795 270.313 40.3808L272.375 42.4874C271.355 43.5518 269.381 44.2614 267.696 44.2614C264.281 44.2614 261.62 41.7556 261.62 38.2299C261.62 34.7707 264.17 32.265 267.519 32.265C271.111 32.265 273.217 34.9924 273.217 39.3165H264.946C265.323 40.5582 266.321 41.4009 267.674 41.4009ZM267.652 35.1255C266.432 35.1255 265.412 35.8351 264.991 36.9881H270.113C269.803 35.8572 268.96 35.1255 267.652 35.1255Z"
        fill="white"
      />
      </g>
      <path
        d="M217.423 64.5977C216.967 63.2875 216.215 62.0573 215.169 61.0106C211.46 57.3019 205.447 57.3019 201.738 61.0106C200.058 62.6909 199.139 64.8443 198.981 67.042L198.732 67.0819C148.02 75.2009 99.7201 94.2449 57.1596 122.85C53.4378 120.028 48.1106 120.314 44.715 123.71C41.0063 127.418 41.0063 133.431 44.715 137.14C48.4237 140.849 54.4366 140.849 58.1453 137.14C61.1182 134.167 61.708 129.714 59.9146 126.153C101.871 98.0313 149.455 79.3037 199.408 71.3063L199.64 71.2691C200.104 72.4237 200.803 73.5056 201.738 74.4409C205.447 78.1496 211.46 78.1496 215.169 74.4409C216.736 72.8733 217.641 70.8939 217.884 68.8509C265.211 63.8403 313.131 68.5312 358.697 82.7125C359.864 83.0755 361.028 83.4446 362.19 83.8196C361.977 86.4959 362.894 89.2452 364.942 91.2924C368.65 95.0011 374.663 95.0011 378.372 91.2924C378.802 90.8624 379.182 90.4015 379.512 89.9167C417.282 104.336 452.279 125.197 482.939 151.58C480.115 155.301 480.401 160.63 483.797 164.027C487.411 167.641 493.215 167.733 496.94 164.302C532.503 198.417 560.424 239.627 578.923 285.204C578.849 285.273 578.776 285.344 578.703 285.417C574.995 289.126 574.995 295.138 578.703 298.847C582.412 302.556 588.425 302.556 592.134 298.847C595.842 295.138 595.842 289.126 592.134 285.417C589.57 282.853 585.906 282.062 582.663 283.042C563.845 236.905 535.482 195.207 499.38 160.716C500.681 157.322 499.963 153.332 497.227 150.597C494.265 147.634 489.831 147.038 486.277 148.809C455.117 121.885 419.507 100.608 381.057 85.9273C381.466 83.0692 380.57 80.0608 378.372 77.8621C374.663 74.1535 368.65 74.1535 364.942 77.8621C364.365 78.4385 363.878 79.0705 363.481 79.7409C362.313 79.3638 361.142 78.9928 359.969 78.6277C313.842 64.2719 265.332 59.524 217.423 64.5977Z"
        fill="white"
      />
      <g
        // className="cursor-pointer hover:scale-125 hover:-translate-y-10 transition-transform duration-800 ease-in-out"
      >
        <path
          className="relative"
          d="M0.407747 165.334V167.995H11.9163V165.046H5.46352L8.23533 162.895C10.8963 160.966 11.7832 159.392 11.7832 157.352C11.7832 154.491 9.43275 152.495 6.19528 152.495C3.73391 152.495 1.80474 153.56 0.296875 155.467L2.38127 157.485C3.49 156.221 4.62089 155.467 6.10658 155.467C7.34835 155.467 8.41272 156.221 8.41272 157.485C8.41272 158.549 7.83619 159.547 6.01788 160.988L0.407747 165.334Z"
          fill="white"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.2812 164.891V167.995H24.563V164.891H26.5809V162.141H24.563V152.784H20.5938L13.5423 162.363L13.8527 164.891H21.2812ZM21.2812 162.141H16.9793L21.2812 156.243V162.141Z"
          fill="white"
        />
        <path
          d="M34.4847 151.52L26.8345 169.636H30.2494L37.8995 151.52H34.4847Z"
          fill="white"
        />
        <path
          d="M46.1909 155.711L40.1372 167.995H43.8404L49.9827 155.223V152.761H38.6516V155.711H46.1909Z"
          fill="white"
        />
        <path
          d="M62.6402 155.711V167.995H65.922V155.711H70.6673V152.761H57.8948V155.711H62.6402Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M72.1235 162.252C72.1235 165.512 74.984 168.261 78.3989 168.261C81.7916 168.261 84.6299 165.512 84.6299 162.252C84.6299 158.992 81.7916 156.265 78.3989 156.265C74.984 156.265 72.1235 158.992 72.1235 162.252ZM81.5477 162.252C81.5477 164.026 80.1063 165.534 78.3989 165.534C76.6693 165.534 75.228 164.026 75.228 162.252C75.228 160.478 76.6693 158.992 78.3989 158.992C80.1063 158.992 81.5477 160.478 81.5477 162.252Z"
          fill="white"
        />
        <path
          d="M85.2591 156.531L89.1618 167.995H92.0667L94.2176 160.789L96.3464 167.995H99.2512L103.154 156.531H99.983L97.8099 163.671L95.5481 156.531H92.865L90.6032 163.671L88.4301 156.531H85.2591Z"
          fill="white"
        />
        <path
          d="M104.769 153.183C104.769 154.158 105.545 154.957 106.543 154.957C107.496 154.957 108.272 154.158 108.272 153.183C108.272 152.274 107.496 151.453 106.543 151.453C105.545 151.453 104.769 152.274 104.769 153.183Z"
          fill="white"
        />
        <path
          d="M104.902 156.531V167.995H108.139V156.531H104.902Z"
          fill="white"
        />
        <path
          d="M121.853 160.7C121.853 158.105 120.079 156.265 117.596 156.265C116.11 156.265 114.824 156.93 114.136 157.972V156.531H110.899V167.995H114.136V161.143L114.159 161.21C114.159 159.968 115.201 158.992 116.554 158.992C117.84 158.992 118.727 159.968 118.727 161.387V167.995H121.853V160.7Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M136.139 156.531H132.88V158.083C132.104 156.997 130.64 156.265 129.088 156.265C126.205 156.265 124.01 158.505 124.01 161.698C124.01 164.891 126.205 167.153 129.088 167.153C130.596 167.153 131.97 166.487 132.88 165.379V166.643C132.88 168.128 131.549 169.082 129.465 169.082C128.134 169.082 126.804 168.661 126.05 167.995L124.919 170.279C126.05 171.166 127.957 171.809 129.975 171.809C133.589 171.809 136.139 169.725 136.139 166.776V156.531ZM132.88 161.698C132.88 163.294 131.727 164.425 130.108 164.425C128.489 164.425 127.336 163.294 127.336 161.698C127.336 160.101 128.489 158.992 130.108 158.992C131.727 158.992 132.88 160.101 132.88 161.698Z"
          fill="white"
        />
      </g>
      <g
        // className="cursor-pointer hover:scale-125  hover:-translate-x-10 transition-transform duration-800 ease-in-out"
      >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M264.641 88.7614V103.995H272.646C275.551 103.995 277.635 102.31 277.635 99.8708C277.635 98.1856 276.615 96.7664 274.841 95.9681C276.194 95.2585 276.97 94.1276 276.97 92.5754C276.97 90.358 275.085 88.7614 272.357 88.7614H264.641ZM271.537 94.9037H267.9V91.5554H271.537C272.757 91.5554 273.621 92.265 273.621 93.2185C273.621 94.1942 272.757 94.9037 271.537 94.9037ZM272.047 101.201H267.9V97.476H272.047C273.355 97.476 274.287 98.2521 274.287 99.3386C274.287 100.425 273.355 101.201 272.047 101.201Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M290.08 97.1434C290.08 94.1498 288.04 92.265 285.046 92.265C283.45 92.265 281.875 92.7085 280.256 93.5289L281.343 95.6798C282.252 95.2142 283.25 94.8594 284.314 94.8594C286.199 94.8594 286.953 95.9238 286.953 97.1434V97.3651C285.977 96.9881 284.957 96.7886 284.026 96.7886C281.365 96.7886 279.259 98.3408 279.259 100.603C279.259 102.798 281.143 104.261 283.583 104.261C284.824 104.261 286.133 103.774 286.953 102.842V103.995H290.08V97.1434ZM286.953 100.381C286.731 101.445 285.556 102.022 284.314 102.022C283.206 102.022 282.385 101.445 282.385 100.514C282.385 99.5826 283.316 98.8951 284.492 98.8951C285.379 98.8951 286.221 99.0504 286.953 99.3165V100.381Z"
        fill="white"
      />
      <path
        d="M299.616 103.264L298.84 101.157C298.618 101.379 298.175 101.534 297.731 101.534C297.132 101.534 296.645 101.002 296.645 100.248V95.1477H299.128V92.5311H296.645V89.3823H293.54V92.5311H291.966V95.1477H293.54V100.758C293.54 102.82 294.959 104.261 296.977 104.261C297.908 104.261 298.951 103.862 299.616 103.264Z"
        fill="white"
      />
      <path
        d="M308.451 103.264L307.675 101.157C307.453 101.379 307.01 101.534 306.566 101.534C305.968 101.534 305.48 101.002 305.48 100.248V95.1477H307.963V92.5311H305.48V89.3823H302.375V92.5311H300.801V95.1477H302.375V100.758C302.375 102.82 303.794 104.261 305.812 104.261C306.744 104.261 307.786 103.862 308.451 103.264Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M318.528 100.381C317.907 100.98 316.798 101.401 315.889 101.401C314.537 101.401 313.539 100.558 313.162 99.3165H321.433C321.433 94.9924 319.326 92.265 315.734 92.265C312.386 92.265 309.836 94.7707 309.836 98.2299C309.836 101.756 312.496 104.261 315.911 104.261C317.597 104.261 319.57 103.552 320.59 102.487L318.528 100.381ZM313.206 96.9881C313.627 95.8351 314.647 95.1255 315.867 95.1255C317.175 95.1255 318.018 95.8572 318.328 96.9881H313.206Z"
        fill="white"
      />
      <path
        d="M330.785 92.265C329.034 92.265 327.614 93.1741 326.861 94.6598V92.5311H323.623V103.995H326.861V98.5182C326.861 96.3673 328.391 94.9924 330.785 94.9924V92.265Z"
        fill="white"
      />
      <path
        d="M332.387 107.277C333.163 107.698 334.316 107.987 335.27 107.987C337.066 107.987 338.529 106.811 339.394 104.572L344.095 92.5311H340.791L337.931 100.58L334.915 92.5311H331.567L336.334 104.106L335.935 104.949C335.691 105.37 335.203 105.57 334.582 105.57C334.205 105.57 333.629 105.37 333.074 105.06L332.387 107.277Z"
        fill="white"
      />
      <path
        d="M263.266 128.733C264.286 130.219 266.415 131.261 268.366 131.261C271.448 131.261 273.621 129.088 273.621 126.028V115.761H270.34V125.762C270.34 127.248 269.364 128.29 268.033 128.29C267.013 128.29 265.927 127.603 265.439 126.605L263.266 128.733Z"
        fill="white"
      />
      <path
        d="M287.346 119.531H284.108V126.383L284.086 126.316C284.086 127.558 283.044 128.534 281.691 128.534C280.405 128.534 279.518 127.558 279.518 126.139V119.531H276.391V126.826C276.391 129.421 278.165 131.261 280.649 131.261C282.135 131.261 283.421 130.596 284.108 129.554V130.995H287.346V119.531Z"
        fill="white"
      />
      <path
        d="M307.996 123.7C307.996 121.105 306.155 119.265 303.76 119.265C302.053 119.265 300.634 120.085 300.035 121.349C299.326 120.063 297.973 119.265 296.399 119.265C294.979 119.265 293.848 119.952 293.294 121.039V119.531H290.057V130.995H293.294V124.077C293.294 122.857 294.203 121.992 295.512 121.992C296.687 121.992 297.529 122.924 297.529 124.254V131.017H300.634V124.077C300.634 122.857 301.565 121.992 302.851 121.992C304.049 121.992 304.891 122.924 304.891 124.254V131.017H307.996V123.7Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M323.068 125.252C323.068 121.749 320.851 119.265 317.79 119.265C316.194 119.265 314.863 119.952 313.954 121.128V119.531H310.717V134.809H313.954V129.377C314.863 130.574 316.194 131.261 317.79 131.261C320.851 131.261 323.068 128.778 323.068 125.252ZM319.919 125.252C319.919 127.181 318.655 128.534 316.859 128.534C315.285 128.534 314.132 127.381 313.954 125.696V124.809C314.132 123.146 315.285 121.992 316.859 121.992C318.655 121.992 319.919 123.345 319.919 125.252Z"
        fill="white"
      />
      <path
        d="M330.64 128.733C332.413 130.374 334.764 131.261 337.403 131.261C341.128 131.261 343.323 129.332 343.323 126.427C343.323 123.611 341.15 122.635 337.868 121.904C335.518 121.372 334.609 121.017 334.609 119.908C334.609 118.999 335.451 118.267 336.848 118.267C338.312 118.267 339.842 118.866 341.195 119.952L342.902 117.602C341.35 116.271 339.421 115.495 336.937 115.495C333.611 115.495 331.305 117.424 331.305 120.241C331.305 123.19 333.589 124.099 336.804 124.831C339.132 125.363 340.019 125.696 340.019 126.782C340.019 127.736 339.177 128.49 337.536 128.49C335.607 128.49 333.966 127.736 332.524 126.494L330.64 128.733Z"
        fill="white"
      />
      <path
        d="M352.389 130.264L351.612 128.157C351.391 128.379 350.947 128.534 350.504 128.534C349.905 128.534 349.417 128.002 349.417 127.248V122.148H351.901V119.531H349.417V116.382H346.313V119.531H344.738V122.148H346.313V127.758C346.313 129.82 347.732 131.261 349.75 131.261C350.681 131.261 351.723 130.862 352.389 130.264Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M364.572 124.143C364.572 121.15 362.532 119.265 359.538 119.265C357.942 119.265 356.367 119.708 354.749 120.529L355.835 122.68C356.744 122.214 357.742 121.859 358.807 121.859C360.691 121.859 361.445 122.924 361.445 124.143V124.365C360.47 123.988 359.45 123.789 358.518 123.789C355.857 123.789 353.751 125.341 353.751 127.603C353.751 129.798 355.636 131.261 358.075 131.261C359.317 131.261 360.625 130.774 361.445 129.842V130.995H364.572V124.143ZM361.445 127.381C361.224 128.445 360.048 129.022 358.807 129.022C357.698 129.022 356.877 128.445 356.877 127.514C356.877 126.583 357.809 125.895 358.984 125.895C359.871 125.895 360.714 126.05 361.445 126.316V127.381Z"
        fill="white"
      />
      <path
        d="M374.463 119.265C372.711 119.265 371.292 120.174 370.538 121.66V119.531H367.301V130.995H370.538V125.518C370.538 123.367 372.068 121.992 374.463 121.992V119.265Z"
        fill="white"
      />
      <path
        d="M383.138 130.264L382.362 128.157C382.14 128.379 381.697 128.534 381.253 128.534C380.655 128.534 380.167 128.002 380.167 127.248V122.148H382.65V119.531H380.167V116.382H377.062V119.531H375.488V122.148H377.062V127.758C377.062 129.82 378.482 131.261 380.499 131.261C381.431 131.261 382.473 130.862 383.138 130.264Z"
        fill="white"
      />
      </g>
      
      <g
        // className="cursor-pointer hover:scale-125  hover:-translate-x-40  hover:-translate-y-12   transition-transform duration-800 ease-in-out"
      >
      <path
        d="M513.238 100.711H504.79V104.015H512.44V106.964H504.79V112.995H501.508V97.7614H513.238V100.711Z"
        fill="white"
      />
      <path
        d="M523.079 101.531H526.316V112.995H523.079V111.554C522.391 112.596 521.105 113.261 519.62 113.261C517.136 113.261 515.362 111.421 515.362 108.826V101.531H518.489V108.139C518.489 109.558 519.376 110.534 520.662 110.534C522.014 110.534 523.057 109.558 523.057 108.316L523.079 108.383V101.531Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M534.504 110.401C535.413 110.401 536.522 109.98 537.143 109.381L539.205 111.487C538.185 112.552 536.212 113.261 534.526 113.261C531.112 113.261 528.451 110.756 528.451 107.23C528.451 103.771 531.001 101.265 534.349 101.265C537.941 101.265 540.048 103.992 540.048 108.316H531.777C532.154 109.558 533.152 110.401 534.504 110.401ZM534.482 104.125C533.263 104.125 532.243 104.835 531.821 105.988H536.944C536.633 104.857 535.79 104.125 534.482 104.125Z"
        fill="white"
      />
      <path
        d="M542.238 112.995V97.4953H545.476V112.995H542.238Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M554.716 112.995V97.7614H560.171C565.294 97.7614 568.93 100.91 568.93 105.367C568.93 109.824 565.294 112.995 560.171 112.995H554.716ZM557.976 110.024H560.593C563.498 110.024 565.56 108.095 565.56 105.367C565.56 102.662 563.498 100.733 560.593 100.733H557.976V110.024Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M576.774 110.401C577.684 110.401 578.792 109.98 579.413 109.381L581.475 111.487C580.455 112.552 578.482 113.261 576.797 113.261C573.382 113.261 570.721 110.756 570.721 107.23C570.721 103.771 573.271 101.265 576.619 101.265C580.211 101.265 582.318 103.992 582.318 108.316H574.047C574.424 109.558 575.422 110.401 576.774 110.401ZM576.752 104.125C575.533 104.125 574.513 104.835 574.091 105.988H579.214C578.903 104.857 578.06 104.125 576.752 104.125Z"
        fill="white"
      />
      <path
        d="M584.508 112.995V97.4953H587.746V112.995H584.508Z"
        fill="white"
      />
      <path
        d="M592.19 99.9567C591.192 99.9567 590.416 99.1584 590.416 98.1827C590.416 97.2736 591.192 96.4531 592.19 96.4531C593.144 96.4531 593.92 97.2736 593.92 98.1827C593.92 99.1584 593.144 99.9567 592.19 99.9567Z"
        fill="white"
      />
      <path
        d="M590.549 112.995V101.531H593.787V112.995H590.549Z"
        fill="white"
      />
      <path
        d="M600.449 112.995L595.482 101.531H598.875L601.957 109.27L604.973 101.531H608.344L603.443 112.995H600.449Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M614.757 110.401C615.666 110.401 616.775 109.98 617.396 109.381L619.458 111.487C618.438 112.552 616.464 113.261 614.779 113.261C611.364 113.261 608.703 110.756 608.703 107.23C608.703 103.771 611.253 101.265 614.602 101.265C618.194 101.265 620.3 103.992 620.3 108.316H612.029C612.406 109.558 613.404 110.401 614.757 110.401ZM614.735 104.125C613.515 104.125 612.495 104.835 612.074 105.988H617.196C616.885 104.857 616.043 104.125 614.735 104.125Z"
        fill="white"
      />
      <path
        d="M625.728 103.66C626.482 102.174 627.901 101.265 629.653 101.265V103.992C627.258 103.992 625.728 105.367 625.728 107.518V112.995H622.491V101.531H625.728V103.66Z"
        fill="white"
      />
      <path
        d="M634.137 116.987C633.184 116.987 632.031 116.698 631.255 116.277L631.942 114.06C632.496 114.37 633.073 114.57 633.45 114.57C634.071 114.57 634.559 114.37 634.802 113.949L635.202 113.106L630.434 101.531H633.782L636.798 109.58L639.659 101.531H642.963L638.262 113.572C637.397 115.811 635.933 116.987 634.137 116.987Z"
        fill="white"
      />
      <path
        d="M507.273 140.261C504.634 140.261 502.284 139.374 500.51 137.733L502.395 135.494C503.836 136.736 505.477 137.49 507.406 137.49C509.047 137.49 509.89 136.736 509.89 135.782C509.89 134.696 509.003 134.363 506.674 133.831C503.459 133.099 501.175 132.19 501.175 129.241C501.175 126.424 503.481 124.495 506.807 124.495C509.291 124.495 511.22 125.271 512.772 126.602L511.065 128.952C509.712 127.866 508.182 127.267 506.719 127.267C505.322 127.267 504.479 127.999 504.479 128.908C504.479 130.017 505.388 130.372 507.739 130.904C511.021 131.635 513.194 132.611 513.194 135.427C513.194 138.332 510.998 140.261 507.273 140.261Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M520.862 137.401C521.771 137.401 522.88 136.98 523.501 136.381L525.563 138.487C524.543 139.552 522.569 140.261 520.884 140.261C517.469 140.261 514.808 137.756 514.808 134.23C514.808 130.771 517.358 128.265 520.707 128.265C524.299 128.265 526.405 130.992 526.405 135.316H518.134C518.511 136.558 519.509 137.401 520.862 137.401ZM520.84 131.125C519.62 131.125 518.6 131.835 518.179 132.988H523.301C522.991 131.857 522.148 131.125 520.84 131.125Z"
        fill="white"
      />
      <path
        d="M531.833 130.66C532.587 129.174 534.006 128.265 535.758 128.265V130.992C533.363 130.992 531.833 132.367 531.833 134.518V139.995H528.596V128.531H531.833V130.66Z"
        fill="white"
      />
      <path
        d="M541.462 139.995L536.495 128.531H539.888L542.97 136.27L545.985 128.531H549.356L544.455 139.995H541.462Z"
        fill="white"
      />
      <path
        d="M552.692 126.957C551.694 126.957 550.918 126.158 550.918 125.183C550.918 124.274 551.694 123.453 552.692 123.453C553.646 123.453 554.422 124.274 554.422 125.183C554.422 126.158 553.646 126.957 552.692 126.957Z"
        fill="white"
      />
      <path
        d="M551.051 139.995V128.531H554.289V139.995H551.051Z"
        fill="white"
      />
      <path
        d="M562.725 140.261C559.332 140.261 556.472 137.512 556.472 134.252C556.472 130.992 559.355 128.265 562.747 128.265C564.61 128.265 566.14 129.019 567.249 130.216L565.098 132.057C564.521 131.414 563.612 130.992 562.725 130.992C561.04 130.992 559.621 132.478 559.621 134.23C559.621 136.026 561.04 137.534 562.747 137.534C563.634 137.534 564.566 137.09 565.164 136.425L567.315 138.243C566.184 139.485 564.632 140.261 562.725 140.261Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M574.674 137.401C575.583 137.401 576.692 136.98 577.313 136.381L579.375 138.487C578.355 139.552 576.381 140.261 574.696 140.261C571.281 140.261 568.62 137.756 568.62 134.23C568.62 130.771 571.17 128.265 574.519 128.265C578.111 128.265 580.217 130.992 580.217 135.316H571.946C572.323 136.558 573.321 137.401 574.674 137.401ZM574.652 131.125C573.432 131.125 572.412 131.835 571.991 132.988H577.113C576.803 131.857 575.96 131.125 574.652 131.125Z"
        fill="white"
      />
      </g>
      <g
        // className="cursor-pointer hover:scale-125  hover:-translate-x-40  hover:-translate-y-12   transition-transform duration-800 ease-in-out"
      >
      <path
        d="M456.262 263.046H448.679V250.761H445.397V265.995H456.262V263.046Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M457.639 260.252C457.639 263.512 460.499 266.261 463.914 266.261C467.307 266.261 470.145 263.512 470.145 260.252C470.145 256.992 467.307 254.265 463.914 254.265C460.499 254.265 457.639 256.992 457.639 260.252ZM467.063 260.252C467.063 262.026 465.622 263.534 463.914 263.534C462.185 263.534 460.743 262.026 460.743 260.252C460.743 258.478 462.185 256.992 463.914 256.992C465.622 256.992 467.063 258.478 467.063 260.252Z"
        fill="white"
      />
      <path
        d="M471.693 260.252C471.693 263.512 474.553 266.261 477.946 266.261C479.853 266.261 481.405 265.485 482.536 264.243L480.385 262.425C479.786 263.09 478.855 263.534 477.968 263.534C476.261 263.534 474.842 262.026 474.842 260.23C474.842 258.478 476.261 256.992 477.946 256.992C478.833 256.992 479.742 257.414 480.319 258.057L482.47 256.216C481.361 255.019 479.831 254.265 477.968 254.265C474.575 254.265 471.693 256.992 471.693 260.252Z"
        fill="white"
      />
      <path
        d="M491.181 259.609L495.638 254.531H491.913L487.655 259.476V250.495H484.418V265.995H487.655V263.179L489.03 261.693L491.868 265.995H495.594L491.181 259.609Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M496.509 260.252C496.509 263.512 499.37 266.261 502.784 266.261C506.177 266.261 509.016 263.512 509.016 260.252C509.016 256.992 506.177 254.265 502.784 254.265C499.37 254.265 496.509 256.992 496.509 260.252ZM505.933 260.252C505.933 262.026 504.492 263.534 502.784 263.534C501.055 263.534 499.614 262.026 499.614 260.252C499.614 258.478 501.055 256.992 502.784 256.992C504.492 256.992 505.933 258.478 505.933 260.252Z"
        fill="white"
      />
      <path
        d="M522.049 254.531H518.812V261.383L518.79 261.316C518.79 262.558 517.748 263.534 516.395 263.534C515.109 263.534 514.222 262.558 514.222 261.139V254.531H511.095V261.826C511.095 264.421 512.869 266.261 515.353 266.261C516.838 266.261 518.125 265.596 518.812 264.554V265.995H522.049V254.531Z"
        fill="white"
      />
      <path
        d="M531.634 265.264L530.858 263.157C530.637 263.379 530.193 263.534 529.75 263.534C529.151 263.534 528.663 263.002 528.663 262.248V257.148H531.147V254.531H528.663V251.382H525.559V254.531H523.984V257.148H525.559V262.758C525.559 264.82 526.978 266.261 528.996 266.261C529.927 266.261 530.969 265.862 531.634 265.264Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M455.708 290.6L456.684 292.995H460.365L454.156 277.761H450.497L444.288 292.995H447.947L448.923 290.6H455.708ZM454.577 287.829H450.053L452.315 282.263L454.577 287.829Z"
        fill="white"
      />
      <path
        d="M461.207 291.532C462.582 292.64 464.533 293.261 466.373 293.261C469.189 293.261 471.03 291.82 471.03 289.558C471.03 287.053 468.835 286.387 466.861 285.877C465.309 285.456 464.843 285.279 464.843 284.658C464.843 284.259 465.398 283.97 466.196 283.97C467.172 283.97 468.369 284.347 469.411 284.99L470.72 282.684C469.456 281.842 467.837 281.265 466.196 281.265C463.491 281.265 461.695 282.817 461.717 284.99C461.739 287.252 463.557 287.895 465.908 288.472L465.912 288.473C467.085 288.783 467.926 289.005 467.926 289.713C467.926 290.223 467.305 290.578 466.44 290.578C464.91 290.578 463.624 290.024 462.604 289.292L461.207 291.532Z"
        fill="white"
      />
      <path
        d="M472.337 291.532C473.712 292.64 475.663 293.261 477.504 293.261C480.32 293.261 482.161 291.82 482.161 289.558C482.161 287.053 479.965 286.387 477.992 285.877C476.44 285.456 475.974 285.279 475.974 284.658C475.974 284.259 476.528 283.97 477.326 283.97C478.302 283.97 479.5 284.347 480.542 284.99L481.85 282.684C480.586 281.842 478.967 281.265 477.326 281.265C474.621 281.265 472.825 282.817 472.847 284.99C472.869 287.252 474.688 287.895 477.038 288.472L477.042 288.473C478.215 288.783 479.056 289.005 479.056 289.713C479.056 290.223 478.435 290.578 477.57 290.578C476.04 290.578 474.754 290.024 473.734 289.292L472.337 291.532Z"
        fill="white"
      />
      <path
        d="M484.177 278.183C484.177 279.158 484.953 279.957 485.951 279.957C486.905 279.957 487.681 279.158 487.681 278.183C487.681 277.274 486.905 276.453 485.951 276.453C484.953 276.453 484.177 277.274 484.177 278.183Z"
        fill="white"
      />
      <path d="M484.31 281.531V292.995H487.548V281.531H484.31Z" fill="white" />
      <path
        d="M489.509 291.532C490.884 292.64 492.836 293.261 494.676 293.261C497.492 293.261 499.333 291.82 499.333 289.558C499.333 287.053 497.137 286.387 495.164 285.877C493.612 285.456 493.146 285.279 493.146 284.658C493.146 284.259 493.7 283.97 494.499 283.97C495.474 283.97 496.672 284.347 497.714 284.99L499.022 282.684C497.758 281.842 496.14 281.265 494.499 281.265C491.793 281.265 489.997 282.817 490.019 284.99C490.042 287.252 491.86 287.895 494.21 288.472L494.215 288.473C495.388 288.783 496.228 289.005 496.228 289.713C496.228 290.223 495.607 290.578 494.743 290.578C493.213 290.578 491.926 290.024 490.906 289.292L489.509 291.532Z"
        fill="white"
      />
      <path
        d="M508.312 292.264L507.536 290.157C507.314 290.379 506.871 290.534 506.427 290.534C505.829 290.534 505.341 290.002 505.341 289.248V284.148H507.824V281.531H505.341V278.382H502.237V281.531H500.662V284.148H502.237V289.758C502.237 291.82 503.656 293.261 505.674 293.261C506.605 293.261 507.647 292.862 508.312 292.264Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M520.496 286.143C520.496 283.15 518.456 281.265 515.462 281.265C513.866 281.265 512.291 281.708 510.673 282.529L511.759 284.68C512.668 284.214 513.666 283.859 514.73 283.859C516.615 283.859 517.369 284.924 517.369 286.143V286.365C516.393 285.988 515.373 285.789 514.442 285.789C511.781 285.789 509.675 287.341 509.675 289.603C509.675 291.798 511.559 293.261 513.999 293.261C515.24 293.261 516.549 292.774 517.369 291.842V292.995H520.496V286.143ZM517.369 289.381C517.147 290.445 515.972 291.022 514.73 291.022C513.622 291.022 512.801 290.445 512.801 289.514C512.801 288.583 513.733 287.895 514.908 287.895C515.795 287.895 516.637 288.05 517.369 288.316V289.381Z"
        fill="white"
      />
      <path
        d="M534.112 285.7C534.112 283.105 532.338 281.265 529.855 281.265C528.369 281.265 527.083 281.93 526.395 282.972V281.531H523.158V292.995H526.395V286.143L526.418 286.21C526.418 284.968 527.46 283.992 528.812 283.992C530.099 283.992 530.986 284.968 530.986 286.387V292.995H534.112V285.7Z"
        fill="white"
      />
      <path
        d="M536.202 287.252C536.202 290.512 539.063 293.261 542.455 293.261C544.362 293.261 545.915 292.485 547.046 291.243L544.895 289.425C544.296 290.09 543.365 290.534 542.478 290.534C540.77 290.534 539.351 289.026 539.351 287.23C539.351 285.478 540.77 283.992 542.455 283.992C543.342 283.992 544.252 284.414 544.828 285.057L546.979 283.216C545.87 282.019 544.34 281.265 542.478 281.265C539.085 281.265 536.202 283.992 536.202 287.252Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M557.043 289.381C556.422 289.98 555.313 290.401 554.404 290.401C553.052 290.401 552.054 289.558 551.677 288.316H559.948C559.948 283.992 557.841 281.265 554.249 281.265C550.901 281.265 548.351 283.771 548.351 287.23C548.351 290.756 551.011 293.261 554.426 293.261C556.112 293.261 558.085 292.552 559.105 291.487L557.043 289.381ZM551.721 285.988C552.142 284.835 553.162 284.125 554.382 284.125C555.69 284.125 556.533 284.857 556.843 285.988H551.721Z"
        fill="white"
      />
      </g>
    </svg>
  );
};
