import React, { SVGProps } from "react";

export const AffortableIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" {...props}>
  <path d="M93.75 20.0437L89.3313 15.625L78.8625 26.0938C77.3688 24.2906 75.7094 22.6312 73.9062 21.1375L84.375 10.6688L79.9563 6.25L68.675 17.5312C61.5375 13.4082 53.2388 11.756 45.0665 12.831C36.8941 13.9059 29.3049 17.6479 23.4764 23.4764C17.6479 29.3049 13.9059 36.8941 12.831 45.0665C11.756 53.2388 13.4082 61.5375 17.5312 68.675L6.25 79.9563L10.6688 84.375L21.1375 73.9062C22.6312 75.7094 24.2906 77.3688 26.0938 78.8625L15.625 89.3313L20.0437 93.75L31.325 82.4688C38.4625 86.5918 46.7612 88.244 54.9335 87.169C63.1059 86.0941 70.6951 82.3521 76.5236 76.5236C82.3521 70.6951 86.0941 63.1059 87.169 54.9335C88.244 46.7612 86.5918 38.4625 82.4688 31.325L93.75 20.0437ZM50 81.25C43.8193 81.25 37.7775 79.4172 32.6384 75.9834C27.4994 72.5496 23.494 67.669 21.1288 61.9589C18.7635 56.2487 18.1447 49.9653 19.3505 43.9034C20.5562 37.8415 23.5325 32.2733 27.9029 27.9029C32.2733 23.5325 37.8415 20.5562 43.9034 19.3505C49.9653 18.1447 56.2487 18.7635 61.9589 21.1288C67.669 23.494 72.5496 27.4994 75.9834 32.6384C79.4172 37.7775 81.25 43.8193 81.25 50C81.2417 58.2855 77.9467 66.2292 72.088 72.088C66.2292 77.9467 58.2855 81.2417 50 81.25Z" fill="white"/>
  <path d="M34.7745 59.9405C35.1916 65.23 39.3717 69.348 46.8711 69.845V73.75H50.1992V69.8184C57.9649 69.277 62.5 65.1235 62.5 59.1063C62.5 53.6215 59.0387 50.7992 52.8262 49.3438L50.1992 48.7225V38.0725C53.5362 38.4541 55.6485 40.2735 56.1544 42.794H61.9941C61.577 37.6909 57.2016 33.706 50.1992 33.2711V29.375H46.8711V33.3687C40.2415 34.0166 35.733 38.0015 35.733 43.646C35.733 48.6337 39.0878 51.811 44.6701 53.1067L46.8711 53.6481V64.9371C43.4631 64.4224 41.2 62.5409 40.6941 59.9405H34.7745ZM46.8445 47.9238C43.5696 47.1694 41.7946 45.6162 41.7946 43.291C41.7946 40.6817 43.7116 38.7292 46.8711 38.1612V47.9238H46.8445ZM50.6785 54.5445C54.6634 55.4675 56.4916 56.9585 56.4916 59.5944C56.4916 62.603 54.2107 64.662 50.1992 65.0436V54.438L50.6785 54.5445Z" fill="white"/>
</svg>
  );
};
