interface Props {
  defaultValue?: string | number;
  inputClass?: string;
  wrapperClass?: string;
  errorClass?: string;
  errorMessage?:string
  inputProp?: any;
}


export const CustomInput = ({
  inputProp,
  wrapperClass,
  inputClass,
  errorMessage,
   defaultValue,
   errorClass
}: Props) => {
  return (
    <div className={wrapperClass}>
      <input
        className={inputClass}
        defaultValue={defaultValue}
        {...inputProp}
      />
      {errorMessage && <p className={errorClass}>{errorMessage}</p>}
    </div>
  );
};


export  const FormControl = ({ children }: { children: React.ReactNode }) => {
    return <div className="w-full flex flex-col gap-1 mb-4">{children}</div>;
  };
  
