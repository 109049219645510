import { SVGProps } from "react";

export const HeadphoneIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" { ...props }>
    <path d="M88.1519 69.2358C88.1502 74.4534 86.3668 79.5138 83.0969 83.5796C79.8269 87.6453 75.2666 90.4724 70.1708 91.593L67.7367 84.2906C69.9657 83.9235 72.0855 83.0665 73.9433 81.7813C75.8011 80.4962 77.3507 78.8149 78.4804 76.8586H69.0759C67.0521 76.8586 65.1113 76.0547 63.6803 74.6237C62.2493 73.1927 61.4454 71.2519 61.4454 69.2282V53.9673C61.4454 51.9436 62.2493 50.0028 63.6803 48.5718C65.1113 47.1408 67.0521 46.3369 69.0759 46.3369H80.2849C79.3539 38.9617 75.7636 32.1797 70.1877 27.2634C64.6118 22.3472 57.4335 19.6345 49.9998 19.6345C42.566 19.6345 35.3878 22.3472 29.8119 27.2634C24.236 32.1797 20.6457 38.9617 19.7146 46.3369H30.9237C32.9474 46.3369 34.8883 47.1408 36.3192 48.5718C37.7502 50.0028 38.5541 51.9436 38.5541 53.9673V69.2282C38.5541 71.2519 37.7502 73.1927 36.3192 74.6237C34.8883 76.0547 32.9474 76.8586 30.9237 76.8586H19.4781C17.4544 76.8586 15.5135 76.0547 14.0826 74.6237C12.6516 73.1927 11.8477 71.2519 11.8477 69.2282V50.1521C11.8477 29.0807 28.9284 12 49.9998 12C71.0712 12 88.1519 29.0807 88.1519 50.1521V69.2282V69.2358ZM80.5215 69.2282V53.9673H69.0759V69.2282H80.5215ZM19.4781 53.9673V69.2282H30.9237V53.9673H19.4781Z" fill="white"/>
  </svg>
  );
};
