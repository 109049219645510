import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import {
  useAcceptPriceRateShipping,
  useAddPaymenTransactiontMethod,
  useConfirmShippingPayment,
  useSaveShippingQuotesInfo,
  useTransactionsCheckForPaymentMethod,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { IShippingPaymentInfoNew } from "./public-shipping-form.form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  AddCardMethodPayload,
  PaymentCardInfo,
  ShippingByIDResponse,
} from "services/endpoints/shipping-quotes/shipping.type";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import { PaymentFormNewShipping } from "pages/shipping-quotes/payment-form";

interface Props {
  from?: string;
  vehicleName?: string;
  payModalStep: number | null;
  setPayModalStep: (num: number | null) => void;
  shippingPaymentInfo: IShippingPaymentInfoNew | null;
  forHistoryOrder?: boolean;
  orderHistoryShippindData?: ShippingByIDResponse | null;
  vin: string;
}

export const ShippingPaymentComponent = ({
  from,
  vehicleName,
  payModalStep,
  setPayModalStep,
  shippingPaymentInfo,
  orderHistoryShippindData,
  vin,
  forHistoryOrder = false,
}: Props) => {
  // const shippingData: any | null = forHistoryOrder
  //   ? {
  //       form: {
  //         shippingZip: orderHistoryShippindData?.shippingZip || "N/A",
  //         shippingStreetAddress:
  //           orderHistoryShippindData?.shippingStreetAddress || "N/A",
  //         destinationZip: orderHistoryShippindData?.destinationZip || "N/A",
  //         destinationStreetAddress:
  //           orderHistoryShippindData?.destinationStreetAddress || "N/A",
  //       },
  //       pay: [
  //         {
  //           referenceNumbers: [
  //             {
  //               number: orderHistoryShippindData?.refNumber || "N/A",
  //               type: {
  //                 value: 12,
  //                 label: "VIN",
  //                 shortLabel: "VIN",
  //               },
  //             },
  //           ],
  //           price: {
  //             ...(orderHistoryShippindData?.price || {}),
  //             label: "$" + (orderHistoryShippindData?.price?.value || "0"),
  //           },
  //           calculatedPrices: {
  //             ushipServiceCharge: {
  //               ...(orderHistoryShippindData?.calculatedPrices
  //                 ?.ushipServiceCharge || {}),
  //               label:
  //                 "$" +
  //                 (orderHistoryShippindData?.calculatedPrices
  //                   ?.ushipServiceCharge?.value || "0"),
  //             },
  //           },
  //           quoteId: orderHistoryShippindData?.quoteId || "",
  //         },
  //       ],
  //     }
  //   : shippingPaymentInfo;

  const [paymentSaved, setPaymentSaved] = useState<PaymentCardInfo | null>(
    null
  );
  const navigate = useNavigate();

  const {
    mutateAsync: mutateAsyncSaveShippingQuotes,
    isPending: isPendingSaveShippingQuetues,
  } = useSaveShippingQuotesInfo();

  const {
    mutateAsync: mutateAsyncAccceptPayment,
    isPending: isPendingToAccceptPayment,
  } = useTransactionsCheckForPaymentMethod();
  const {
    mutateAsync: mutateAsyncAcceptPriceRateShipping,
    isPending: isPendingToAcceptPriceRateShipping,
    data,
  } = useAcceptPriceRateShipping();
  const {
    mutateAsync: mutateConfirmShippingPayment,
    isPending: isPendingToConfirmShippingPayment,
  } = useConfirmShippingPayment();

  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
  } = useAddPaymenTransactiontMethod();

  const handleSaveQuotes = async () => {
    try {
      const res = await mutateAsyncSaveShippingQuotes(
        shippingPaymentInfo?.pay.QuoteID || ""
      );
      console.log("saved Data", res);
      navigate("/shipping-quotes/order-history");
      setPayModalStep(null);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handleOnProceedPayment = async () => {
    try {
      const isPaymentExistRes = await mutateAsyncAccceptPayment();
      setPaymentSaved(isPaymentExistRes);
      setPayModalStep(2);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handleConfirmPayment = async (data: AddCardMethodPayload) => {
    try {
      if (paymentSaved) {
        const confirmShippingPayment = await mutateConfirmShippingPayment(
          shippingPaymentInfo?.pay.QuoteID || ""
        );
        const res = await mutateAsyncAcceptPriceRateShipping(
          shippingPaymentInfo?.pay.QuoteID || ""
        );
        console.log("confirmShippingPayment", confirmShippingPayment);
        if (res !== "Invalid quote id" && confirmShippingPayment >= 0) {
          setPayModalStep(3);
        }
      } else {
        const paymentData = await mutateAddPaymenTransactiontMethod(data);
        const confirmShippingPayment = await mutateConfirmShippingPayment(
          shippingPaymentInfo?.pay.QuoteID || ""
        );

        const res = await mutateAsyncAcceptPriceRateShipping(
          shippingPaymentInfo?.pay.QuoteID || ""
        );

        console.log("paymentData", paymentData);
        if (res !== "Invalid quote id" && confirmShippingPayment >= 0) {
          setPayModalStep(3);
        }
      }
    } catch (e) {
      console.log("Error :", e);
    }
  };
  return (
    <>
      <PaymentFormNewShipping
        vin={vin}
        from={from}
        vehicleName={vehicleName ?? ""}
        handleProceed={handleOnProceedPayment}
        payData={shippingPaymentInfo}
        showSaveQuotes={!forHistoryOrder}
        isPendingToProceed={isPendingToAccceptPayment}
        handleSaveQuotes={handleSaveQuotes}
        isSaveQuotesPending={isPendingSaveShippingQuetues}
      />
    </>
  );
};
