import { SVGProps } from "react";

export const DownArrowIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="17"
    viewBox="0 0 28 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={props.stroke}
      id="Vector"
      d="M12.1874 15.4603L0.677022 3.9499C0.462379 3.73526 0.292116 3.48044 0.175953 3.2C0.0597892 2.91955 -1.14479e-07 2.61898 -1.0121e-07 2.31543C-7.44133e-08 1.70238 0.243532 1.11444 0.677022 0.680951C0.891664 0.466308 1.14648 0.296044 1.42693 0.179881C1.70737 0.063717 2.00795 0.00392991 2.3115 0.00392993C2.92454 0.00392995 3.51248 0.247461 3.94597 0.680951L13.8219 10.5799L23.6978 0.680952C23.9118 0.465182 24.1664 0.293921 24.4469 0.177047C24.7275 0.0601739 25.0284 1.09402e-06 25.3323 1.10731e-06C25.6362 1.12059e-06 25.9371 0.0601739 26.2176 0.177047C26.4981 0.293921 26.7527 0.465182 26.9667 0.680952C27.1825 0.89496 27.3538 1.14957 27.4707 1.4301C27.5875 1.71063 27.6477 2.01153 27.6477 2.31543C27.6477 2.61933 27.5875 2.92022 27.4707 3.20075C27.3538 3.48128 27.1825 3.73589 26.9667 3.9499L15.4564 15.4603C15.2424 15.6761 14.9877 15.8473 14.7072 15.9642C14.4267 16.0811 14.1258 16.1412 13.8219 16.1412C13.518 16.1412 13.2171 16.0811 12.9366 15.9642C12.656 15.8473 12.4014 15.6761 12.1874 15.4603Z"
      fill="url(#paint0_linear_1414_14183)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1414_14183"
        x1="-3.52778e-07"
        y1="8.07062"
        x2="27.6477"
        y2="8.07062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.stopColor} />
        <stop offset="1" stopColor="#070C84" />
      </linearGradient>
    </defs>
  </svg>
);
