import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@nextui-org/react";
import downArrow from "../dealer-services-pricing/assets/svg/down_icon_white.svg";
import dropIconBlack from "../dealer-services-pricing/assets/svg/drop_icon_black.svg";
import "./index.scss";
import { Typography } from "components";
export default function ViewBecomeDealerPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const guides = [
    {
      title: "1. Research & Education: ",
      description:
        "Dive into the world of car dealerships. Research industry trends, best practices, and potential challenges. We're happy to answer any questions you might have, so feel free to contact us.",
    },
    {
      title: "2. Costs & Time: ",
      description:
        "Starting any business involves commitment and investment. Be prepared for the financial costs associated with becoming a car dealer and acknowledge the time commitment required for optimal success.",
    },
    {
      title: "3. State Laws & Regulations: ",
      description:
        "Each state has specific laws governing car dealerships. Familiarize yourself with your state's requirements for licenses, permits, and business operations.",
    },
    {
      title: "4. Place of Business:",
      description:
        "Most states require a dedicated business location for car dealerships, often with minimum space requirements. Consider your budget and desired location carefully.",
    },
    {
      title: "5. Vehicle Sourcing: ",
      description:
        "Identify reliable sources for your vehicle inventory, such as private sellers, auctions, or other dealerships. Establish a network that ensures a consistent flow of quality cars.",
    },
  ];

  const [clickTier, setClickTier] = useState(
    guides.map(() => ({ isClick: false }))
  );

  return (
    <div className="md:p-3">
      <Typography variant="custom" className="text-center">
        <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent text-black bg-clip-text text-3xl 2xl:text-5xl font-bold pt-5">
          <div>How to Become a Vehicle Dealer:</div>
          <div>A Step-by-Step Guide</div>
        </p>
      </Typography>
      <Typography variant="custom" className="mt-10 text-center">
        <p className="text-lg 2xl:text-xl">
          Becoming a vehicle dealer can be a fulfilling career path for those
          passionate about cars and entrepreneurship. While the process might
          seem daunting at first, we're here to simplify it for you. Selling
          used cars can be a lucrative business with the right approach,
          combining efficiency and integrity. This guide outlines the best
          practices to help you become a successful dealer:
        </p>
      </Typography>

      <Typography variant="custom" className="mt-10 mb-5">
        <p className="text-lg 2xl:text-xl font-semibold">
          Initial Considerations:
        </p>
      </Typography>
      <Accordion
        showDivider={false}
        selectionMode="multiple"
        defaultSelectedKeys={"6"}
        defaultExpandedKeys={guides.map((_, index) => index.toString())}
      >
        {guides.map(({ title, description }, idx) => (
          <AccordionItem
            key={idx}
            onPressEnd={() => {
              setClickTier((prev) => {
                const newState = [...prev];
                newState[idx].isClick = !newState[idx].isClick;
                return newState;
              });
            }}
            indicator={<></>}
            title={
              <div
                className={`relative ${
                  clickTier[idx].isClick
                    ? "bg-gradient-to-r from-[#018FFD] to-[#070C84] text-white p-4 rounded-lg"
                    : "bg-white text-black p-4 rounded-lg border-2 border-sky-500	"
                }`}
              >
                <p className={`pt-1 font-light text-xl pr-10`}>{title}</p>
                <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-10 text-white">
                  {clickTier[idx].isClick ? (
                    <img
                      src={downArrow}
                      alt="down arrow"
                      className="w-6 h-6 cursor-pointer"
                    />
                  ) : (
                    <img
                      src={dropIconBlack}
                      alt="down arrow"
                      className="w-6 h-6 cursor-pointer"
                    />
                  )}
                </div>
              </div>
            }
            className="w-full text-black rounded-lg"
          >
            <div className=" px-5 text-wrap">{description}</div>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
