import React from "react";
import done_icon from "../../../dealer/features/shipping-quotes/assets/done-icon.png";

interface Props {
  buttonText?: string;
  textNote?: string;
  onClick?: () => void;
  disable?: boolean;
}
const ThankYou: React.FC<Props> = ({
  buttonText = "Done",
  textNote = "Thank You",
  disable = false,
  onClick,
}) => {
  // const navigate = useNavigate();
  const handleBtnClick = () => {
    onClick && onClick();
  };

  return (
    <>
      <div className="mb-32">
        <img
          className="m-auto h-56 w-56 animate-bounce"
          src={done_icon}
          alt=""
        />
        <div
          className="text-4xl -mt-8 font-bold text-center text-blue-900"
          style={{ fontFamily: "Metropolis-Bold" }}
        >
          {textNote}
        </div>
        <div
          className="flex justify-center mt-4"
          style={{ fontFamily: "Metropolis-SemiBold" }}
        >
         {buttonText && <button
            className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 w-2/5 text-white font-bold py-3 px-4 mt-4 rounded-xl focus:outline-none"
            onClick={handleBtnClick}
            disabled={disable}
          >
            {buttonText}
          </button>}
        </div>
      </div>
    </>
  );
};

export default ThankYou;
