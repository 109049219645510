import React, { useCallback, useEffect, useState } from "react";
import { useGetListingDetails } from "services/listings.service";
import { ListingDetailsSnapshot } from "services";
import {
  capitalizeAllBeforeDash,
  capitalizeForDashOrWhiteSpace,
  findImage,
} from "utils";
import { env } from "config/env";
import { Image } from "@nextui-org/react";

interface Props {
  listing: any;
}
export default function ListingOfTheDay(props: Props) {
  const [images, setImages] = useState<string[]>([]);
  const { listing } = props;
  const [isHover, setHover] = useState(false);
  const numberFormatter = (price: number) => {
    return price.toLocaleString();
  };

  console.log({ listing });

  const { data: listingDetailsData } = useGetListingDetails(
    Number(listing?.listingId) ?? 0
  );
  // const listing = listingDetailsData?.listing;

  const viewListing = (listingId: number) => {
    window.open(`/#/listing/view-listing/${listingId}/`);
  };

  return (
    <div>
      {listing && (
        <div className="flex items-center flex-col justify-end ">
          <p className="font-bold font-[Metropolis] bg-[#3263238] text-lg mb-2 md:mt-[5rem]">
            Listing of the Day
          </p>
          <div
            className=" rounded-xl relative overflow-hidden group md:max-w-auto md:max-w-[300px] xl:max-w-[300px] "
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {listing?.listingId && (
              <Image
                className={`w-[400px] h-[250px] object-cover z-0 rounded-t-xl ${
                  isHover &&
                  "transition-transform  duration-300 transform scale-105"
                }`}
                radius="none"
                src={`${env.BASE_API_URL}Listings/image/${listing?.listingId}`}
                alt="Car Image"
              />
            )}
            <div
              className={`rounded-xl  absolute bottom-0 left-0 right-0 bg-gradient-to-t from-[#018FFD]/80 to-[#018ffd]/20 text-white transition-transform transition-height duration-900 ease-in-out ${
                isHover
                  ? "translate-y-0 h-full"
                  : "translate-y-full min-h-[110px]"
              }`}
            >
              {isHover && (
                <div className="h-full rounded-xl ">
                  <div className="h-3/4 flex items-center justify-center">
                    <div className="pl-3 pr-3 cursor-pointer mb-5">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icon/preview-btn.svg"
                        }
                        alt="view"
                        className=" h-[50px] w-[50px]"
                        onClick={() => viewListing(listing?.listingId ?? 0)}
                      />
                    </div>
                  </div>
                  <div className="text-center absolute bottom-[14px] left-0 right-0 p-2">
                    <div className="text-2xl font-bold text-center ">
                      ${numberFormatter(listing?.price ?? 0)}
                    </div>
                    <div className="text-lg font-semibold ">
                      {listing?.year ?? ""}{" "}
                      {capitalizeForDashOrWhiteSpace(listing?.make) ?? " "}{" "}
                      {capitalizeAllBeforeDash(listing?.model) ?? ""}
                    </div>
                    <div className="text-lg font-semibold ">
                      {listing?.trim?.toUpperCase() ?? " "}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!isHover && (
              <div
                className={`min-h-[110px] absolute bottom-0 left-0 right-0  bg-gradient-to-t from-[#018FFD] to-[#018ffd]/.5
               px-4  rounded-b-[10px] h-[auto] flex items-center justify-center `}
              >
                <div className="">
                  <div className="w-[100%] text-white  pt-8">
                    <div className="text-xl font-bold text-center ">
                      ${numberFormatter(listing?.price ?? 0)}
                    </div>
                    <div className="text-md font-semibold text-center">
                      {listing?.year ?? ""}{" "}
                      {capitalizeForDashOrWhiteSpace(listing?.make) ?? " "}{" "}
                      {capitalizeAllBeforeDash(listing?.model) ?? ""}
                    </div>
                    <div className="text-md font-semibold text-center">
                      {listing?.trim?.toUpperCase() ?? " "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
