import { Button, Listbox, ListboxItem } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

import { Typography } from "components";
import { CheckIcon } from "assets/svgs/icons/check-icon";
import { TiltingOrderHistory } from "services";
import { capitalizeAllBeforeDash, capitalizeForDashOrWhiteSpace } from "utils";
import { isThisDealer } from "utils/get-user-id";

interface ListOrderHistoryProps {
  data: TiltingOrderHistory[];
}
export const ListOrderHistory: React.FC<ListOrderHistoryProps> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Listbox
      aria-label="order-history-list"
      variant="light"
      disallowEmptySelection
      selectionMode="none"
      items={data || []}
    >
      {data.map((orderHistory, idx) => {
        const name = `${orderHistory.year} ${capitalizeForDashOrWhiteSpace(
          orderHistory.make
        )} ${capitalizeAllBeforeDash(orderHistory.model)}`;

        return (
          <ListboxItem key={idx}>
            <div className="grid grid-cols-1 xl:grid-cols-4 gap-4 border border-blue-400 rounded-lg p-8 items-center">
              <div className="grid justify-items-center md:justify-items-center xl:justify-items-start">
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[700] text-2xl"
                >
                  {name}
                </Typography>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] text-lg"
                >
                  VIN - {orderHistory.vin}
                </Typography>
              </div>
              <div></div>
              <div className="grid grid-cols-1 justify-items-center md:justify-items-center xl:justify-items-end">
                <div className="flex flex-col items-center xl:mr-5">
                  <Typography variant="body">
                    <CheckIcon />
                  </Typography>
                  <Typography variant="body">Completed</Typography>
                  {/* <Typography variant="body">
                    {orderHistory.status === "pending" ? (
                      <CloseXIcon />
                    ) : (
                      <CheckIcon />
                    )}
                  </Typography>
                  <Typography variant="body">
                    {orderHistory.status.charAt(0).toUpperCase() +
                      orderHistory.status.slice(1)}
                  </Typography> */}
                </div>
              </div>
              <div className="grid grid-flow-col gap-4">
                <Button
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 tracking-wide px-6 rounded-xl w-full"
                  type="button"
                  size="md"
                  onClick={() =>
                    navigate(`/${isThisDealer()?"dealer":"user"}/dmv-titling/dvm-transit-status`)
                  }
                >
                  <Typography variant="body">Transit Status</Typography>
                </Button>
                <Button
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 tracking-wide px-6 rounded-xl w-full"
                  type="button"
                  size="md"
                  onClick={() =>
                    navigate(`/${isThisDealer()?"dealer":"user"}/dmv-titling/dmv-history/${orderHistory.quoteId}`)
                  }
                >
                  <Typography variant="body">Continue</Typography>
                </Button>
              </div>
            </div>
          </ListboxItem>
        );
      })}
    </Listbox>
  );
};
