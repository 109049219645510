import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import Form from "components/ui/form";
import { Typography } from "components";
import Input from "components/ui/input";
import { AutoLoanLeaseCalculatorSchema } from "./AutoLoanLeaseCalculatorSchema";
import AmortizationTable from "./components/AmotizationTable";
import LoanCalculator from "./components/LoanCalculator";
import LeaseCalculator from "./components/LeaseCalculator";
import LeaseAmortizationTable from "./components/LeaseAmotizationTable";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

export default function AutoLoanLeaseCalculatorPage() {
  const [loanDetails, setLoanDetails] = useState({
    totalLoanAndInterest: 0,
    loanTerm: 0,
    interestRate: 0,
  });
  const [isLease, setIsLease] = useState(false);
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  return (
    <div  className="md:p-2 mt-10">
      <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text 2xl:text-5xl text-3xl font-bold mb-10">
          Payment Calculators
          </p>
        </Typography>
      <Typography variant="custom" className="text-center text-xl mb-10 md:p-3">
        These calculators are for a general comparison if you want a specific
        quote tied to an actual car please go into the listing and use the
        calculators imbedded. If you have any questions about the fields feel
        free to ask Turbo. IE: what are the ranges of rates for a person with a
        700 credit score?
      </Typography>
      <div className="p-4">
        <div className="w-full flex flex-col items-center ">
          <LoanCalculator
            handleCallBack={(e) => {
              setLoanDetails(e);
              setIsLease(false);
              onOpen();
            }}
          />
        </div>
        <div className="w-full flex flex-col items-center mt-10 ">
          <LeaseCalculator
            handleCallBack={(e: any) => {
              setLoanDetails(e);
              setIsLease(true);
              onOpen();
            }}
          />
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        size="5xl"
        placement="center"
        closeButton={<BlackCloseXIcon width={"32px"} height={"32px"} />}
     
      >
        <ModalContent>
          {(onClose) => (
            <>
              <div className="mt-1 md:p-5">
                <div className="text-center text-2xl mb-5 ">
                  Amortization Schedule
                </div>
                <div className="sm-[500px] md:h-[700px] overflow-y-scroll overflow-scroll">
                  {isLease ? (
                    <LeaseAmortizationTable loanDetails={loanDetails} />
                  ) : (
                    <AmortizationTable loanDetails={loanDetails} />
                  )}
                </div>
              </div>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
