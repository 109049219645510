import { Typography } from "components";
import React, { useEffect } from "react";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="md:p-3 mt-10">
      <Typography variant="custom" className="text-center">
        <p className="text-3xl 2xl:text-5xl font-bold">About Us</p>
      </Typography>
      <Typography variant="custom" className="mt-16 text-center ">
        <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-xl 2xl:text-3xl font-bold text-center">
          Welcome to findmyoptions.com!– Your One-Stop Destination for Anything
          with a Motor!
        </p>
        <p className="text-xl mt-7">
          At findmyoptions.com, we are passionate about all things mechanical.
          We understand the unique bond between car enthusiasts and their
          vehicles, and our mission is to provide a platform that simplifies the
          way you buy, sell, and connect within the automotive community.
        </p>
        <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-xl 2xl:text-3xl font-bold mt-16">
          Our Story
        </p>
        <p className="text-xl mt-7 ">
          Founded by a group of dedicated car lovers, findmyoptions.com was born
          out of a shared love for anything with a motor and a desire to create
          an online hub that caters to the diverse needs of car owners, buyers,
          and sellers. Whether you're a seasoned gearhead or a first-time car
          buyer, we're here to assist you every step of the way.
        </p>
        <p className="text-xl mt-16 ">
          At findmyoptions.com our commitment is to be the go-to platform for
          all your automotive needs. We're here to serve the automotive
          community and help you navigate the exciting world of cars with
          confidence. Whether you're a collector, an amateur mechanic, or
          someone in search of reliable transportation, we're dedicated to
          making your automotive journey enjoyable and hassle-free.
        </p>
        <p className="text-xl mt-16 mb-32 font-bold">
          Thank you for choosing findmyoptions.com. Let's drive into the future
          together!
        </p>
      </Typography>
    </div>
  );
}
