import { Progress } from "@nextui-org/react";
import * as React from "react";

interface IProps {
  isApiRequest?: boolean;
}

export const LinearDeterminateComponent: React.FC<IProps> = ({
  isApiRequest,
}) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <Progress
        isStriped
        aria-label="Loading..."
        value={progress}
        className="max-w-md z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 bg-cyan-600"
      />
    </div>
  );
};
