import DriversLicenseIcon from "./assets/drivers-license-icon.png";
import ProofIncomeIcon from "./assets/income-icon.png";
import ResidencyProof from "./assets/residency-proof.png";
import DownPayAmmount from "./assets/down-pay-ammount.png";

// import { TicketIcon } from "./assets/ticket.icon";
// import { TurboIcon } from "./assets/turbo.icon";

export const cards = [
  {
    cardNumber: "01",
    title: "Valid driver's license",
    icon: <img src={DriversLicenseIcon} alt="Valid driver's license" className="absolute bottom-8 w-12 h-auto object-cover" />,
  },
  {
    cardNumber: "02",
    title: "Proof of income",
    icon: <img src={ProofIncomeIcon} alt="ProofIncomeIcon" className="absolute bottom-8 w-12 h-auto object-cover" />,
  },

  {
    cardNumber: "03",
    title: "Proof of Residency",
    icon: <img src={ResidencyProof} alt="residency-proof" className="absolute bottom-6 w-12 h-auto object-cover" />,
  },
  
  {
    cardNumber: "04",
    title: "Down payment (amount may vary)",
    icon: <img src={DownPayAmmount} alt="down-pay-ammount" className="absolute bottom-6 w-12 h-auto object-cover" />,
  },
];




export const listOptionsBHPHF = [
  {
    name: "Fast and Convenient: ",
    description:
      "On-site financing approval, allowing you to drive away with your new car the same day.",
  },
  {
    name: "All Credit Types: ",
    description:
      "Don't let past credit issues hold you back. BHPH financing options cater to a wider range of credit situations.",
  }
];