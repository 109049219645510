import { z } from "zod";

export const EnterMissingInformationValidationSchema = z.object({
  vehicleType: z.string().min(1, {
    message: "Vehicle Type is required",
  }),
  vehicleBasePrice: z.string().min(1, {
    message: "Vehicle Base Price is required",
  }),
  plateType: z.string().optional(),
  transType: z.string().min(1, {
    message: "Transfer Type is required",
  }),
  transDt: z.string().optional(),
  uploadedDate: z.string().optional(),
  vehicleToBeTitledIn: z.object({
    titledState: z.string().min(1, {
      message: "Vehicle Currently Registered In is required",
    }),
    state: z.string().optional(),
    county: z.string().optional(),
    city: z.string().optional(),
    zipcode: z.string()?.min(1, {
      message: "Zipcode is required",
    }),
    transferingPlates: z.boolean().default(false).optional(),
    licensePlateNumber: z.string().optional(),
    licensePlateState: z.string().optional(),
    vYear: z.string().optional(),
  }),
  doesVehicleHaveLoan: z.object({
    lienholderCompany: z.string().optional(),
    lienholderName: z.string().optional(),
    insuranceCompany: z.string().min(1, {
      message: "Insurance Company is required",
    }),
    policyNumber: z.string().min(1, {
      message: "Policy Number is required",
    }),
  }),
  buyerOneDetails: z
    .object({
      fbFirstName: z.string().optional(),
      fbMiddleName: z.string().optional(),
      fbLastName: z.string().optional(),
      fbCompany: z.string().optional(),
      fbAddress: z.string().optional(),
      fbAddress2: z.string().optional(),
      fbCity: z.string().optional(),
      fbState: z.string().optional(),
      fbzip: z.string().optional(),
      fbdlNumber: z.string().optional(),
      fbdlState: z.string().optional(),
    })
    .optional(),
  buyerTwoDetails: z
    .object({
      sbFirstName: z.string().optional(),
      sbMiddleName: z.string().optional(),
      sbLastName: z.string().optional(),
      sbAddress1: z.string().optional(),
      sbAddress2: z.string().optional(),
      sbCity: z.string().optional(),
      sbState: z.string().optional(),
      sbzip: z.string().optional(),
      buyerCompanyName: z.string().optional(),
      buyerDriverLicenseNumber: z.string().optional(),
      buyerDriverLicenseState: z.string().optional(),
    })
    .optional(),
  sellerOneDetails: z
    .object({
      fsFirstName: z.string().optional(),
      fsMiddleName: z.string().optional(),
      fsLastName: z.string().optional(),
      fsCompany: z.string().optional(),
      fsAddress: z.string().optional(),
      sellerAddress2: z.string().optional(),
      fsCity: z.string().optional(),
      fsState: z.string().optional(),
      fszip: z.string().optional(),
      sellerDriverLicenseNumber: z.string().optional(),
      sellerDriverLicenseState: z.string().optional(),
      sellerDealerLicenseNumber: z.string().optional(),
    })
    .optional(),
  sellerTwoDetails: z
    .object({
      ssFirstName: z.string().optional(),
      ssMiddleName: z.string().optional(),
      ssLastName: z.string().optional(),
      sellerCompanyName: z.string().optional(),
      sellerAddress: z.string().optional(),
      sellerAddress2: z.string().optional(),
      sellerCity: z.string().optional(),
      sellerState: z.string().optional(),
      sellerZip: z.string().optional(),
      sellerDriverLicenseNumber: z.string().optional(),
      sellerDriverLicenseState: z.string().optional(),
      sellerDealerLicenseNumber: z.string().optional(),
    })
    .optional(),
  newUsed: z.string()?.min(1, {
    message: "New or used is required",
  }),
  vin: z.string().optional(),
});
export const EnterMissingInformationValidationSchemaForQuotes = z.object({
  vehicleType: z.string().min(1, {
    message: "Vehicle Type is required",
  }),
  vehicleBasePrice: z.string().min(1, {
    message: "Vehicle Base Price is required",
  }),
  plateType: z.string().optional(),
  transType: z.string().min(1, {
    message: "Transfer Type is required",
  }),
  transDt: z.string().optional(),
  uploadedDate: z.string().optional(),
  vehicleToBeTitledIn: z.object({
    titledState: z.string().min(1, {
      message: "Vehicle Currently Registered In is required",
    }),
    state: z.string().optional(),
    county: z.string().optional(),
    city: z.string().optional(),
    zipcode: z.string()?.min(1, {
      message: "Zipcode is required",
    }),
    transferingPlates: z.boolean().default(false).optional(),
    licensePlateNumber: z.string().optional(),
    licensePlateState: z.string().optional(),
    vYear: z.string().optional(),
  }),
  doesVehicleHaveLoan: z.object({
    lienholderCompany: z.string().optional(),
    lienholderName: z.string().optional(),
    // insuranceCompany: z.string().min(1, {
    //   message: "Insurance Company is required",
    // }),
    // policyNumber: z.string().min(1, {
    //   message: "Policy Number is required",
    // }),
  }),
  vin: z.string().optional(),
});

export const EnterMissingDetailsValidationSchema = z.object({
  state: z.string()?.min(1, {
    message: "State is required",
  }),
  county: z.string()?.min(1, {
    message: "County is required",
  }),
  city: z.string()?.min(1, {
    message: "City is required",
  }),
  zipCode: z.string()?.min(1, {
    message: "Zip code is required",
  }),
  transferTypes: z
    .string()
    .refine((value) => value !== "Select your Transfer Type", {
      message: "Transfer types is required",
    }),
  lien: z.boolean().default(false).optional(),
  transferPlates: z.boolean().default(false).optional(),
  newOrUsed: z.string()?.min(1, {
    message: "New or used is required",
  }),
  vin: z.string().optional(),
});

export const DmvFeeDetailsValidationSchema = z.object({
  transferFee: z.string()?.min(1, {
    message: "Transfer fee is required",
  }),
  dmvFee: z.string()?.min(1, {
    message: "DMV fee is required",
  }),
  sampleFeeOne: z.string()?.optional(),
  sampleFeeTwo: z.string()?.optional(),
  sampleFeeThree: z.string()?.optional(),
  total: z.string()?.optional(),
});
