import {
  Button as NextButton,
  ButtonProps as NextButtonProps,
} from "@nextui-org/react";

interface ButtonProps extends NextButtonProps {
  children: React.ReactNode;
  className?: string;
  customVariant?: "default" | "custom" | "gradient";
  rest?: any;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className = "",
  customVariant = "default",
  ...rest
}) => {
  return (
    <NextButton className={mapStyles(customVariant, className)} {...rest}>
      {children}
    </NextButton>
  );
};

const mapStyles = (
  customVariant: ButtonProps["customVariant"],
  className: string
) => {
  switch (customVariant) {
    case "custom":
      return className;
    case "gradient":
      return `border rounded-2xl border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors ${className}`;
    case "default":
      return "bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg tracking-wide px-6 rounded-xl";
    default:
      return "";
  }
};
