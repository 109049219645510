import React, { useEffect, useState } from "react";
import "./EnterVIN.scss";
import { EnterVIN, VinDataType } from "./type";
import apiClient from "../../utils/apiClient";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  changeVinDataForManageList,
  changeVinNumberForManageList,
  changeMileageForManageList,
} from "../../store/manage-list";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { useInitiateListingVIN, usePostSubmitResearchVehicleForm } from "services";
import { getUserId, isThisDealer } from "utils/get-user-id";

const EnterVINOnly = () => {
  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const location = useLocation();

  const [isSuccessDecodeData, setIsSuccessDecodeData] =
    useState<boolean>(false);
  // const [vinData, setVinData] = useState<VinDataResponse | null>(null);
  const [vinNumber, setVinNumber] = useState<EnterVIN>({
    data: location.state.vin || "",
    error: false,
  });

  //research api
  const {
    mutateAsync: submitResearchMutate,
    isPending: initIsPendingTosubmitReseach,
    error: errResearch,
  } = usePostSubmitResearchVehicleForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //init linsting
  const { data: listingId } = useInitiateListingVIN(
    isSuccessDecodeData ? vinNumber.data : "",
    getUserId(),
    location.state?.mileage || 0
  );

  useEffect(() => {
    let interval: number;
    if (step === 1) {
      let interval = setInterval(() => {
        if (progress < 100) {
          setProgress((p) => p + 20);
        } else {
          clearInterval(interval);
          setProgress(100);
          if (vinNumber.error) {
            setStep(0);
          }
        }
      }, 500);
    }
    return () => clearInterval(interval);
  }, [progress, step]);

  useEffect(() => {
    if (progress >= 99 && listingId) {
      if(location.state?.id){

        submitResearchMutate({ ...location.state, listingId:listingId, email: location?.state?.email||"",linkToListing: location?.state?.linkToListing||"", vin: vinNumber.data })
      }
      navigate(
        `/${isThisDealer() ? "dealer" : "user"}/start-car-research/${
          vinNumber.data
        }/${location.state?.mileage || 0}/${listingId}`
      );
    }
  }, [progress, listingId]);

  const fetchVinData = async () => {
    try {
      if (vinNumber.data.length <= 0 || vinNumber.error) {
        throw new Error("len not right");
      }

      setStep(1);
      const response = await apiClient.get(
        `/VinData/DecodeVinData?vin=${vinNumber.data}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );

      dispatch(changeVinDataForManageList(response?.data?.message || null));
      dispatch(changeVinNumberForManageList(vinNumber.data || null));
      dispatch(changeMileageForManageList(location.state?.mileage || null));
      if (response?.data?.message) setIsSuccessDecodeData(true);

      const err =
        response.data?.message?.find(
          (data: VinDataType) => data.code === "ErrorCode"
        ).value !== "0";
      setVinNumber((prev) => ({
        ...prev,
        error: err,
      }));
    } catch (error) {
      console.error("Error fetching VIN data:", error);
      setVinNumber((prev) => ({ ...prev, error: true }));
    }
  };

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber((prev) => ({ ...prev, data: e.target.value.trim().toLocaleUpperCase() }));
    const len = e.target.value.trim().length;
    if (len !== 17) {
      setVinNumber((prev) => ({ ...prev, error: len !== 0 }));
    } else {
      setVinNumber((prev) => ({ ...prev, error: false }));
    }
  };

  // const toggleItem = (id: number) => {
  //   const updatedItems = toggleItems.map((item) =>
  //     item.id === id ? { ...item, checked: !item.checked } : item
  //   );
  //   setToggleItems(updatedItems);
  // };

  // const isSidebarOpen = document
  //   .querySelector("#application-sidebar")
  //   ?.getAttribute("data-open");

  return (
    <React.Fragment>
      {/* <NavBar type={"enter-scan"}> */}
      <div className="flex items-center m-auto enter-vin h-[calc(75vh)]">
        <div className="flex items-center px-4 mx-auto h-full bg-fixed bg-center bg-cover ">
          {step === 1 && (
            <>
              <div
                style={{ zIndex: 100, height: "108%", overflow: "hidden" }}
                className="absolute inset-0 bg-black opacity-50 z-999 md:w-full w-[108%] "
              ></div>
              <div
                style={{ zIndex: 101 }}
                className="relative gradiant-modal-loading h-[450px] py-24 px-10  md:px-24  mx-auto  rounded-lg shadow-md "
              >
                <div className="flex justify-center mb-4 text-sm font-bold text-white md:text-2xl">
                  Searching for Data
                </div>
                <div className="flex justify-center mb-16 text-lg font-bold text-white md:text-4xl">
                  {vinNumber.data}
                </div>

                <div className="flex justify-center mb-2">
                  <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-xs">
                    {" "}
                    Please wait while findmyoptions.com queries your VIN
                  </p>
                </div>
                <div className="flex justify-center">
                  <div className="loader-container">
                    <div
                      className="loader-progress"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          )}
          {step === 0 && (
            <form className="flex flex-col px-8 py-12 my-auto rounded-lg shadow-md gradiant-box-vin  md:px-8">
              <div className="min-w-0 xl:min-w-[650px] flex flex-col justify-center mb-12">
                <label
                  htmlFor="yourInput1"
                  className={`text-center mb-4 ${
                    vinNumber.error ? "text-red-500" : "text-white"
                  } font-bold  md:text-2xl text-lg `}
                >
                  {"Enter VIN Number"}
                </label>
                <div className="relative w-full text-center">
                  <input
                    type="text"
                    id="yourInput1"
                    defaultValue={location.state?.vin || ""}
                    name="yourInput1"
                    onChange={onChangeVIN}
                    value={vinNumber.data}
                    className={`w-full md:w-3/4 text-center border-none focus:outline-none bg-transparent ${
                      vinNumber.error ? "text-red-500" : "text-white"
                    } font-semibold text-lg md:text-4xl `}
                  />
                  <div
                    className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-full md:w-3/4 h-[1.5px]  ${
                      vinNumber.error ? "bg-red-500" : "bg-white"
                    }`}
                  ></div>
                  {vinNumber.error && (
                    <div
                      className={`absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-full md:w-3/4
                  flex  items-center justify-center text-[#FF0A0A]`}
                    >
                      <ErrorInfoIcon className="-mt-[4px] w-[16px] h-[16px] mr-[2px]" />
                      <label className="text-[16px]">
                        Please enter Valid VIN
                      </label>
                    </div>
                  )}
                </div>
              </div>

              {/*<div className="flex flex-col justify-center w-1/2 max-w-md mx-auto mb-4">
                  <label
                    htmlFor="yourInput1"
                    className={`text-center mb-1 text-white font-bold  md:text-lg text-md `}
                  >
                    Enter Mileage
                  </label>
                  <div className="flex  justify-center w-full  mb-4">
                    <input
                      type="number"
                      id="yourInput1"
                      name="yourInput1"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (Number(value) && Number(value) > 0) {
                          setMileageData((prev) => ({
                            ...prev,
                            mileage: Number(value),
                          }));
                        } else return (e.target.value = "");
                      }}
                      className={`w-[200px] md:w-[300px] text-center ${
                        !mileageData.error
                          ? "border-white"
                          : "border-red-500 border-3"
                      } border focus:outline-none rounded-xl h-[46px] font-semibold text-xl md:text-2xl`}
                    />
                  </div>
                </div>
                 <div className="flex flex-col justify-center w-1/2 max-w-md mx-auto mb-8">
                  <label
                    htmlFor="yourInput1"
                    className={`text-center mb-1 text-white font-bold  md:text-lg text-md `}
                  >
                    Enter Price
                  </label>
                  <div className="flex  justify-center w-full  mb-4">
                    <input
                      type="number"
                      id="yourInput1"
                      name="yourInput1"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (Number(value) && Number(value) > 0) {
                          setPriceData((prev) => ({
                            ...prev,
                            price: Number(value),
                          }));
                        } else return (e.target.value = "");
                      }}
                      className={`w-[200px] md:w-[300px] text-center ${
                        !priceData.error
                          ? "border-white"
                          : "border-red-500 border-3"
                      } border focus:outline-none rounded-xl h-[46px] font-semibold text-xl md:text-2xl`}
                    />
                  </div>
                </div>
                <div>
                  {toggleItems.map(({ name, id, checked, disable }) => (
                    <div
                      key={id}
                      className="flex items-center justify-between md:mb-0 mb-2"
                    >
                      <h5 className="mb-1 text-sm text-white md:text-xl">
                        {name}
                      </h5>
                      <label className="flex items-center cursor-pointer">
                        <div className="relative ">
                          <input
                            id="toggle"
                            type="checkbox"
                            className="sr-only"
                            disabled={disable}
                            checked={checked}
                            onChange={() => toggleItem(id)}
                          />
                          <div className="block bg-white w-[3rem] h-5 w-14 md:h-6 rounded-full "></div>
                          <div
                            className={`dot absolute top-1 ${
                              checked
                                ? "left-[calc(100%-1.1rem)] bg-[#018FFD]"
                                : "left-1 bg-[#B5B5B5]"
                            } w-3 h-3 md:w-4 md:h-4 rounded-full transition`}
                          ></div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div> 
                 <h6 className="text-xs text-white md:text-base ">
                    (History/Options Reports will result in charges to your credit card.)
                    </h6> */}
              <div className="flex items-center justify-center w-full text-center ">
                <button
                  onClick={() => {
                    fetchVinData();
                  }}
                  className=" md:mr-6 mt-5 w-3/4 max-w-[200px] py-2 text-lg text-white rounded-lg  confirm-btn-fin focus:outline-none focus:shadow-outline md:text-2xl"
                  type="button"
                >
                  {"Confirm"}
                </button>
              </div>
            </form>
          )}

          {/* {step > 0 && (
            <div
              style={{ zIndex: 150 }}
              className={`flex overflow-y-auto p-4 overflow-x-hidden  fixed ps-0 ${
                isSidebarOpen
                  ? width < 1023
                    ? "md:ps-4"
                    : "md:ps-[17rem]"
                  : "md:ps-8"
              } ${
                isSidebarOpen ? "xl:ps-72" : "xl:ps-12"
              } items-center  modal-container-vin pt-[130px] justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full`}
            >
              <div className="absolute inset-0 bg-black opacity-50 z-999"></div>

              {step === 1 && (
                <div className="relative gradiant-modal-loading h-[450px] py-24 px-24   md:px-24  mx-auto  rounded-lg shadow-md ">
                  <div className="flex justify-center mb-4 text-lg font-bold text-white md:text-2xl">
                    Searching for Data
                  </div>
                  <div className="flex justify-center mb-16 text-2xl font-bold text-white md:text-4xl">
                    {vinNumber.data}
                  </div>

                  <div className="flex justify-center mb-2">
                    <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-base">
                      {" "}
                      Please wait while findmyoptions.com queries your VIN
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div className="loader-container">
                      <div
                        className="loader-progress"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )} */}
        </div>
        {/* <PaymentModal
          isOpen={showPayModal === 1}
          isPublic={true}
          onOpen={() => {
            setShowPayModal(0);
          }}
          Component={
            <PaymentFormWithDetails
              handleNext={handleConfirmPaymentForOpt}
              // payAmmount={"0.99"}
              showAmmount={false}
              savedMethod={checkIfPaymentExist}
              isPending={isPendingToAddPaymenTransactiontMethod}
              errorMessage={paymentAddErrorMessage?.message}
            />
          }
        /> */}
      </div>
      {/* </NavBar> */}
    </React.Fragment>
  );
};

export default EnterVINOnly;
