import React, { useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";
import { money } from "utils/money";
const LeaseAmortizationTable = ({ loanDetails }: any) => {
  const [schedule, setSchedule] = useState<any>([]);

  useEffect(() => {
    calculateAmortizationSchedule();
  }, []);

  const calculateAmortizationSchedule = () => {
    const {
      costVehicleNum,
      residualNum,
      stateTaxRate,
      discountNum,
      downPaymentNum,
      loanTermMonthsNum,
      moneyFactorNum,
    } = loanDetails;

    // Calculations
    const netCapitalizedCost = costVehicleNum - downPaymentNum - discountNum;
    const totalDepreciation = netCapitalizedCost - residualNum;
    const totalFinanceCharge =
      ((netCapitalizedCost + residualNum) / 2) *
      moneyFactorNum *
      loanTermMonthsNum;
    const preTaxMonthlyPayment =
      (totalDepreciation + totalFinanceCharge) / loanTermMonthsNum;
    const monthlyPaymentIncludingTax =
      preTaxMonthlyPayment * (1 + stateTaxRate);

    // Setting the initial balance to the sum of total depreciation and total finance charge
    let remainingBalance = totalDepreciation + totalFinanceCharge;

    let calculatedSchedule = [];
    for (let month = 1; month <= loanTermMonthsNum; month++) {
      const monthlyDepreciationCharge = totalDepreciation / loanTermMonthsNum;
      const monthlyFinanceCharge = totalFinanceCharge / loanTermMonthsNum;

      // Update remaining balance by subtracting both the monthly depreciation and finance charge
      remainingBalance -= monthlyDepreciationCharge + monthlyFinanceCharge;

      calculatedSchedule.push({
        month,
        // totalPayment: monthlyPaymentIncludingTax.toFixed(2),
        // principalPaid: monthlyDepreciationCharge.toFixed(2),
        // interest: monthlyFinanceCharge.toFixed(2),
        remainingBalance:
          remainingBalance > 0 ? remainingBalance.toFixed(2) : 0,
      });
    }

    setSchedule(calculatedSchedule);
  };

  const renderCell = (item: any, columnKey: any) => {
    switch (columnKey) {
      case "month":
        return <span>{item[columnKey]}</span>;
      // case "totalPayment":
      // case "principalPaid":
      // case "interest":
      case "remainingBalance":
        return <span>${money(item[columnKey])}</span>;

      default:
        return <span>{item[columnKey]}</span>;
    }
  };

  return (
    <div className="">
      <Table
        aria-label="table with dynamic content border-none"
        shadow={"none"}
        radius="lg"
        border={0}
        classNames={{
          thead: "[&>tr:last-child]:hidden",
        }}
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn
              className={`font-bold  text-md xl:text-xl text-[#070C84] border-2  border-black/50 ${
                column.uid !== "action" && "border-r-[#070C84] border-r-3"
              } p-3 text-center`}
              key={column.uid}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={schedule ?? []} className="break-all">
          {(item: any) => (
            <TableRow key={item.month} className="text-center">
              {(columnKey) => (
                <TableCell
                  className={`break-all text-sm xl:text-lg border-t-black/50 border-2  ${
                    columnKey === "action" ? "w-40" : ""
                  } `}
                >
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

// Define columns outside of the component to avoid re-creation on each render
const columns = [
  { name: "Month", uid: "month" },
  // { name: "Total Payment", uid: "totalPayment" },
  // { name: "Depreciation", uid: "principalPaid" },
  // { name: "Rent Charge", uid: "interest" },
  { name: "Remaining Balance", uid: "remainingBalance" },
];

export default LeaseAmortizationTable;
