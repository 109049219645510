import { SVGProps } from "react";

export const SearchIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="basil:search-solid">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6792 19.5265C16.0254 20.848 13.9283 21.486 11.8187 21.3096C9.70918 21.1331 7.74724 20.1556 6.33589 18.5778C4.92453 17 4.1709 14.9417 4.22977 12.8256C4.28864 10.7095 5.15555 8.69622 6.65245 7.19932C8.14934 5.70243 10.1626 4.83552 12.2787 4.77664C14.3948 4.71777 16.4532 5.47141 18.031 6.88276C19.6088 8.29412 20.5863 10.2561 20.7627 12.3656C20.9391 14.4752 20.3011 16.5722 18.9797 18.2261L25.3053 24.5505C25.3957 24.6347 25.4682 24.7363 25.5185 24.8492C25.5688 24.9621 25.5958 25.0839 25.598 25.2074C25.6002 25.331 25.5775 25.4537 25.5312 25.5683C25.4849 25.6829 25.416 25.7869 25.3287 25.8743C25.2413 25.9617 25.1372 26.0306 25.0226 26.0768C24.9081 26.1231 24.7853 26.1459 24.6618 26.1437C24.5383 26.1415 24.4164 26.1145 24.3035 26.0642C24.1907 26.0139 24.0891 25.9414 24.0048 25.851L17.6792 19.5265ZM7.9564 17.6102C7.05594 16.7096 6.44265 15.5624 6.19404 14.3134C5.94543 13.0644 6.07266 11.7697 6.55964 10.593C7.04663 9.41625 7.87151 8.41031 8.93004 7.70226C9.98858 6.99422 11.2332 6.61586 12.5068 6.615C13.7803 6.61415 15.0254 6.99083 16.0849 7.69745C17.1444 8.40407 17.9706 9.4089 18.4592 10.585C18.9478 11.761 19.0768 13.0555 18.8298 14.3049C18.5829 15.5542 17.9712 16.7023 17.0719 17.6041L17.0658 17.6102L17.0596 17.6151C15.8513 18.8206 14.2139 19.4973 12.507 19.4963C10.8001 19.4954 9.16343 18.817 7.9564 17.6102Z"
        fill="#263238"
      />
    </g>
  </svg>
);
