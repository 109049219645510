import { http } from "services/http/service.http";
import { FMOMemberShipType } from "./fmo-membership.type";
import { throwNotErrorSuccessResponse } from "config/retryConfig";


class FMOMemberShipEndpoint {
  public async SubmitFMOMembershipPayment(): Promise<any> {
    try{

      const res = await http.post(`/Transactions/SubmitFMOMembershipPayment`, {
        headers: {
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(res,res.data || "Failed to submit membership request! please try again.")
      return res.data;
    }catch(e){
      throw e
    }
  }

  public async ProcessFMOMembershipPayment(): Promise<any> {
    try{

      const res = await http.post(`/Transactions/ProcessFMOMembershipPayment`, {
        headers: {
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(res, res.data || "Failed to process the membership payment! please try again.")
    return res.data;
    }catch(e){
      throw e;
    }
  }

  
  public async GetFMOMembershipStatus(): Promise<FMOMemberShipType> {
    const res = await http.get(`/Transactions/GetFMOMembershipStatus`);
    return res.data;
  }
}

export const fmomembershipEndpoint = new FMOMemberShipEndpoint();
