import React from "react";
import { useNavigate } from "react-router-dom";
import FMO_logo from "./assets/FMO_logo.png";

const ShowQuotes: React.FC = () => {
  const quote = {
    referenceNumbers: [
      {
        number: 1,
      },
    ],
    quoteId: "",
    payload: {
      originAddress: {
        postalCode: "",
        streetAddress: "",
      },
      destinationAddress: {
        postalCode: "",
        streetAddress: "",
      },
    },
    price: {
      label: "",
      value: "",
    },
    calculatedPrices: {
      ushipServiceCharge: { label: "", value: "" },
    },
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="p-8 m-auto w-full md:w-2/3 font-[Metropolis-SemiBold]">
        <div className="w-20 m-auto mb-8 h-30">
          <img src={FMO_logo} alt="" />
        </div>
        <div className="flex flex-col mb-4">
          <div className="font-bold text-2xl text-start pl-20 mb-8 font-[Metropolis-Bold]">
            <h1>2023 Ford Bronco Outer Banks 4-Door 4WD</h1>
          </div>
          <div className="flex items-center justify-between pr-2 mb-4">
            <label className="w-1/5 mb-2 font-bold">Reference Number</label>
            <input
              type="text"
              value={quote.referenceNumbers[0].number}
              className="w-4/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex items-center justify-between pr-2 mb-4">
            <label className="w-1/5 mb-2 font-bold">Quote ID</label>
            <input
              type="text"
              value={quote.quoteId}
              className="w-4/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex items-center justify-between w-full pr-2 mb-4 md:w-1/2">
            <label className="w-2/5 mb-2 font-bold">Shipping Zip</label>
            <input
              type="text"
              value={quote.payload.originAddress.postalCode}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
          <div className="flex items-center justify-between w-full pr-2 mb-4 md:w-1/2">
            <label className="w-2/5 mb-2 font-bold">Price</label>
            <input
              type="text"
              value={quote.price.label}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
          <div className="flex items-center justify-between w-full pr-2 mb-4 md:w-1/2">
            <label className="w-2/5 mb-2 font-bold">
              Shipping Street Address
            </label>
            <input
              type="text"
              value={quote.payload.originAddress.streetAddress}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
          <div className="flex items-center justify-between w-full pr-2 mb-4 md:w-1/2">
            <label className="w-2/5 mb-2 font-bold">Service Charge</label>
            <input
              type="text"
              value={quote.calculatedPrices.ushipServiceCharge.label}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
          <div className="flex w-full pr-2 mb-4 md:w-1/2 items-cente justify-betweenr">
            <label className="w-2/5 mb-2 font-bold">Destination ZIP</label>
            <input
              type="text"
              value={quote.payload.destinationAddress.postalCode}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
          <div className="flex items-center justify-between w-full pr-2 mb-4 md:w-1/2">
            <label className="w-2/5 mb-2 font-bold">Total Price</label>
            <input
              type="text"
              value={`$${
                quote.calculatedPrices.ushipServiceCharge.value +
                quote.price.value
              }`}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
          <div className="flex items-center justify-between w-full pr-2 mb-4 md:w-1/2">
            <label className="w-2/5 mb-2 font-bold">
              Destination Street Address
            </label>
            <input
              type="text"
              value={quote.payload.destinationAddress.streetAddress}
              className="w-3/5 p-2 border border-gray-400 rounded-lg"
            />
          </div>
        </div>
        <div className="flex justify-center md:justify-end">
          <button
            type="button"
            className="text-white bg-gradient-to-r from-[#018FFD] to-[#070C84] hover:bg-gradient-to-br font-medium rounded-md text-sm text-center h-10 w-1/3 md:w-1/5 mt-2 mb-2 mr-8 md:mr-0"
            onClick={() => alert("It is under development.")}
          >
            Save Quote
          </button>
          <button
            type="button"
            className="text-white bg-gradient-to-r from-[#018FFD] to-[#070C84] hover:bg-gradient-to-br font-medium rounded-md text-sm text-center h-10 w-1/3 md:w-1/5 mt-2 mb-2 ml-0 md:ml-8"
            onClick={() => navigate("/shipping-quotes/payment-form")}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default ShowQuotes;
