import { configureStore } from "@reduxjs/toolkit";
import dmvReducer from "./dmv-slice";
import shippingSlice from "./shipping";

import manageListReducer from "./manage-list";
import userInformation from "./user-information";
import sellerOfferPriceSlice from "./seller-offer-price";
import acceptOfferReducer from "modules/user/store/accept-offer-slice";
import vehicleConditionReducer from "./vehicle-condition";
// import authReducer from "./auth";

export const store = configureStore({
  reducer: {
    acceptOffer: acceptOfferReducer,
    dmv: dmvReducer,
    // auth: authReducer
    user: userInformation,
    list: manageListReducer,
    shipping: shippingSlice,
    sellerOffer: sellerOfferPriceSlice,
    vehicleCondition: vehicleConditionReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
