import React, { useState } from "react";
import { Button } from "@nextui-org/react";
import { UploadDocumentsBulk } from "pages/dealer/manage-listing/upload-document-bulk.component";
import { SelectNextUi, Typography } from "components";
import SingleUploadSectionBhph from "./SingleUploadFile.component";
import { UploadTypesForDoc } from "modules/dealer/features/Listings/enum/UploadTypes";
import { ViewLoadedDocumentListing } from "modules/dealer/features/Listings/components/ViewLoadedDocumentListing";
import { IBhphRequestType } from "modules/dealer/store/seller-offer-price";

interface UploadPhotosModalProps {
  onClose?: () => void;
  listingId?: number | undefined;
  isItCreate: boolean;
  initDoc: string[];
  typeOfferBhph? : IBhphRequestType
}

export const UploadDocumentsBhph: React.FC<UploadPhotosModalProps> = ({
  onClose,
  listingId,
  isItCreate,
  initDoc,
  typeOfferBhph
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadType, setUploadType] = useState<UploadTypesForDoc>(
    isItCreate ? UploadTypesForDoc.upload : UploadTypesForDoc.view
  );
  const [selectedIncomType, setSelectedIncomeType] =
    useState<string>("bank_states");
  const [files, setFiles] = useState<File[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const [canCreateList, setCanCreateList] = useState<boolean>(false);

  const handleCreateList = async () => {
    if (listingId && files.length >= 0) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("listingId", String(listingId));
      files.forEach((flx, index) => {
        formData.append(`uploadfiles`, flx);
      });
      setCanCreateList(false);
      onClose && onClose()
      setIsLoading(false);
    }

  };

  const getActiveClassName = (
    type: UploadTypesForDoc.upload | UploadTypesForDoc.view
  ) => {
    return uploadType === type
      ? "bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg  tracking-wide px-6 rounded-xl  w-full md:w-auto"
      : "  w-full md:w-auto text-blue-500 text-sm md:text-lg  tracking-wide px-6 rounded-xl";
  };

  return (
    <div className="w-full  " id={"SelectIncomeTypeBhphSelect"}>
      <div>
        <Typography
          variant="custom"
          className="font-[Metropolis] text-xl my-4 font-[700] md:text-2xl  text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text"
        >
          Upload Documents
        </Typography>
      </div>
      <div className="w-full  my-8 mb-8 ">
        { typeOfferBhph ==="Standard_Financing" ? (
          <SingleUploadSectionBhph label="Drivers License" />
        ) : (
          <>
            <SelectIncomeTypeBhph
              onHandleChange={(val: string) => setSelectedIncomeType(val)}
            />
            <SingleUploadSectionBhph label="Drivers License" />
            <SingleUploadSectionBhph label="Proof of Residency" />

            {selectedIncomType !== "One_Bank_Statement" ?<div>
              <Typography
                variant="custom"
                className="font-[Metropolis] text-base my-4 font-[500] md:text-xl  text-center mb-4 mt-8 bg-clip-text"
              >
                {
                  IncomeType.find((item) => item.value == selectedIncomType)
                    ?.label
                }
              </Typography>
              <div className="w-full flex items-center justify-between md:justify-center mb-8 gap-4">
                <Button
                  onClick={() => setUploadType(UploadTypesForDoc.upload)}
                  className={getActiveClassName(UploadTypesForDoc.upload)}
                  type="button"
                >
                  Bulk Upload
                </Button>

                <Button
                  onClick={() => setUploadType(UploadTypesForDoc.view)}
                  className={getActiveClassName(UploadTypesForDoc.view)}
                  type="button"
                >
                  Single Upload
                </Button>
              </div>
            </div>:<SingleUploadSectionBhph label="One Bank Dtatement" />
          }
          </>
        )}
      </div>
      {selectedIncomType !== "One_Bank_Statement" && <>{uploadType === UploadTypesForDoc.upload?  <UploadDocumentsBulk
        files={files}
        setFiles={setFiles}
        canCreateList={canCreateList}
        setCanCreateList={(canCreate: boolean) => setCanCreateList(canCreate)}
        listingId={listingId}
        setIsEdited={setIsEdited}
      />:(<div>
        {["Document 1","Document 2", "Document 3", "Document 4"].map((file=>{
          return(<ViewLoadedDocumentListing  file={{name:file}} showBtns={true}/>)
        }))}
      </div>)}</>}

      <div className="mt-2 md:mt-10 pb-10 md:pb-5 flex flex-col gap-2 items-center justify-center">
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg py-6 md:py-8 tracking-wide px-10 rounded-xl"
          isLoading={isLoading}
          isDisabled={
            isItCreate
              ? isLoading || files.length < 1
              : isLoading || files.length < 1 || !isEdited
          }
          // isDisabled={isItCreate ?((aiOnState?aiCarDetails.length <4 :files.length < 4) || isLoading || !canCreateList) : ((mainImg?.name === coverImg?.name && !isEdited ) || isLoading  || (aiOnState?aiCarDetails.length <4 :files.length < 4))}
          onClick={handleCreateList}
        >
          {"Continue"}
        </Button>
      </div>
    </div>
  );
};

const IncomeType = [
  { label: "12 months bank statements", value: "bank_states" },
  { label: "12 Most Recent Pay stubs", value: "Pay_stubs" },
  {
    label: "One month bank statement showing 50% of total vehicle price",
    value: "One_Bank_Statement",
  },
];
const SelectIncomeTypeBhph = ({
  onHandleChange,
}: {
  onHandleChange: (val: string) => void;
}) => {
  return (
    <div className=" grid grid-cols-1 gap-0 md:gap-2 mb-6 md:grid-cols-3 justify-items-left h-[80] md:h-[40] formControl ">
      <div className="text-left text-[14px] font-bold h-full flex items-end md:items-center">
        {"Please select income type"}
      </div>{" "}
      <div className="md:mt-0 col-span-1  md:col-span-2 w-full md:w-auto flex gap-2   ">
        <SelectNextUi
          placeholder="Select House Type"
          size="sm"
          aria-label="Select Vehicle Type"
          className="bg-transparent "
          data={IncomeType}
          register={{
            onChange: (e: any) => {
              onHandleChange(e.target.value);
            },
          }}
          defaultSelectedKeys={["bank_states"]}
          portalId="SelectIncomeTypeBhphSelect"
        />
      </div>
    </div>
  );
};
