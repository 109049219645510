import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../../components/navbar/index";
import DmvPriceingModals from "./DmvPriceingModals";
import PaymentForm from "../../features/shipping-quotes/PaymentForm";
import SendOTP from "../../features/shipping-quotes/SendOTP";
import { PayModal } from "../../../shared/components/modal/PayModal";
import done_icon from "../../features/shipping-quotes/assets/done-icon.png";
import { useNavigate } from "react-router-dom";
const DmvEnterMissingDetails: React.FC = () => {
  const [openPriceModal, setOpenPriceModal] = useState<boolean>(false);
  const [showPayModal, setShowPayModal] = useState<number | null>(null);
  const [data, setData] = useState({
    State: "",
    County: "",
    City: "",
    "ZIP Code": "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenPriceModal(true);
  };
  useEffect(() => {
    const quote = {
      calculatedPrices: {
        ushipServiceCharge: {
          value: 50, // Adjust this value as needed
        },
      },
      price: {
        value: 100, // Adjust this value as needed
      },
    };

    localStorage.setItem("qoute", JSON.stringify(quote));
  }, []);

  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/dealer-dashboard/fee-details", { state: { priceOptId: "2" } });
  };
  return (
    <React.Fragment>
      <Navbar type={"dmv-titling"}>
        <div className="flex flex-col items-center mx-8">
          <h1 className="text-3xl font-bold mb-4 font-[Metropolis-Bold] mt-8">
            Enter Missing Details
          </h1>
          <p className="font-[Metropolis-bold] text-center text-md text-gray-700 w-full px-4 md:px-32">
            We audit the vehicles details and the buyer seller details and
            invalidate any fields that we do not have the info on.
          </p>
          <div className="justify-center items-center  sm:w-full md:w-full lg:max-w-[56rem] mt-8">
            <div className="bg-slate-50 px-6 py-6 shadow-right rounded-lg">
              <form onSubmit={handleSubmit}>
                <div className="grid gap-3 pt-6">
                  {["State", "County", "City", "ZIP Code"].map(
                    (label, index) => (
                      <div
                        key={index}
                        className="flex flex-col md:flex-row items-center justify-between"
                      >
                        <label className="mt-1 text-xl font-[Metropolis-bold] text-[#263238] md:min-w-[9rem] md:pl-10">
                          {label}
                        </label>
                        <input
                          className="border-2 text-xl font-[Metropolis-bold] bg-transparent md:w-full md:max-w-[38rem] border-black rounded-lg p-2 mb-2 sm:mb-0 md:mr-10"
                          name={Object.keys(data)[index]}
                          onChange={handleChange}
                        />
                      </div>
                    )
                  )}

                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <label className="mt-1 text-xl font-[Metropolis-bold] text-[#263238] md:min-w-[9rem] md:pl-10">
                      Transfer Types
                    </label>
                    <select className="bg-transparent text-xl md:w-full md:max-w-[38rem] rounded-lg p-2 mb-2 sm:mb-0 md:mr-10 border-2 border-gray-300 text-gray-900    focus:ring-blue-500 focus:border-blue-500 block   dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-200 dark:text-gray-500 dark:focus:ring-gray-500 dark:focus:border-gray-600">
                      <option selected className="">
                        Select your Transfer Type
                      </option>
                      <option value="US">Purchase Cash</option>
                      <option value="US">Purchase With Loan</option>
                    </select>
                  </div>

                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <p className="mt-1 text-xl font-[Metropolis-bold] text-[#263238]  md:pl-10">
                      Lien
                    </p>
                    <label className="inline-flex items-center me-5 ml-2 cursor-pointer p-2 mb-2 sm:mb-0 md:mr-10">
                      <input
                        type="checkbox"
                        className="sr-only peer outline-none"
                      />
                      <div className="relative w-11 h-6 bg-gray-300 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-400 peer-checked:bg-blue-600"></div>
                    </label>

                    <p className="mt-1 text-xl font-[Metropolis-bold]  text-[#263238] md:pl-10">
                      Transfering Plates
                    </p>
                    <label className="inline-flex items-center me-5 ml-2 cursor-pointer p-2 mb-2 sm:mb-0 md:mr-10">
                      <input
                        type="checkbox"
                        className="sr-only peer outline-none"
                      />
                      <div className="relative w-11 h-6 bg-gray-300 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-400 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>

                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <label className="mt-1 text-xl font-[Metropolis-bold] md:min-w-[9rem] text-[#263238] md:pl-10">
                      {"New/Used"}
                    </label>
                    <input
                      className="border-2 text-xl md:w-full md:max-w-[8rem] text-center border-black rounded-lg p-2 mb-2 sm:mb-0 "
                      defaultValue={"Used"}
                      disabled
                    />

                    <label className="mt-1 text-xl font-[Metropolis-bold] text-[#263238] md:pl-10">
                      {"VIN"}
                    </label>
                    <input
                      className="border-2 text-xl md:w-full text-center md:max-w-[16rem] border-black rounded-lg p-2 mb-2 sm:mb-0 md:mr-10"
                      defaultValue={"3FMCR9B63PRE19717"}
                      disabled
                    />
                  </div>

                  <button
                    type="submit"
                    className="mt-4 ml-auto text-xl font-[Metropolis-bold] block px-12 py-4 text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] md:mr-10"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <DmvPriceingModals
          open={openPriceModal}
          handleClose={(step) => {
            setOpenPriceModal((prev) => !prev);
            if (step === "1") {
              setShowPayModal(0);
            }
          }}
        />
        <PayModal
          isOpen={showPayModal === 0}
          onClose={() => setShowPayModal(null)}
        >
          <PaymentForm showmodal={true} handleNext={() => setShowPayModal(1)} />
        </PayModal>
        <PayModal
          isOpen={showPayModal === 1}
          onClose={() => setShowPayModal(null)}
        >
          {" "}
          <SendOTP isInModal={true} handleNext={() => setShowPayModal(2)} />
        </PayModal>
        <PayModal
          isOpen={showPayModal === 2}
          onClose={() => setShowPayModal(null)}
        >
          <div className=" ">
            <img className="m-auto h-56 w-56" src={done_icon} alt="" />
            <div
              className="text-4xl -mt-8 font-bold text-center text-blue-900"
              style={{ fontFamily: "Metropolis-Bold" }}
            >
              Payment Successful
            </div>
            <div
              className="flex justify-center mt-4 mb-8"
              style={{ fontFamily: "Metropolis-SemiBold" }}
            >
              <button
                className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 w-2/5 text-white font-bold py-3 px-4 mt-4 rounded-xl focus:outline-none"
                onClick={() => {
                  setShowPayModal(null);
                  handleContinue();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </PayModal>
      </Navbar>
    </React.Fragment>
  );
};

export default DmvEnterMissingDetails;
