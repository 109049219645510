import { Spacer } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Typography } from "components";
import { ViewCarDetails } from "./details-view-car.component";
// import { BidViewCar } from "./bid-view-car.component";
import { AccordionViewCar } from "./accordion-view-car.component";
import { RetailValueEstimate } from "pages/shared";
import GetPriceData from "core/http/listing/GetPriceData";
import { ListingDetailsSnapshot, useGetListingDetails } from "services";
import { findImage, money } from "utils";
import { VehicleListingDescription } from "pages/shared";
import Loading from "components/Loading/Loading";
import { useGetCreditCardMethodStatusData } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { localStorageFn } from "utils/localstorage";
import { ApplyFinancePublic } from "./apply-finance-public.component";

const ViewSingleCarPage = () => {
  const params = useParams<{ id: string }>();
  const isLogin = !!localStorageFn.getItem("access_token");
  const [images, setImages] = useState<ListingDetailsSnapshot[]>([]);
  
  const [priceData, setPriceData] = useState<any>({});
  
  const { data: listingDetailsData, isLoading } = useGetListingDetails(
    Number(params?.id) ?? 0
  );
  const {  data: checkIfPaymentExist, refetch: refetchPaymentForCardDetail } =
  useGetCreditCardMethodStatusData(isLogin);
  

  const listing = listingDetailsData?.listing;
  const snapshot = listingDetailsData?.snapshot;
  const getDefaultRetailValue = async (period: number) => {
    if (listing) {
      await GetPriceData(listing?.vin, period ?? 180, 0).then((response) => {
        if (response?.success) {
          setPriceData(response);
        }
      });
    }
  };

  const plotImages = useCallback(
    (snapshot: ListingDetailsSnapshot[] | undefined) => {
      if (snapshot) {
        for (const ss of snapshot) {
          const filteredImages = findImage(ss?.value);
          if (filteredImages && (ss.groupName === "Images" || ss.code === "Primary_Image"))
            setImages((prev: any[]) => prev.concat(ss));
        }
      }
    },
    []
  );

  let parsedDescription;
  try {
    parsedDescription = JSON.parse(
      listingDetailsData?.listing?.description || "{}"
    );
  } catch (error) {
    console.error("Failed to parse description:", error);
    parsedDescription = {};
  }

  useEffect(() => {
    plotImages(snapshot);
  }, [plotImages, snapshot, snapshot?.length]);

  useEffect(() => {
    getDefaultRetailValue(180);
  }, [listing]);
 
  if (isLoading) return <Loading />;
  return (
    <div>
      <div className="grid flex-col justify-items-center mb-6">
        <Typography variant="pageTitle">
          {listing?.name ?? "Not Available"}
        </Typography>
        <Typography
          variant="custom"
          className="text-2xl font-[700] md:text-3xl xl:text-[30px] text-[#263238] md:text-justify text-center"
        >
          ${money(+(listing?.price||0)) ?? "0"} 
        </Typography>
        <Typography
          variant="custom"
          className="text-xl font-[700] md:text-2xl xl:text-[25px] text-[#263238] md:text-justify text-center"
        >
          {money(+(listing?.mileage||0)) ?? "0"} Miles
        </Typography>
      </div>
      {images.length > 0 && <ViewCarDetails imagesData={images} />}

      <ApplyFinancePublic listing={listing} />
      {!isLoading && priceData && (
        <RetailValueEstimate
          onCallBack={getDefaultRetailValue}
          retailEstimate={priceData}
        />
      )}

      <VehicleListingDescription
        listingDescription={parsedDescription}
        carModel={priceData.vehicle}
      />
      <AccordionViewCar
        listingId={params?.id || ""}
        vinNumber={listing?.vin ?? ""}
        isCardAdded={checkIfPaymentExist}
        listing={listing}
        refetchPaymentForCardDetail={refetchPaymentForCardDetail}
      />
      <Spacer y={20} />
    </div>
  );
};

export default ViewSingleCarPage;
