import { SelectNextUi, TextErrorHelper, Toggle, Typography } from "components";
import {
  dmvDocumentServiceSignal,
  enterMissingInfoDefaultSignal,
} from "../store";
import Input from "components/ui/input";
import { ListingZipCodeDetailsResponse } from "services";
import { states } from "utils";

interface Place {
  city: string;
  county: string;
  state: string;
  stateAbbr: string;
}
interface VehicleTitledInProps {
  register: any;
  errors: any;
  transferPlates: boolean;
  handleTransferPlates: () => void;
  setZipValue: (value: string) => void;
  zipcodeData: ListingZipCodeDetailsResponse | undefined;
  setPlace: React.Dispatch<React.SetStateAction<Place>>;
  place: Place;
}
export const VehicleTitledIn: React.FC<VehicleTitledInProps> = ({
  register,
  errors,
  transferPlates,
  handleTransferPlates,
  setZipValue,
  zipcodeData,
  setPlace,
  place,
}) => {
  return (
    <>
      <FormControl  >
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Vehicle Currently Registered in
        </Typography>
        <div className="col-span-3 flex flex-col gap-2">
          <SelectNextUi
            placeholder="Select Titled State"
            className="col-span-3"
            aria-label="Select Titled State"
            data={states}
            register={register("vehicleToBeTitledIn.titledState")}
            portalId="dmvformdatails"
          />
          <TextErrorHelper className="ml-1">
            {errors.errors.vehicleToBeTitledIn?.titledState?.message}
          </TextErrorHelper>
        </div>
      </FormControl>
      <Typography
        variant="custom"
        className="font-[800] text-[20px] text-[#313131] my-10"
      >
        Vehicle to be Titled in:
      </Typography>
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          Zip
        </Typography>
        <Input
          variant="form"
          size="lg"
          className="col-span-3"
          classNames={{
            input: "px-4 text-center",
          }}
          defaultValue={dmvDocumentServiceSignal.value?.zipcode ?? ""}
          errorMessage={errors.errors.vehicleToBeTitledIn?.zipcode?.message}
          {...register("vehicleToBeTitledIn.zipcode")}
          onChange={(e) => setZipValue(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          State
        </Typography>
        <Input
          isReadOnly
          variant="form"
          size="lg"
          className="col-span-3"
          classNames={{
            input: "px-4 text-center",
          }}
          value={place?.state ?? ""}
          errorMessage={errors.errors.vehicleToBeTitledIn?.state?.message}
          {...register("vehicleToBeTitledIn.state")}
          onChange={(e) => {
            setPlace((prev) => ({
              ...prev,
              state: e.target.value,
            }));
          }}
        />
      </FormControl>
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          County
        </Typography>
        <Input
          isReadOnly
          variant="form"
          size="lg"
          className="col-span-3"
          classNames={{
            input: "px-4 text-center",
          }}
          value={place?.county ?? ""}
          errorMessage={errors.errors.vehicleToBeTitledIn?.county?.message}
          {...register("vehicleToBeTitledIn.county")}
          onChange={(e) => {
            setPlace((prev) => ({
              ...prev,
              county: e.target.value,
            }));
          }}
        />
      </FormControl>
      <FormControl>
        <Typography
          variant="custom"
          className="text-[#263238] font-[600] text-left col-span-2"
        >
          City
        </Typography>
        <Input
          isReadOnly
          variant="form"
          size="lg"
          className="col-span-3"
          classNames={{
            input: "px-4 text-center",
          }}
          value={place?.city ?? ""}
          errorMessage={errors.errors.vehicleToBeTitledIn?.city?.message}
          {...register("vehicleToBeTitledIn.city")}
          onChange={(e) => {
            setPlace((prev) => ({
              ...prev,
              city: e.target.value,
            }));
          }}
        />
      </FormControl>
      {enterMissingInfoDefaultSignal.value.transferPlates.map((field, idx) => {
        return (
          <FormControl key={`${field}-${idx}`}>
            {!transferPlates && field.isDisabled ? null : (
              <Typography
                variant="custom"
                className="text-[#263238] font-[600] text-left col-span-2"
              >
                {field.label}
              </Typography>
            )}

            {field.name === "transferingPlates" ? (
              <Toggle
                size="md"
                checked={transferPlates}
                color="default"
                {...register(`vehicleToBeTitledIn.${field.name}` as any)}
                onChange={handleTransferPlates}
              />
            ) : field.name === "zipcode" ? (
              <Input
                variant="form"
                size="lg"
                className="col-span-3"
                classNames={{
                  input: "px-4 text-center",
                  inputWrapper: `${
                    field.isDisabled && !transferPlates ? "border-gray-500" : ""
                  }`,
                }}
                defaultValue={
                  (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
                }
                errorMessage={
                  (errors.errors.vehicleToBeTitledIn as any)?.[field.name]
                    ?.message
                }
                {...register(`vehicleToBeTitledIn.${field.name}` as any)}
                onChange={(e) => setZipValue(e.target.value)}
              />
            ) : !transferPlates && field.isDisabled ? null : (
              <Input
                variant="form"
                size="lg"
                className="col-span-3"
                classNames={{
                  input: "px-4 text-center",
                  inputWrapper: `${
                    field.isDisabled && !transferPlates ? "border-gray-500" : ""
                  }`,
                }}
                defaultValue={
                  (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
                }
                isDisabled={field.isDisabled && !transferPlates}
                errorMessage={
                  (errors.errors.vehicleToBeTitledIn as any)?.[field.name]
                    ?.message
                }
                {...register(`vehicleToBeTitledIn.${field.name}` as any)}
              />
            )}
          </FormControl>
        );
      })}
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
