import { Typography } from "../typography";

interface TextErrorHelperProps {
  children: React.ReactNode;
  className?: string;
}

export const TextErrorHelper: React.FC<TextErrorHelperProps> = ({
  children,
  className,
}) => {
  return (
    <Typography
      variant="custom"
      className={`text-[#f31260] text-[0.75rem] font-[600] leading-4 -ml-0.5 mt-1 tracking-wide ${className}`}
    >
      {children}
    </Typography>
  );
};
