import { SVGProps } from "react";

export const ViewIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="15.5912"
      cy="16.142"
      r="14.8669"
      fill="url(#paint0_linear_9177_13298)"
      stroke="white"
      stroke-width="0.612768"
    />
    <g clip-path="url(#clip0_9177_13298)">
      <path
        d="M15.5376 14.2414C16.0271 14.2414 16.4966 14.4359 16.8428 14.782C17.189 15.1282 17.3834 15.5977 17.3834 16.0872C17.3834 16.5768 17.189 17.0463 16.8428 17.3925C16.4966 17.7386 16.0271 17.9331 15.5376 17.9331C15.0481 17.9331 14.5786 17.7386 14.2324 17.3925C13.8862 17.0463 13.6918 16.5768 13.6918 16.0872C13.6918 15.5977 13.8862 15.1282 14.2324 14.782C14.5786 14.4359 15.0481 14.2414 15.5376 14.2414ZM15.5376 11.4727C18.614 11.4727 21.2412 13.3862 22.3057 16.0872C21.2412 18.7883 18.614 20.7018 15.5376 20.7018C12.4612 20.7018 9.83396 18.7883 8.76953 16.0872C9.83396 13.3862 12.4612 11.4727 15.5376 11.4727ZM10.1108 16.0872C10.6081 17.1026 11.3803 17.9582 12.3397 18.5565C13.299 19.1549 14.407 19.4721 15.5376 19.4721C16.6682 19.4721 17.7762 19.1549 18.7355 18.5565C19.6949 17.9582 20.4671 17.1026 20.9644 16.0872C20.4671 15.0718 19.6949 14.2163 18.7355 13.618C17.7762 13.0196 16.6682 12.7024 15.5376 12.7024C14.407 12.7024 13.299 13.0196 12.3397 13.618C11.3803 14.2163 10.6081 15.0718 10.1108 16.0872Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_9177_13298"
        x1="3.61234"
        y1="0.96875"
        x2="27.3039"
        y2="35.3083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#018FFD" />
        <stop offset="1" stop-color="#070C84" />
      </linearGradient>
      <clipPath id="clip0_9177_13298">
        <rect
          width="14.7667"
          height="14.7667"
          fill="white"
          transform="translate(8.15234 8.70703)"
        />
      </clipPath>
    </defs>
  </svg>
);
