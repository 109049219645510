import React, { useEffect, useState } from "react";
import "./../../../modules/dealer/components/enter-vin/EnterVIN.scss";
import {
  EnterVIN,
  VinDataType,
} from "../../../modules/dealer/components/enter-vin/type";
import apiClient from "../../../modules/dealer/utils/apiClient";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { PaymentModal } from "pages/shared";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import {
  useAddPaymenTransactiontMethod,
  useAddPaymentOptionForPublicUserMethod,
  useGetCreditCardMethodStatusData,
} from "hooks/api/mutations/shipping/use-shipping-quotes";

import { useGetPriceAdvanceDataPdf } from "services";
import { Button, Typography } from "components";
import Loading from "components/Loading/Loading";
import { useProcessFMOReportSubscription } from "hooks/api/queries/view-cars/view-cars";
import { localStorageFn } from "utils/localstorage";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";

const VehicleHistoryReports = () => {
  const isLogin = !!localStorageFn.getItem("access_token");

  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [vinNumber, setVinNumber] = useState<EnterVIN>({
    data: "",
    error: false,
  });
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);
  const {
    mutateAsync: mutateAddPaymenTransactiontMethodForPublicUserMethod,
    isPending: isPendingToAddPaymenTransactiontMethodForPublicUserMethod,
    error: hasPaymentError,
  } = useAddPaymentOptionForPublicUserMethod(
    vinNumber.data,
    "FMO_MARKET_VALUE"
  );
  //payment
  const { data: checkIfPaymentExist, refetch: refetchDataForCard } =
    useGetCreditCardMethodStatusData(isLogin);

  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: errorMesForAppPayment,
  } = useAddPaymenTransactiontMethod();

  const {
    data: priceAdvanceData,
    refetch: refetchPriceAdvanceData,
    isLoading,
  } = useGetPriceAdvanceDataPdf(vinNumber.data || "", false);
  const {
    mutateAsync: mutateProcessFMOReportSubscription,
    isPending: isPendingToProcessFMOReportSubscription,
    error: errorForReportProceess,
  } = useProcessFMOReportSubscription("0", vinNumber.data);

  const [showPayModal, setShowPayModal] = useState<number | null>(0);

  useEffect(() => {
    let interval: any = null;
    if (step === 1) {
      if (!interval) {
        interval = setInterval(() => {
          if (progress < 99) {
            setProgress((p) => p + 20);
          } else {
            setProgress(100);
            setStep(0);
            clearInterval(interval);
            if (vinNumber.error) {
              setShowPayModal(0);
            } else {
              setShowPayModal(1);
            }
          }
        }, 500);
      }
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [step, progress]);

  React.useEffect(() => {
    if (priceAdvanceData) {
      const blob = new Blob([priceAdvanceData], { type: "application/pdf" });
      setPdfUrl(URL.createObjectURL(blob));
    }
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [priceAdvanceData]);

  const fetchVinData = async () => {
    try {
      if (vinNumber.data.length <= 0 || vinNumber.error) {
        throw new Error("len not right");
      }

      const response = await apiClient.get(
        `/VinData/DecodeVinData?vin=${vinNumber.data}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );
      setStep(1);

      const err =
        response.data?.message?.find(
          (data: VinDataType) => data.code === "ErrorCode"
        ).value !== "0";
      setVinNumber((prev) => ({
        ...prev,
        error: err,
      }));
    } catch (error) {
      console.error("Error fetching VIN data:", error);
      setVinNumber((prev) => ({ ...prev, error: true }));
    }
  };

  const handleConfirmPaymentForOpt = async (e: any) => {
    try {
      if (isLogin) {
        if (!checkIfPaymentExist) {
          await mutateAddPaymenTransactiontMethod(e);
          refetchDataForCard();
        }
        await mutateProcessFMOReportSubscription("FMO_OPTIONS_DATA_VALUE");
      } else {
        await mutateAddPaymenTransactiontMethodForPublicUserMethod(e);
        // await mutateProcessFMOReportSubscription("FMO_OPTIONS_DATA_VALUE");
      }
      // await mutateProcessFMOReportSubscription("FMO_MARKET_VALUE");
      setShowPayModal(2);
      refetchPriceAdvanceData();
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber((prev) => ({ ...prev, data: e.target.value.trim().toLocaleUpperCase() }));
    const len = e.target.value.trim().length;
    if (len !== 17) {
      setVinNumber((prev) => ({ ...prev, error: len !== 0 }));
    } else {
      setVinNumber((prev) => ({ ...prev, error: false }));
    }
  };
  return (
    <React.Fragment>
      {/* <NavBar type={"enter-scan"}> */}
      <div className="flex flex-col justify-center items-center gap-12 md:p-2 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text 2xl:text-5xl text-3xl font-bold mb-10">
            Vehicle History Report
          </p>
        </Typography>
        {isLoading && <Loading />}
        {showPayModal === 3 && (
          <div className={` w-full  flex justify-between `}>
            <div></div>
            <Button
              className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl"
              type="button"
              size="md"
              onClick={() => {
                setShowPayModal(0);
                setStep(0);
              }}
            >
              <Typography className="font-bold" variant="body">
                {" "}
                New Vehicle History Report
              </Typography>
            </Button>
          </div>
        )}
        {pdfUrl && showPayModal === 3 ? (
          <iframe
            title="PDF Viewer"
            src={pdfUrl}
            width="100%"
            height="500px"
          ></iframe>
        ) : (
          //   step === 0 ?
          // <div className="max-w-[42rem] w-full   ">
          //    <PayNow title="Factory Installed Options" price={4.99} prevPrice={9.99} onPay={()=>{setShowPayModal(1)}} />
          //    </div>
          //  :
          <div className="flex items-center w-full md:w-auto m-auto enter-vin">
            <div className="flex items-center w-full md:w-auto  mx-auto bg-fixed bg-center bg-cover ">
              <form className="flex flex-col px-8 py-12 rounded-lg shadow-md gradiant-box-vin md:px-8">
                <div className="min-w-0 xl:min-w-[650px] flex flex-col justify-center mb-12">
                  <label
                    htmlFor="yourInput1"
                    className={`text-center mb-4 ${
                      vinNumber.error ? "text-red-500" : "text-white"
                    } font-bold  md:text-2xl text-lg `}
                  >
                    {"Enter VIN Number"}
                  </label>
                  <div className="relative w-full text-center">
                    <input
                      type="text"
                      id="yourInput1"
                      name="yourInput1"
                      onChange={onChangeVIN}
                      value={vinNumber.data}
                      className={`w-full md:w-3/4 text-center border-none focus:outline-none bg-transparent ${
                        vinNumber.error ? "text-red-500" : "text-white"
                      } font-semibold text-lg md:text-4xl `}
                    />
                    <div
                      className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-full md:w-3/4 h-[1.5px]  ${
                        vinNumber.error ? "bg-red-500" : "bg-white"
                      }`}
                    ></div>
                    {vinNumber.error && (
                      <div
                        className={`absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-full md:w-3/4
                  flex  items-center justify-center text-[#FF0A0A]`}
                      >
                        <ErrorInfoIcon className="-mt-[4px] w-[16px] h-[16px] mr-[2px]" />
                        <label className="text-[16px]">
                          Please enter Valid VIN
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:flex items-center justify-center w-full text-center md:text-left">
                  <button
                    onClick={() => {
                      fetchVinData();
                    }}
                    className=" md:mr-6 mt-5 w-3/4 max-w-[200px] py-2 text-lg text-white rounded-lg  confirm-btn-fin focus:outline-none focus:shadow-outline md:text-2xl"
                    type="button"
                  >
                    {"Confirm"}
                  </button>
                </div>
              </form>

              {step === 1 && (
                <div
                  style={{ zIndex: 150 }}
                  className="flex overflow-y-auto p-4 overflow-x-hidden fixed items-center  modal-container-vin  justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full"
                >
                  <div className="absolute inset-0 bg-black opacity-50 z-999"></div>
                  <div className="relative gradiant-modal-loading h-[450px] py-24 px-10  md:px-24  mx-auto  rounded-lg shadow-md ">
                    <div className="flex justify-center mb-4 text-lg font-bold text-white md:text-2xl">
                      Searching for Data
                    </div>
                    <div className="flex justify-center mb-16 text-2xl font-bold text-white md:text-4xl">
                      {vinNumber.data}
                    </div>

                    <div className="flex justify-center mb-2">
                      <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-base">
                        {" "}
                        Please wait while findmyoptions.com queries your VIN
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <div className="loader-container">
                        <div
                          className="loader-progress"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <PaymentModal
          isOpen={showPayModal === 1 || showPayModal === 2}
          isPublic={true}
          onOpen={() => {
            if (showPayModal === 2) {
              setShowPayModal(3);
            } else {
              setShowPayModal(0);
            }
          }}
          Component={
            showPayModal === 1 ? (
              <PaymentFormWithDetails
                handleNext={handleConfirmPaymentForOpt}
                payAmmount={"4.99"}
                discountPrice={"9.99"}
                savedMethod={checkIfPaymentExist}
                isPending={
                  isPendingToAddPaymenTransactiontMethodForPublicUserMethod ||
                  isPendingToAddPaymenTransactiontMethod ||
                  isPendingToProcessFMOReportSubscription
                }
                errorMessage={
                  hasPaymentError?.message ||
                  errorMesForAppPayment?.message ||
                  errorForReportProceess?.message
                }
              />
            ) : (
              <ThankYou textNote="Payment Successful" onClick={() => setShowPayModal(3)} />
            )
          }
        />
      </div>

      {/* </NavBar> */}
    </React.Fragment>
  );
};

export default VehicleHistoryReports;
