import React, { useEffect } from "react";
import "./index.scss";
import TierServicesSection from "./TierServicesSection";
export default function ViewDealerServicesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const tierServices = [
    {
      title:
        "The ONLY Cloud Software Solution specifically designed for Buy Here Pay Here Dealerships",
      tiers: [

        {
          title:
            "Buy Here Pay Here Loan Origination Software:",
          subTitle:
            "We created an unmatched automated lending platform for buy here pay here loans, experience the difference with instant pre-approvals, contracts and forms and a servicing platform for keeping track of your borrowers payments.",
        },
        
        {
          title:
            "Web Hosting & Integrated Inventory Management:",
          subTitle:
            "Bid farewell to the complexities of managing your website. Gain access to a seamlessly integrated website hosted on our platform, where you can effortlessly manage your inventory through the FMO Mobile App. Say goodbye to crashing sites and the hassle of hiring website designers.",
        },
        {
          title:
            "Integrated CRM with Sales Automation:",
          subTitle:
            "Empower your dealership with FMO's Custom Dealer CRM, a robust Customer Relationship Manager integrated with sales automation tools. Our partnership with Salesforce™ brings you the industry's most advanced CRM system, empowering you to manage your sales pipeline with unparalleled efficiency. Benefit from an integrated phone system and multi-line dialer for enhanced communication efficiency. Utilize customizable automated follow-up systems rooted in the best sales practices, ensuring no opportunity slips through the cracks.",
        },

        {
          title: "Research:",
          subTitle:
            "Purchasing vehicles is one of the most important parts of your business. We give you the tools to look up wholesale and retail values instantly with history reports and options data as well as create a lead flow for purchasing vehicles from private parties.",
        },
        {
          title: "Craft Captivating Vehicle Listings in Seconds:",
          subTitle:
            "Create stunning and data-rich vehicle listings that capture the attention of potential buyers and accelerate your sales pipeline. Our intuitive listing creation tool streamlines the process, allowing you to showcase your vehicles in their best light with high-quality images, detailed descriptions, and prominent calls to action.",
        },
        {
          title:
            "Unleash Profit Optimization with Built-In Calculators:",
          subTitle:
            "Get competitive quotes for vehicle transport across the nation, right within our platform. No need for separate searches or calls. Pay and track your",
        },
        {
          title:
            "Empower Your Sales Team with a Mobile Arsenal:",
          subTitle:
            "Gain unparalleled insights into your dealership's financial performance with our built-in profit and loss calculators, encompassing both macro and micro perspectives. Empower yourself to make informed decisions that drive profitability, maximize your return on investment, and achieve sustainable financial success.",
        },
        
        {
          title:
            "Effortless Listing Export to Reach a Wider Audience:",
          subTitle:
            "Expand your reach and attract potential buyers from diverse online platforms with our easy export feature. Seamlessly export your vehicle listings to popular automotive websites, social media platforms, and online marketplaces, ensuring your vehicles are visible to a wider audience.",
        },
        {
          title:
            "Streamline DMV & Titling:",
          subTitle:
            "Cut staffing costs and ditch the hassle with our fully online platform. Handle DMV and Titling electronically, saving you time, money, and paperwork.",
        },
        {
          title:
            "Seamless Vehicle Shipping:",
          subTitle:
            "Get competitive quotes for vehicle transport across the nation, right within our platform. No need for separate searches or calls. Pay and track your",
        },
      ],
    },
  ];

  return (
    <div className="">
      <TierServicesSection tierServices={tierServices} />
    </div>
  );
}
