import { SVGProps } from "react";

export const EmailIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M5.10078 25.5016C4.39953 25.5016 3.79901 25.2517 3.29921 24.7519C2.79941 24.2521 2.54993 23.652 2.55078 22.9516V7.65156C2.55078 6.95031 2.80068 6.34979 3.30048 5.84999C3.80028 5.35019 4.40038 5.10071 5.10078 5.10156H25.5008C26.202 5.10156 26.8026 5.35146 27.3024 5.85126C27.8022 6.35106 28.0516 6.95116 28.0508 7.65156V22.9516C28.0508 23.6528 27.8009 24.2533 27.3011 24.7531C26.8013 25.2529 26.2012 25.5024 25.5008 25.5016H5.10078ZM15.3008 16.5766L5.10078 10.2016V22.9516H25.5008V10.2016L15.3008 16.5766ZM15.3008 14.0266L25.5008 7.65156H5.10078L15.3008 14.0266ZM5.10078 10.2016V7.65156V22.9516V10.2016Z"
      fill="#313131"
    />
  </svg>
);
