import {
  Card,
  CardBody,
  CardFooter,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";

import { DateTextField, Toggle, Typography } from "components";
import Input from "components/ui/input";
import { useEffect, useState } from "react";
import { json, useLocation } from "react-router-dom";
import { MONTHS } from "pages/user/apply-finance/RequestedLoanTerms";
import { IManageListingValidationSchema } from "pages/dealer/manage-listing/manage-listing.validation";
import { ListingDetailsResponse } from "services";

interface VehicleHistorySnapshotProps {
  showShadow?: boolean;
  register?: UseFormRegister<any>;
  errors?: any;
  listingAdvanceData: any;
  defaultValue?: string;
  isItCreate: boolean;
  actionBtn: number;
  setValue: UseFormSetValue<IManageListingValidationSchema>;
  listingDetailsData?: ListingDetailsResponse;

}
interface IFinaceState {
  cash_Price: number;
  down_Payment: number;
  loan_Term: number;
  interest_Rate: number;
  monthly_Payment: number;
  in_House:boolean
}

export const VehicleHistorySnapshot: React.FC<VehicleHistorySnapshotProps> = ({
  showShadow = true,
  register,
  errors,
  defaultValue,
  listingAdvanceData,
  isItCreate,
  actionBtn,
  setValue,
  listingDetailsData
}) => {
  const location = useLocation();

  const [priceData, setPriceData] = useState(
    isItCreate ? location.state?.price || "" : defaultValue || ""
  );
  const shadow = showShadow ? "sm" : "none";
  const sales =
    new Set(listingAdvanceData?.titles?.map((sale: any) => sale.date))?.size ||
    "0";
  const accidents = listingAdvanceData?.accidents?.length || "0";
  useEffect(() => {
    if (defaultValue) {
      setPriceData(defaultValue);
    }
  }, [defaultValue]);

  const [sellerFinanceOffer, setSellerFinanceOffer] = useState<IFinaceState>({
    cash_Price: 0,
    down_Payment: 0,
    loan_Term: 0,
    interest_Rate: 0 ,
    monthly_Payment: 0,
    in_House:false
  });

  

  useEffect(() => {
    // debugger
    const loanAmount =
      Number(sellerFinanceOffer?.cash_Price) -
      Number(sellerFinanceOffer.down_Payment);

    const loanTermMonths = Number(sellerFinanceOffer.loan_Term);

    const monthlyInterestRate =
      Number(sellerFinanceOffer.interest_Rate) / 100 / 12;

    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));

    setSellerFinanceOffer((prev)=>({...prev,monthly_Payment:+monthlyPayment.toFixed(2)}))
    
  }, [
    sellerFinanceOffer.cash_Price,
    sellerFinanceOffer.down_Payment,
    //   sellerFinanceOffer.estimated_Credit_Score,
    sellerFinanceOffer.interest_Rate,
    sellerFinanceOffer.loan_Term,
  ]);

  
  useEffect(() => {
    if (sellerFinanceOffer.cash_Price) {
      setValue(
        "financingPrice",
        sellerFinanceOffer.cash_Price
      );
    }
    if (sellerFinanceOffer.down_Payment) {
      setValue(
        "downPayment",
        sellerFinanceOffer.down_Payment
      );
    }
    if (sellerFinanceOffer.interest_Rate) {
      setValue(
        "interestRate",
        sellerFinanceOffer.interest_Rate
      );
    }
    if (sellerFinanceOffer.in_House) {
      setValue(
        "financeType",
        sellerFinanceOffer.in_House? 1:0
      );
    }
    if (sellerFinanceOffer.loan_Term) {
      setValue(
        "term",
        sellerFinanceOffer.loan_Term
      );
    }
    
    if (sellerFinanceOffer.monthly_Payment) {
      setValue(
        "monthlyPayment",
        sellerFinanceOffer.monthly_Payment
      );
    }
   
  }, [sellerFinanceOffer.cash_Price,
    sellerFinanceOffer.down_Payment,
    //   sellerFinanceOffer.estimated_Credit_Score,
    sellerFinanceOffer.interest_Rate,
    sellerFinanceOffer.loan_Term,
    sellerFinanceOffer.in_House,
    sellerFinanceOffer.monthly_Payment
  ]);
  
  useEffect(()=>{
    if(listingDetailsData?.listing){
      setSellerFinanceOffer({
        cash_Price: listingDetailsData.listing?.financingPrice,
        down_Payment: listingDetailsData.listing?.downPayment,
        loan_Term: listingDetailsData.listing?.term,
        interest_Rate: listingDetailsData.listing?.interestRate ,
        monthly_Payment: listingDetailsData.listing?.monthlyPayment,
        in_House: listingDetailsData.listing?.financeType ? true:false
      })
    }
  },[listingDetailsData?.listing])
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-10">
      <div className="col-span-1">
        <Card
          fullWidth
          isPressable={false}
          shadow={shadow}
          radius="lg"
          className="w-full bg-[#F1F1F180] h-full"
        >
          <CardBody className="flex mt-2 px-10">
            <Typography
              variant="titleWithGradient"
              className="px-6 py-3 text-lg xl:text-2xl font-[600] text-center"
            >
              Vehicle History Snapshot
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 md:gap-5 mt-10">
              <div className="col-span-2 mb-5">
                <Typography
                  variant="custom"
                  className="text-[#263238] h-12 font-[600] col-span-2 text-center"
                >
                  Number of Owners
                </Typography>
                <Card className="min-w-35 mt-2">
                  <CardBody>
                    <Typography
                      variant="custom"
                      className="text-6xl font-[900] text-center text-nowrap py-8 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text"
                    >
                      {listingAdvanceData ? sales : "?"}
                    </Typography>
                  </CardBody>
                </Card>
              </div>
              <div className="col-span-2 mb-5">
                <Typography
                  variant="custom"
                  className="text-[#263238] h-12 font-[600] col-span-2 text-center"
                >
                  Number of Accidents
                </Typography>
                <Card className="min-w-35 mt-2">
                  <CardBody>
                    <Typography
                      variant="custom"
                      className="text-6xl font-[900] text-nowrap text-center py-8 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text"
                    >
                      {listingAdvanceData ? accidents : "?"}
                    </Typography>
                  </CardBody>
                </Card>
              </div>
            </div>
          </CardBody>
          <CardFooter>
            {!listingAdvanceData &&
              "No vehicle history report has been purchased. Please buy a history report to access this data."}
          </CardFooter>
        </Card>
      </div>
      <div className="col-span-1 ">
        <Card
          shadow={shadow}
          radius="lg"
          className="w-full bg-[#F1F1F180] h-full px-4 overflow-visible"
        >
          <CardBody
            className="flex justify-center  items-center mt-2 overflow-visible"
            id="vehicle_history_snapeshot_select"
          >
            <div className="">
              <FormControl>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] col-span-2 text-left"
                >
                  Cash Price
                </Typography>
                {(isItCreate ? true : priceData) && register && (
                  <Input
                    type="number"
                    variant="form"
                    size="compact"
                    className="col-span-3"
                    isInvalid={!!errors?.price?.message}
                    min={1}
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    {...register("price")}
                    defaultValue={priceData}
                    errorMessage={errors?.price?.message}
                  />
                )}
              </FormControl>
              <FormControl>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] col-span-2 text-left"
                >
                  Financing Price
                </Typography>

                <Input
                  type="number"
                  variant="form"
                  size="compact"
                  className="col-span-3"
                  
                  classNames={{
                    input: "px-4 text-center",
                  }}
                  value={sellerFinanceOffer?.cash_Price?.toString()}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setSellerFinanceOffer((prev)=>({...prev, cash_Price:value}));
                  }}

                />
              </FormControl>
              <FormControl>
                <Typography
                  variant="custom"
                  className="text-black font-[600] col-span-2 text-left"
                >
                  In House Finance Only?
                </Typography>
                <Toggle
                  size="sm"
                  checked={sellerFinanceOffer.in_House}
                  disabled
                  onChange={(e) => {
                    setSellerFinanceOffer((prev)=>({...prev, in_House:e.target.checked}));
             
                  }}
                  color="default"
                />
              </FormControl>

              <FormControl>
                <Typography
                  variant="custom"
                  className="text-black font-[600] col-span-2 text-left"
                >
                  Down Payment (Min $500)
                </Typography>
                <Input
                  // disabled
                  variant="form"
                  size="compact"
                  className="col-span-3 text-center"
                  classNames={{
                    input: "px-4 text-center",
                  }}
                  // color="disabled"
                  value={sellerFinanceOffer?.down_Payment?.toString()}

                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setSellerFinanceOffer((prev)=>({...prev, down_Payment:value}));
                  }}
                />
              </FormControl>

              <FormControl>
                <Typography
                  variant="custom"
                  className="text-black font-[600] col-span-2 text-left"
                >
                  Interest Rate
                </Typography>
                <Input
                  // disabled
                  variant="form"
                  size="compact"
                  className="col-span-3"
                  // color="disabled"
                  classNames={{
                    input: "px-4 text-center",
                  }}
                  value={sellerFinanceOffer?.interest_Rate?.toString()}

                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setSellerFinanceOffer((prev)=>({...prev, interest_Rate:value}));
                  }}
                />
              </FormControl>

              <FormControl>
                <Typography
                  variant="custom"
                  className="text-black font-[600] col-span-2 text-left"
                >
                  Term
                </Typography>
                
                <Select
                  size="sm"
                  className="w-full col-span-3"
                  variant="flat"
                  fullWidth
                  classNames={{
                    trigger:
                      "border-2 rounded-2xl h-[42px] w-full border border-double border-transparent   bg-white",
                    value: "text-center",
                    mainWrapper: "w-full",
                    innerWrapper: "w-full",
                  }}
                  placeholder="Select Months"
                  onChange={(e)=>{
                    
                    const value = Number(e.target.value);
                    setSellerFinanceOffer((prev)=>({...prev, loan_Term:value}));
                  }}
                  popoverProps={{
                    portalContainer:
                      document.getElementById(
                        "vehicle_history_snapeshot_select"
                      ) || undefined,
                  }}
                  selectionMode="single"
                  selectedKeys={new Set([sellerFinanceOffer?.loan_Term?.toString()])}
                >
                  {MONTHS.map((data) => (
                    <SelectItem
                      key={data.value}
                      value={data.value}
                      className="text-center"
                    >
                      {data.label}
                    </SelectItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <Typography
                  variant="custom"
                  className="text-black font-[600] col-span-2 text-left"
                >
                  Monthly Payment
                </Typography>
                <Input
                  disabled
                  variant="form"
                  size="compact"
                  className="col-span-3"
                  color="disabled"
                  isDisabled={true}
                  value={Number.isFinite(sellerFinanceOffer.monthly_Payment )? sellerFinanceOffer.monthly_Payment.toFixed(2):""}
                />
              </FormControl>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-5 justify-items-stretch mb-5 align-baseline items-center">
      {children}
    </div>
  );
};
