import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import ProtectedRoutes from "./protected-routes";

import ShowQuotes from "../modules/dealer/features/shipping-quotes/ShowQuotes";
import PaymentForm from "../modules/dealer/features/shipping-quotes/PaymentForm";
import SendOTP from "../modules/dealer/features/shipping-quotes/SendOTP";
import AuthLayout from "../components/layouts/auth-layout";
import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import DmvFeeDetails from "modules/dealer/components/dmv-title/DmvFeeDetails";
import ResetPassword from "modules/dealer/features/forgot-password/ResetPassword";
import DealerLogin from "pages/(auth)/dealer-login";
import DealerDashboard from "pages/dealer/dashboard";
import ProfitLoss from "pages/dealer/profit-loss";
import DmvDocStatus from "modules/dealer/components/dmv-title/DmvDocStatus";
import TransitStatus from "modules/dealer/components/dmv-title/DmvTransitStatus";
import EmailTheme from "modules/dealer/features/forgot-password/EmailTheme";
import DealerRegister from "pages/(auth)/dealer-register";
import EnterVINNumber from "modules/dealer/components/enter-vin/EnterVIN";
import {
  NewOrderShippingPage,
  OrderHistoryShippingPage,
} from "pages/shipping-quotes";
import { SearchCarPage } from "pages/public";
import { Loadable, PublicLayout } from "components";
import { lazy } from "react";
import UserLogin from "pages/(auth)/user-login";
import UserRegister from "pages/(auth)/user-register";
import {
  SaveReportsPage,
  SavedVehiclesPage,
  UserDashboardPage,
} from "pages/user";
import DealerDashboardLayout from "components/layouts/dealer-dashboard-layout";
import UserDashboardLayout from "components/layouts/user-dashboard-layout";

import ViewDealerServicesPage from "pages/public/dealer-services-pricing";
// import ViewWhatWeDoPage from "pages/public/what-we-do";
import ViewBecomeDealerPage from "pages/public/become-a-dealer";
import ManageListingPage from "pages/dealer/manage-listing/manage-listing";
import SelectVehicle, {
  DmvFeeDetailsForm,
  DmvFeeQuoteForm,
  DmvFeeAndPdfDocsForm,
  OrderHistoryDmvPage,
  EnterMissingInformationForm,
} from "pages/dealer/dmv-titling";
import CareersPage from "pages/public/careers";
import AboutUsPage from "pages/public/about-us";
import ArchivedAndSoldPage from "pages/dealer/manage-inventory/archived-and-sold";
import ManageInventory from "pages/dealer/manage-inventory/live-inventory";
import UserGarage from "pages/user/garage";
import LienAndLoanPage from "pages/public/private-party-services/lien-and-loan/lien-and-loan";
import NationWideShippingPage from "pages/public/private-party-services/nationwide-shipping/nationwide-shipping";
import DmvTitlingServicesPage from "pages/public/private-party-services/titling-services/dmv-titling-services";
import VehicleHistoryReports from "pages/public/vehicle-history-reports";
import { ROLES } from "assets/constants";
import SellInstantQuotePage from "pages/public/private-party-services/sell-instant-quote/sell-instant-quote";
import LandignPage from "pages/public/landing/index";
import PublicRoutes from "./public-routes";
import UserSelectVehicle from "pages/user/dmv-titling/new-order/SelectVehicle";
import { UserOrderHistoryShippingPage } from "pages/user/shipping-quotes/order-history";
import UserManageListingPage from "pages/user/manage-inventory";

import FmoMembershipPage from "pages/public/membership-advantage/fmo-membership/fmo-membership";
import GivingBackPage from "pages/public/membership-advantage/giving-back/giving-back";
import RepairAssistancePage from "pages/public/membership-advantage/repair-assistance/repair-assistance";
import ShopPartnershipsPage from "pages/public/membership-advantage/shop-partnerships/shop-partnerships";
import EscrowOfferAcceptedSteps from "pages/dealer/offer-accepted/escrow-accepted-steps";
import PendingSales from "pages/dealer/pending-sales/pending-sales";
import FactoryInstalledOptions from "pages/public/factory-installed-options";
import AutoLoanLeaseCalculatorPage from "pages/public/tools/auto-loan-lease-calculator";
import FreeBasicVinSearch from "pages/public/free-basic-vin-search";
import VehicleShippingQuotePage from "pages/public/tools/vehicle-shipping-quote";
import FmoMembershipAcceptPage from "pages/user/Benefits/membership/fmo-membership-accept";
import RepairAgreement from "pages/user/Benefits/car-warrently/repair-agreement";
import ViewSingleCarPage from "pages/public/view-car/index";
import { DealerProfilePage } from "pages/dealer";
import { DealerMembership } from "pages/dealer/dealer-membership";
import { DmvFeeQuoteFormHistory } from "pages/dealer/dmv-titling/dmv-fee-quote-form-history.component";
import { UserProfilePage } from "pages/user/profile/user-profile";
import ContactUs from "pages/public/contact-us";
import AdvertisersPage from "pages/public/partner/advertisers";
import InvestorsPage from "pages/public/partner/investors";
import AffiliatesPage from "pages/public/partner/affiliates";
import SuccessMessagePage from "pages/public/private-party-services/sell-instant-quote/success/success-message";
import ListRepairAgreements from "pages/user/Benefits/car-warrently/components/list-repair-agreement.component";
import BulkEnterVIN from "modules/dealer/components/enter-vin/BulkEnterVIN";
import OfferAccepted from "pages/dealer/offer-accepted/components/OfferAccepted";
import StepsForDebitCreditOffer from "pages/dealer/offer-accepted/components/credit-debit-steps";
import StepsForCashZelleOffer from "pages/dealer/offer-accepted/components/cash-zelle-steps";
import StepsForFinancingOffer from "pages/dealer/offer-accepted/components/financing-steps";
import BuyHerePayHereFinancing from "pages/public/finance/buy-here-pay-here-financing/bhphf.component";
import TraditionalFinancing from "pages/public/finance/traditional-financing/traditional-financing";
import PreApproval from "pages/public/finance/pre-approval/pre-approval";
import ListYourCarForSale from "pages/public/finance/list-your-car-for-sale/list-your-car-for-sale";
import ApplyFinanceLoan from "pages/user/apply-finance/ApplyFinanceSteps";
import LoanPayments from "pages/user/apply-finance/LoanPayments";
import CarResearch from "modules/dealer/components/research/car-research";
import EnterVINOnly from "modules/dealer/components/enter-vin/EnterVINOnly";
import StartReasearchComponent from "modules/dealer/components/research/start-research.component";


export const AppRoutes = () => {
  const ViewSingleCarPageLoadable = Loadable(
    lazy(() => import("pages/public/view-car/index"))
  );
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="listing/landing-page" />}
        ></Route>
        {/* Home Page */}
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<LandignPage />} />
        </Route>

        {/* AUTH ROUTES (PUBLIC) */}
        <Route
          element={<PublicRoutes AllowedROLES={[ROLES.user, ROLES.dealer]} />}
        >
          <Route element={<AuthLayout />}>
            <Route path="/login">
              <Route index element={<DealerLogin />} />
              <Route path="user" element={<UserLogin />} />
            </Route>
            <Route path="/register">
              <Route index element={<DealerRegister />} />
              <Route path="user" element={<UserRegister />} />
            </Route>
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/email" element={<EmailTheme />} />
            {/* <Route path="/forgot-password/changed" element={<PassChange />} /> */}
          </Route>
        </Route>

        <Route path="/listing" element={<PublicLayout />}>
          <Route index path="landing-page" element={<LandignPage />} />
          <Route index path="search-car" element={<SearchCarPage />} />
          <Route path="search-car/:id" element={<ViewSingleCarPage />} />
          <Route
            index
            path="view-listing/:id"
            element={<ViewSingleCarPageLoadable />}
          />

          <Route
            path="vehicle-history-reports"
            element={<VehicleHistoryReports />}
          />
          <Route
            path="factory-installed-options"
            element={<FactoryInstalledOptions />}
          />

          <Route path="careers" element={<CareersPage />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="dealer-services">
            <Route path="become-a-dealer" element={<ViewBecomeDealerPage />} />
            {/* <Route
              path="dealer-services-pricing"
              element={<ViewDealerServicesPage />}
            /> */}
            <Route path="what-we-do" element={<ViewDealerServicesPage />} />
          </Route>

          <Route path="private-party-services">
            <Route
              path="sell-instant-quote"
              element={<SellInstantQuotePage />}
            />
            <Route path="loan-and-lien" element={<LienAndLoanPage />} />
            <Route
              path="nationwide-shipping"
              element={<NationWideShippingPage />}
            />
            <Route
              path="titling-services"
              element={<DmvTitlingServicesPage />}
            />
            <Route path="sell-instant-quote">
              <Route path="success" element={<SuccessMessagePage />} />
            </Route>
          </Route>

          <Route path="finance">
            <Route path="buy-here-pay-here-financing" element={<BuyHerePayHereFinancing />} />
             <Route
              path="traditional-financing"
              element={<TraditionalFinancing />}
            />
            <Route path="pre-approval" element={<PreApproval />} />
            
            <Route path="list-your-car-for-sale" element={<ListYourCarForSale />} />
          </Route>

          <Route path="membership-advantage">
            <Route
              path="fmo-membership"
              element={<FmoMembershipPage isPrivateView={false} />}
            />

            <Route path="giving-back" element={<GivingBackPage />} />
            <Route path="used-car-warranty">
              <Route index element={<RepairAssistancePage />} />
              <Route path="thank-you/:id" element={<RepairAssistancePage />} />
              <Route
                path="warranty-form/:id"
                element={<RepairAssistancePage />}
              />
            </Route>
            <Route
              path="shop-partnerships"
              element={<ShopPartnershipsPage />}
            />
          </Route>
          <Route path="tools">
            <Route
              path="free-basic-vin-search"
              element={<FreeBasicVinSearch />}
            />
            <Route
              path="vehicle-shipping-quote"
              element={<VehicleShippingQuotePage />}
            />
            <Route
              path="auto-loan-lease-calculator"
              element={<AutoLoanLeaseCalculatorPage />}
            />
          </Route>
          <Route path="partner">
            <Route path="advertisers" element={<AdvertisersPage />} />
            <Route path="investors" element={<InvestorsPage />} />
            <Route path="affiliates" element={<AffiliatesPage />} />
          </Route>
        </Route>

        {/* DASHBOARD ROUTES (PRIVATE) */}
        <Route element={<ProtectedRoutes AllowedROLES={[ROLES.dealer]} />}>
          {/* DEALER ROUTES */}
          <Route element={<DealerDashboardLayout />}>
            <Route path="/dealer">
              <Route path="dashboard" element={<DealerDashboard />} />
              <Route path="dmv-titling">
                <Route path="new-order">
                  <Route
                    index
                    element={<SelectVehicle fromShipping={false} />}
                  />
                  <Route
                    path="enter-missing-details-quotes/:id/:listingId"
                    element={
                      <EnterMissingInformationForm fullService={false} />
                    }
                  />
                  <Route
                    path="enter-missing-details-full/:id/:listingId"
                    element={<EnterMissingInformationForm fullService={true} />}
                  />
                  <Route
                    path="enter-missing-details-quotes/:id/:listingId/dmv-fee-details"
                    element={<DmvFeeDetailsForm />}
                  />
                  {/* <Route
                    path="enter-missing-details/:id/:listingId/dmv-fee-and-pdf-docs"
                    element={<DmvFeeAndPdfDocsForm />}
                  /> */}
                  <Route
                    path="enter-missing-details-full/:id/:listingId/dmv-fee-quotes"
                    element={<DmvFeeQuoteForm />}
                  />
                </Route>
                <Route
                  path="order-history"
                  index
                  element={<OrderHistoryDmvPage />}
                />
                <Route
                  path="dmv-history/:qid"
                  element={<DmvFeeQuoteFormHistory />}
                />

                <Route path="dvm-document-status" element={<DmvDocStatus />} />
                <Route
                  path="dvm-transit-status"
                  element={<TransitStatus isForDmv={true} />}
                />
              </Route>
              <Route path="profit-loss" element={<ProfitLoss />} />
              <Route path="enter-vin" element={<EnterVINNumber />} />
              
              <Route path="enter-vin-only" element={<EnterVINOnly />} />
              <Route path="car-research" element={<CarResearch />} />
              <Route path="new-research" element={<StartReasearchComponent />} />


              <Route path="bulk-enter-vin" element={<BulkEnterVIN />} />

              <Route path="manage-inventory">
                <Route
                  index
                  path="live-inventory"
                  element={<ManageInventory />}
                />
                <Route
                  path="archived-and-sold"
                  element={<ArchivedAndSoldPage />}
                />
                <Route path="pending-sales" element={<PendingSales />} />
                <Route
                  path="vehicle-transaction-type/:id/:listingId"
                  element={<OfferAccepted />}
                />
                <Route path="offer-accepted-steps/:id/:listingId">
                  <Route path="escrow" element={<EscrowOfferAcceptedSteps />} />

                  <Route
                    path="cash-zelle"
                    element={<StepsForCashZelleOffer />}
                  />
                  <Route
                    path="credit-debit"
                    element={<StepsForDebitCreditOffer />}
                  />
                  <Route
                    path="financing"
                    element={<StepsForFinancingOffer />}
                  />
                </Route>
              </Route>
              <Route
                path="create-listing/:vin/:mileage/:listingId"
                element={
                  <ManageListingPage forDealer={true} isItCreate={true} />
                }
              />
              <Route
                path="manage-listing/:vin/:mileage/:listingId"
                element={
                  <ManageListingPage forDealer={true} isItCreate={false} />
                }
              />
              <Route
                path="car-research/:vin/:mileage/:listingId"
                element={
                  <ManageListingPage forDealer={true} isItCreate={false} status="reseach" />
                }
              />
              
              <Route
                path="start-car-research/:vin/:mileage/:listingId"
                element={
                  <ManageListingPage forDealer={true} isItCreate={true} status="reseach" />
                }
              />
              <Route path="fee-details" element={<DmvFeeDetails />} />
            </Route>
            <Route path="/shipping-quotes">
              <Route
                path="select-vehicle"
                index
                element={<SelectVehicle fromShipping={true} />}
              />
              <Route path="show-quotes" element={<ShowQuotes />} />
              <Route path="new-order" element={<NewOrderShippingPage />} />
              <Route
                path="order-history"
                element={<OrderHistoryShippingPage />}
              />
              <Route path="payment-form" element={<PaymentForm />} />
              <Route path="send-otp" element={<SendOTP />} />
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route
                path="transit-status/:id"
                element={<TransitStatus isForDmv={false} />}
              />
            </Route>
            <Route path="/user-profile" element={<DealerProfilePage />} />
            <Route path="/user-membership" element={<DealerMembership />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoutes AllowedROLES={[ROLES.user]} />}>
          {/* USER ROUTES */}
          <Route
            element={
              <ProtectedRoutes AllowedROLES={[ROLES.user, ROLES.dealer]} />
            }
          >
            <Route element={<UserDashboardLayout />}>
              <Route path="/user">
                <Route path="dashboard" element={<UserDashboardPage />} />

                <Route path="user-profile" element={<UserProfilePage />} />
                <Route
                  path="manage-inventory"
                  element={<UserManageListingPage />}
                ></Route>
                <Route path="enter-vin" element={<EnterVINNumber />} />

                <Route
                  path="create-listing/:vin/:mileage/:listingId"
                  element={
                    <ManageListingPage forDealer={false} isItCreate={true} />
                  }
                />
                <Route
                  path="manage-listing/:vin/:mileage/:listingId"
                  element={
                    <ManageListingPage forDealer={false} isItCreate={false} />
                  }
                />
                <Route path="garage">
                  <Route index element={<UserGarage />} />
                  <Route path="pending-sales" element={<PendingSales />} />
                  <Route
                    path="vehicle-transaction-type/:id/:listingId"
                    element={<OfferAccepted />}
                  />
                  <Route path="offer-accepted-steps/:id/:listingId">
                    <Route
                      path="escrow"
                      element={<EscrowOfferAcceptedSteps />}
                    />
                    <Route
                      path="cash-zelle"
                      element={<StepsForCashZelleOffer />}
                    />
                    <Route
                      path="credit-debit"
                      element={<StepsForDebitCreditOffer />}
                    />

                    <Route
                      path="financing"
                      element={<StepsForFinancingOffer />}
                    />
                  </Route>
                  <Route
                    path="saved-vehicles"
                    element={<SavedVehiclesPage />}
                  />
                  <Route
                    path="loan-payments"
                    element={<LoanPayments />}
                  />
                  <Route
                    path="apply-finance-loan/:listingId"
                    element={<ApplyFinanceLoan isPreRequest={false} />}
                  />
                  <Route
                    path="apply-pre-approve-loan/:listingId"
                    element={<ApplyFinanceLoan isPreRequest={true} />}
                  />
                </Route>
                <Route path="saved-reports" element={<SaveReportsPage />} />
                <Route path="dmv-titling">
                  <Route path="new-order">
                    <Route
                      index
                      element={<UserSelectVehicle fromShipping={false} />}
                    />
                    <Route
                      path="enter-missing-details-quotes/:id/:listingId"
                      element={
                        <EnterMissingInformationForm fullService={false} />
                      }
                    />
                    <Route
                      path="enter-missing-details-full/:id/:listingId"
                      element={
                        <EnterMissingInformationForm fullService={true} />
                      }
                    />
                    <Route
                      path="enter-missing-details-quotes/:id/:listingId/dmv-fee-details"
                      element={<DmvFeeDetailsForm />}
                    />
                    {/* <Route
                      path="enter-missing-details/:id/:listingId/dmv-fee-and-pdf-docs"
                      element={<DmvFeeAndPdfDocsForm />}
                    /> */}
                    <Route
                      path="enter-missing-details-full/:id/:listingId/dmv-fee-quotes"
                      element={<DmvFeeQuoteForm />}
                    />
                  </Route>
                  <Route
                    path="order-history"
                    index
                    element={<OrderHistoryDmvPage />}
                  />
                  <Route
                    path="dmv-history/:qid"
                    element={<DmvFeeQuoteFormHistory />}
                  />

                  <Route
                    path="dvm-transit-status"
                    element={<TransitStatus isForDmv={true} />}
                  />
                </Route>

                <Route path="benefits">
                  <Route path="fmo-membership">
                    <Route
                      index
                      element={<FmoMembershipPage isPrivateView={true} />}
                    />
                    <Route
                      path="accept"
                      element={<FmoMembershipAcceptPage />}
                    />
                  </Route>
                  <Route path="repair-agreement">
                    <Route index element={<ListRepairAgreements />} />
                    <Route path="add" element={<RepairAgreement />} />
                  </Route>
                </Route>

                <Route path="shipping-quotes">
                  <Route
                    path="select-vehicle"
                    index
                    element={<UserSelectVehicle fromShipping={true} />}
                  />
                  <Route
                    path="select-vehicle"
                    index
                    element={<SelectVehicle fromShipping={true} />}
                  />
                  {/* <Route path="show-quotes" element={<ShowQuotes />} /> */}
                  <Route path="new-order" element={<NewOrderShippingPage />} />

                  <Route
                    path="order-history"
                    index
                    element={<OrderHistoryShippingPage />}
                    // element={<UserOrderHistoryShippingPage />}
                  />
                  <Route
                    path="transit-status/:id"
                    element={<TransitStatus isForDmv={false} />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        {/* NOT FOUND */}
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </HashRouter>
  );
};
