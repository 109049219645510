import { Typography } from "components";
import Input from "components/ui/input";
import { useTurboChatMutation } from "hooks/api/mutations/chat/turbo-ai/use-turbo-mutation";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import User from "./User";
import Turbo from "./Turbo";
import send from "../assets/send.png";
import "../index.scss";
interface Props {
  isHover: boolean;
  userName?: any;
}

export default function MobileNewChatBotTurbo({ isHover, userName }: Props) {
  const [messages, setMessages] = useState<any[]>([]);

  const { mutate } = useTurboChatMutation();
  const [message, setMessage] = useState("");
  const [onChangeMessage, setOnChangeMessage] = useState("");
  const [messagePaddingValue, setMessagePaddingValue] = useState(0);
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const inputRefMobile = useRef<HTMLInputElement>(null);

  const [turbochatLoading, setturbochatLoading] = useState(false);
  useEffect(() => {
    if (message) {
      handleSubmit();
      scrollToBottom();
    }
  }, [message]);
  const handleSubmit = async () => {
    setturbochatLoading(true);
    if (message !== " ") {
      const updatedMessages = Array.from([
        ...messages,
        { content: message, role: "user" },
      ]);
      setMessages((prev) => [...prev, { content: message, role: "user" }]);

      mutate(updatedMessages, {
        onSuccess: (response: any) => {
          setMessages((prev) => [
            ...prev,
            { content: response.answer, role: "assistant" },
          ]);
          setturbochatLoading(false);
        },
        onError: (error) => {
          console.error("Error:", error);
          setturbochatLoading(false);
        },
      });
    }
    setMessage("");
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      setMessagePaddingValue(chatMessagesRef.current.scrollHeight);
    }
  };

  return (
    <div>
      {isHover && (
        <div className={`w-full h-[480px]`}>
          <div className="flex items-center justify-between p-5">
            <Typography variant="pageTitle" className="font-light">
              Turbo<span className="text-xl">-AI Support</span>
            </Typography>
          </div>
          <div className=" overflow-y-auto pb-5">
            <div className="flex flex-col justify-center">
              <div className={`${!messages.length && "flex justify-center"}`}>
                <div
                  className={`flex flex-col items-start overflow-y-auto h-[330px] 
                  ${!messages.length && "w-full mt-16"}`}
                  ref={chatMessagesRef}
                >
                  {!messages.length && (
                    <div className="w-full flex items-center justify-center">
                      <Typography variant="pageTitle" className="font-light">
                        <div className="text-start text-lg md:text-xl">
                          <div className="slide-right">
                            <p>
                              Hello, {userName ?? "user"}
                              <div className="text-start text-xl md:text-2xl slide-right">
                                How can I help you today?
                              </div>
                            </p>
                          </div>
                        </div>
                      </Typography>
                    </div>
                  )}

                  {messages.map((res) => {
                    return (
                      <div className="w-full">
                        {res.role === "assistant" ? (
                          <div className=" w-full ">
                            <Turbo content={res.content} />
                          </div>
                        ) : (
                          <div className="text-right w-full relative">
                            <User content={res.content} />
                          </div>
                        )}
                      </div>
                    );
                  })}

                  {turbochatLoading && (
                    <div className="flex items-center justify-start mb-[10px] px-5">
                      <div
                        className={`break-normal flex flex-col px-[10px] md:px-[20px] py-2 md:pt-1 md:pb-5 max-w-[70%] md:max-w-[100%] min-h-[50px] min-md:h-[79px] justify-center chat-reciever `}
                      >
                        <div className="flex justify-start text-[#070C84] font-bold text-[9px] md:text-[14px]">
                          {"Turbo"}
                        </div>

                        <div className="text-[10px] md:text-[15px]">
                          ...Searching for the answer
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 w-full p-5 z-50 ">
            <Input
              variant="turbo"
              type="text"
              ref={inputRefMobile}
              onKeyPress={(event: any) => {
                const mes = event.target.value;
                setOnChangeMessage(mes);
                if (event.charCode === 13) {
                  setMessage(mes);
                  setOnChangeMessage("");
                }
              }}
              placeholder="Ask me anything car related!"
              className="text-xs w-full"
              value={onChangeMessage}
              onValueChange={setOnChangeMessage}
              endContent={
              <button
                  className="-mr-5"
                  onClick={(e: any) => {
                    e.preventDefault()
                    const inputValue = inputRefMobile.current?.value;
                    if (inputValue && inputValue.trim() !== "") {
                      setMessage(inputValue);
                      inputRefMobile.current.value = "";
                      inputRefMobile.current.focus()
                      setOnChangeMessage("");
                    }
                  }}
                >
                  <img src={send} alt="send"/>
                </button>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
