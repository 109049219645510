import { Button } from "@nextui-org/react";
import { Typography } from "components";
import { useNavigate } from "react-router-dom";

const AbboutBHPHF = () => {
  const navigate = useNavigate()

  const handleCreateAccount = () =>{
    navigate("/login/user")
  }
  return( <div>
    <div className="flex flex-col md:flex-row items-center justify-center gap-4 text-center text-lg font-[Metropolis]">
      <Typography variant="pageTitle" className="text-left">
        Get on the Road Today!
      </Typography>

      <p className="font-[Metropolis] font-[600] text-left">
        With BHPH financing, you can get the car you need to commute to work,
        run errands, and explore new possibilities. Visit us today and
        experience the ease and convenience of BHPH financing!
      </p>
    </div>
    <div className="flex mt-6 items-center justify-center  ">
      <Button onClick={handleCreateAccount} className="text-center  text-xs sm:text-lg md:text-xl font-[700] bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text border-[#070C84]  border-2">
        Create an Account today to Get Pre-Approved!
      </Button>
    </div>
  </div>)
}
 

export default AbboutBHPHF;
