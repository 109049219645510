import { cn } from "@nextui-org/react";
import React from "react";
import Toggle from "modules/shared/components/toggle/toggle";
import Textarea from "components/ui/textarea";

interface VehicleConditionItemProps {
  keyToggle: string;
  keyDescription: string;
  title: string;
  isSelected: boolean;
  description: string;
  isManageListing?: boolean;
  onToggle: (toggleKey: string, descriptionKen: string) => void;
  onDescriptionChange: (
    toggleKey: string,
    descriptionKey: string,
    description: string
  ) => void;
}

export default function VehicleConditionItem({
  keyToggle,
  keyDescription,
  title,
  isSelected,
  isManageListing,
  description,
  onToggle,
  onDescriptionChange,
}: VehicleConditionItemProps) {
  return (
    <div className="flex justify-center items-center">
      <div
        className={cn(
          `flex text-base   justify-between ${
            isManageListing ? "w-full md:w-[95%]" : "w-full"
          } mt-4`
        )}
      >
        <div className="flex flex-1 flex-col items-start">
          <div className="flex justify-between items-center w-full gap-4 mb-2">
            <p className=" lg:text-lg md:text-md text-sm    ">
              {title}
            </p>

            <label className="flex items-center cursor-pointer ">
              <div className="relative ">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={isSelected}
                  onChange={() => onToggle(keyToggle, keyDescription)}
                />
                <div
                  className={`block   ${
                    isSelected ? "bg-blue-500" : "bg-gray-300"
                  }  w-16 h-8 rounded-full  border border-[#018ffd]`}
                >
                  <div
                    className={`absolute inset-0 flex items-center  justify-${
                      isSelected ? "start pr-2" : "end pl-2 "
                    }`}
                  >
                    <span
                      className={`text-[13px]   ${
                        !isSelected ? "text-gray-600" : "text-white"
                      }  font-medium px-3 `}
                    >
                      {isSelected ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
                <div
                  className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${
                    isSelected ? "transform translate-x-full ml-2" : ""
                  }`}
                ></div>
              </div>
            </label>
          </div>

          {isSelected && (
            <Textarea
              classNames={{
                input: " w-full",
                inputWrapper:
                  "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)] ",
              }}
              placeholder="Enter your description"
              className=" border-2 rounded-2xl w-full"
              value={description}
              onChange={(event) =>
                onDescriptionChange(
                  keyToggle,
                  keyDescription,
                  event.target.value
                )
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
