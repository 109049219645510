import ContactUsCard from "./card.component";
import { cards } from "../contact-us.data";
import { useState } from "react";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import MobileNewChatBotTurbo from "pages/public/landing/components/MobileNewChatBotTurbo";
import Ticket from "modules/dealer/components/chathelp/Ticket";
import SupportChat from "modules/dealer/components/chathelp/SupportChat";
const ContactUsCards = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [selectedValue, setSelectedValue] = useState("");

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      {cards.map((card) => (
        <ContactUsCard card={card} />
      ))}
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        size="sm"
        placement="top"
        className="rounded-3xl"
      >
        <ModalContent>
          {(onClose) => {
            return (
              <div>
                {selectedValue === "01" ? (
                  <Ticket
                    setCurrScreen={() => {}}
                    handleScreenChange={onClose}
                  />
                ) : selectedValue === "02" ? (
                  <SupportChat
                    setCurrScreen={() => {}}
                    handleScreenChange={onClose}
                  />
                ) : (
                  <MobileNewChatBotTurbo isHover={isOpen} />
                )}
              </div>
            );
          }}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ContactUsCards;
