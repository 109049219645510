import { SVGProps } from "react";

export const ShippingTruckIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      d="M0 5.625V7.5H17.8125V21.5625H12.0413C11.6231 19.9509 10.1737 18.75 8.4375 18.75C6.70125 18.75 5.25187 19.9509 4.83375 21.5625H3.75V16.875H1.875V23.4375H4.83375C5.25187 25.0491 6.70125 26.25 8.4375 26.25C10.1737 26.25 11.6231 25.0491 12.0413 23.4375H19.8337C20.2519 25.0491 21.7013 26.25 23.4375 26.25C25.1737 26.25 26.6231 25.0491 27.0413 23.4375H30V15.7913L29.9409 15.6441L28.0659 10.0191L27.8625 9.375H19.6875V5.625H0ZM0.9375 9.375V11.25H9.375V9.375H0.9375ZM19.6875 11.25H26.5134L28.125 16.0547V21.5625H27.0413C26.6231 19.9509 25.1737 18.75 23.4375 18.75C21.7013 18.75 20.2519 19.9509 19.8337 21.5625H19.6875V11.25ZM1.875 13.125V15H7.5V13.125H1.875ZM8.4375 20.625C9.48469 20.625 10.3125 21.4528 10.3125 22.5C10.3125 23.5472 9.48469 24.375 8.4375 24.375C7.39031 24.375 6.5625 23.5472 6.5625 22.5C6.5625 21.4528 7.39031 20.625 8.4375 20.625ZM23.4375 20.625C24.4847 20.625 25.3125 21.4528 25.3125 22.5C25.3125 23.5472 24.4847 24.375 23.4375 24.375C22.3903 24.375 21.5625 23.5472 21.5625 22.5C21.5625 21.4528 22.3903 20.625 23.4375 20.625Z"
      fill="#2C2C2C"
    />
  </svg>
);
