import { Button } from "@nextui-org/react";
import { Typography } from "components";
import { useRef } from "react";
import { IFileInput } from "../enter-missing-information-form.component";

interface Props {
  handleChangeDMVDoc: (e: any, fileType: string) => void;
  selectedFiles: IFileInput;
}

export const UploadFileSection = ({
  handleChangeDMVDoc,
  selectedFiles,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef2 = useRef<HTMLInputElement>(null);

  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const onHandleFileClick2 = () => {
    if (fileInputRef2.current) {
      fileInputRef2.current.click();
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-5 justify-items-stretch mt-5 mb-3 align-baseline items-center">
      <div className="col-span-2"></div>
      <div className="col-span-3 md:ml-3">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5 ">
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="text-[#263238] font-[600] text-center text-wrap text-[14px]"
            >
              Upload Driver License
            </Typography>
            <label htmlFor="license-file-input">
              <Button
                onClick={onHandleFileClick}
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
              >
                {selectedFiles.license?.name
                  ? "License Uploaded"
                  : "Choose File"}
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                accept=".pdf,.doc,.docx,image/*"
                onChange={(e) => handleChangeDMVDoc(e, "license")}
                style={{ display: "none" }}
                id="license-file-input"
              />
            </label>
          </div>
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="text-[#263238] font-[600] text-center text-wrap text-[14px]"
            >
              Upload Current Insurance
            </Typography>
            <label htmlFor="insurance-file-input">
              <Button
                onClick={onHandleFileClick2}
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
              >
                {selectedFiles.insurance?.name
                  ? "Insurance Uploaded"
                  : "Choose File"}
              </Button>
              <input
                type="file"
                ref={fileInputRef2}
                accept=".pdf,.doc,.docx,image/*"
                onChange={(e) => handleChangeDMVDoc(e, "insurance")}
                style={{ display: "none" }}
                id="insurance-file-input"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
