import { SVGProps } from "react";

export const CashStackSquaredIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="mdi:cash-multiple">
      <path
        id="Vector"
        d="M11.7576 14.0469H52.5907V41.2689H11.7576V14.0469ZM32.1741 20.8524C33.9791 20.8524 35.7101 21.5694 36.9864 22.8457C38.2627 24.122 38.9797 25.853 38.9797 27.6579C38.9797 29.4628 38.2627 31.1938 36.9864 32.4701C35.7101 33.7464 33.9791 34.4634 32.1741 34.4634C30.3692 34.4634 28.6382 33.7464 27.3619 32.4701C26.0856 31.1938 25.3686 29.4628 25.3686 27.6579C25.3686 25.853 26.0856 24.122 27.3619 22.8457C28.6382 21.5694 30.3692 20.8524 32.1741 20.8524ZM20.8316 18.5839C20.8316 19.7872 20.3536 20.9412 19.5028 21.792C18.6519 22.6429 17.4979 23.1209 16.2946 23.1209V32.1949C17.4979 32.1949 18.6519 32.6729 19.5028 33.5238C20.3536 34.3746 20.8316 35.5286 20.8316 36.7319H43.5167C43.5167 35.5286 43.9947 34.3746 44.8455 33.5238C45.6964 32.6729 46.8504 32.1949 48.0537 32.1949V23.1209C46.8504 23.1209 45.6964 22.6429 44.8455 21.792C43.9947 20.9412 43.5167 19.7872 43.5167 18.5839H20.8316ZM2.68359 23.1209H7.2206V45.8059H43.5167V50.3429H2.68359V23.1209Z"
        fill="#018FFD"
      />
    </g>
  </svg>
);
