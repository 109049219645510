import { Button } from "@nextui-org/react";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

interface Props {
  handleContinue: () => void;
}
export default function BhphfContractTwo({ handleContinue }: Props) {
  return (
    <div className="border-gray-500 border  p-4 md:p-8">
      <h1 className="text-center font-bold text-2xl ">BUYERS GUIDE</h1>
      <div className="border-gray-600 border mt-4 mb-2    " />
      <div className="mb-4">
        <p className="text-center">
          IMPORTANT: Spoken promises are difficult to enforce. Ask the dealer to
          put all promises in writing. Keep this form.
        </p>
        <div className="border-gray-400 border mt-8 mb-2 " />
        <div className="flex justify-between items-center">
          <p>VEHICLE MAKE</p>
          <p>MODEL</p>
          <p>YEAR</p>
          <p>VEHICLE IDENTIFICATION NUMBER (VIN)</p>
        </div>
      </div>
      <h1 className="text-left font-bold text-2xl ">
        WARRANTIES FOR THIS VEHICLE:
      </h1>
      <div className="border-gray-600 border  my-2    " />
      <div className="flex gap-4 items-center">
        <span className="border-gray-500 border-2 p-4   "></span>
        <h1 className="text-left font-bold text-2xl ">
          IMPLIED WARRANTIES ONLY
        </h1>
      </div>
      <div className="ml-12">
        The dealer doesn't make any promises to fix things that need repair when
        you buy the vehicle or afterward. But implied warranties under your
        state's laws may give you some rights to have the dealer take care of
        serious problems that were not apparent when you bought the vehicle.
      </div>
      <div className="border-dashed mb-4	border-gray-400 border ml-12" />
      <div className="flex gap-4  items-center mb-2">
        <span className="border-gray-500 border-2    ">
          <BlackCloseXIcon width={"32px"} height={"32px"} className="" />
        </span>
        <h1 className="text-left font-bold text-2xl ">DEALER WARRANTY</h1>
      </div>
      <div className="flex gap-4  items-center mb-2">
        <span className="border-gray-500 border-2  ml-4 p-2 "></span>
        <h1 className="text-left   ">FULL WARRANTY.</h1>
      </div>

      <div className="flex gap-4 mb-4 items-start ">
        <span className="border-gray-500 border-2  ml-4 p-2 "></span>
        <h1 className="text-left   ">
          LIMITED WARRANTY. The dealer will pay{" "}
          <span className="border-gray-500 border-b px-4"></span>% of the labor
          and<span className="border-gray-500 border-b px-4"></span>% of the
          parts for the covered systems that fail during the warranty period.
          Ask the dealer for a copy of the warranty, and for any documents that
          explain warranty coverage, exclusions, and the dealer's repair
          obligations. Implied warranties under your state's laws may give you
          additional rights.
        </h1>
      </div>
      <div className="flex gap-4">
        <div className="w-full">
          <h1 className="text-left  font-bold ">SYSTEMS COVERED:</h1>
          <h1 className="text-left   ">See Attached</h1>
          <div className="mb-6	border-gray-400 border" />
          <div className="mb-6	border-gray-400 border" />
          <div className="mb-6	border-gray-400 border" />
          <div className="mb-6	border-gray-400 border" />
        </div>
        <div className="w-full">
          <h1 className="text-left  font-bold ">DURATION:</h1>
          <h1 className="text-left   ">
            1 Month or 1000 Miles whichever comes first
          </h1>
          <div className="mb-6	border-gray-400 border" />
          <div className="mb-6	border-gray-400 border" />
          <div className="mb-6	border-gray-400 border" />
          <div className="mb-6	border-gray-400 border" />
        </div>
      </div>
      <h1 className="text-left font-bold text-2xl ">
        NON-DEALER WARRANTIES FOR THIS VEHICLE:
      </h1>
      <div className="border-gray-600 border  my-2    " />

      <div className="my-4">
        <div className="flex gap-4  items-start mb-2">
          <span className="border-gray-500 border-2  p-2 "></span>
          <h1 className="text-left   ">
            MANUFACTURER'S WARRANTY STILL APPLIES. The manufacturer's original
            warranty has not expired on some components of the vehicle.
          </h1>
        </div>

        <div className="flex gap-4  items-start mb-2">
          <span className="border-gray-500 border-2  p-2 "></span>
          <h1 className="text-left   ">
            MANUFACTURER'S USED VEHICLE WARRANTY APPLIES.
          </h1>
        </div>

        <div className="flex gap-4  items-start mb-2">
          <span className="border-gray-500 border-2  p-2 "></span>
          <h1 className="text-left   ">OTHER USED VEHICLE WARRANTY APPLIES.</h1>
        </div>
        <div>
          Ask the dealer for a copy of the warranty document and an explanation
          of warranty coverage, exclusions, and repair obligations.
        </div>
        <div className="border-gray-500 border  my-2    " />

        <div className="flex gap-4  items-start mb-2">
          <span className="border-gray-500 border-2  p-2 "></span>
          <h1 className="text-left   ">
            SERVICE CONTRACT. A service contract on this vehicle is available
            for an extra charge. Ask for details about coverage, deductible,
            price, and exclusions. If you buy a service contract within 90 days
            of your purchase of this vehicle, implied warranties under your
            state's laws may give you additional rights.
          </h1>
        </div>
      </div>
      <div className="border-gray-600 border  my-2    " />
      <div>
        <p className="font-bold my-1">
          ASK THE DEALER IF YOUR MECHANIC CAN INSPECT THE VEHICLE ON OR OFF THE
          LOT.
        </p>
        <p className="my-1">
          <span className="font-bold">
            {" "}
            OBTAIN A VEHICLE HISTORY REPORT AND CHECK FOR OPEN SAFETY RECALLS.
          </span>{" "}
          For information on how to obtain a vehicle history report, visit
          ftc.gov/usedcars. To check for open safety recalls, visit
          safercar.gov. You will need the vehicle identification number (VIN)
          shown above to make the best use of the resources on these sites.
        </p>
        <p className="font-bold my-1">
          SEE OTHER SIDE for important additional information, including a list
          of major defects that may occur in used motor vehicles.
        </p>

        <p className="font-bold my-1">
          Si el concesionario gestiona la venta en español, pídale una copia de
          la Guía del Comprador en español.
        </p>
      </div>
      <div className="border-gray-600 border  my-2    " />
      <div className="mb-[10rem] sm:mb-[24rem]">
        <p className="mb-4">
          Here is a list of some major defects that may occur in used vehicles.
        </p>

        <div className="flex gap-4">
          <div className="w-full">
            {[
              {
                label: "Frame & Body",
                content: [
                  "Frame-cracks, corrective welds, or rusted through",
                  "Dog tracks-bent or twisted frame",
                ],
              },
              {
                label: "Engine",
                content: [
                  "Oil leakage, excluding normal seepage",
                  "Cracked block or head",
                  "Belts missing or inoperable",
                  "Knocks or misses related to camshaft",
                  "lifters and push rods",
                  "Abnormal exhaust discharge",
                ],
              },
              {
                label: "Transmission & Drive Shaft",
                content: [
                  "Improper fluid level or leakage, excluding",
                  "normal seepage",
                  "Cracked or damaged case which is visible",
                  "Abnormal noise or vibration caused by faulty",
                  "transmission or drive shaft",
                  "Improper shifting or functioning in any gear",
                  "Manual clutch slips or chatters",
                ],
              },
              {
                label: "Differential",
                content: [
                  "Improper fluid level or leakeage, excluding",
                  "normal seepage",
                  "Cracked of damaged housing which is",
                  "visible",
                  "Abnormal noise or vibration caused by faulty",
                  "differential",
                ],
              },
            ].map(({ label, content }) => {
              return (
                <div key={label} className="mb-2">
                  <h1 className="font-bold text-center">{label}</h1>
                  {content.map((test, key) => (
                    <p key={key} className=" text-center	">
                      {test}
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="w-full">
            {[
              {
                label: "Cooling System",
                content: [
                  "Leakage including radiator",
                  "Improperly functioning water pump",
                ],
              },
              {
                label: "Electrical System",
                content: [
                  "Battery leakage",
                  "Improperly functioning alternator, generator, battery, or starter",
                ],
              },
              {
                label: "Fuel System",
                content: ["Visible leakage"],
              },
              {
                label: "Inoperable Accessories",
                content: [
                  "Gauges or warning devices",
                  "Air conditioner",
                  "Heater & Defroster",
                ],
              },

              {
                label: "Brake System",
                content: [
                  "Failure warning light broken",
                  "Pedal not firm under pressure (DOT spec.)",
                  "Not enough pedal reserve (DOT spec.)",
                  "Does not stop vehicle in straight line (DOT spec.)",
                  "Hoses damaged",
                  "Drum or rotor too thin (Mfgr. Specs)",
                  "Lining or pad thickness less than 1/32 inch",
                  "Power unit not operating or leaking",
                  "Structural or mechanical parts damaged",
                ],
              },

              {
                label: "Air Bags",
                content: [],
              },
            ].map(({ label, content }) => {
              return (
                <div key={label} className="mb-2">
                  <h1 className="font-bold text-center">{label}</h1>
                  {content.map((test, key) => (
                    <p key={key} className=" text-center	">
                      {test}
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="w-full">
            {[
              {
                label: "Steering System",
                content: [
                  "Too much free play at steering wheel (DOT specs.)",
                  "Free play in linkage more than 1/4 inch",
                  "Steering gear binds or jams",
                  "Front wheels aligned improperly (DOT specs.)",
                  "Power unit belts cracked or slipping",
                  "Power unit fluid level improper",
                ],
              },
              {
                label: "SystemSuspension",
                content: [
                  "Ball joint seals damaged",
                  "Structural parts bent or damaged",
                  "Stabilizer bar disconnected",
                  "Spring broken",
                  "Shock absorber mounting loose",
                  "Rubber bushings damaged or missing",
                  "Radius rod damaged or missing",
                  "Shock absorber leaking or functioning improperly",
                ],
              },
              {
                label: "Tires",
                content: [
                  "Tread depth less than 2/32 inch",
                  "Sizes mismatched",
                  "Visible damage",
                ],
              },
              {
                label: "Inoperable Accessories",
                content: [
                  "Visible cracks, damage or repairs",
                  "Mounting bolts loose or missing",
                ],
              },

              {
                label: "Wheels",
                content: ["Leakage", "Catalytic Converter"],
              },
            ].map(({ label, content }) => {
              return (
                <div key={label} className="mb-2">
                  <h1 className="font-bold text-center">{label}</h1>
                  {content.map((test, key) => (
                    <p key={key} className=" text-center	">
                      {test}
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="border-gray-600 border  my-2    " />
      <div>
        {[
          { fleid: ["DEALER NAME"] },
          { fleid: ["ADDRESS"] },
          { fleid: ["TELEPHONE", "EMAIL", ""] },
          { fleid: ["FOR COMPLAINTS AFTER SALE, CONTACT:"] },
        ].map(({ fleid }) => {
          return (
            <>
              <div className="border-gray-400 border mt-4 " />
              <div className="flex justify-between items-center">
                {fleid.map((txt, key) => (
                  <p key={key}>{txt}</p>
                ))}
              </div>
            </>
          );
        })}

        <div className="border-gray-400 border mt-4 mb-8" />
      </div>
      <div className="border-gray-600 border  my-2    " />
      <h2>
        <span className="font-bold">IMPORTANT:</span> The information on this
        form is part of any contract to buy this vehicle. Removing this label
        before consumer purchase (except for purpose of test-driving) violates
        federal law (16 C.F.R. 455).
      </h2>
      <div className="border-gray-600 border  my-12    " />
      <div className="p-8">
        {[
          "Systems Covered under Limited 30 day 1000 mile warranty as required by California Law for vehicles sold with Buy Here Pay Here Loans:",

          "(1) Engine, including all internally lubricated parts.",
          "(2) Transmission and transaxle.",
          "(3) Front and rear wheel drive components.",
          "(4) Engine cooling system.",
          "(5) Alternator, generator, starter, and ignition system, not including the battery.",
          "(6) Braking system.",
          "(7) Front and rear suspension systems.",
          "(8) Steering system and components.",
          "(9) Seatbelts.",
          "(10) Inflatable restraint systems installed on the vehicle as originally manufactured.",
          "(11) Catalytic converter and other emissions components necessary for the vehicle to pass a California emissions test.",
          "(12) Heater.",

          "(13) Seals and gaskets on components described in this subdivision.",
          "(14) Electrical, electronic, and computer components, to the extent that those components substantially affect the functionality of other components described in this subdivision.",
        ].map((item, key) => {
          return <div key={key} className="font-semibold">{item}</div>;
        })}
      </div>

      <div className="my-6 flex justify-center">
      <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
            type="button"
            size="md"
            onClick={handleContinue}
          >
            Continue
          </Button>
      </div>
    </div>
  );
}
