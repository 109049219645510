import { Typography } from "components";
import React, { useEffect } from "react";
import checkIcon from "./assets/svg/check-icon.svg";
export default function CareersPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const listOptions = [
    {
      name: "Be a Trailblazer: ",
      description:
        "We're pioneers in the automotive marketplace revolution, and you'll play a direct role in shaping its next chapter.",
    },
    {
      name: "Make a Real Difference: ",
      description:
        "Your work will touch the lives of countless car buyers and dealers, connecting them and fulfilling their dreams.",
    },
    {
      name: "Embrace Innovation: ",
      description:
        "We thrive on creativity and welcome you to challenge the status quo and explore groundbreaking ideas.",
    },
    {
      name: "Collaborate and Grow: ",
      description:
        "We foster a culture of teamwork, open communication, and mutual support, where everyone's voice matters.",
    },
    {
      name: "Invest in Your Future: ",
      description:
        "We provide exceptional professional development opportunities and career advancement paths to help you reach your full potential.",
    },
    {
      name: "Enjoy the Perks: ",
      description:
        "We reward our employees with competitive salaries, comprehensive benefits packages, and a dynamic work environment.",
    },
  ];

  return (
    <div className="flex items-center justify-center">
      <div className="md:p-3 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="text-3xl 2xl:text-5xl font-bold mb-5">Careers</p>
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-xl 2xl:text-3xl font-bold pt-5">
            Unleash Your Potential: Drive Innovation at{" "}
            <span className="underline"> findmyoptions.com </span>
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 ">
          <p className="text-lg 2xl:text-xl">
            At findmyoptions.com, we're not just transforming the automotive
            marketplace, we're building a team of passionate individuals who
            share our ambition for a seamless and exhilarating car-buying
            experience. Whether you're a tech wizard, a marketing champion, or a
            customer service hero, we invite you to join our fast-paced,
            collaborative environment and fuel the future of automotive sales.
          </p>
        </Typography>

        <Typography variant="custom" className="mt-10 ">
          <p className="pb-5 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl 2xl:text-3xl font-bold pt-5 text-center">
            Why Choose Us?
          </p>
          {listOptions.map((res) => (
            <div className="flex items-start  ">
              <img src={checkIcon} height={16} width={16} className="mt-3.5" />
              <p className={`px-3 pt-3 text-black font-light text-xl`}>
                <span className="font-semibold text-black bg-clip-text">
                  {res.name}
                </span>
                {res.description}
              </p>
            </div>
          ))}
        </Typography>
        <Typography variant="custom" className="mt-10">
          <p className="text-lg 2xl:text-xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Ready to Take the Wheel?
          </p>
          <p className="text-lg 2xl:text-xl mt-2">
            We're currently seeking talented individuals across various
            departments, including software development, marketing, customer
            support, operations, finance, and more!
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 mb-10 ">
          <p className="text-lg 2xl:text-xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Here's how to join our mission:
          </p>
          <div className="ml-2">
            <p className="text-lg 2xl:text-xl mt-2">
              1. Explore our Current Openings: Dive into our detailed job
              descriptions on Indeed to find the perfect fit for your skills and
              aspirations.
            </p>
            <p className="text-lg 2xl:text-xl mt-2">
              2. Follow Us on LinkedIn: Stay updated on our latest
              opportunities, company news, and team culture by connecting with
              us on LinkedIn.
            </p>
            <p className="text-lg 2xl:text-xl mt-2">
              3. Get in Touch: We're always eager to meet passionate
              individuals. Don't hesitate to reach out with your resume and tell
              us why you belong on our team.
            </p>
          </div>
        </Typography>
      </div>
    </div>
  );
}
