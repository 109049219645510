import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableHeader,
//   TableColumn,
//   TableBody,
//   TableRow,
//   TableCell,
//   Image,
// } from "@nextui-org/react";
// import viewIcon from "../../../../dealer/manage-inventory/assets/svg/view-icon.svg";
import { money } from "utils/money";
import { Button, Typography } from "components";
import { VehicleRepairSubscriptionPayload } from "modules/user/services/endpoints/repair/repair-agreement.type";
import { useGetVehicleServiceContract } from "modules/user/hooks/api/mutations/benefits/repair-agreement";
import VehicleServiceContractDetails from "./vehicle-service-contract-details";
import OdometerVerification from "./odometer-verification";
import Loading from "components/Loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import SearchCarCard from "modules/shared/components/car-card/SearchCarCard";
import uploadIcon from "../../assets/gray-upload.png";
import fileIcon from "../../assets/file-icon.png";
import { findVehicleBy } from "utils/find-vehicle-by";
import WarrentyCard from "modules/shared/components/warrenty-card/WarrentyCard";

interface Props {}

export default function ListRepairAgreements({}: Props) {
  const navigate = useNavigate();
  const [selectedCar, setSelectedCar] =
    useState<VehicleRepairSubscriptionPayload | null>(null);
  const {
    data: vscStatus,
    refetch: getVscStatusFetch,
    isPending: vscPending,
  } = useGetVehicleServiceContract(true);
  const location = useLocation();

  // Access the passed data
  const passedData = location.state;
  
  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4 ">
        <Typography variant="pageTitle" className="pb-12">
          Used Car Warranty
        </Typography>
        <div className=" w-full  flex justify-between mb-2">
          <div></div>
          <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl"
            type="button"
            size="md"
            onClick={() => navigate("add")}
          >
            <Typography className="font-bold" variant="body">
              {" "}
              Add Warranty
            </Typography>
          </Button>
        </div>
      </div>
    );
  }, []);

 
  useEffect(() => {
    if (vscStatus && vscStatus.length === 0) {
      navigate("add",{ state:null});
    }
    if(passedData?.length > 0){
      setSelectedCar({ vin: passedData})
      navigate("",{ state:null});
   
    }
  }, [vscStatus]);
 
  if (vscPending) return <Loading />;

  return (
    <div>
      {selectedCar ? (
        selectedCar?.odometerStatus === 1   ? (
          <VehicleServiceContractDetails
            vscStatus={selectedCar}
            handleViewAll={() => setSelectedCar(null)}
          />
        ) : (
          <OdometerVerification
            vin={selectedCar.vin }
            OdometerVerification={selectedCar?.odometerStatus === 2 ? 1 : selectedCar?.isOdometerVerified ? 0: 1 }
            handleSubmit={() => {
              setSelectedCar(null);
              getVscStatusFetch();
            }}
          />
        )
      ) : (
        <div className="p-0">
          {/* <InfiniteScroll
          dataLength={items.length}
          next={refetch}
          hasMore={page !== null && items.length >= 9}
          scrollThreshold={0.8}
          loader={
            <p className="flex justify-center items-center">
              <b>Loading...</b>
            </p>
          }
        > */}
          {topContent}
        
          <div className="col-span-4  pb-20 overflow-y-auto mt-8">
            {vscStatus && vscStatus.length > 0  && passedData === null? (
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 gap-4">
                {vscStatus?.map((car) => {
                  // const year =findVehicleBy(car?.vinData,"ModelYear")?.value;
                  // const make = findVehicleBy(car?.vinData,"Make")?.value;
                  // const model = findVehicleBy(car?.vinData,"Model")?.value;
                  
                  return (
                    <WarrentyCard
                      car={car}
                      key={car.vin}
                      // line1={`${ year || ""} ${make} ${model}`}
                      
                      line2={`VIN - ${car.vin}`}
                      // imgURl={`${env.BASE_API_URL}Listings/image/${car.listingId}`}
                      imgURl={""}
                      // imgURl={car.isOdometerVerified ? fileIcon : uploadIcon}
                      line3={`$${
                        car?.agreementAmount
                          ? money(+car?.agreementAmount)
                          : "0"
                      }`}
                      // line1={`${car?.year || ""} ${car.make} ${car.model}`}
                      line4={`${
                        car?.monthsRemaining
                          ? money(+car?.monthsRemaining)
                          : "0"
                      } months remaining`}
                      status={` ${
                         car.odometerStatus === 0 ? "Pending Approval" : car.odometerStatus === 1?"Approved":"Rejected"
                      }`}

                      btnLabel={"View"}
                      onSelect={() => {
                        setSelectedCar(car);
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center items-center mt-8">
                <Typography variant="subtitle" className="pb-16">
                  No Used Car Warranty Found!
                </Typography>
              </div>
            )}
          </div>
          {/* </InfiniteScroll> */}
        </div>
      )}
    </div>
  );
}
