import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface VinDataResponse {
  status: number;
  message: VinData[];
}

export interface VinData {
  code: string;
  variable: string;
  value: string;
  groupName: string | null;
}

export interface EnterVIN {
  data: string;
  error: boolean;
}

export default function EnterVINNumber() {
  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [vinData, setVinData] = useState<VinDataResponse | null>(null);
  const [vinNumber, setVinNumber] = useState<EnterVIN>({
    data: "",
    error: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    let interval: number;
    if (step === 1) {
      let interval = setInterval(() => {
        if (progress < 100) {
          setProgress((p) => p + 20);
        } else {
          clearInterval(interval);
          setProgress(100);
          if (vinNumber.error) {
            setStep(0);
          } else {
            navigate("/dealer/manage-listing");
          }
        }
      }, 500);
    }
    return () => clearInterval(interval);
  }, [progress, step]);

  const fetchVinData = async () => {
    try {
      if (vinNumber.data.length <= 0 || vinNumber.error) {
        throw new Error("len not right");
      }
      setStep(1);
      const response = await axios.get(
        `/VinData/DecodeVinData?vin=${vinNumber.data}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );
      setVinData(response.data);
      const err =
        response.data?.message?.find(
          (data: VinData) => data.code === "ErrorCode"
        ).value !== "0";
      setVinNumber((prev) => ({
        ...prev,
        error: err,
      }));
    } catch (error) {
      console.error("Error fetching VIN data:", error);
      setVinNumber((prev) => ({ ...prev, error: true }));
    }
  };

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber((prev) => ({ ...prev, data: e.target.value.trim().toLocaleUpperCase() }));
    const len = e.target.value.trim().length;
    if (len !== 17) {
      setVinNumber((prev) => ({ ...prev, error: len !== 0 }));
    } else {
      setVinNumber((prev) => ({ ...prev, error: false }));
    }
  };

  return (
    <>
      <div className="flex items-center m-auto">
        <div className="flex items-center px-4 mx-auto bg-fixed bg-center bg-cover ">
          <form className="flex flex-col px-8 py-12 rounded-[38px] shadow-md shrink-0 w-full max-w-[770px] min-h-[428px] h-full bg-gradient-to-b from-[#018ffd] to-[#070c84]  md:px-8">
            <div className="flex flex-col justify-center w-3/4 max-w-md mx-auto mb-12">
              <label
                htmlFor="yourInput1"
                className={`text-center mb-4 ${
                  vinNumber.error ? "text-red-500" : "text-white"
                } font-bold  md:text-2xl text-lg `}
              >
                {"Enter VIN Number"}
              </label>
              <div className="relative w-full">
                <input
                  type="text"
                  id="yourInput1"
                  value={vinNumber.data}
                  name="yourInput1"
                  onChange={onChangeVIN}
                  className={`w-full text-center border-none focus:outline-none bg-transparent ${
                    vinNumber.error ? "text-red-500" : "text-white"
                  } font-semibold text-2xl md:text-4xl `}
                />
                <div
                  className={`absolute -bottom-2 left-0 w-full h-[1.5px]  ${
                    vinNumber.error ? "bg-red-500" : "bg-white"
                  }`}
                ></div>
              </div>
            </div>
            {[
              "See basic free data",
              "Vehicle specific options",
              "Vehicle history report",
              "Full subscription",
            ].map((name, key) => (
              <div key={key} className="flex items-center justify-between">
                <h5 className="mb-1 text-sm text-white md:text-xl">{name}</h5>
                <label className="relative inline-flex items-center mb-1 cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div className="relative w-11 h-6 bg-red peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-[#ffffffbf] peer-checked:bg-[#ffffffbf] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-blue-400 peer-checked:after:bg-blue-500 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div>
                </label>
              </div>
            ))}
            <div className="grid items-center justify-between grid-cols-1 gap-4 mt-auto md:grid-cols-3">
              <div className=" md:col-span-2">
                <h5
                  className="text-white text-sm md:text-xl font-[Metropolis]"
                  style={{ fontFamily: "Metropolis" }}
                >
                  Get vehicle data and options history?
                </h5>
                <h6 className="text-xs text-white md:text-base">
                  (This will result in a charge to your credit card)
                </h6>
              </div>
              <button
                onClick={() => {
                  fetchVinData();
                }}
                className="py-2 text-lg text-white rounded-[20px] border border-white bg-[#018ffd] max-w-[200px] w-full [box-shadow:2px_2px_15px_0px_rgba(255,255,255,0.3)] focus:outline-none focus:shadow-outline md:text-2xl"
                type="button"
              >
                {"Confirm"}
              </button>
            </div>
          </form>

          {step > 0 && (
            <div
              style={{ zIndex: 150 }}
              className="flex overflow-y-auto p-4 overflow-x-hidden fixed items-center px-[72px] md:pl-[314px] pt-[130px] justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full"
            >
              <div className="absolute inset-0 bg-black opacity-50 z-999"></div>

              {step === 1 && (
                <div className="relative  max-w-[362px] md:max-w-[580px] rounded-[40px] bg-gradient-to-l from-[#018ffd] to-[#070c84] h-[450px] py-24 px-10  md:px-24  mx-auto shadow-md ">
                  <div className="flex justify-center mb-4 text-lg font-bold text-white md:text-2xl">
                    Searching for Data
                  </div>
                  <div className="flex justify-center mb-16 text-2xl font-bold text-white md:text-4xl">
                    {vinNumber.data}
                  </div>

                  <div className="flex justify-center mb-2">
                    <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-base">
                      {" "}
                      Please wait while findmyoptions.com queries your VIN
                    </p>
                  </div>

                  <div className="flex justify-center">
                    <div className="w-full h-[20px] bg-white max-w-[258px] rounded-2xl overflow-hidden border-[4px]">
                      <div
                        className="h-full bg-[#018ffd] rounded-2xl transition-[width] duration-300 ease-in-out"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
