import { Typography } from "components/typography";
import { CustomInput, FormControl } from "./custome";
import { APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM } from "./schema.type";
import { DateTextField } from "components/date-text-field";
import { SelectNextUi } from "components/select-next-ui";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { useGetZipCodeDetails } from "services";

interface Props {
  type: "EmploymentInformation" | "PreviousEmploymentInformation";
  errorObj?: any;
  register: any;
  getValues: any;
}

export const EmploymentInformationForm = ({
  type,
  register,
  getValues,
  errorObj,
}: Props) => {
  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10">
        {APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM.EmploymentInformation.top.map(
          (field, id) => {
            return (
              <FormControl key={id}>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                >
                  {field.label}
                </Typography>
                <CustomInput
                  errorMessage={errorObj?.top?.[field.name as any]?.message}
                  errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                  inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                  defaultValue={getValues(`${type}.top.${field.name}` as any)}
                  inputProp={register(`${type}.top.${field.name}` as any)}
                />
              </FormControl>
            );
          }
        )}
      </div>
      <div 
      className="flex flex-col w-full gap-4  md:flex-row flex-grow justify-between items-center"
    
      >
        {APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM.EmploymentInformation.bottom.map(
          (field, id) => {
            return (
              <div className="w-full  gap-1 mb-4" key={id}>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] text-left text-sm 2xl:text-md"
                >
                  {field.label}
                </Typography>
                {field?.type === "Years" ? (
                     <div className="w-full flex gap-2">
                     <SelectNextUi
                       placeholder="Select House Type"
                       className="w-1/2"
                       size="sm"
                       errorMessage={
                         errorObj?.bottom?.[field.name as any]?.month?.message
                       }
                       aria-label="Select Vehicle Type"
                       data={
                         field.optionsWithLabel || []
                       }
                       heightClass="h-[3rem] "
                       register={register(`${type}.bottom.${field.name}.month` as any)}
                       portalId="Residential_Information_select"
                     />
                     {/* <DateTextField
                       classNames={{
                         mainWrapper: "w-full",
                         base: "w-full ",
                         input: "px-4 w-full text-center ",
                       }}
                       className="w-full"
                       showYearPicker={true}
                       errorMessage={
                         errorObj?.bottom?.[field.name as any]?.years?.message
                       }
                       {...register(`${type}.bottom.${field.name}.years` as any)}
                       initDate={getValues(
                         `${type}.bottom.${field.name}.years` as any
                       )}
                       onDateChange={(date) => {
                         register(
                           `${type}.bottom.${field.name}.years` as any
                         ).onChange({
                           target: {
                             name: `${type}.bottom.${field.name}.years` as any,
                             value: date,
                           },
                         });
                       }}
                     /> */}
                     <CustomInput
                    errorMessage={
                      errorObj?.bottom?.[field.name as any]?.years?.message
                    }
                    wrapperClass="w-1/2"
                    errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                    inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                    defaultValue={getValues(
                      `${type}.bottom.${field.name}.years` as any
                    )}
                    
                    inputProp={register(`${type}.bottom.${field.name}.years` as any)}
                    // inputProp={{register:register(`${type}.bottom.${field.name}.years` as any),type:"number"}}
                 
                  />
                   </div>
                ) : (
                  <CustomInput
                    errorMessage={
                      errorObj?.bottom?.[field.name as any]?.message
                    }
                    errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                    inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                    defaultValue={getValues(
                      `${type}.bottom.${field.name}` as any
                    )}
                    inputProp={register(`${type}.bottom.${field.name}` as any)}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

interface PropsRes {
  type:
    | "ResidentialInformation"
    | "PreviousResidentialInformation"
    | "PreviousResidentialInformationCo";
  errorObj?: any;
  register: any;
  getValues: any;
  setValue:any
}

export const ResidentialInformationForm = ({
  type,
  register,
  getValues,
  setValue,
  errorObj,
}: PropsRes) => {
  
  const [zipValue, setZipValue] = useState("");
  const [debouncedValue] = useDebounceValue(zipValue, 300);
  const { mutateAsync: zipcodeMutateAsync} =
    useGetZipCodeDetails(debouncedValue);
  console.log("data",getValues(
    `ResidentialInformation.top.ZipCode` as any
  ))
  useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
       const data = await zipcodeMutateAsync();
       if(data){
         setValue(`${type}.top.State`,data.state)
         setValue(`${type}.top.City`,data.city)
       }
      };
      fetchZipCode();
    }
  }, [debouncedValue]);


  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
        {APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM.ResidentialInformation.top.map(
          (field, id) => {
            return (
              <FormControl key={id}>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                >
                  {field.label}
                </Typography>
              {field.name === "ZipCode"?( <CustomInput
                  errorMessage={
                    errorObj?.top?.[field.name as any]?.message
                  }
                  errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                  inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                  defaultValue={getValues(
                    `${type}.top.${field.name}` as any
                  )}
                  inputProp={{...register(`${type}.top.${field.name}` as any) ,onChange : (e:any)=>{
                    register(`${type}.top.${field.name}`)?.onChange(e)
                    setZipValue(e.target.value)
                  }}}
                />):(
                <CustomInput
                errorMessage={errorObj?.top?.[field.name as any]?.message}
                errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                defaultValue={getValues(`${type}.top.${field.name}` as any)}
                inputProp={register(`${type}.top.${field.name}` as any)}
                />)
              }
              </FormControl>
            );
          }
        )}
      </div>
      <div 
      //   className="flex flex-col w-full gap-4  md:flex-row flex-grow justify-between items-center"
    className="grid grid-cols-1 md:grid-cols-2 xl:flex gap-2 gap-x-10 xl:gap-4"
      
    //   className="flex flex-col w-full gap-4  md:flex-row flex-grow justify-between items-center"
      >
        {APPY_ONLINE_FORM_FIELDS_DATA_PAYMENT_FORM.ResidentialInformation.bottom.map(
          (field, id) => {
            return (
              <div className={`w-full  gap-1 mb-4 ${field.type === "Years" ? "col-span-1 md:col-span-1 xl:col-span-2":""}`} key={id}>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] text-left text-sm 2xl:text-md"
                >
                  {field.label}
                </Typography>

                {field?.type === "Years" ? (
                  <div className="w-full flex gap-2 gap-x-2 md:gap-x-2 xl:gap-x-2">
                    <SelectNextUi
                      placeholder="Select House Type"
                      size="sm"
                      className="w-1/2"
                      errorMessage={
                        errorObj?.bottom?.[field.name as any]?.month?.message
                      }
                      aria-label="Select Vehicle Type"
                      data={
                        field.optionsWithLabel || []
                      }
                      heightClass="h-[3rem]"
                      register={register(`${type}.bottom.${field.name}.month` as any)}
                      portalId="Residential_Information_select"
                    />
                   
                   <CustomInput
                    errorMessage={
                      errorObj?.bottom?.[field.name as any]?.years?.message
                    }
                    wrapperClass="w-1/2"
                    errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                    inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                    defaultValue={getValues(
                      `${type}.bottom.${field.name}.years` as any
                    )}
                    inputProp={register(`${type}.bottom.${field.name}.years` as any)}
                    // inputProp={{register:register(`${type}.bottom.${field.name}.years` as any),type:"number"}}
                  />
                  </div>
                ) : field?.type === "Options" ? (
                  <div>
                    <SelectNextUi
                      placeholder="Select House Type"
                      size="sm"
                      errorMessage={
                        errorObj?.bottom?.[field.name as any]?.message
                      }
                      aria-label="Select Vehicle Type"
                      data={
                        field.options?.map((val) => ({
                          label: val,
                          value: val,
                        })) || []
                      }
                      heightClass="h-[3rem]"
                      register={register(`${type}.bottom.${field.name}` as any)}
                      portalId="Residential_Information_select"
                    />
                  </div>
                ) :  (
                  <CustomInput
                    errorMessage={
                      errorObj?.bottom?.[field.name as any]?.message
                    }
                    errorClass="text-red-400 mt-0.5 text-sm text-[Metropolis]"
                    inputClass="bg-white w-full border-blue-500 border-1.5 rounded-2xl h-[3rem] px-2 text-center"
                    defaultValue={getValues(
                      `${type}.bottom.${field.name}` as any
                    )}
                    inputProp={register(`${type}.bottom.${field.name}` as any)}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
