import { http } from "services/http/service.http";
import { throwNotErrorSuccessResponse } from "config/retryConfig";
import { ISubmitVehiclePurchaseApplicationPayload, IVehicleBuyerApplicationPayloadAppyFinance } from "./apply-finance.type";


class AppyForLoanApplication {
  public async SubmitVehiclePurchaseApplication(payload:ISubmitVehiclePurchaseApplicationPayload): Promise<any> {
    try{

      const res = await http.post(`/Finance/SubmitVehiclePurchaseApplication`,payload, {
        headers: {
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(res,res.data || "Failed to submit vehicle purchase application! please try again.")
      return res.data;
    }catch(e){
      throw e
    }
  }

  public async SubmitBuyerInformation(payload:IVehicleBuyerApplicationPayloadAppyFinance): Promise<any> {
    try{

      const res = await http.post(`/Finance/SubmitBuyerInformation`,payload, {
        headers: {
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(res,res?.data?.title || "Failed to submit Buyer Information! please try again.")
      return res.data;
    }catch(e){
      throw e
    }
  }

}

export const appyForLoanApplication = new AppyForLoanApplication();
