import { useMutation } from "@tanstack/react-query";
import { userMemeberShipEndpoint } from "services/endpoints/user-membership/user-membership";
import { UserTierOpt } from "services/endpoints/user-membership/user-membership.type";

export const useProcessUserTier = () => {
  return useMutation<string, Error, UserTierOpt>({
    mutationFn: async (payload) => {
      const res = await userMemeberShipEndpoint.processUserTier(payload);
      return res;
    },
  });
};

export const useProcessUserTierRegistration = () => {
  return useMutation<string, Error, UserTierOpt>({
    mutationFn: async (payload) => {
      const res = await userMemeberShipEndpoint.processUserTierRegistration(
        payload
      );
      return res;
    },
  });
};

// export const useSaveShippingQuotesInfo = () => {
//   return useMutation<any, Error,string >({
//     mutationFn: async (payload) => {
//       const res = await shippingEndpoint.saveShippingQuotes(payload);
//       return res;
//     },
//   });
// };
// export const useGetShippingQuotesById = () => {
//   return useMutation<ShippingByIDResponse, Error,string >({
//     mutationFn: async (payload) => {
//       const res = await shippingEndpoint.getShippingQuotesById(payload);
//       return res;
//     },
//   });
// };

// export const useAcceptPriceRateShipping= () => {
//   return useMutation<any, Error, string >({
//     mutationFn: async (payload) => {
//       const res = await shippingEndpoint.AcceptPriceRateShipping(payload);
//       return res;
//     },
//   });
// };

// export const useTransactionsCheckForPaymentMethod = () => {
//   return useMutation<PaymentCardInfo, Error >({
//     mutationFn: async () => {
//       const res = await shippingEndpoint.TransactionsCheckForPaymentMethod();
//       return res;
//     },
//   });
// };

// export const useConfirmShippingPayment = () => {
//   return useMutation<any, Error,string >({
//     mutationFn: async (payload) => {
//       const res = await shippingEndpoint.ConfirmShippingPayment(payload);
//       return res;
//     },
//   });
// };

// export const useAddPaymenTransactiontMethod = () => {
//   return useMutation<any, Error,AddCardMethodPayload >({
//     mutationFn: async (payload) => {
//       const res = await shippingEndpoint.AddPaymenTransactiontMethod(payload);
//       return res;
//     },
//   });
// };

// // export const useShippingOrderHistory = () => {
// //   return useMutation<CarShippignData[], Error,number >({
// //     mutationFn: async (payload) => {
// //       const res = await shippingEndpoint.getShippingOrderHistory(payload);
// //       return res;
// //     },
// //   });
// // };
// export const useShippingOrderHistory = (page: number, updateState:(newData:CarShippignData[])=>void) => {
//   return useQuery<CarShippignData[], Error>({
//     queryKey: ["shipping-order-data"],
//     queryFn: async () => {
//       const res = await shippingEndpoint.getShippingOrderHistory(page);
//       updateState(res)
//       return res;
//     },
//     enabled: !!page,
//     refetchOnWindowFocus: false,
//     refetchOnMount: true,
//     refetchInterval: false,
//   });
// };
