import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../dealer/dealer-membership/index.scss";
import { getUserRoles } from "utils/get-user-id";
import { PaymentModal } from "pages/shared";
import CarTierCard from "./CarTierCard";
import UsedCardForm from "modules/shared/features/UsedCarForm/UsedCarForm";
import { steps } from "framer-motion";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";

const tiers = [
  {
    id: "T1W",
    name: "Tier 1",
    type: "Used Car Warranty",
    note: "per month",
    price: 99,
    features: [
      "10k Miles/Year",
      "36 Months",
      "Roadside Assistance",
      "FMO Membership",
    ],
  },
  {
    id: "T2W",
    name: "Tier 2",
    type: "Used Car Warranty",
    note: "per month",
    price: 129,
    features: [
      "15k Miles/Year",
      "36 Months",
      "Roadside Assistance",
      "FMO Membership",
    ],
  },
];

interface PropsForDetails {}
export const CarTierDetails: React.FC<PropsForDetails> = ({}) => {

  const [openModal, setOpenModal] = useState(0);
  const [selectedTiers, setSelectedTiers] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const handleNextThankyou = async () => {
    try {
      // const userRole = getUserRoles();
      // if (userRole === "User") {
      //   navigate("/user/benefits/repair-agreement");
      // } else {
      //   navigate("/register/user");
      // }
      setOpenModal(0);
    } catch (e) {
      setOpenModal(0);
      console.log(e);
    }
  };

  const handleOpenSuccess = () => {
    setOpenModal(2);
    navigate(
      `/listing/membership-advantage/used-car-warranty/thank-you/${selectedTiers}`
    );
  } 
  useEffect(()=>{
    if (location.pathname.includes('used-car-warranty/thank-you/T1W')) {
      setOpenModal(2);
      navigate(
        `/listing/membership-advantage/used-car-warranty/thank-you/T1W`
      );
    }else if(location.pathname.includes('used-car-warranty/thank-you/T2W')){
      setOpenModal(2);
      navigate(
        `/listing/membership-advantage/used-car-warranty/thank-you/T2W`
      );
    }else if (location.pathname.includes('used-car-warranty/warranty-form/T1W')) {
      setOpenModal(1);
      setSelectedTiers("T1W")
      navigate(
        `/listing/membership-advantage/used-car-warranty/warranty-form/T1W`
      );
    } else if (location.pathname.includes('used-car-warranty/warranty-form/T2W')) {
      setOpenModal(1);
      setSelectedTiers("T2W")
      navigate(
        `/listing/membership-advantage/used-car-warranty/warranty-form/T2W`
      );
    }
  },[])
  return (
    <div className="flex flex-col md:flex-row items-center justify-center">
      {tiers.map((tier) => {
        return (
          <CarTierCard
            key={tier.id}
            tier={tier}
            // isSelected={tier.id === selectedTier}
            handleSelect={() => {
              setOpenModal(1);
              setSelectedTiers(tier.id);
              navigate(
                `/listing/membership-advantage/used-car-warranty/warranty-form/${tier.id}`
              );
            }}
          />
        );
      })}

      <PaymentModal
        isPublic={true}
        isOpen={openModal !== 0}
        onOpen={() => {
          setOpenModal(0);
          navigate("/listing/membership-advantage/used-car-warranty");
        }}
        Component={
          openModal === 1 ? (
            <UsedCardForm
              handleNext={handleOpenSuccess}
            />
          ) : (
            <ThankYou onClick={handleNextThankyou} />
          )
        }
      />
    </div>
  );
};
