import * as React from "react";
import { Card, Image } from "@nextui-org/react";
import { Typography } from "components";

interface WhyChooseFMOProps {}

const DataFMOLeft = [
  {
    title: "Transparency…The data doesn’t lie.",
    content:
      "FMO creates every listing with proprietary software, pulling accurate vehicle data and history for all of our customers. This simplifies listing creation, saving time and ensuring clean, accurate descriptions. We're making listings more reliable, no more scouring the web looking for additional data on a vehicle.",
  },
  {
    title: "Cost-Effective History Reports",
    content:
      "Our history reports are just as accurate as those from other providers but at a significantly lower cost. We source our data from the same reputable sources. Stop paying more for the same information.",
  },
  {
    title:
      "Turbo, our AI sales assistant gives you instant data at your fingertips",
    content:
      "Use our chatbot anywhere, empowering informed decisions pertaining to anything vehicle related that you have questions on.",
  },
  {
    title: "100% Customer Focused",
    content:
      "Your satisfaction is our top priority. We strive to be your number one technology partner, not just today but forever. We're committed to keeping our site updated, and your feedback is crucial to building software tailored specifically to your needs.",
  },
  {
    title: "Leveling the Playing Field",
    content:
      "We provide independent shops with the same tools as large online retailers, including instant quoting tools for tax, shipping, and DMV transfers. Our custom quote forms for consumers help give buyers and sellers piece of mind when completing transactions.",
  },
  {
    title: "Take Control with FMO",
    content:
      "Experience the FMO difference and revolutionize your vehicle purchasing and sales processes. Sign up with an account or contact us today to learn more.",
  },
];

export const WhyChooseFMO: React.FC<WhyChooseFMOProps> = () => {
  return (
    <>
      {" "}
      <Typography
        className="mt-10 flex items-center gap-4 text-2xl md:text-4xl bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text"
        variant="title"
      >
        Why Choose{" "}
        <Image
          src="assets/icons/new-logo.svg"
          className="w-28 h-28 -mb-2 m-0 md:m-0"
          removeWrapper
          radius="none"
          shadow="none"
        />
      </Typography>
      <Card
        className=" grid grid-cols-1 md:grid-cols-2 justify-items-center border-none shadow-none"
        fullWidth
        isPressable={false}
      >
        {DataFMOLeft.map((item, i) => {
          return (
            <div
              className={`border border-blue-400 w-[98%] rounded-3xl p-5 my-2 ml-0 mr-0 ${
                i % 2 ? "md:ml-2" : "md:mr-2"
              }`}
              key={i}
            >
              <Typography
                variant="custom"
                className="text-md md:text-[19px] font-[600] text-[#018FFD] text-center md:text-left"
              >
                {item.title}
              </Typography>
              <Typography
                variant="custom"
                className="text-[12px] md:text-[14px] font-[500] text-black mt-2 md:mt-4 "
              >
                {item.content}
              </Typography>
            </div>
          );
        })}
      </Card>
    </>
  );
};
