import React from "react";
import { Button, Card, CardBody } from "@nextui-org/react";
import Form from "components/ui/form";
import { Typography } from "components";
import { VehicleOptPaymentValidationSchema } from "./VehicleOptPaymentValidationSchema";
import Input from "components/ui/input";
import paymentBg from "../../../pages/dealer/offer-accepted/assets/payment-bg.png";

interface Props {
  onCallback: () => void;
  // onApplyOnline: () => void;
  btnLabel: string;
  messageTop?: string;
  messageMid?: string;
  messageBottom?: string;
  priceData:{base:string,ship:string,fees:string,total:string}
}

export default function OtherOfferPaymentsFormCashZelle({
  btnLabel,
  onCallback,
  messageTop,
  messageMid,
  messageBottom,
  priceData
}: Props) {
  return (
    <Card
      className="min-w-[340px] "
      style={{
        backgroundImage: `url(${paymentBg})`,
        backgroundSize: "cover",
      }}
    >
      <CardBody>
        <div className="p-16 px-16 ">
          <div className="mt-10 ">
            {[
              {
                label: "Vehicle Base Price",
                name: "vehicleBasePrice",
                editable: false,
                defaultVal: priceData?.base
              },
              {
                label: "Vehicle Taxes & Fees",
                name: "vehicleTaxesFees",
                editable: false,
                defaultVal: priceData?.fees
              },
              {
                label: "Shipping Fees",
                name: "ShippingFees",
                editable: false,
                defaultVal: priceData?.ship
              },
              {
                label: "Total",
                name: "total",
                editable: false,
                defaultVal: priceData?.total
              },
            ].map(({ name, label,editable,defaultVal }, idx) => {
              return (
                <div
                  className=" flex flex-col md:flex-row justify-between gap-4 mb-4"
                  key={idx}
                >
                  <Typography
                    variant="textFieldLabel"
                    className="mt-2 text-bold min-w-[10rem]"
                  >
                    {label}
                  </Typography>

                  <Input
                    variant="form"
                    size="compact"
                    type="number"
                    className=""
                    isDisabled={!editable}
                    defaultValue={defaultVal}
                    
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16 p-5",
                    }}
                  />
                </div>
              );
            })}
            <div>{messageTop}</div>
            <div className="flex flex-col  flex-wrap  ">
              {/* <div className="min-w-[10rem]"></div> */}
              {messageMid}
              <Button
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm 2xl:text-lg w-full py-6 tracking-wide  xl:px-8 2xl:px-6 rounded-xl mt-5"
                type="submit"
                isLoading={false}
                onClick={onCallback}
              >
                {btnLabel}
              </Button>
            </div>
            <div className="mt-4">{messageBottom}</div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
