import { Card, Image } from "@nextui-org/react";
import { TicketIcon } from "../assets/ticket.icon";

interface CardProp {
  card: any;
}

const ContactUsCard = ({ card }: CardProp) => {
  const { cardNumber, title, subtitle, icon, extra } = card;
  return (
    <Card className="cursor-default	 relative items-center px-4 py-8 text-center bg-gradient-to-b from-[#018ffd] to-[#070c84] text-white gap-4  h-[350px] w-full ">
      <h1 className="text-2xl leading-10">
        {cardNumber}
        <br /> {title}
      </h1>
      <p className="text-left lg:text-justify font-[Metropolis] font-[500]">
        {subtitle}
      </p>
      <h2> {extra}</h2>
      {icon}
    </Card>
  );
};

export default ContactUsCard;
