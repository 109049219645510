import { Suspense } from "react";
import { LinearDeterminateComponent } from "./linear-determinate.loader";

export const Loadable =
  (Component: React.ElementType) => (props: JSX.IntrinsicAttributes) => {
    return (
      <Suspense fallback={<LinearDeterminateComponent />}>
        <Component {...props} />
      </Suspense>
    );
  };
