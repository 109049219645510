import {
  Input as NextInput,
  InputProps,
  extendVariants,
  VariantProps,
} from "@nextui-org/react";
import {  forwardRef } from "react";

const CustomInputVariants = extendVariants(NextInput, {
  variants: {
    variant: {
      custom: {
        // base: ["br500"],
        // mainWrapper: ["br500"],
        inputWrapper: [
          // "bg-blue-100",
          "border",
          "border-blue-400",
          // "shadow",
          "transition-colors",
          // "focus-within:bg-blue-100",
          "data-[hover=true]:border-blue-600",
          // "data-[hover=true]:bg-blue-100",
          "group-data-[focus=true]:border-blue-600",
        ],
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:px-0",
        ],
      },
      readonly: {
        inputWrapper: [
          "border",
          "border-none",
          "bg-transparent",
          "shadow-none",
        ],
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:px-0",
          "cursor-not-allowed",
          "text-right",
        ],
      },

      turbo: {
        innerWrapper: ["pr-5"],
        inputWrapper: [
          "border",
          "border-double",
          "border-transparent",
          "bg-origin-border",
          "[background-clip:padding-box,border-box]",
          "[background-image:linear-gradient(to_right,#018ffd,#070c84),linear-gradient(to_right,white,white)]",
          "transition-colors",
          "data-[hover=true]:border-blue-600",
          // "data-[hover=true]:bg-blue-100",
          "group-data-[focus=true]:border-blue-600",
        ],
        input: [
          "text-white",
          "placeholder:text-white",
          "placeholder:px-0",
          "placeholder:text-xs",
        ],
      },
      gradient: {
        inputWrapper: [
          "border",
          "border-double",
          "border-transparent",
          "bg-origin-border",
          "[background-clip:padding-box,border-box]",
          "[background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)]",
          "transition-colors",
          "data-[hover=true]:border-blue-600",
          // "data-[hover=true]:bg-blue-100",
          "group-data-[focus=true]:border-blue-600",
        ],
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:px-0",
        ],
      },
      ticket: {
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:font-[500]",
          "placeholder:font-sans",
          "placeholder:px-0",
          "h-full",
          "data-[has-start-content=true]:ps-9",
        ],
        inputWrapper: [
          "px-0",
          "relative",
          "w-[90%]",
          "border",
          "border-double",
          "border-transparent",
          "bg-origin-border",
          "[background-clip:padding-box,border-box]",
          "[background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)]",
          "transition-colors",
        ],
      },
      form: {
        innerWrapper: ["px-2"],
        inputWrapper: [
          "px-0",
          "relative",
          "w-full",
          "w-full",
          "border",
          "border-double",
          "border-transparent",
          "bg-origin-border",
          "[background-clip:padding-box,border-box]",
          "[background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)]",
          "transition-colors",
          // "shadow",
          // "focus-within:bg-blue-100",
          // "data-[hover=true]:border-blue-600",
          // "data-[hover=true]:bg-blue-100",
          // "group-data-[focus=true]:border-blue-600",
        ],
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:font-[500]",
          "placeholder:font-sans",
          "placeholder:px-0",
          "h-full",
          "data-[has-start-content=true]:ps-9",
        ],

        label: ["text-black/50"],
      },
      calculator: {
        innerWrapper: ["px-3"],
        inputWrapper: [
          "pl-0",
          "relative",
          "border",
          "border-double",
          "border-transparent",
          "bg-origin-border",
          "[background-clip:padding-box,border-box]",
          "[background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)]",
          "transition-colors",
        ],
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:font-[500]",
          "placeholder:font-sans",
          "placeholder:px-0",
          "h-full",
          "data-[has-start-content=true]:ps-9",
        ],

        label: ["text-black/50"],
      },
    },

    color: {
      disabled: {
        inputWrapper: "border-gray-500",
        innerWrapper: "bg-[#F1F1F180]",
      },
      stone: {
        inputWrapper: [
          "bg-zinc-100",
          "border",
          "shadow",
          "transition-colors",
          "focus-within:bg-zinc-100",
          "data-[hover=true]:border-zinc-600",
          "data-[hover=true]:bg-zinc-100",
          "group-data-[focus=true]:border-zinc-600",
        ],
        input: ["text-zinc-800", "placeholder:text-zinc-600"],
      },
    },
    size: {
      compact: {
        inputWrapper: "h-unit-11 min-h-unit-11",
        input: "text-tiny px-1",
      },
      xs: {
        inputWrapper: "h-unit-6 min-h-unit-6 px-1",
        input: "text-tiny",
      },
      md: {
        inputWrapper: "h-unit-10 min-h-unit-10",
        input: "text-small",
      },
      xl: {
        inputWrapper: "h-unit-16 min-h-unit-16",
        input: "text-medium",
      },
    },
    radius: {
      base: {
        inputWrapper: "rounded-xl",
        input: "rounded-xl",
      },
      xs: {
        inputWrapper: "rounded",
      },
      sm: {
        inputWrapper: "rounded-[4px]",
      },
      xl: {
        inputWrapper: "rounded-3xl",
        input: "rounded-3xl",
      },
    },
    textSize: {
      base: {
        input: "text-base",
      },
    },
    removeLabel: {
      true: {
        label: "hidden",
      },
      false: {},
    },
  },
  defaultVariants: {
    variant: "bordered",
    textSize: "base",
    removeLabel: false,
  },
});

type InputVariantProps = VariantProps<typeof CustomInputVariants>;
// type InputSlots = keyof ReturnType<typeof CustomInputVariants>;

type MergedProps = InputVariantProps &
  Omit<InputProps, keyof InputVariantProps> & { initialValue?: string | number};

const Input = forwardRef<HTMLInputElement,MergedProps>(function Input(
  { variant = "custom", ...otherProps },
  ref
) {
  return <CustomInputVariants  ref={ref} variant={variant} {...otherProps} />;
});

export default Input;
