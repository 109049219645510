import React, { useState } from "react";
import backgroundImage from "../assets/sell-quote-bg.svg";
import { Button } from "@nextui-org/react";
import Input from "components/ui/input";
import { useDispatch } from "react-redux";
import { changeCarForShipping } from "modules/dealer/store/shipping";
import { useNavigate } from "react-router-dom";
import { getUserId, isThisDealer } from "utils/get-user-id";
import { Typography } from "components";

interface Props {
  onClose: () => void;
  vehicle?: any;
}
export default function OffScreenPopup(props: Props) {
  const { yearMakeModel, vin, listingId, year, make, model, userId } =
    props.vehicle;
    
  const [activeStep, setActiveStep] = useState<string | null>(null);
  const [counterOffer, setCounterOffer] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isThisBuyer: boolean = getUserId().toString() === String(userId);
  const navigateToSteps = () => {
    const name = yearMakeModel;
    dispatch(changeCarForShipping({ name, vin, listingId, year, make, model }));
    // if(isThisDealer()){
    navigate(
      `${
        isThisDealer() ? "/dealer/manage-inventory/" : "/user/garage/"
      }vehicle-transaction-type/${vin}/${listingId}`
    );
    // }
    props.onClose();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <div className="text-center pt-[70px] pb-[30px]">
        {activeStep === null && (
          <div>
            <div className="text-[#070C84] text-2xl font-bold">
              <div>{isThisBuyer ?"You have" :"User has"} Offered ${props?.vehicle?.price || "N/A"}</div>
            </div>
            <div className="text-[#070C84] text-2xl font-bold">
              <div>Plus tax and Fees</div>
            </div>
            <div className="flex flex-col items-center justify-center mt-7">
              {/* {!isThisBuyer &&  */}
              <Button
                className="col-span-4 md:col-span-2 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-1/2 py-7 racking-wide rounded-xl mt-7"
                type="submit"
                onClick={() => setActiveStep("AcceptOffer")}
              >
                Accept Offer
              </Button>
               {/* } */}
              <Input
                variant="custom"
                className="w-1/2 mt-7"
                classNames={{
                  inputWrapper: counterOffer === "" ? "border-red-500" : "",
                }}
                placeholder="Enter your offer"
                onChange={(e) => setCounterOffer(e.currentTarget.value)}
                onKeyPress={(event: any) => {}}
              />
              <Button
                className="col-span-4 md:col-span-2 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-1/2 py-7 racking-wide rounded-xl mt-7"
                type="submit"
                onClick={() => {
                  if (counterOffer === null) {
                    setCounterOffer("");
                  } else {
                    setActiveStep("CounterOffer");
                  }
                }}
              >
                Counter Offer
              </Button>
            </div>
            <div className="text-[#070C84] text-lg font-semibold mt-32 flex gap-10 items-center justify-center cursor-pointer">
              {!isThisBuyer && (
                <>
                  <p onClick={() => setActiveStep("OfferRejected")}>
                    Reject Offer
                  </p>
                  <p onClick={() => setActiveStep("BlockUser")}>Block User</p>
                </>
              )}
            </div>
          </div>
        )}
        {activeStep === "AcceptOffer" && (
          <div className="h-[400px] flex flex-col items-center justify-center">
            <div className="text-[#070C84] text-4xl font-bold ">
              <div>Thank You!</div>
            </div>

            <div className="text-[#070C84] text-2xl font-bold mt-7">
              <div>A Purchase Transaction has been  </div>
              <div>opened with the Buyer. </div>
            </div>
            <div className="text-[#070C84] text-2xl font-bold mt-7">
              <div>You will be notified when the buyer</div>
              <div>approves the transaction</div>
            </div>
            <Button
              className="col-span-4 md:col-span-2 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-1/2 py-7 racking-wide rounded-xl mt-10"
              type="submit"
              onClick={() => navigateToSteps()}
            >
              Continue
            </Button>
          </div>
        )}
        {activeStep === "CounterOffer" && (
          <div className="h-[400px] flex flex-col items-center justify-center">
            <div className="text-[#070C84] text-4xl font-bold ">
              <div>Thank You!</div>
            </div>

            <div className="text-[#070C84] text-2xl font-bold mt-7">
              <div>Your Counter Offer has been sent to  </div>
              <div>the Buyer </div>
            </div>
            <div className="text-[#070C84] text-2xl font-bold mt-7">
              <div>You will be notified when the buyer </div>
              <div>accepts your offer</div>
            </div>
          </div>
        )}
        {activeStep === "BlockUser" && (
          <div className="h-[400px] flex flex-col items-center justify-center">
            <div className="text-[#070C84] text-4xl font-bold ">
              <div>Thank You!</div>
            </div>

            <div className="text-[#070C84] text-2xl font-bold mt-7">
              <div>Offer Rejected   </div>
              <div>User has been Blocked</div>
            </div>
          </div>
        )}
        {activeStep === "OfferRejected" && (
          <div className="h-[400px] flex flex-col items-center justify-center">
            <div className="text-[#070C84] text-4xl font-bold ">
              <div>Thank You!</div>
            </div>

            <div className="text-[#070C84] text-2xl font-bold mt-7">
              <div>Offer Rejected   </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
