import { z } from "zod";

export const UsedCarReviewValidationSchema = z.object({
  fname: z.string().min(1, {
    message: "First Name is required",
  }),
  lname: z.string().min(1, {
    message: "Last Name is required",
  }),
  email: z
    .string()
    .min(1, "Email is Required")
    .email({ message: "Please provide a valid Email" }),
  phone: z
    .string({ required_error: "Phone Number is Required" })
    .trim()
    .min(1, "Phone Number is Required")
    .refine(
      (value) =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
      "Please provide valid US Phone Number"
    ),
});

export const FmoMembershipValidationSchema = z.object({
  fname: z.string().min(1, {
    message: "First Name is required",
  }),
  lname: z.string().min(1, {
    message: "Last Name is required",
  }),
  email: z
    .string()
    .min(1, "Email is Required")
    .email({ message: "Please provide a valid Email" }),
  phone: z
    .string({ required_error: "Phone Number is Required" })
    .trim()
    .min(1, "Phone Number is Required")
    .refine(
      (value) =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
      "Please provide valid US Phone Number"
    ),
    
  address: z.string().min(1, {
    message: "Last Name is required",
  }),
});
