import { Card } from "@nextui-org/react";
import { Typography } from "components";

interface CardProp {
  card: { id: string; title: string; description?: string };
}

const LabelInfoCardGradiant = ({ card }: CardProp) => {
  const { title, description } = card;
  return (
    <Card className="cursor-default	 relative items-center px-4 py-6 text-center bg-gradient-to-b from-[#018ffd] to-[#070c84] text-white gap-4   w-full ">
      <div className="flex flex-col items-center p-4   relative">
        <Typography
          variant="custom"
          className="font-semibold text-white text-xl bg-clip-text"
        >
          {title}
        </Typography>
        <Typography
          variant="custom"
          className={`px-6  text-white font-light text-xl`}
        >
          {description}
        </Typography>
      </div>
    </Card>
  );
};

export default LabelInfoCardGradiant;
