import React, { useState, useEffect } from "react";
import Help from "./Help";
import Ticket from "./Ticket";
import { TurboChat } from "./TurboChat";
import SupportChat from "./SupportChat";
import "./chathelp.css";
import { DownArrowIcon } from "assets/svgs/icons/down-arrow-icon";
import { Button } from "@nextui-org/react";

const ChatHelp = ({
  setChatToggle,
  curr,
  chatToggle,
  openChat,
  setOpenChat,
  childRef,
}) => {
  const [currScreen, setCurrScreen] = useState(curr);
  const [isUnmounting, setIsUnmounting] = useState(false);

  useEffect(() => {
    // Add slide out animation when unmounting,func=(message,messages,setMessages,setturbochatLoading,mutate,setMessage)=>{}
    if (isUnmounting) {
      const timer = setTimeout(() => {
        setIsUnmounting(false);
        setOpenChat(false);
        setChatToggle(false);
      }, 100); // Adjust duration to match animation duration
      // Clean up timeout to avoid memory leaks
      return () => {
        clearTimeout(timer);
        setCurrScreen("");
      };
    }
  }, [isUnmounting]);

  const handleScreenChange = (screen) => {
    setIsUnmounting(true);
    // setCurrScreen(screen);
  };

  return (
    <>
      {/* <div className="fixed bottom-[1.66rem] right-32 z-[200]">
        <div
          className="rounded-full w-[56px] h-[56px] sm:flex justify-center items-center p-4 bg-gradient-to-r bg-black "
          onBlur={() => setOpenChat(!openChat)}
          onClick={() => {
            setChatToggle(false);
            setOpenChat(!openChat);
          }}
        >
          <div className="z-[1000]">
            {!openChat ? (
              <img
                onBlur={() => console.log()}
                className="rounded-full object-contain w-full h-full"
                src={process.env.PUBLIC_URL + "/assets/icons/turbo.svg"}
                alt=""
              />
            ) : (
              <div className="rounded-full flex justify-center items-center object-contain w-full h-full">
                <DownArrowIcon stopColor="#FFF" />
              </div>
            )}
          </div>
        </div>
      </div> */}
      {openChat && (
        <div
          className={`animate__animated max-[767px]:w-[100vw] z-[200] md:right-24 turbo-chat shadow-lg md:rounded-[25px] md:mt-40 ${
            !isUnmounting ? "animate__slideInRight" : "animate__slideOutRight"
          }`}
        >
          <TurboChat ref={childRef} handleScreenChange={handleScreenChange} />
        </div>
      )}
      {chatToggle && (
        <>
          {window.innerWidth <= 767 && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-[100]"></div>
          )}

          <div
            className={`mr-5 max-[767px]:w-[90vw] z-[200] chat-modal shadow-lg rounded-lg md:rounded-[25px] mt-16 md:mt-32 md:mr-[2rem] ${
              !isUnmounting ? "animate__slideInDown" : "animate__slideOutUp"
            }`}
          >
            <div className="absolute h-full w-full bg-black">
              <input onBlur={() => console.log("focus")} />
            </div>
            <div className="relative flex justify-center items-center ">
              <div className="max-[767px]:w-[90vw]">
                {curr === "help" ? (
                  <Help
                    setCurrScreen={setCurrScreen}
                    handleScreenChange={handleScreenChange}
                  />
                ) : curr === "case" ? (
                  <Ticket
                    setCurrScreen={setCurrScreen}
                    handleScreenChange={handleScreenChange}
                  />
                ) : (
                  <SupportChat
                    setCurrScreen={setCurrScreen}
                    handleScreenChange={handleScreenChange}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChatHelp;
