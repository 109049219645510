import React from "react";
import finishIcon from "../assets/quote-finish-icon.svg";
import { Image } from "@nextui-org/react";
import isSmallScreen from "../../utils/isSmallScreen";
import backgroundImage from "../assets/sell-quote-bg.svg";
import bgWhite from "../assets/white-bg.png";

export default function SuccessMessagePage() {
  return (
    <div className={`flex items-center justify-center `}>
      <div
        className={`relative flex justify-center items-center min-h-[600px] rounded-2xl w-full `}
        style={{
          backgroundImage: `url(${
            !isSmallScreen() ? backgroundImage : bgWhite
          })`,
          backgroundSize: "cover",
        }}
      >
        <div className="text-center ">
          <Image
            src={finishIcon}
            className="w-40 h-40 m-auto animate-bounce"
            removeWrapper
          />
          <div className="text-4xl mb-2">Thank You!</div>
          <div className="text-xl">
            Your submission is received and we will contact you soon.
          </div>
        </div>
      </div>
    </div>
  );
}
