import { saveCarResearchInfo } from "modules/dealer/store/manage-list";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ISubmitResearchVehicleFormPayload,
  useInitiateListingVINMut,
  usePostSubmitResearchVehicleForm,
  usePutDeleteResearchListing,
  useUpdateListingData,
} from "services";
import { getUserId } from "utils/get-user-id";

export const RowComponent = ({
  data,
  selectedStatus,
  refetchListingData,
  ContinueRefetch,
  continuePending,
}: {
  data?: ISubmitResearchVehicleFormPayload;
  selectedStatus: number;
  ContinueRefetch: () => void;

  refetchListingData: () => void;
  continuePending: boolean;
}) => {
  const navigate = useNavigate();

  const {
    mutateAsync: initListingMutate,
    isPending: initIsPending,
    error: errInitMes,
  } = useInitiateListingVINMut();
  const {
    mutateAsync: submitResearchMutate,
    isPending: initIsPendingTosubmitReseach,
    error: errResearch,
  } = usePostSubmitResearchVehicleForm();

  const {
    mutateAsync: mutateAsyncUpdateListingData,
    isPending: isPendingUpdateListing,
    error: updateListingError,
  } = useUpdateListingData(false, false);

  const {
    mutateAsync: mutateAsyncDeleteReasrce,
    isPending: isPendingDeleteReseach,
    // error: updateListingError,
  } = usePutDeleteResearchListing();
  const dispatch = useDispatch();
  // const { data: listingDetailsData } = useGetListingDetails(
  //   Number(data?.listingId) ?? 0
  // );
  // const { vin } = data;
  // listingDetailsData?.listing || {};
  const handleContinue = async (data?: ISubmitResearchVehicleFormPayload) => {
    try {
      if (data?.vin) {
        let responeId = data.listingId;
        if (!data.listingId) {
          responeId = await initListingMutate({
            vinNumber: data?.vin || "",
            mileage: String(data.mileage || 0),
            userId: getUserId(),
          });
        }
        const payload = {
          ...data,
          listingId: responeId,
          email: data.email || "",
          linkToListing: data.linkToListing || "",
        };
        await submitResearchMutate(payload);
        dispatch(saveCarResearchInfo(payload));

        navigate(
          `/dealer/start-car-research/${data?.vin || 0}/${
            data?.mileage || 0
          }/${responeId}`
        );
      } else {
        if (data) {
          await submitResearchMutate({
            ...data,
            email: data?.email || "",
            linkToListing: data?.linkToListing || "",
          });
          dispatch(saveCarResearchInfo(data));
        }

        navigate(`/dealer/enter-vin-only`, {
          state: data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = async (data?: ISubmitResearchVehicleFormPayload) => {
    try {
      if (data?.listingId) {
        const userId = getUserId();

        const formDataPayload = new FormData();
        formDataPayload.append("listingInput.userId", userId?.toString());
        formDataPayload.append(
          "listingInput.listingId",
          data?.listingId.toString()
        );
        formDataPayload.append("listingInput.tenantId", userId?.toString());
        formDataPayload.append("listingInput.description", "{}");
        formDataPayload.append("listingInput.name", data?.name || "-");
        formDataPayload.append("listingInput.make", data?.name || "-");
        formDataPayload.append("listingInput.model", "-");
        formDataPayload.append("listingInput.trim", "-");
        formDataPayload.append("listingInput.year", "-");
        formDataPayload.append("listingInput.vin", data.vin || "-");
        formDataPayload.append(
          "listingInput.licensePlate",
          data?.phoneNumber || ""
        );
        formDataPayload.append("listingInput.price", "0");
        formDataPayload.append("listingInput.purchasePrice", "0");
        formDataPayload.append("listingInput.repairCost", "0");
        formDataPayload.append("listingInput.reconditioningCost", "0");
        formDataPayload.append("listingInput.actualSalePrice", "0");
        formDataPayload.append("listingInput.status", "3");

        formDataPayload.append("listingInput.mileage", data.mileage || "0");

        // Add auctionDetails properties
        formDataPayload.append(
          "auctionDetails.listingId",
          data?.listingId.toString()
        );
        formDataPayload.append("auctionDetails.listingPrice", "0");
        formDataPayload.append("auctionDetails.enableAuction", "false");
        formDataPayload.append("auctionDetails.startingBid", "0");
        formDataPayload.append("auctionDetails.reservePrice", "0");
        formDataPayload.append(
          "auctionDetails.biddingEndDate",
          "2025-02-02T00:00:00"
        );

        const res = await mutateAsyncUpdateListingData(formDataPayload);
        // console.log("filesSignalLintingImg.value", filesSignalLintingImg.value);
        // filesSignalLintingImg.value.forEach((flx, index) => {
        //   formDataPayload.append(`uploadfiles`, flx.file, flx.part + index);
        // });
        ContinueRefetch();
        refetchListingData();
      } else {
        if (data?.id) {
          await mutateAsyncDeleteReasrce(data.id);

          ContinueRefetch();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <tr
      className="bg-white text-black border-b text-base border-[#C9C9C9] relative md:text-xl font-semibold"
      id={`${data?.mileage}${data?.vin}${data?.name}`}
    >
      <th
        scope="row"
        className="px-6 py-4 text-center border-l border-[#C9C9C9] text-base  whitespace-nowrap"
      >
        {data?.vin || "-"}
      </th>
      <td className="px-6 py-4 text-center border-l text-base border-[#C9C9C9]">
        {data?.mileage || "-"}
      </td>
      <td className="px-6 py-4 text-center border-l  break-words text-base border-[#C9C9C9]">
        {data?.name || "-"}
      </td>
      <td className="overflow-hidden max-w-64 text-base  h-16 text-center border-l border-[#C9C9C9] cursor-pointer ">
        {data?.phoneNumber || "-"}
      </td>

      <td className="  text-base  h-16 text-center border-l border-[#C9C9C9] cursor-pointer ">
        {/* <Dropdown
          portalContainer={
            document.getElementById(
              `${data?.mileage}${data?.vin}${data?.name}`
            ) || document.body
          }
          style={{
            position: "absolute",
            zIndex: 9999, // Adjust as needed
          }}
          size="sm"
          placement="bottom"
        >
          <DropdownTrigger>
            <Button
              className="bg-transparent text-base"
              isLoading={
                initIsPendingTosubmitReseach ||
                initIsPending ||
                isPendingUpdateListing ||
                continuePending ||
                isPendingDeleteReseach
              }
            >
              {"View"}
            </Button>
          </DropdownTrigger>
          <DropdownMenu  aria-label="Static Actions">
            <DropdownItem
              key="view-research"
              className="text-center"
              onClick={() => handleContinue(data)}
            >
              {"View"}
            </DropdownItem>
            <DropdownItem
              className="text-center"
              key="cancel-research"
              onClick={() => handleCancel(data)}
            >
              {"Cancel"}
            </DropdownItem>

            <DropdownItem
              className="text-center"
              key="repot-research"
              isReadOnly={!data?.vin}
              onClick={() => {
                window.open(
                  `https://mmr.manheim.com/ui-mmr/?country=US&vin=${data?.vin}&mid=201401746560735&excludeBuild=true`,
                  "_blank"
                );
              }}
            >
            {data?.vin ? "Manheim Market Report" : ""}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown> */}

        <div className="flex  px-2 w-full min-w-[10rem] justify-around ">
          <p
            onClick={() => handleContinue(data)}
            className="hover:text-blue-500"
          >
            {initIsPendingTosubmitReseach || initIsPending
              ? "Loading..."
              : "View"}
          </p>

          <p onClick={() => handleCancel(data)} className="hover:text-red-500">
            {isPendingUpdateListing || continuePending || isPendingDeleteReseach
              ? "Loading..."
              : "Cancel"}
          </p>
        </div>
      </td>
    </tr>
  );
};
