import { Button, Card, CardBody } from "@nextui-org/react";
import { Typography } from "components";
import { moneyWithDecimal } from "utils";

interface PayNowProps {
  title: string;
  price: number;
  prevPrice?: number;
  isLoading: boolean
  onPay: () => void;
}
export const PayNow: React.FC<PayNowProps> = ({
  title,
  price,
  prevPrice,
  onPay,
  isLoading
}) => {
  return (
    <div className=" w-full h-full flex justify-center px-10 md:px-20 mb-10 mt-6">
      <Card className="w-full min-h-[220px] h-full items-center bg-gradient-to-b from-[#018FFD] to-[#070C84] flex justify-center">
        <CardBody className="flex justify-center mb-4 items-center">
          <Typography
            variant="custom"
            className="text-center text-white font-[600] text-xl mt-4"
          >
            {title}
          </Typography>
          <Typography
            variant="custom"
            className="text-center text-white font-[600] text-2xl mt-2"
          >
            {prevPrice && (
              <span>
                (
                <span className="line-through ">
                  ${moneyWithDecimal(prevPrice)}
                </span>
                )
              </span>
            )}{" "}
            ${moneyWithDecimal(price)}
          </Typography>
          <Button
            className="my-2/12 w-28 mt-3 bg-white"
            size="md"
            onClick={onPay}
            isLoading={isLoading}
          >
            <Typography
              variant="custom"
              className="text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text font-[600] text-lg"
            >
              Pay Now
            </Typography>
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};
