import { ButtonProps } from "antd";
import React from "react";

export default function Button(props: ButtonProps) {
  return (
    <div>
      <button
        {...props}
        data-modal-hide="default-modal"
        type="button"
        className={`${props.className} transform hover:scale-105 transition duration-100 ease-in-out`}
      >
        {props.name}
      </button>
    </div>
  );
}
