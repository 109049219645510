import { env } from "config/env";
import ky from "ky";
import { localStorageFn } from "./localstorage";

/**
 * This instance of `ky` is for making general request with a minimal config
 */
const api = ky.create({
  prefixUrl: env.BASE_API_URL,
  hooks: {
    beforeError: [
      async (error) => {
        const { response } = error;
        const res = await response.json();
        error.message = res.message;
        return error;
      },
    ],
  },
  retry: { limit: 0 },
});

/**
 * This instance of `ky` is for making authenticated requests as it already includes the access token and refresh token handled.
 */
const authApi = api.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorageFn.getItem("access_token");
        request.headers.set("Authorization", `Bearer ${token}`);
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 403) {
          const token = await ky(`User/refresh`, {
            prefixUrl: env.BASE_API_URL,
          }).text();

          localStorageFn.setItem("refresh_token", token);

          request.headers.set("Authorization", `Bearer ${token}`);

          return ky(request);
        }
      },
    ],
  },
});

export { api, authApi };
