import { SVGProps } from "react";

export const StarIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3978 22.4431L12.9145 25.7535L14.3582 19.5119L9.51654 15.3118L15.904 14.7723L18.3978 8.8806L20.8915 14.7723L27.279 15.3118L22.4374 19.5119L23.8811 25.7535M32.9811 13.4743L22.4957 12.5848L18.3978 2.91602L14.2999 12.5848L3.81445 13.4743L11.7624 20.3723L9.38529 30.6243L18.3978 25.1848L27.4103 30.6243L25.0186 20.3723L32.9811 13.4743Z"
      fill="white"
    />
  </svg>
);
