import { FunctionComponent } from "react";
import FMO_logo from "./assets/images/FMO_logo.png";

interface Props {
    name: string;
  }
  
const MainFrame: React.FC<Props> = ({ name }) => {
  return (
    <div className="w-[600px] h-[580px] gap-[50px_0px] max-[540px]:gap-[80px_0px] rounded-3xl bg-white [backdrop-filter:blur(200px)] box-border flex flex-col items-center justify-start py-12 px-5 max-w-full z-[3] text-left border-[2px] border-solid border-indigo-700" style={{fontFamily:'Metropolis'}}>
      <div className="w-[500px] h-[600px] relative rounded-31xl bg-white [backdrop-filter:blur(200px)] box-border hidden max-w-full border-[3px] border-solid border-dodgerblue-100" />
      <h1 className="text-sm -mt-8 max-[540px]:-mt-10 text-center text-gray-500">Welcome to</h1>
      <header className="w-[550px] flex flex-col items-center justify-start gap-[62px_0px] max-w-full -mt-4 max-[540px]:-mt-10">
       
        <img
          className="w-[80px] h-[40px] relative overflow-hidden shrink-0 z-[4]"
          loading="eager"
          alt=""
          src={FMO_logo}
        />
        <div className="self-stretch flex flex-col items-center justify-start gap-[31px_0px] max-w-full shrink-0 -mt-8 max-[540px]:-mt-12">
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-[21px] box-border max-w-full shrink-0">
            <div className="h-[63px] relative inline-block shrink-0 max-w-full z-[4]">
              <p className="m-0 mb-0 text-sm font-semibold">Hi {name},</p>
              <p className="m-0 text-sm leading-[130.5%] font-medium text-gray-500">
                Here are your password reset instructions.
              </p>
            </div>
          </div>
          <div className="self-stretch h-0.5 relative box-border z-[4] border-t-[2px] -mt-8 border-solid border-blue-200" />
          <div className="h-[40px] relative text-xs leading-[16px] font-semibold text-center inline-block max-w-full z-[4]">
            <p className="m-0">
              A request to reset your findmyoptions password has been made. If
              you did not
            </p>
            <p className="m-0">
              make this request,simply ignore this email. If you did make this
              request,
            </p>
            <p className="m-0 whitespace-pre-wrap">{`please reset your password:  `}</p>
          </div>
        </div>
      </header>
      <div className="w-[550px] flex flex-col items-center justify-start pt-0 px-0 pb-[62px] box-border gap-[41px_0px] max-w-full mq450:gap-[41px_0px]">
        <div className="flex flex-row items-start justify-start py-0 pr-0 box-border w-[140px]">
          <button className="cursor-pointer [border:none] pt-6 px-6 pb-[25px] -mt-8 bg-[transparent] h-[46px] flex-1 rounded-[12px] [background:linear-gradient(95.72deg,_#018ffd,_#070c84)] flex flex-row items-center justify-center box-border w-[190px] whitespace-nowrap z-[4]">
            <div className="self-stretch relative text-sm font-semibold font-metropolis text-white m-auto mt-[-8%]">
              Reset Password
            </div>
          </button>
        </div>
        <div className="flex flex-col items-start justify-start gap-[29px_0px] max-w-full shrink-0">
          <div className="h-[63px] ml-4 -mt-8 relative font-semibold  z-[4] mq450:text-base">
            <p className="m-0 text-xs">Thank you,</p>
            <p className="m-0 text-xs">Team findmyoptions</p>
          </div>
          <div className="self-stretch h-10 mx-4 -my-4 relative leading-[23.57px] text-center inline-block z-[4] text-darkgray mq450:text-base">
            <span className="text-xs text-gray-500">
              If the button above does not work, try copying and pasting the URL
              into your browser. If you continue to have problems; please visit
            </span>
            <span className="font-semibold text-darkslategray">
              <span className="text-darkslategray">{` `}</span>
              <span className="text-darkgray">our</span>
              <span> Help Center</span>
            </span>
          </div>
        </div>
        <div className="self-stretch h-0.5 relative box-border z-[4] border-t-[2px] -mt-4 border-solid border-blue-200" />
      </div>
      <div className="h-5 relative font-semibold text-center inline-block z-[4] -mt-20 max-[540px]:-mt-24">
        Findmyoptions.com
      </div>
    </div>
  );
};

export default MainFrame;
