import * as React from "react";

import CloseIcon from "../CloseIcon";
import "./modal.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  headerTitle?: string;
  ContentComponent: React.ReactNode;
}

export const ResponsiveModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  headerTitle,
  ContentComponent,
}) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="modal-background z-50">
          <div className="modal-content ">
            <div className="  relative bg-white rounded-3xl pt-10 ">
              <button
                type="button"
                className="absolute top-4 md:top-10 right-4 md:right-10 hover:scale-125 w-4 h-4"
                onClick={closeModal}
                tabIndex={-1}
              >
                <CloseIcon />
              </button>
            </div>
            {/* {headerTitle && ( */}
                <div className="flex items-center h-10  justify-center text-center">
                  <h3 className="text-base md:text-xl font-semibold">{headerTitle}</h3>
                </div>
              {/* )} */}
            <div className="p-4 overflow-auto h-[calc(100%-100px)]">
              
              {ContentComponent}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
