import { Typography } from "components";

const ContactUsHeader = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 text-center">
      <Typography variant="pageTitle">Contact Us</Typography>
      <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
        Connecting With Us is Easy
      </h2>
      <p className="font-[Metropolis] font-[500] text-center">
        At findmyoptions.com, we are passionate about all things mechanical. We
        understand the unique bond between car enthusiasts and their vehicles,
        and our mission is to provide a platform that simplifies the way you
        buy, sell, and connect within the automotive community.
      </p>
    </div>
  );
};

export default ContactUsHeader;
