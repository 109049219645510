import { z } from "zod";

export const ManageListingValidationSchema = z.object({
  listingId: z.number()?.optional(),
  name: z.string()?.optional(),
  make: z.string()?.optional(),
  model: z.string()?.optional(),
  vin: z.string()?.optional(),
  licensePlate: z.string()?.optional(),
  price: z.string().min(1, {
    message: "Buy it now price is required",
  }),
  purchasePrice: z.number()?.optional(),
  repairCost: z.number()?.optional(),
  reconditioningCost: z.number()?.optional(),
  actualSalePrice: z.number()?.optional(),
  status: z.number()?.optional(),
  mileage: z.string().min(1, {
    message: "Mileage is required",
  }),
  userId: z.number()?.optional(),
  tenantId: z.number()?.optional(),
  description: z.string()?.optional(),
  // enableAuction: z.boolean()?.optional(),
  // startingBid: z.number()?.optional(),
  // reservePrice: z.number()?.optional(),
  // biddingEndDate: z.string()?.optional(),
  listingPrice: z.number()?.optional(),
  financingPrice: z.number()?.optional(),
  downPayment: z.number()?.optional(),
  interestRate: z.number()?.optional(),
  financeType: z.number()?.optional(),
  term: z.number()?.optional(),
  monthlyPayment: z.number()?.optional(),
});


export type IManageListingValidationSchema  = z.infer<typeof ManageListingValidationSchema >;