import { Typography } from "components";
import carImg from "./assets/car_war.png";
import { Warrentystroke } from "./assets/warrenty-stroke.svg";

interface MainSectionWarrentyProps { 
  handleClickQueotes:()=>void
}

export const MainSectionWarrenty: React.FC<MainSectionWarrentyProps> = ({handleClickQueotes}) => {
  function makePhoneCall() {
    const phoneNumber = '859-838-1752';
    const telUrl = 'tel:' + phoneNumber;
    window.open(telUrl);
  }
  return (
    <div className="flex flex-col items-center justify-center main-warrenty-page">
    <div className="relative grid grid-cols-1 xl:grid-cols-2 mb-20 md:mb-52" >
      <div className="relative flex justify-center w-full h-full items-center mb-10 p-0">
        <div className="main-side-img  relative px-4">
          <Warrentystroke className="mx-auto pb-6 sm:pb-0 md:mt-12 xl:mt-6 w-full z-20" />
        </div>
        <img
          src={carImg}
          className=" absolute -bottom-12 md:-bottom-48  xl:-bottom-16 2xl:-bottom-16 3xl:-bottom-44 4xl:-bottom-56"
          // className=" absolute -bottom-0 "
          alt="car"
        />
      </div>
      <div className="flex items-center flex-col gap-4  order-first xl:order-2 xl:mt-20 ">
        <Typography
          variant="custom"
          className="font-[Metropolis] text-4xl font-[900] md:text54xl xl:text-6xl text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text"
        >
          No.1
        </Typography>
        <Typography
          variant="custom"
          className="font-[Metropolis] text-3xl font-[700] md:text-4xl xl:text-5xl text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text text-nowrap"
        >
          Used Car Warranty
        </Typography>
        <Typography
          variant="custom"
          className="font-[Metropolis] text-3xl font-[700] md:text-4xl xl:text-5xl text-center bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text"
        >
          in America
        </Typography>
        <Typography variant="custom" className="text-[#555] font-[700] text-center text-xl">
          Drive with Confidence: Explore Our Used Car Service Contracts with
          Roadside Assistance included
        </Typography>

        <Typography
          variant="custom"
          className="text-[#070C84] relative h-[3.5rem] text-center border-2 rounded-lg border-[#070C84] px-4 py-2    hover:bg-gradient-to-r from-blue-500 to-indigo-900 hover:text-white "

          // className="text-[#070C84] text-3xl font-bold border-2 rounded-lg border-[#070C84] px-4  py-2 cursor-pointer"
        >
        <p
          className=" text-3xl cursor-pointer font-bold hover:text-[2rem] "
          onClick={makePhoneCall}
          // className="text-[#070C84] text-3xl font-bold border-2 rounded-lg border-[#070C84] px-4  py-2 cursor-pointer"
        >
          859-838-1752
        </p>
        
        </Typography>
        
        <Typography
          variant="custom"
          className="bg-gradient-to-r h-[3.5rem] font-bold  from-[#018ffd] to-[#070c84] text-white py-3  tracking-wide px-[4rem] rounded-xl "
        >
        <p
        
          className="text-lg cursor-pointer font-bold hover:text-[1.3rem] "

          onClick={handleClickQueotes}
        >
           Get a Quote?
        </p>
        
        </Typography>
        
      </div>
    </div>
    </div>
  );
};
