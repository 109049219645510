import React, { useState } from "react";
import editIcon from "./assets/editIcon.svg";
import { MdLockOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

const LoginCard: React.FC = () => {
  const [editDetails, setEditDetails] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!username || !password) {
      setFormError("Username and password are required");
    } else {
      setFormError("");
      setEditDetails(false);
      return;
    }
    // Continue with form submission logic...
  };

  return (
    <div className="bg-white rounded-3xl m-2 p-8 shadow-lg w-1/2 max-h-1/2 ">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold mb-6 text-[#070C84]">
          Login Details
        </h1>
        {!editDetails && (
          <img
            onClick={() => setEditDetails(true)}
            src={editIcon}
            alt="Mastercard logo"
            className="h-8 w-12"
          />
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="m-10">
          <div className="flex flex-row mb-4 ml-20">
            <div className="absolute ml-4 mt-5 text-[20px]">
            <FaRegEdit />
            </div>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={!editDetails}
              className="shadow border-[#018ffd] border-2 rounded-3xl w-4/5 py-4 pl-10 text-[#313131] mb-8 "
              name="username"
              type="text"
              placeholder="Username"
            />
          </div>
          <div className="flex flex-row mb-6 ml-20">
          <div className="absolute ml-4 mt-5 text-[22px]">
            <MdLockOutline />
            </div>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={!editDetails}
              className="shadow border-[#018ffd] border-2 rounded-3xl w-4/5 py-4 pl-10 text-[#313131] "
              name="password"
              type="password"
              placeholder="Password"
            />
          </div>
          {formError && <div className="text-red-500 text-sm">{formError}</div>}
          {editDetails && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#018FFD] to-[#070C84] text-white rounded-lg p-2 w-24"
              >
                Save
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginCard;
