import { Button } from "@nextui-org/react";
import BhphfContractHalfSixSalesDEtails from "./contract-6half-bhphf";

interface Props {
  handleContinue: () => void;
}
export default function BhphfContractSixSales({ handleContinue }: Props) {
  return (
    <div className="p-2 md:p-8">
      <h2 className="mt-4 font-semibold text-center">
        PRE-COMPUTED INTEREST VEHICLE SALES CONTRACT AND SECURITY AGREEMENT
      </h2>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full border-collapse border border-gray-400">
          <tbody>
            <tr>
              <td className="border border-gray-400 p-2">
                Dealer/Creditor Name
              </td>
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 ">
                Dealer License Number
              </td>
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 w-1/2 p-2">
                Contract Number
              </td>
              <td className="border border-gray-400 w-1/2 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 ">Stock Number </td>
              <td className="border border-gray-400 p-2" />
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="min-w-full border-collapse border border-gray-400">
          <tbody>
            <tr>
              <td className="border border-gray-400 w-1/4 p-2">Buyer1 Name</td>
              <td className="border border-gray-400 p-2 w-1/4" />
              <td className="border border-gray-400 p-2 w-1/4">Buyer2 Name</td>
              <td className="border border-gray-400 p-2 w-1/4" />
            </tr>
            <tr>
              <td className="border border-gray-400 w-1/4 p-2">Address</td>
              <td className="border border-gray-400 p-2 w-1/4" />
              <td className="border border-gray-400 p-2 w-1/4">Address</td>
              <td className="border border-gray-400 p-2 w-1/4" />
            </tr>
            <tr>
              <td className="border border-gray-400 w-1/4 p-2">
                City, State, Zip
              </td>
              <td className="border border-gray-400 p-2 w-1/4" />
              <td className="border border-gray-400 p-2 w-1/4">
                City, State, Zip
              </td>
              <td className="border border-gray-400 p-2 w-1/4" />
            </tr>
            <tr>
              <td className="border border-gray-400 w-1/4 p-2">Email</td>
              <td className="border border-gray-400 p-2 w-1/4" />
              <td className="border border-gray-400 p-2 w-1/4">Email</td>
              <td className="border border-gray-400 p-2 w-1/4" />
            </tr>
            <tr>
              <td className="border border-gray-400 w-1/4 p-2">Phone</td>
              <td className="border border-gray-400 p-2 w-1/4" />
              <td className="border border-gray-400 p-2 w-1/4">Phone</td>
              <td className="border border-gray-400 p-2 w-1/4" />
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto mt-4">
        <div className="w-full flex  flex-grow  ">
          <div className=" text-center font-bold border-red-500 border-2 p-2 ">
            NEW/USED
            <br />
            <span className="font-bold text-red-500 "> USED</span>
          </div>

          <div>
            <div className="border border-gray-400  p-2">Year</div>

            <div className="border border-gray-400  p-6"></div>
          </div>

          <div>
            <div className="border border-gray-400  p-2">Make/Model</div>

            <div className="border border-gray-400  p-6"></div>
          </div>

          <div>
            <div className="border border-gray-400  p-2">Odometer Reading</div>

            <div className="border border-gray-400  p-6"></div>
          </div>

          <div>
            <div className="border border-gray-400  p-2">
              Vehicle Identification Number
            </div>

            <div className="border border-gray-400  p-6"></div>
          </div>

          <div>
            <div className="border border-gray-400  p-2">
              License Plate Number
            </div>

            <div className="border border-gray-400  p-6"></div>
          </div>

          <div>
            <div className="border border-gray-400  p-2">Color</div>

            <div className="border border-gray-400  p-6"></div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-4">
        <h2 className="mt-4 font-semibold my-4 text-center">
          FEDERAL TRUTH IN LENDING DISCLOSURES
        </h2>
        <table className="min-w-full border-collapse border border-gray-400">
          <tbody>
            <tr>
              <td className="border border-gray-400 p-2 ">
                <p className="font-semibold p-0">Annual Percentage Rate</p>

                <span className="text-sm">
                  The cost of your credit as a yearly rate
                </span>
              </td>

              <td className="border border-gray-400 p-2 ">
                <p className="font-semibold p-0">Finance Charge</p>

                <span className="text-sm">
                  The dollar amount the credit will cost you{" "}
                </span>
              </td>

              <td className="border border-gray-400 p-2 ">
                <p className="font-semibold p-0">Amount Financed</p>

                <span className="text-sm">
                  The amount of credit provided to you on your behalf{" "}
                </span>
              </td>

              <td className="border border-gray-400 p-2 ">
                <p className="font-semibold p-0">Total of Payments</p>

                <span className="text-sm">
                  The amount you will have paid after you have made all payments
                  as scheduled{" "}
                </span>
              </td>

              <td className="border border-gray-400 p-2 ">
                <p className="font-semibold p-0">Total Sale Price</p>
                <p className="text-sm">
                  The total cost of your purchase on credit, including your
                  downpayment of
                </p>
                $
                <span className="border-gray-500 border-b px-8 sm:px-12"></span>
              </td>
            </tr>

            <tr>
              <td className="border border-gray-400 p-6 "> </td>
              <td className="border border-gray-400 p-6" />
              <td className="border border-gray-400 p-6" />
              <td className="border border-gray-400 p-6" />
              <td className="border border-gray-400 p-6" />
            </tr>
          </tbody>
        </table>
      </div>
      <div className="my-6">
        <h2 className="font-semibold ">
          Payment Schedule: SEE BELOW AMORTIZATION TABLE
        </h2>
        <div className="my-4">
          <p>
            <span className="font-semibold">Security.</span>
            You are giving us a security interest in the Property purchased.
          </p>
          <p>
            <span className="font-semibold">Late Charge.</span>
            If all or any portion of a payment is not paid within 10 days of its
            due date, you will be charged a late charge of 5% of the unpaid
            amount of the payment due.
          </p>
          <p>
            <span className="font-semibold">Prepayment.</span>
            If you pay off this Contract early, you will not have to pay a
            penalty.Contract Provisions. You can see the terms of this Contract
            for any additional information about nonpayment, default, any
            required repayment before the scheduled date, and prepayment refunds
            and penalties.
          </p>
        </div>
      </div>

      <div className="my-12">
        <h1 className="text-center font-bold text-lg">
          PRE-COMPUTED INTEREST VEHICLE SALES CONTRACT AND SECURITY AGREEMENT
        </h1>
        <h2 className="mt-4 font-semibold">Itemization of Amount Financed</h2>
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Fee Type</th>
                <th className="border border-gray-400 p-2">Description</th>
                <th className="border border-gray-400 p-2">Subtotals</th>
                <th className="border border-gray-400 p-2">Totals</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-400 p-2">
                  1. Total Cash Price
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 pl-4">
                  A. Cash Price Motor Vehicle and Accessories
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">
                  1. Cash Price Vehicle
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">
                  2. Cash Price Accessories
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">3. Other</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <EmpltyRowCreate arr={["B1"]} />

              <tr>
                <td className="border border-gray-400 p-2">
                  B. Document Processing Charge
                </td>
                <td className="border border-gray-400 p-2">
                  (Not a Governmental Fee)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  C. Emissions Testing Charge
                </td>
                <td className="border border-gray-400 p-2">
                  (Not a Governmental Fee)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  D. (Optional) Theft Deterrent Device(s)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <EmpltyRowCreate arr={["E1", "E2", "E3"]} />
              <tr>
                <td className="border border-gray-400 p-2">
                  E. (Optional) Surface Protection Product(s)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <EmpltyRowCreate arr={["F1", "F2", "F3"]} />

              <tr>
                <td className="border border-gray-400 p-2">
                  F. EV Charging Station
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  G. Sales Tax (On Taxable Items)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  H. Electronic Vehicle Registration or Transfer Charge
                </td>
                <td className="border border-gray-400 p-2">
                  (Not a Governmental Fee)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  I. (Optional) Service Contract (To Whom Paid)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  J. Prior Credit Lease Balance (e) Paid by seller to (see down
                  payment calculation)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-4">Vehicle 1</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-4">Vehicle 2</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  K. (Optional) Guaranteed Asset Protection Waiver (GAP)
                </td>
                <td className="border border-gray-400 p-2">
                  A separate GAP agreement must be signed by Buyer if applicable
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  L. Used Vehicle Contract Cancellation
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">M. Other (For)</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <EmpltyRowCreate arr={["m1"]} />
            </tbody>
          </table>
        </div>

        <div className="overflow-x-auto mt-4">
          <table className="min-w-full border-collapse border border-gray-400">
            <tbody>
              <tr>
                <td className="border border-gray-400 p-2">
                  1. Total Cash Price (A through M)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-4" />
                <td className="border border-gray-400 p-4" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  2. Amounts Paid to Public Officials
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">
                  a. License Fees
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">
                  b. Registration/Transfer/Titling Fees
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">
                  c. Tire Fees
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-8">d. Other</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2  pl-4">
                  Total Official Fees (a through d)
                </td>
                <td className="border border-gray-400 p-2 " />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2">
                  3. Amounts Paid to Insurance Companies
                </td>
                <td className="border border-gray-400 p-2"></td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2"></td>
                <td className="border border-gray-400 p-2">
                  Procured by Dealer Excluding GAP
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2">
                  4. State Emissions Certification Fee
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  State Emissions Exemption Fee{" "}
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2">
                  5. Subtotal (1 through 4)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2">
                  6. Total Down Payment
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  A. Trade In Values
                </td>
                <td className="border border-gray-400 p-2"></td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2">Vehicle 1 Vin</td>
                <td className="border border-gray-400 p-2">yr,mk,md,odo</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2">Vehicle 2 Vin</td>
                <td className="border border-gray-400 p-2">yr,mk,md,odo</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  B. Less Prior Credit or Lease Balance (e)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-4">Vehicle 1</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-4">Vehicle 2</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  C. Net Trade in (A less B)
                </td>
                <td className="border border-gray-400 p-2">
                  (Indicate if Negative Number)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-4">Vehicle 1</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2 pl-4">Vehicle 2</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  D. Deferred Down Payment
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  E. Manufacturers Rebate
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 pl-8">F. Other</td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 pl-8">
                  G. Remaining Cash Down Payment
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 font-bold pl-8">
                  Total Down Payment (6C through G)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 "></td>
                <td className="border border-gray-400 p-2">
                  (If negative, enter zero on line 6 then enter the amount less
                  than zero as a positive number on line 1J above)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>

              <tr>
                <td className="border border-gray-400 font-bold p-2 ">
                  7. Amount Financed (5 less 6)
                </td>
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
                <td className="border border-gray-400 p-2" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-56">
        <h1 className="text-center my-4 font-bold text-lg">
          PRE-COMPUTED INTEREST VEHICLE SALES CONTRACT AND SECURITY AGREEMENT
        </h1>
        <h1 className="text-center font-bold my-4 text-lg">
          AMORTIZATION TABLE
        </h1>
        <div>
          <p className="my-1">
            One Payment of
            <span className="border-gray-500 border-b px-12 sm:px-20"></span>
            Payments of
            <span className="border-gray-500 border-b px-12 sm:px-20"></span>
          </p>
          <p>
            <span className="border-gray-500 border-b px-12 sm:px-20"></span>
            Due
            <span className="border-gray-500 border-b px-12 sm:px-20"></span>
            Due Monthly, Weekly, Bi-Weekly Beginning
            <span className="border-gray-500 border-b px-12 sm:px-20"></span>
          </p>
          <p className="font-semibold flex my-8 justify-between">
            <span>Month</span>
            <span>Payment Amount</span>
            <span>Principle</span>
            <span>Interest</span>
            <span>Remaining Balance</span>
          </p>
        </div>
      </div>

      <div className="my-10 mx-2 sm:mx-8">
        <h1 className="text-center my-4 font-bold text-lg">
          PRE-COMPUTED INTEREST VEHICLE SALES CONTRACT AND SECURITY AGREEMENT
        </h1>
        <h1 className="text-center font-bold my-4 text-lg">NOTICES </h1>

        <div className="text-red-500">
          <p className="my-4">
            THE MINIMUM PUBLIC LIABILITY INSURANCE LIMITS PROVIDED IN LAW MUST
            BE MET BY EVERY PERSON WHO PURCHASES A VEHICLE. IF YOU ARE UNSURE
            WHETHER OR NOT YOUR CURRENT INSURANCE POLICY WILL COVER YOUR NEWLY
            ACQUIRED VEHICLE IN THE EVENT OF AN ACCIDENT, YOU SHOULD CONTACT
            YOUR INSURANCE AGENT.
          </p>
          <p className="my-4">
            WARNING:
            <br />
            YOUR PRESENT POLICY MAY NOT COVER COLLISION DAMAGE OR MAY NOT
            PROVIDE FOR FULL REPLACEMENT COSTS FOR THE VEHICLE BEING PURCHASED.
            IF YOU DO NOT HAVE FULL COVERAGE, SUPPLEMENTAL COVERAGE FOR
            COLLISION DAMAGE MAY BE AVAILABLE TO YOU THROUGH YOUR INSURANCE
            AGENT OR THROUGH THE SELLING DEALER. HOWEVER, UNLESS OTHERWISE
            SPECIFIED, THE COVERAGE YOU OBTAIN THROUGH THE DEALER PROTECTS ONLY
            THE DEALER, USUALLY UP TO THE AMOUNT OF THE UNPAID BALANCE REMAINING
            AFTER THE VEHICLE HAS BEEN REPOSSESSED AND SOLD.
          </p>
          <p className="my-4">
            FOR ADVICE ON FULL COVERAGE THAT WILL PROTECT YOU IN THE EVENT OF
            LOSS OR DAMAGE TO YOUR VEHICLE, YOU SHOULD CONTACT YOUR INSURANCE
            AGENT.
          </p>
          <p className="my-4">
            THE BUYER SHALL SIGN TO ACKNOWLEDGE THAT HE/SHE UNDERSTANDS THESE
            PUBLIC LIABILITY TERMS AND CONDITIONS.
          </p>
        </div>

        <div className="my-4">
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28" />
            </p>

            <p>
              {" "}
              Buyer2 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28"></span>
            </p>
          </p>
          <p className="my-4">
            "WARNING-IT IS YOUR RESPONSIBILITY UNDER CALIFORNIA LAW TO OBTAIN
            LIABILITY INSURANCE OR BE SUBJECT TO PENALTIES FOR VIOLATING SECTION
            16020 OF THE VEHICLE CODE, WHICH MAY INCLUDE LOSS OF LICENSE OR A
            FINE. THE INSURANCE ACQUIRED BY THE LIENHOLDER DOES NOT PROVIDE
            LIABILITY COVERAGE AND DOES NOT SATISFY YOUR RESPONSIBILITY UNDER
            CALIFORNIA LAW."
          </p>
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28" />
            </p>

            <p>
              {" "}
              Buyer2 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28"></span>
            </p>
          </p>

          <p className="my-4">
            If you have a complaint concerning this sale, you should try to
            resolve it with the seller. Complaints concerning unfair or
            deceptive practices or methods by the seller may be referred to the
            city attorney, the district attorney, or an investigator for the
            Department of Motor Vehicles, or any combination thereof. After this
            contract is signed, the seller may not change the financing or
            payment terms unless you agree in writing to the change. You do not
            have to agree to any change, and it is an unfair or deceptive
            practice for the seller to make a unilateral change.
          </p>
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28" />
            </p>

            <p>
              {" "}
              Buyer2 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28"></span>
            </p>
          </p>

          <p className="my-4">
            "Notice to buyer: (1) Do not sign this agreement before you read it
            or if it contains any blank spaces to be filled in. (2) You are
            entitled to a completely filled-in copy of this agreement. (3) You
            can prepay the full amount due under this agreement at any time and
            obtain a partial refund of the finance charge if it is $1 or more.
            (4) If you default in the performance of your obligations under this
            agreement, the vehicle may be repossessed and you may be subject to
            suit and liability for the unpaid indebtedness evidenced by this
            agreement."
          </p>
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28" />
            </p>

            <p>
              {" "}
              Buyer2 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28"></span>
            </p>
          </p>
        </div>
      </div>
      <div className="my-14">
        <h1 className="text-center my-4 font-bold text-lg">
          PRE-COMPUTED INTEREST VEHICLE SALES CONTRACT AND SECURITY AGREEMENT
        </h1>

        <h1 className="text-center font-bold my-4 text-lg">NOTICES </h1>

        <div className="my-4">
          <p className="my-4">
            THERE IS NO COOLING-OFF PERIOD UNLESS YOU OBTAIN A CONTRACT
            CANCELLATION OPTION
          </p>

          <p className="mt-2">
            California law does not provide for a "cooling-off" or other
            cancellation period for vehicle sales. Therefore, you cannot later
            cancel this contract simply because you change your mind, decide the
            vehicle costs too much, or wish you had acquired a different
            vehicle. After you sign below, you may only cancel this contract
            with the agreement of the seller or for legal cause, such as fraud.
          </p>
          <p className="mb-4">
            However, California law does require a seller to offer a two-day
            contract cancellation option on used vehicles with a purchase price
            of less than forty thousand dollars ($40,000), subject to certain
            statutory conditions. This contract cancellation option requirement
            does not apply to the sale of a recreational vehicle, a motorcycle,
            or an off-highway motor vehicle subject to identification under
            California law. See the vehicle contract cancellation option
            agreement for details.
          </p>
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28" />
            </p>

            <p>
              {" "}
              Buyer2 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28"></span>
            </p>
          </p>
          <p className="mt-4">STOP AND READ:</p>
          <p>
            YOU CANNOT BE REQUIRED TO BUY A GAP WAIVER OR ANY OTHER OPTIONAL
            ADD-ON PRODUCTS OR SERVICES. IT IS OPTIONAL.
          </p>
          <p className="mb-4">
            NO ONE CAN MAKE YOU BUY A GAP WAIVER OR ANY OTHER OPTIONAL ADD-ON
            PRODUCTS OR SERVICES TO GET FINANCING, TO GET CERTAIN FINANCING
            TERMS, OR TO GET CERTAIN TERMS FOR THE SALE OF A VEHICLE. IT IS
            UNLAWFUL TO REQUIRE OR ATTEMPT TO REQUIRE THE PURCHASE OF THIS GAP
            WAIVER OR ANY OTHER OPTIONAL ADD-ON PRODUCTS OR SERVICES.
          </p>
          <p className="text-left flex  gap-6 flex-wrap ">
            <p>
              Buyer1 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28" />
            </p>

            <p>
              {" "}
              Buyer2 Signature
              <span className="border-gray-500 border-b px-12 sm:px-28"></span>
            </p>
          </p>
        </div>
      </div>
      <BhphfContractHalfSixSalesDEtails/>
      <div className="my-6 flex justify-center">
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
          type="button"
          size="md"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

const EmpltyRowCreate = ({ arr }: { arr: string[] }) => {
  return (
    <>
      {arr.map((item) => {
        return (
          <tr key={item}>
            <td className="border border-gray-400 p-4" />
            <td className="border border-gray-400 p-2" />
            <td className="border border-gray-400 p-2" />
            <td className="border border-gray-400 p-2" />
          </tr>
        );
      })}
    </>
  );
};
