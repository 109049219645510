export function isValidCreditCardNumber(cardNumber:string) {
    // Remove spaces and dashes
    cardNumber = cardNumber.replace(/[\s-]/g, '');

    // Check if the card number contains only digits and has a valid length
    if (!/^\d{15,16}$/.test(cardNumber)) {
        return false;
    }

    // Check if the card number passes the Luhn algorithm
    let sum = 0;
    let doubleUp = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i), 10);
        if (doubleUp) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
        doubleUp = !doubleUp;
    }
    return sum % 10 === 0;
}
