import {
  Switch as NextSwitch,
  SwitchProps as defaultSwitchProps,
} from "@nextui-org/react";
import { FC } from "react";

interface ToggleProps {
  checked: boolean;
}

export const Toggle: FC<ToggleProps & defaultSwitchProps> = ({
  checked,
  ...rest
}) => {
  return <NextSwitch isSelected={checked} {...rest} />;
};
