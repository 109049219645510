/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse } from "axios";
import qs from "query-string";

import { HttpStatusCode } from "./status-code.http";
import { env } from "config/env";
import { localStorageFn } from "../../utils/localstorage";
import { THttpModuleOptions } from "./service-type.http";

class HttpService {
  private http: AxiosInstance;

  constructor(private readonly instance = axios) {
    this.http = this.instance.create({
      baseURL: env.BASE_API_URL,
      timeout: 30000,
      paramsSerializer: function (params) {
        return qs.stringify(params, {
          encode: false,
        });
      },
    });
  }

  private interceptor() {
    this.http.interceptors.request.use((config) => {
      if (config.url === "User/authenticate") return config;

      if (localStorageFn.getItem("access_token")) {
        config.headers!.Authorization = `Bearer ${localStorageFn.getItem(
          "access_token"
        )}`;
      }
      return config;
    });

    this.http.interceptors.response.use(async (response: AxiosResponse) => {
      return response;
    });
  }

  get<T = any>(
    url: string,
    data?: Record<string, any>,
    config?: THttpModuleOptions
  ): Promise<AxiosResponse<T & any>> {
    this.interceptor();
    return this.http
      .get(url, {
        params: data && data,
        validateStatus: (status: HttpStatusCode) =>
          status === HttpStatusCode.OK,
        ...config,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorageFn.removeItem("access_token");
        }
        throw error;
      });
  }

  delete<T = any>(
    url: string,
    config?: THttpModuleOptions
  ): Promise<AxiosResponse<T & any>> {
    this.interceptor();
    return this.http
      .delete(url, {
        validateStatus: (status: HttpStatusCode) =>
          status <= HttpStatusCode.TOO_MANY_REQUESTS,
        ...config,
      })
      .catch((error) => {
        throw error(
          "Server is busy, please try again later",
          HttpStatusCode.SERVICE_UNAVAILABLE
        );
      });
  }

  post<T = any>(
    url: string,
    data?: Record<string, any> | any,
    config?: THttpModuleOptions
  ): Promise<AxiosResponse<T & any>> {
    this.interceptor();
    return this.http
      .post(url, data, {
        validateStatus: (status: HttpStatusCode) =>
          status <= HttpStatusCode.TOO_MANY_REQUESTS,
        ...config,
      })
      .catch((error) => {
        throw error;
      });
  }

  put<T = any>(
    url: string,
    data?: Record<string, any>,
    config?: THttpModuleOptions
  ): Promise<AxiosResponse<T & any>> {
    this.interceptor();
    return this.http
      .put(url, data, {
        validateStatus: (status: HttpStatusCode) =>
          status <= HttpStatusCode.TOO_MANY_REQUESTS,
        ...config,
      })
      .catch((error) => {
        throw error(
          "Server is busy, please try again later",
          HttpStatusCode.SERVICE_UNAVAILABLE
        );
      });
  }

  patch<T = any>(
    url: string,
    data?: Record<string, any>,
    config?: THttpModuleOptions
  ): Promise<AxiosResponse<T & any>> {
    this.interceptor();
    return this.http
      .patch(url, data, {
        validateStatus: (status: HttpStatusCode) =>
          status <= HttpStatusCode.TOO_MANY_REQUESTS,
        ...config,
      })
      .catch((error) => {
        throw error(
          "Server is busy, please try again later",
          HttpStatusCode.SERVICE_UNAVAILABLE
        );
      });
  }
}

export const http = new HttpService();
