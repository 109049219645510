import * as React from "react";
import { useDisclosure } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";

import {
  TilingSelectAtcProcedureRequest,
  useGetListingDetails,
  useGetSellerDetailsByListingId,
  useGetVindataDecode,
  useGetZipCodeDetails,
} from "services";
import { dmvDocumentServiceSignal, dmvtitlingSignal, selectedProcedureSignal } from "./store";
import { getUserId } from "utils/get-user-id";
import { extractNumberWithComma, formatDateToShortDate } from "utils";
import { findVehicleBy } from "utils/find-vehicle-by";
import { useSelectAtcProcedure, useUploadDMVTitlingFiles } from "services/tiling.service";
import { IFileInput } from "./enter-missing-information-form.component";

interface Props {
  isHideTitle?: boolean;
  onCallback?: () => void;
  fullService?:boolean;
  navigateOnSubmit?:boolean
  selectedFiles?:IFileInput
}

export const useDmvTilting = (props: Props) => {
  const { onCallback, isHideTitle ,selectedFiles ,navigateOnSubmit=true } = props;
  const params = useParams();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [ loading,setLoading]=React.useState(false);
  const [transferPlates, setTransferPlates] = React.useState(false);
  const [doesHaveLoan, setDoesHaveLoan] = React.useState(false);
  const [buyerTwo, setBuyerTwo] = React.useState(false);
  const [sellerTwo, setSellerTwo] = React.useState(false);
  const [place, setPlace] = React.useState({
    city: "",
    county: "",
    state: "",
    stateAbbr: "",
  });
  const [zipValue, setZipValue] = React.useState("");
  const [debouncedValue] = useDebounceValue(zipValue, 500);

  const [buyerOnePlace, setBuyerOnePlace] = React.useState({
    city: "",
    county: "",
    state: "",
    stateAbbr: "",
  });
  const [zipValuebuyerOne, setZipValueBuyerOne] = React.useState("");
  const [buyerOneDebouncedValue] = useDebounceValue(zipValuebuyerOne, 500);

  const currUserId = getUserId();

  const handleTransferPlates = () => {
    setTransferPlates(!transferPlates);
  };
  const { data: listingDetailsData } = useGetListingDetails(
    Number(params?.listingId) ?? ""
  );

  const { mutateAsync: sellerDetailsMutateAsync, data: sellerDetailsData } =
    useGetSellerDetailsByListingId(params?.listingId ?? "");
  React.useEffect(() => {
    const fetchSellerDetails = async () => {
      await sellerDetailsMutateAsync();
    };
    fetchSellerDetails();
  }, [sellerDetailsMutateAsync]);

  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
    isPending,
  } = useGetZipCodeDetails(debouncedValue || buyerOneDebouncedValue);

  React.useEffect(() => {
    const fetchZipCode = async () => {
      await zipcodeMutateAsync();
    };
    fetchZipCode();
  }, [zipcodeMutateAsync, debouncedValue]);
  React.useEffect(() => {
    if (zipcodeData && !isPending) {
      setPlace({
        city: zipcodeData?.city ?? "",
        county: zipcodeData?.county ?? "",
        stateAbbr: zipcodeData?.stateAbbr ?? "",
        state: zipcodeData?.state ?? "",
      });
    }
  }, [isPending, zipcodeData]);

  const {
    mutateAsync: selectAtcProcedureMutateAsync,

  } = useSelectAtcProcedure();
  
  const navigate = useNavigate();
  const { data: vinDecodeData } = useGetVindataDecode(params?.id ?? "");
  const message = vinDecodeData?.message;

  const { mutateAsync: uploadDMVTitlingFilesAsycn } =
    useUploadDMVTitlingFiles();

  const handleAtcUploadDoc = (atcData: any) => {
    const formData = new FormData();
    selectedFiles?.insurance &&
      formData.append("currentInsurance", selectedFiles.insurance);
    selectedFiles?.license &&
      formData.append("driverLicense", selectedFiles.license);

    uploadDMVTitlingFilesAsycn({
      qId: atcData?.quoteId || "0",
      payload: formData,
    });
  };

  const handleSubmit = async (formData: any) => {
    try{
      setLoading(true);
      const userId = getUserId();
      const payload: TilingSelectAtcProcedureRequest = {
        listingId: Number(params?.listingId ?? "0"),
        quoteId: 0,
        quoteStatus: 0,
        paymentStatus: 0,
        tier1: !props?.fullService,
        // tier2: toggles.pdf,
        tier2: false,
        tier3: !!props.fullService,
        userId,
      };
      const atcData = await selectAtcProcedureMutateAsync(payload);
      selectedProcedureSignal.value = atcData;
  
      if(props.fullService && (selectedFiles?.insurance || selectedFiles?.license)){
        handleAtcUploadDoc(atcData)
      }
      const today = new Date();
      dmvDocumentServiceSignal.value = {
        titledState: formData?.vehicleToBeTitledIn?.titledState || "",
        state: place.stateAbbr || "",
        county: place.county || "",
        city: place.city || "",
        zipcode: formData?.vehicleToBeTitledIn?.zipcode || "",
        transferingPlates: transferPlates ? "Yes" : "No",
        sbDriverLicenseNum:
          formData?.vehicleToBeTitledIn?.licensePlateNumber || "",
        sbDriverLicenseState:
          formData?.vehicleToBeTitledIn?.licensePlateNumber || "",
        lienholderCompany: formData?.doesVehicleHaveLoan?.lienholderCompany || "",
        lienholderAddress: formData?.doesVehicleHaveLoan?.lienholderName || "",
        lien: doesHaveLoan ? "Yes" : "No",
        insuranceCompany: formData?.doesVehicleHaveLoan?.insuranceCompany || "",
        fbFirstName: formData?.buyerOneDetails?.fbFirstName || "",
        fbMiddleName: formData?.buyerOneDetails?.fbMiddleName || "",
        fbLastName: formData?.buyerOneDetails?.fbLastName || "",
        fbCompany: formData?.buyerOneDetails?.fbCompany || "",
        fbAddress: formData?.buyerOneDetails?.fbAddress || "",
        fbAddress2: formData?.buyerOneDetails?.fbAddress2 || "",
        fbCity: formData?.buyerOneDetails?.fbCity || "",
        fbState: formData?.buyerOneDetails?.fbState || "",
        fbzip: formData?.buyerOneDetails?.fbzip || "",
        fbdlNumber: formData?.buyerOneDetails?.fbdlNumber || "",
        fbdlState: formData?.buyerOneDetails?.fbdlState || "",
        fbdob: "",
        fbssn: "",
        sbFirstName: formData?.buyerTwoDetails?.sbFirstName || "",
        sbMiddleName: formData?.buyerTwoDetails?.sbMiddleName || "",
        sbLastName: formData?.buyerTwoDetails?.sbLastName || "",
        sbAddress1: formData?.buyerTwoDetails?.sbAddress1 || "",
        sbAddress2: formData?.buyerTwoDetails?.sbAddress2 || "",
        sbCity: formData?.buyerTwoDetails?.sbCity || "",
        sbState: formData?.buyerTwoDetails?.sbState || "",
        sbzip: formData?.buyerTwoDetails?.sbzip || "",
        sbdob: "",
        sbssn: "",
        fsFirstName: formData?.sellerOneDetails?.fsFirstName || "",
        fsMiddleName: formData?.sellerOneDetails?.fsMiddleName || "",
        fsLastName: formData?.sellerOneDetails?.fsLastName || "",
        fsAddress: formData?.sellerOneDetails?.fsAddress || "",
        fsCity: formData?.sellerOneDetails?.fsCity || "",
        fsState: formData?.sellerOneDetails?.fsState || "",
        fszip: formData?.sellerOneDetails?.fszip || "",
        fsCompany: formData?.sellerOneDetails?.fsCompany || "",
        ssFirstName: formData?.sellerTwoDetails?.ssFirstName || "",
        ssMiddleName: formData?.sellerTwoDetails?.ssMiddleName || "",
        ssLastName: formData?.sellerTwoDetails?.ssLastName || "",
        sellerDealerNumber: "",
        newUsed: formData?.newUsedVinSection?.newUsed || "",
        vin: params?.id?.toUpperCase() || "",
        transDt: formatDateToShortDate(today),
        uploadeddate: formatDateToShortDate(today),
        plateType: formData?.plateType || "",
        transType: formData?.transType || "",
        userID: currUserId.toString() || "",
        number: formData?.vehiclePlateNumber || "0",
        vehicleFuelType: "Gas",
        cylinders: findVehicleBy(message ?? [], "EngineCylinders")?.value ?? "",
        vehicleBodyStyle: findVehicleBy(message ?? [], "BodyClass")?.value ?? "",
        make: findVehicleBy(message ?? [], "Make")?.value ?? "",
        model: findVehicleBy(message ?? [], "Model")?.value ?? "",
        vYear: findVehicleBy(message ?? [], "ModelYear")?.value ?? "",
        odometer: findVehicleBy(message ?? [], "EngineKW")?.value ?? "",
        gvw:
          extractNumberWithComma(
            findVehicleBy(message ?? [], "GVWR")?.value || ""
          )?.toString() ?? "",
        weight:
          extractNumberWithComma(
            findVehicleBy(message ?? [], "Note")?.value || ""
          )?.toString() ?? "",
        vehicleType: formData?.vehicleType || "",
        usertype: "",
        dealerID: "",
        expiration: "",
        documentFees: "0",
        warrantyFees: "0",
        color: "",
        hp: "",
        msrp: "",
        purchaseprice: "",
        purchaseDate: "",
        tradeIn: "",
        tradeInState: "",
        tradeAmt: "",
        totalPrice: "",
        lienholderAddress2: "",
        lienholderFederalTaxID: "",
        lienholderStateTaxID: "",
        lhCity: "",
        lhState: "",
        lhzip: "",
        lienDate: "",
        maturityDate: "",
        amount: "",
        insuranceDate: "",
        insuranceExpiryDate: "",
        insuranceNumber: "",
        leaseCompanyName: "",
        leaseAddress: "",
        leaseAddress2: "",
        leaseFederalTaxId: "",
        leaseStateTaxId: "",
        leaseCity: "",
        leaseState: "",
        leaseZIP: "",
        downPayment: "",
        monthlyPayment: "0",
        monthsInTerm: "0",
        strUser: "",
        feid: "",
        titled: "",
      };
      dmvtitlingSignal.value = {
        type: "FeeQuote",
        requestFrom: "ATC",
        requestFor: "",
        verbosityLevel: "4",
        granularity: "2",
        quoteType: "A",
        isProcessing: "1",
        state: place.state || "",
        county: place.county || "",
        city: place.city || "",
        zipCode: formData?.vehicleToBeTitledIn?.zipCode,
        outsideCityLimits: "0",
        vehicle: {
          vin: params?.id?.toUpperCase() ?? "",
          basePrice: formData?.vehicleBasePrice || "0",
          customerType: "D",
          fuelType: "G",
          cylinders: "6",
          finance: {
            downPayment: "0",
            monthlyPayment: "0",
            monthsInTerm: "0",
            capCostReduction: "0",
          },
          rimSize: "0",
          tires: "0",
          axles: "0",
          gvw: "0",
          gvwr: "0",
          length: "0",
          mpg: "0",
          registrationMonths: "0",
          cc: "0",
          lienStatus: "1",
          year: "2018",
          msrp: "1",
          plate: {
            isTransfer: "1",
            plateType: "R",
          },
          registrationOption: "B",
          daysSincePurchase: "138",
          titleStatus: "T",
          transactionType: "P",
          vehicleType: "PV",
          warranties: {},
          weight: "3458",
          rebates: {
            dealer: "0",
            manufacturer: "0",
          },
          additions: {
            addition: {
              name: "Add on",
              content: "0",
            },
          },
          deliveryFees: {
            deliveryFee: {
              name: "DeliveryFees",
              content: "0.00",
            },
          },
          discounts: {
            discount: {
              name: "Discount",
              content: "0.00",
            },
          },
          docFees: {
            docFee: {
              name: "Doc Fees",
              content: "0.00",
            },
          },
          dealerServiceFees: {
            dealerServiceFee: {
              name: "DealerServiceFee",
              content: "0.00",
            },
          },
          extendedWarranties: {},
          insurancePackages: {},
          protectionPackages: {},
          taxes: {},
          theftProtections: {},
          taxesPaidToAnotherState: "0",
        },
      };
      if(navigateOnSubmit){
        if (props?.fullService){ navigate("dmv-fee-quotes");}
        else {navigate("dmv-fee-details")};
        
      }
      onOpen();
      onCallback && onCallback();
      setLoading(false);
    }catch(e){
      console.log(e)
      setLoading(false);
    }
   
  };

  return {
    isHideTitle,
    loading,
    isOpen,
    onOpenChange,
    transferPlates,
    doesHaveLoan,
    buyerTwo,
    sellerTwo,
    place,
    handleTransferPlates,
    listingDetailsData,
    sellerDetailsData,
    handleSubmit,
    setDoesHaveLoan,
    setBuyerTwo,
    setSellerTwo,
    setPlace,
    setZipValue,
    setZipValueBuyerOne,
    zipcodeData,
    params,
  };
};
