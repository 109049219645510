import { Typography } from "components";
import React from "react";

interface DmvFeeQuoteModalModalProps {
  open: boolean;
  handleClose: () => void;
  selectedData?: {
    name: string;
    vin: string;
    listingId: number;
  };
}

export const DmvFeeQuoteModal: React.FC<DmvFeeQuoteModalModalProps> = ({
  open,
  handleClose,
  selectedData,
}) => {
  const handleContinue = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      {/* <div className="absolute flex items-center m-auto dmv-title"> */}
      {/* <div className="flex items-center  bg-cover bg-center mx-auto px-4 bg-fixed"> */}
      {open && (
        <>
          {/* <div
            style={{ zIndex: 150 }}
            className="absolute inset-0 bg-black opacity-50 z-999"
          ></div> */}
          <div
            style={{ zIndex: 151 }}
            className="dmv-title absolute flex  p-4 overflow-x-hidden  items-center  modal-container-vin  justify-center -top-32 right-0 left-0 z-50 w-full  h-[100%]  max-h-full"
          >
            <div className="relative gradiant-modal-loading h-[450px] py-24 px-24   md:px-12  mx-auto  rounded-lg shadow-md ">
              <img
                onClick={handleClose}
                src="assets/icons/close-icon.svg"
                alt="x"
                className="absolute right-8 top-10 border-white border-2 p-1 rounded-full cursor-pointer"
              />
              <div className="text-white justify-center  font-bold mb-4  md:text-2xl flex text-lg">
                Please Confirm Payment
              </div>
              <Typography
                variant="custom"
                className="text-white justify-center text-center mb-8 text-2xl md:text-4xl font-[800] flex"
              >
                $10.50
              </Typography>

              <div className="flex justify-center flex-col items-center mb-2 gap-8">
                <p className="text-white w-full font-bold  text-center md:text-sm  text-base">
                  "By clicking ACCEPT, you agree to the charge listed below to
                  your credit card on file"
                </p>
                <button
                  onClick={handleContinue}
                  className=" text-white confirm-btn-fin py-2  rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-2xl"
                  type="button"
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* </div> */}
      {/* </div> */}
    </React.Fragment>
  );
};
