import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Image,
  Select,
  SelectItem,
} from "@nextui-org/react";

import Textarea from "components/ui/textarea";
import { SelectNextUi, TextErrorHelper, Typography } from "components";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { useGetShippingQuotesInfoPublic } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { useGetSendSellerCustomCashOffer } from "hooks/api/mutations/view-cars/view-cars";
import {
  useGetATCFeeQuotesListing,
  useQueryGetSellerDetailsByListingId,
} from "hooks/api/queries/view-cars/view-cars";
import { SendSellerCashOfferSchema } from "pages/public/view-car/view-car.validation";
import { useEffect, useState } from "react";
import { getUserId } from "utils/get-user-id";
import { useSelectAtcProcedure } from "services/tiling.service";
import {
  ListingDetailsResponse,
  TilingSelectAtcProcedureRequest,
  useGetCurrentUserForBoth,
  useGetZipCodeDetails,
  useGetZipCodeDetailsForMul,
} from "services";
import { useDebounceValue } from "usehooks-ts";
import { FormatedShippingResponseType } from "utils/shipping";
import { useSubmitVehiclePurchaseApplication } from "modules/user/hooks/api/mutations/finance/use-finance";
import { useNavigate } from "react-router-dom";
import { SendApplyOfferLoanTerm } from "./apply-finance.schema";

interface RequestedLoanTermsProps {
  vin: string;
  listing?: ListingDetailsResponse["listing"] | null;
  financeOption: "In_House_Financing" | "Standard_Financing";
  isPreRequest: boolean
}

export const MONTHS = [
  6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  26, 27, 28, 29, 30,
].map((val) => ({ label: `${val} months`, value: val.toString() }));

export const RequestedLoanTerms: React.FC<RequestedLoanTermsProps> = ({
  vin,
  listing,
  financeOption,
  isPreRequest= false
}) => {
  const userId = getUserId();
  const navigate = useNavigate();
  const id = String(listing?.listingId || "");
  const [priceDataForOffer, setPriceDataForOffer] = useState<any>({});
  // const [isOfferSent, setIsOfferSent] = useState<boolean>(false);

  const { data: sellerData } = useQueryGetSellerDetailsByListingId(isPreRequest?'':id);
  const [place, setPlace] = useState({
    city: "",
    county: "",
    state: "",
    stateAbbr: "",
  });
  const [zipValue, setZipValue] = useState("");
  const [debouncedValue] = useDebounceValue(zipValue, 500);

  // DMV relate hooks
  const {
    mutateAsync: selectAtcProcedureMutateAsync,
    // data: atcProcedureData,
    isPending: isPendingToAtcProcedure,
  } = useSelectAtcProcedure();
  const {
    mutateAsync: mutateFeeQuotesData,
    isPending: isPendingToFeeQuotes,
    // data: feeQuotesData,
    error: dmvError,
  } = useGetATCFeeQuotesListing(id || "", vin || "");

  //shipping related hooks
  const {
    mutateAsync: mutateAsyncGetShippingQuotes,
    isPending: isPendingToGetShippingInfo,
    error: shippingError,
  } = useGetShippingQuotesInfoPublic();

  const { mutateAsync: zipcodeMutateAsyncDealer, data: zipcodeDataDealer } =
  useGetZipCodeDetailsForMul();

  const {
    data: userData,
  } = useGetCurrentUserForBoth(userId, false);
  // offer related hooks
  const {
    mutateAsync: mutateSubmitLoanApplication,
    isPending: isPendingToSendLoadApplication,
    error: sendOfferError,
  } = useSubmitVehiclePurchaseApplication();

  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
    isPending: isPendingToFindZipCode,
  } = useGetZipCodeDetails(debouncedValue);
  //finace part start

  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState(0);

  const [sellerFinanceOffer, setSellerFinanceOffer] = useState({
    cash_Price: 0,
    down_Payment: 0,
    loan_Term: 0,
    interest_Rate: financeOption === "Standard_Financing" ? 0 : 25,
    listingId: 0,
    monthly_Payment: 0,
    userId: 0,
    down_Payment_error: false,
    interest_Rate_error: false,
    loan_Term_error:false
  });

  const [loanDetails, setLoanDetails] = useState({
    totalLoanAndInterest: 0,
    loanTerm: 0,
    interestRate: financeOption === "Standard_Financing" ? 0 : 25,
  });

  useEffect(() => {
    const loanAmount =
      Number(priceDataForOffer?.total) -
      Number(sellerFinanceOffer.down_Payment);

    const loanTermMonths = Number(sellerFinanceOffer.loan_Term);

    const monthlyInterestRate =
      Number(sellerFinanceOffer.interest_Rate) / 100 / 12;

    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));

    setEstimatedMonthlyPayment(monthlyPayment);
    const data = {
      totalLoanAndInterest: loanAmount,
      loanTerm: loanTermMonths,
      interestRate: sellerFinanceOffer.interest_Rate
    };
    setLoanDetails(data);
  }, [
    sellerFinanceOffer.cash_Price,
    sellerFinanceOffer.down_Payment,
    //   sellerFinanceOffer.estimated_Credit_Score,
    sellerFinanceOffer.interest_Rate,
    sellerFinanceOffer.loan_Term,
  ]);
  //finace part end

  useEffect(() => {
    const fetchZipCode = async () => {
      await zipcodeMutateAsync();
    };
    fetchZipCode();
  }, [zipcodeMutateAsync, debouncedValue]);

  useEffect(() => {
    if (zipcodeData && !isPendingToFindZipCode) {
      setPlace({
        city: zipcodeData?.city ?? "",
        county: zipcodeData?.county ?? "",
        stateAbbr: zipcodeData?.stateAbbr ?? "",
        state: zipcodeData?.state ?? "",
      });
    }
  }, [isPendingToFindZipCode, zipcodeData]);

  useEffect(() => {
    if (sellerData?.zipCode|| userData?.zipCode ) {
      zipcodeMutateAsyncDealer(isPreRequest?(userData?.zipCode ||""):(sellerData?.zipCode || ""));
    }
  }, [sellerData?.zipCode,userData?.zipCode]);
  console.log("sellerData --->",userData)

  const handleCalculate = async (formData: any) => {
    if (  !zipcodeData ||  (!isPreRequest && !zipcodeDataDealer) ) {
      return 0;
    }
    try {
      let resShip: FormatedShippingResponseType | null = null;
      if (formData.isShippingNeeded) {
        resShip = await mutateAsyncGetShippingQuotes({
          vin: isPreRequest?"5GADT13S862340782": vin,
          listingId: +(id || 0),
          shippingZip: isPreRequest?( userData?.zipCode || "") :(sellerData?.zipCode ?? ""),
          shippingStreetAddress: "",
          destinationZip: zipValue,
          destinationStreetAddress: "",
          estimatedShipDate: new Date().toISOString(),
          desiredEstimatedShipDate: "",
          ship: zipcodeDataDealer || {} as any,
          dest: zipcodeData,
          carYear: isPreRequest? 2006: (Number(listing?.year || 0)),
          make: isPreRequest ? 'Buick' : (listing?.make || ""),
          model: isPreRequest?"Rainier": (listing?.model || ""),
        }).catch((e) => {
          console.error("error : ", e);
          return null;
        });
      }
      let data;
      const payload: TilingSelectAtcProcedureRequest = {
        listingId: +(id || 0),
        quoteId: 0,
        tier1: true,
        tier2: false,
        tier3: false,
        userId: userId ? userId : 0,
      };

      data = await selectAtcProcedureMutateAsync(payload);

      const vehicle = listing;
      const basePrice = formData.userOffer;
      const dmvData = await mutateFeeQuotesData({
        place,
        zipValue,
        qId: data?.quoteId,
        basePrice,
        vehicle,
      });

      const salesTax = Number(
        dmvData?.feeDetails?.feeDetail?.find(
          (item) => item.feeName === "State Tax"
        )?.amount || "0"
      );
      const totalDmvFee = Number(dmvData?.feeTotal || "0");
      const dmvfees = totalDmvFee - salesTax;

      const totalShipping = resShip?.TotalPrice || 0;
      setPriceDataForOffer({
        shipping: {
          label: "$" + totalShipping.toFixed(2),
          value: totalShipping,
        },
        dmv: {
          sales: { value: salesTax, label: "$" + salesTax.toFixed(2) },
          fees: { value: dmvfees, label: "$" + dmvfees.toFixed(2) },
        },
        total: Number(totalShipping) + Number(formData.userOffer) + totalDmvFee,
      });

      //   const totalPrice =
      //     Number(totalShipping) + Number(formData.userOffer) + totalDmvFee;
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handleSendLoanApplication = async (formData: {
    offer: string;
    // shippingZip: string;
    userMessage?: string;
    isShippingNeeded?: boolean;
  }) => {
    if (!zipcodeData) {
      return 0;
    }
    try {
      const  data =  SendApplyOfferLoanTerm.safeParse({down_Payment :sellerFinanceOffer.down_Payment ,interest_Rate: sellerFinanceOffer.interest_Rate, loan_Term : loanDetails.loanTerm })
      if(!data.success){
        setSellerFinanceOffer((prev)=>({
          ...prev,
          loan_Term_error: prev.loan_Term < 1,
          down_Payment_error: prev.down_Payment < 500,
          interest_Rate_error: prev.interest_Rate < 1
        }))
        return 0;
      }

     
      const resSentOffer = await mutateSubmitLoanApplication({
        buyerId: userId,
        applicationDate: new Date().toISOString(),
        fees: priceDataForOffer?.dmv?.fees?.value || 0,
        financeType: 0,
        interestRate: loanDetails.interestRate,
        listingId: +id,
        loanTerm: loanDetails.loanTerm,
        purchasePrice: +formData.offer,
        sellerId: +(listing?.tenantId || 0),
        shippingCharges: Number(priceDataForOffer?.shipping?.value || 0),
        shippingIncluded: formData?.isShippingNeeded || false,
        totalDownPayment: sellerFinanceOffer.down_Payment,
        totalFees:
          Number(priceDataForOffer?.dmv?.sales?.value || 0) +
          Number(priceDataForOffer?.dmv?.fees?.value || 0),
        totalSalesTax: Number(priceDataForOffer?.dmv?.sales?.value || 0),
        totalShippingCharges: Number(priceDataForOffer?.shipping?.value || 0),
        zipcode: zipValue,
        salesTax: Number(priceDataForOffer?.dmv?.sales?.value || 0),
        totalCashPrice: Number(priceDataForOffer?.total || 0),
        NoteToSeller: formData?.userMessage || ""
      });
      // if (resSentOffer === "") {
      //   setIsOfferSent(true);
      // }
      
      navigate(`/user/garage/${isPreRequest?"apply-pre-approve-loan":"apply-finance-loan"}/${listing?.listingId ||0}`)
    } catch (e) {
      console.log("Error :", e);
    }
  };

  return (
    <Form
      id="request_load_applicarion_select_id"
      schema={SendSellerCashOfferSchema}
      onSubmit={handleCalculate}
      className=""
    >
      {({
        formState: { errors, isSubmitted },
        register,
        getValues,
        setValue,
      }) => {
        return (
          <Card className="bg-transparent pb-3" shadow="none">
            <CardHeader className="flex justify-center mt-2">
              <Typography
                variant="titleWithGradient"
                className=" px-3 md:px-14 py-3 text-sm sm:text-lg md:text-3xl font-[600]"
              >
                Requested Loan Terms
              </Typography>
            </CardHeader>
            <CardBody
              className={`grid grid-cols-1 lg:grid-cols-2 md:gap-x-12 md:px-12 md:my-5  ${":mb-0"}`}
            >
              <div>
                <div className="col-span-2 pt-4">
                  <div className="grid lg:grid-cols-2 justify-items-start items-center gap-2 lg:gap-10">
                    <Typography
                      variant="custom"
                      className=" text-xs sm:text-medium font-[600]"
                    >
                      Enter Your Offer Here
                    </Typography>
                    <Input
                      isInvalid={!!errors["userOffer"]}
                      variant="form"
                      size="compact"
                      radius="base"
                      classNames={{
                        base: "w-full",
                        inputWrapper: !!errors["userOffer"]
                          ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)] "
                          : "border-black",
                      }}
                      {...register("userOffer")}
                    />
                  </div>
                </div>
                <div className="col-span-2 pt-4">
                  <div className="grid lg:grid-cols-2 justify-items-start items-center gap-2 lg:gap-10">
                    <Typography
                      variant="custom"
                      className="text-xs sm:text-medium font-[600]"
                    >
                      Type in your zip Code
                    </Typography>
                    <Input
                      isInvalid={!zipcodeData && isSubmitted}
                      variant="form"
                      size="compact"
                      radius="base"
                      maxLength={5}
                      classNames={{
                        base: "w-full",
                        inputWrapper:
                          !zipcodeData && isSubmitted
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : " border-black",
                      }}
                      {...register("userZip")}
                      onChange={(e) => setZipValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <Textarea
                  cols={50}
                  rows={6}
                  minRows={6}
                  maxRows={6}
                  variant="custom"
                  type="text"
                  classNames={{
                    inputWrapper: "bg-white border-black",
                  }}
                  placeholder="Send Note to Seller"
                  {...register("userMessage")}
                  name="userMessage"
                />
              </div>

              <div className="grid grid-cols-2 mt-4 lg:mt-0 gap-2 lg:gap-10  justify-between ">
                <div></div>
                <Button
                  size="lg"
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-xs sm:text-lg w-full  tracking-wide px-6 rounded-xl "
                  type="submit"
                  isLoading={isPendingToGetShippingInfo || isPendingToFeeQuotes}
                >
                  Calculate
                </Button>
              
              </div>
              {!zipcodeDataDealer && isSubmitted 
                && (
              <div className="flex flex-row gap-2 my-2 col-span-1 lg:col-span-2 justify-center ">
                <Image src="/assets/icons/error-outline.svg" alt="error" />
                <TextErrorHelper>
                  &nbsp; {isPreRequest?"Please update your zipcode.": "Listing Does not have Seller zip information"}
                </TextErrorHelper>
              </div>
            )}
            {((getValues("isShippingNeeded") && shippingError?.message) ||
              dmvError?.message ||
              sendOfferError?.message) && (
              <div className="flex flex-row gap-2 my-2 col-span-1 lg:col-span-2 justify-center bottom-1">
                <Image src="/assets/icons/error-outline.svg" alt="error" />
                <TextErrorHelper>
                  &nbsp;{" "}
                  {(getValues("isShippingNeeded") && shippingError?.message) ||
                    dmvError?.message ||
                    sendOfferError?.message}
                </TextErrorHelper>
              </div>
            )}
              <div className="grid w-full col-span-1 lg:col-span-2  grid-cols-1 sm:grid-cols-3 gap-2 lg:gap-10 pt-4">
                <div className="bg-white border border-[#018ffd] text-center rounded-2xl flex flex-col items-center">
                  <Typography
                    variant="label"
                    className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg"
                  >
                    Sales Tax
                  </Typography>
                  <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                  <Input
                    variant="bordered"
                    disabled
                    classNames={{
                      inputWrapper: "border-0",
                      input: "text-center",
                    }}
                    value={
                      priceDataForOffer?.dmv?.sales?.label ||
                      (isPendingToAtcProcedure ||
                      isPendingToFeeQuotes ||
                      isPendingToGetShippingInfo
                        ? "..."
                        : "")
                    }
                  />
                </div>
                <div className="bg-white border border-[#018ffd]  text-center rounded-2xl flex flex-col items-center">
                  <Typography
                    variant="label"
                    className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg"
                  >
                    Dmv & fees
                  </Typography>
                  <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                  <Input
                    variant="bordered"
                    disabled
                    classNames={{
                      inputWrapper: "border-0",
                      input: "text-center",
                    }}
                    value={
                      priceDataForOffer?.dmv?.fees?.label ||
                      (isPendingToAtcProcedure ||
                      isPendingToFeeQuotes ||
                      isPendingToGetShippingInfo
                        ? "..."
                        : "")
                    }
                  />
                </div>

                <div className="bg-white text-center rounded-2xl flex flex-col items-center border border-[#018ffd] ">
                  <Typography
                    variant="label"
                    className="py-3 md:px-3 text-xs sm:text-lg text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text md:text-lg lg:text-sm min-[1118px]:text-lg xl:text-sm min-[1298px]:text-lg"
                  >
                    <span>Shipping Quote</span>
                    <Checkbox
                      type="checkbox"
                      className="ml-1 md:ml-2 px-1 sm:px-2"
                      onChange={(e) => {
                        setValue("isShippingNeeded", e.currentTarget.checked);
                      }}
                    />
                  </Typography>
                  <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                  <Input
                    variant="bordered"
                    disabled
                    value={
                      priceDataForOffer?.shipping?.label ||
                      (isPendingToAtcProcedure ||
                      isPendingToFeeQuotes ||
                      isPendingToGetShippingInfo
                        ? "..."
                        : "")
                    }
                    classNames={{
                      inputWrapper: "border-0",
                      input: "text-center",
                    }}
                  />
                </div>
              </div>

              <div className="my-8 col-span-1 lg:col-span-2 flex flex-col sm:flex-row gap-2 lg:gap-10 items-center">
                <div className="grid grid-cols-1 w-full md:grid-cols-2 justify-items-start items-center gap-2 lg:gap-10">
                  <Typography
                    variant="custom"
                    className="text-xs sm:text-medium font-[600]"
                  >
                    Total Cash Price
                  </Typography>
                  <Input
                    disabled
                    size="compact"
                    value={
                     priceDataForOffer?.total
                    }
                    classNames={{
                      base: "w-full",
                      input: "text-black py-0",
                      inputWrapper: " border-black",
                    }}
                  />
                </div>

                <div className="grid w-full  grid-cols-1 md:grid-cols-2 justify-items-start items-center gap-2 lg:gap-10">
                  <Typography
                    variant="custom"
                    className=" text-xs sm:text-medium font-[600]"
                  >
                    What is Your Down Payment
                  </Typography>
                  <Input
                    //   isInvalid={!!errors["userOffer"]}
                    variant="form"
                    size="compact"
                    radius="base"
                    isInvalid={sellerFinanceOffer.down_Payment_error}
                      classNames={{
                        base: "w-full",
                        inputWrapper: sellerFinanceOffer.down_Payment_error
                          ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)] "
                          : "border-black",
                      }}
                    //   {...register("userOffer")}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      const data = {
                        ...sellerFinanceOffer,
                        down_Payment: value,
                        down_Payment_error: value < 500
                      };
                      setSellerFinanceOffer(data);
                    }}
                  />
                </div>
              </div>

              <div className="col-span-1 lg:col-span-2">
                <div className="grid grid-cols-1 sm:grid-cols-3  gap-2 lg:gap-10 pt-4">
                  <div className="bg-white border border-[#018ffd] text-center rounded-2xl flex flex-col items-center">
                    <Typography
                      variant="label"
                      className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg "
                    >
                      Loan Term <span className="text-xs">(Months)</span>
                    </Typography>
                    <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                    {financeOption === "Standard_Financing" ? (
                      <Input
                        variant="bordered"
                        classNames={{
                          inputWrapper: "border-0 ",
                          input: sellerFinanceOffer.loan_Term_error ?"border-b-2 border-red-500 text-center": "text-center ",

                        }}
                        
                        isInvalid={sellerFinanceOffer.loan_Term_error}
                        //   errorMessage={
                        //     errors["loan_Term" as keyof typeof errors]?.message
                        //   }
                        disabled={priceDataForOffer?.total ? false : true}
                        //   {...register("loan_Term" as "loan_Term")}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          const data = {
                            ...sellerFinanceOffer,
                            loan_Term: value,
                            loan_Term_error: value<1,
                            
                          };
                          setSellerFinanceOffer(data);
                        }}
                      />
                    ) : (
                      <Select
                        size="lg"
                        variant="flat"
                        fullWidth
                        classNames={{
                          trigger:
                            "border-2 rounded-2xl border border-double border-transparent   bg-white",
                          value: "text-center",
                          innerWrapper:sellerFinanceOffer.loan_Term_error
                          ?"border-b-2  border-red-500 text-center": "text-center "
                        }}
                        placeholder="Select Months"
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          const data = {
                            ...sellerFinanceOffer,
                            loan_Term: value,
                            loan_Term_error: value<1,
                          };
                          setSellerFinanceOffer(data);
                        }}
                        
                        popoverProps={{ portalContainer: document.getElementById("request_load_applicarion_select_id")|| undefined}}
                      >
                        {MONTHS.map((data) => (
                          <SelectItem
                            key={data.value}
                            value={data.value}
                            className="text-center"
                          >
                            {data.label}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  </div>
                  <div className="bg-white border  text-center rounded-2xl flex flex-col items-center border-[#018ffd]">
                    <Typography
                      variant="label"
                      className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg "
                    >
                      Monthly Payment
                    </Typography>
                    <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                    <Input
                      variant="bordered"
                      classNames={{
                        inputWrapper:
                          "border-0 bg-gray-100 rounded-b-2xl rounded-t-none",
                        input: "text-center border-0",
                      }}
                      disabled
                      value={
                        String(estimatedMonthlyPayment) !== "NaN"
                          ? String(estimatedMonthlyPayment.toFixed(2))
                          : ""
                      }
                    />
                  </div>
                  <div className="bg-white text-center rounded-2xl flex flex-col items-center border border-[#018ffd] ">
                    <Typography
                      variant="label"
                      className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg "
                    >
                      Interest Rate
                    </Typography> 
                    <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                    <Input
                      variant="bordered"
                      classNames={{
                        inputWrapper: "border-0",                        
                        input: sellerFinanceOffer.interest_Rate_error ?"border-b-2 border-red-500 text-center": "text-center ",

                      }}
                      disabled={
                        !priceDataForOffer?.total ||
                        financeOption === "In_House_Financing"
                      }
                      defaultValue={
                        financeOption === "In_House_Financing" ? "25%" : ""
                      }
                      
                      //   {...register("interest_Rate" as "interest_Rate")}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const data = {
                          ...sellerFinanceOffer,
                          interest_Rate: value,
                          interest_Rate_error: value <1
                        };
                        setSellerFinanceOffer(data);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid col-span-1 lg:col-span-2 grid-cols-4 mt-4 gap-2 lg:gap-10  justify-between ">
                <div className="col-span-2 lg:col-span-3"></div>
                <Button
                  size="lg"
                  className="bg-gradient-to-r col-span-2 lg:col-span-1 from-[#018ffd] to-[#070c84] text-white text-xs sm:text-lg w-full  tracking-wide px-6 rounded-xl"
                  isLoading={isPendingToSendLoadApplication}
                  onClick={() => {
                    handleSendLoanApplication({
                      offer: getValues("userOffer"),
                      // shippingZip: getValues("userZip"),
                      userMessage: getValues("userMessage"),
                      isShippingNeeded: getValues("isShippingNeeded"),
                    });
                  }}
                >
                  {"Continue"}
                </Button>
              </div>
            </CardBody>

          
          </Card>
        );
      }}
    </Form>
  );
};
