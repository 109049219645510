import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Image,
} from "@nextui-org/react";

import Textarea from "components/ui/textarea";
import { TextErrorHelper, Typography } from "components";
import Form from "components/ui/form";
import Input from "components/ui/input";
import {
  useGetShippingQuotesInfo,
  useGetShippingQuotesInfoPublic,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { useGetSendSellerCustomCashOffer } from "hooks/api/mutations/view-cars/view-cars";
import {
  useGetATCFeeQuotesListing,
  useQueryGetSellerDetailsByListingId,
} from "hooks/api/queries/view-cars/view-cars";
import { SendSellerCashOfferSchema } from "pages/public/view-car/view-car.validation";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserId } from "utils/get-user-id";
import { useDispatch } from "react-redux";
import { changeForSellerOfferPrice } from "modules/dealer/store/seller-offer-price";
import { useSelectAtcProcedure } from "services/tiling.service";
import {
  TilingSelectAtcProcedureRequest,
  useGetZipCodeDetails,
} from "services";
import { useDebounceValue } from "usehooks-ts";
import { FormatedShippingResponseType } from "utils/shipping";

interface ICalculateTaxDMVShipping {
  vin: string;
  listing?: any;
}
export const CalculateTaxDMVShipping: React.FC<ICalculateTaxDMVShipping> = ({
  vin,
  listing,
}) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const userId = getUserId();
  const token = localStorage.getItem("access_token");
  const [priceDataForOffer, setPriceDataForOffer] = useState<any>({});
  // const [isOfferSent, setIsOfferSent] = useState<boolean>(false);
  const { data: sellerData } = useQueryGetSellerDetailsByListingId(id || "");
  const [place, setPlace] = useState({
    city: "",
    county: "",
    state: "",
    stateAbbr: "",
  });
  const [zipValue, setZipValue] = useState("");
  const [debouncedValue] = useDebounceValue(zipValue, 500);

  // DMV relate hooks
  const {
    mutateAsync: selectAtcProcedureMutateAsync,
    // data: atcProcedureData,
    isPending: isPendingToAtcProcedure,
  } = useSelectAtcProcedure();
  const {
    mutateAsync: mutateFeeQuotesData,
    isPending: isPendingToFeeQuotes,
    // data: feeQuotesData,
    error: dmvError,
  } = useGetATCFeeQuotesListing(id || "", vin || "");

  //shipping related hooks
  const {
    mutateAsync: mutateAsyncGetShippingQuotes,
    isPending: isPendingToGetShippingInfo,
    error: shippingError,
  } = useGetShippingQuotesInfoPublic();

  const {
    mutateAsync: zipcodeMutateAsyncDealer,
    data: zipcodeDataDealer,
    isPending: isDealerPending,
  } = useGetZipCodeDetails(sellerData?.zipCode || "");
  // offer related hooks

  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
    isPending: isPendingToFindZipCode,
  } = useGetZipCodeDetails(debouncedValue);

  useEffect(() => {
    const fetchZipCode = async () => {
      await zipcodeMutateAsync();
    };
    fetchZipCode();
  }, [zipcodeMutateAsync, debouncedValue]);

  useEffect(() => {
    if (zipcodeData && !isPendingToFindZipCode) {
      setPlace({
        city: zipcodeData?.city ?? "",
        county: zipcodeData?.county ?? "",
        stateAbbr: zipcodeData?.stateAbbr ?? "",
        state: zipcodeData?.state ?? "",
      });
    }
  }, [isPendingToFindZipCode, zipcodeData]);

  useEffect(() => {
    if (sellerData?.zipCode) {
      zipcodeMutateAsyncDealer();
    }
  }, [sellerData?.zipCode]);

  const handleCalculate = async (formData: any) => {
    debugger
    if (!zipcodeData || !zipcodeDataDealer) {
      return 0;
    }
    try {
      let resShip: FormatedShippingResponseType | null = null;
      if (formData.isShippingNeeded) {
        resShip = await mutateAsyncGetShippingQuotes({
          vin: vin,
          listingId: +(id || 0),
          shippingZip: sellerData?.zipCode ?? "",
          shippingStreetAddress: "",
          destinationZip: zipValue,
          destinationStreetAddress: "",
          estimatedShipDate: new Date().toISOString(),
          desiredEstimatedShipDate: "",
          ship: zipcodeDataDealer,
          dest: zipcodeData,
          carYear: listing.year,
          make: listing.make,
          model: listing.model,
        }).catch((e) => {
          console.error("error : ", e);
          return null;
        });
      }
      let data;
      const payload: TilingSelectAtcProcedureRequest = {
        listingId: +(id || 0),
        quoteId: 0,
        tier1: true,
        tier2: false,
        tier3: false,
        userId: userId ? userId : 0,
      };
      if (token) {
        data = await selectAtcProcedureMutateAsync(payload);
      }

      const vehicle = listing;
      const basePrice = formData.userOffer;
      const dmvData = await mutateFeeQuotesData({
        place,
        zipValue,
        qId: token ? data?.quoteId : "1",
        basePrice,
        vehicle,
      });

      const salesTax = Number(
        dmvData?.feeDetails?.feeDetail?.find(
          (item) => item.feeName === "State Tax"
        )?.amount || "0"
      );
      const totalDmvFee = Number(dmvData?.feeTotal || "0");
      const dmvfees = totalDmvFee - salesTax;

      const totalShipping = resShip?.TotalPrice || 0;

      const totalPrice =
        Number(totalShipping) + Number(formData.userOffer) + totalDmvFee;
      setPriceDataForOffer({
        shipping: {
          label: "$" + totalShipping.toFixed(2),
          value: totalShipping,
        },
        dmv: {
          sales: { value: salesTax, label: "$" + salesTax.toFixed(2) },
          fees: { value: dmvfees, label: "$" + dmvfees.toFixed(2) },
        },
        total: totalPrice,
      });

      dispatch(changeForSellerOfferPrice({ totalPrice, vin }));
    } catch (e) {
      console.log("Error :", e);
    }
  };


  return (
    <Form
      id="new-order-shipping-form"
      schema={SendSellerCashOfferSchema}
      onSubmit={handleCalculate}
      className=""
    >
      {({
        formState: { errors, isSubmitted },
        register,
        getValues,
        setValue,
      }) => {
        return (
          <Card className="bg-[#f1f1f180] pb-3" shadow="sm">
            <CardBody
              className={`grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-8 md:px-12 md:my-5  `}
            >
              <div className="flex flex-col  gap-2">
                <Typography
                  variant="custom"
                  className=" text-xs sm:text-medium font-[600]"
                >
                  Enter Offer
                </Typography>
                <Input
                  isInvalid={!!errors["userOffer"]}
                  variant="form"
                  size="compact"
                  radius="base"
                  classNames={{
                    base: "w-full",
                    inputWrapper: !!errors["userOffer"]
                      ? "h-[62px] [background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)] "
                      : "border-black h-[62px]",
                  }}
                  {...register("userOffer")}
                />
              </div>
              <div className="flex flex-col  gap-2">
                <Typography
                  variant="custom"
                  className="text-xs sm:text-medium font-[600]"
                >
                  Zip Code
                </Typography>
                <Input
                  isInvalid={!zipcodeData && isSubmitted}
                  variant="form"
                  size="compact"
                  radius="base"
                  maxLength={5}
                  classNames={{
                    base: "w-full",
                    inputWrapper:
                      !zipcodeData && isSubmitted
                        ? "h-[62px] [background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                        : "h-[62px] border-black",
                  }}
                  {...register("userZip")}
                  onChange={(e) => setZipValue(e.target.value)}
                />
              </div>
              <div className="flex">

              <Button
                size="lg"
                className="bg-gradient-to-r mt-auto  from-[#018ffd] to-[#070c84] text-white text-xs sm:text-lg py-8 w-full  tracking-wide px-6 rounded-xl "
                type="submit"
                isLoading={isPendingToGetShippingInfo || isPendingToFeeQuotes}
                >
                Calculate
              </Button>
                </div>
              <div className="bg-white border border-[#018ffd] text-center rounded-2xl flex flex-col items-center">
                <Typography
                  variant="label"
                  className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg"
                >
                  Sales Tax
                </Typography>
                <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                <Input
                  variant="bordered"
                  disabled
                  classNames={{
                    inputWrapper: "border-0",
                    input: "text-center",
                  }}
                  value={
                    priceDataForOffer?.dmv?.sales?.label ||
                    (isPendingToAtcProcedure ||
                    isPendingToFeeQuotes ||
                    isPendingToGetShippingInfo
                      ? "..."
                      : "")
                  }
                />
              </div>
              <div className="bg-white border border-[#018ffd]  text-center rounded-2xl flex flex-col items-center">
                <Typography
                  variant="label"
                  className="p-3 text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xs sm:text-lg"
                >
                  Dmv & fees
                </Typography>
                <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                <Input
                  variant="bordered"
                  disabled
                  classNames={{
                    inputWrapper: "border-0",
                    input: "text-center",
                  }}
                  value={
                    priceDataForOffer?.dmv?.fees?.label ||
                    (isPendingToAtcProcedure ||
                    isPendingToFeeQuotes ||
                    isPendingToGetShippingInfo
                      ? "..."
                      : "")
                  }
                />
              </div>
              <div className="bg-white text-center rounded-2xl flex flex-col items-center border border-[#018ffd] ">
                <Typography
                  variant="label"
                  className="py-3 md:px-3 text-xs sm:text-lg text-transparent bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text md:text-lg lg:text-sm min-[1118px]:text-lg xl:text-sm min-[1298px]:text-lg"
                >
                  <span>Shipping Quote</span>
                  <Checkbox
                    type="checkbox"
                    className="ml-1 md:ml-2 px-1 sm:px-2"
                    onChange={(e) => {
                      setValue("isShippingNeeded", e.currentTarget.checked);
                    }}
                  />
                </Typography>
                <div className="bg-gradient-to-r from-[#018ffd] to-[#070c84] h-[1px] w-full " />
                <Input
                  variant="bordered"
                  disabled
                  value={
                    priceDataForOffer?.shipping?.label ||
                    (isPendingToAtcProcedure ||
                    isPendingToFeeQuotes ||
                    isPendingToGetShippingInfo
                      ? "..."
                      : "")
                  }
                  classNames={{
                    inputWrapper: "border-0",
                    input: "text-center",
                  }}
                />
              </div>
            </CardBody>
            
            {!zipcodeDataDealer && !isDealerPending && (
              <div className="flex flex-row gap-2 mr-2 justify-center bottom-1">
                <Image src="/assets/icons/error-outline.svg" alt="error" />
                <TextErrorHelper>
                  &nbsp; {"Listing Does not have Seller zip information"}
                </TextErrorHelper>
              </div>
            )}
            {(shippingError?.message ||
              dmvError?.message 
             ) && (
              <div className="flex flex-row gap-2 mr-2 justify-center bottom-1">
                <Image src="/assets/icons/error-outline.svg" alt="error" />
                <TextErrorHelper>
                  &nbsp;{" "}
                  {(getValues("isShippingNeeded") && shippingError?.message) ||
                    dmvError?.message 
                    }
                </TextErrorHelper>
              </div>
            )}
          </Card>
        );
      }}
    </Form>
  );
};
