import { Typography } from "components/typography";
import { useNavigate } from "react-router-dom";

export const AuthFooter = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center mt-24 pl-5">
      <Typography
        variant="custom"
        className="text-xs text-[#263238] font-[500] mb-5"
      >
        © {year}{" "}
        <a
          onClick={() => navigate("")}
          className="text-[#018ffd] cursor-pointer"
        >
          findmyoptions.com
        </a>{" "}
        LLC, All Rights Reserved.
      </Typography>
    </div>
  );
};
