import { money } from "utils";
import { env } from "config/env";
import { Button, Typography } from "components";
import { useEffect, useState } from "react";
import {
  HistoryDataResponse,
  useGetListingDetails,
  useGetPriceAdvanceDataPdf,
  useGetVehicleHistoryData,
} from "services";
import { transformData } from "utils/transform-data";
import { CardBody, Image, Card } from "@nextui-org/react";
import { ViewIcon } from "modules/shared/components/car-card/ViewIcon";
interface SaveReportsDetailsProps {
  listingId: string;
  vin: string;
  handleView?: (
    pdfUrl: string | null,
    factoryData: any,
    vehicleHistoryData: HistoryDataResponse | undefined,
    errorVehicleHistoryData: any
  ) => void;
}

export default function SaveReportsDetails({
  listingId,
  vin,
  handleView,
}: SaveReportsDetailsProps) {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [factoryData, setFactoryData] = useState<any>({});
  const { data: listingDetailsData } = useGetListingDetails(
    Number(listingId) ?? 0
  );
  const { data: vehicleHistoryData, error: errorVehicleHistoryData } =
    useGetVehicleHistoryData(vin);
  const { data: priceAdvanceData } = useGetPriceAdvanceDataPdf(vin);

  useEffect(() => {
    if (priceAdvanceData) {
      const blob = new Blob([priceAdvanceData], { type: "application/pdf" });
      setPdfUrl(URL.createObjectURL(blob));
    }
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [priceAdvanceData]);

  useEffect(() => {
    if (vehicleHistoryData)
      setFactoryData(transformData(vehicleHistoryData) || {});
  }, [vehicleHistoryData]);

  return (
    <Card
      className="border border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors rounded-2xl"
      shadow="none"
      fullWidth
    >
      <CardBody className="flex flex-col">
        <div className="bg-white rounded-3xl h-full w-full">
          {/* <div className="col-span-2">
            <Image
              removeWrapper
              src={`${env.BASE_API_URL}Listings/image/${listingDetailsData?.listing?.listingId}`}
              alt="car"
              // onClick={() =>
              //   onClick && onClick(listingDetailsData)
              // }
              className="w-full h-56 xl:h-40 2xl:h-48 object-cover cursor-pointer"
            />
          </div> */}
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="pt-4 text-xl sm:text-sm lg:text-xl xl:text-base text-center font-[800] text-[#263238] min-h- overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {listingDetailsData?.listing?.year ?? ""}{" "}
              {listingDetailsData?.listing?.make ?? ""}{" "}
              {listingDetailsData?.listing?.model ?? ""}
            </Typography>
            {/* <Typography
              variant="custom"
              className="text-center text-md font-[600] text-[#263238] py-1 min-h-7"
            >
              {listingDetailsData?.listing?.trim ?? ""}
            </Typography> */}

            {/* <Typography
              variant="custom"
              className="font-bold text-center text-xl  text-[#263238] h-8  sm:text-sm lg:text-xl xl:text-base "
            >
              4 door 4WD
            </Typography> */}
            <Typography
              variant="custom"
              className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm uppercase"
            >
              VIN - {vin}
            </Typography>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center mt-auto gap-4 ">
          <ViewIcon
            onClick={() => {
              handleView &&
                handleView(
                  pdfUrl,
                  factoryData,
                  vehicleHistoryData,
                  errorVehicleHistoryData
                );
            }}
            className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
          />
        </div>
      </CardBody>
    </Card>
    // <tr
    //   className="bg-white text-black border-b border-[#C9C9C9] text-base md:text-xl font-semibold"
    //   key={listingId}
    // >
    //   <th
    //     scope="row"
    //     className="px-6 py-4 text-center border-l border-[#C9C9C9]  whitespace-nowrap"
    //   >
    //     {listing?.vin === "undefined"
    //       ? "N/A"
    //       : listing?.vin.toUpperCase() ?? vin}
    //   </th>
    //   <td className="px-6 py-4 text-center border-l border-[#C9C9C9]">
    //     {listing?.make === "undefined" ? "N/A" : listing?.make}
    //   </td>
    //   <td className="px-6 py-4 text-center border-l border-[#C9C9C9]">
    //     {listing?.model === "undefined" ? "N/A" : listing?.model}
    //   </td>
    //   <td
    //     onClick={() => {
    // handleView &&
    //   handleView(
    //     pdfUrl,
    //     factoryData,
    //     vehicleHistoryData,
    //     errorVehicleHistoryData
    //   );
    //     }}
    //     className="overflow-hidden max-w-64  h-16 text-center border-l border-[#C9C9C9] cursor-pointer "
    //   >
    //     <p className="text-transition-dvm">View</p>
    //   </td>
    // </tr>
  );
}

// import React from "react";
// import { useGetListingDetails } from "services";

// export default function SaveReportsDetails({ listingId }: any) {
//   const { data: listingDetailsData } = useGetListingDetails(
//     Number(listingId) ?? 0
//   );
//   const listing = listingDetailsData?.listing;
//   return (
//     <tr
//       className="bg-white text-black border-b border-[#C9C9C9] text-base md:text-xl font-semibold"
//       key={listingId}
//     >
//       <th
//         scope="row"
//         className="px-6 py-4 text-center border-l border-[#C9C9C9]  whitespace-nowrap"
//       >
//         {listing?.vin === "undefined" ? "N/A" : listing?.vin.toUpperCase()}
//       </th>
//       <td className="px-6 py-4 text-center border-l border-[#C9C9C9]">
//         {listing?.make === "undefined" ? "N/A" : listing?.make}
//       </td>
//       <td className="px-6 py-4 text-center border-l border-[#C9C9C9]">
//         {listing?.model === "undefined" ? "N/A" : listing?.model}
//       </td>
//       <td
//         onClick={() => {}}
//         className="overflow-hidden max-w-64  h-16 text-center border-l border-[#C9C9C9] cursor-pointer "
//       >
//         <p className="text-transition-dvm">View</p>
//       </td>
//     </tr>
//   );
// }
