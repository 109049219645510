import { jwtDecode } from "jwt-decode";
import { localStorageFn } from "./localstorage";
import { safeDecode } from "./jwt";
export const getUserId = () => {
  const token = localStorage.getItem("access_token");
  let decoded: UserData = {};
  if (token) {
    decoded = jwtDecode(token);
  }
  return Number(decoded?.nameid) ?? 1;
};

export const getCurrUsername = () => {
  const token = localStorage.getItem("access_token");
  let decoded: UserData = {};
  if (token) {
    decoded = jwtDecode(token);
  }
  return decoded?.unique_name ?? "";
};
interface UserData {
  isTenant?: string;
  email?: string;
  exp?: number;
  iat?: number;
  nameid?: string;
  nbf?: number;
  role?: string;
  unique_name?: string;
}

export const getUserRoles = () => {
  const userToken: any = String(localStorageFn.getItem("access_token"));
  const userData: any = safeDecode(userToken);

  return userData?.role || "";
};


export const isThisDealer = () => {
  const userToken: any = String(localStorageFn.getItem("access_token"));
  const userData: any = safeDecode(userToken);

  return userData?.role === "Dealer";
};


export const getUserCaimData = (key : keyof UserData) => {
  const userToken: any = String(localStorageFn.getItem("access_token"));
  const userData: any = safeDecode(userToken);

  return  userData? userData[key] : "";
};