import { useMutation } from "@tanstack/react-query";
import { changeUserName } from "modules/dealer/store/user-information";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DealerLoginSchema,
  DealerRegisterSchema,
  UserLoginSchema,
  UserRegisterSchema,
} from "schemas/auth.schema";
import { api, authApi } from "utils/api";
import { safeDecode } from "utils/jwt";
import { localStorageFn } from "utils/localstorage";
import { z } from "zod";

// User Login Mutation
type UserLoginSchemaType = z.infer<typeof UserLoginSchema>;

type UserLoginApiResponse = {
  accessToken: string;
  refreshToken: string;
};

const loginUser = async (
  data: UserLoginSchemaType
): Promise<UserLoginApiResponse> => {
  return api.post("User/authenticate", { json: data }).json();
};

const setUserData = async (id: any, role: string, dispatch: any) => {
  try {
    const response: any = await authApi
      .get(`User/Get${role}ById?id=${id}`)
      .json();
    localStorageFn.setItem("firstName", response?.firstName ?? "");
    await dispatch(changeUserName(response?.firstName));
  } catch {
    localStorageFn.setItem("firstName", "");
    throw new Error("User not found");
  }
};

export function useLoginUserMutation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      const token: any = safeDecode(data.accessToken);
      if (token.role === "Dealer") {
        throw new Error("Not allowed to Access");
      }
      localStorageFn.setItem("access_token", data.accessToken);
      localStorageFn.setItem("refresh_token", data.refreshToken);
      await setUserData(token?.nameid, token?.role, dispatch);
      navigate("/user/dashboard");
    },
  });
}

// User Register Mutation
type UserRegisterSchemaType = z.infer<typeof UserRegisterSchema>;

type UserRegisterApiResponse = {
  status: number;
  message: string;
};

const registerUser = async (
  data: UserRegisterSchemaType
): Promise<UserRegisterApiResponse> => {
  return api.post("User/registeruser", { json: data }).json();
};

export function useRegisterUserMutation() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: registerUser,
    onSuccess: (data) => {
      navigate("/login/user");
    },
  });
}

// Dealer Login Mutation
type DealerLoginSchemaType = z.infer<typeof DealerLoginSchema>;

type DealerLoginApiResponse = {
  accessToken: string;
  refreshToken: string;
};

const loginDealer = async (
  data: DealerLoginSchemaType
): Promise<DealerLoginApiResponse> => {
  return api.post("User/authenticate", { json: data }).json();
};

export function useLoginDealerMutation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: loginDealer,
    onSuccess: async (data) => {
      const token: any = safeDecode(data?.accessToken);

      if (token.role === "User") {
        throw new Error("Not allowed to Access");
      }
      localStorageFn.setItem("access_token", data.accessToken);
      localStorageFn.setItem("refresh_token", data.refreshToken);
      await setUserData(token?.nameid, token?.role, dispatch);
      navigate("/user/dashboard");
    },
  });
}

// Dealer Register Mutation
type DealerRegisterSchemaType = z.infer<typeof DealerRegisterSchema>;

type DealerRegisterApiResponse = {
  status: number;
  message: string;
};

const registerDealer = async (
  data: DealerRegisterSchemaType
): Promise<DealerRegisterApiResponse> => {
  return api.post("User/register", { json: data }).json();
};

export function useRegisterDealerMutation() {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: registerDealer,
    onSuccess: async (data: any) => {
      console.log(data);

      localStorageFn.setItem("tier_token", data.token);
    },
  });
}
