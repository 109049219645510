const dealerDashboardTools = {
  paymentCalculator: "/dealer-dashboard/tools/payment-calculator",
};

export const PATH_APP = {
  tools: dealerDashboardTools,
};

export const PATH_AUTH = {};
export const PATH_ADMIN = {};
