import React, { useEffect, useState } from "react";
import "./EnterVIN.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useGetCurrentUserForBoth } from "services";
import { getUserId, isThisDealer } from "utils/get-user-id";
import {
  useAddPaymenTransactiontMethod,
  useGetCreditCardMethodStatusData,
} from "hooks/api/mutations/shipping/use-shipping-quotes";

import { PaymentModal } from "pages/shared";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import Input from "components/ui/input";
import { Toggle, Typography } from "components";
import { Button } from "@nextui-org/react";
import BulkRowCreateList from "./components/BulkRow.component";

export interface ListingRowType {
  id: number;
  vin: number;
  milage: number;
  price: number;
  report: boolean;
  files: File[] | null;
}

const InitRow: ListingRowType = {
  id: 1,
  vin: 0,
  milage: 0,
  price: 0,
  report: false,
  files: null,
};
const BulkEnterVIN = () => {
  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [listRows, setListRows] = useState<ListingRowType[]>([
    InitRow,
    { ...InitRow, id: 2 },
  ]);
  const [showPayModal, setShowPayModal] = useState<number | null>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: dealearData } = useGetCurrentUserForBoth(
    getUserId(),
    isThisDealer()
  );
  //payment related hooks
  const { data: checkIfPaymentExist } = useGetCreditCardMethodStatusData(true);

  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
  } = useAddPaymenTransactiontMethod();

  const handleConfirmPaymentForOpt = async (e: any) => {
    try {
      await setStep(3);

      if (!checkIfPaymentExist) {
        await mutateAddPaymenTransactiontMethod(e);
        // data=await mutateProcessFMOReportSubscription("FMO_OPTIONS_DATA_VALUE");
      }

      await setShowPayModal(2);
      // refetchPriceAdvanceData();
    } catch (e) {
      console.log("Error :", e);
    }
  };

  useEffect(() => {
    let interval: number;
    if (step === 1) {
      let interval = setInterval(() => {
        if (progress < 100) {
          setProgress((p) => p + 20);
        } else {
          clearInterval(interval);
          setProgress(100);
        }
      }, 500);
    }
    return () => clearInterval(interval);
  }, [progress, step]);

  // useEffect(()=>{
  //   if(progress>=99 && listingId){
  //   navigate(
  //     `/${isThisDealer()?"dealer":"user"}/create-listing/${vinNumber.data}/${mileageData?.mileage}/${listingId}`,{state:{ price:priceData.price}}
  //   );}
  // },[progress,listingId])

  const fetchVinData = async () => {
    if (!checkIfPaymentExist) {
      setShowPayModal(1);
      return 0;
    }
    try {
    } catch (error) {
      console.error("Error fetching VIN data:", error);
    }
  };

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {};

  const toggleItem = (id: number) => {};

  // const isSidebarOpen = document
  //   .querySelector("#application-sidebar")
  //   ?.getAttribute("data-open");

  return (
    <React.Fragment>
      {/* <NavBar type={"enter-scan"}> */}
      <div className="flex items-center m-auto enter-vin w-full">
        <div className="flex items-center mx-auto bg-fixed w-full bg-center bg-cover h-[calc(100vh-9.5rem)] h-max-[42rem]">
          <form className="flex flex-col px-2 md:px-8 py-12 rounded-lg  shadow-md  gradiant-box-vin overflow-y-auto smooth-scroll scrollbar-hide h-full  w-full">
            {step === 1 ? (
              <>
                <div
                  style={{ zIndex: 100, height: "108%", overflow: "hidden" }}
                  className="absolute inset-0 bg-black opacity-50 z-999 md:w-full w-[108%] "
                ></div>
                <div
                  style={{ zIndex: 101 }}
                  className="relative gradiant-modal-loading h-[450px] py-24 px-10  md:px-24  mx-auto  rounded-lg shadow-md "
                >
                  <div className="flex justify-center mb-4 text-sm font-bold text-white md:text-2xl">
                    Searching for Data
                  </div>
                  <div className="flex justify-center mb-16 text-lg font-bold text-white md:text-4xl">
                    {"ALL VIN numbers"}
                  </div>

                  <div className="flex justify-center mb-2">
                    <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-xs">
                      {" "}
                      Please wait while findmyoptions.com queries your VIN
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div className="loader-container">
                      <div
                        className="loader-progress"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full  px-2 md:px-6">
                <div className=" hidden md:flex  w-full gap-1 justify-around mb-4  text-white">
                  <div className="text-center px-2 w-full w-min-[30%]">
                    Enter VIN
                  </div>
                  <div className="text-center px-2 w-full w-min-[30%]">
                    Enter Price
                  </div>

                  <div className="text-center px-2 w-full w-min-[30%] ">
                    Enter Mileage
                  </div>
                  <div className=" text-center  w-full w-min-[7%]">
                    History/Options Report
                  </div>
                  <div className="text-center  w-full w-min-[3%]">
                    Upload Pictures
                  </div>
                </div>
                {listRows.map((item) => {
                  return (
                    <BulkRowCreateList
                      key={item.id}
                      item={item}
                      handleToggle={(e) => {
                        const tempState = [...listRows];
                        tempState[item.id - 1].report = e.target.checked;
                        setListRows(tempState);
                      }}

                      handleFileChange={(files) => {
                        const tempState = [...listRows];
                        tempState[item.id - 1].files = files;
                        setListRows(tempState);
                      }}
                    />
                  );
                })}
              </div>
            )}
            <div className="px-2 md:px-8 flex justify-center md:justify-start gap-4 mb-4">
              <Button
                className="px-2 text-[#018FFD]  rounded-full  shadow-sm shadow-white  bg-white"
                onClick={() => {
                  setListRows((p) => [...p, { ...InitRow, id: p.length + 1 }]);
                }}
              >
                Add +
              </Button>
              {listRows.length > 2 && (
                <Button
                  className="px-2 text-[#018FFD]  rounded-full  shadow-sm shadow-white  bg-white"
                  onClick={() => {
                    setListRows((p) => {
                      const temp = [...p];
                      temp.pop();
                      return temp;
                    });
                  }}
                >
                  Remove -
                </Button>
              )}
            </div>
            <div className="px-2 md:px-8  mt-auto flex flex-col md:flex-row  gap-2 justify-between items-center">
              <Typography className="text-white text-xs md:text-sm text-center ">
                History/Options Reports will result in $4.99/car charges to your
                credit card.{" "}
              </Typography>
              <Button className="bg-[#018FFD]  p-6 text-base  md:text-xl  border-1 shadow-sm shadow-gray-400 border-white text-white">
                Create Listings
              </Button>
            </div>
          </form>
        </div>
        <PaymentModal
          isOpen={showPayModal === 1}
          isPublic={true}
          onOpen={() => {
            setShowPayModal(0);
          }}
          Component={
            <PaymentFormWithDetails
              handleNext={handleConfirmPaymentForOpt}
              // payAmmount={"0.99"}
              showAmmount={false}
              savedMethod={checkIfPaymentExist}
              isPending={isPendingToAddPaymenTransactiontMethod}
            />
          }
        />
      </div>
      {/* </NavBar> */}
    </React.Fragment>
  );
};

export default BulkEnterVIN;
