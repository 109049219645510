import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";
import { Button } from "@nextui-org/react";

import "../../../../../modules/dealer/components/dmv-title/DmvTitle.scss";
import { SelectVehicleModal } from "../../../../dealer/dmv-titling/select-vehicle-modal.component";
import {
  ListingListResponse,
  useGetListingList,
  useSearchListingList,
} from "services";
import { Typography } from "components";
import Loading from "components/Loading/Loading";
import { getUserId } from "utils/get-user-id";
import { useDispatch } from "react-redux";
import {
  dmvDocumentServiceSignal,
  dmvtitlingSignal,
} from "pages/dealer/dmv-titling/store";
import { changeCarForShipping } from "modules/dealer/store/shipping";
import SearchCarCard from "modules/shared/components/car-card/SearchCarCard";
import { env } from "config/env";

interface Props {
  fromShipping: boolean;
}

const UserSelectVehicle = ({ fromShipping }: Props) => {
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState<ListingListResponse[]>([]);
  const [debouncedValue] = useDebounceValue(searchValue, 500);
  const dispatch = useDispatch();
  const { data: dataListing, isLoading: isLoadingList } = useGetListingList(
    getUserId(),
    1,
    1,
    20
  );
  useEffect(() => {
    setSearchData(dataListing || []);
    dmvtitlingSignal.value = null;
    dmvDocumentServiceSignal.value = null;
  }, [dataListing]);

  const { data: dataSearchListing, isLoading: isLoadingSearch } =
    useSearchListingList(debouncedValue);
  useEffect(() => {
    setSearchData(dataSearchListing || []);
    if (searchValue.length === 0) {
      setSearchData(dataListing || []);
    }
  }, [dataListing, dataSearchListing, searchValue.length]);

  const changeOpenConfirmationModal = (
    name: string,
    vin: string,
    listingId: number,
    year: string,
    make: string,
    model: string
  ) => {
    setSelectedData({ name, vin, listingId });
    if (fromShipping) {
      dispatch(changeCarForShipping({ name, vin, listingId ,year: year, make,model }));
      navigate("/user/shipping-quotes/new-order");
    } else {
      setOpenConfirmationModal((prev) => !prev);
    }
  };

  if (isLoadingList || isLoadingSearch) return <Loading />;

  return (
    <div className="flex dmv-title mb-20">
      <div className="w-full">
        <Typography variant="pageTitle">Select Vehicle</Typography>
        <p className="py-3 mb-6 text-xl text-center">
          If your vehicle is not currently in your inventory/garage please add
          it first.
        </p>
        <div className="grid grid-cols-3 gap-2 sm:gap-5">
          <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-xs sm:text-lg tracking-wide rounded-xl px-2 sm:px-16 w-full h-[54px] md:w-[190px] mb-4"
            type="button"
            size="lg"
            onClick={() => navigate("/user/enter-vin")}
          >
            Add Vehicle
          </Button>
          <form className="col-span-2 md:flex md:justify-end">
            <div className="relative">
              <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 text-sm text-gray-900 border border-gray-300 ps-10 rounded-xl bg-gray-50 focus:ring-blue-500 focus:border-blue-500 md:w-[400px]"
                placeholder="Search Keywords"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                required
              />
            </div>
          </form>
        </div>

        <div className="col-span-4  pb-20 overflow-y-auto mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 gap-4">
            {searchData?.map((car) => {
              return (
                <SearchCarCard
                  line1={`${car?.year || ""} ${car.make} ${car.model}`}
                  line2={car.trim}
                  line3={`VIN - ${car.vin}`}
                  imgURl={`${env.BASE_API_URL}Listings/image/${car.listingId}`}
                  car={car}
                  onSelect={() =>
                    changeOpenConfirmationModal(
                      car.name,
                      car.vin,
                      car.listingId,
                      car.year,
                      car.make,
                      car.model
                    )
                  }
                  cardOnClick={() =>
                    changeOpenConfirmationModal(
                      car.name,
                      car.vin,
                      car.listingId,
                      car.year,
                      car.make,
                      car.model
                    )
                  }
                />
              );
            })}
          </div>
        </div>
        <SelectVehicleModal
          open={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false)}
          selectedData={selectedData}
        />
      </div>
    </div>
  );
};

export default UserSelectVehicle;
