import React from "react";
import { Typography } from "components";

export default function PurchaseCompletedComponent() {

  return (
    <div className="flex items-center gap-4 flex-col justify-center my-auto w-full h-full  p-4 md:p-10 pb-20">
    <Typography
      variant="custom"
      className="text-center  text-xl text-white font-bold  m-auto md:text-3xl "
    >
      Purchase Completed!
    </Typography>
    <Typography
      variant="custom"
      className="text-center  text-xl text-white  m-auto md:text-2xl "
    >
      Thank you for using findmyoptions.com for the purchase of your
      vehicle! At this time no further work on your end it required!{" "}
    </Typography>
    <Typography
      variant="custom"
      className="text-center  text-xl text-white  m-auto md:text-2xl "
    >
      Congratulations and enjoy your new vehicle!
    </Typography>
    <Typography
      variant="custom"
      className="text-center  text-xl text-white  m-auto md:text-2xl "
    >
      Please don’t forget to spread the word about our website!{" "}
    </Typography>
  </div>
  );
}
