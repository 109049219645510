import { Typography } from "components";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import {
  listOptionsTraditionalFinancingWHY,
  listOptionsTraditionalFinancingGettingStarted,
} from "./traditional-financing.data";
import BenefitsOfTraditionalFinancing from "./components/cards.component";
import BusinessmanDrivingImg from "./assets/Businessmandriving.png"

function TraditionalFinancing() {
  return (
    <div className="flex flex-col gap-16 py-12 ">
      <div className="flex flex-col items-center justify-center gap-4 text-center">
        <Typography variant="pageTitle">Traditional Financing</Typography>
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[rgb(7,12,132)] text-transparent bg-clip-text">
          Secure Financing for Your New Car with Competitive Rates at our
          participating Dealers!
        </h2>
        <p className="font-[Metropolis] font-[600] text-left">
          We understand that financing a new car is a crucial step in the buying
          process. That's why our partnered dealerships offer a variety of
          traditional financing options through a network of trusted lenders,
          ensuring you get the best possible rates and terms to fit your budget.
        </p>
      </div>

      <Typography variant="custom" className=" ">
        <p className="pb-5 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl 2xl:text-3xl font-bold pt-5 text-left">
          Why Choose Traditional Financing?
        </p>
        {listOptionsTraditionalFinancingWHY.map((res) => (
          <div className="flex items-start  ">
            <img
              src={checkIcon}
              height={16}
              alt="Y"
              width={16}
              className="mt-3.5"
            />

            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {res.name}
              </span>
              {res.description}
            </p>
          </div>
        ))}
      </Typography>

      <BenefitsOfTraditionalFinancing />
      <Typography variant="custom" className=" ">
        <p className="pb-5 bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl 2xl:text-3xl font-bold pt-5 text-left">
          Getting Started with Traditional Financing:
        </p>
        <p>
          Ready to unlock the possibilities of owning your dream car? Here's how
          to get started with traditional financing at findmyoptions!
        </p>
        {listOptionsTraditionalFinancingGettingStarted.map((res) => (
          <div className="flex items-start  ">
            <img
              src={checkIcon}
              height={16}
              alt="Y"
              width={16}
              className="mt-3.5"
            />

            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {res.name}
              </span>
              {res.description}
            </p>
          </div>
        ))}
      </Typography>
      <div className=" w-full h-96 my-12">
        <div className=" flex absolute w-full h-96 right-0 from-[#018ffd] -z-10 to-[#070c84] text-white bg-gradient-to-r" >
        <img alt="drive" src={BusinessmanDrivingImg} className="w-1/2 hidden sm:block relative -top-16 max-w-[32rem] " />
        <div className="w-full sm:w-1/2 z-20  flex items-center justify-center text-center  right-0  relative -top-8">
          <Typography variant="custom" className="text-white p-8 flex flex-col justify-center items-center">

            <h1 className="text-lg md:text-2xl font-bold mb-4 mt-12">Drive Away Confidently</h1>

            <p className="text-left">
              With competitive rates, flexible terms, and the potential to
              improve your credit score, traditional financing can be a smart
              choice for your car purchase. Let us help you navigate the road to
              car ownership. Create an account today to explore your traditional
              financing options and get behind the wheel of your dream car!
            </p>
          </Typography>
        </div>
        </div>
      </div>
    </div>
  );
}

export default TraditionalFinancing;
