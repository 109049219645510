//RMF- ray
import * as React from "react";
import { useParams } from "react-router-dom";
import { signal } from "@preact/signals-react";

import { Typography } from "components";
import Input from "components/ui/input";

import {
  RetailValueEstimate,
  VehicleHistorySnapshot,
  VehicleListingDescription,
  capitalizeWords,
} from "pages/shared";

import {
  ListingDataRequest,
  ListingDescriptionRequest,
  useGetListingAdvanceData,
  useGetListingDescription,
  useGetListingDetails,
  useGetListingPriceData,
  useGetVehicleDescription,
  useGetVindataDecode,
  useUpdateListingData,
} from "services";

import Form from "components/ui/form";

import { findVehicleBy } from "utils/find-vehicle-by";
import Loading from "components/Loading/Loading";

import { capitalizeAllBeforeDash, capitalizeForDashOrWhiteSpace } from "utils";
import { signalhasDoneBulkUpload } from "pages/dealer/manage-listing/upload-photos.component";
import { ManageListingValidationSchema } from "pages/dealer/manage-listing/manage-listing.validation";
import { AccordionCarData } from "pages/dealer/manage-listing/accordion-car-data.component";
import { ActionListing } from "pages/dealer/manage-listing/action-listing.component";
import { ProfitLossCalculator } from "pages/dealer/manage-listing/profit-loss-calculator.component";

export const signalHasClickedMakeLiveListing = signal(false);
const UserManageListingPage = () => {
  const params = useParams();
  const [period, setPeriod] = React.useState(180);
  const [mileage, setMileage] = React.useState<string | null>(null);

  const { data: listingDetailsData } = useGetListingDetails(
    Number(params?.listingId) ?? 0
  );
  const listingAdvanceData = useGetListingAdvanceData(params?.vin ?? "");
  const listing = listingDetailsData?.listing;
  const { data: priceData, refetch } = useGetListingPriceData(
    period,
    params?.vin ?? "",
    mileage ?? "0"
  );

  React.useEffect(() => {
    refetch();
  }, [refetch, period, mileage]);

  const {
    data: listingDescriptionData,
    mutateAsync: mutateAsyncListingDescription,
  } = useGetVehicleDescription();

  const {
    mutateAsync: mutateAsyncUpdateListingData,
    isPending: isPendingUpdateListing,
  } = useUpdateListingData();

  const handleMileageChange = (e: {
    target: { value: React.SetStateAction<string | null> };
  }) => {
    setMileage(e.target.value);
  };

  const { data: vinDecodeData, isLoading } = useGetVindataDecode(
    params?.vin ?? ""
  );
  const message = vinDecodeData?.message;

  const make = findVehicleBy(message ?? [], "Make")?.value ?? "";
  const model = findVehicleBy(message ?? [], "Model")?.value ?? "";
  const year = findVehicleBy(message ?? [], "ModelYear")?.value ?? "";
  const trim =
    findVehicleBy(message ?? [], "Trim")?.value ??
    findVehicleBy(message ?? [], "Series")?.value ??
    "";

  const nameWithTrim = `${year} ${capitalizeForDashOrWhiteSpace(
    make
  )} ${capitalizeAllBeforeDash(model)} ${trim?.toUpperCase()}`;

  const name = `${year} ${capitalizeForDashOrWhiteSpace(
    make
  )} ${capitalizeAllBeforeDash(model)}`;

  React.useEffect(() => {
    if (priceData?.vehicle) {
      const payload: ListingDescriptionRequest = {
        carModel: name,
        year,
        make,
        model,
        trim,
      };
      mutateAsyncListingDescription(payload);
    }
  }, [
    make,
    model,
    mutateAsyncListingDescription,
    name,
    priceData?.vehicle,
    trim,
    year,
  ]);

  const onClickMakeLive = async (formData: any) => {
    signalHasClickedMakeLiveListing.value = true;
    if (signalhasDoneBulkUpload.value === false) return;

    const payload: ListingDataRequest = {
      listingInput: {
        userId: 1,
        listingId: Number(params?.listingId) ?? 0,
        tenantId: 1,
        description: JSON.stringify(listingDescriptionData) || "",
        name: nameWithTrim,
        make: capitalizeForDashOrWhiteSpace(make),
        model,
        trim,
        year,
        vin: params?.vin ?? "",
        licensePlate: "",
        price: formData?.price ?? 0,
        purchasePrice: 1900,
        repairCost: 50,
        reconditioningCost: 10,
        actualSalePrice: 5000,
        status: 1,
        mileage: formData?.mileage ?? 0,
      },
      auctionDetails: {
        listingId: Number(params?.listingId) ?? 0,
        listingPrice: 20000,
        enableAuction: true,
        startingBid: 20000,
        reservePrice: 20000,
        biddingEndDate: "2025-02-02T00:00:00",
      },
    };

    const res = await mutateAsyncUpdateListingData(payload);

    if (!isPendingUpdateListing && res?.listingInput?.listingId) {
      signalhasDoneBulkUpload.value = false;
      signalHasClickedMakeLiveListing.value = false;
      window.open(
        `#/listing/view-listing/${res?.listingInput?.listingId}`,
        "_blank"
      );
    }
  };

  if (isLoading) return <Loading />;
  return (
    <Form
      id="manage-listing-form"
      schema={ManageListingValidationSchema}
      onSubmit={onClickMakeLive}
    >
      {({ formState: { errors }, register, watch }) => {
        return (
          <div className="px-5 md:px-10 xl:px-32 mb-40">
            <div className="flex flex-col gap-7 items-center justify-center">
              <Typography variant="pageTitle">Manage Listing</Typography>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 mt-8">
              <div className="col-span-1 md:col-span-2">
                <Typography
                  variant="custom"
                  className="text-[#313131] font-[700] text-md md:text-large xl:text-xl text-center md:text-left"
                >
                  {name}
                </Typography>
                <Typography
                  variant="custom"
                  className="text-[#313131] font-[700] text-md md:text-large xl:text-xl text-center md:text-left"
                >
                  {trim}
                </Typography>
              </div>
              <div className="col-span-1 md:col-span-2 mt-6 md:mt-0">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 items-center">
                  <Typography
                    variant="custom"
                    className="col-span-3 md:col-span-1 md:col-start-2 text-[#313131] font-[600] text-md md:ml-3"
                  >
                    Enter Mileage
                  </Typography>
                  <Input
                    type="number"
                    className="col-span-3 md:col-span-2"
                    classNames={{
                      input: "text-center px-4",
                    }}
                    defaultValue={params?.mileage}
                    errorMessage={errors?.mileage?.message}
                    {...register("mileage")}
                    onChange={handleMileageChange}
                  />
                  <Typography
                    variant="custom"
                    className="col-span-4 md:col-span-1 md:col-start-3 md:col-end-5 mt-2 sm:-mt-2 text-[#313131] font-[500] md:font-[600] text-xs md:text-xs text-center "
                  >
                    VIN - {params ? params?.vin : "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
            {/* <ProfitLossCalculator /> */}
            {/* <RetailValueEstimate
              retailEstimate={priceData}
              onCallBack={setPeriod}
            />
            <VehicleHistorySnapshot
              register={register}
              errors={errors}
              listingAdvanceData={listingAdvanceData.data}
            />
            <AccordionCarData vinNumber={params?.vin ?? ""} />
            <VehicleListingDescription
              listingDescription={listingDescriptionData ?? null}
              carModel={name}
            />
            <ActionListing
              isPendingUpdateListing={isPendingUpdateListing}
              listing={listing}
              params={params}
            /> */}
          </div>
        );
      }}
    </Form>
  );
};

export default UserManageListingPage;
