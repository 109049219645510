export const findImage = (image: string) => {
  if (
    image.includes(".webp") ||
    image.includes(".png") ||
    image.includes("File_")
  ) {
    return image;
  }

  return null;
};
