import { AddressInput, Typography } from "components";
import {
  dmvDocumentServiceSignal,
  enterMissingInfoDefaultSignal,
} from "../store";
import Input from "components/ui/input";
import { ListingSellerDetailsResponse } from "services";

interface SellerOneDetailsProps {
  register: any;
  errors: any;
  sellerDetailsData: ListingSellerDetailsResponse | undefined;
  setValue: any;
  getValues: any;
}
export const SellerOneDetails: React.FC<SellerOneDetailsProps> = ({
  register,
  errors,
  setValue,
  getValues,
}) => {
  return (
    <>
      <Typography
        variant="custom"
        className="font-[800] text-[20px] text-[#313131] my-10"
      >
        Seller 1 Details:
      </Typography>
      {enterMissingInfoDefaultSignal.value.sellerOneDetails.map(
        (field, idx) => {
          return (
            <FormControl key={`${field}-${idx}`}>
              <Typography
                variant="custom"
                className="text-[#263238] font-[600] text-left col-span-2"
              >
                {field.label}
              </Typography>
              {["fsAddress", "sellerAddress2"].includes(field.name) ? (
                <div className="col-span-3">
                  <AddressInput
                    // zipData={zipcodeData}
                    handleSelect={(value) =>
                      setValue(`sellerOneDetails.${field.name}`, value)
                    }
                    placeholder=""
                    InputComponent={(props) => {
                      return (
                        <Input
                          variant="form"
                          size="lg"
                          className="col-span-3"
                          classNames={{
                            input: "px-4 text-center",
                          }}
                          defaultValue={
                            (dmvDocumentServiceSignal.value as any)?.[
                              field.name
                            ] ?? ""
                          }
                          errorMessage={
                            (errors.errors.sellerOneDetails as any)?.[
                              field.name
                            ]?.message
                          }
                          {...register(`sellerOneDetails.${field.name}` as any)}
                          value={getValues(
                            `sellerOneDetails.${field.name}` as any
                          )}
                          onChange={(e) => {
                            props.onChange(e);
                            setValue(
                              `sellerOneDetails.${field.name}`,
                              e.target.value
                            );
                          }}
                        />
                      );
                    }}
                  />
                </div>
              ) : (
                <Input
                  variant="form"
                  size="lg"
                  className="col-span-3"
                  classNames={{
                    input: "px-4 text-center",
                  }}
                  defaultValue={
                    (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
                  }
                  errorMessage={
                    (errors.errors.sellerOneDetails as any)?.[field.name]
                      ?.message
                  }
                  {...register(`sellerOneDetails.${field.name}` as any)}
                />
              )}
            </FormControl>
          );
        }
      )}
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
