import { Button } from "@nextui-org/react";
import { Toggle, Typography } from "components";
import Input from "components/ui/input";
import { ListingRowType } from "../BulkEnterVIN";
import { useRef } from "react";

interface Props {
  item: ListingRowType;
  handleToggle: (e: any) => void;
  handleFileChange: (file: File[]) => void;
}

const BulkRowCreateList = ({ item, handleToggle, handleFileChange }: Props) => {
  const { id, report, files } = item;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFileChange && handleFileChange(Array.from(e.target.files));
    }
  };
  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="flex relative flex-col md:flex-row gap-2 w-full justify-around mb-12 md:mb-4 text-xs md:text-sm items-center  text-center text-white">
      <div className="absolute -top-8 md:top-0.5 left-0 md:-left-8 ">{id}.</div>
      <div className="flex flex-col  text-center  px-0 md:px-2 w-full w-min-[30%]">
        <Typography className="block md:hidden text-start mb-1  text-white text-xs md:text-sm ">
          Enter VIN
        </Typography>
        <Input
          size="compact"
          className="w-full"
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          onChange={(e) => {}}
          // isDisabled={!openModal}
          radius="md"
          // defaultValue={username}
        />
      </div>
      <div className="flex flex-col text-center  px-0 md:px-2 w-full w-min-[30%]">
        <Typography className="text-white text-xs md:text-sm block md:hidden text-start mb-1 ">
          Enter Price
        </Typography>
        <Input
          size="compact"
          className="w-full"
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          onChange={(e) => {}}
          // isDisabled={!openModal}
          radius="md"
          // defaultValue={username}
        />
      </div>

      <div className="flex flex-col text-center px-0 md:px-2 w-full w-min-[30%]">
        <Typography className="text-white text-xs md:text-sm block md:hidden text-start mb-1 ">
          Enter Mileage
        </Typography>{" "}
        <Input
          size="compact"
          className="w-full"
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          onChange={(e) => {}}
          // isDisabled={!openModal}
          radius="md"
          // defaultValue={username}
        />
      </div>
      <div className="flex items-center justify-between md:justify-center text-center  w-full w-min-[7%]">
        <Typography className="text-white text-xs md:text-sm block md:hidden text-start mb-1 ">
          History/Options Report
        </Typography>
        <Toggle
          size="md"
          checked={report}
          // checked={aiImgDetationON.value}
          onChange={handleToggle}
        />
      </div>
      <div className="flex justify-between md:justify-center items-center  text-center  w-full w-min-[3%]">
        <Typography className="text-white w-full text-xs md:text-sm block md:hidden text-start mb-1 ">
          Upload Pictures
        </Typography>
        <input
          type="file"
          id="fileInput"
          multiple={true}
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChangeLocal}
        />

        <Button
          onClick={onHandleFileClick}
          className="bg-[#018FFD] text-xs md:text-sm w-full  border-1 shadow-sm shadow-gray-400 border-white text-white"
        >
          {files ? "Edit" : "Upload"}
        </Button>
      </div>
    </div>
  );
};

export default BulkRowCreateList;
