import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../../components/navbar/index";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const DmvFeeDetails: React.FC = () => {
  // const [data, setData] = useState({

  // });

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setData((prevdata) => ({
  //     ...prevdata,
  //     [name]: value,
  //   }));
  // };
  const navigate = useNavigate();
  const priceOptId = useSelector((state: RootState) => state.dmv.pricing);

  const handleProceed = async () => {
    navigate("/dealer-dmvTitling/dvm-document-status");
  };
  return (
    <React.Fragment>
      <Navbar type={"shipping-quote"}>
        <div className="flex flex-col items-center mx-8">
          <div className="justify-center items-center  sm:w-full md:w-full lg:max-w-[56rem] mt-8">
            <div className="bg-slate-50 px-6 py-8 shadow-right rounded-lg">
              <div className="flex items-center justify-center flex-col">
                <h1 className="text-3xl font-bold mb-4 font-[Metropolis-Bold] mt-8">
                  {priceOptId === "0" ? "DMV Fee Details" : "Fee Quote"}
                </h1>
                <p className="font-[Metropolis-bold] text-center text-md text-gray-700 w-full px-4 ">
                  Pending Integration
                </p>
              </div>
              <form>
                <div className="grid gap-3 py-6 px-32">
                  {[
                    { label: "Transfer Fee", value: "100" },
                    { label: "DMV Fee", value: "100" },
                    { label: "Sample Fee 1", value: "100" },
                    { label: "Sample Fee 2", value: "100" },
                    { label: "Sample Fee 3", value: "100" },
                  ].map(({ label, value }, index) => (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row items-center justify-between "
                    >
                      <label className="mt-1 text-xl font-[Metropolis-bold] text-[#263238] md:min-w-[12rem] md:pl-10">
                        {label}
                      </label>
                      <input
                        className="border-2 text-xl outline-none bg-transparent text-center md:w-full md:max-w-[20rem] font-bold border-black rounded-lg p-2 mb-2 sm:mb-0 md:mr-10"
                        // name={Object.keys(data)[index]}
                        defaultValue={value + "$"}
                      />
                    </div>
                  ))}

                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <label className="mt-1 text-xl font-[Metropolis-bold] text-[#018FFD] md:min-w-[12rem] md:pl-10">
                      Total
                    </label>
                    <input
                      className="border-2 text-xl bg-transparent text-center md:w-full font-bold md:max-w-[20rem] border-[#018FFD] outline-none rounded-lg p-2 mb-2 sm:mb-0 md:mr-10"
                      defaultValue={"500$"}
                      // onChange={handleChange}
                    />
                  </div>
                  {priceOptId === "2" && (
                    <button
                      onClick={handleProceed}
                      className="mt-4 mx-auto text-xl font-[Metropolis-bold] block px-12 py-4 text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] "
                    >
                      Proceed
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default DmvFeeDetails;
