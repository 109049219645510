import { Button, Image } from "@nextui-org/react";
import { GradientModal, Toggle, Typography } from "components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface SelectVehicleModalProps {
  open: boolean;
  handleClose: () => void;
  selectedData?: {
    name: string;
    vin: string;
    listingId: number;
  };
}

export const SelectVehicleModal: React.FC<SelectVehicleModalProps> = ({
  open,
  handleClose,
  selectedData,
}) => {
  const navigate = useNavigate();
  const [toggles, setToggles] = React.useState<Record<string, boolean>>({
    quote: false,
    // pdf: false,
    fullService: false,
  });

  // const [showNext, setShowNext] = useState(false);
  // const handleContinue = () => {
  // navigate(
  //   `enter-missing-details/${selectedData?.vin}/${selectedData?.listingId}`
  // );
  // setShowNext(true);
  // handleClose();
  // };

  const handleOnConfirm = () => {
    const { quote, fullService } = toggles;
    // if (quote) navigate("dmv-fee-details");
    // // else if (pdf) navigate("dmv-fee-and-pdf-docs");
    // else if (fullService) navigate("dmv-fee-quotes");
    if (quote) {
      navigate(
        `enter-missing-details-quotes/${selectedData?.vin}/${selectedData?.listingId}`
      );
    } else if (fullService) {
      navigate(
        `enter-missing-details-full/${selectedData?.vin}/${selectedData?.listingId}`
      );
    }
    handleClose();
  };
  const handleToggleChange = (toggleName: string) => {
    setToggles((prevToggles) => ({
      ...Object.fromEntries(
        Object.entries(prevToggles).map(([name, value]) => [
          name,
          name === toggleName,
        ])
      ),
    }));
  };

  return (
    <React.Fragment>
      <div className="flex items-center m-auto dmv-title">
        <div className="flex items-center  bg-cover bg-center mx-auto px-4 bg-fixed">
          {/* {open &&
            (showNext ? ( */}
          <GradientModal isOpen={open} onOpenChange={handleClose}>
            <div className="flex items-center justify-center p-20">
              <Typography
                variant="custom"
                className="text-[#fff] text-2xl md:text-3xl font-[600] text-center"
              >
                Choose Your Plan
              </Typography>
            </div>
            <div className="grid gap-5 px-5 md:px-20">
              {["quote", "fullService"].map((toggleName) => (
                <div
                  className="grid grid-cols-3 md:grid-cols-6"
                  key={toggleName}
                >
                  <Image
                    className="col-span-1 ml-10"
                    src="assets/icons/shield_yes.svg"
                  />
                  <Typography
                    variant="custom"
                    className="-ml-10 md:ml-0 col-span-1 md:col-span-4 text-small md:text-lg font-[600] text-[#fff]"
                  >
                    {toggleName === "quote" && "Quote Only? Free"}
                    {/* {toggleName === "pdf" &&
                "Quote and PDF Documents for Self Service?"} */}
                    {toggleName === "fullService" && "Full Service $149"}
                  </Typography>
                  <Toggle
                    size="md"
                    checked={toggles[toggleName]}
                    onChange={() => handleToggleChange(toggleName)}
                    // disabled={isPending}
                    color="default"
                    className="ml-auto"
                  />
                </div>
              ))}
              <div className="flex justify-center mt-6 mb-10 md:mb-20">
                <Button
                  size="lg"
                  className="bg-[#018FFD] border border-white px-16 py-8"
                  onClick={handleOnConfirm}
                  isDisabled={!Object.values(toggles).some((toggle) => toggle)}
                  // isLoading={isPending}
                >
                  <Typography
                    variant="custom"
                    className="text-xl font-[600] text-[#fff]"
                  >
                    Confirm
                  </Typography>
                </Button>
              </div>
            </div>
          </GradientModal>
          {/* ) : (
              <div
                style={{ zIndex: 150 }}
                className="flex overflow-y-auto p-4 overflow-x-hidden fixed items-center  modal-container-vin pt-[130px] justify-center top-0 right-0 left-0 z-50 w-full md:inset-0 h-[100%]  max-h-full"
              >
                <div className="absolute inset-0 bg-black opacity-50 z-999"></div>

                <div className="relative gradiant-modal-loading h-[450px] py-24 px-24   md:px-12  mx-auto  rounded-lg shadow-md ">
                  <img
                    onClick={handleClose}
                    src="assets/icons/close-icon.svg"
                    alt="x"
                    className="absolute right-8 top-10 border-white border-2 p-1 rounded-full cursor-pointer"
                  />
                  <div className="text-white justify-center  font-bold mb-4  md:text-2xl flex text-lg">
                    Confirm Vehicle
                  </div>
                  <div className="text-white justify-center text-center font-bold mb-8  text-2xl md:text-4xl flex">
                    {selectedData?.name}
                  </div>

                  <div className="flex justify-center flex-col items-center mb-2 gap-8">
                    <p className="text-white w-full max-w-[318px] font-bold  text-center   md:text-lg  text-base">
                      VIN - {selectedData?.vin.toUpperCase()}
                    </p>
                    <button
                      onClick={handleContinue}
                      className=" text-white confirm-btn-fin py-2  rounded-lg focus:outline-none focus:shadow-outline text-lg md:text-2xl"
                      type="button"
                    >
                      {"Confirm"}
                    </button>
                  </div>
                </div>
              </div>
            ))} */}
        </div>
      </div>
    </React.Fragment>
  );
};
