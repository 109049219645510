import React, { useState } from "react";
import { PaymentCardInfo } from "services/endpoints/shipping-quotes/shipping.type";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { Button, Checkbox } from "@nextui-org/react";
import { AddressInput, PhoneNumberInput, Toggle, Typography } from "components";
import { useGetZipCodeDetails } from "services";
import { useDebounceValue } from "usehooks-ts";
import { Controller } from "react-hook-form";

import { useGetWarrantyLead } from "hooks/api/queries/view-cars/view-cars";
import { useNavigate } from "react-router-dom";
import { FmoMembershipValidationSchema, UsedCarReviewValidationSchema } from "modules/shared/features/UsedCarForm/used-car.validation";

interface Props {
  handleNext?: () => void;
  savedMethod?: PaymentCardInfo | null;
  isPending?: boolean;
}

const FmoMembershipForm: React.FC<Props> = ({ handleNext, isPending }) => {
  const [rfChecked, setRFChecked] = React.useState({
    checked: false,
    data: "",
    error: false,
  });
  const [zipValue, setZipValue] = React.useState("");
  const {
    mutateAsync: getWarrentyLeadAsync,
    isPending: isPendingToGetWarrantyLead,
  } = useGetWarrantyLead();
  const [debouncedValue] = useDebounceValue(zipValue, 500);
  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
    isPending: isPendingToFindZipCode,
  } = useGetZipCodeDetails(debouncedValue);
  React.useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  const handleSubmit = (e: any) => {
    const shouldGoNext = zipcodeData;
    if (handleNext && shouldGoNext) {
      handleNext();
    }
  };

  const handleNavigate = (forPage: "privacy" | "terms") => {
    window.open(
      window.location.href + "?policy=" + forPage,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div className="w-full ">
      <div className="m-auto flex flex-col  max-w-screen-sm py-6 md:py-10  ">
        <div className={`rounded-xl px-6`}>
          <Form
            id="new-order-shipping-form"
            schema={FmoMembershipValidationSchema}
            onSubmit={handleSubmit}
            className="md:px-10"
          >
            {({ formState: { errors, isSubmitted },setValue,getValues, register, control }) => {
              console.log("errors", errors);
              return (
                <div className="flex flex-col gap-2">
                  <div className="grid grid-cols-1  md:grid-cols-2 gap-4 ">
                    {[
                      {
                        id: "fname",
                        label: "First Name",
                        style: "w-full  ",
                        placeholder: "",
                        type: "text",
                      },
                      {
                        id: "lname",
                        label: "Last Name",
                        style: "w-full  ",
                        placeholder: "",
                        type: "text",
                      },
                    ].map(({ id, label, style, placeholder, type }) => {
                      return (
                        <div className={style} key={id}>
                          <label
                            htmlFor={id}
                            className="text-xs block mb-1 font-bold"
                          >
                            {label}
                          </label>
                          <Controller
                            control={control}
                            name={id as any}
                            render={({ field }) => (
                              <Input
                                variant="form"
                                size="compact"
                                errorMessage={
                                  errors[id as keyof typeof errors]?.message
                                }
                                type={type}
                                placeholder={placeholder}
                                id={id}
                                // {...register(id as any)}
                                {...field}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const formattedValue = inputValue.replace(
                                    /[^a-zA-Z]/g,
                                    ""
                                  );

                                  return field.onChange(formattedValue);
                                }}
                                classNames={{
                                  input:
                                    "font-bold  w-full focus:outline-none text-center",
                                  inputWrapper: !!errors[
                                    id as keyof typeof errors
                                  ]
                                    ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                    : "",
                                }}
                              />
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div className="grid grid-cols-1  md:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor={"email"}
                        className="text-xs block  font-bold"
                      >
                        Email
                      </label>
                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={errors["email"]?.message}
                        type="text"
                        id={"email"}
                        {...register("email")}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper: !!errors["email"]
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : "",
                        }}
                      />
                    </div>

                    <div className="w-full  ">
                      <label
                        htmlFor={"phone"}
                        className="text-xs block mb-1 font-bold"
                      >
                        Phone
                      </label>
                      <PhoneNumberInput
                        key={"phone"}
                        control={control}
                        id={"phone"}
                        placeholder=""
                        error={!!errors.phone}
                        InputProps={{
                          classNames: {
                            input:
                              "font-bold w-full focus:outline-none text-center",
                            inputWrapper: !!errors.phone
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          },
                          errorMessage: errors.phone?.message,
                        }}
                      />
                    </div>
                  </div>
                  <div className=" grid grid-cols-1  md:grid-cols-3 gap-4">
                    <div className="w-full  ">
                      <label
                        htmlFor={"zipcode"}
                        className="text-xs block mb-1 font-bold"
                      >
                        ZIP Code
                      </label>

                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={
                          !zipcodeData && isSubmitted && "ZIP Code is Invalid"
                        }
                        type="text"
                        id={"zipcode"}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper:
                            !zipcodeData && isSubmitted
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                        }}
                        value={zipValue}
                        maxLength={5}
                        onChange={(e) => {
                          const formattedValue = e.target.value?.replace(
                            /\D/g,
                            ""
                          );
                          setZipValue(formattedValue);
                        }}
                      />
                    </div>
                    
                    <div className="w-full  col-span-1 md:col-span-2">
                      <label
                        htmlFor={"zipcode"}
                        className="text-xs block mb-1 font-bold"
                      >
                        Address
                      </label>

                      
                      <AddressInput
                        zipData={zipcodeData}
                        handleSelect={(value) => setValue("address", value)}
                        placeholder=""
                        InputComponent={(props) => {
                          return (
                            <Controller
                              control={control}
                              name="address"
                              render={({ field }) => (
                                <Input
                                  key={"address"}
                                  variant="form"
                                  size="compact"
                                  errorMessage={
                                    errors["address" as keyof typeof errors]
                                      ?.message
                                  }
                                  type="text"
                                  tabIndex={9}
                                  classNames={{
                                    input:
                                      "font-bold  w-full focus:outline-none text-center",
                                    inputWrapper: !!errors[
                                      "address" as keyof typeof errors
                                    ]
                                      ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                      : "",
                                  }}
                                  {...props}
                                  id={"address"}
                                  value={getValues("address")}
                                  onChange={(e) => {
                                    props.onChange(e);
                                    return field.onChange(e);
                                  }}
                                />
                              )}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div
                    // key={id}
                    className="flex items-center justify-between mt-2 md:mb-0 mb-2"
                  >
                    <label
                      tabIndex={10}
                      htmlFor={"Reference_Number"}
                      className="text-xs  block mb-1 font-bold"
                    >
                      {"Reference Number"}
                    </label>
                    <Toggle
                      size="sm"
                      checked={rfChecked.checked}
                      id="Reference_Number"
                      onChange={() =>
                        setRFChecked((p) => ({
                          ...p,
                          checked: !p.checked,
                        }))
                      }
                    />
                  </div>
                  {rfChecked.checked && (
                    <div className="flex  justify-center w-full mt-2 ">
                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={
                          rfChecked.error && "Reference Number is required"
                        }
                        type="text"
                        id={"Reference Number"}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper: rfChecked.error
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : "",
                        }}
                        tabIndex={11}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (Number(value) && Number(value) > 0) {
                            setRFChecked((prev) => ({
                              ...prev,
                              data: value,
                              error: Number(value) === 0,
                            }));
                          } else return (e.target.value = "");
                        }}
                      />
                    </div>
                  )}
                  <div className="flex flex-col items-center">
                    <Button
                      type="submit"
                      isLoading={isPending || isPendingToGetWarrantyLead}
                      // isDisabled={!checked}
                      className="bg-gradient-to-r w-full  mb-6 mt-2 from-[#018ffd] to-[#070c84] text-white text-lg  py-6 tracking-wide px-6 rounded-xl "
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FmoMembershipForm;
