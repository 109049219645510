import { isValidCreditCardNumber } from "utils";
import { z } from "zod";

export const NewOrderValidationSchema = z.object({
  shippingZip: z
    .string()
    .min(4, {
      message: "Shipping Zip is required",
    })
    ,
  shippingStreetAddress: z.string().min(1, {
    message: "Shipping Street Address is required",
  }),
  destinationZip: z
    .string()
    .min(4, {
      message: "Destination Zip is required",
    }),
  destinationStreetAddress: z.string().min(1, {
    message: "Destination Street Address is required",
  }),
  estimatedShipDate: z.date().optional(),
});

export const AddPaymentValidationSchema = z.object({
  fname: z.string().min(1, {
    message: "First Name is required",
  }),
  lname: z.string().min(1, {
    message: "Last Name is required",
  }),
  // email: z
  //   .string()
  //   .min(1, "Email is Required")
  //   .email({ message: "Please provide a valid Email" }),
  cardNumber: z.string().min(1, {
    message: "Card Number is required",
  }).refine((value) => isValidCreditCardNumber(value), {
    message: "Invalid credit card number",
}),
  address: z.string().min(1, {
    message: "Address is required",
  }).max(60, {
    message: "Address should be less than 60 characters",
  }),
  expiryDate: z.string().refine(
    (value) => {
      const [month, year] = value.split("/");
      const currentYear = new Date().getFullYear().toString().substr(-2);
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return (
        /^\d{2}\/\d{2}$/.test(value) && // MM/YY format validation
        parseInt(month) >= 1 &&
        parseInt(month) <= 12 && // Month should be between 01 and 12
        parseInt(year) >= parseInt(currentYear) && // Year should be the current year or later
        (parseInt(year) !== parseInt(currentYear) ||
          parseInt(month) >= parseInt(currentMonth)) // If year is current, month should be current or later
      );
    },
    {
      message: "Expiry date must be in the format MM/YY",
      path: ["expiryDate"],
    }
  ),
  cvv: z
    .string()
    .min(1, "CVV is required")
    .regex(/^\d{3,4}$/, "CVV must be a 3 or 4 digit number"),
});

export const AddPaymentValidationSchemaRegister = z.object({
  fname: z.string().min(1, {
    message: "First Name is required",
  }),
  lname: z.string().min(1, {
    message: "Last Name is required",
  }),
  cardNumber: z.string().min(1, {
    message: "Card Number is required",
  }),
  address: z.string().min(1, {
    message: "Address is required",
  }),
  // zipcode: z
  //   .string()
  //   .min(1, {
  //     message: "Zip Code is required",
  //   })
  //   .length(5, "Zip Code needs to have a length of 5"),
  expiryDate: z.string().refine(
    (value) => {
      const [month, year] = value.split("/");
      const currentYear = new Date().getFullYear().toString().substr(-2);
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      return (
        /^\d{2}\/\d{2}$/.test(value) && // MM/YY format validation
        parseInt(month) >= 1 &&
        parseInt(month) <= 12 && // Month should be between 01 and 12
        parseInt(year) >= parseInt(currentYear) && // Year should be the current year or later
        (parseInt(year) !== parseInt(currentYear) ||
          parseInt(month) >= parseInt(currentMonth)) // If year is current, month should be current or later
      );
    },
    {
      message: "Expiry date must be in the format MM/YY",
      path: ["expiryDate"],
    }
  ),
  cvv: z
    .string()
    .min(1, "CVV is required")
    .regex(/^\d{3,4}$/, "CVV must be a 3 or 4 digit number"),
});
