import { useMutation, useQuery } from "@tanstack/react-query";

import { vinDataEndpoint } from "./endpoints/vin-data/vin-data.endpoint";
import { VinDataDecodeResponse } from "./endpoints/vin-data/vin-data.type";
import { shouldRetryOnError } from "config/retryConfig";

const api = vinDataEndpoint;
export const useGetVindataDecode = (vinNumber: string) => {
  return useQuery<VinDataDecodeResponse, Error>({
    queryKey: ["vin-data-decode", vinNumber],
    queryFn: async () => {
      const res = await api.getVinDataDecodeAsync(vinNumber);
      return res;
    },
    enabled: !!vinNumber,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: false,
    retry: shouldRetryOnError,
  });
};

export const useGetVindataDecodeMut = () => {
  return useMutation<VinDataDecodeResponse, Error, string>({
    mutationFn: async (payload) => {
      const res = await api.getVinDataDecodeAsync(payload);
      return res;
    },
  });
};
