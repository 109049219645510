import { SVGProps } from "react";

export const LockIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="mdi:password-outline">
      <path
        id="Vector"
        d="M15.0716 22.1237C14.4051 22.1237 13.7659 21.8589 13.2946 21.3876C12.8234 20.9164 12.5586 20.2772 12.5586 19.6107C12.5586 18.216 13.6769 17.0977 15.0716 17.0977C15.7381 17.0977 16.3773 17.3624 16.8486 17.8337C17.3199 18.305 17.5846 18.9442 17.5846 19.6107C17.5846 20.2772 17.3199 20.9164 16.8486 21.3876C16.3773 21.8589 15.7381 22.1237 15.0716 22.1237ZM22.6107 25.8932V13.3281H7.53255V25.8932H22.6107ZM22.6107 10.8151C23.2772 10.8151 23.9164 11.0799 24.3876 11.5512C24.8589 12.0224 25.1237 12.6616 25.1237 13.3281V25.8932C25.1237 26.5597 24.8589 27.1989 24.3876 27.6702C23.9164 28.1415 23.2772 28.4062 22.6107 28.4062H7.53255C6.86606 28.4062 6.22686 28.1415 5.75558 27.6702C5.2843 27.1989 5.01953 26.5597 5.01953 25.8932V13.3281C5.01953 11.9334 6.13783 10.8151 7.53255 10.8151H8.78906V8.30208C8.78906 6.63585 9.45097 5.03786 10.6292 3.85965C11.8074 2.68144 13.4054 2.01953 15.0716 2.01953C15.8967 2.01953 16.7136 2.18203 17.4758 2.49776C18.2381 2.81349 18.9307 3.27626 19.514 3.85965C20.0974 4.44304 20.5602 5.13562 20.8759 5.89785C21.1917 6.66009 21.3542 7.47705 21.3542 8.30208V10.8151H22.6107ZM15.0716 4.53255C14.0719 4.53255 13.1131 4.9297 12.4062 5.63662C11.6992 6.34355 11.3021 7.30234 11.3021 8.30208V10.8151H18.8411V8.30208C18.8411 7.30234 18.444 6.34355 17.7371 5.63662C17.0302 4.9297 16.0714 4.53255 15.0716 4.53255Z"
        fill="#313131"
      />
    </g>
  </svg>
);
