import { Button, Image } from "@nextui-org/react";
import { EyeIcon } from "assets/svgs/icons/eye-icon";
import { EyeSlashedIcon } from "assets/svgs/icons/eye-slash-icon";
import {  TextErrorHelper, Typography } from "components";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { LoginDetailsValidationSchema } from "pages/dealer/dealer-membership/validation";
import { useState } from "react";
import { ChangePasswordRequest, useChangePassword } from "services";
import { getCurrUsername, getUserRoles } from "utils/get-user-id";
import { z } from "zod";

interface Props {
  type: string;
  handleForgatePass:()=>void;
}
const EditPassword = ({ type = "USER" ,handleForgatePass}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  const username = getCurrUsername();
  const userRole = getUserRoles();

  const { mutateAsync, isPending, data } = useChangePassword(
    userRole === "Dealer" ? "dealer" : "user"
  );

  const handleSubmit = async (
    formData: z.infer<typeof LoginDetailsValidationSchema>
  ) => {
    const payload: ChangePasswordRequest = {
      confirmPassword: formData.newPassword,
      newPassword: formData.newPassword,
      oldPassword: formData.oldPassword,
      userName: username,
    };
    await mutateAsync(payload);
  };
  return (
 <Form
      onSubmit={handleSubmit}
      schema={LoginDetailsValidationSchema}
      className=""
    >
      {({ formState: { errors, dirtyFields }, register }) => {
        return ( 
    <div className="flex w-full justify-center items-center">
      <div className="mt-12 w-full flex flex-col gap-4 items-center">
        <Typography
          variant="custom"
          className="text-white text-xl  md:text-4xl"
        >
          Change Password
        </Typography>
        

        <Input
          size="lg"
          className="w-3/4 md:w-1/2 "
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          
          startContent={
            <Image
              src="/assets/icons/password-edit.svg"
              className="w-[20px] h-[20px]"
              removeWrapper
              radius="none"
            />
          }
          type={isVisible2 ? "text" : "password"}
          {...register("oldPassword")}
          errorMessage={errors["oldPassword"]?.message}
          endContent={
            <button
              className="absolute right-3 focus:outline-none"
              type="button"
              onClick={
                isVisible2
                  ? () => setIsVisible2(false)
                  : () => setIsVisible2(true)
              }
            >
              {isVisible2 ? (
                <EyeIcon className="text-3xl pointer-events-none text-default-600" />
              ) : (
                <EyeSlashedIcon className="text-3xl pointer-events-none text-default-600" />
              )}
            </button>
          }
          // isDisabled={!openModal}
          radius="xl"
          placeholder={"Enter Old Password"}
          
        />

        {/* <div  className="flex flex-col w-3/4 md:w-1/2"> */}
        <Input
          size="lg"
          className="w-3/4 md:w-1/2 "
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          startContent={
            <Image
              src="/assets/icons/password-edit.svg"
              className="w-[20px] h-[20px]"
              removeWrapper
              radius="none"
            />
          }
          type={isVisible ? "text" : "password"}
          errorMessage={errors["newPassword"]?.message}
          {...register("newPassword")}
          endContent={
            <button
              className="absolute right-3 focus:outline-none"
              type="button"
              onClick={
                isVisible
                  ? () => setIsVisible(false)
                  : () => setIsVisible(true)
              }
            >
              {isVisible ? (
                <EyeIcon className="text-3xl pointer-events-none text-default-600" />
              ) : (
                <EyeSlashedIcon className="text-3xl pointer-events-none text-default-600" />
              )}
            </button>
          }
          // isDisabled={!openModal}
          radius="xl"
          placeholder={"Enter New Password"}
        />
        {/* </div> */}
        <div className="flex flex-col w-3/4 md:w-1/2">
        <Input
          size="lg"
          className=" w-full"
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          startContent={
            <Image
              src="/assets/icons/password-edit.svg"
              className="w-[20px] h-[20px]"
              removeWrapper
              radius="none"
            />
          }
          type={isVisible3 ? "text" : "password"}
          errorMessage={errors["confirmPassword"]?.message}
          {...register("confirmPassword")}
          endContent={
            <button
              className="absolute right-3 focus:outline-none"
              type="button"
              onClick={
                isVisible3
                  ? () => setIsVisible3(false)
                  : () => setIsVisible3(true)
              }
            >
              {isVisible3 ? (
                <EyeIcon className="text-3xl pointer-events-none text-default-600" />
              ) : (
                <EyeSlashedIcon className="text-3xl pointer-events-none text-default-600" />
              )}
            </button>
          }
          // isDisabled={!openModal}
          radius="xl"
          placeholder={"Confirm New Password"}
        />
        <span  onClick={handleForgatePass} className="ml-auto cursor-pointer mt-2 text-xs md:text-base text-white text-nowrap" >Forgot your Password? </span>
        </div>
        <TextErrorHelper
          className={`w-10/12 text-center text-base md:text-lg ${
            data?.statusCode === 200 ? "text-green-500" : ""
          }`}
        >
        {data?.message}
              </TextErrorHelper>
        <Button
          className="bg-white h text-[#018ffd] text-lg   h-full py-4 px-16"
          size="sm"
          disableAnimation
          type="submit"
          isLoading={isPending}
        >
          <Typography
            variant="custom"
            className="text-xs md:text-xl font-[600]"
          >
            Confirm
          </Typography>
        </Button>
      </div>
    </div>
    );
  }}
</Form> 
  );
};

export default EditPassword;
