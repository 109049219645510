import { useMutation, useQuery } from "@tanstack/react-query";
import { tilingEndpoint } from "./endpoints/tiling/tiling.endpoint";
import {
  DocumentServiceQuotesRequest,
  DocumentServiceQuotesResponse,
  FeeQuotesResponse,
  TilingRequest,
  TilingSelectAtcProcedureRequest,
  TilingSelectAtcProcedureResponse,
  TiltingOrderHistory,
} from "./endpoints/tiling/tiling.type";
import { selectedProcedureSignal } from "pages/dealer/dmv-titling/store";

const api = tilingEndpoint;
export const useGetATCFeeQuotes = (listingId: string) => {
  return useMutation<FeeQuotesResponse, Error, TilingRequest>({
    mutationFn: async (payload) => {
      const res = await api.getATCFeeQuotesAsync(
        payload,
        selectedProcedureSignal.value?.quoteId || "0",
        listingId
      );
      return res;
    },
  });
};

export const useSelectAtcProcedure = () => {
  return useMutation<
    TilingSelectAtcProcedureResponse,
    Error,
    TilingSelectAtcProcedureRequest
  >({
    mutationFn: async (payload) => {
      const res = await api.selectAtcProcedureAsync(payload);
      return res;
    },
  });
};

export const useUploadDMVTitlingFiles = () => {
  return useMutation<
    any,
    Error,
    any
  >({
    mutationFn: async ({qId,payload}) => {
      const res = await api.uploadDMVTitlingFiles(payload,qId);
      return res;
    },
  });
};

export const useGetDmvOrderHistoryAsync = () => {
  return useQuery<TiltingOrderHistory[], Error>({
    queryKey: ["dmv-order-history"],
    queryFn: async () => {
      const res = await api.getDmvOrderHistoryAsync(1, 20);
      return res;
    },
  });
};

export const useGetDocumentServiceQuotes = (
  quotesId: string,
  listingId: string
) => {
  return useMutation<
    DocumentServiceQuotesResponse[]|undefined,
    Error,
    DocumentServiceQuotesRequest
  >({
    mutationFn: async (payload) => {
      const res = await api.getDocumentServiceQuotesAsync(
        payload,
        quotesId,
        listingId
      );
      return res;
    },
    onError:(e)=>{
      console.log(e)
    }
    // retry: 0,
  });
};

export const useGetDocumentServiceQuotesId = (
  quotesId: string
) => {
  return useMutation<
    DocumentServiceQuotesResponse[]|undefined,
    Error
  >({
    mutationFn: async (payload) => {
      const res = await api.getDocumentServiceQuotesIdAsync(
        
        quotesId,
  
      );
      return res;
    },
    onError:(e)=>{
      console.log(e)
    }
    // retry: 0,
  });
};


export const useGetProcessTitlingPayment = (
   
) => {
  return useMutation<
    any,
    Error  >({
    mutationFn: async () => {
      const res = await api.getProcessTitlingPayment(   
        selectedProcedureSignal.value?.quoteId || "0"
        );
      return res;
    },

    // retry: 4,
  });
};