import { Typography } from "components";
import React from "react";

export default function TermsConditions() {
  return (
    <div className="pl-4 pr-4  mt-2">
      {/* <Typography variant="custom" className="pb-5">
        <p className="text-4xl font-bold text-center">Terms and Conditions</p>
      </Typography> */}
      <Typography variant="custom" className="mt-3">
        <p className="text-lg mt-1">
          PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFOREUSING
          THIS SITE AS THEY CONTAIN IMPORTANT INFORMATION THAT MAYAFFECT YOUR
          LEGAL RIGHTS, INCLUDING REQUIRING INDIVIDUALARBITRATION OF ANY
          POTENTIAL LEGAL DISPUTES BETWEEN YOU ANDfindmyoptions.com LLC AND
          WAIVING ANY RIGHT TO PARTICIPATE IN A CLASSACTION LAWSUIT OR
          CLASS-WIDE ARBITRATION.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg mt-1">
          The findmyoptions.com website (the “Site”) is owned and operated by
          findmyoptions.com LLC(or "findmyoptions.com LLC"). By using this Site,
          you explicitly agree to comply with and be bound by the following
          terms and conditions (“Terms and Conditions” or “Agreement”), and all
          policies, rules, and instructions, and additional terms posted on or
          through the Site, all of which are incorporated into this Agreement.
          By registering for, logging into, visiting and/or otherwise using the
          Site in any way, you indicate that you understand, intend to be
          legally bound by, and accept these Terms and Conditions.
          findmyoptions.com LLC may change these Terms and Conditions at any
          time. Your continued use of the Site following appropriate notice of
          such modifications will be conclusively deemed acceptance of any
          changes to these Terms and Conditions. At all times, you are bound by
          the then-current version of these Terms and Conditions and all
          applicable laws. Please review these Terms and Conditions periodically
          for changes.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold  mt-8">Use of Offer Services </p>
        <p className="text-lg mt-1">
          BY SUBMITTING YOUR INFORMATION TO RECEIVE AN OFFER, YOU AGREE (I)
          NOTTO SUE findmyoptions.com LLC, ITS AFFILIATES, ITS SERVICE
          PROVIDERS, OR ITS ORTHEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES
          AND AGENTS(COLLECTIVELY, THE “RELEASED PARTIES”) FOR, AND AGREE TO
          RELEASE ANDHOLD HARMLESS THE RELEASED PARTIES FROM AND AGAINST, ANY
          AND ALLCLAIMS RELATING TO OR ARISING OUT OF THE CONDUCT OF ANY
          PARTICIPATINGDEALER IN CONNECTION WITH THE OFFER SERVICES, AND (II)
          YOU AGREE TOLOOK SOLELY TO YOUR PARTICIPATING DEALER/SELLER FOR YOUR
          REMEDYAND NOT TO THE RELEASED PARTIES.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold  mt-8">
          Use of findmyoptions.com LLC Site Listings Generally 
        </p>
        <p className="text-lg mt-1">
          FOR ALL SITE-RELATED SERVICES, findmyoptions.com LLC IS NOT AN
          AUTOMOBILEBROKER OR DEALER AND DOES NOT SELL, EXCHANGE, BUY, OR OFFER
          FORSALE, NEGOTIATE OR ATTEMPT TO NEGOTIATE, A SALE OR EXCHANGE OF
          ANINTEREST IN ANY VEHICLE LISTED FOR SALE ON OUR SITE.
          findmyoptions.com LLC does not hold or possess title for any vehicle
          listed for sale on our Site and findmyoptions.com LLC is not a party
          to any vehicle sale contract between vehicle buyers and sellers that
          originates on or through the Site.
        </p>
        <p>
          All buyers and sellers must be at least 18 years old to use the Site
          and by using the Site, you represent that you are at least 18 years
          old.
        </p>
        <p className="mt-8">
          Information about a particular vehicle is supplied by the seller, not
          by findmyoptions.com LLC, and the buyer is solely responsible for
          reading the full vehicle listing before making a commitment to buy.
          The price and other terms of any sale are arranged after negotiation
          between the buyer and the seller. findmyoptions.com LLC is not
          responsible for incorrect information given by buyers or sellers,
          including (i) information regarding vehicles, including but not
          limited to vehicle mileage, condition, history, or photos of vehicles,
          and (ii) information about the identity, reliability or suitability of
          a buyer or seller. VinAudit Vehicle History reports are provided for
          informational purposes only from an unaffiliated third-party vehicle
          report provider, and findmyoptions.com LLC is not responsible for any
          incorrect information or mistakes. We cannot verify the
          information/photos that sellers supply or guarantee the vehicles they
          offer. When using this Site, please use common sense and good
          judgment. When dealing with prospective purchasers/sellers, we urge
          you to take the same precautions you would take if you were
          purchasing/selling a car through a classified ad in the newspaper or
          any other private sales transaction.
        </p>
        <p className="mt-8">
          findmyoptions.com LLC MAKES NO GUARANTEES, WARRANTEES
          ORREPRESENTATIONS REGARDING ANY VEHICLES LISTED FOR SALE OR SOLD ONOUR
          SITE. VEHICLE SALES ARE “AS IS”. VEHICLE SALES ARE BETWEEN THEBUYER
          AND THE SELLER ONLY. findmyoptions.com LLC DOES NOT OWN, INSPECT,BUY,
          OR SELL, ANY VEHICLES LISTED FOR SALE ON OUR SITE. ANY DISPUTEABOUT
          THE CONDITION OF A VEHICLE SOLD THROUGH THE SITE IS BETWEENTHE BUYER
          AND SELLER ONLY. findmyoptions.com LLC DOES NOT HOLD ORPOSSESS TITLE
          FOR ANY VEHICLE LISTED FOR SALE ON OUR SITE.{" "}
        </p>
        <p>
          All applications for credit and/or lending arrangements are through
          one or more unaffiliated third-party providers over whom
          findmyoptions.com LLC has no control. Any application for credit or
          lending arrangement, terms, conditions, warranties, or representations
          associated with any application for credit or lending arrangement, is
          solely between you and any such third-party provider.
          findmyoptions.com LLC is not responsible or liable for any loss or
          damage incurred as the result of such application for credit or
          lending arrangement. Interactive Services (Chatbot, Managed Chat,
          Etc.) Consent and Disclaimers This Site may provide interactive
          services, including chatbot, and managed chat functionality with an
          option to chat. You agree that we may record and retain a transcript
          of all communications with you via these interactive tools in order to
          provide the tool and for quality and verification purposes. Your use
          or access of any of these tools or of our Site is governed by these
          Terms and Conditions and our Privacy Policy.
        </p>
        <p className="text-lg font-bold  mt-8">
          Use of Private Party Sale Services
        </p>
        <p>
          findmyoptions.com LLC includes a vehicle listing and information
          service on the public listing portion of the site, that brings
          together private buyers and sellers. The information on vehicles
          provided through the Private Listing Platform is supplied by the
          seller or other third parties;findmyoptions.com LLC is not responsible
          for the accuracy of such information.findmyoptions.com LLC provides
          the Private Listing Platform services and the Site and all information
          and materials without conditions, representations or warranties of any
          kind, either expressed or implied. findmyoptions.com LLC is not a
          party to any transaction between Private Listing Platform vehicle
          buyers and sellers. Vehicle prices do not include additional fees such
          as government fees and taxes, title and registration fees, licensing
          and plate fees, finance charges, dealer document preparation fees, and
          emission testing. You may pay a fee for access to certain
          findmyoptions.com LLC services. All such fees are payable in advance
          of the provision of any such service are non refundable. We may change
          these fees at any time in our sole discretion. You shall be solely
          responsible for the payment of any taxes applicable to its payment for
          and/or use of such services. Your obligation to pay the fees is
          unconditional and not dependent on level of promotion, number of ad
          impressions or exposures, sales, leads, or inspection outcomes. With
          respect to the Featured listing services offered on the Private
          Listing Platform, your listing contains a valid VIN. Following
          payment, you will receive such services for the period of time
          specified at the time of purchase unless your listing is removed by
          you for any reason or by findmyoptions.com LLC due to an issue with
          your listing. As a Private Listing Platform seller you represent and
          warrant: (i) that you are not a motor vehicle dealer or broker, or
          acting in the capacity as an owner, employee or representative of a
          dealer or broker, (ii) that all information submitted to
          findmyoptions.com LLC about a particular vehicle and your contact
          information are accurate and true. In addition, you agree to approve
          listing descriptions as complete and accurate before publication,
          (iii) that all relevant vehicle information is included in the listing
          submission, (iv) that you are prepared to sell the listed vehicle at
          the price at which it is listed, (v) that you have possession of the
          actual vehicle listed and the proper authority to transfer title.
        </p>
        <p className="text-lg font-bold  mt-8">
          Dealer Use of Restricted Services
        </p>
        <p className="mt-8">
          If you are a car dealer listing vehicles using findmyoptions.com LLC
          Restricted listing services, you and your dealership’s access to and
          use of the Restricted services is subject to the Dealer Software
          Agreement.
        </p>
        <p className="text-lg font-bold mt-8">
          Ownership And Intellectual Property
        </p>
        <p className="mt-8">
          Except as otherwise indicated, all copyright rights and other
          intellectual property rights in this Site and its contents, including
          any and all messages, data, information, text, music, sound, photos,
          images, graphics, code, marks, logos, audio and video, footage,
          animations, podcasts, analyses, studies, reports, downloads, and other
          content contained herein ("Site Content") is owned by or licensed to
          findmyoptions.com LLC, and protected by law, including copyright,
          database, trade secret, and trademark laws of the United States and
          all applicable jurisdictions, as well as other applicable state,
          national, and international laws and regulations. The Site Content is
          Copyright 2024, findmyoptions.com LLC, all rights reserved.
          findmyoptions.com LLC also owns copyright rights in the Site Content
          as a collective work and/or compilation, and in any and all databases
          accessible on or through the Site. findmyoptions.com LLC, the
          findmyoptions.com LLC Logo, and all other marks displayed on this site
          (the "Trademarks") are registered and/or common law trademarks of
          findmyoptions.com LLC and/or various third parties. Except as
          expressly stated herein, nothing contained on this Site may be
          construed as granting, by implication, estoppel, or otherwise, any
          grant, license or right to use any Site Content or Trademarks without
          the prior written permission of findmyoptions.com LLC or such other
          party that may own the Trademarks or any licensed copyrights. You
          agree not to directly or indirectly: attempt to register, challenge or
          contest the validity or the findmyoptions.com LLC (or it slicensor’s,
          as applicable) ownership of, such copyrights, Trademarks or any
          otherfindmyoptions.com LLC intellectual property, or assist any third
          party in doing so.
        </p>
        <p className="text-lg font-bold  mt-8">
          Use of Chat Rooms, Bulletin Boards and Other Interactive Areas
        </p>
        <p className="mt-8">
          The Site may contain discussion forums, bulletin boards, review
          services or other forums in which you or third parties may post
          reviews of automobiles and automotive related services or other
          content, messages, materials or other items on the Site ("Interactive
          Areas"). Iffindmyoptions.com LLC provides such Interactive Areas, you
          are solely responsible for your use of such Interactive Areas and use
          them at your own risk. No action should be taken based upon any of the
          information contained in the Interactive Areas. You should seek
          independent professional advice from a person who is licensed and/or
          knowledgeable in the applicable automotive area before acting upon any
          opinion, advice, or information contained on the Site. All statements,
          information and other content submitted by users are solely the
          opinions of users, and not of findmyoptions.com LLC. By posting or
          distributing any message, data, information, text, music, sound,
          photos, images, graphics, code, marks, logos or other content
          ("Content") to or through the Site, unless we indicate otherwise, you
          (a) grant findmyoptions.com LLC and its affiliates and sublicensees an
          onexclusive, royalty-free, perpetual, worldwide, transferable,
          irrevocable and fully sublicensable right to use, post, store,
          reproduce, modify, adapt, edit, translate, distribute ,transmit,
          publish, create derivative works from and publicly display,
          telecommunicate and perform such Content throughout the world in any
          media, now known or hereafter devised; (b)grant findmyoptions.com LLC
          and its affiliates and sublicensees the right to use the name that you
          submit in connection with such Content, if they choose in their
          absolute discretion; and (c)represent and warrant that (i) you own
          and/or control all of the rights to the Content that you post or
          otherwise distribute, or you otherwise have the lawful right to post
          and distribute such Conten tto or through the Site, and to grant the
          licenses granted herein; (ii) such Content is accurate and not
          misleading; and (iii) use and posting or other transmission of such
          Content does not violate these Terms and Conditions and will not
          violate any rights of or cause injury to any person or entity. You
          further grant findmyoptions.com LLC the right to pursue at law any
          person or entity that violates your or findmyoptions.com LLC rights in
          the Content or breaches of these Terms and Conditions.
          findmyoptions.com LLC takes no responsibility and assumes no liability
          for any Content posted, stored or uploaded by you or any third party,
          or for any loss or damage thereto or in connection therewith, nor is
          findmyoptions.com LLC liable for any mistakes, inaccuracies,
          infringements, defamation, slander, libel, omissions, falsehoods,
          obscenity, pornography or profanity you may encounter. As a provider
          of interactive services, findmyoptions.com LLC is not liable for any
          statements, representations or Content provided by its users in any
          public forum, personal homepage or any Interactive Area. Although
          findmyoptions.com LLC has no obligation to screen, editor monitor any
          of the Content posted to or distributed through any Interactive
          Area,findmyoptions.com LLC reserves the right, and has absolute
          discretion, to remove, screen or edit without notice any Content
          posted or stored on the Site at any time and for any reason and
          without liability to you or any third party, and you are solely
          responsible for creating backup copies of and replacing any Content
          you post or store on the Site at your sole cost and expense. Any use
          of the Interactive Areas or other portions of the Site in violation of
          the foregoing violates these Terms and Conditions and may result in,
          among other things, termination or suspension of your rights to use
          the Interactive Areas and/or the Site. In order to cooperate with
          legitimate governmental requests, subpoenas or court orders, to
          protect findmyoptions.com LLC systems and customers, or to ensure the
          integrity and operation of findmyoptions.com LLC business and systems,
          findmyoptions.com LLC may access and disclose any information it
          considers necessary or appropriate, including, without limitation,
          user profile information (i.e.name, email address, etc.), IP
          addressing and traffic information, usage history, and posted Content.
        </p>
        <p>
          findmyoptions.com LLC right to disclose any such information, as
          applicable, shall be pursuant to the terms of findmyoptions.com LLC
          Privacy Policy. Please see findmyoptions.com LLC Privacy Policy for
          the terms of findmyoptions.com LLC personal data collection and use
          practices with respect to the Site. By using this Site, you consent to
          findmyoptions.com LLC collection and use of personal data as set forth
          in findmyoptions.com LLC Privacy Policy.
        </p>
        <p className="text-lg font-bold  mt-8">Prohibited Activities</p>
        <p className="mt-8">
          By using this Site, you expressly agree not to use, reproduce, modify,
          adapt, edit, translate, publicly display, telecommunicate or perform,
          post, upload to, transmit, distribute, store, created erivative works
          from or otherwise publish throughout the World, in any media, now
          known or hereafter devised, on or through the Site any of the
          following:
        </p>
        <p className="mt-8">
          1. Content that is unlawful, untrue, libelous, defamatory, slanderous,
          obscene, pornographic ,indecent, lewd, suggestive, harassing,
          threatening, invasive of privacy or publicity rights, abusive,
          inflammatory, fraudulent or otherwise objectionable or inappropriate;
        </p>
        <p className="mt-8">
          2. Content that would constitute, encourage or provide instructions
          for a criminal offense, violate the rights of any party, or that would
          otherwise create liability or violate any local, state, foreign,
          national or international law, including, without limitation, the
          regulations of the U.S. Securities and Exchange Commission, the
          Canadian Securities Commission or any rules of a securities exchange
          such as the New York Stock Exchange, the American Stock Exchange, the
          NASDAQ Stock Market or the Toronto Stock Exchange;
        </p>
        <p className="mt-8">
          3. Content that may infringe any patent, trademark, trade secret,
          copyright or other intellectual or proprietary right of any party
          (including rights of privacy and publicity);
        </p>
        <p className="mt-8">
          4. Content that impersonates any person or entity or otherwise
          misrepresents your affiliation with a person or entity;
        </p>
        <p className="mt-8">
          5. Unsolicited promotions, political campaigning, advertising or
          solicitations;
        </p>
        <p className="mt-8">
          6. Private or personally identifiable information of any third party,
          including, without limitation, addresses, phone numbers, email
          addresses, Social Security numbers and credit card numbers;
        </p>
        <p className="mt-8">
          7. Viruses, corrupted data or other harmful, disruptive or destructive
          files;
        </p>
        <p className="mt-8">
          8. Content that is unrelated to the purpose of the area of the Site in
          which such Content isposted;
        </p>
        <p className="mt-8">
          9. Content submitted using the Site’s vehicle listing lead forms or
          other lead submissionfunctionality for any purpose other than genuine
          consumer inquiries relating to thevehicle listed, including (a) to
          promote products or services, (b) to inquire about orconduct
          dealer-to-dealer or other wholesale vehicle transactions, or (c) for
          any otherbusiness/commercial purpose; or
        </p>
        <p className="mt-8">
          10. Content that, in the sole judgment of findmyoptions.com LLC,
          contravenes the above, isotherwise objectionable or inappropriate, or
          which restricts or inhibits any other personfrom using or enjoying the
          Site, or which may expose findmyoptions.com LLC or itsaffiliates or
          its users to any harm or liability of any type.
        </p>
        <p className="mt-8">
          You further agree that you will not attempt or do any of the
          following:
        </p>
        <p className="mt-8">
          1. Interfere with or disrupt the Site or our computer systems,
          servers, or networks;
        </p>
        <p className="mt-8">
          2. Attempt to gain unauthorized access to any part of the Site, to
          accounts that belong toother users, or to computer systems or networks
          connected to the Site; or
        </p>
        <p className="mt-8">
          3. Engage in any systematic extraction of data or data fields,
          including, without limitation,email addresses, by use of any automated
          mechanism, such as web robots, crawlers, orspiders (except in strict
          conformance with the Robots Exclusion Protocol) or otherwise.
        </p>

        <p className="text-lg font-bold mt-8">Rights to User Content </p>
        <p className="mt-8">
          Content submitted by users will be considered non-confidential and
          findmyoptions.com LLC isunder no obligation to treat such Content as
          confidential or proprietary information. Withoutlimiting the
          foregoing, findmyoptions.com LLC reserves the right to use the Content
          as it deemsappropriate, including, without limitation, posting,
          reproducing, adapting, translating, publiclydisplaying,
          telecommunicating or performing, uploading to, transmitting,
          distributing, storing,creating derivative works from, publishing,
          deleting, editing, modifying, rejecting, or refusing topost it.
          findmyoptions.com LLC is under no obligation to offer you or make any
          payment forContent that you submit or its use as contemplated herein
          or for your permission or license toedit, delete or otherwise modify
          Content once it has been submitted to findmyoptions.com
          LLC.findmyoptions.com LLC shall have no duty to attribute authorship
          of Content to you, and shallnot be obligated to enforce any form of
          attribution by third parties. If it is determined that you retain
          moral rights (including rights of attribution or integrity) in
          theContent, you hereby declare that (a) you do not require that any
          personally identifyinginformation be used in connection with the
          Content, or any derivative works of or upgrades orupdates thereto; (b)
          you have no objection to the publication, use, modification, deletion
          and exploitation of the Content by findmyoptions.com LLC or its
          licensees or sublicensees, successors and assigns; (c) you forever
          irrevocably waive in favor of findmyoptions.com LLC and its
          successors, assigns, officers, employees, affiliates, agents and
          sublicensees, and agree not to claim or assert any entitlement to, any
          and all moral rights you may have as an author throughout the World in
          any of the Content; and (d) you forever release findmyoptions.com LLC,
          and its licensees, successors and assigns, from any claims that you
          could otherwise assert against findmyoptions.com LLC by virtue of any
          such moral rights.
        </p>
        <p className="text-lg font-bold  mt-8">
          Grant of License; Use Of The Site
        </p>
        <p className="mt-8">
          findmyoptions.com LLC grants to you a personal, revocable,
          nonexclusive, non-transferable license to view, download, and print
          limited portions of the Site Content available in the public areas of
          the Site subject to the following conditions: (i) you may only do so
          on an occasional basis for your own personal and non-commercial use;
          (ii) you may not modify the Site Content;(iii) any displays or
          printouts of Site Content must be marked "©, findmyoptions.com LLC,
          All rights reserved."; (iv) you may not remove or modify any
          copyright, trademark, or other proprietary notices that have been
          placed in/on the Site Content by findmyoptions.com LLC; and(v) you may
          not reproduce, modify, adapt, translate, edit, upload to, transmit,
          store, further post, mirror, frame, “deep link,” “scrape,” data mine,
          prepare derivative works from, sell or distribute or publicly display,
          perform or telecommunicate Site Content, except as provided herein.
          Except as expressly permitted above, copying, transmission,
          reproduction, public display, performance or telecommunication,
          reproduction, republication, modification, adaptation, translation,
          editing, storing, transmitting, creation of derivative works, posting,
          or redistribution of the Site Content or any portion thereof is
          strictly prohibited without the prior written permission
          offindmyoptions.com LLC. To request permission, you may contact
          findmyoptions.com LLC as follows:
        </p>
        <p className="mt-8">By electronic mail to: support@findmyoptions.com</p>
        <p className="mt-8">
          Or by regular mail to: findmyoptions.com LLC, 6844 Bardstown Rd #5178,
          Louisville, KY 40291
        </p>
        <p className="mt-8">
          You may not use Site Content in any manner that suggests the
          affiliation, sponsorship, endorsement or association with or by
          findmyoptions.com LLC with or of you or any third-party entity,
          person, product or service unless express written permission has been
          given byfindmyoptions.com LLC in advance. You represent and warrant
          that your use of Site Content will be consistent with this license and
          will not infringe or violate the rights of any other party or breach
          any contract or legal duty to any other parties, or violate any
          applicable law. Any unauthorized use of the Site or the Site Content
          will terminate the permission granted by this Agreement and may
          violate applicable laws, including copyright law, trademark laws, and
          commercial regulations and statutes.
        </p>
        <p className="mt-8">
          Without limiting the foregoing, you represent and warrant that you are
          not listed on any government list of prohibited or restricted parties
          or on any sanctions, debarment or exclusion list or located in (or a
          national of) a country that is subject to a U.S. government embargo or
          that has been designated by the U.S. government as a "terrorist
          supporting" country.
        </p>
        <p className="mt-8">
          <p>WARRANTY DISCLAIMER</p>
          <p>
            findmyoptions.com LLC MAKES NO CONDITION, REPRESENTATION OR
            WARRANTYOF ANY KIND REGARDING THE SUITABILITY OF THE SITE CONTENT,
            OR ANYPORTION THEREOF, FOR ANY PURPOSE. THE SITE, ALL SITE CONTENT,
            AND ALLOTHER CONTENT AVAILABLE ON OR THROUGH THE SITE ARE PROVIDED
            ON AN"AS IS," “AS AVAILABLE” BASIS, AND findmyoptions.com LLC
            EXPRESSLY DISCLAIMSANY AND ALL CONDITIONS, REPRESENTATIONS AND
            WARRANTIES OFMERCHANTABILITY OR FITNESS FOR PARTICULAR USE,
            INCLUDING ALL IMPLIEDWARRANTIES IN CONNECTION WITH THE FOREGOING.
            findmyoptions.com LLC ALSOEXPRESSLY DISCLAIMS ANY CONDITION,
            WARRANTY OR REPRESENTATION ASTO THE ACCURACY, RESULTS, TIMELINESS,
            COMPLETENESS,NON-INFRINGEMENT, OR PROPRIETARY CHARACTER OF THE SITE,
            ALL SITECONTENT, AND ALL OTHER CONTENT AVAILABLE ON OR THROUGH THE
            SITE.findmyoptions.com LLC DOES NOT GUARANTEE THAT THE SITE WILL BE
            ERRORFREE, OR CONTINUOUSLY AVAILABLE, OR THAT THE SITE WILL BE FREE
            OFVIRUSES OR OTHER HARMFUL COMPONENTS. YOU WAIVE AND COVENANT NOTTO
            ASSERT ANY CLAIMS OR ALLEGATIONS OF ANY NATURE WHATSOEVERARISING
            FROM OR RELATING TO YOUR USE OF THE SITE, INCLUDING,
            WITHOUTLIMITATION, ALL CLAIMS AND ALLEGATIONS RELATING TO THE
            ALLEGEDINFRINGEMENT OF PROPRIETARY RIGHTS, THE ALLEGED INACCURACY OF
            SITECONTENT, YOUR INABILITY TO ACCESS THE SITE AT ANY GIVEN TIME,
            ORALLEGATIONS THAT findmyoptions.com LLC HAS OR SHOULD INDEMNIFY,
            DEFEND,OR HOLD YOU HARMLESS FROM ANY CLAIM OR ALLEGATION ARISING
            FROMYOUR USE OR OTHER EXPLOITATION OF THE SITE.
          </p>
        </p>
        <p className="mt-8">
          <p>LIMITATION OF LIABILITY</p>
          <p>
            YOU USE THE SITE AT YOUR OWN RISK AND NEITHER findmyoptions.com LLC,
            ITSAFFILIATES AND SUBLICENSEE NOR THE PARTIES WHO PROVIDE
            INFORMATIONAND MATERIAL FOR PUBLICATION WITHIN THE SITE CONTENT
            SHALL BE LIABLEFOR ANY DAMAGES OF ANY SORT ARISING FROM YOUR USE OF
            OR INABILITY TOUSE THE SITE OR ANY PRODUCT OR SERVICE LINKED TO OR
            FROM ORADVERTISED OR PROMOTED ON THE SITE, INCLUDING, WITHOUT
            LIMITATION,CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE,
            EXEMPLARY, OROTHER DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR
            PROFITS, LOSSOF BUSINESS, AND LOSS OF DATA), EVEN IF WE ARE ADVISED
            BEFOREHAND OFTHE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME
            STATES/JURISDICTIONS DONOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FORCONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATIONS MAY NOTAPPLY TO YOU. TO THE EXTENT PERMITTED BY LAW,
            findmyoptions.com LLC ALSODISCLAIMS LIABILITY FOR ANY DIRECT DAMAGES
            BASED ON YOUR ACCESS ORUSE OF THE SITE AND THE SITE CONTENT. IF
            findmyoptions.com LLC IS FOUND TOBE LIABLE UNDER THIS AGREEMENT,
            INCLUDING FOR DIRECT OR ANY TYPE OFDAMAGES, OUR AGGREGATE LIABILITY
            TO YOU OR TO ANY THIRD PARTY ISLIMITED TO THE GREATER OF (A) THE
            AMOUNT OF FEES PAID BY YOU IN THE 12MONTHS PRIOR TO THE ACTION
            GIVING RISE TO THE LIABILITY; OR (B) $100. YOU AGREE TO INDEMNIFY
            findmyoptions.com LLC AND ITS OWNERS,SHAREHOLDERS, SUBSIDIARIES,
            AFFILIATES, OFFICERS, EMPLOYEES, PARTNERS,AND LICENSORS, AND THEIR
            RESPECTIVE SUCCESSORS AND ASSIGNS, AND HOLDALL OF THE FOREGOING
            HARMLESS, FROM AND AGAINST ANY AND ALL CLAIMS,LIABILITY AND
            EXPENSES, INCLUDING ATTORNEYS FEES, ARISING FROM ORRELATED IN ANY
            WAY TO YOUR USE OF THE SITE OR ANY BREACH OF THESETERMS AND
            CONDITIONS.
          </p>
        </p>
        <p className="mt-8">
          <p>Links To Other Sites</p>
          <p>
            The Site contains links to third-party web sites. The linked sites
            are not under the control offindmyoptions.com LLC, and
            findmyoptions.com LLC is not responsible for the contents of any
            linked site. findmyoptions.com LLC provides these links as a
            convenience, and a link does not imply endorsement of, sponsorship
            of, or affiliation with the linked site by findmyoptions.com LLC.
            Linked sites are owned and operated by independent retailers or
            service providers, and therefore, findmyoptions.com LLC cannot
            ensure that you will be satisfied with their products, services, or
            business practices. You should make whatever investigation you feel
            necessary or appropriate, including reviewing their Terms of Use and
            Privacy Policies, before proceeding with any transaction with any of
            these third parties.
          </p>
        </p>
        <p className="mt-8">
          <p>Digital Millennium Copyright Act Compliance</p>
          <p>
            findmyoptions.com LLC complies with the provisions of the Digital
            Millennium Copyright Act applicable to internet service providers
            (17 U.S.C. SS 512). If you have any complaints or objections to
            material posted on this Site, or if you believe that material posted
            on this Site infringes a copyright that you hold, you may contact
            our Designated Agent at the address provided below. According to the
            U.S. Digital Millennium Copyright Act, 17 U.S.C. Sec.512(c)(3), your
            notice must comply with the following requirements:
          </p>
        </p>
        <p className="mt-8">
          1. A physical or electronic signature of the copyright owner or person
          authorized to act on behalf of the owner of an exclusive right that is
          allegedly infringed;
        </p>
        <p className="mt-8">
          2. Identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works at a single online site
          are covered by a single notification, arepresentative list of such
          works at that site;
        </p>
        <p className="mt-8">
          3. Identification of the material that is claimed to be infringing or
          to be the subject ofinfringing activity and that is to be removed or
          access to which is to be disabled, andinformation reasonably
          sufficient to permit findmyoptions.com LLC to locate thematerial;
        </p>
        <p className="mt-8">
          4. Information reasonably sufficient to permit findmyoptions.com LLC
          to contact you, suchas address, telephone number, and, if available,
          an electronic mail address at which youmay be contacted;
        </p>
        <p className="mt-8">
          5. A statement by you that you have a good faith belief that use of
          the material in themanner complained of is not authorized by the
          copyright owner, its agent, or the law; and
        </p>
        <p className="mt-8">
          6. A statement that the information in the notification is accurate
          and under penalty ofperjury, that you are the copyright owner or
          authorized to act on behalf of the owner of anexclusive right that is
          allegedly infringed.
        </p>
        <p className="mt-8">
          The Designated Agent for notice of copyright infringement claims may
          be reached as follows:
        </p>
        <p className="mt-8">findmyoptions.com LLC</p>
        <p className="mt-8">6844 Bardstown Rd #5178</p>
        <p className="mt-8">Louisville, KY 40291</p>
        <p className="mt-8">support@findmyoptions.com</p>
        <p className="text-lg font-bold mt-8">SMS Terms</p>
        <p className="mt-8">
          If you submit your phone number on findmyoptions.com LLC, you agree to
          receive textmessages in connection with your vehicle shopping
          interests. You may receive text messages from findmyoptions.com LLC,
          from findmyoptions.com LLC service provider(s), from avehicle’s seller
          or from any of seller’s agents. Text messages from a vehicle’s seller
          will be subject to the seller’s policies, procedures, and compliance
          protocols, of whichfindmyoptions.com LLC has no control.
        </p>
        <p className="mt-8">
          You can cancel the SMS service from findmyoptions.com LLC at any time.
          Just text "STOP" tothe short code. After you send the SMS message
          "STOP" to us, we will send you an SMS message to confirm that you have
          been unsubscribed from SMS messages fromfindmyoptions.com LLC. After
          this, you will no longer receive SMS messages from us. If you want to
          join again, just sign up as you did the first time and we will start
          sending SMS messagesto you again.
        </p>
        <p className="mt-8">
          If you are experiencing issues with the messaging program you can
          reply with the keyword HELP for more assistance.
        </p>
        <p className="mt-8">
          Carriers are not liable for delayed or undelivered messages.
        </p>
        <p className="mt-8">
          As always, message and data rates may apply for any messages sent to
          you from us and to us from you. If you have any questions about your
          text plan or data plan, it is best to contact your wireless provider
        </p>
        <p className="mt-8">
          If you have any questions regarding our privacy practices, please read
          our Privacy Policies
        </p>
        <p className="text-lg font-bold mt-8">
          Applicable Law and Jurisdiction
        </p>
        <p className="mt-8">
          The Site (excluding links to third-party sites) is owned and
          controlled by findmyoptions.com LLC, and operated by findmyoptions.com
          LLC from its offices in and around Lexington, KY. While
          findmyoptions.com LLC has made no effort to publish the Site
          elsewhere, because thisSite is published on the World Wide Web it is
          accessible in all fifty states of the United States, Canada and in
          other countries. Each of these places has laws that may differ from
          those of Kentucky and from each other. You and findmyoptions.com LLC
          both benefit from establishing a predictable legal environment in
          which to publish, access and use this Site. Therefore, by publishing,
          accessing, and/or using the Site, you and findmyoptions.com LLC
          explicitly agreethat all disputes, claims, or other matters arising
          from or relating to your use of this Site will be governed by the laws
          of the State of Kentucky, without regard to its conflicts of law
          principles. You agree that all claims you may have against
          findmyoptions.com LLC arising from or relating to the operation or use
          of this Site will be heard and resolved according to the Section below
          captioned “Dispute Resolution – Mandatory Arbitration and Class Action
          Waiver.” If you chooseto access this Site from locations other than
          Lexington, KY, you will be responsible for compliance with all local
          laws of such other jurisdiction.
        </p>
        <p className="mt-8">
          DISPUTE RESOLUTION – MANDATORY ARBITRATION AND CLASS ACTION WAIVERMOST
          CUSTOMER CONCERNS CAN BE RESOLVED QUICKLY AND TO THECUSTOMER'S
          SATISFACTION BY CONTACTING US. IN THE UNLIKELY EVENT THATOUR CUSTOMER
          SERVICE DEPARTMENT IS UNABLE TO RESOLVE YOURCONCERNS, WE EACH AGREE TO
          RESOLVE DISPUTES OR CLAIMS ARISING OUTOF, OR RELATING IN ANY WAY TO,
          THE TERMS AND CONDITIONS, THE SITE,CONTENT, OR YOUR USE OF THE SITE OR
          THE INTERACTIVE AREAS (“DISPUTES”)THROUGH BINDING ARBITRATION OR SMALL
          CLAIMS COURT, INSTEAD OF INCOURTS OF GENERAL JURISDICTION TO THE
          FULLEST EXTENT PERMITTED BYLAW, AND SUBJECT TO THE TERMS OF THIS
          AGREEMENT. ANY ARBITRATIONUNDER THIS AGREEMENT WILL TAKE PLACE ON AN
          INDIVIDUAL BASIS TO THEMAXIMUM EXTENT PERMITTED BY LAW. CLASS
          ARBITRATIONS, CLASS ACTIONS,PRIVATE ATTORNEY GENERAL ACTIONS OR
          REPRESENTATIVE ARBITRATIONSARE NOT PERMITTED. IN ARBITRATION, BOTH YOU
          AND findmyoptions.com LLCWILL BE ENTITLED TO RECOVER ATTORNEYS´ FEES
          FROM THE OTHER PARTY TOTHE SAME EXTENT AS YOU WOULD BE IN COURT.
        </p>
        <p className="text-lg font-bold mt-8">
          1. Notice of Claim and Required Information Dispute Resolution Process
        </p>
        <p>
          A party who intends to seek arbitration must first send to the other,
          by certified mail, a writtenNotice of Dispute (“Notice”). The Notice
          to findmyoptions.com LLC should be addressed to:findmyoptions.com LLC
          6844 Bardstown Rd #5178 Louisville, KY 40291, Attention: Legal(“Notice
          Address”). The Notice must describe the nature and basis of the claim
          or dispute and setforth the specific relief you seek from
          findmyoptions.com LLC (“Demand”). Iffindmyoptions.com LLC and you do
          not reach an agreement to resolve the claim within 60 daysafter the
          Notice is received, you or findmyoptions.com LLC may then commence an
          arbitrationproceeding.
        </p>
        <p className="text-lg font-bold mt-8">2. Arbitration Rules</p>
        <p>
          The arbitration will be administered by the American Arbitration
          Association (“AAA”) andconducted before a single arbitrator that you
          and findmyoptions.com LLC mutually selectpursuant to the AAA’s rules,
          including the AAA’s Supplementary Procedures forConsumer-Related
          Disputes, available at http://www.adr.org or by calling 800-778-7879.
        </p>
        <p>
          To begin an arbitration proceeding, you must submit the Dispute by
          utilizing the forms availableat http://www.adr.org, and simultaneously
          sending a copy of the completed form to the followingaddress: Attn:
          Support, findmyoptions.com LLC 6844 Bardstown Rd #5178 Louisville,
          KY40291. Payment of all filing, administration and arbitrator fees
          will be governed by the AAA'srules. The arbitration will be conducted
          based upon written submissions unless you requestand/or the arbitrator
          determines that a telephone or in-person hearing is necessary. If
          thearbitrator grants the request or determines an in-person hearing is
          necessary, the hearing willproceed in Lexington, Kentucky, unless the
          arbitrator determines or we agree that the mattershould proceed in the
          county in which you reside (which county must be in the
          continentalUnited States of America). The arbitration shall be
          governed by the laws of the State of Kentucky.
        </p>
        <p>
          The arbitrator will apply and be bound by this Agreement, apply
          applicable law and the facts,and issue a reasoned decision or award.
          All issues are for the arbitrator to decide, including thescope and
          enforceability of this arbitration provision as well as the
          Agreement´s other terms andconditions, and the arbitrator shall have
          exclusive authority to resolve any such dispute relatingto the scope
          and enforceability of this arbitration provision or any other term of
          this Agreementincluding, but not limited to any claim that all or any
          part of this arbitration provision orAgreement is void or voidable. In
          arbitration, and to the extent otherwise permitted by law, the parties
          may exchange “offers ofcompromise” or stipulate to judgments or awards
          in the same way the parties could in court.Such offers of compromise
          shall have the same force and effect as they would in a
          courtproceeding. The arbitration proceedings shall remain
          confidential, except for purposes of seekingcourt intervention (if
          necessary).
        </p>
        <p className="text-lg font-bold mt-8">3. No Class Action Matters</p>
        <p>
          We each agree that we shall bring any Dispute against the other in our
          respective individualcapacities and not as a plaintiff or class member
          in any purported class, consolidated orrepresentative proceeding, as a
          private attorney general or as an association. In addition, we
          eachagree that Disputes shall be arbitrated only on an individual
          basis and not in a class, consolidatedor representative action. The
          arbitrator shall not have the power to vary these provisions.
        </p>
        <p className="text-lg font-bold mt-8">
          4. Choice of Law and Forum; No Jury Trial
        </p>
        <p>
          If for any reason a Dispute proceeds in court: (a) you agree that any
          such Dispute may only beinstituted in a state or federal court of
          competent subject matter jurisdiction located in FayetteCounty,
          Kentucky; (b) you and findmyoptions.com LLC irrevocably consent and
          submit to theexclusive personal jurisdiction and venue of such courts
          for resolution of such Disputes, stipulateto the fairness and
          convenience of such courts and covenant not to assert any objection
          toproceeding in such courts; (c) you and findmyoptions.com LLC agree
          that the FederalArbitration Act, the AAA rules, applicable federal law
          and the laws of the State of Kentucky,without regard to principles of
          conflicts of law, will govern this Agreement and any Disputes;and (d)
          you and findmyoptions.com LLC agree to waive any right to a trial by
          jury.
        </p>
        <p className="text-lg font-bold mt-8">5. Injunctive Relief</p>
        <p>
          Notwithstanding anything to the contrary in this Agreement, either
          party may bring suit in courtseeking an injunction or other equitable
          relief arising out of or relating to the infringement of aparty’s
          intellectual property or any conduct that violates the ”Ownership And
          IntellectualProperty” section of the Terms of Use(“Your Conduct”).
        </p>
        <p className="text-lg font-bold mt-8">6. Severability</p>
        <p>
          You use the Internet solely at your own risk and subject to all
          applicable local, state, national,and international laws and
          regulations. Although findmyoptions.com LLC has endeavored tocreate a
          secure and reliable Site, the confidentiality of any communication or
          materialtransmitted to/from this Site over the Internet cannot be
          guaranteed. Accordingly,findmyoptions.com LLC is not responsible for
          the security of any information transmitted via theInternet, the
          accuracy of the information contained on the Site, or for the
          consequences of anyreliance on such information. findmyoptions.com LLC
          shall have no liability for interruptions oromissions in Internet,
          network or hosting services. You assume the sole and complete risk
          ofusing this Site.
        </p>
        <p className="text-lg font-bold mt-8">Modifications to the Site</p>
        <p>
          findmyoptions.com LLC may change, restrict access to, suspend, or
          discontinue any aspect ofthe findmyoptions.com service or the Site at
          any time, including availability of any feature,database, or content.
          findmyoptions.com LLC may also impose limits on certain features
          andservices or restrict your access to all or parts of the
          findmyoptions.com web site without noticeor liability.
          findmyoptions.com LLC may remove any review from the Site if
          findmyoptions.comLLC determines, in its sole discretion, that the
          review may be false or fraudulent.findmyoptions.com LLC may exclude or
          remove vehicle prices from the Site iffindmyoptions.com LLC
          determines, in its sole discretion, that the prices violate Guidelines
          forDealer Pricing and Marketing. findmyoptions.com LLC reserves the
          right (but assumes no obligation) to delete, move,condense or edit any
          content on the Site that comes to findmyoptions.com LLC' attention
          andthat findmyoptions.com LLC considers unacceptable or inappropriate,
          whether for legal or otherreasons.
        </p>
        <p className="text-lg font-bold mt-8">Termination</p>
        <p>
          You agree that we may, under some circumstances and without prior
          individual notice to you,terminate your use of and access to any of
          the parts of the Site to which we restrict access, forexample, by
          requiring registration. Some of the reasons for such termination may
          include, but arenot limited to, (a) a breach or violation or suspected
          breach or violation of these Terms andConditions or other incorporated
          terms or guidelines, (b) a request by law enforcement oranother
          government agency, (c) our decision to discontinue or change all or
          part of the Site, (d)technical or security issues, and (e) fraudulent
          or illegal activities. All terminations will be madein our sole
          discretion and you agree that we will not be liable for any
          termination of your use ofor access to the Site or any part of the
          Site.
        </p>
        <p className="text-lg font-bold mt-8">Miscellaneous</p>
        <p>
          This agreement may not be assigned by you without the prior written
          consent offindmyoptions.com LLC. Any attempted assignment that does
          not comply with these Terms andConditions shall be null and void.
          findmyoptions.com LLC may assign these Terms andConditions, in whole
          or in part, to any third-party in its sole discretion. These terms
          andConditions shall insure to the benefit of and be binding upon the
          parties hereto, and theirpermitted successors, permitted assigns,
          heirs, and legal and personal representatives. If anyprovisions of
          this agreement are held to be invalid or unenforceable by an
          arbitrator or a court ofcompetent jurisdiction, any such provision(s)
          shall be enforced to the maximum extentpermissible so as to effect the
          intent of the parties and shall not affect the validity
          orenforceability of any other provisions herein, and the remainder of
          this Agreement shall continuein full force and effect. Any waiver of
          any provision herein, to be effective, must be in writingsigned by an
          authorized officer of findmyoptions.com LLC, and shall not constitute
          a waiver ofany other provision herein or a continuing waiver of any
          future breaches of the same provision.
        </p>
        <p className="text-lg font-bold mt-8">
          Entire Agreement/Reservation of Rights
        </p>
        <p>
          These Terms and Conditions and findmyoptions.com LLC Privacy Policy
          represent the entireagreement between you and findmyoptions.com LLC
          with respect to your access to or use of anymaterial available on or
          through the Site, and it supersedes all prior or
          contemporaneouscommunications and proposals, whether electronic, oral,
          or written between you andfindmyoptions.com LLC with respect to same.
          Any rights not expressly granted under thisAgreement are reserved.
        </p>
      </Typography>
    </div>
  );
}
