import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, Button } from "@nextui-org/react";
import { Typography } from "components";
import downArrow from "./assets/svg/down_icon_white.svg";
import dropIconBlack from "./assets/svg/drop_icon_black.svg";

import TierDetail from "./TierDetail";
import TierCards from "./TierCards";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import UsedCardForm from "modules/shared/features/UsedCarForm/UsedCarForm";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  tierServices: TierService[];
}
interface TierService {
  title: string;
  tiers: Tier[];
}
interface Tier {
  title: string;
  subTitle: string;
  
}

export default function TierServicesSection(props: Props) {
  const [selectedKeys, setSelectedKeys] = useState<any>(new Set([]));
  const [openModal, setOpenModal] = useState(0);
  
  // const navigate = useNavigate();
  // const location = useLocation()
  const handleNextThankyou = async () => {
    try {
      // const userRole = getUserRoles();
      // if (userRole === "User") {
      //   navigate("/user/benefits/repair-agreement");
      // } else {
      //   navigate("/register/user");
      // }
      setOpenModal(0);
    } catch (e) {
      setOpenModal(0);
      console.log(e);
    }
  };

  const handleOpenSuccess = () => {
    setOpenModal(2);
    // navigate(
    //   `/listing/membership-advantage/used-car-warranty/thank-you/RMD`
    // );
  } 
  // useEffect(()=>{
  //   if (location.pathname.includes('/listing/dealer-services/dealer-services-pricing/thank-you/RMD')) {
  //     setOpenModal(2);
  //     navigate(
  //       `'/listing/dealer-services/dealer-services-pricing/thank-you/RMD'`
  //     );
  //   }else if (location.pathname.includes('/listing/dealer-services/dealer-services-pricing/request-demo-dealer')) {
  //     setOpenModal(1);
  //     navigate(
  //       `'/listing/dealer-services/dealer-services-pricing/request-demo-dealer'`
  //     );
  //   } 
    
  // },[])
  
  // const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set([]));
  // console.log("selectedKeys >>>>>>>>>", selectedKeys, selectedKeys.has("0"));

  // const [clickTier, setClickTier] = useState([
  //   { tier: 0, isClick: false },
  //   { tier: 1, isClick: false },
  //   { tier: 2, isClick: false },
  //   { tier: 3, isClick: false },
  // ]);
  // const handleSelectionChange = (key: string) => {
  //   console.log("handleSelectionChange >>>>>", );

  //   if (selectedKeys.has(key)) {
  //     const currSet = new Set(selectedKeys);
  //     currSet.delete(key)
  //     setSelectedKeys(currSet);
  //   } else {
  //     setSelectedKeys(new Set(selectedKeys.add(key)));
  //   }
  // };

  return (
    <>
      {props.tierServices.map(({ title, tiers }, index) => (
        <div key={index} className="w-full">
          <div className="pt-5 pb-5">
            <Typography
              variant="custom"
              className="text-center text-3xl 2xl:text-5xl  font-bold mb-10 
              bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text"
            >
              {title}
            </Typography>
          </div>
          <div className="flex flex-col items-center my-6 justify-center gap-4 text-center">
            <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[rgb(7,12,132)] text-transparent bg-clip-text">
              Unlock Significant Cost Savings and Streamlined Operations
            </h2>
            <p className="font-[Metropolis] font-[600] text-left">
              Embrace the FMO difference and experience the unparalleled
              benefits of our innovative dealer solutions. Our comprehensive
              package offers extreme efficiencies and monthly cost savings,
              freeing up resources for business growth.
            </p>
          </div>
          <div>
            <div className="flex flex-col items-center my-6 justify-center gap-4 text-center">
              <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[rgb(7,12,132)] text-transparent bg-clip-text">
                Experience the FMO Difference and Transform Your Business
              </h2>
              <p className="font-[Metropolis] font-[600] text-left">
                Join the growing number of dealers who have embraced the FMO
                advantage and are reaping the rewards of streamlined operations,
                reduced costs, and increased sales. Contact us today to learn
                more about how our solutions can elevate your business to new
                heights.
              </p>
            </div>
          </div>
          <div></div>
          <Accordion
            selectedKeys={selectedKeys}
            // onSelectionChange={handleSelectionChange}
            onSelectionChange={setSelectedKeys}
            showDivider={false}
            selectionMode="multiple"
            className="p-0"
          >
            {tiers.map(
              ({ title: tierTitle, subTitle,}, idx) => {
                return (
                  <AccordionItem
                    key={idx.toString()}
                    // onPressEnd={() => {
                    //   setClickTier((prev) => {
                    //     const newState = [...prev];
                    //     newState[idx].isClick = !newState[idx].isClick;
                    //     return newState;
                    //   });
                    // }}

                    aria-label={"Accordion " + idx}
                    indicator={<></>}
                    title={
                      <div
                        key={idx}
                        className={`relative w-[101%] ${
                          selectedKeys.has(idx.toString())
                            ? "bg-gradient-to-r from-[#018FFD] to-[#070C84] text-white p-4 rounded-lg"
                            : "bg-white text-black p-4 rounded-lg border-2 border-sky-500	"
                        }`}
                      >
                        <div>
                          <p
                            className={`pt-1 font-light text-xl pr-10 text-wrap`}
                          >
                            {tierTitle}
                          </p>
                        </div>
                        <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-10 text-white">
                          {selectedKeys.has(idx.toString()) ? (
                            <img
                              src={downArrow}
                              alt="down arrow"
                              className="w-6 h-6 cursor-pointer"
                            />
                          ) : (
                            <img
                              src={dropIconBlack}
                              alt="down arrow"
                              className="w-6 h-6 cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                    }
                    className="w-full text-white rounded-lg"
                  >
                    {selectedKeys.has(idx.toString()) ? (
                      <React.Fragment key={idx}>
                        <Typography
                          variant="custom"
                          className="text-[#2F2F2F] text-xl font-normal text-wrap px-5"
                        >
                          <p className={`pt-1 text-black font-light text-xl `}>
                            {subTitle}
                          </p>
                        </Typography>

                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </AccordionItem>
                );
              }
            )}
          </Accordion>

         
        </div>
      ))}

      <div className="flex justify-center">
      <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 text-base sm:text-xl font-bold tracking-wide px-12 mt-10 rounded-xl h-[54px]"
            type="button"
            size="md"
            onClick={() => setOpenModal(1)}
          >

            Request Demo

          </Button>

          <CustomizableHeaderModel
          size="2xl"
        isOpen={openModal !== 0}
        onOpen={() => {
          setOpenModal(0);
          // navigate("/listing/dealer-services/dealer-services-pricing");
        }}
        Component={
          openModal === 1 ? (
            <UsedCardForm
              handleNext={handleOpenSuccess}
            />
          ) : (
            <div className="mt-4">
            <ThankYou buttonText="" onClick={handleNextThankyou} />
            </div>
          )
        }
      />

      </div>
      
      {/* <div className="mt-28 mb-[100px] mx-16">
          <TierCards mainClass="justify-items-center w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 place-items-center gap-16 2xl:gap-36	"/>
        </div> */}
    </>
  );
}
