import * as React from "react";
import { Card, CardBody } from "@nextui-org/react";

import { Typography } from "components";
import { useState } from "react";
import { money } from "utils/money";

interface RetailValueEstimateProps {
  className?: string;
  retailEstimate?: any;
  onCallBack?: (period: number) => void;
}
const options = [
  { label: "3 months", key: 90 },
  { label: "6 months", key: 180 },
  { label: "9 months", key: 270 },
];
export const RetailValueEstimate: React.FC<RetailValueEstimateProps> = ({
  className,
  retailEstimate,
  onCallBack,
}) => {
  const { average, below, above } = retailEstimate?.prices ?? {
    average: 0,
    below: 0,
    above: 0,
  };

  const [selectedOption, setSelectedOption] = useState(options[1].label);

  const handleSelectChange = (event: any) => {
    const val = event.target.value || options[1].key;
    setSelectedOption(val);
    const period = options.filter((e) => e.label === val)[0].key;
    onCallBack && onCallBack(period);
  };

  return (
    <Card className="mt-10 bg-[#F1F1F180]" fullWidth isPressable={false}>
      <CardBody className="flex justify-center items-center mt-2 md:px-20">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 justify-items-center">
          <div className="md:col-start-2 md:col-span-4">
            <Typography
              variant="titleWithGradient"
              className="px-6 py-3 text-lg md:text-2xl font-[600] text-center"
            >
              Our Retail Value Estimate
            </Typography>
          </div>

          <div className="md:ml-16 mt-auto">
            <div className="flex items-center align-center border border-gray-500 w-full md:w-[120px] h-[40px] rounded-lg p-2 ">
              <select
                defaultValue={selectedOption}
                onChange={handleSelectChange}
                className="w-full md:w-[115px] h-[36px]  focus:outline-none"
              >
                {options.map((option: any, key: number) => (
                  <option key={key}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="md:w-full h-full my-5 mx-2 md:mx-0">
          <div className=" flex justify-center mt-5 overflow-hidden gap-4 md:gap-10 items-end">
            <div className="flex flex-col items-center">
              <Typography
                variant="custom"
                className="text-center text-[#018FFD] font-[600] text-xs md:text-lg mb-2"
              >{`${money(below)} or Less`}</Typography>
              <div className="h-[70px] md:h-[90px] w-full md:w-[140px] bg-gradient-to-b from-[#018FFD] to-[#0666B1] rounded-sm  border-b-2"></div>
              <Typography
                variant="custom"
                className="text-center text-[#018FFD] font-[600] text-md md:text-xl"
              >
                Low Price
              </Typography>
            </div>
            <div className="flex flex-col items-center ml-4 md:ml-0">
              <Typography
                variant="custom"
                className="text-center text-[#00DC58] font-[700] text-xs md:text-lg mb-2"
              >{`Around $${money(average)}`}</Typography>
              <div className="h-[140px] md:h-[180px] w-full  md:w-[140px]  bg-gradient-to-b from-[#00DC58] to-[#04A042] rounded-sm  border-b-2"></div>
              <Typography
                variant="custom"
                className="text-center text-[#00DC58] font-[600] text-md md:text-xl"
              >
                Good Price
              </Typography>
            </div>
            <div className="flex flex-col items-center ml-4 md:ml-0">
              <Typography
                variant="custom"
                className="text-center text-[#FF0A0A] font-[600]  text-xs md:text-lg mb-2"
              >{`${money(above)} or More`}</Typography>
              <div className="h-[70px] md:h-[90px] w-full md:w-[140px] bg-gradient-to-b from-[#FF0A0A] to-[#990606] rounded-sm  border-b-2"></div>
              <Typography
                variant="custom"
                className="text-center text-[#FF0A0A] font-[600] text-md md:text-xl"
              >
                High Price
              </Typography>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
