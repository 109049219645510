import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  MouseEvent,
} from "react";
import CloseIcon from "../../modules/shared/components/CloseIcon";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import axios from "axios";
import Input from "components/ui/input";
import Textarea from "components/ui/textarea";
import { env } from "config/env";
interface TicketProps {
  setCurrScreen: (screen: string) => void;
  handleScreenChange: () => void;
}

interface FormData {
  subject: string;
  email: string;
  phone: string;
  description: string;
}
const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePhone = (phone: string): boolean => {
  const re = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return re.test(phone);
};

const Contact: React.FC<TicketProps> = ({
  setCurrScreen,
  handleScreenChange,
}) => {
  const [formData, setFormData] = useState<FormData>({
    subject: "",
    email: "",
    phone: "",
    description: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [success, setSuccess] = useState<boolean>(false);
  const ticketContentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ticketContentRef.current &&
      !ticketContentRef.current.contains(event.target as Node)
    ) {
      handleScreenChange();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside as any);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside as any);
    };
  }, [ticketContentRef, handleScreenChange]);

  const validateInput = (name: string, value: string): string => {
    switch (name) {
      case "subject":
        return value.trim().length > 0 && value.trim().length <= 50
          ? ""
          : "Subject cannot be more than 50 words.";
      case "email":
        return validateEmail(value)
          ? ""
          : "Please enter a valid email address.";
      case "phone":
        return validatePhone(value) ? "" : "Please enter a valid phone number.";
      case "description":
        return value.trim().length >= 50
          ? ""
          : "Description must be at least 50 words.";
      default:
        return "";
    }
  };

  const handleSubmit = async () => {
    const subjectError = validateInput("subject", formData.subject);
    const emailError = validateInput("email", formData.email);
    const phoneError = validateInput("phone", formData.phone);
    const descriptionError = validateInput("description", formData.description);

    if (subjectError || emailError || phoneError || descriptionError) {
      setErrors({
        subject: subjectError,
        email: emailError,
        phone: phoneError,
        description: descriptionError,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${env.BASE_API_URL}Support/SupportRequest`,
        formData
      );
      console.log("Response:", response.data);
      setSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setSuccess(false);
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const error = validateInput(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const isFormComplete =
    Object.values(formData).every((value) => value.trim() !== "") &&
    Object.values(errors).every((error) => error === "");

  return (
    <div className="flex justify-center items-center min-h-screen max-h-screen">
      <div
        ref={ticketContentRef}
        id="ticket-content"
        className="min-[768px]:w-[400px] min-[768px]:h-[580px] min-[768px]:rounded-[50px] min-[768px]:mt-32 min-[768px]:mr-8 max-[768px]:w-[400px] max-[768px]:h-[500px] bg-[#fff] max-[768px]:rounded-[50px] m-0 md:m-5"
      >
        <div className="relative flex  md:h-[100px] grad max-[768px]:rounded-t-[50px] rounded-t-[50px] h-[80px]">
          <div
            className="ml-[9px] mt-[15px] md:ml-[39px] md:mt-[39px] "
            onClick={() => {
              setCurrScreen("help");
            }}
          ></div>
          <div className="-ml-3 md:ml-[-45px] flex items-center justify-center w-full text-[15px] max-[768px]:text-[20px] md:text-[20px] font-extrabold text-white gap-2 max-[768px]:gap-1 ">
            <span className="">
              <img
                className=" w-[20px] h-[20px] md:w-[32px] md:h-[32px] max-[768px]:w-[32px] max-[768px]:h-[32px]"
                src={process.env.PUBLIC_URL + "/assets/icons/ticket.svg"}
                alt=""
                onClick={handleScreenChange}
              />
            </span>
            <div>Create a Ticket</div>
          </div>
          <div className="absolute right-0 mr-[9px] mt-[15px] md:mr-[39px] md:mt-[39px] max-[768px]:mr-[39px] max-[768px]:mt-[39px] ">
            <div
              className="inline-block w-[8px] h-[8px] md:w-[17px] md:h-[17px] max-[768px]: w-[17px] max-[768px]: h-[17px] relative cursor-pointer"
              onClick={handleScreenChange}
            >
              <CloseIcon stroke="#FFF" strokeWidth="4.028" />
            </div>
          </div>
        </div>
        {success ? (
          <div className="overflow-y-auto mt-[0px] h-[450px] rounded-b-[50px] flex flex-col justify-center items-center w-full text-white bg-gradient-to-r from-blue-500 to-indigo-700">
            <div className="flex items-center justify-center text-white text-[150px] -mt-10 max-[768px]:text-[120px]">
              <IoIosCheckmarkCircleOutline />
            </div>
            <div className="relative text-[22px] text-center w-2/3 text-white mt-8 font-[Metropolis-Bold]">
              Your ticket has been raised. We'll get back to you shortly.
            </div>
          </div>
        ) : (
          <div className="overflow-y-auto max-h-[65vh]">
            <div className="flex flex-col gap-3 md:gap-2  my-5 w-full overflow-hidden">
              <div className=" relative  w-full">
                <div className="relative flex items-center mx-[5%] w-full h-full">
                  <Input
                    variant="ticket"
                    type="text"
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16",
                      inputWrapper: !!errors.subject ? "border-red-500" : "",
                    }}
                    placeholder="Subject"
                    startContent={
                      <span
                        className={`absolute pl-8 md:-pl-4 ${
                          errors?.subject?.length > 0 ? "pb-3 md:pb-0" : ""
                        }`}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/icons/subject.svg"
                          }
                          className="md:h-[20px] md:w-[20px]"
                          alt="car"
                        />
                      </span>
                    }
                    value={formData.subject}
                    name="subject"
                    onChange={handleInputChange}
                  />
                </div>
                {errors.subject && (
                  <p className="text-red-500 text-sm mx-[5%] mt-1">
                    {errors.subject}
                  </p>
                )}
              </div>
              <div className="-mt-[2px] relative  w-full">
                <div className="relative flex items-center mx-[5%] w-full h-full">
                  <Input
                    variant="ticket"
                    type="text"
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16",
                      inputWrapper: !!errors.email ? "border-red-500" : "",
                    }}
                    placeholder="Email"
                    startContent={
                      <span
                        className={`absolute pl-8 md:-pl-4 ${
                          errors?.email?.length > 0 ? "pb-3 md:pb-0" : ""
                        }`}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/email-gray.svg"
                          }
                          className="md:h-[20px] md:w-[20px]"
                          alt="car"
                        />
                      </span>
                    }
                    value={formData.email}
                    name="email"
                    onChange={handleInputChange}
                  />
                </div>
                {errors.email && (
                  <p className="text-red-500 text-sm mx-[5%] mt-1">
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="-mt-[2px] relative  w-full">
                <div className="relative flex items-center mx-[5%] w-full h-full">
                  <Input
                    variant="ticket"
                    type="text"
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16",
                      inputWrapper: !!errors.phone ? "border-red-500" : "",
                    }}
                    placeholder="Phone"
                    startContent={
                      <span
                        className={`absolute pl-8 md:-pl-4 ${
                          errors?.phone?.length > 0 ? "pb-3 md:pb-0" : ""
                        }`}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/phone-gray.svg"
                          }
                          className="md:h-[20px] md:w-[20px]"
                          alt="car"
                        />
                      </span>
                    }
                    value={formData.phone}
                    name="phone"
                    onChange={handleInputChange}
                  />
                </div>
                {errors.phone && (
                  <p className="text-red-500 text-sm mx-[5%] mt-1">
                    {errors.phone}
                  </p>
                )}
              </div>
              <div className="-mt-[2px] relative  w-full">
                <div className="relative flex items-center mx-[5%] w-full h-full">
                  <Textarea
                    rows={6}
                    cols={50}
                    variant="ticket"
                    type="text"
                    classNames={{
                      input:
                        "data-[has-start-content=true]:ps-16 overflow-y-auto max-h-32",
                      inputWrapper: !!errors.description
                        ? "border-red-500"
                        : "",
                    }}
                    className="fixed-height-textarea overflow-y-auto max-h-32"
                    placeholder="Description"
                    startContent={
                      <span
                        className={`absolute top-[14px] pl-8 md:-pl-4 ${
                          errors?.description?.length > 0 ? "pb-3 md:pb-0" : ""
                        }`}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/description.svg"
                          }
                          className="md:h-[20px] md:w-[20px]"
                          alt="car"
                        />
                      </span>
                    }
                    value={formData.description}
                    name="description"
                    onChange={handleInputChange}
                  />
                </div>
                {errors.description && (
                  <p className="text-red-500 text-sm mx-[5%] mt-1">
                    {errors.description}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
        {!success && (
          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              className="md:mt-[0px] text-center text-white w-[90%] md:w-[90%] h-[50px] md:h-[60px]  md:text-[15px] font-bold  rounded-[12px] md:rounded-[18px] chatsuppbtn "
            >
              <span className="">Send</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
