import React, { useState } from "react";
import archiveIcon from "../assets/png/archive-icon.png";
import {
  changeMileageForManageList,
  changeVinNumberForManageList,
} from "modules/dealer/store/manage-list";
import { useDispatch } from "react-redux";
import {
  useUpdateListingStatus,
} from "services/listings.service";
import Loading from "components/Loading/Loading";
import { env } from "config/env";
import { Card, CardBody, Image } from "@nextui-org/react";
import { isThisDealer } from "utils/get-user-id";

interface Props {
  car: any;
  refetch: () => void;
}
export default function Car(props: Props) {
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);
  // const [images, setImages] = useState<string[]>([]);

  // const {
  //   mutateAsync,
  //   isPending: isPendingUpdateListing,
  //   isSuccess,
  // } = useUpdateListingData();

  const { car : listing } = props;
  const { mutateAsync, isPending: isPendingUpdateListing } =
    useUpdateListingStatus(Number(listing?.listingId), 0);

  const [isHover, setHover] = useState(false);
  const numberFormatter = (price: number) => {
    return price.toLocaleString();
  };

  // const { data: listingDetailsData } = useGetListingDetails(
  //   Number(car?.listingId) ?? 0
  // );
  // const listing = listingDetailsData?.listing;
  // const snapshot = listingDetailsData?.snapshot;

  // const plotImages = useCallback(
  //   (snapshot: ListingDetailsSnapshot[] | undefined) => {
  //     if (snapshot) {
  //       for (const ss of snapshot) {
  //         if (ss.value) {
  //           const filteredImages = findImage(ss?.value);
  //           if (filteredImages)
  //             setImages((prev: any[]) => prev.concat(filteredImages));
  //         }
  //       }
  //     }
  //   },
  //   []
  // );

  // useEffect(() => {
  //   plotImages(snapshot);
  // }, [plotImages, snapshot, snapshot?.length]);

  const editListing = (vinNumber: string, listingId: number) => {
    dispatch(changeVinNumberForManageList(vinNumber || null));
    dispatch(changeMileageForManageList(200000));
    window.open(
      `/#/${isThisDealer()?"dealer":"user"}/manage-listing/${vinNumber}/${listing?.mileage||0}/${listingId}/`,
      "_blank"
    );
  };
  const viewListing = (listingId: number) => {
    window.open(`/#/listing/view-listing/${listingId}/`, "_blank");
  };

  const archiveListing = async () => {
    setIsUpdated(true);
    
    
    const res = await mutateAsync();
    if (res) {
      setIsUpdated(false);
      props.refetch()!;
      if(isThisDealer()){
        window.open("/#/dealer/manage-inventory/archived-and-sold", "_blank");
      }
    }
  };

  return (
    <Card
      className="border border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors rounded-2xl"
      shadow="none"
      fullWidth
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardBody className="flex flex-col">
        <div className="w-full">
          {isUpdated && <Loading />}
          {listing && (
            <div className=" rounded-xl relative overflow-hidden group w-full">
              {listing?.listingId && (
                <Image
                  className={`h-[218px] z-0 rounded-xl object-cover w-full ${
                    isHover &&
                    "transition-transform  duration-300 transform scale-105"
                  }`}
                  radius="none"
                  removeWrapper={true}
                  src={`${env.BASE_API_URL}Listings/image/${listing.listingId}`}
                  alt="Car Image"
                />
              )}

              <div className={`pl-2 bg-white h-auto rounded-b-[10px] px-0`}>
                {!isHover && (
                  <div className="pt-1">
                    <div className="w-[100%] text-[#263238]">
                      <div className=" text-2xl sm:text-xl md:text-2xl font-bold text-[#263238] overflow-hidden whitespace-nowrap overflow-ellipsis">
                        ${numberFormatter(listing?.price ?? 0)}
                      </div>
                      <div className="text-base sm:text-sm md:text-lg font-semibold overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {listing?.year ?? ""} {listing?.make ?? " "}{" "}
                        {listing?.model ?? ""}
                      </div>
                      <div className="text-base sm:text-sm md:text-lg font-semibold overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {listing?.trim ?? ""}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={`rounded-xl  absolute bottom-0 left-0 right-0 bg-gradient-to-t from-[#070c84]/80 to-[#018ffd]/80 text-white transition-transform transition-height duration-900 ease-in-out ${
            isHover ? "scale-100 h-full" : "scale-0 h-0"
          }`}
        >
          {isHover && (
            <div className="h-full rounded-xl ">
              <div className="h-3/4 flex items-center justify-center">
                <div className="cursor-pointer">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icon/edit-btn.svg"}
                    alt="edit"
                    className=" h-[50px] w-[50px]"
                    onClick={() =>
                      editListing(listing?.vin ?? "", listing?.listingId ?? 0)
                    }
                  />
                </div>
                <div className="pl-3 pr-3 cursor-pointer">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/preview-btn.svg"
                    }
                    alt="view"
                    className=" h-[50px] w-[50px]"
                    onClick={() => viewListing(listing?.listingId ?? 0)}
                  />
                </div>
                <div className="bg-white rounded-full p-2.5 cursor-pointer">
                  <img
                    src={archiveIcon}
                    alt="archive"
                    className="w-[25px] h-[25px]"
                    onClick={() => archiveListing()}
                  />
                </div>
              </div>
              <div className="text-start absolute bottom-[20px] p-5">
                <div className="text-2xl font-bold ">
                  ${numberFormatter(listing?.price ?? 0)}
                </div>
                <div className="text-md font-semibold ">
                  {listing?.year ?? ""} {listing?.make ?? ""}{" "}
                  {listing?.model ?? ""}
                </div>
                <div className="text-md font-semibold">
                  {listing?.trim ?? ""}
                </div>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
