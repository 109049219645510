import { SVGProps } from "react";

export const CashIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="tabler:cash">
      <g id="Group">
        <path
          id="Vector"
          d="M8.75 13.75C8.75 13.087 9.01339 12.4511 9.48223 11.9822C9.95107 11.5134 10.587 11.25 11.25 11.25H23.75C24.413 11.25 25.0489 11.5134 25.5178 11.9822C25.9866 12.4511 26.25 13.087 26.25 13.75V21.25C26.25 21.913 25.9866 22.5489 25.5178 23.0178C25.0489 23.4866 24.413 23.75 23.75 23.75H11.25C10.587 23.75 9.95107 23.4866 9.48223 23.0178C9.01339 22.5489 8.75 21.913 8.75 21.25V13.75Z"
          stroke="#2C2C2C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M21.25 11.25V8.75C21.25 8.08696 20.9866 7.45107 20.5178 6.98223C20.0489 6.51339 19.413 6.25 18.75 6.25H6.25C5.58696 6.25 4.95107 6.51339 4.48223 6.98223C4.01339 7.45107 3.75 8.08696 3.75 8.75V16.25C3.75 16.913 4.01339 17.5489 4.48223 18.0178C4.95107 18.4866 5.58696 18.75 6.25 18.75H8.75"
          stroke="#2C2C2C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2782 14C17.4315 14 17.5557 14.1242 17.5557 14.2775V14.895C17.7556 14.9109 17.9525 14.9568 18.1397 15.0317C18.414 15.1414 18.6606 15.3105 18.8616 15.5269C19.0496 15.7292 19.038 16.0456 18.8357 16.2336C18.6334 16.4215 18.317 16.4099 18.129 16.2076C18.0286 16.0995 17.9054 16.015 17.7683 15.9602C17.6313 15.9053 17.4838 15.8816 17.3365 15.8906C17.3136 15.892 17.2905 15.8918 17.2676 15.8901C17.0535 15.8736 16.8414 15.9417 16.677 16.0799C16.5256 16.2072 16.4257 16.3845 16.3947 16.5788C16.4273 16.7733 16.5282 16.9506 16.6801 17.0783C16.8449 17.2168 17.0571 17.2861 17.272 17.2714C17.745 17.2392 18.2122 17.3916 18.5752 17.6966C18.9382 18.0017 19.1688 18.4356 19.2186 18.9072C19.2222 18.942 19.2222 18.9772 19.2186 19.012C19.1688 19.4836 18.9382 19.9175 18.5752 20.2226C18.2852 20.4663 17.9286 20.6126 17.5557 20.6451V21.4931C17.5557 21.6464 17.4315 21.7706 17.2782 21.7706C17.1249 21.7706 17.0007 21.6464 17.0007 21.4931V20.6351C16.811 20.6115 16.6252 20.5605 16.4492 20.483C16.1731 20.3616 15.9285 20.1785 15.7342 19.9478C15.5563 19.7366 15.5833 19.4212 15.7945 19.2433C16.0056 19.0654 16.3211 19.0923 16.499 19.3035C16.5949 19.4174 16.7156 19.5077 16.8519 19.5677C16.9882 19.6277 17.1364 19.6556 17.2851 19.6494C17.3034 19.6486 17.3217 19.6489 17.34 19.6501C17.5548 19.6648 17.767 19.5956 17.9319 19.457C18.0832 19.3298 18.1839 19.1534 18.2168 18.9596C18.1839 18.7659 18.0832 18.5894 17.9319 18.4622C17.767 18.3237 17.5548 18.2544 17.34 18.2691C16.8669 18.3013 16.3997 18.1489 16.0367 17.8439C15.6737 17.5388 15.4431 17.1049 15.3934 16.6333C15.3899 16.5999 15.3897 16.5662 15.3929 16.5328C15.4388 16.0584 15.6688 15.6209 16.0337 15.3143C16.31 15.0821 16.647 14.939 17.0007 14.8991V14.2775C17.0007 14.1242 17.1249 14 17.2782 14Z"
        fill="#2C2C2C"
      />
    </g>
  </svg>
);
