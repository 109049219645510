import { z } from "zod";

export const UserRegisterSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, "FirstName is Required")
    .min(2, "At least 2 Characters is Required")
    .refine(
      (value) => /^[a-zA-Z]+$/.test(value),
      "Name can only contains Alphabetic Characters."
    ),
  lastName: z
    .string()
    .trim()
    .min(1, "LastName is Required")
    .min(2, "At least 2 Characters is Required")
    .refine(
      (value) => /^[a-zA-Z]+$/.test(value),
      "Name can only contains Alphabetic Characters."
    ),
  phoneNumber: z
  .string({ required_error: "Phone Number is Required" })
  .trim()
  .min(1, "Phone Number is Required")
  .refine(
    (value) =>
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
    "Please provide valid US Phone Number"
  ),
  email: z
    .string()
    .min(1, "Email is Required")
    .email({ message: "Please provide a valid Email" }),
  // zipcode: z
  //   .string()
  //   .trim()
  //   .min(1, "ZIP code is Required")
  //   .refine(
  //     (value) => /^\d{5}(-\d{4})?$/.test(value),
  //     "ZIP code should be in XXXXX or XXXXX-XXXX format"
  //   ),
  password: z
    .string()
    .trim()
    .min(8, "Password must be at least 8 characters long")
    .refine((value) => {
      const hasLowercase = /[a-z]/.test(value);
      const hasUppercase = /[A-Z]/.test(value);
      const hasNumber = /\d/.test(value);
      const hasSpecialChar = /[_=+@$!%*?&]/.test(value);
      console.log({ hasSpecialChar });
      console.log({ hasNumber });
      console.log({ hasNumber });
      console.log({ hasLowercase });
      let proceed = false;
      if (hasLowercase && hasUppercase && hasNumber && hasSpecialChar) {
        proceed = true;
      }
      return proceed;
    }, "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"),
});

export const UserLoginSchema = z.object({
  username: z.string().min(1, "Username is Required"),
  password: z.string().min(1, "Password is Required"),
});

export const DealerRegisterSchema = z.object({
  dealershipName: z
    .string()
    .trim()
    .min(1, "Dealership Name is Required")
    .min(2, "At least 2 Characters is Required"),
  firstName: z
    .string()
    .trim()
    .min(1, "First/Last Name is Required")
    .min(2, "At least 2 Characters is Required")
    .refine(
      (value) => /^[a-zA-Z]+$/.test(value),
      "Name can only contains Alphabetic Characters."
    ),
  phoneNumber: z
    .string({ required_error: "Phone Number is Required" })
    .trim()
    .min(1, "Phone Number is Required")
    .refine(
      (value) =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
      "Please provide valid US Phone Number"
    ),

  stateLicenseNumber: z
    .string()
    .trim()
    .min(1, "State Dealer License Number is Required")
    .min(2, "At least 2 Characters is Required"),
  email: z
    .string()
    .min(1, "Email is Required")
    .email({ message: "Please provide a valid Email" }),
  username: z
    .string()
    .trim()
    .min(1, "Desired Username is Required")
    .min(8, "At least 8 Characters is Required"),
  physicalAddress: z
    .string({ required_error: "Physical Address is Required" })
    .trim()
    .min(1, "Physical Address is Required"),
  password: z
    .string()
    .trim()
    .min(1, "Desired Password is Required")
    .refine(
      (value) => /^(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/.test(value),
      "At least 8 Characters with 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Characters is Required"
    ),
    zipCode:z
    .string()
    .trim()
    .min(4, "Zip Code is Required"),
});

export const DealerLoginSchema = z.object({
  username: z.string().min(1, "Username is Required"),
  password: z.string().min(1, "Password is Required"),
});

// TODO: Remove this after we're done pushing the commit to trigger the testing build
