import { SVGProps } from "react";

export const ChatIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="71"
    height="71"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4767 53.0492V64.0335L19.8955 61.3822L33.7848 53.0492H47.5604C50.7775 53.0492 53.3938 50.4329 53.3938 47.2158V23.8822C53.3938 20.6651 50.7775 18.0488 47.5604 18.0488H12.56C9.34284 18.0488 6.72656 20.6651 6.72656 23.8822V47.2158C6.72656 50.4329 9.34284 53.0492 12.56 53.0492H15.4767ZM12.56 23.8822H47.5604V47.2158H32.1689L21.3101 53.7317V47.2158H12.56V23.8822Z"
      fill="white"
    />
    <path
      d="M59.2283 6.38477H24.2279C21.0108 6.38477 18.3945 9.00105 18.3945 12.2182H53.3949C56.612 12.2182 59.2283 14.8344 59.2283 18.0516V41.3852C62.4454 41.3852 65.0617 38.7689 65.0617 35.5518V12.2182C65.0617 9.00105 62.4454 6.38477 59.2283 6.38477Z"
      fill="white"
    />
  </svg>
);
