import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputProps } from "@nextui-org/react";

import calender_icon from "../../assets/images/calender-icon.png";
import Input from "components/ui/input";

interface DateTextFieldProps extends InputProps {
  InputComponent?: React.ReactNode;
  disabled?: boolean;
  showYearPicker?:boolean,
  initDate?: Date
  onDateChange?: (date: Date | null) => void; // Define a callback prop
}

export const DateTextField = React.forwardRef<
  HTMLInputElement,
  DateTextFieldProps
>(({ InputComponent, disabled, onDateChange,showYearPicker=false,initDate, ...rest }, ref) => {
  const [startDate, setStartDate] = React.useState<Date | null>( initDate ?? null);
  const datePickerRef = React.useRef<any>(null);

  // Callback function to handle date change
  const handleDateChange = (date: Date | null) => {
    setStartDate(date); // Update local state
    if (onDateChange) {
      onDateChange(date); // Call the callback prop with the selected date
    }
  };

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  useEffect(()=>{
    if(initDate){
      setStartDate(initDate)
    }
  },[initDate])
  return (
    <DatePicker
      selected={startDate}
      disabled={disabled}
      onChange={handleDateChange}
      ref={datePickerRef}
      dateFormat={showYearPicker? "yyyy":"dd/MM/yyyy"}
    
      showYearPicker={showYearPicker}  
      customInput={
        InputComponent ?? (
          <Input
            {...rest}
            variant="form"
            size="compact"
            classNames={{
              input: "data-[has-start-content=true]:ps-16 p-5",
              ...rest.classNames,
            }}
            endContent={
              <img
                src={calender_icon}
                alt="calendar-icon"
                onClick={openDatePicker}
                className="mr-5 cursor-pointer"
              />
            }
            ref={ref}
            color={disabled ? "disabled" : "default"}
          />
        )
      }
    />
  );
});

export const DateTextField2 = React.forwardRef<
  HTMLInputElement,
  DateTextFieldProps
>(({ InputComponent, disabled, onDateChange, ...rest }) => {
  return (
    <Input
      {...rest}
      type="date"
      variant="form"
      classNames={{
        input:
          "data-[has-start-content=true]:ps-16 p-5 text-center h-[64px] placeholder-gray-500 placeholder-opacity-75",
        ...rest.classNames,
      }}
      color={disabled ? "disabled" : "default"}
    />
  );
});

export const DateTextFieldWithStartDate = React.forwardRef<
  HTMLInputElement,
  DateTextFieldProps
>(({ InputComponent,initDate, disabled, onDateChange, ...rest }, ref) => {
  const [startDate, setStartDate] = React.useState<Date | null>(initDate??null);
  const datePickerRef = React.useRef<any>(null);

  // Callback function to handle date change
  const handleDateChange = (date: Date | null) => {
    setStartDate(date); // Update local state
    if (onDateChange) {
      onDateChange(date); // Call the callback prop with the selected date
    }
  };

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  return (
    <DatePicker
      selected={startDate}
      disabled={disabled}
      onChange={handleDateChange}
      ref={datePickerRef}
      customInput={
        InputComponent ?? (
          <Input
            {...rest}
            variant="form"
            size="compact"
            classNames={{
              input: "text-center",
              ...rest.classNames,
            }}
            endContent={
              <img
                src={calender_icon}
                alt="calendar-icon"
                onClick={openDatePicker}
                className="mr-5 hidden lg:block cursor-pointer"
              />
            }
            ref={ref}
            color={disabled ? "disabled" : "default"}
          />
        )
      }
    />
  );
});
