import { Select, SelectItem } from "@nextui-org/react";
import { useState } from "react";

interface SelectNextUiProps {
  register: any;
  data: { label: string; value: string }[];
  placeholder: string;
  className?: string;
  defaultSelectedKeys?: string[];
  portalId?:string,
  errorMessage?:string,
  size?: "sm" | "md" | "lg" 
  heightClass?: string
}
export const SelectNextUi: React.FC<SelectNextUiProps> = ({
  register,
  data,
  placeholder,
  className,
  defaultSelectedKeys,
  portalId,
  errorMessage,
  size="lg",
  heightClass
}) => {
  return (
    <Select
      className={className}
      size={size}
      variant="flat"
      defaultSelectedKeys={defaultSelectedKeys}
      fullWidth
      errorMessage={errorMessage}
      classNames={{
        base: "",
        innerWrapper: "border-blue-500",
        mainWrapper: "border-blue-500",
        trigger:
        `${heightClass} border-2 rounded-2xl border border-double border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors bg-white`,
        value: "text-center",
      }}
      popoverProps={portalId?{portalContainer:document.getElementById(portalId)}:{}}
      aria-label={placeholder}
      {...register}
    >
      {data.map((data) => (
        <SelectItem key={data.value} value={data.value} className="text-center">
          {data.label}
        </SelectItem>
      ))}
    </Select>
  );
};

interface SelectNextUiPropsSearch {
  register: any;
  data: { label: string; value: string }[];
  placeholder: string;
  handleClearSelect:()=>void
  className?: string;
  defaultSelectedKeys?: string[];
  label?: string
  selectedKeys?:string[] 
  portalId?:string,
  textSize?:string
}
export const SelectNextUiCarSearch: React.FC<SelectNextUiPropsSearch> = ({
  register,
  data,
  placeholder,
  className,
  defaultSelectedKeys,
  label="",
  portalId,
  selectedKeys=[],
  handleClearSelect,
  textSize=""
}) => {
  // const [open, setOpen] = useState (false)
  return (
    <Select
      // isOpen={open}
      // onOpenChange={setOpen}
      className={className}
      size="lg"
      // aria-label={label}
      variant="flat"

      selectionMode="multiple"

      defaultSelectedKeys={defaultSelectedKeys}
      fullWidth
      selectedKeys={selectedKeys}
      popoverProps={portalId?{portalContainer:document.getElementById(portalId)}:{
        // placement: "bottom-start", // Adjust this value as needed
        // offset: [0, 10], // Adjust these values as needed

      }}
      endContent={selectedKeys.length > 0 && <div className="absolute right-3.5 text-base  hover:text-blue-500" onClick={()=>{handleClearSelect(); 
        // setOpen(false)
       }}>X</div>}
      
      classNames={{
        base: "",
        innerWrapper: "border-blue-500 ",
        mainWrapper: "border-blue-500 ",
        trigger:
          `h-[3.5rem]  ${selectedKeys.length > 0 ?"border-2":"border-1"} rounded-2xl border-double border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors bg-white`,
        value: `text-center ${textSize} pl-6`,
        
      }}
      aria-label={placeholder}
      {...register}
      { ...(selectedKeys.length > 0?{ selectorIcon: <></>}:{})}
    >
      {data.map((data) => (
        <SelectItem key={data.value} value={data.value} className="text-center">
          {data.label}
        </SelectItem>
      ))}
    </Select>
  );
};
