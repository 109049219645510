import { Typography } from "components";

interface DataItem {
  title: string;
  value: string;
}

interface VehicleFactoryInstalledProps {
  data: {
    Color: string;
    Interior: string;
    Options: string[];
  };
}

const DataSection: React.FC<DataItem> = ({ title, value }) => {
  return (
    <div className="col-span-1 px-5">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-1 md:gap-20 mb-5">
        <div className="col-span-2 md:mb-6">
          <Typography
            variant="custom"
            className="gradient-text font-[600] text-medium"
          >
            {title}
          </Typography>
        </div>
        <div className="col-span-4 ">
          <Typography
            variant="custom"
            className="font-[400] md:font-[500] text-xs md:text-sm ml-2 md:ml-0"
          >
            {value}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const VehicleFactoryInstalled: React.FC<
  VehicleFactoryInstalledProps
> = ({ data }) => {
  return (
    <div className="grid grid-cols-1 max-h-[400px] overflow-y-auto">
      <DataSection title="Interior" value={data.Interior} />
      <DataSection title="Color" value={data.Color} />
      {data.Options?.map((option: string, idx: number) => (
        <DataSection
          key={idx}
          title={idx === 0 ? "Options" : ""}
          value={option}
        />
      ))}
    </div>
  );
};
