import { useEffect } from "react";
import { env } from "config/env";

export const useSFChat = () => {
  useEffect(() => {
    const initEmbeddedMessaging = () => {
      try {
        if ((window as any)?.embeddedservice_bootstrap) {
          (window as any).embeddedservice_bootstrap.settings.language = "en_US";
          (window as any).embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
          (window as any).embeddedservice_bootstrap.init(
            env.SF_CHAT_ID,
            env.SF_CHAT_ENVLABEL,
            env.SF_CHAT_URL,
            {
              scrt2URL: env.SF_CHAT_SCRTURL,
            }
          );
        }
        const embeddedMessaging = document.querySelector(
          ".embedded-messaging"
        ) as HTMLElement;
        if (embeddedMessaging) {
          embeddedMessaging.style.display = "none";
        }
      } catch (err) {
        console.error("Error loading Embedded Messaging: ", err);
      }
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = env.SF_CHAT_BOOTSCRIPT_URL;
    script.onload = initEmbeddedMessaging;
    document.head.appendChild(script);

    return () => {
      // Cleanup function
      document.head.removeChild(script);
    };
  }, []);
};
