import LabelInfoCardGradiant from "./card.component";
import { cards } from "../pre-approval.data";

const HowtoGetPreApproved = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center mb-8 text-center">
        <h2 className="text-2xl bg-gradient-to-r from-[#018FFD] to-[#070C84] text-transparent bg-clip-text">
        How to Get Pre-Approved:
        </h2>
      </div>
  
      <p className="pb-5 text-base sm:text-xl font-bold pt-5 text-center">
      Getting pre-approved at findmyoptions is simple and secure. Here's what you need to do:
        </p>
      <div className="flex flex-col lg:flex-row gap-8">
       
      {cards.map((card) => (
        <LabelInfoCardGradiant card={card} />
      ))}
    </div>
    </div>
  );
};

export default HowtoGetPreApproved;
