import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { useEffect, useState } from "react";

interface DropdownProps {
  onSelect: (option: number) => void;
  selectedOption?: number;
}

const SoldExpenseChartDropdown = ({
  onSelect,
  selectedOption: initialSelectedOption = 1,
}: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<number>(
    initialSelectedOption
  );
  const options = [
    { month: 1, key: "1Months" },
    { month: 3, key: "3Months" },
    { month: 6, key: "6Months" },
    { month: 12, key: "12Months" },
  ];

  useEffect(() => {
    onSelect(selectedOption);
  }, []); // This effect runs only once after component mounts

  const handleSelect = (option: number) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button variant="bordered" size="sm">
          {selectedOption} Months
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Dynamic Actions"
        className="px-4"
        items={options}
      >
        {(option) => (
          <DropdownItem
            key={option.key}
            onClick={() => handleSelect(option.month)}
          >
            {option.month} Months
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SoldExpenseChartDropdown;
