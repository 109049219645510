import React, { useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Image,
} from "@nextui-org/react";
import { money } from "utils/money";
const AmortizationTable = ({ loanDetails }: any) => {
  const [schedule, setSchedule] = useState([]);
  useEffect(() => {
    calculateAmortizationSchedule();
  }, []);

  const calculateAmortizationSchedule = () => {
    const loanTermMonths = Number(loanDetails.loanTerm);

    const monthlyInterestRate = Number(loanDetails.interestRate) / 100 / 12;

    const monthlyPayment =
      (loanDetails.totalLoanAndInterest * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));

    let balance = loanDetails.totalLoanAndInterest;
    let amortizationSchedule: any = [];

    for (let n = 1; n <= loanTermMonths; n++) {
      const interestPayment = balance * monthlyInterestRate;

      const principalPayment = monthlyPayment - interestPayment;

      balance -= principalPayment;

      amortizationSchedule.push({
        month: n,
        paymentAmount: monthlyPayment.toFixed(2),
        principalPaid: principalPayment.toFixed(2),
        interestPaid: interestPayment.toFixed(2),
        remainingBalance: balance > 0 ? balance.toFixed(2) : 0,
      });
    }

    setSchedule(amortizationSchedule);
  };
  const columns = [
    { name: "Month", uid: "month" },
    { name: "Payment Amount", uid: "paymentAmount" },
    { name: "Principal", uid: "principalPaid" },
    { name: "Interest", uid: "interestPaid" },
    { name: "Balance", uid: "remainingBalance" },
  ];

  const renderCell = React.useCallback((car: any, columnKey: any) => {
    switch (columnKey) {
      case "month":
        return <span className="text-xs">{car.month}</span>;
      case "paymentAmount":
        return <span className="text-xs">${money(car.paymentAmount)}</span>;
      case "principalPaid":
        return (
          <span className="capitalize text-xs">
            ${money(car.principalPaid)}
          </span>
        );
      case "interestPaid":
        return (
          <span className="capitalize text-xs">${money(car.interestPaid)}</span>
        );
      case "remainingBalance":
        return <span className="text-xs">${money(car.remainingBalance)}</span>;
      default:
        return car[columnKey];
    }
  }, []);

  return (
    <div>
      <Table
        aria-label="table with dynamic content border-none"
        shadow={"none"}
        radius="lg"
        border={0}
        classNames={{
          thead: "[&>tr:last-child]:hidden",
        }}
        className="w-full break-normal		"
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn
              className={`font-bold text-xs lg:text-md  text-[#070C84] border-2  border-black/50  ${
                column.uid !== "action" &&
                "border-r-[#070C84] border-r-3 break-normal	"
              } p-1 md:p-3 text-center`}
              key={column.uid}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={schedule ?? []} className="break-all">
          {(item: any) => (
            <TableRow key={item.month} className="text-center">
              {(columnKey) => (
                <TableCell
                  className={`break-all text-xs xl:text-lg border-t-black/50 border-2  `}
                >
                  {renderCell(item, columnKey)}
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AmortizationTable;
