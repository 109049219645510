import * as React from "react";
import { Button, Card, CardBody } from "@nextui-org/react";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import Form from "components/ui/form";
import { NewOrderValidationSchema } from "./new-order.validation";
import { AddressInput, DateTextField, Typography } from "components";
import Input from "components/ui/input";
import { PaymentModal } from "pages/shared";
import {
  GetShippingDetailsResponse,
  ShippingDetailsPayload,
} from "services/endpoints/shipping-quotes/shipping.type";
import { useGetShippingQuotesInfo } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import { ShippingPaymentComponent } from "./shipping-payment.component";
import { useDebounceValue } from "usehooks-ts";
import {  useGetZipCodeDetailsForMul } from "services";
import { FormatedShippingResponseType } from "utils/shipping";
import { useNavigate } from "react-router-dom";
import { isThisDealer } from "utils/get-user-id";
const FORM_FIELDS_DATA = [
  {
    label: "Enter Shipping ZIP",
    name: "shippingZip",
  },
  {
    label: "Enter Shipping Street Address",
    name: "shippingStreetAddress",
    type: "address",
    zipName: "shippingZip",
  },
  {
    label: "Enter Destination ZIP",
    name: "destinationZip",
  },
  {
    label: "Enter Destination Street Address",
    name: "destinationStreetAddress",
    type: "address",
    zipName: "destinationZip",
  },
  {
    label: "Estimated Ship Date",
    name: "estimatedShipDate",
    type: "date",
  },
  // {
  //   label: "Desired Delivery Date",
  //   name: "desiredEstimatedShipDate",
  //   type: "date",
  // },
];

export interface IShippingPaymentInfoNew {
  form: ShippingDetailsPayload;
  pay: FormatedShippingResponseType;
}

interface Props {
  from?: string;
  handleSubmit?: (res:any)=>void
}

export const NewOrderForm = (props: Props) => {
  const [payModalStep, setPayModalStep] = useState<number | null>(null);
  const [shippingPaymentInfo, setShippingPaymentInfo] =
    useState<IShippingPaymentInfoNew | null>(null);
  const selectedCar = useSelector((state: RootState) => state.shipping);
  
  const [zipValue, setZipValue] = React.useState({pick:"",des:""});
  const navigate = useNavigate()
  const [debouncedValue] = useDebounceValue(zipValue.pick, 300);
  const [debouncedValue2] = useDebounceValue(zipValue.des, 300);

  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
  } = useGetZipCodeDetailsForMul();
  const {
    mutateAsync: zipcodeMutateAsync2,
    data: zipcodeData2,
  } = useGetZipCodeDetailsForMul();


  const {
    mutateAsync: mutateAsyncGetShippingQuotes,
    isPending: isPendingGetShippingQuetues,
    error:shippingError
  } = useGetShippingQuotesInfo();

  const handleOnSubmit = async (e: any) => {
    try {
      if(!zipcodeData) return;
      const payload = {
        ...e,
        vin: selectedCar?.carData?.vin || "",
        listingId: selectedCar?.carData?.listingId,
        dest: zipcodeData,
        ship: zipcodeData2,
        carYear: selectedCar.carData?.year,
        make: selectedCar.carData?.make,
        model: selectedCar.carData?.model
      }
      const res = await mutateAsyncGetShippingQuotes(payload);
      if(props.handleSubmit){
        props.handleSubmit(res)
        return;
      }
      setShippingPaymentInfo({ form: e, pay: res });
      setPayModalStep(1);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  
  React.useEffect(() => {
    if (zipValue.pick.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync(zipValue.pick);
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    if (zipValue.des.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync2(zipValue.des);
      };
      fetchZipCode();
    }
  }, [debouncedValue2]);

  React.useEffect(()=>{
    if(!selectedCar.carData && !props.handleSubmit){
      navigate(isThisDealer()?"/shipping-quotes/select-vehicle":"/user/shipping-quotes/select-vehicle")
    }
  },[])

  return (
    <div>
      <Card className="min-w-[340px]">
        <CardBody>
          <Typography
            variant="custom"
            className="text-lg mt-8 font-[700] md:text-2xl xl:text-[24px] text-[#263238] text-center pb-2"
          >
            {selectedCar.carData?.name}
          </Typography>
          <Form
            id="new-order-shipping-form"
            schema={NewOrderValidationSchema}
            onSubmit={handleOnSubmit}
            className="px-10"
          >
            {({
              formState: { errors, defaultValues,isSubmitted },
              register,
              getValues,
              setValue,
            }) => (
              <div className="mt-10 grid gap-x-5">
                {FORM_FIELDS_DATA.map(({ name, label, type, zipName }, idx) => {
                  return (
                    <FormControl key={idx}>
                      <Typography
                        variant="textFieldLabel"
                        className="text-bold"
                      >
                        {label}
                      </Typography>
                      {type === "date" ? (
                        <DateTextField
                          errorMessage={
                            errors[name as keyof typeof errors]?.message
                          }
                          classNames={{
                            inputWrapper: errors[name as keyof typeof errors]
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          {...register(name as keyof typeof defaultValues)}
                          onDateChange={(date) => {
                            register(
                              name as keyof typeof defaultValues
                            ).onChange({ target: { name: name, value: date } });
                          }}
                        />
                      ) : type === "address" ? (
                        <div className="relative">
                          <AddressInput
                            zipData={(name === "shippingStreetAddress"
                            ? zipcodeData
                            : zipcodeData2)}
                            handleSelect={(value) =>
                              setValue(name as any, value)
                            }
                            placeholder=""
                            InputComponent={(props) => {
                              return (
                                <Input
                                  variant="form"
                                  size="compact"
                                  errorMessage={
                                    errors[name as keyof typeof errors]?.message
                                  }
                                  classNames={{
                                    input:
                                      "data-[has-start-content=true]:ps-16 p-5",
                                    inputWrapper: !!errors[
                                      name as keyof typeof errors
                                    ]
                                      ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                      : "",
                                  }}
                                  {...register(
                                    name as keyof typeof defaultValues
                                  )}
                                  value={getValues(
                                    name as keyof typeof defaultValues
                                  )}
                                  onChange={(e) => {
                                    props.onChange(e);
                                    return register(
                                      name as keyof typeof defaultValues
                                    ).onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <Input
                          variant="form"
                          size="compact"
                          errorMessage={
                            errors[name as keyof typeof errors]?.message ? errors[name as keyof typeof errors]?.message : ((name === "shippingZip"
                            ? !zipcodeData
                            : !zipcodeData2) && isSubmitted) ? "zip code is invalid" :""
                          }
                          maxLength={5}
                          type="number"
                          classNames={{
                            input: "data-[has-start-content=true]:ps-16 p-5",
                            inputWrapper: (!!errors[name as keyof typeof errors] || ((name === "shippingZip"
                            ? !zipcodeData
                            : !zipcodeData2) && isSubmitted))
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                          {...register(name as keyof typeof defaultValues)}
                          value={getValues(
                            name as keyof typeof defaultValues
                          )}
                          onChange={(e) => {
                            const formattedValue = e.target.value?.replace(
                              /\D/g,
                              ""
                            );
                            if(name=== "shippingZip"){
                              setZipValue(p=>({...p, pick: formattedValue, turn:name}))
                            }else{
                              setZipValue(p=>({...p, des: formattedValue, turn:name}))

                            }
                            return register(
                              name as keyof typeof defaultValues
                            ).onChange(e);
                          }}
                        />
                      )}
                    </FormControl>
                  );
                })}
                <Typography variant="error">{shippingError?.message}</Typography>
                <FormControl>
                  <div></div>
                  <div>
                    <Button
                      className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                      type="submit"
                      isLoading={isPendingGetShippingQuetues}
                    >
                      Submit
                    </Button>
                  </div>
                </FormControl>
              </div>
            )}
          </Form>
        </CardBody>
      </Card>
      <PaymentModal
        isOpen={payModalStep !== null}
        onOpen={() => {
          setPayModalStep(null);
        }}
        Component={
          <ShippingPaymentComponent
            from={props.from}
            vehicleName={selectedCar.carData?.name}
            payModalStep={payModalStep}
            setPayModalStep={(num) => setPayModalStep(num)}
            shippingPaymentInfo={shippingPaymentInfo}
            vin={selectedCar.carData?.vin}
          />
        }
      />
    </div>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1  md:grid-cols-1 xl:grid-cols-2 justify-items-left mb-5 align-baseline items-center">
      {children}
    </div>
  );
};
