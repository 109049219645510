
import { useEffect } from 'react';

export const useHttpsRedirect = () => {
    
    useEffect(() => {
        if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
            window.location.replace(`https://${window.location.hostname}${window.location.pathname}${window.location.search}`);
        }
    }, []);
};