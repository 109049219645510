import React, { useEffect } from "react";
import masterCard from "./assets/masterCard.svg";
import ccIcon from "./assets/ccIcon.svg";
import { shippingEndpoint } from "services/endpoints/shipping-quotes/shipping.endpoint";
import { useTransactionsCheckForPaymentMethod } from "hooks/api/mutations/shipping/use-shipping-quotes";

interface Props {
  handleAddCard: () => void;
}

const CreditCard = ({ handleAddCard }: Props) => {
  const {
    mutateAsync: mutateAsyncAccceptPayment,
    isPending: isPendingToAccceptPayment,
    data,
  } = useTransactionsCheckForPaymentMethod();
  console.log("data --->", data);
  useEffect(() => {
    mutateAsyncAccceptPayment();
  }, []);

  return (
    <div className="bg-white rounded-3xl m-2 p-8 shadow-lg w-1/2 max-h-1/2">
      <div>
        <div className="flex justify-between items-center py-4">
          <h1 className="text-2xl font-bold mb-6 text-[#070C84]">
            Saved Cards
          </h1>
          <button
            onClick={handleAddCard}
            className="bg-gradient-to-r from-[#018FFD] to-[#070C84] text-white rounded-md px-4 py-2 font-Metropolis"
          >
            Add New Card +
          </button>
        </div>
        <div className="max-w-sm mx-auto bg-white  overflow-hidden">
          <div className="bg-gradient-to-r from-[#018FFD] to-[#070C84] rounded-lg p-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img
                  src={ccIcon}
                  alt="Mastercard logo"
                  className="h-8 w-12 m-1"
                />
                <h2 className="text-white text-lg font-bold">ABC BANK</h2>
              </div>

              <span className="text-white text-sm">CREDIT</span>
            </div>
            <div className="flex justify-between items-center mt-4">
              <span className="text-white text-2xl font-mono">
                XXXX XXXX XXXX 8269
              </span>
            </div>
            <div className="flex items-center my-4">
              <div className="flex flex-row items-center">
                <div className="flex flex-col">
                  {/* <span className="text-white text-[10px] mr-2">VALID</span>
                  <span className="text-white text-[10px] mr-2">THRU</span> */}
                </div>
                {/* <span className="text-white text-2xl font-m">5/23</span> */}
              </div>
              <div className="flex flex-row items-center mx-10">
                {/* <span className="text-white text-[10px] mr-2 font-font-Metropolis">
                  CVC
                </span>
                <span className="text-white text-2xl font-font-Metropolis">
                  633
                </span> */}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-white text-2xl font-font-Metropolis">
                Ajith Kaythottil
              </span>
              <span className="text-white text-sm font-font-Metropolis">
                <img
                  src={masterCard}
                  alt="Mastercard logo"
                  className="h-8 w-12"
                />
                master card
              </span>
            </div>
          </div>
          <button className=" text-black py-2 w-full font-font-Metropolis flex justify-end">
            Remove Card
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
