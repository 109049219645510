import { SVGProps } from "react";

export const PenAndPaperIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M26.7758 15.3004C26.4376 15.3004 26.1133 15.4347 25.8742 15.6738C25.6351 15.9129 25.5008 16.2372 25.5008 16.5754V24.2254C25.5008 24.5635 25.3665 24.8878 25.1273 25.1269C24.8882 25.366 24.5639 25.5004 24.2258 25.5004H6.37578C6.03763 25.5004 5.71333 25.366 5.47422 25.1269C5.23511 24.8878 5.10078 24.5635 5.10078 24.2254V6.37536C5.10078 6.03721 5.23511 5.71291 5.47422 5.4738C5.71333 5.23469 6.03763 5.10036 6.37578 5.10036H14.0258C14.3639 5.10036 14.6882 4.96603 14.9273 4.72692C15.1665 4.48781 15.3008 4.16351 15.3008 3.82536C15.3008 3.48721 15.1665 3.16291 14.9273 2.9238C14.6882 2.68469 14.3639 2.55036 14.0258 2.55036H6.37578C5.36133 2.55036 4.38842 2.95335 3.6711 3.67068C2.95377 4.388 2.55078 5.36091 2.55078 6.37536V24.2254C2.55078 25.2398 2.95377 26.2127 3.6711 26.93C4.38842 27.6474 5.36133 28.0504 6.37578 28.0504H24.2258C25.2402 28.0504 26.2131 27.6474 26.9305 26.93C27.6478 26.2127 28.0508 25.2398 28.0508 24.2254V16.5754C28.0508 16.2372 27.9165 15.9129 27.6773 15.6738C27.4382 15.4347 27.1139 15.3004 26.7758 15.3004ZM7.65078 16.2694V21.6754C7.65078 22.0135 7.78511 22.3378 8.02422 22.5769C8.26333 22.816 8.58763 22.9504 8.92578 22.9504H14.3318C14.4996 22.9513 14.6659 22.9192 14.8213 22.8557C14.9766 22.7923 15.1179 22.6988 15.237 22.5806L24.06 13.7449L27.681 10.2004C27.8005 10.0818 27.8954 9.94082 27.9601 9.78545C28.0248 9.63008 28.0582 9.46343 28.0582 9.29511C28.0582 9.1268 28.0248 8.96015 27.9601 8.80478C27.8954 8.64941 27.8005 8.50839 27.681 8.38986L22.275 2.92011C22.1565 2.80061 22.0155 2.70576 21.8601 2.64103C21.7047 2.5763 21.5381 2.54297 21.3698 2.54297C21.2015 2.54297 21.0348 2.5763 20.8794 2.64103C20.7241 2.70576 20.5831 2.80061 20.4645 2.92011L16.869 6.52836L8.02053 15.3641C7.90236 15.4832 7.80887 15.6245 7.74542 15.7799C7.68197 15.9352 7.64981 16.1016 7.65078 16.2694ZM21.3698 5.62311L24.978 9.23136L23.1675 11.0419L19.5593 7.43361L21.3698 5.62311ZM10.2008 16.7921L17.7615 9.23136L21.3698 12.8396L13.809 20.4004H10.2008V16.7921Z"
      fill="#313131"
    />
  </svg>
);
