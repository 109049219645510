import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";
import { Button } from "@nextui-org/react";

import "../../../modules/dealer/components/dmv-title/DmvTitle.scss";
import { SelectVehicleModal } from "./select-vehicle-modal.component";
import {
  ListingListResponse,
  useGetListingList,
  useSearchListingList,
} from "services";
import { Typography } from "components";
import Loading from "components/Loading/Loading";
import { useDispatch } from "react-redux";
import { changeCarForShipping } from "modules/dealer/store/shipping";
import { dmvDocumentServiceSignal, dmvtitlingSignal } from "./store";
import { getUserId } from "utils/get-user-id";
import SearchCarCard from "modules/shared/components/car-card/SearchCarCard";
import { env } from "config/env";

interface Props {
  fromShipping: boolean;
}

const SelectVehicle = ({ fromShipping }: Props) => {
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState<ListingListResponse[]>([]);
  const [debouncedValue] = useDebounceValue(searchValue, 500);
  const dispatch = useDispatch();
  const { data: dataListing, isLoading: isLoadingList } = useGetListingList(
    getUserId(),
    1,
    1,
    20
  );
  useEffect(() => {
    setSearchData(dataListing || []);
    dmvtitlingSignal.value = null;
    dmvDocumentServiceSignal.value = null;
  }, [dataListing]);

  const { data: dataSearchListing, isLoading: isLoadingSearch } =
    useSearchListingList(debouncedValue);
  useEffect(() => {
    setSearchData(dataSearchListing || []);
    if (searchValue.length === 0) {
      setSearchData(dataListing || []);
    }
  }, [dataListing, dataSearchListing, searchValue.length]);

  const changeOpenConfirmationModal = (
    name: string,
    vin: string,
    listingId: number,
    year: string,
    make: string,
    model: string
  ) => {
    setSelectedData({ name, vin, listingId,year });
    if (fromShipping) {
      dispatch(changeCarForShipping({ name, vin, listingId, year:year, make,model }));
      navigate("/shipping-quotes/new-order");
    } else {
      setOpenConfirmationModal((prev) => !prev);
    }
  };

  if (isLoadingList || isLoadingSearch) return <Loading />;

  return (
    <div className="flex dmv-title mb-20">
      <div className="w-full">
        <Typography variant="pageTitle">Select Vehicle</Typography>
        <p className="py-3 mb-6 text-xl text-center">
          If your vehicle is not currently in your inventory/garage please add
          it first.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-6 items-center gap-2">
          <div className="col-span-2">
            <Button
              className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg tracking-wide rounded-xl px-16 w-full h-[54px] md:w-[190px] mb-4"
              type="button"
              size="lg"
              onClick={() => navigate("/dealer/enter-vin")}
            >
              Add Vehicle
            </Button>
          </div>
          <div></div>
          <div></div>
          <div className="col-span-2 md:flex md:justify-end">
            <form>
              <div className="relative">
                <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 text-sm  placeholder:px-0 text-gray-900 border border-gray-300 ps-10 rounded-xl bg-gray-50 focus:ring-blue-500 focus:border-blue-500 md:w-[400px]"
                  placeholder="Search Keywords"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  required
                />
              </div>
            </form>
          </div>
        </div>

        {/* <div className="relative overflow-x-auto w-full rounded-xl border-[#070C84] border-2">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-lg text-white uppercase bg-[#070c84]">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-center border-l border-[#070c84] text-base md:text-xl"
                >
                  VIN
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
                >
                  MAKE
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
                >
                  MODEL
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
                >
                  SELECT
                </th>
              </tr>
            </thead>
            <tbody>
              {searchData?.map(({ vin, make, model, name, listingId }) => (
                <tr
                  className="bg-white text-black border-b border-[#C9C9C9] text-base md:text-xl font-semibold"
                  key={listingId}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 text-center border-l border-[#C9C9C9]  whitespace-nowrap"
                  >
                    {vin === "undefined" ? "N/A" : vin.toUpperCase()}
                  </th>
                  <td className="px-6 py-4 text-center border-l border-[#C9C9C9]">
                    {make === "undefined" ? "N/A" : make}
                  </td>
                  <td className="px-6 py-4 text-center border-l border-[#C9C9C9]">
                    {model === "undefined" ? "N/A" : model}
                  </td>
                  <td
                    onClick={() =>
                      changeOpenConfirmationModal(name, vin, listingId)
                    }
                    className="overflow-hidden max-w-64  h-16 text-center border-l border-[#C9C9C9] cursor-pointer "
                  >
                    <p className="text-transition-dvm">Select</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        <div className="col-span-4  pb-20 overflow-y-auto mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 gap-4">
            {searchData?.map((car) => {
              return (
                <SearchCarCard
                  car={car}
                  line1={`${car?.year || ""} ${car.make} ${car.model}`}
                  line2={car.trim}
                  line3={`VIN - ${car.vin}`}
                  imgURl={`${env.BASE_API_URL}Listings/image/${car.listingId}`}
                  onSelect={() =>{
                    
                    changeOpenConfirmationModal(
                      car.name,
                      car.vin,
                      car.listingId,
                      car.year,
                      car.make,
                      car.model
                    )}
                  }
                  cardOnClick={() =>
                    changeOpenConfirmationModal(
                      car.name,
                      car.vin,
                      car.listingId,
                      car.year,
                      car.make,
                      car.model
                    )
                  }
                />
              );
            })}
          </div>
        </div>
        <SelectVehicleModal
          open={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false)}
          selectedData={selectedData}
        />
      </div>
    </div>
  );
};

export default SelectVehicle;
