import React from "react";
import { Button } from "@nextui-org/react";
import { Typography } from "components";
import { useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import { getUserId } from "utils/get-user-id";
import { pendingSalesData } from "services/endpoints/pending-sales/pending-sales.type";

interface Props {
  onCallback: () => void;
  saleOffer: pendingSalesData | null
}
const processStepsInfo = [
  { step: 1, description: "Payment for Online Titling/DMV Service" },
  {
    step: 2,
    description: "Payment for shipping",
  },
  {
    step: 3,
    description: "Payment for vehicle",
  },
  {
    step: 4,
    description:
      "Buyer will Upload Drivers License and Insurance, Enter missing information, and sign all transfer paperwork required",
  },
  {
    step: 5,
    description:
      "Vehicle will be shipped once all payments are received and all paperwork is completed.",
  },
  {
    step: 6,
    description:
      "Buyer will have up to 5 business days including saturdays from the date you receive the vehicle to inspect the vehicle (waiting period can be waived)",
  },
  {
    step: 7,
    description:
      "After buyer agrees to accept the vehicle, escrow.com will disburse the payments accordingly!",
  },
];
export default function HowItWorks({saleOffer,onCallback}: Props) {
  const userid = getUserId().toString();
  return (
    <div className="flex flex-col items-center justify-center lg:-mt-24">
      <div className="text-center">
        <Typography
          variant="custom"
          className={` -mt-8 md:mt-10  font-bold text-[22px] md:text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
        >
          Offer Accepted! Here’s how the process works!
        </Typography>
        <Typography variant="custom" className={`font-semibold text-xl  mt-5`}>
          We will help will close this all cash transaction 100% online see our
          7 easy steps below: 
        </Typography>
      </div>
      {processStepsInfo.map((res) => (
        <div className="flex items-center justify-between w-full gap-10 mt-5 px-4 max-w-[56rem]">
          <div className="flex flex-col items-center justify-center">
            <div className=" w-10 h-10 bg-gradient-to-r from-[#018FFD] to-[#070C84] rounded-full flex items-center justify-center text-white font-bold text-xl">
              <div>{res.step}</div>
            </div>
            <div className="text-sm lg:text-lg whitespace-nowrap">Step {res.step}</div>
          </div>
          <div className=" p-7 rounded-xl border border-blue-400 w-[80%]">
            {res.description}
          </div>
        </div>
      ))}
      <p className="text-[#070C84]  font-bold mt-10 mb-5 text-xl max-w-[900px]">
        Note: Buyer may work directly with the dealer to facilitate this
        transaction, however, findmyoptions.com will not be responsible for any
        issues that arise from such transactions
      </p>
      {userid === String(saleOffer?.userId) && <Button
        className="col-span-4 md:col-span-2 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-1/4 py-7 racking-wide rounded-xl mb-32"
        type="submit"
        onClick={() => onCallback()}
      >
        Continue
      </Button>}
    </div>
  );
}
