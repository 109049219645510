import React from "react";

const CloseIcon = ({
  fill = "none",
  stroke = "black",
  strokeWidth = "4.0278",
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23" fill={fill}>
      <path
        d="M19.6296 3L2.17578 20.4538M2.17578 3L19.6296 20.4538"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
