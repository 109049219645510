import { Button } from "@nextui-org/react";
import { LocationZipIcon } from "assets/svgs/icons/location-zip-icon";
import { PhoneNumberInput } from "components";
import Input from "components/ui/input";
import React from "react";

export default function ContactForm({ register, errors, control }: any) {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <div>Full Name</div>
        <Input
          variant="form"
          className="w-[300px] md:min-w-[400px]"
          radius="md"
          size="md"
          isInvalid={!!errors.name}
          errorMessage={errors?.name?.message}
          classNames={{
            input: "data-[has-start-content=true]:ps-16",
            inputWrapper: !!errors.name
              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
              : "",
          }}
          {...register("name")}
        />
      </div>
      <div>
        <div>Phone Number</div>
        <PhoneNumberInput
          control={control}
          iconClass="absolute left-3"
          id={"phone"}
          error={!!errors.phone}
          errorMessage={errors?.phone?.message}
        />
      </div>
      <div>{!!errors.phone}</div>
      <div>
        <div>Email</div>
        <Input
          variant="form"
          className="w-[300px] md:min-w-[400px]"
          radius="md"
          size="md"
          isInvalid={!!errors.email}
          errorMessage={errors?.email?.message}
          classNames={{
            input: "data-[has-start-content=true]:ps-16",
            inputWrapper: !!errors.email
              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
              : "",
          }}
          {...register("email")}
        />
      </div>
      <div>
        <div>Zip Code</div>
        <Input
          variant="form"
          className="w-[300px] md:min-w-[400px]"
          radius="md"
          size="md"
          isInvalid={!!errors.zipCode}
          errorMessage={errors?.zipCode?.message}
          classNames={{
            input: "data-[has-start-content=true]:ps-16",
            inputWrapper: !!errors.zipCode
              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
              : "",
          }}
          {...register("zipCode")}
        />
      </div>
    </div>
  );
}
