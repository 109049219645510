import axios from "axios";
import { http } from "../../http/service.http";
import { UserTierOpt } from "./user-membership.type";
import { env } from "config/env";
import { throwNotErrorSuccessResponse } from "config/retryConfig";
class UserMemeberShipEndpoint {
  public async processUserTier(selectedTier?: UserTierOpt): Promise<any> {
    try{
    const res = await http.post(
      `Transactions/ProcessUserTier?selectedTier=${selectedTier}`,
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    throwNotErrorSuccessResponse(res,res.data || "Failed to process the payment for tier! please try again.")
    return res.data;
  }catch(e){
    throw e;
  }
  }

  public async processUserTierRegistration(
    selectedTier?: UserTierOpt
  ): Promise<any> {
    try {
      const tierToken = String(localStorage.getItem("tier_token"));
      console.log("tierToken ", tierToken);

      const url = `${env.BASE_API_URL}Transactions/ProcessUserTier?selectedTier=${selectedTier}`;

      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${JSON.parse(tierToken)}`,
          },
        }
      );
      throwNotErrorSuccessResponse(response,response.data || "Failed to Process the selected Tier! please contact support.")
      // Return the response data
      return response.data;
    } catch (error) {
      // Handle errors or rethrow
      console.error("Error processing user tier:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  }
}

export const userMemeberShipEndpoint = new UserMemeberShipEndpoint();
