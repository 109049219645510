import * as React from "react";
import { Card, CardBody, CardFooter } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";

import { Typography } from "components";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { DmvFeeDetailsValidationSchema } from "./validation";
import { useGetATCFeeQuotes } from "services/tiling.service";
import { dmvtitlingSignal } from "./store";
import Loading from "components/Loading/Loading";
import { makeDecimalAPercentage, removeHTMLTags } from "utils";

export const DmvFeeDetailsForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { mutateAsync, isPending, data } = useGetATCFeeQuotes(
    params?.listingId as string
  );

  React.useEffect(() => {
    const fetchATCFeeQuotes = async () => {
      await mutateAsync(dmvtitlingSignal.value as any);
    };
    fetchATCFeeQuotes();
  }, [mutateAsync]);

  const handleOnSubmit = () => {
    navigate("/dealer/dmv-titling/new-order");
  };

  if (isPending) return <Loading />;

  return (
    <div className="px-5 md:px-10 xl:px-32 mb-40">
      <div className="flex flex-col gap-4 items-center justify-center">
        <Typography variant="pageTitle">DMV Fee Details</Typography>
        <Typography
          variant="custom"
          className="text-medium text-[#263238] font-[400] text-center"
        >
          {data?.notes &&
            (removeHTMLTags(data?.notes?.note[0]?.noteText) ?? "")}
        </Typography>
      </div>
      <div className="mt-10">
        <Form
          id="dmv-missing-details-form"
          schema={DmvFeeDetailsValidationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ formState: { errors } }) => {
            return (
              <Card
                isPressable={false}
                radius="lg"
                className="xl:w-9/12 max-w-[40rem] bg-[#F1F1F180] m-auto bg-no-repeat flex items-center justify-center"
                style={{
                  backgroundImage: "url(assets/images/header-background.png)",
                }}
              >
                <CardBody className="flex my-6 px-16 max-w-[26rem]">
                  {data?.feeDetails?.feeDetail?.map((feeDetail, index) => {
                    if (!feeDetail) return null;
                    return (
                      <FormControl key={index}>
                        <div className="max-w-[12rem]">
                          {feeDetail?.feeName === "State Tax" ? (
                            <>
                              <Typography
                                variant="custom"
                                className="text-[#263238] font-[600] text-center md:text-left text-sm"
                              >
                                {feeDetail?.feeName}
                              </Typography>
                              <Typography
                                variant="custom"
                                className="text-[#263238] font-[400] text-center md:text-left text-sm"
                              >
                                {makeDecimalAPercentage(
                                  Number(data?.totalSalesTax?.rate)
                                )}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                variant="custom"
                                className="text-[#263238] font-[600] text-center md:text-left text-sm"
                              >
                                {feeDetail?.feeType}
                              </Typography>
                              {Number(feeDetail?.feeTaxRate) > 0 ? (
                                <Typography
                                  variant="custom"
                                  className="text-[#263238] font-[400] text-center md:text-left text-sm"
                                >
                                  {makeDecimalAPercentage(
                                    Number(feeDetail?.feeTaxRate)
                                  )}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="custom"
                                  className="text-[#263238] font-[400] text-center md:text-left text-sm"
                                >
                                  {feeDetail?.feeName}
                                </Typography>
                              )}
                            </>
                          )}
                        </div>
                        <Input
                          variant="form"
                          size="lg"
                          className="max-w-[12rem]"
                          classNames={{
                            input: "px-4 text-center border-[#263238]",
                            inputWrapper: "border-[#263238]",
                          }}
                          isDisabled
                          defaultValue={
                            feeDetail?.feeName === "State Tax"
                              ? `$${Number(data?.totalSalesTax?.amount).toFixed(
                                  2
                                )}`
                              : `$${feeDetail?.amount}`
                          }
                        />
                      </FormControl>
                    );
                  })}
                  <FormControl>
                    
                    <Typography
                      variant="custom"
                      className="text-[#018FFD] font-[600] text-center md:text-left"
                    >
                      Total
                    </Typography>
                    <Input
                      variant="form"
                      size="lg"
                      className="max-w-[12rem]"
                      classNames={{
                        input: "px-4 text-center",
                      }}
                      isDisabled
                      defaultValue={`$${data?.feeTotal}` ?? ""}
                      errorMessage={errors.transferFee?.message}
                    />
                  </FormControl>
                </CardBody>
                <CardFooter
                  className="h-60 bg-cover bg-center bg-[#F1F1F180] -mt-64"
                  style={{
                    backgroundImage: "url(assets/images/footer-background.png)",
                  }}
                ></CardFooter>
              </Card>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between gap-2 mb-6">
      {children}
    </div>
  );
};
