import { signal } from "@preact/signals-react";
import {
  DocumentServiceQuotesRequest,
  TilingRequest,
  TilingSelectAtcProcedureResponse,
} from "services/endpoints/tiling/tiling.type";

export const dmvtitlingSignal = signal<TilingRequest | null>(null);
export const dmvDocumentServiceSignal =
  signal<Partial<DocumentServiceQuotesRequest> | null>(null);

export const selectedProcedureSignal =
  signal<TilingSelectAtcProcedureResponse | null>(null);

export const vehicleInfoSignal = signal({
  vehicleType: "",
  vehicleBodyStyle: "",
  vehicleFuelType: "",
});
export const enterMissingInfoDefaultSignal = signal({
  transferPlates: [
    { label: "Transfering Plates?", name: "transferingPlates" },
    {
      label: "License Plate Number",
      name: "licensePlateNumber",
      isDisabled: true,
    },
    {
      label: "License Plate State",
      name: "licensePlateState",
      isDisabled: true,
    },
  ],
  doesVehicleHaveLoan: [
    {
      label: "Does Vehicle Have Loan?",
      name: "doesVehicleHaveLoan",
    },
    {
      label: "Lienholder Company",
      name: "lienholderCompany",
      isDisabled: true,
    },
    { label: "Lienholder Name", name: "lienholderName", isDisabled: true },
    { label: "Policy Number", name: "policyNumber", isDisabled: false },
    { label: "Insurance Company", name: "insuranceCompany", isDisabled: false },
  ],
  buyerOneDetails: [
    { label: "Buyer First Name", name: "fbFirstName" },
    { label: "Buyer Middle Name", name: "fbMiddleName" },
    { label: "Buyer Last Name", name: "fbLastName" },
    { label: "Buyer Company Name", name: "fbCompany" },
    { label: "Buyer Address", name: "fbAddress" },
    { label: "Buyer Address 2", name: "fbAddress2" },
    { label: "Buyer Zip", name: "fbzip" },
    { label: "Buyer State", name: "fbState" },
    { label: "Buyer City", name: "fbCity" },
    { label: "Buyer Driver License Number", name: "fbdlNumber" },
    { label: "Buyer Driver License State", name: "fbdlState" },
  ],
  buyerTwoDetails: [
    { label: "Buyer First Name", name: "sbFirstName", isDisabled: true },
    { label: "Buyer Middle Name", name: "sbMiddleName", isDisabled: true },
    { label: "Buyer Last Name", name: "sbLastName", isDisabled: true },
    { label: "Buyer Company Name", name: "buyerCompanyName", isDisabled: true },
    { label: "Buyer Address", name: "sbAddress1", isDisabled: true },
    { label: "Buyer Address 2", name: "sbAddress2", isDisabled: true },
    { label: "Buyer City", name: "sbCity", isDisabled: true },
    { label: "Buyer State", name: "sbState", isDisabled: true },
    { label: "Buyer Zip", name: "sbzip", isDisabled: true },
    {
      label: "Buyer Driver License Number",
      name: "buyerDriverLicenseNumber",
      isDisabled: true,
    },
    {
      label: "Buyer Driver License State",
      name: "buyerDriverLicenseState",
      isDisabled: true,
    },
  ],
  sellerOneDetails: [
    { label: "Seller First Name", name: "fsFirstName" },
    { label: "Seller Middle Name", name: "fsMiddleName" },
    { label: "Seller Last Name", name: "fsLastName" },
    { label: "Seller Company Name", name: "fsCompany" },
    { label: "Seller Address", name: "fsAddress" },
    { label: "Seller Address 2", name: "sellerAddress2" },
    { label: "Seller City", name: "fsCity" },
    { label: "Seller State", name: "fsState" },
    { label: "Seller Zip", name: "fszip" },
    {
      label: "Seller Driver License Number",
      name: "sellerDriverLicenseNumber",
    },
    { label: "Seller Driver License State", name: "sellerDriverLicenseState" },
    {
      label: "Seller Dealer License Number",
      name: "sellerDealerLicenseNumber",
    },
  ],
  sellerTwoDetails: [
    { label: "Seller First Name", name: "ssFirstName", isDisabled: true },
    { label: "Seller Middle Name", name: "ssMiddleName", isDisabled: true },
    { label: "Seller Last Name", name: "ssLastName", isDisabled: true },
    {
      label: "Seller Company Name",
      name: "sellerCompanyName",
      isDisabled: true,
    },
    { label: "Seller Address", name: "sellerAddress", isDisabled: true },
    { label: "Seller Address 2", name: "sellerAddress2", isDisabled: true },
    { label: "Seller City", name: "sellerCity", isDisabled: true },
    { label: "Seller State", name: "sellerState", isDisabled: true },
    { label: "Seller Zip", name: "sellerZip", isDisabled: true },
    {
      label: "Seller Driver License Number",
      name: "sellerDriverLicenseNumber",
      isDisabled: true,
    },
    {
      label: "Seller Driver License State",
      name: "sellerDriverLicenseState",
      isDisabled: true,
    },
    {
      label: "Seller Dealer License Number",
      name: "sellerDealerLicenseNumber",
      isDisabled: true,
    },
  ],
});
