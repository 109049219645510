import { useEffect, useRef } from "react";
import { Button } from "@nextui-org/react";

import { getCarPartDetailForListing } from "core/http/listing/GetListingDescription";
import { PreviewSelectedListing } from "modules/dealer/features/Listings/components/PreviewSelectedListing";
interface UploadPhotosBulkProps {
  setCanCreateList: (canCreate: boolean) => void;
  files: File[];
  canCreateList: boolean;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setMainImg:React.Dispatch<React.SetStateAction<File|null>>;
  mainImg:File | null;
  aiCarDetails: { file: File; part: string }[];
  setIsEdited:React.Dispatch<React.SetStateAction<boolean>>;
  setCarAiDetails: React.Dispatch<
    React.SetStateAction<{ part: string; file: File }[]>
    >;
    listingId?: number | undefined;
    aiOnState:boolean
}

export const UploadPhotosBulk: React.FC<UploadPhotosBulkProps> = ({
  canCreateList,
  files,
  setCarAiDetails,
  setFiles,
  aiCarDetails,
  setCanCreateList,
  listingId,
  aiOnState,
  mainImg,
  setMainImg,
  setIsEdited
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setIsEdited(true)
    }
  };

  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      setCanCreateList(false);
    }
  };
  const removeFileAtIndex = (file: File) => {
    setCarAiDetails((prevFiles) =>
      prevFiles?.filter((fl, index) => (fl.file.name  !==  file.name && fl.file.lastModified !== file.lastModified))
    );
    setFiles((prevFiles) =>
      prevFiles?.filter((fl, index) => (fl.name  !==  file.name && fl.lastModified !== file.lastModified))
    );
    setIsEdited(true)
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setCanCreateList(false);
    setIsEdited(true)
  };

  useEffect(() => {
    if (listingId && files.length > 0 ) {
      // const formData = new FormData();
      // formData.append("listingId", String(listingId));
      // files.forEach((file) => {
      //   formData.append(`uploadfiles`, file);
      // });
      // apiClient
      //   .post("/Listings/UploadFiles", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       accept: "*/*",
      //     },
      //   })
      //   .then(() => {
          if(aiOnState){
            files.forEach((file) => {
              if(!aiCarDetails.find(fl=>(fl.file.name === file.name && fl.file.lastModified=== file.lastModified))){
                const img = new FormData();
                img.append(`image_file`, file);
                getCarPartDetailForListing(img)
                  .then((res) => {
                
                    setCarAiDetails((p) => [
                      ...p,
                      { file: file, part: res?.data?.part },
                    ]);
                    setCanCreateList(true);
                  })
                  .catch((err) => {
                    console.log(err);
                  });  
              }
  
              
            });
          }else{
            setCanCreateList(true);
          }
        // })
        // .catch((err) => {
        //   console.log(err);
        // });
    }
  }, [files,aiOnState]);

  return (
    <div className=" overflow-y-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className={`${files.length <= 0 ? " col-span-2" : ""}`}>
          <div
            className="p-2 modal-browse-files flex flex-col items-center justify-center "
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <img
              src="./assets/images/upload-file.png"
              alt="upload-file"
              className="h-10 lg:h-24 md:h-14 w-14 md:w-20 lg:w-32"
            />
            <h3 className="text-sm md:text-base py-2 md:py-4 text-black font-semibold">
              Drag & Drop Files or
            </h3>
            <input
              type="file"
              accept="image/*"
              multiple
              id="fileInput"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <Button
              className="flex items-center justify-center h-7 md:h-10 w-28 md:w-40 text-white hover:bg-blue-700 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-700 modal-browse-button"
              onClick={onHandleFileClick}
            >
              Browse files
            </Button>
          </div>
        </div>
        <div className="basis-1/2 overflow-y-auto max-h-[300px] xl:max-h-[350px] shadow-sm md:shadow-none">
          {files &&
            aiCarDetails &&
            files.map((file, index) => {
              const hasPart= aiCarDetails.find(data=>(data.file.name === file.name || data.file.lastModified===file.lastModified))
            
              return (
                <PreviewSelectedListing
                  key={index}
                  handleRemove={() => removeFileAtIndex(file)}
                  parts={aiOnState? hasPart?.part || "": true}
                  file={hasPart? hasPart.file : file}
                  isFirst={mainImg ? mainImg.name === file.name :index === 0  }
                  handleMakeFirst={()=>setMainImg(file)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
