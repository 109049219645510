import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { signal } from "@preact/signals-react";

import { TextErrorHelper, Typography } from "components";
import Input from "components/ui/input";
import { ProfitLossCalculator } from "./profit-loss-calculator.component";
import {
  RetailValueEstimate,
  VehicleHistorySnapshot,
  VehicleListingDescription,
} from "pages/shared";
import { AccordionCarData } from "./accordion-car-data.component";
import {
  ListingDescriptionRequest,
  useCreateVehicleCondition,
  useGetCurrentUserForBoth,
  useGetListingAdvanceData,
  useGetListingDetails,
  useGetListingPriceData,
  useGetVehicleDescription,
  useGetVindataDecode,
  useUpdateListingData,
  useUpdateListingDataMut,
  useUpdateListingStatus,
  useUploadFiles,
} from "services";
import { ActionListing } from "./action-listing.component";
import Form from "components/ui/form";
import {
  IManageListingValidationSchema,
  ManageListingValidationSchema,
} from "./manage-listing.validation";
import { findVehicleBy } from "utils/find-vehicle-by";
import {
  filesSignalLintingImg,
  signalhasDoneBulkUpload,
} from "./upload-photos.component";
import { capitalizeAllBeforeDash, capitalizeForDashOrWhiteSpace } from "utils";
import { getUserId, isThisDealer } from "utils/get-user-id";
import { useGetCreditCardMethodStatusData } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { localStorageFn } from "utils/localstorage";
import { RootState } from "modules/dealer/store/store";
import { useSelector } from "react-redux";
import { Image } from "@nextui-org/react";
import { filesSignalLintingDocuments } from "./upload-documents.component";
import { ActionResearch } from "./action-research.component";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const signalHasClickedMakeLiveListing = signal(false);

interface Props {
  forDealer?: boolean;
  isItCreate?: boolean;
  status?: "reseach" | "";
}

const ManageListingPage = ({
  forDealer = true,
  isItCreate = false,
  status = "",
}: Props) => {
  const params = useParams();
  const userId = getUserId();
  const navigate = useNavigate();
  const [period, setPeriod] = React.useState(180);
  const [aiOnState, setAiOnState] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [actionBtn, setActionBtn] = React.useState(1); // mark sold - 2, make live - 1, achieve/add to garage - 0 , soft delete - 3
  const [mileage, setMileage] = React.useState<string | null>(null);
  const { mutateAsync: mutateAsyncVehicleCondition } =
    useCreateVehicleCondition();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    // reset,
    // trigger,
    setError,
    getValues,
  } = useForm<IManageListingValidationSchema>({
    resolver: zodResolver(ManageListingValidationSchema),
  });
  const { data: currentUserData } = useGetCurrentUserForBoth(userId, forDealer);
  const {
    data: listingDetailsData,
    isLoading: isLoadingToListingDetails,
    refetch: refetchListingData,
  } = useGetListingDetails(Number(params?.listingId) ?? 0);
  const { data: listingAdvanceData, refetch: listingAdvanceDataRefetch } =
    useGetListingAdvanceData(params?.vin ?? "");
  const listing = listingDetailsData?.listing;
  const { data: priceData, refetch } = useGetListingPriceData(
    period,
    params?.vin ?? "",
    mileage ?? "0"
  );

  React.useEffect(() => {
    refetch();
  }, [refetch, period, mileage]);

  const {
    data: listingDescriptionData,
    mutateAsync: mutateAsyncListingDescription,
    isPending: isLoadingToListingDescription,
    error: errorMesAIdesc,
  } = useGetVehicleDescription();

  //create make live linting

  const {
    mutateAsync: mutateAsyncUpdateListingData,
    isPending: isPendingUpdateListing,
    error: updateListingError,
  } = useUpdateListingDataMut();
  // const { mutateAsync: uploadFileAnsc, error: errorForUploadFiles } =
  //   useUploadFiles();

  const { data: checkIfPaymentExist, refetch: refetchPaymentForCardDetail } =
    useGetCreditCardMethodStatusData(true);
  const vehicleCondition = useSelector(
    (state: RootState) => state.vehicleCondition.vehicleCondition
  );
  const handleMileageChange = (e: {
    target: { value: React.SetStateAction<string | null> };
  }) => {
    setMileage(e.target.value);
  };

  const {
    data: vinDecodeData,
    isLoading,
    isFetchedAfterMount: isFetchedAfterMountVinDecoded,
  } = useGetVindataDecode(params?.vin ?? "");
  const message = vinDecodeData?.message;

  const make = findVehicleBy(message ?? [], "Make")?.value ?? "";
  const model = findVehicleBy(message ?? [], "Model")?.value ?? "";
  const year = findVehicleBy(message ?? [], "ModelYear")?.value ?? "";
  const trim =
    findVehicleBy(message ?? [], "Trim")?.value ??
    findVehicleBy(message ?? [], "Series")?.value ??
    "";

  const nameWithTrim = `${year} ${capitalizeForDashOrWhiteSpace(
    make
  )} ${capitalizeAllBeforeDash(model)} ${trim?.toUpperCase()}`;

  const name = `${year} ${capitalizeForDashOrWhiteSpace(
    make
  )} ${capitalizeAllBeforeDash(model)}`;

  const handleTryAgainListingDescription = async () => {
    try {
      if (vinDecodeData && status === "") {
        const payload: ListingDescriptionRequest = {
          carModel: name,
          year,
          make,
          model,
          trim,
        };
        await mutateAsyncListingDescription(payload);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (isFetchedAfterMountVinDecoded && isItCreate) {
      handleTryAgainListingDescription();
    }
  }, [isFetchedAfterMountVinDecoded]);

  const onClickMakeLive = async (formData: IManageListingValidationSchema) => {
    try {
      signalHasClickedMakeLiveListing.value = true;
      if (!listingDescriptionData || !currentUserData?.zipCode) {
        return;
      }

      if (
        (actionBtn === 1 || (isThisDealer() === false && actionBtn === 0)) &&
        filesSignalLintingImg.value.length < 4
      ) {
        return;
      }
      if(actionBtn === 2 && !getValues("purchasePrice") ){
        setError("purchasePrice",{"message":"Purchase Price is required."})
        return;
      }else{
        setError("purchasePrice",{})
      }

      const vehicleConditionWithListingId: any = {
        ...vehicleCondition,
        listingId: Number(params?.listingId),
      };

      mutateAsyncVehicleCondition(vehicleConditionWithListingId).then((e) => {
        console.error("error :", e);
      });
   
      const res = await updateListingAPICall(aiOnState, status === "reseach"
        ? false
        : isThisDealer()
        ? filesSignalLintingImg.value.length > 3
          ? true
          : actionBtn !== 0
        : true);
      refetchListingData();
      signalHasClickedMakeLiveListing.value = false;

      // const formDataDoc = new FormData();
      // formDataDoc.append("listingId", String(params?.listingId));
      //   filesSignalLintingDocuments.value.forEach((flx, index) => {
      //     formDataDoc.append(`uploadfiles`, flx);
      //   });

      if (
        !isPendingUpdateListing &&
        res?.listingInput?.listingId &&
        actionBtn === 1
      ) {
        signalhasDoneBulkUpload.value = false;
        signalHasClickedMakeLiveListing.value = false;
        // window.open(
        //   `#/listing/view-listing/${res?.listingInput?.listingId}`,
        //   "_blank"
        // );

         setShowPreview(true);
      }

      if (actionBtn === 0 && !forDealer) {
        navigate("/user/garage");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateLiveList = async (formData: IManageListingValidationSchema) => {
    try {
      signalHasClickedMakeLiveListing.value = true;
      if (!currentUserData?.zipCode) {
        return;
      }

      if (
        (actionBtn === 1 || (isThisDealer() === false && actionBtn === 0)) &&
        filesSignalLintingImg.value.length < 4
      ) {
        return;
      }
      if(actionBtn === 2 && !getValues("purchasePrice") ){
        setError("purchasePrice",{"message":"Purchase Price is required."})
        return;
      }else{
        setError("purchasePrice",{})
      }
      
      const res = await updateListingAPICall(false,false);

      refetchListingData();
      signalHasClickedMakeLiveListing.value = false;

      if (
        !isPendingUpdateListing &&
        res?.listingInput?.listingId &&
        actionBtn === 1
      ) {
        signalhasDoneBulkUpload.value = false;
        signalHasClickedMakeLiveListing.value = false;
        // window.open(
        //   `#/listing/view-listing/${res?.listingInput?.listingId}`,
        //   "_blank"
        // );
        
        setShowPreview(true);
      }

      if (actionBtn === 0 && !forDealer) {
        navigate("/user/garage");
      }
      // localStorageFn.removeItem("usOpt");
    } catch (e) {
      console.log(e);
    }
  };

  const updateListingAPICall = async (
    hasPositionalImages:boolean,replaceImages:boolean
  ) => {
    
    const formDataPayload = new FormData();
    formDataPayload.append("listingInput.userId", userId?.toString());
    formDataPayload.append("listingInput.listingId", params?.listingId || "0");
    formDataPayload.append("listingInput.tenantId", userId?.toString());
    formDataPayload.append(
      "listingInput.description",
      listingDescriptionData
        ? JSON.stringify(listingDescriptionData)
        : listing?.description
        ? listing?.description
        : "{}"
    );
    formDataPayload.append("listingInput.name", nameWithTrim);
    formDataPayload.append(
      "listingInput.make",
      capitalizeForDashOrWhiteSpace(make)
    );
    formDataPayload.append("listingInput.model", model);
    formDataPayload.append("listingInput.trim", trim);
    formDataPayload.append("listingInput.year", year);
    formDataPayload.append("listingInput.vin", params?.vin || "");
    formDataPayload.append("listingInput.licensePlate", "");
    formDataPayload.append("listingInput.price", getValues("price")?.toString() || "0");
    formDataPayload.append("listingInput.purchasePrice", getValues("purchasePrice")?.toString()||"0");
    formDataPayload.append("listingInput.repairCost", getValues("repairCost")?.toString()||"0");
    formDataPayload.append("listingInput.reconditioningCost", getValues("reconditioningCost")?.toString()||"0");
    formDataPayload.append("listingInput.actualSalePrice", getValues("actualSalePrice")?.toString()||"0");
   
    formDataPayload.append("listingInput.financingPrice", getValues("financingPrice")?.toString()||"0");
    formDataPayload.append("listingInput.downPayment", getValues("downPayment")?.toString()||"0");
    formDataPayload.append("listingInput.interestRate", getValues("interestRate")?.toString()||"0");
    formDataPayload.append("listingInput.financeType", getValues("financeType")?.toString()||"0");
    formDataPayload.append("listingInput.term", getValues("term")?.toString()||"0");
    formDataPayload.append("listingInput.monthlyPayment", getValues("monthlyPayment")?.toString()||"0");
   
    formDataPayload.append("listingInput.status", actionBtn?.toString());

    formDataPayload.append(
      "listingInput.mileage",
      getValues("mileage")?.toString() || "0"
    );

    // Add auctionDetails properties
    formDataPayload.append(
      "auctionDetails.listingId",
      params?.listingId || "0"
    );
    formDataPayload.append("auctionDetails.listingPrice", "0");
    formDataPayload.append("auctionDetails.enableAuction", "false");
    formDataPayload.append("auctionDetails.startingBid", "0");
    formDataPayload.append("auctionDetails.reservePrice", "0");
    formDataPayload.append(
      "auctionDetails.biddingEndDate",
      "2025-02-02T00:00:00"
    );
    // console.log("filesSignalLintingImg.value", filesSignalLintingImg.value);
  
    filesSignalLintingImg.value.forEach((flx, index) => {
      formDataPayload.append(`uploadfiles`, flx.file, flx.part + index);
    });

    const res = await mutateAsyncUpdateListingData({ payload:formDataPayload, hasPositionalImages,replaceImages});
    return res;
  };

  
  

  return (
    <form
      id="manage-listing-form"
      onSubmit={
        isItCreate
          ? handleSubmit(onClickMakeLive)
          : handleSubmit(onUpdateLiveList)
      }
    >
      <>
        <div className="px-0 md:px-10 xl:px-32 mb-40">
          <>
            <div className="flex flex-col gap-7 items-center justify-center">
              <Typography variant="pageTitle">Manage Listing</Typography>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 mt-8">
              <div className="col-span-1 md:col-span-2">
                <Typography
                  variant="custom"
                  className="text-[#313131] font-[700] text-md md:text-large xl:text-xl text-center md:text-left"
                >
                  {name}
                </Typography>
                <Typography
                  variant="custom"
                  className="text-[#313131] font-[700] text-md md:text-large xl:text-xl text-center md:text-left"
                >
                  {trim}
                </Typography>
              </div>
              <div className="col-span-1 md:col-span-2 mt-6 md:mt-0">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 items-center">
                  <Typography
                    variant="custom"
                    className="col-span-3 md:col-span-1 md:col-start-2 text-[#313131] font-[600] text-md md:ml-3"
                  >
                    Enter Mileage
                  </Typography>
                  <Input
                    type="number"
                    className="col-span-3 md:col-span-2"
                    min={1}
                    classNames={{
                      input: "text-center px-4",
                    }}
                    defaultValue={params?.mileage}
                    errorMessage={errors?.mileage?.message}
                    {...register("mileage")}
                    onChange={handleMileageChange}
                  />
                  <Typography
                    variant="custom"
                    className="col-span-4 md:col-span-1 md:col-start-3 md:col-end-5 mt-2 md:-mt-2  text-[#313131] font-[500] md:font-[600] text-xs md:text-xs text-center "
                  >
                    VIN - <span className="uppercase"> {params ? params?.vin : "N/A"}</span>
                  </Typography>
                </div>
              </div>
            </div>
          </>
          {isThisDealer() && <ProfitLossCalculator setValue={setValue} listingDetailsData={listingDetailsData}/>}
          <RetailValueEstimate
            retailEstimate={priceData}
            onCallBack={setPeriod}
          />
          <VehicleHistorySnapshot
            register={register}
            errors={errors}
            defaultValue={listingDetailsData?.listing?.price?.toString() || ""}
            listingAdvanceData={listingAdvanceData}
            isItCreate={isItCreate}
            actionBtn={actionBtn}
            setValue={setValue} 
            listingDetailsData={listingDetailsData}
          />
          <AccordionCarData
            isCardAdded={checkIfPaymentExist}
            listingId={params?.listingId || ""}
            vinNumber={params?.vin ?? ""}
            listingAdvanceDataRefetch={listingAdvanceDataRefetch}
            refetchPaymentForCardDetail={refetchPaymentForCardDetail}
          />
          {status === "" && (
            <VehicleListingDescription
              listingDescription={
                JSON.parse(listingDetailsData?.listing?.description || "{}")?.car_description
                  ? JSON.parse(listingDetailsData?.listing?.description || "{}")
                  :(  listingDescriptionData ?? null)
              }
              carModel={nameWithTrim}
              handleTryAgain={handleTryAgainListingDescription}
              isLoading={
                 isLoadingToListingDescription
                  || isLoadingToListingDetails
              }
              showRefetch={!listingDescriptionData}
              errorMessage={errorMesAIdesc?.message}
            />
          )}
          {isSubmitted &&
            !listingDescriptionData &&
            isItCreate &&
            status === "" && (
              <div className=" mt-4">
                <div className="flex flex-row gap-2 mr-2">
                  <Image src="/assets/icons/error-outline.svg" alt="error" />
                  <TextErrorHelper className="text-lg">
                    {" "}
                    AI description of your car is required to create a car
                    listing.
                  </TextErrorHelper>
                </div>
              </div>
            )}
          
          {isSubmitted && !currentUserData?.zipCode && (
            <div className=" mt-4">
              <div className="flex flex-row gap-2 mr-2">
                <Image src="/assets/icons/error-outline.svg" alt="error" />
                <TextErrorHelper className="text-lg">
                  {" "}
                  Please update your account with a zip code to create a
                  listing.
                </TextErrorHelper>
              </div>
            </div>
          )}
          {isSubmitted && (updateListingError?.message || errors.purchasePrice?.message) && (
            <div className=" mt-4">
              <div className="flex flex-row gap-2 mr-2">
                <TextErrorHelper className="text-lg">
                  {updateListingError?.message || errors.purchasePrice?.message}
                </TextErrorHelper>
              </div>
            </div>
          )}
          {status === "reseach" ? (
            <ActionResearch
              isItCreate={isItCreate}
              isPendingUpdateListing={isPendingUpdateListing}
              listing={listing}
              params={params}
              showPreview={showPreview}
              getValues={getValues}
              setShowPreview={setShowPreview}
              actionBtn={actionBtn}
              seActionType={setActionBtn}
              snapshot={listingDetailsData?.snapshot}
              refetchListingData={refetchListingData}
              mutateAsyncUpdateListingData={mutateAsyncUpdateListingData}
              cancelData={{
                nameWithTrim: nameWithTrim,
                year: year,
                trim: trim,
                model: model,
                make: make,
              }}
            />
          ) : (
            <ActionListing
              isItCreate={isItCreate}
              isPendingUpdateListing={isPendingUpdateListing}
              listing={listing}
              params={params}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              actionBtn={actionBtn}
              aiOnState={aiOnState}
              setAiOnState={setAiOnState}
              seActionType={setActionBtn}
              snapshot={listingDetailsData?.snapshot}
              refetchListingData={refetchListingData}
              updateListingAPICall={updateListingAPICall}

            />
          )}
        </div>
        {/* {isLoading && <Loading />} */}
      </>
    </form>
  );
};

export default ManageListingPage;
