import { Button } from "@nextui-org/react";
import { Typography } from "components";
import Input from "components/ui/input";
import { IShippingPaymentInfoNew } from "../new-order/new-order.form";
import { useNavigate } from "react-router-dom";

const FORM_FIELDS_DATA_PAYMENT_FORM = {
  topSection: [
    {
      label: "Reference Number",
      name: "referenceNumber",
    },
    {
      label: "Quote ID",
      name: "quoteId",
    },
  ],
  bottomLeftSection: [
    {
      label: "Shipping ZIP",
      name: "shippingZip",
    },
    {
      label: "Shipping Street Address",
      name: "shippingStreetAddress",
    },
    {
      label: "Destination ZIP",
      name: "destinationZip",
    },
    {
      label: "Destination Street Address",
      name: "destinationStreetAddress",
    },
  ],
  bottomRightSection: [
    {
      label: "Price",
      name: "price",
    },
    {
      label: "Service Charge",
      name: "serviceCharge",
    },
    {
      label: "Total Price",
      name: "totalPrice",
    },
  ],
};

interface Props {
  vehicleName?: string;
  handleProceed: () => void;
  handleSaveQuotes: () => void;
  payData: IShippingPaymentInfoNew | null;
  isPendingToProceed?: boolean;
  isSaveQuotesPending?: boolean;
  showSaveQuotes?: boolean;
  vin:string
  from?: string;
}

const formatedPriceLabel = (value?: number) => {
  return value ? ("$" + value) : "N/A"
}

export const PaymentFormNewShipping = ({
  from,
  vehicleName,
  handleProceed,
  payData,
  handleSaveQuotes,
  isPendingToProceed,
  isSaveQuotesPending,
  vin,
  showSaveQuotes = true,
}: Props) => {
  const valueOfForm = {
    referenceNumber: vin || "N/A",
    quoteId: payData?.pay?.QuoteID || "N/A",
    totalPrice: formatedPriceLabel(payData?.pay.TotalPrice ) ,
    serviceCharge:formatedPriceLabel(payData?.pay.ServiceCharge),
    price: formatedPriceLabel(payData?.pay.Price),
    destinationStreetAddress: payData?.form?.destinationStreetAddress,
    destinationZip: payData?.form?.destinationZip,
    shippingStreetAddress: payData?.form?.shippingStreetAddress,
    shippingZip: payData?.form?.shippingZip,
  };

  const navigate = useNavigate();
  return (
    <div className="px-10 pb-20 justify-items-center items-center">
      <Typography
        variant="custom"
        className="font-[Metropolis] text-2xl lg:text-3xl font-bold text-black text-center 
        bg-gradient-to-r from-blue-600 to-indigo-900 text-transparent bg-clip-text"
      >
        {vehicleName ?? "2023 Ford Bronco Outer Banks 4-Door 4WD"}
      </Typography>
      <div className="grid col-span-2">
        <div className="mt-10 grid">
          {FORM_FIELDS_DATA_PAYMENT_FORM.topSection.map(
            ({ name, label }, idx) => {
              return (
                <FormControl key={idx}>
                  <Typography
                    variant="custom"
                    className="text-[#263238] font-[600] col-span-1 text-left text-sm 2xl:text-md"
                  >
                    {label}
                  </Typography>
                  <Input
                    variant="form"
                    size="compact"
                    className="col-span-4"
                    classNames={{
                      input: "px-4 text-center",
                    }}
                    disabled={true}
                    defaultValue={String(
                      valueOfForm[name as keyof typeof valueOfForm]
                    )}
                  />
                </FormControl>
              );
            }
          )}
          <div className="grid grid-cols-2 md:grid-cols-4">
            <div className="col-span-2">
              {FORM_FIELDS_DATA_PAYMENT_FORM.bottomLeftSection.map(
                (field, id) => {
                  return (
                    <FormControl key={id}>
                      <Typography
                        variant="custom"
                        className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                      >
                        {field.label}
                      </Typography>
                      <Input
                        variant="form"
                        size="compact"
                        className="col-span-3"
                        classNames={{
                          input: "px-4 text-center",
                        }}
                        defaultValue={String(
                          valueOfForm[field.name as keyof typeof valueOfForm]
                        )}
                        disabled={true}
                      />
                    </FormControl>
                  );
                }
              )}
            </div>
            <div className="col-span-2 ml-0 md:ml-10">
              {FORM_FIELDS_DATA_PAYMENT_FORM.bottomRightSection.map(
                (field, id) => {
                  return (
                    <FormControl key={id}>
                      <Typography
                        variant="custom"
                        className="text-[#263238] font-[600] col-span-2 text-left text-sm 2xl:text-md"
                      >
                        {field.label}
                      </Typography>
                      <Input
                        variant="form"
                        size="compact"
                        className="col-span-3"
                        classNames={{
                          input: "px-4 text-center",
                        }}
                        defaultValue={String(
                          valueOfForm[field.name as keyof typeof valueOfForm]
                        )}
                        disabled={true}
                      />
                    </FormControl>
                  );
                }
              )}
              <div>
                {from === "vehicle-shipping" ? (
                  <div>
                    <Button
                      className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm  w-full py-7 tracking-wide px-6 rounded-xl"
                      type="button"
                      size="md"
                      onClick={() => {
                        localStorage.clear();
                        navigate("/register/user");
                      }}
                    >
                      Create an Account
                    </Button>
                  </div>
                ) : (
                  <div
                    className={
                      showSaveQuotes
                        ? "grid grid-cols-1 gap-4 md:grid-cols-2"
                        : "flex justify-center items-center "
                    }
                  >
                    {showSaveQuotes && (
                      <Button
                        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-md  w-full py-7 tracking-wide px-6 rounded-xl"
                        type="button"
                        size="md"
                        onClick={handleSaveQuotes}
                        isLoading={isSaveQuotesPending}
                      >
                        Save Quote
                      </Button>
                    )}
                    <Button
                      className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-md w-full py-7 tracking-wide px-6 rounded-xl"
                      type="button"
                      size="md"
                      onClick={handleProceed}
                      isLoading={isPendingToProceed}
                    >
                      Proceed
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 gap-2 items-center mb-4">
      {children}
    </div>
  );
};
