import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { pendingSalesData } from 'services/endpoints/pending-sales/pending-sales.type'

interface PaymentInfoType {
  type:string,
  shipmentRequire: boolean
} 
export interface AcceptOfferState {
  currectSale: pendingSalesData | null,
  paymentInfo:  PaymentInfoType | null
}

const initialState: AcceptOfferState = {
  currectSale: null,
  paymentInfo:null
}

export const acceptOfferSlice = createSlice({
  name: 'acceptOffer',
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    changeDataForacceptOffer: (state, action: PayloadAction<pendingSalesData|null>) => {
      state.currectSale = action.payload
    },
    changeDataForPaymentInfo: (state, action: PayloadAction<  PaymentInfoType | null>) => {
      state.paymentInfo = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {  changeDataForacceptOffer,changeDataForPaymentInfo } = acceptOfferSlice.actions

export default acceptOfferSlice.reducer