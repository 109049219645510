import ButtonLoader from "modules/shared/components/ButtonLoader";
import React from "react";
import "./Loading.scss";
export default function Loading() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 opacity-50 z-50">
      <div className="loader"></div>
    </div>
  );
}
