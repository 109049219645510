import { Button, Image } from "@nextui-org/react";
import { Typography } from "components";
import Input from "components/ui/input";
import { useState } from "react";
import {
  UserUpdateRequest,
  useGetCurrentUserForBoth,
  useUpdateUser,
} from "services";
import { getUserId, isThisDealer } from "utils/get-user-id";

interface Props {
  type: string;
  username: string;
  onClose:()=>void
}
const EditUserName = ({ type = "USER", username,onClose }: Props) => {
  const userId = getUserId();
  const { data: userData, refetch: getUserDataAgain } =
    useGetCurrentUserForBoth(userId, isThisDealer());

  const {
    mutateAsync,
    data,
    isPending,
    error: userNameError,
  } = useUpdateUser();
  console.log("userNameError", userNameError);
  const [userName, serUserName] = useState(username);
  const [error, setError] = useState("");

  const handleUpdateUser = async () => {
    try {
      const payload: UserUpdateRequest = {
        address: userData?.address || "",
        firstName: userData?.firstName || "",
        lastName: userData?.lastName || "",
        email: userData?.email || "",
        phoneNumber: userData?.phoneNumber || "",
        dateOfBirth: userData?.dateOfBirth || "",
        id: userId,
        city: userData?.city || "",
        dealershipType: userData?.dealershipType || "",
        contactPerson: userData?.contactPerson || "",
        description: userData?.description || "",
        inventorySize: userData?.inventorySize || "",
        licenseNumber: userData?.licenseNumber || "",
        username: userName,
        // middleName: userData?.middleName || "",
        state: userData?.state || "",
        zipCode: userData?.zipCode || "",
      };

      const res = await mutateAsync(payload);
      if ("message" in res) {
        setError("Username Already Exist");
      } else {
        getUserDataAgain();
        onClose()
        setError("");
      }
    } catch (e) {
      console.log(e);
    }
    // if (res) window.location.reload();
  };

  return (
    <div className="flex w-full justify-center items-center">
      <div className="mt-32 w-full flex flex-col gap-8 items-center">
        <Typography
          variant="custom"
          className="text-white text-xl  md:text-4xl"
        >
          Change Username
        </Typography>
        <Input
          size="lg"
          className="w-3/4 md:w-1/2 "
          classNames={{
            input: "ml-2 text-left ",
            innerWrapper: "h-[69px] ",
          }}
          onChange={(e) => {
            const uname = e.target.value.trim();
            serUserName(uname);
            if (uname.length < 1) {
              setError("username required!");
            } else {
              setError("");
            }
          }}
          startContent={
            <Image
              src="/assets/icons/username-pencil-edit.svg"
              className="w-[20px] h-[20px]"
              removeWrapper
              radius="none"
            />
          }
          // isDisabled={!openModal}
          radius="xl"
          defaultValue={username}
        />
        <Typography variant="error" className="text-xl">
          {" "}
          {error}
        </Typography>
        <Button
          className="bg-white h text-[#018ffd] text-lg  h-full py-4 px-16"
          size="sm"
          disableAnimation
          onClick={handleUpdateUser}
          isLoading={isPending}
        >
          <Typography
            variant="custom"
            className="text-xs md:text-xl font-[600]"
          >
            Save
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default EditUserName;
