import {
  Textarea as NextTextarea,
  TextAreaProps,
  extendVariants,
  VariantProps,
} from "@nextui-org/react";
import { FC, forwardRef } from "react";

const CustomTextareaVariants = extendVariants(NextTextarea, {
  variants: {
    variant: {
      custom: {
        inputWrapper: [
          "border",
          "border-blue-400",
          "transition-colors",
          "data-[hover=true]:border-blue-600",
          "group-data-[focus=true]:border-blue-600",
          "h-[130px]",
        ],
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:px-0",
        ],
      },
      ticket: {
        input: [
          "text-zinc-800",
          "placeholder:text-zinc-500",
          "placeholder:font-[500]",
          "placeholder:font-sans",
          "placeholder:px-0",
          "h-full",
          "data-[has-start-content=true]:ps-9",
        ],
        inputWrapper: [
          "px-0",
          "relative",
          "w-[90%]",
          "h-[130px]",
          "border",
          "border-double",
          "border-transparent",
          "bg-origin-border",
          "[background-clip:padding-box,border-box]",
          "[background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)]",
          "transition-colors",
        ],
      },
    },
    color: {
      stone: {
        inputWrapper: [
          "bg-zinc-100",
          "border",
          "shadow",
          "transition-colors",
          "focus-within:bg-zinc-100",
          "data-[hover=true]:border-zinc-600",
          "data-[hover=true]:bg-zinc-100",
          "group-data-[focus=true]:border-zinc-600",
        ],
        input: ["text-zinc-800", "placeholder:text-zinc-600"],
      },
    },
    size: {
      compact: {
        inputWrapper: "h-unit-11 min-h-unit-11",
        input: "text-tiny px-1",
      },
      xs: {
        inputWrapper: "h-unit-6 min-h-unit-6 px-1",
        input: "text-tiny",
      },
      md: {
        inputWrapper: "h-unit-10 min-h-unit-10",
        input: "text-small",
      },
      xl: {
        inputWrapper: "h-unit-16 min-h-unit-16",
        input: "text-medium",
      },
    },
    radius: {
      base: {
        inputWrapper: "rounded-xl",
      },
      xs: {
        inputWrapper: "rounded",
      },
      sm: {
        inputWrapper: "rounded-[4px]",
      },
      xl: {
        inputWrapper: "rounded-3xl",
      },
    },
    textSize: {
      base: {
        input: "text-base",
      },
    },
    removeLabel: {
      true: {
        label: "hidden",
      },
      false: {},
    },
  },
  defaultVariants: {
    variant: "bordered",
    textSize: "base",
    removeLabel: false,
  },
});

type TextareaVariantProps = VariantProps<typeof CustomTextareaVariants>;

type MergedTextareaProps = TextareaVariantProps &
  Omit<TextAreaProps, keyof TextareaVariantProps>;

const Textarea = forwardRef<HTMLTextAreaElement ,MergedTextareaProps >(function Textarea(
  { variant = "custom", ...otherProps },
  ref
) {
  return <CustomTextareaVariants ref={ref} variant={variant} {...otherProps} />;
});

export default Textarea;
