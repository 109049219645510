import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";
import { Typography } from "components";

interface CookieBannerProps {
  onCallback: (value: number) => void;
}

const CookieBanner = ({ onCallback }: CookieBannerProps) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  return (
    <>
      <div className="w-full px-1 sm:px-3 md:px-5 lg:px-[30px] xl:px-[130px] 2xl:px-[230px] mt-4 md:mt-16 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-6 items-center mb-5">
        <Typography
          variant="custom"
          className="col-span-2 lg:col-span-1 xl:col-span-2 text-white text-xs md:text-[15px] font-[300] text-center md:text-left"
        >
          We use cookies to improve your experience on our website, personalize
          content and ads, and analyze our traffic. We use both required cookies
          that are essential for the website to function and optional cookies
          that help us improve your experience.
        </Typography>
        <div className="col-span-2 grid justify-items-center lg:col-span-1 lg:flex lg:items-center gap-5 lg:gap-8 lg:justify-end">
          <Button
            variant="bordered"
            className="border border-blue-500 rounded-md py-6"
            onPress={onOpen}
          >
            <Typography
              variant="custom"
              className="text-white font-[500] md:text-xs xl:text-[15px]"
            >
              Cookie Preferences
            </Typography>
          </Button>
          <Button
            className="border rounded-md bg-white py-6"
            onClick={() => onCallback(1)}
          >
            <Typography
              variant="custom"
              className="text-blue-500 font-[500] md:text-xs xl:text-[15px]"
            >
              Accept all Cookies
            </Typography>
          </Button>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="full"
        className="w-full h-auto bg-gradient-to-r from-[#018ffd] to-[#070c84] py-10 px-6 md:px-20 text-white text-justify border-b-[2rem]"
        placement="bottom"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="grid lg:grid-cols-2 items-center gap-20">
                <div className="grid lg:grid-cols-2 items-center gap-4">
                  <Button
                    variant="bordered"
                    className="p-6  text-white  md:mx-8 lg:m-0 "
                    onClick={() => onCallback(0)}
                  >
                    Only Accept Required Cookies
                  </Button>
                  <Typography variant="custom">
                    This option only enables the cookies necessary for the
                    website to function. You can learn more about our cookie
                    policy here.
                  </Typography>
                </div>
                <div className="grid lg:grid-cols-2 items-center gap-4">
                  <Button
                    variant="bordered"
                    className="p-6 text-white  md:mx-8 lg:m-0"
                    onClick={() => onCallback(1)}
                  >
                    Accept All Cookies
                  </Button>
                  <Typography variant="custom">
                    This option enables all cookies, both required and optional.
                  </Typography>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CookieBanner;
