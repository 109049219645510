import * as React from "react";
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { SendSellerCustomizedOffer } from "../../shared/send-seller-customized-offer.component";
import {
  PayNow,
  PaymentModal,
  SendSellerFinanceOffer,
  VehicleFactoryInstalled,
  VehicleHistoryReport,
} from "pages/shared";
import {
  useGetPriceAdvanceDataPdf,
  useGetVehicleHistoryData,
  useGetVindataDecode,
} from "services";
import "./index.scss";
import downArrow from "assets/svgs/gradient-down-arrow.svg";
import { transformData } from "utils/transform-data";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import { useAddPaymenTransactiontMethod, useAddPaymentOptionForPublicUserMethod } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { PaymentCardInfo } from "services/endpoints/shipping-quotes/shipping.type";
import { useProcessFMOReportSubscription } from "hooks/api/queries/view-cars/view-cars";
import { Image } from "@nextui-org/react";
import { TextErrorHelper } from "components";
import { localStorageFn } from "utils/localstorage";
import { CalculateTaxDMVShipping } from "pages/shared/dmv-tax-shiping-calcultor.component";

interface AccordionViewCarProps {
  vinNumber: string;
  listingId: string;
  isCardAdded: PaymentCardInfo | undefined;
  listing?: any;
  refetchPaymentForCardDetail:()=>void
}

export type ListingPaymentCustomOpt =
  | "FMO_MARKET_VALUE"
  | "FMO_OPTIONS_DATA_VALUE"
  | "FMO_VIN_AUDIT_JSON"
  | "";

export const AccordionViewCar: React.FC<AccordionViewCarProps> = ({
  vinNumber,
  listingId,
  isCardAdded,
  listing,
  refetchPaymentForCardDetail
}) => {
  const isLogin = !!localStorageFn.getItem("access_token");
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);
  const [accordionClick, setAccordionClick] = React.useState(false);
  const [factoryData, setFactoryData] = React.useState<any>([]);

  const [showPayModal, setShowPayModal] = React.useState<{
    show: boolean;
    ammount: string;
    tierId: ListingPaymentCustomOpt;
  }>({
    show: false,
    ammount: "",
    tierId: "",
  });

  const { data: priceAdvanceData, refetch: refetchPriceAdvanceData, isLoading: isPendingPdf } =
    useGetPriceAdvanceDataPdf(vinNumber);
  // dealer related api

  //listing related hooks
  const { data: vinDecodeData } = useGetVindataDecode(vinNumber);
  const {
    data: vehicleHistoryData,
    refetch: refetchVehicleHistoryData,
    error: errorVehicleHistoryData,
    isLoading:isPendingOptions
  } = useGetVehicleHistoryData(vinNumber);

  //payment related hooks
  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: errorMesForAppPayment,
  } = useAddPaymenTransactiontMethod();
  const {
    mutateAsync: mutateProcessFMOReportSubscription,
    isPending: isPendingToProcessFMOReportSubscription,
    error: errorForReportProceess,
  } = useProcessFMOReportSubscription(listingId, vinNumber);

  const {
    mutateAsync: mutateAddPaymenTransactiontMethodForPublicUserMethod,
    isPending: isPendingToAddPaymenTransactiontMethodForPublicUserMethod,
    error: errorMessageForPayment,
  } = useAddPaymentOptionForPublicUserMethod(
    vinNumber,
    showPayModal.tierId
  );

  const handlePaymentOnLisingOption = async (
    ammount: string,
    key: ListingPaymentCustomOpt
  ) => {
    setShowPayModal({ ammount: ammount, show: true, tierId: key });
  };
  const handleConfirmPaymentForOpt = async (e: any) => {
    try {
      if (isLogin) {
      if (isCardAdded) {
        await mutateProcessFMOReportSubscription(showPayModal.tierId);
        refetchPaymentForCardDetail()
      } else {
        await mutateAddPaymenTransactiontMethod(e);
        await mutateProcessFMOReportSubscription(showPayModal.tierId);
      } 
     } else {
      await mutateAddPaymenTransactiontMethodForPublicUserMethod(e);
      // await mutateProcessFMOReportSubscription("FMO_OPTIONS_DATA_VALUE");
    }
      //  setSelect(showPayModal.tierId as ListingPaymentCustomOpt)
      setShowPayModal((p) => ({ ...p, show: false }));
      // refetchVehicleHistoryData();
      // refetchPriceAdvanceData();
      if (showPayModal.tierId === "FMO_OPTIONS_DATA_VALUE") {
        refetchVehicleHistoryData();
      }

      if (showPayModal.tierId === "FMO_MARKET_VALUE") {
        refetchPriceAdvanceData();
      }
    } catch (e) {
      console.log("Error :", e);
    }
  };

  React.useEffect(() => {
    if (priceAdvanceData) {
      const blob = new Blob([priceAdvanceData], { type: "application/pdf" });
      setPdfUrl(URL.createObjectURL(blob));
    }
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [priceAdvanceData]);

  React.useEffect(() => {
    if (vehicleHistoryData)
      setFactoryData(transformData(vehicleHistoryData) || {});
  }, [vehicleHistoryData]);

  const onClickAccordion = () => {
    setTimeout(() => {
      setAccordionClick(!accordionClick);
    }, 100);
  };
  const newPlugin = defaultLayoutPlugin();

  
  return (
    <>
      <Accordion
        variant="splitted"
        className="flex mt-10 h-full"
        showDivider
        fullWidth
        selectionMode="multiple"
        itemClasses={{
          base: "p-2",
          heading:
            "text-xl font-[600] bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text",
          content: "",
          indicator: "text-[#018ffd]",
          title:
            "text-transparent font-bold bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xl font-[600]",
          startContent: "text-lg font-bold",
        }}
      >
        <AccordionItem
          key="1"
          aria-label="Basic Vehicle Data"
          title="Basic Vehicle Data"
          className="w-full px-6 py-1 font-boldrounded-lg shadow-right bg-[#f1f1f1]/50"
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
        >
          <VehicleHistoryReport
            data={
              vinDecodeData && vinDecodeData?.message?.length > 0
                ? vinDecodeData?.message?.slice(5)
                : []
            }
          />
        </AccordionItem>
        <AccordionItem
          key="2"
          aria-label="Vehicle History Report"
          title="Vehicle History Report"
          className="w-full px-6 py-1 font-boldrounded-lg shadow-right bg-[#f1f1f1]/50"
          classNames={{
            content: "flex flex-col items-center justify-center",
          }}
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
        >
          {pdfUrl ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div className="w-full h-[375px] md:h-[750px]">
                <Viewer fileUrl={pdfUrl} plugins={[newPlugin]} />
              </div>
            </Worker>
          ) : (
            <div className="flex justify-center items-center max-w-[42rem] w-full   ">
              <PayNow
                title="Vehicle History Report"
                price={4.99}
                isLoading={isPendingPdf}
                onPay={() =>
                  handlePaymentOnLisingOption("4.99", "FMO_MARKET_VALUE")
                }
              />
            </div>
          )}
        </AccordionItem>
        <AccordionItem
          key="3"
          aria-label="Factory Installed Options"
          title="Factory Installed Options"
          className="w-full  px-6 py-1 font-boldrounded-lg shadow-right bg-[#f1f1f1]/50"
          classNames={{
            content: "flex flex-col items-center justify-center",
          }}
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
          onPressStart={onClickAccordion}
        >
          {vehicleHistoryData ? (
            factoryData?.Options  ?<VehicleFactoryInstalled data={factoryData} />:<div className="flex flex-row gap-2 mr-2 p-4">
            <Image src="/assets/icons/error-outline.svg" alt="error" />
            <TextErrorHelper className="text-lg">
              Failed to Load Data! please try again.
            </TextErrorHelper>
          </div>
          ) : errorVehicleHistoryData?.response?.status === 402 ? (
            <div className="flex justify-center items-center max-w-[42rem] w-full   ">
              <PayNow
                title="Factory Installed Options"
                price={0.99}
                isLoading={isPendingOptions}
                onPay={() =>
                  handlePaymentOnLisingOption("0.99", "FMO_OPTIONS_DATA_VALUE")
                }
              />
            </div>
          ) : (
            <div className="flex flex-row gap-2 mr-2 p-4">
              {/* <Image src="/assets/icons/error-outline.svg" alt="error" /> */}
              <TextErrorHelper className="text-lg">
                We’re on it! An automated report wasn’t available for this vin,
                however, we are looking to find the options for you, a support
                ticket has been opened
              </TextErrorHelper>
            </div>
          )}
        </AccordionItem>
        <AccordionItem
          key="4"
          aria-label="Calculate Tax, DMV and Shipping"
          title="Calculate Tax, DMV and Shipping"
          className="w-full px-6 py-1 font-boldrounded-lg shadow-right bg-[#f1f1f1]/50"
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
        >
          <div className="grid gap-10">
            <CalculateTaxDMVShipping vin={vinNumber} listing={listing}  />
            {/* <SendSellerFinanceOffer /> */}
          </div>
        </AccordionItem>
      </Accordion>
      <PaymentModal
        isOpen={showPayModal.show}
        onOpen={() => {
          setShowPayModal((p) => ({ ...p, show: false }));
        }}
        Component={
          <PaymentFormWithDetails
            handleNext={handleConfirmPaymentForOpt}
            payAmmount={showPayModal.ammount}
            savedMethod={isCardAdded}
            isPending={
              isPendingToProcessFMOReportSubscription ||
              isPendingToAddPaymenTransactiontMethod ||
              isPendingToAddPaymenTransactiontMethodForPublicUserMethod
            }
            showAmmount={showPayModal.tierId !== ""}
            errorMessage={errorMessageForPayment?.message || errorMesForAppPayment?.message || errorForReportProceess?.message}
          />
        }
      />
    </>
  );
};
