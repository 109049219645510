export const formatPhoneNumber = (inputValue) => {
  const formattedValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

  if (formattedValue.length < 4) {
    return formattedValue;
  } else if (formattedValue.length < 7) {
    return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`;
  } else {
    return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
      3,
      6
    )}-${formattedValue.slice(6)}`;
  }
};

export const formatCentsCurrency = (inputValue) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return formatter.format(inputValue);
};
export const formatWholeCurrency = (inputValue) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(inputValue);
};
