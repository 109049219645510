import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "assets/svgs/logo";
import { MdOutlineCopyright } from "react-icons/md";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { UserAvatarIcon } from "assets/svgs/icons/user-avatar-icon";
import { EyeIcon } from "assets/svgs/icons/eye-icon";
import { EyeSlashedIcon } from "assets/svgs/icons/eye-slash-icon";
import { Button } from "@nextui-org/react";
import { useRegisterUserMutation } from "hooks/api/mutations/use-auth-mutation";
import { UserRegisterSchema } from "schemas/auth.schema";
import { z } from "zod";
import { LockIcon } from "assets/svgs/icons/lock-icon";
import { PenAndPaperIcon } from "assets/svgs/icons/pen-and-paper-icon";
import { PhoneIcon } from "assets/svgs/icons/phone-icon";
import { EmailIcon } from "assets/svgs/icons/email-icon";
import { env } from "config/env";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { Controller } from "react-hook-form";
import { PhoneNumberInput } from "components";
import { useDebounceValue } from "usehooks-ts";
import { useGetZipCodeDetails } from "services";

export default function UserRegister() {
  const [isVisible, setIsVisible] = useState(false);
  const [isOnClick, setOnclick] = useState(false);
  const [zipValue, setZipValue] = useState("");
  const [debouncedValue] = useDebounceValue(zipValue, 300);
  const {
    mutateAsync: zipcodeMutateAsync,
    data: zipcodeData,
  } = useGetZipCodeDetails(debouncedValue);

  useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);
  const [captchaState, setCaptchaState] = useState<{
    state: "error" | "success" | "default";
  }>(() => {
    return {
      state: "default",
    };
  });
  const captchaRef = useRef<ReCAPTCHA>(null);
  const navigate = useNavigate();

  const {
    mutate: registerMutation,
    isPending,
    isError,
    error,
  } = useRegisterUserMutation();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleRegister = async (data: z.infer<typeof UserRegisterSchema>) => {
    if(!zipcodeData) return;
  
    if (captchaState.state !== "success") {
      setCaptchaState({ state: "error" });
      return;
    }
    const token = captchaRef?.current?.getValue();
    captchaRef?.current?.reset();

    const result = {
      ...data,
      Username: data.email,
    };

    registerMutation(result);
  };

  function onCaptchaChange() {
    setCaptchaState({
      state: "success",
    });
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-3 px-4 py-2 md:gap-0 md:py-1 md:px-8 md:flex-row mt-10">
        <div
          className="flex w-full flex-col gap-8 shadow-xl md:shadow-none md:gap-2 px-4 bg-cover rounded-2xl text-white justify-between md:rounded-e-none md:rounded-s-3xl items-center text-center [text-shadow:_0_4px_4px_rgb(0_0_0_/_25%)] md:w-[440px] md:h-[660px] py-10"
          style={{ backgroundImage: "url('/assets/images/panel_left.png')" }}
        >
          <div
            className="pb-8 cursor-pointer mb-10 lg:mb-0"
            onClick={() => navigate("/listing/landing-page")}
          >
            <Logo className="w-40 h-16" />
          </div>
          <div className="pt-2 md:pt-32 items-center justify-between h-full w-full flex flex-col gap-6">
            <div className="space-y-5">
              <div className="text-2xl lg:text-4xl font-thin">
                Welcome Aboard!!
              </div>
              <div className="text-xs max-w-80">
                The future of automotive inventory and online listing management
                is here, and it&apos;s all in one place.
                <div className="text-xs">
                  Start a free account with us today!
                </div>
              </div>
            </div>
            <div className="flex gap-1 text-xs mt-20 lg:mt-0">
              findmyoptions.com <MdOutlineCopyright className="size-2" /> 2024
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-[480px] h-[660px] rounded-e-3xl  md:bg-[#f1f1f1]/50 md:border border-[#dbdbdb] justify-center items-center gap-2">
          <div
            className={`p-4 text-lg text-center md:w-8/12 ${
              isOnClick ? "mt-40" : "mt-16"
            } md:mt-0`}
          >
            Enter Information for a Better User Experience
          </div>
          <div className="w-full px-4 md:px-12">
            <Form schema={UserRegisterSchema} onSubmit={handleRegister}>
              {({ register, formState: { errors,isSubmitted }, control }) => {
                return (
                  <div className="flex flex-col gap-3">
                    <FormControl>
                      <Input
                        isDisabled={isPending}
                        variant="form"
                        radius="xl"
                        size="lg"
                        placeholder="First Name"
                        isInvalid={!!errors.firstName}
                        errorMessage={errors?.firstName?.message}
                        className="col-span-2"
                        classNames={{
                          input: "data-[has-start-content=true]:ps-7",
                          inputWrapper: !!errors.firstName
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : "",
                        }}
                        startContent={
                          <span className="absolute left-3 top-[12px]">
                            <UserAvatarIcon className="size-4" />
                          </span>
                        }
                        {...register("firstName")}
                      />
                      <Input
                        isDisabled={isPending}
                        variant="form"
                        radius="xl"
                        size="lg"
                        placeholder="Last Name"
                        isInvalid={!!errors.lastName}
                        errorMessage={errors?.lastName?.message}
                        className="col-span-2"
                        classNames={{
                          input: "data-[has-start-content=true]:ps-7",
                          inputWrapper: !!errors.lastName
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : "",
                        }}
                        startContent={
                          <span className="absolute left-3 top-[13px]">
                            <PenAndPaperIcon className="size-4" />
                          </span>
                        }
                        {...register("lastName")}
                      />
                    </FormControl>

                    <FormControl>
                      <PhoneNumberInput
                        key={"phoneNumber"}
                        control={control}
                        id={"phoneNumber"}
                        icon={<PhoneIcon className="size-4" />}
                        iconClass="absolute left-3 top-[13px]"
                        placeholder="Phone Number"
                        error={!!errors.phoneNumber}
                        InputProps={{
                          errorMessage: errors?.phoneNumber?.message,
                          radius:"xl",
                          isDisabled:isPending,
                          className: "col-span-2",
                          classNames: {
                            input: "data-[has-start-content=true]:ps-7",
                            inputWrapper: !!errors.phoneNumber
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          },
                        }}
                      />

                      <Input
                        isDisabled={isPending}
                        variant="form"
                        radius="xl"
                        size="lg"
                        placeholder="Zip Code"
                        maxLength={5}
                        isInvalid={!zipcodeData && isSubmitted}
                        errorMessage={!zipcodeData && isSubmitted && "ZIP Code is Invalid"
                      }
                        className="col-span-2"
                        classNames={{
                          input: "data-[has-start-content=true]:ps-7",
                          inputWrapper: !zipcodeData && isSubmitted
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : "",
                        }}
                        startContent={
                          <span className="absolute left-3 top-[13px]">
                            <EmailIcon className="size-4" />
                          </span>
                        }
                        // {...register("zipcode")}
                        onChange={(e) => {
                          const formattedValue = e.target.value?.replace(
                            /\D/g,
                            ""
                          );
                          setZipValue(formattedValue);
                        }}
                      />
                    </FormControl>
                    <Input
                      isDisabled={isPending}
                      variant="form"
                      radius="xl"
                      size="lg"
                      placeholder="Email"
                      isInvalid={!!errors.email}
                      errorMessage={errors?.email?.message}
                      classNames={{
                        input: "data-[has-start-content=true]:ps-7",
                        inputWrapper: !!errors.email
                          ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                          : "",
                      }}
                      startContent={
                        <span className="absolute left-3 top-[13px]">
                          <EmailIcon className="size-4" />
                        </span>
                      }
                      {...register("email")}
                    />

                    <Input
                      isDisabled={isPending}
                      variant="form"
                      radius="xl"
                      size="lg"
                      startContent={
                        <span className="absolute left-3 top-[13px]">
                          <LockIcon className="size-4" />
                        </span>
                      }
                      placeholder="Password"
                      type={isVisible ? "text" : "password"}
                      classNames={{
                        input: "data-[has-start-content=true]:ps-7",
                        inputWrapper: !!errors.password
                          ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                          : "",
                      }}
                      isInvalid={!!errors.password}
                      errorMessage={errors?.password?.message}
                      endContent={
                        <button
                          className="absolute right-3 focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isVisible ? (
                            <EyeIcon className="text-2xl pointer-events-none text-default-600" />
                          ) : (
                            <EyeSlashedIcon className="text-2xl pointer-events-none text-default-600" />
                          )}
                        </button>
                      }
                      {...register("password")}
                    />
                    <div className="flex flex-col gap-1 place-content-center md:mt-[5px]">
                      <ReCAPTCHA
                        sitekey={env.RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaChange}
                        className="flex place-content-center"
                        ref={captchaRef}
                      />

                      {captchaState.state === "error" && (
                        <div className="flex items-center justify-center m-auto">
                          <ErrorInfoIcon className="" />
                          <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold   text-left">
                            {"Please Select Captcha!"}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-center">
                      <Button
                        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-6 tracking-wide px-6 rounded-3xl"
                        isLoading={isPending}
                        type="submit"
                        onClick={() => {
                          if (captchaState.state === "default")
                            setCaptchaState({ state: "error" });

                          setOnclick(true);
                        }}
                        size="sm"
                      >
                        Sign Up
                      </Button>
                    </div>
                    {isError && (
                      <div className="flex flex-col items-center lg:py-2 text-red-500 text-sm lg:text-md">
                        {error.message}
                      </div>
                    )}
                    <div className={`flex justify-center text-xs -mt-2 `}>
                      <div>Already have an account?</div>
                      <Link to={"/login/user"} className="text-[#018ffd] pl-2">
                        {" "}
                        Login
                      </Link>
                    </div>
                  </div>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-4 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
