export default function ListingLoopView(props: any) {
  const { data } = props;

  return (
    <div className=" flex flex-col items-center overflow-y-auto  h-[500px] md:p-2 lg:p-5">
      {data.length > 0 &&
        data.map((vin: any, idx: number) => {
          return (
            <div
              key={idx}
              className={`flex justify-between w-full py-1 ${
                vin?.variable ? "bg-white" : "bg-[#f1f1f1]/50"
              } rounded-full dark:bg-gray-600 mb-3 md:p-2`}
            >
              <p className="w-full flex justify-between  items-center p-1 lg:p-3">
                <span className="text-white gradient-text w-[150px] sm:w-full">
                  {vin?.key ?? vin?.variable}
                </span>
                <span className="text-black break-word w-[150px] sm:w-full text-end">
                  {vin?.value ? vin?.value : "-"}
                </span>
              </p>
            </div>
          );
        })}
    </div>
  );
}
