import { useMutation } from "@tanstack/react-query";
// import { shippingEndpoint } from "services/endpoints/shipping-quotes/shipping.endpoint";
// import { AddCardMethodPayload, CarShippignData, GetShippingDetailsResponse, PaymentCardInfo, ShippingByIDResponse, ShippingDetailsPayload } from "services/endpoints/shipping-quotes/shipping.type";
import { viewCarEndpoint } from "services/endpoints/view-car/view-car.endpoint";
import {
  ISellerLeadPayload,
  PostSellerFinanceOfferPayload,
  SendSellerCustomCashOfferPayload,
} from "services/endpoints/view-car/view-car.type";
export const useGetSendSellerCustomCashOffer = () => {
  return useMutation<string, Error, SendSellerCustomCashOfferPayload>({
    mutationFn: async (payload) => {
      const res = await viewCarEndpoint.SendSellerCustomCashOffer(payload);
      return res;
    },
  });
};

export const usePostSellerFinanceOffer = () => {
  return useMutation<string, Error, PostSellerFinanceOfferPayload>({
    mutationFn: async (payload) => {
      const res = await viewCarEndpoint.PostSellerFinanceOffer(payload);
      return res;
    },
  });
};

export const usePostSellerLead = () => {
  return useMutation<string, Error, ISellerLeadPayload>({
    mutationFn: async (payload) => {
      const res = await viewCarEndpoint.PostSellerLead(payload);
      return res;
    },
  });
};
