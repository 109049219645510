import { Typography } from "components/typography";
import { NewOrderForm } from "./new-order.form";

export const NewOrderShippingPage = () => {
  return (
    <div className="grid grid-cols-1 justify-items-center py-3 w-full h-full p-20 gap-16">
      <div className="flex flex-col gap-7 items-center justify-center">
        <Typography variant="title" 
        className="font-[Metropolis] text-2xl lg:text-3xl font-bold text-black text-center 
        bg-gradient-to-r from-blue-600 to-indigo-900 text-transparent bg-clip-text"
        >Vehicle Shipping Quotes</Typography>
      </div>
      <div className="flex flex-col items-center justify-center mb-20 gap-12">
        <NewOrderForm />
      </div>
    </div>
  );
};