import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { VinDataType } from "../../components/enter-vin/type";

export interface ICarReseachInfo   {
  "vin": string,
  "mileage": string,
  "name": string,
  "phoneNumber": string,
  "listingId": number,
  "id": number,
  "listingStatus"?: number,
  "origin"?: string
  email? :string,
  userId?:number
  linkToListing?:string
}
export interface ManageListState {
  vinData: VinDataType[] | null;
  vin: string | null;
  mileage: number | 0;
  listingId: number | null;
  research: null |ICarReseachInfo

}

const initialState: ManageListState = {
  vinData: null,
  vin: null,
  mileage: 0,
  listingId: null,
  research: null,
  
};

export const manageListSlice = createSlice({
  name: "managelist",
  initialState,
  reducers: {
    changeVinDataForManageList: (
      state,
      action: PayloadAction<VinDataType[] | null>
    ) => {
      state.vinData = action.payload;
    },

    changeVinNumberForManageList: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.vin = action.payload;
    },
    changeMileageForManageList: (state, action: PayloadAction<number>) => {
      state.mileage = action.payload;
    },
    changeCurrentListingIdForManageList: (state, action: PayloadAction<number>) => {
      state.listingId = action.payload;
    },
    saveCarResearchInfo: (state, action: PayloadAction<ICarReseachInfo>) => {
      state.research = action.payload;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  changeVinDataForManageList,
  changeVinNumberForManageList,
  changeMileageForManageList,
  changeCurrentListingIdForManageList,
  saveCarResearchInfo
} = manageListSlice.actions;

export default manageListSlice.reducer;
