import React, { useState, useRef, useEffect, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';

interface PassProps {
    setCurrScreen: (screen: string) => void;
    handleScreenChange: () => void;
}

const PassChange: React.FC<PassProps> = ({ setCurrScreen, handleScreenChange }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const ticketContentRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ticketContentRef.current && !ticketContentRef.current.contains(event.target as Node)) {
            handleScreenChange();
        }
    };

    useEffect(() => {
        // The 'mousedown' event is of type 'any' to avoid type mismatch.
        document.addEventListener('mousedown', handleClickOutside as any);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside as any);
        };
    }, [ticketContentRef, handleScreenChange]);

    const navigateToForgotPass = () => {
        navigate('/forgot-password');
    };

    const login = () => {
        navigate('/');
    };

    return (
        <>
            {!isOpen && (
                <div className='bg-gradient-to-r from-blue-600 to-indigo-700 max-[540px]:h-[400px] max-[540px]:w-[300px] min-[768px]:h-[400px] min-[768px]:w-[500px] max-[768px]:h-[400px] max-[768px]:w-[500px] rounded-xl p-10 max-[768px]:p-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' style={{ fontFamily: 'Metropolis' }}>

                    <div onClick={navigateToForgotPass} className='float-right text-white text-3xl max-[768px]:text-2xl'>
                        <IoMdCloseCircleOutline />
                    </div>
                    <div className='flex items-center justify-center text-white text-[120px] mt-8 max-[768px]:text-[120px]'>
                        <IoIosCheckmarkCircleOutline  />
                    </div>
                    <div ref={ticketContentRef} className='text-center  max-[540px]:w-full min-[768px]:w-full max-[768px]:w-full  mt-8 max-[540px]:p-4 min-[768px]:p-8 max-[768px]:mt-12 max-[768px]:p-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                  
                        <h1 className='text-white min-[768px]:text-2xl max-[768px]:text-3xl min-[768px]:font-bold min-[768px]:mt-10 max-[768px]:mb-4 min-[768px]:mb-2 max-[540px]:text-[25px]'>Password Changed!</h1>
                        <h3 className='text-white min-[768px]:text-md max-[768px]:text-sm max-[768px]:mb-4 max-[540px]:mt-2 font-semibold max-[540px]:text-[15px] max-[540px]:leading-[17px]'>Your password has been changed successfully</h3>
                   
                    
                           
                            <button className='max-[540px]:w-32 min-[768px]:w-32 min-[768px]:h-12 max-[768px]:w-40 max-[768px]:h-8 m-auto min-[768px]:mt-4 max-[768px]:mt-4 bg-white rounded-xl max-[768px]:rounded-md cursor-pointer' type='submit' >
                                <h1 className='text-blue-500 min-[768px]:text-xs max-[768px]:text-sm font-semibold' onClick={login}>Back to Login</h1>
                            </button>

                    </div>
                </div>
            )}
        </>
    );
};

export default PassChange;