import React, { useEffect, useState } from "react";
import { Image, Button } from "@nextui-org/react";
import Input from "components/ui/input";
import searchIcon from "../manage-inventory/assets/svg/search-icon-gray.svg";

import { money } from "utils/money";

import { Typography } from "components";
import { isThisDealer } from "utils/get-user-id";
import OffScreenPopup from "./components/offer-screen-popup";
import { Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import { pendingSalesData } from "services/endpoints/pending-sales/pending-sales.type";
import { useGetPendingSalesData } from "hooks/api/queries/pending-sales/use-pending-sales";
import { useDispatch } from "react-redux";
import { changeDataForacceptOffer } from "modules/user/store/accept-offer-slice";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchCarCard from "modules/shared/components/car-card/SearchCarCard";
import { env } from "config/env";
import "../manage-inventory/archived-and-sold/archived-and-sold.scss";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

export default function PendingSales() {
  const [selectedCar, setSelectedCar] = useState();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [page, setPage] = useState<number | null>(1);
  const [selectedType, setSelectedType] = useState<number | null>(0);
  const [items, setItem] = useState<pendingSalesData[]>([]);
  const { refetch } = useGetPendingSalesData(page || 1, (newData) => {
    setPage((prev) => (newData.length > 0 ? prev && prev + 1 : null));
    setItem((prev) => [...prev, ...newData]);
  });
  const isThisUserDealer = isThisDealer();
  const dispatch = useDispatch();
  // const columns = isThisDealer()? [
  //   { name: "VIN", uid: "vin" },
  //   { name: "Year, Make & Model", uid: "yearMakeModel" },
  //   { name: "Price", uid: "price" },
  //   { name: "Offer", uid: "offer" },
  //   { name: "Status", uid: "status" },
  // ] : [
  //   { name: "VIN", uid: "vin" },
  //   { name: "Year, Make & Model", uid: "yearMakeModel" },
  //   { name: "Price", uid: "price" },
  //   { name: "Status", uid: "status" },
  // ];

  useEffect(() => {
    if (selectedCar) {
      dispatch(changeDataForacceptOffer(selectedCar));
    }
  }, selectedCar);

  // const renderCell = React.useCallback(
  //   (car: pendingSalesData, columnKey: any) => {
  //     switch (columnKey) {
  //       case "vin":
  //         return (
  //           <Link
  //             className="uppercase "
  //             target={"_blank"}
  //             href={`/#/listing/view-listing/${car.listingId}`}
  //           >
  //             <div className="text-xs">{car.vin}</div>
  //           </Link>
  //         );
  //       case "yearMakeModel":
  //         return (
  //           <span className="break-all text-xs">
  //             {(car?.year ? car.year + " " : "") +
  //               (car?.make || "") +
  //               " " +
  //               (car?.model || "")}
  //           </span>
  //         );
  //       case "price":
  //         return <span className="text-xs">${money(+car.price)}</span>;
  //       case "offer":
  //         return (
  //           <div className="relative flex items-center justify-center">
  //             <Image
  //               alt="Card background"
  //               shadow="none"
  //               removeWrapper
  //               radius="lg"
  //               className="w-5 h-5 lg:w-10 lg:h-10 cursor-pointer"
  //               src={viewIcon}
  //               onClick={() => {
  //                 setSelectedCar(car as any);
  //                 onOpen();
  //               }}
  //             />
  //           </div>
  //         );
  //       default:
  //         return <div className="text-xs">Sale Pending</div>;
  //     }
  //   },
  //   []
  // );
  const handleDealerCase = (car: any) => {
    setSelectedCar(car as any);
    onOpen();
  };
  // const hadleViewUser = async (id: string) => {
  //   window.open(`#/listing/view-listing/${id}`, "_blank");
  // };

  const filteredData =   items.filter(data=> data.offerStatus === selectedType )  
  return (
    <div>
      <div className="p-0">
        <InfiniteScroll
          dataLength={filteredData.length}
          next={refetch}
          hasMore={page !== null && filteredData.length >= 8}
          scrollThreshold={0.8}
          loader={
            <p className="flex justify-center items-center">
              <b>Loading...</b>
            </p>
          }
        >
          <div className="flex flex-col gap-4 ">
            <Typography variant="pageTitle" className="pb-16">
              Pending Sales
            </Typography>

            <div className="md:flex items-center justify-between gap-3">
              <div className="w-full md:flex justify-between input-container">
                <div className="w-full flex  items-center gap-4 mb-4 md:mb-0">
                  <Button
                    // name="Archived"
                    className={`
                    ${
                      selectedType === 0 ? "selected" : "default"
                    }  text-[15px] lg:text-[20px] px-5  text-center  rounded-xl w-full md:w-40
                `}
                    onClick={() => {
                      setSelectedType(0);
                    }}
                  >
                    Pending
                  </Button>
                  <div className="flex items-center justify-cente r   h-[54px] w-full">
                    <Button
                      // name=""
                      className={`${
                        selectedType === 1 ? "selected" : "default"
                      } text-[15px] lg:text-[20px] px-5 text-center  rounded-xl w-full md:w-40`}
                      onClick={() => {
                        setSelectedType(1);
                      }}
                    >
                      Sold
                    </Button>
                  </div>
                </div>
                <Input
                  variant="custom"
                  className="w-full md:w-[400px]"
                  placeholder="Search Keywords"
                  classNames={{ inputWrapper: "h-[54px] ", input:"placeholder:px-0", base:"placeholder:px-0" }}
           
                  startContent={
                    <Image
                      shadow="none"
                      removeWrapper
                      radius="lg"
                      className="w-4 h-4 lg:w-7 lg:h-7 cursor-pointer"
                      src={searchIcon}
                    />
                  }
                />
              </div>
            </div>
          </div>
          {/* <Table
            aria-label="table with dynamic content border-none"
            shadow={"none"}
            radius="none"
            border={0}
            classNames={{
              thead: "[&>tr:last-child]:hidden",
              wrapper: "p-0",
              table: "bg-[#070C84] rounded-t-xl border-0",
              td: "bg-white",
            }}
            topContent={topContent}
          >
            <TableHeader columns={columns} className="overflow-scroll">
              {(column) => (
                <TableColumn
                  className={`break-all font-bold text-white  text-sm md:text-md xl:text-xl bg-[#070C84] rounded-t-xl ${
                    column.name !== "Status" && "border-r border-r-gray-500"
                  } md:p-3 text-center`}
                  key={column.uid}
                >
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody items={items ?? []} className="break-all">
              {(item) => (
                <TableRow key={String(item?.listingId)} className="text-center">
                  {(columnKey) => (
                    <TableCell
                      className={` text-xs md:text-md xl:text-lg border-t-black/50 border-2 
                   
                   `}
                    >
                      {renderCell(item, columnKey)}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table> */}
          <div className="col-span-4  pb-20 overflow-y-auto mt-8">
            {filteredData && filteredData.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 5xl:grid-cols-6 gap-4">
                {filteredData?.map((car) => {
                  return (
                    <SearchCarCard
                      car={car}
                      imgURl={`${env.BASE_API_URL}Listings/image/${car.listingId}`}
                      line1={`${car?.year || ""} ${car.make} ${car.model}`}
                      line2={`$${car?.price ? money(+car?.price) : "0"}`}
                      line3={`VIN - ${car.vin}`}
                      btnLabel={"pendingSales"}
                      onClick={()=>handleDealerCase(car)}
                      onSelect={() => {
                        // isThisUserDealer
                        //   ? 
                          handleDealerCase(car)
                          // : hadleViewUser(car?.listingId?.toString());
                      }}
                      status={isThisDealer() ? "Offer Received" : "Offer Sent"}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center items-center mt-8">
                <Typography variant="subtitle" className="pb-16">
                  No {selectedType ? "Sold":"Pending"} Sales Found!
                </Typography>
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        placement="center"
        size="3xl"
        
        closeButton={<BlackCloseXIcon width={"32px"} height={"32px"} tabIndex={-2} />}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <OffScreenPopup onClose={onClose} vehicle={selectedCar} />
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
