import { SVGProps } from "react";

export const ErrorInfoIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      d="M6.68591 9.47222C6.8438 9.47222 6.97624 9.41873 7.08324 9.31173C7.19023 9.20474 7.24354 9.07248 7.24317 8.91496C7.24317 8.75707 7.18967 8.62481 7.08268 8.51819C6.97569 8.41157 6.84343 8.35807 6.68591 8.3577C6.52802 8.3577 6.39576 8.41119 6.28914 8.51819C6.18252 8.62518 6.12902 8.75744 6.12865 8.91496C6.12865 9.07285 6.18214 9.20529 6.28914 9.31229C6.39613 9.41928 6.52839 9.47259 6.68591 9.47222ZM6.12865 7.24317H7.24317V3.8996H6.12865V7.24317ZM6.68591 12.2585C5.91503 12.2585 5.19059 12.1122 4.51258 11.8194C3.83458 11.5267 3.24481 11.1297 2.74327 10.6285C2.24174 10.127 1.84478 9.53724 1.5524 8.85923C1.26003 8.18123 1.11365 7.45679 1.11328 6.68591C1.11328 5.91503 1.25966 5.19059 1.5524 4.51258C1.84515 3.83458 2.24211 3.24481 2.74327 2.74327C3.24481 2.24174 3.83458 1.84478 4.51258 1.5524C5.19059 1.26003 5.91503 1.11365 6.68591 1.11328C7.45679 1.11328 8.18123 1.25966 8.85923 1.5524C9.53724 1.84515 10.127 2.24211 10.6285 2.74327C11.1301 3.24481 11.5272 3.83458 11.82 4.51258C12.1127 5.19059 12.2589 5.91503 12.2585 6.68591C12.2585 7.45679 12.1122 8.18123 11.8194 8.85923C11.5267 9.53724 11.1297 10.127 10.6285 10.6285C10.127 11.1301 9.53724 11.5272 8.85923 11.82C8.18123 12.1127 7.45679 12.2589 6.68591 12.2585ZM6.68591 11.144C7.93046 11.144 8.98462 10.7121 9.84838 9.84838C10.7121 8.98462 11.144 7.93046 11.144 6.68591C11.144 5.44136 10.7121 4.3872 9.84838 3.52344C8.98462 2.65969 7.93046 2.22781 6.68591 2.22781C5.44136 2.22781 4.3872 2.65969 3.52344 3.52344C2.65969 4.3872 2.22781 5.44136 2.22781 6.68591C2.22781 7.93046 2.65969 8.98462 3.52344 9.84838C4.3872 10.7121 5.44136 11.144 6.68591 11.144Z"
      fill="#FF0A0A"
    />
  </svg>
);
