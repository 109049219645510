
import { useEffect } from 'react';
import { configureCometD } from './cometdConnection';
import { env } from 'config/env';

export const useCometdHandshake = () => {
    
  useEffect(()=>{
    
    if(env.ENABLE_COMETD === "true"){
        configureCometD(
          document.getElementById("root"), 
          'https:', //protocol to refer to
          env.SF_HOSTNAME, //hostname to refer to
          env.SF_SESSIONID
        );
    }
      
      
  },[])
};