import { SVGProps } from "react";

export const LinkedInIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    {...props}
  >
    <path
      d="M0.310547 5.22152C0.310547 2.33775 2.6483 0 5.53206 0H40.3422C43.226 0 45.5637 2.33775 45.5637 5.22152V40.0317C45.5637 42.9154 43.226 45.2532 40.3422 45.2532H5.53206C2.6483 45.2532 0.310547 42.9154 0.310547 40.0316V5.22152Z"
      fill="url(#paint0_linear_7732_8466)"
    />
    <path
      d="M16.3565 11.0604C16.3562 11.7307 16.0896 12.3734 15.6154 12.8471C15.1412 13.3208 14.4982 13.5868 13.8279 13.5865C13.1576 13.5861 12.5149 13.3195 12.0412 12.8453C11.5675 12.3711 11.3015 11.7281 11.3019 11.0579C11.3022 10.3876 11.5688 9.74485 12.043 9.27112C12.5172 8.79739 13.1602 8.53144 13.8305 8.53178C14.5007 8.53211 15.1434 8.79871 15.6172 9.27291C16.0909 9.74712 16.3569 10.3901 16.3565 11.0604ZM16.4323 15.4579H11.3777V31.2791H16.4323V15.4579ZM24.4187 15.4579H19.3893V31.2791H24.3682V22.9768C24.3682 18.3517 30.3959 17.9221 30.3959 22.9768V31.2791H35.3874V21.2582C35.3874 13.4613 26.4659 13.752 24.3682 17.5809L24.4187 15.4579Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7732_8466"
        x1="-6.65148"
        y1="-6.38186"
        x2="49.6249"
        y2="53.0854"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#018FFD" />
        <stop offset="1" stop-color="#070C84" />
      </linearGradient>
    </defs>
  </svg>
);
