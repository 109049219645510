import axios from "axios";
import { http } from "../../http/service.http";
import { env } from "config/env";
import {
  AddCardMethodPayload,
  CarShippignData,
  GetShippingDetailsResponse,
  PaymentCardInfo,
  SUB_KEY_TYPE_FOR_PAYMENT,
  ShippingByIDResponse,
  ShippingDetailsPayload,
} from "./shipping.type";
import { throwNotErrorSuccessResponse } from "config/retryConfig";
import { ListingZipCodeDetailsResponse } from "../listings/listings.type";
import { FormateShippingResponse, FormateShippingResponseNew, FormatedShippingResponseType } from "utils/shipping";

class ShippingEndpoint {
  public async GetShippingRates(
    data?: ShippingDetailsPayload & {
      vin: string;
      listingId: number;
      ship: ListingZipCodeDetailsResponse;
      dest: ListingZipCodeDetailsResponse;
      carYear: number,
      make: string;
      model: string;
    }
  ): Promise<FormatedShippingResponseType> {
    try{
      const payload = {
        "items": [
          {
            "item": {
              "commodity": "carsLightTrucks",
              "year":  Number(data?.carYear || "0"),
              "makeName": data?.make,
              "modelName": data?.model?.split("/")[0]
            },
            "originAddress": {
              "streetAddress": data?.shippingStreetAddress,
              "postalCode": data?.shippingZip,
              "country": "US"
            },
            "destinationAddress": {
              "streetAddress": data?.destinationStreetAddress,
              "postalCode": data?.destinationZip,
              "country": "US"
            },
            "timeFrame": {
              "earliestArrival": data?.estimatedShipDate,
              "latestArrival": data?.estimatedShipDate,
              "timeFrameType": "after"
            }
          }
        ],
        "integratorId": "findmyoptions"
      }
  
      const res = await http.post(`Shipping/GetShippingRates?listingId=${data?.listingId || 0}`, payload, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          
        },
        timeout: 60000
      });
      if(res.data.totalCount === 0) {
        throw new Error("No shipping quotes found!")
      }
      if(Array.isArray(res.data?.errors)){
        throwNotErrorSuccessResponse(res, res.data?.errors[0]?.reason|| res.data?.reason ||res.data?.message  || "Failed to get shipping quotes! please try again.")
      }else{
        throwNotErrorSuccessResponse(res,  res.data?.reason ||res.data?.message  || "Failed to get shipping quotes! please try again.")
      }
      return FormateShippingResponseNew(res.data);
  
      }catch(e){
        throw e
      }
  }

  public async GetShippingRatesPublic(
    data?: ShippingDetailsPayload & {
      vin: string;
      listingId: number;
      ship: ListingZipCodeDetailsResponse;
      dest: ListingZipCodeDetailsResponse;
      carYear: number;
      make: string;
      model: string;
    }
  ): Promise<FormatedShippingResponseType> {
    try{
    
    const payload = {
      "items": [
        {
          "item": {
            "commodity": "carsLightTrucks",
            "year":  Number(data?.carYear || "0"),
            "makeName": data?.make,
            "modelName": data?.model?.split("/")[0]
          },
          "originAddress": {
            "streetAddress": data?.shippingStreetAddress,
            "postalCode": data?.shippingZip,
            "country": "US"
          },
          "destinationAddress": {
            "streetAddress": data?.destinationStreetAddress,
            "postalCode": data?.destinationZip,
            "country": "US"
          },
          "timeFrame": {
            "earliestArrival": data?.estimatedShipDate,
            "latestArrival": data?.estimatedShipDate,
            "timeFrameType": "after"
          }
        }
      ],
      "integratorId": "findmyoptions"
    }

    // const payload = {
    //   items: [
    //     {
    //       commodity: "carsLightTrucks",
    //       year: Number(data?.carYear || "0"),
    //     },
    //   ],
    //   route: {
    //     items: [
    //       {
    //         address: {
    //           postalCode: data?.ship.zipCode,
    //           streetAddress: data?.shippingStreetAddress,
    //           majorMunicipality: data?.ship.city,
    //           country: "US",
    //           stateProvince: data?.ship.stateAbbr,
    //         },
    //         timeFrame: {
    //           earliestArrival: data?.estimatedShipDate,
    //           latestArrival: data?.estimatedShipDate,
    //           timeFrameType: "after",
    //         },
    //       },
    //       {
    //         address: {
    //           postalCode: data?.dest.zipCode,
    //           streetAddress: data?.destinationStreetAddress,
    //           majorMunicipality: data?.dest.city,
    //           stateProvince: data?.dest.stateAbbr,
    //           country: "US",
    //         },
    //       },
    //     ],
    //   },
    //   referenceNumbers: [
    //     {
    //       type: "VIN",
    //       number: data?.vin,
    //     },
    //   ],
    //   "integratorId": "findmyoptions"
    // };
    const res = await http.post(`Shipping/GetShippingRatesPublic`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      timeout: 60000
    });
    
    if(res.data.totalCount === 0) {
      throw new Error("No shipping quotes found!")
    }
    if(Array.isArray(res.data?.errors)){
      throwNotErrorSuccessResponse(res, res.data?.errors[0]?.reason|| res.data?.reason ||res.data?.message  || "Failed to get shipping quotes! please try again.")
    }else{
      throwNotErrorSuccessResponse(res,  res.data?.reason ||res.data?.message  || "Failed to get shipping quotes! please try again.")
    }
    return FormateShippingResponseNew(res.data);

    }catch(e){
      throw e
    }

  }
  public async AcceptPriceRateShipping(quoteId?: string): Promise<any> {
    try{
      const res = await http.post(`Shipping/AcceptRate?quoteId=${quoteId}`, {
        headers: {
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(res,res.data || "Failed to Accept shipping rate! please contant support.")
      return res.data;
      
    } catch(e){
      //rmv - temp - val
     console.log(e)
      // throw e;
    }
  }

  public async ConfirmShippingPayment(quoteId?: string): Promise<any> {
    try{
      const res = await http.post(
        `Transactions/ProcessShippingPayment?quoteId=${quoteId}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );
      throwNotErrorSuccessResponse(res,res.data || "Shipping payment failed! please try again")
      return res.data;
    } catch (e){
      //rmv - temp - val
      console.log(e)
      // throw e;
    }
  }

  public async getShippingStatus(
    quoteId?: string
  ): Promise<number | undefined> {
    try {
      const res = await http.post(
        `/Shipping/ShippingStatus?quoteId=${quoteId}`,
        {
          headers: {
            Accept: "*/*",
          },
        }
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
  public async saveShippingQuotes(quoteId?: string): Promise<any> {
    const res = await http.post(
      `Shipping/SaveShippingQuote?quoteId=${quoteId}`,
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async getShippingQuotesById(
    quoteId?: string
  ): Promise<ShippingByIDResponse> {
    const res = await http.post(
      `Shipping/GetShippingRecordByQuoteId?quoteId=${quoteId}`,
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async getShippingOrderHistory(
    page: number
  ): Promise<CarShippignData[]> {
    const res = await http.post(
      `Shipping/GetOrderHistory?page=${page}&pagesize=10`,
      {
        headers: {
          Accept: "*/*",
        },
      }
    );
    return res.data;
  }

  public async TransactionsCheckForPaymentMethod(): Promise<PaymentCardInfo> {
    try{
      const res = await http.post(`Transactions/CheckIfPaymentMethodExists`, {
        headers: {
          Accept: "*/*",
        },
      });
      throwNotErrorSuccessResponse(res,"Failed to fetch payment detail! Please try again.")
      return res.data;
   
    } catch(e) {
      throw e;
    }
  }

  public async AddPaymenTransactiontMethod(
    payload: AddCardMethodPayload
  ): Promise<any> {
    const res = await http
      .post(
        `Transactions/AddPaymentMethod`,
        {
          number: payload.cardNumber,
          name: `${payload.fname} ${payload.lname}`,
          cvc: payload.cvv,
          expYear: "20" + payload.expiryDate.split("/")[1],
          expMonth: payload.expiryDate.split("/")[0],
          streetAddress: payload.address,
          city: payload.city,
          region: payload.state,
          postalCode: payload.zipcode,
          email: payload.email,
          country: "US",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      )
      .catch((e) => {
        throw e;
      });
    if (res.status !== 200) {
      throw new Error(res?.data || "payment failed! please try again");
    }
    return res.data;
  }

  public async AddPaymentOptionForPublicUserMethod(
    payload: AddCardMethodPayload,
    vin: string,
    sub_key: SUB_KEY_TYPE_FOR_PAYMENT
  ): Promise<any> {
    const res = await http
      .post(
        `Transactions/AddPaymentOptionForPublicUserMethod?subscriptionKey=${sub_key}&VIN=${vin}`,
        {
          number: payload.cardNumber,
          name: `${payload.fname} ${payload.lname}`,
          cvc: payload.cvv,
          expYear: "20" + payload.expiryDate.split("/")[1],
          expMonth: payload.expiryDate.split("/")[0],
          streetAddress: payload.address,
          city: payload.city,
          region: payload.state,
          postalCode: payload.zipcode,
          email: payload.email,
          country: "US",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      )
      .catch((e) => {
        throw e;
      });

    if (res.status !== 200) {
      throw new Error(res?.data || "Payment failed! Please try again");
    }

    return res.data;
  }

  public async AddPaymenTransactiontMethodRegistration(
    payload: AddCardMethodPayload
  ): Promise<any> {
    try{
      const tierToken = String(localStorage.getItem("tier_token"));

      const res = await axios.post(
        `${env.BASE_API_URL}Transactions/AddPaymentMethod`,
        {
          number: payload.cardNumber,
          name: `${payload.fname} ${payload.lname}`,
          cvc: payload.cvv,
          expYear: "20" + payload.expiryDate.split("/")[1],
          expMonth: payload.expiryDate.split("/")[0],
          streetAddress: payload.address,
          city: payload.city,
          region: payload.state,
          postalCode: payload.zipcode,
          country: "US",
          email: payload.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${JSON.parse(tierToken)}`,
          },
        }
      );
      throwNotErrorSuccessResponse(res, res.data || "Failed to Add card! please try again later.")
      return res.data;
    } catch(e){
      throw e;
    }
    
  }
}

export const shippingEndpoint = new ShippingEndpoint();
