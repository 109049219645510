import React, { useEffect, useState } from "react";
import { Card, CardBody, Chip, Image } from "@nextui-org/react";

import { Button, Typography } from "components";
import { env } from "config/env";
import { ViewIcon } from "../car-card/ViewIcon";
import { EditIcon } from "../car-card/EidtIcon";
import { useGetSFOdometerFile } from "modules/user/hooks/api/mutations/benefits/repair-agreement";
import { localStorageFn } from "utils/localstorage";
import PDFThumbnail from "pages/user/Benefits/car-warrently/components/pdf-thumbnail";

interface Props {
  car: any;
  imgURl: string;
  onSelect: (data: any) => void;
  onSelect2?: (data: any) => void;
  onClick?: (data: any) => void;
  btnLabel?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  status?: string;
}
export default function WarrentyCard({
  car,
  onSelect,
  onSelect2,
  onClick,
  btnLabel = "Select",
  line1,
  line2,
  line3,
  line4,
  status,
}: Props) {
  const { data: imgDataUrl } = useGetSFOdometerFile(car.vin);
  // const [fileUrl, setFileUrl] = useState<any>("");
  // useEffect(() => {
  //   const authToken = localStorageFn.getItem("access_token");
  //   fetch(`${env.BASE_API_URL}Listings/GetSFOdometerFile?vin=${car.vin}`, {
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   })
  //     .then((response) => {
  //       // console.log("type ------->",response.headers.get('content-type'))
  //       return response.blob()})
  //     .then((blob) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(blob);
  //       return new Promise((res) => {
  //         reader.onloadend = () => {
  //           res(reader.result);
  //         };
  //       });
  //     })
  //     .then((url) => {
  //       setFileUrl(url);
  //     });
  // }, []);

  return (
    <Card
      className="border border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors rounded-2xl"
      shadow="none"
      fullWidth
    >
      <CardBody className="flex flex-col">
        <div className="bg-white rounded-3xl h-full w-full">
          <div className="relative col-span-2">
            {isBase64PDF(imgDataUrl || "") ? (
              <PDFThumbnail fileUrl={imgDataUrl} />
            ) : (
              <Image
                removeWrapper
                // src={fileUrl}
                src={imgDataUrl && getDataUrlFromBase64(imgDataUrl)}
                // onError={handleImageError}
                alt="bs41"
                onClick={() => onClick && onClick(car)}
                className="w-full h-56 xl:h-40 2xl:h-48 object-cover cursor-pointer"
              />
            )}
            {status && (
              <Chip className="absolute top-0 right-0 z-10 text-xs m-2 bg-white border border-blue-500  font-[Metropolis]">
                {status}
              </Chip>
            )}
          </div>
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="pt-4 text-xl sm:text-sm lg:text-xl xl:text-base text-center font-[800] text-[#263238] min-h- overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {line1}
            </Typography>
            <Typography
              variant="custom"
              className="font-bold text-center text-xl  text-[#263238] h-8  sm:text-sm lg:text-xl xl:text-base "
            >
              {line2}
            </Typography>

            <Typography
              variant="custom"
              className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm"
            >
              {line3}
            </Typography>
            {line4 && (
              <Typography
                variant="custom"
                className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm"
              >
                {line4}
              </Typography>
            )}

            {/* <div className="grid grid-cols-3 justify-items-center mt-2 font-[800]">
              <Typography>
                {money(Number(car.listingDetail.basicDetail.mileage)) || 0} mi
              </Typography>
              <Typography className="text-center">
                Days Listed:
                <br /> 0
              </Typography>
              <Typography className="text-center">
                Viewed?
                <br /> Y
              </Typography>
            </div> */}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center mt-auto gap-4 ">
          {/* <Button
            customVariant="gradient"
            className="w-full w-max-[12rem] overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            <Typography
              variant="label"
              className="text-lg md:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-[900] overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {car.sellerDetail?.city || car.sellerDetail?.state
                ? car.sellerDetail?.city +
                  ", " +
                  car.sellerDetail?.state?.toLocaleUpperCase()
                : "N/A"}
            </Typography>
          </Button> */}
          {btnLabel === "pendingSales" ? (
            <ViewIcon
              onClick={() => onSelect(car)}
              className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
            />
          ) : btnLabel === "soldAndArchived" ? (
            <div className="relative flex items-center justify-center">
              <ViewIcon
                onClick={() => onSelect(car)}
                className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
              />
              <div className="pl-6">
                <EditIcon
                  onClick={() => onSelect2 && onSelect2(car)}
                  className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
                />
              </div>
            </div>
          ) : (
            <Button
              customVariant="gradient"
              className=" w-auto h-auto sm:py-1 rounded-xl sm:rounded-lg lg:rounded-xl"
              onClick={() => onSelect(car)}
              // isLoading={saveUserListingPending}
            >
              <Typography
                variant="custom"
                className="text-sm sm:text-xs lg:text-base xl:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-bold"
              >
                {btnLabel}
              </Typography>
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

function isBase64PDF(base64String: string) {
  // Decode the Base64 string
  if (base64String.length < 1) {
    return false;
  }
  const decodedData = atob(base64String);

  // Check if the decoded data starts with "%PDF", which is a characteristic of PDF files
  return decodedData.startsWith("%PDF");
}

function getDataUrlFromBase64(base64String: string) {
  // Determine the MIME type based on the image format
  const imageFormat = getImageFormatFromBase64(base64String);
  let mimeType;
  switch (imageFormat) {
    case "PNG":
      mimeType = "image/png";
      break;
    case "GIF":
      mimeType = "image/gif";
      break;
    case "BMP":
      mimeType = "image/bmp";
      break;
    // default:
    default:
      mimeType = "image/jpeg";
      break;
    //     throw new Error("Unknown image format");
  }

  // Combine the MIME type and Base64 string to generate the data URL
  const dataUrl = `data:${mimeType};base64,${base64String}`;
  return dataUrl;
}

function getImageFormatFromBase64(base64String: string) {
  // Decode the Base64 string
  const decodedData = atob(base64String);

  // Convert the decoded data into a Uint8Array
  const byteArray = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; i++) {
    byteArray[i] = decodedData.charCodeAt(i);
  }

  // Check the header bytes to identify the image format
  const headerBytes = byteArray.subarray(0, 4); // Take the first 4 bytes
  if (arrayEquals(headerBytes, [0xff, 0xd8, 0xff, 0xe0])) {
    return "JPEG";
  } else if (arrayEquals(headerBytes, [0x89, 0x50, 0x4e, 0x47])) {
    return "PNG";
  } else if (arrayEquals(headerBytes, [0x47, 0x49, 0x46, 0x38])) {
    return "GIF";
  } else if (arrayEquals(headerBytes, [0x42, 0x4d])) {
    return "BMP";
  } else {
    return "Unknown";
  }
}

function arrayEquals(array1: any, array2: any) {
  return (
    array1.length === array2.length &&
    array1.every((value: any, index: any) => value === array2[index])
  );
}
