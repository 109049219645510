import { Typography } from "components";
import React, { useEffect, useState } from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";
import {
  Button,
  Image,
  Modal,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";
import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import { PayModal } from "modules/shared/components/modal/PayModal";
import paymentBg from "../../../dealer/offer-accepted/assets/payment-bg.png";
import { useNavigate } from "react-router-dom";
import {
  useGetFMOMembershipStatus,
  useProcessFMOMembershipPayment,
  useSubmitFMOMembershipPayment,
} from "modules/user/hooks/api/mutations/benefits/use-fmo-membership";
import {
  useAddPaymenTransactiontMethod,
  useGetCreditCardMethodStatusData,
  useTransactionsCheckForPaymentMethod,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { AddCardMethodPayload } from "services/endpoints/shipping-quotes/shipping.type";
import Loading from "components/Loading/Loading";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import FmoMembershipForm from "./fmoMembershipForm";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";
interface Props {
  isPrivateView?: boolean;
}

export default function FmoMembershipPage({ isPrivateView = false }: Props) {

  const [showModal, setShowModal] = useState<null | "thanks" | "form">(null);

  const navigate = useNavigate();
  const { data: fmoStatus, isPending: fmoStatusPending } =
    useGetFMOMembershipStatus(isPrivateView);
  // const {
  //   data: test,
  //   mutateAsync: ProcessFMOMembershipPaymentAsync,
  //   isPending: isPendingToProcessFmoMemberShip,
  //   error: processMemError,
  // } = useProcessFMOMembershipPayment();
  // const {
  //   data,
  //   mutateAsync: SubmitFMOMembershipPaymentAsync,
  //   isPending: isPendingToSubmitFmoMemberShip,
  //   error: sumbitFmoMemberShipError,
  // } = useSubmitFMOMembershipPayment();
  // const {
  //   mutateAsync: mutateAsyncAccceptPayment,
  //   data: checkIfPaymentExist,
  //   isPending: isPendingToAccceptPayment,
  // } = useTransactionsCheckForPaymentMethod();

  // const {
  //   data: checkIfPaymentExist,
  //   refetch: mutateAsyncAccceptPayment,
  //   isLoading: isPendingToAccceptPayment,
  // } = useGetCreditCardMethodStatusData(true);
  // const {
  //   mutateAsync: mutateAddPaymenTransactiontMethod,
  //   isPending: isPendingToAddPaymenTransactiontMethod,
  //   error: addCardErrorMessage,
  // } = useAddPaymenTransactiontMethod();

  useEffect(() => {
    if (fmoStatus?.status === 1 && isPrivateView) {
      navigate("accept");
    }
  }, [fmoStatus]);

  const handleBecomeMember = async () => {
    // await SubmitFMOMembershipPaymentAsync();
    try {
      // await mutateAsyncAccceptPayment();
      setShowModal("form");
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleConfirmPayment = async (data?: AddCardMethodPayload) => {
    try {
      // if (checkIfPaymentExist) {
      //   await SubmitFMOMembershipPaymentAsync();
      //   await ProcessFMOMembershipPaymentAsync();
      // } else {
      //   const paymentData = await mutateAddPaymenTransactiontMethod(data);
      //   await SubmitFMOMembershipPaymentAsync();
      //   await ProcessFMOMembershipPaymentAsync();
      //   mutateAsyncAccceptPayment()
      //   console.log("paymentData", paymentData);
      // }
      
      // navigate("accept");
      setShowModal("thanks");
    } catch (e) {
      console.log("Error :", e);
    }
  };
  if (fmoStatusPending && isPrivateView) return <Loading />;
  return (
    <div className="flex items-center justify-center">
      <div className="mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r   from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            FMO Membership
          </p>
          <p className="bg-gradient-to-r   from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-2xl font-bold pt-5">
            Never Be Stranded: Access Expert Vehicle Support with
            findmyoptions.com
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-center">
          <p className="text-xl">
            Don't let car troubles leave you feeling frustrated and confused. At
            findmyoptions.com, we offer a{" "}
            <span className="font-semibold">trusted and affordable</span>{" "}
            solution to keep your vehicle running smoothly:{" "}
            <span className="font-semibold">
              expert online service and repair advice,
            </span>{" "}
            available <span className="font-semibold">24/7</span> for {" "}
            <span className="font-semibold">free.</span>
          </p>
        </Typography>
        <div className="flex justify-center items-center">
          {isPrivateView && (
            <Button
              className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg mx-auto  py-8 tracking-wide px-6 rounded-3xl mt-5"
              onClick={handleBecomeMember}
            >
              Become a Member
            </Button>
          )}{" "}
        </div>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            What You Get with Our Membership:
          </p>

          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Unlimited Access to Service Advisors:{" "}
              </span>
              <span className="font-semibold">
                Connect with a network of vetted and experienced mechanics and
                automotive professionals through live chat or support tickets.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                24/7 Support:{" "}
              </span>
              Get expert assistance{" "}
              <span className="font-semibold">anytime, anywhere,</span> ensuring
              your questions are answered promptly.{" "}
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Detailed & Personalized Advice:{" "}
              </span>
              Receive{" "}
              <span className="font-semibold">
                clear, step-by-step guidance
              </span>{" "}
              tailored to your specific vehicle and situation.{" "}
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Avoid Costly Mistakes:{" "}
              </span>
              Get <span className="font-semibold">informed advice</span> before
              making any repair decisions, saving you time and money.{" "}
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Peace of Mind:{" "}
              </span>
              Gain the <span className="font-semibold">confidence</span> to
              tackle minor maintenance tasks or troubleshoot car issues
              effectively.{" "}
            </p>
          </div>

          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Multiple Coverage Options:{" "}
              </span>
              We offer a variety of plans with different coverage levels and
              durations to suit your budget and vehicle's needs.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Peace of Mind:{" "}
              </span>
              Knowing you're covered against unexpected repairs can give you
              peace of mind and help you avoid costly breakdowns.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            How It Works:
          </p>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Become a Member:{" "}
              </span>
              Sign up for <span className="font-semibold">free.</span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Connect with an Advisor:{" "}
              </span>
              Choose between live chat for a real-time conversation or submit a
              detailed <span className="font-semibold">support ticket</span>{" "}
              outlining your problem.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Get Expert Advice:{" "}
              </span>
              Receive{" "}
              <span className="font-semibold">
                professional guidance and recommendations
              </span>{" "}
              from our team of certified advisors
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Fix Your Car with Confidence:{" "}
              </span>
              Use the expert advice to diagnose the problem, perform basic
              repairs, or make informed decisions about professional repair
              services.
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Benefits of Our Membership:
          </p>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Cost-Effective:{" "}
              </span>
              Affordable monthly membership compared to individual consultations
              or dealership repairs.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Convenience:{" "}
              </span>
              Get immediate access to expert advice from the comfort of your
              home, anytime of day.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Trustworthy Advice:{" "}
              </span>
              Our advisors are highly qualified and experienced, ensuring you
              receive reliable and accurate information.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Empowerment:{" "}
              </span>
              Gain knowledge and confidence to handle minor maintenance tasks
              and troubleshoot basic car issues.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img
              src={checkIcon}
              alt="checks"
              height={16}
              width={16}
              className="mt-[16px]"
            />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Avoid Unnecessary Repairs:{" "}
              </span>
              Get professional guidance to avoid costly repairs or unnecessary
              replacements.{" "}
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-16 mb-10 text-center">
          <p className="text-2xl font-bold text-[#070C84]">
            Join the findmyoptions.com Community Today!
          </p>
          <p className="text-lg mt-2">
            Create an account and select the FMO membership and get started
            today!
          </p>
        </Typography>
        {!isPrivateView && (
          <div className="text-center">
            <Button
              className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg  py-8 tracking-wide px-6 rounded-xl mt-5"
              type="submit"
              onClick={() => {
                localStorage.clear();
                navigate("/register/user");
              }}
            >
              Create Account
            </Button>
          </div>
        )}
      </div>
      

      <CustomizableHeaderModel
        isOpen={showModal !== null}
        size="2xl"
        onOpen={() => setShowModal(null)}
        Component={
          showModal === "thanks" ?  <>
          <div className="py-10">

            <ThankYou
            textNote="Successful"
            buttonText="Continue"
            
            onClick={() => {
              window.scrollTo(0, 0);
                // setActiveSteps(steps[activeSteps.step]);
                navigate("accept");
              }}
              />
              </div>
            
        </>:
          <div>
            <div className="w-full flex justify-center">
              <Image
                src="assets/icons/new-logo.svg"
                className="w-28 h-28 -mb-2 m-0 md:m-0"
                removeWrapper
                radius="none"
                shadow="none"
              />
            </div>
            <FmoMembershipForm
              // payAmmount=""
              // showAmmount={false}
              // lableBtn="Continue"
              // showmodal={true}
              // savedMethod={checkIfPaymentExist}
              // isPending={
              //   isPendingToAccceptPayment ||
              //   isPendingToAddPaymenTransactiontMethod ||
              //   isPendingToProcessFmoMemberShip ||
              //   isPendingToSubmitFmoMemberShip
              // }
              // errorMessage={addCardErrorMessage?.message || processMemError?.message || sumbitFmoMemberShipError?.message}
              handleNext={() => {
                handleConfirmPayment();
              }}
            />
          </div>


        }
      />
    </div>
  );
}
