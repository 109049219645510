import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import { listingsEndpoint } from "./endpoints/listings/listings.endpoint";
import {
  ListingDetailsPriceAndAdvanceDataResponse,
  ListingDetailsResponse,
  ListingDataRequest,
  HistoryDataResponse,
  PriceDataResponse,
  ListingDescriptionResponse,
  CarPartResponse,
  ListingAdvanceData,
  ListingListResponse,
  ListingDescriptionRequest,
  ListingSellerDetailsResponse,
  ListingZipCodeDetailsResponse,
  ListingVehicleResponse,
  ListingVehicleRequest,
  VehicleCondition,
  ListingDetails,
  SFInstantQuoteLead,
  SaveUserListing,
  ISubmitResearchVehicleFormPayload,
  IGetDashboardData,
} from "./endpoints/listings/listings.type";
import { traverse } from "utils/transform-data";
import { shouldRetryOnError } from "config/retryConfig";

const api = listingsEndpoint;
export const useGetListingDetails = (id: number) => {
  return useQuery<ListingDetailsResponse, Error>({
    queryKey: [id],
    queryFn: async () => {
      const res = await api.getListingDetailsAsync(id);
      return res;
    },
    enabled:!!id
  });
};

export const useGetMyGarage = (userId: number) => {
  return useQuery<ListingDetails[], Error>({
    queryKey: [userId],
    queryFn: async () => {
      const res = await api.getMyGarageAsync(userId);
      return res;
    },
  });
};

export const usePurchasedReports = (
  userId: number,
  subscriptionKey: string
) => {
  return useQuery<any[], Error>({
    queryKey: [subscriptionKey],
    queryFn: async () => {
      const res = await api.getPurchasedReportsAsync(userId, subscriptionKey);
      return res;
    },
  });
};

export const useGetListingImage = (fileName: string) => {
  return useQuery<string, Error>({
    queryKey: ["listing-details-image"],
    queryFn: async () => {
      const res = await api.getListingDetailsImageAsync(fileName);
      return res;
    },
  });
};

export const useGetListingDetailsPriceAndAdvanceData = (vinNumber: string) => {
  return useQuery<ListingDetailsPriceAndAdvanceDataResponse, Error>({
    queryKey: ["listing-details-price-advance"],
    queryFn: async () => {
      const res = await api.getListingDetailsPriceAndAdvanceDataAsync(
        vinNumber
      );
      return res;
    },
    enabled: !!vinNumber,
  });
};

export const useInitiateListingVIN = (
  vinNumber: string,
  userId?: number,
  mileage?: string
) => {
  return useQuery<any, Error>({
    queryKey: ["initiate-listing-VIN"],
    queryFn: async () => {
      const res = await api.initiateListingAsync(vinNumber, userId, mileage);
      return res;
    },
    enabled: !!vinNumber,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    
  });
};

export const useInitiateListingVINMut = () => {
  return useMutation<number, Error, {  vinNumber: string,
    userId?: number,
    mileage?: string}>({
   
    mutationFn: async ({vinNumber,userId, mileage} ) => {
      const res = await api.initiateListingAsync(vinNumber, userId, mileage);
      return res;
    },
    });
};


export const useUpdateListingData = (hasPositionalImages=false,replaceImages=false) => {
  return useMutation<ListingDataRequest, Error, any>({
    mutationFn: async (payload) => {
      const res = await api.updateListingDataAsync(payload,hasPositionalImages,replaceImages);
      return res;
    },
  });
};

export const useUpdateListingDataMut = () => {
  return useMutation<ListingDataRequest, Error, {payload:any,hasPositionalImages:boolean,replaceImages:boolean}>({
    mutationFn: async ({payload,hasPositionalImages=false,replaceImages=false}) => {
      const res = await api.updateListingDataAsync(payload,hasPositionalImages,replaceImages);
      return res;
    },
  });
};

export const useUpdateListingStatus = (lid:number,status:number) => {
  return useMutation<ListingDataRequest, Error>({
    mutationFn: async () => {
      const res = await api.UpdateListingStatusAsync(lid,status);
      return res;
    },
  });
};

export const useUpdateListingStatusMulti = (lid:number) => {
  return useMutation<ListingDataRequest, Error, number>({
    mutationFn: async (status:number) => {
      const res = await api.UpdateListingStatusAsync(lid,status);
      return res;
    },
  });
};
export const useSaveListingData = () => {
  return useMutation<ListingDataRequest, Error, ListingDataRequest>({
    mutationFn: async (payload) => {
      const res = await api.saveListingDataAsync(payload);
      return res;
    },
  });
};

export const useCreateVehicleCondition = () => {
  return useMutation<VehicleCondition, Error, VehicleCondition>({
    mutationFn: async (payload) => {
      const res = await api.createVehicleCondition(payload);
      return res;
    },
  });
};

export const useSFInstantQuoteLead = () => {
  return useMutation<SFInstantQuoteLead, Error, SFInstantQuoteLead>({
    mutationFn: async (payload) => {
      const res = await api.createSFInstantQuoteLeadAsync(payload);
      return res;
    },
  });
};

export const useGetPriceAdvanceDataPdf = (
  vinNumber: string,
  enableApi = true
) => {
  return useQuery<string, Error>({
    queryKey: ["listing-details-price-advance-pdf", vinNumber],
    queryFn: async () => {
      const res = await api.getPriceAdvanceDataPdfAsync(vinNumber);
      return res;
    },
    enabled: enableApi && !!vinNumber,
    retry: 0,
    refetchOnMount: false,
  });
};

export const useGetVehicleHistoryData = (
  vinNumber: string,
  enableApi = true
) => {
  return useQuery<HistoryDataResponse, any>({
    queryKey: ["listing-details-history",vinNumber],
    queryFn: async () => {
      const res = await api.getVehicleHistoryDataAsync(vinNumber);
      return traverse(res.data);
    },
    enabled: enableApi && !!vinNumber,
    refetchOnMount: false,
    retry: 0,
  });
};

export const useGetListingPriceData = (
  period: number,
  vin: string,
  mileage: string
) => {
  return useQuery<PriceDataResponse, Error>({
    queryKey: ["listing-details-price"],
    queryFn: async () => {
      const res = await api.getPriceDataAsync(period, vin, mileage);
      return res;
    },
    enabled: !!vin,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

export const useGetListingAdvanceData = (vin: string) => {
  return useQuery<ListingAdvanceData, Error>({
    queryKey: ["listing-advance-data"],
    queryFn: async () => {
      const res = await api.getAdvanceDataAsync(vin);
      return res;
    },
    enabled: !!vin,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    retry: 0,
  });
};

export const useGetListingDescription = () => {
  return useMutation<
    ListingDescriptionResponse,
    Error,
    ListingDescriptionRequest
  >({
    mutationFn: async (payload) => {
      const res = await api.getListingDescriptionAsync(payload);
      return res;
    },
  });
};

export const useGetVehicleDescription = () => {
  return useMutation<
    ListingDescriptionResponse,
    Error,
    ListingDescriptionRequest
  >({
    mutationFn: async (payload) => {
      const res = await api.getVehicleDescriptionAsync(payload);
      return res;
    },
  });
};

export const useUploadFiles = () => {
  return useMutation<number, Error, FormData>({
    mutationFn: async (payload) => {
      const res = await api.uploadFilesAsync(payload);
      return res;
    },
  });
};

export const useUploadCoverFiles = (listId:string) => {
  return useMutation<number, Error, string>({
    mutationFn: async (fileName) => {
      const res = await api.uploadCoverFiles(listId,fileName);
      return res;
    },
  });
};

export const useGetUserSavedListing = (enabled= false) => {
  return useQuery<any[], Error>({
    queryKey: ["user-saved-listing"],
    queryFn: async () => {
      return await api.getUserSavedListingsAsync();
    },
    enabled:enabled,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: false,
    retry:shouldRetryOnError
  });
};

export const useSaveUserListing = () => {
  return useMutation<SaveUserListing, Error, SaveUserListing>({
    mutationFn: async (payload) => {
      const res = await api.saveUserListingAsync(payload);
      return res;
    },
  });
};

export const useRemoveUserListing = () => {
  return useMutation<SaveUserListing, Error, SaveUserListing>({
    mutationFn: async (payload) => {
      const res = await api.removeUserListingAsync(payload);
      return res;
    },
  });
};

export const useGetCarPartUploaded = () => {
  return useMutation<CarPartResponse[], Error, string>({
    mutationFn: async (payload) => {
      const res = await api.getCarPartUploadedAsync(payload);
      return res;
    },
    onError: (error) => {
      console.log("error", error);
      return;
    },
    throwOnError: true,
  });
};
export const useGetListingSearch = (search: string) => {
  return useQuery<any[], Error>({
    queryKey: ["listing-search"],
    queryFn: async () => {
      const res = await api.getListingSearchAsync(search);
      const sortedData = res.sort((a: any, b: any) => {
        return a.listingId - b.listingId;
      });
      return sortedData;
    },
    enabled: !!search,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

export const useGetListingArchiveAndSold = (
  tenantId: number,
  pageSize?: number,
  page?: number,
  status?: number
) => {
  return useQuery<any[], Error>({
    queryKey: ["listing-archive-and-sold", page],
    queryFn: async () => {
      const res = await api.getListingArchiveAndSoldSync(
        tenantId,
        pageSize,
        page,
        status
      );

      return res.sort((a: any, b: any) => {
        return b.listingId - a.listingId;
      });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

export const useGetListingArchiveAndSoldSearch = (
  search: string,
  status: number
) => {
  return useQuery<any[], Error>({
    queryKey: ["listing-archive-and-sold-search"],
    queryFn: async () => {
      const res = await api.getListingArchiveAndSoldSearchAsync(search, status);
      return res.sort((a: any, b: any) => {
        return b.listingId - a.listingId;
      });
    },
    enabled: !!search,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

export const useGetDashboardData = (
  month: string
) => {
  return useQuery<IGetDashboardData, Error>({
    queryKey: ["dashboard-dealer-data",month],
    queryFn: async () => {
      const res = await api.GetDashboardData(month);
      return res
    },
    enabled: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: false,
  });
};

export const useGetListingList = (
  tenantId: number,
  status: number,
  page: number = 1,
  pageSize: number = 10
) => {
  return useQuery<ListingListResponse[], Error>({
    queryKey: ["listing-list"],
    queryFn: async () => {
      const res = await api.getListingListAsync(
        tenantId,
        status,
        page,
        pageSize
      );
      return res;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: false,
  });
};

export const useGetSellerDetailsByListingId = (listingId: string) => {
  return useMutation<ListingSellerDetailsResponse, Error>({
    mutationFn: async () => {
      const res = await api.getSellerDetailsByListingIdAsync(Number(listingId));
      return res;
    },
  });
};

export const useGetZipCodeDetails = (zipcode: string) => {
  return useMutation<ListingZipCodeDetailsResponse, Error>({
    mutationFn: async () => {
      const res = await api.getZipCodeDetailsAsync(Number(zipcode));
      return res;
    },
  });
};

export const useGetZipCodeDetailsForMul = () => {
  return useMutation<ListingZipCodeDetailsResponse, Error, string>({
    mutationFn: async (zipcode: string) => {
      const res = await api.getZipCodeDetailsAsync(Number(zipcode));
      return res;
    },
  });
};

export const usePostSubmitResearchVehicleForm = () => {
  return useMutation<string, Error, ISubmitResearchVehicleFormPayload>({
    mutationFn: async (payload) => {
      const res = await api.postSubmitResearchVehicleForm(payload);
      return res;
    },
  });
};
export const usePutDeleteResearchListing = () => {
  return useMutation<string, Error, number>({
    mutationFn: async (rid) => {
      const res = await api.putDeleteResearchListing(rid);
      return res;
    },
  });
};


export const useGetResearchVehicleForms = () => {
  return useQuery<ISubmitResearchVehicleFormPayload[], Error>({
    queryKey: [],
    queryFn: async () => {
      const res = await api.getResearchVehicleForms();
      // const res = await api.searchListingListAsync(query);
      return res;
    },
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: false,
    retry: 0,
  });
};

export const useSearchListingList = (query: string) => {
  return useQuery<ListingListResponse[], Error>({
    queryKey: ["listing-list-search", { query: query }],
    queryFn: async () => {
      const res = await api.searchListingListAsync(query);
      return res;
    },
    enabled: !!query,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    retry: 0,
  });
};

export const useGetListingsVehicles = (
  searchCriteria: ListingVehicleRequest[],
  updateState: (newData: ListingVehicleResponse[]) => void,
  pageParam: number = 1,
  pageSize: number = 10
) => {
  return useQuery<ListingVehicleResponse[], Error>({
    queryKey: ["cars-search", { page: pageParam }],
    // queryKey:[],
    queryFn: async () => {
      const res = await api.getListingsVehiclesAsync(
        searchCriteria,
        Number(pageParam),
        pageSize
      );
      updateState(res);
      return res;
    },
    enabled: !!pageParam,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    retry: 0,
  });
};

export const useGetListingOfTheDay = () => {
  return useQuery<any, Error>({
    queryKey: ["GetListingOfTheDay"],
    queryFn: async () => {
      const res = await api.getListingOfTheDay();
      return res;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
  });
};

// export const useGetListingsVehicles = (
//   searchCriteria: string,
//   pageSize: number = 5
// ) => {
//   return useInfiniteQuery<ListingVehicleResponse, Error>({
//     queryKey: ["listing-vehicles", { searchCriteria }],
//     initialPageParam: 1,
//     queryFn: async ({ pageParam = 1 }) => {
//       const res = await api.getListingsVehiclesAsync(
//         searchCriteria,
//         Number(pageParam),
//         pageSize
//       );
//       return res;
//     },
//     getNextPageParam: (lastPage, allPages) => {
//       console.log("lastPage", [lastPage].length);
//       console.log("allPages", allPages);
//       if ([lastPage].length === 5) {
//         return allPages.length + 1;
//       } else {
//         return false;
//       }
//     },
//     refetchOnWindowFocus: false,
//     refetchOnMount: false,
//     refetchInterval: false,
//     retry: 0,
//   });
// };
