import { Toggle, Typography } from "components";
import {
  dmvDocumentServiceSignal,
  enterMissingInfoDefaultSignal,
} from "../store";
import Input from "components/ui/input";

interface BuyerTwoDetailsProps {
  register: any;
  errors: any;
  buyerTwo: boolean;
  setBuyerTwo: (value: boolean) => void;
}
export const BuyerTwoDetails: React.FC<BuyerTwoDetailsProps> = ({
  register,
  errors,
  buyerTwo,
  setBuyerTwo,
}) => {
  return (
    <>
      <FormControl>
        <Typography
          variant="custom"
          className="col-span-2 mt-10 text-[#313131] font-[600] text-md"
        >
          Second Buyer?
        </Typography>
        <Toggle
          size="md"
          checked={buyerTwo}
          color="default"
          className="mt-10"
          onChange={() => setBuyerTwo(!buyerTwo)}
        />
      </FormControl>
      {buyerTwo && (
        <Typography
          variant="custom"
          className="font-[800] text-[20px] text-[#313131] mt-4 mb-10"
        >
          Buyer 2 Details:
        </Typography>
      )}

      {buyerTwo &&
        enterMissingInfoDefaultSignal.value.buyerTwoDetails.map(
          (field, idx) => {
            return (
              <FormControl key={`${field}-${idx}`}>
                <Typography
                  variant="custom"
                  className="text-[#263238] font-[600] text-left col-span-2"
                >
                  {field.label}
                </Typography>
                <Input
                  variant="form"
                  size="lg"
                  className="col-span-3"
                  classNames={{
                    input: "px-4 text-center",
                    inputWrapper: `${
                      field.isDisabled && !buyerTwo ? "border-gray-500" : ""
                    }`,
                  }}
                  defaultValue={
                    (dmvDocumentServiceSignal.value as any)?.[field.name] ?? ""
                  }
                  isDisabled={field.isDisabled && !buyerTwo}
                  errorMessage={
                    (errors.errors.buyerTwoDetails as any)?.[field.name]
                      ?.message
                  }
                  {...register(`buyerTwoDetails.${field.name}` as any)}
                />
              </FormControl>
            );
          }
        )}
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
