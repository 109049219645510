import { Typography } from "components";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import { Button } from "@nextui-org/react";
import { useState } from "react";

interface Props {}

export default function LoanPayments({}: Props) {
  // const params = useParams();
  // const navigate = useNavigate();
  // const { bnphOffer } = useSelector((state: RootState) => state.sellerOffer);

  const [openModal, setOpenModal] = useState<string | null>(null);

  return (
    <>
      <div className="md:flex ">
        <div className="md:ml-5 pb-4  w-full flex flex-col items-center justify-center relative ">
          <div className="w-full flex justify-center flex-col items-center">
            <Typography
              variant="custom"
              className={`text-center  my-4 md:mb-16  font-bold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
            >
              Loan Payments
            </Typography>
            <div className={`${"md:pl-8"} w-full`}>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 w-full">
                {[
                  { label: "VIN" },
                  { label: "Year" },
                  { label: "Made" },
                  { label: "Model" },
                ].map(({ label }, ind) => {
                  return (
                    <div key={ind} className="mb-2">
                      <label className="text-[#263238] text-sm">{label}</label>
                      <input
                        className={`bg-transparent  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left outline-none`}
                      />
                    </div>
                  );
                })}
              </div>

              <div className=" grid grid-cols-1 mb-6 my-6 sm:my-10 gap-0 md:gap-2 md:grid-cols-3 justify-items-left h-[80] md:h-[40] formControl">
                <div className="text-left text-[14px] text-xl font-bold h-full flex items-end md:items-center">
                  Current payment including past due
                </div>{" "}
                <div className="md:mt-0 col-span-1  md:col-span-2 w-full md:w-auto flex gap-2   ">
                  <input
                    className={`bg-transparent  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left outline-none`}
                  />

                  <Button
                    name="Browse files"
                    data-modal-hide="default-modal"
                    className=" bg-gradient-to-r from-[#018ffd] to-[#070c84]  w-[6rem] md:w-[16rem]  h-full border rounded-xl text-white font-semibold text-[14px] md:text-xl"
                  >
                    Pay
                  </Button>
                </div>
              </div>
              <TableComponentForPayment header="Payment Schedule"/>
              
              <TableComponentForPayment header="Payment History"/>


              <div className="my-6 md:my-16">
                <Typography
                  variant="custom"
                  className={`text-center  my-4  font-bold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
                >
                Payoff Quote
                </Typography>
                <div className=" grid grid-cols-1 my-6 sm:my-10 gap-0 md:gap-2 md:grid-cols-3 justify-items-left h-[80] md:h-[40] formControl mb-4">
                <div className="text-left text-[14px] text-xl font-bold h-full flex items-end md:items-center">
                Current amount is to pay off the loan in full
                </div>{" "}
                <div className="md:mt-0 col-span-1  md:col-span-2 w-full md:w-auto flex gap-2   ">
                  <input
                    id="fileInput"
                    multiple={false}
                    className={`bg-transparent  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left outline-none`}
                  />

                  <Button
                    name="Browse files"
                    data-modal-hide="default-modal"
                    className=" bg-gradient-to-r from-[#018ffd] to-[#070c84]  w-[12rem] md:w-[16rem]  h-full border rounded-xl text-white font-semibold text-[14px] md:text-xl"
                  >
                    PDF Download
                  </Button>
                </div>
              </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomizableHeaderModel
        size={"2xl"}
        isOpen={openModal !== null}
        onOpen={() => {
          setOpenModal(null);
        }}
        Component={
          <ShowStatusForFinaceApplication
            handleNext={() => setOpenModal(null)}
          />
        }
      />
    </>
  );
}

const TableLabel = [
  "Month",
  "Payment Amount",
  "Principal",
  "Interest",
  "Balance",
];


const TableComponentForPayment = ({header}:{header:string}) =>{
  return(
    <div className="my-6  py-4">
      <Typography
        variant="custom"
        className={`text-center  my-4  font-bold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
      >
        {header}
      </Typography>
      <div className="relative overflow-x-auto w-full rounded-xl border-[#00000080]  border-2">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-lg text-white  border-[#00000080] ">
            <tr>
              {TableLabel.map((txt) => {
                return (
                  <th
                    key={txt}
                    scope="col"
                    className="px-6 py-3 text-center border-l border-[#00000080] bg-gradient-to-r from-[#018FFD] to-[#070C84] "
                  >
                    {txt}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dummyData.map(
              (
                {
                  Month,
                  Balance,
                  Interest,
                  Principal,
                  PaymentAmount,
                },
                idx
              ) => (
                <tr
                  className="bg-white text-black border-b border-[#00000080] text-base md:text-xl font-semibold"
                  key={idx}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 text-center border-l border-[#00000080]  whitespace-nowrap"
                  >
                    {Month}
                  </th>
                  <td className="px-6 py-4 text-center border-l border-[#00000080]">
                    ${PaymentAmount}
                  </td>
                  <td className="px-6 py-4 text-center border-l border-[#00000080]">
                    ${Principal}
                  </td>
                  <td className="overflow-hidden max-w-64  h-16 text-center border-l border-[#00000080] cursor-pointer ">
                    ${Interest}
                  </td>
                  <td className="overflow-hidden max-w-64  h-16 text-center border-l border-[#00000080] cursor-pointer ">
                    ${Balance}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>)
}

const dummyData = [
  {
    Month: 1,
    PaymentAmount: 1000,
    Principal: 800,
    Interest: 200,
    Balance: 2000,
  },
  {
    Month: 2,
    PaymentAmount: 1000,
    Principal: 820,
    Interest: 180,
    Balance: 1180,
  },
];

const ShowStatusForFinaceApplication = ({
  handleNext,
}: {
  handleNext: () => void;
}) => {
  return (
    <div className="w-full px-4 md:px-20 py-32 flex flex-col items-center">
      <Typography
        variant="custom"
        className=" text-xl font-[Metropolis] font-semibold text-center"
      >
        Pending Review
      </Typography>
      <div className="w-full max-w-52">
        <Button
          onClick={handleNext}
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
