import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VehicleCondition } from "services";

interface VehicleConditionState {
  vehicleCondition: VehicleCondition;
}

const initialState: VehicleConditionState = {
  vehicleCondition: {
    listingId: 0,
    mechnical_Issue: false,
    mechanicalIssueDescription: "",
    missing_Original_Key: false,
    missingOriginalKeyDescription: "",
    bumper_repair: false,
    bumperRepairDescription: "",
    interior_Cracks: false,
    interiorCracksDescription: "",
    exterior_scratches: false,
    exteriorScratchesDescription: "",
    lights_on_in_dash: false,
    lightsOnInDashDescription: "",
    items_functionality: false,
    itemsFunctionalityDescription: "",
    do_tires_50_remaining: false,
    tires50RemainingDescription: "",
    transmission_slipping: false,
    transmissionSlippingDescription: "",
    broken_glass: false,
    brokenGlassDescription: "",
  },
};

const vehicleConditionSlice = createSlice({
  name: "vehicleCondition",
  initialState,
  reducers: {
    setVehicleCondition: (state, action: PayloadAction<VehicleCondition>) => {
      state.vehicleCondition = action.payload;
    },
    updateVehicleCondition: (
      state,
      action: PayloadAction<Partial<VehicleCondition>>
    ) => {
      console.log(action);

      state.vehicleCondition = {
        ...state.vehicleCondition,
        ...action.payload,
      };
    },
    clearVehicleCondition: (state) => {
      state.vehicleCondition = { ...initialState.vehicleCondition };
    },
  },
});
export const { updateVehicleCondition, clearVehicleCondition } =
  vehicleConditionSlice.actions;

export default vehicleConditionSlice.reducer;
