import { useCarListingsQuery } from "hooks/api/queries/car-listings/use-car-listings-query";
import { useEffect, useState } from "react";
import { ListingDetails, useGetListingSearch } from "services";
import { getUserId } from "utils/get-user-id";
import { useDebounceValue } from "usehooks-ts";

interface Props {
}

export const useCarlistSearch = ({}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  // const [prevAction, setPrevAction] = useState("");
  const [page, setPage] = useState<number | null>(1);
  const [pageSize, setPageSize] = useState(10);
  // const infiniteScroll = useRef<HTMLDivElement>(null);
  const status = 1;
  const userId = getUserId();
  const {
    data: carListings,
    refetch: listRefetch,
    isFetching: isFetchingCarList,
    isPending:isPendingListingQuery,
    isError: isErrorListingQuery,
    error: listingQueryError,
  } = useCarListingsQuery(userId, status, page || 1, pageSize);

  const {
    data: listCarSearch,
    refetch: searchRefetch
  } = useGetListingSearch(searchValue);

  const [items, setItems] = useState<ListingDetails[]>();
  const [debonceSearchValue] = useDebounceValue(searchValue, 400);

  useEffect(() => {
    if (carListings?.length) {
      const newCars = (carListings as ListingDetails[]) || [];
      const oldCars = (listCarSearch && listCarSearch?.length > 0) ? []:Array.isArray(items) ?  items : [];
      const uniqueCars = Array.from(new Set([...oldCars, ...newCars]));
      setItems(uniqueCars);
    }
  }, [carListings]);

  useEffect(() => {
    if (listCarSearch) {
      const newCars = (listCarSearch as ListingDetails[]) || [];
      
      setItems(newCars);
    }
  }, [listCarSearch]);

  useEffect(() => {
    if (page && page > 1 && searchValue.length === 0) {
      listRefetch();
    }
  }, [page]);

  useEffect(() => {
    if (searchValue.length > 0) {
      searchRefetch();
      setPage(1)
     } else if (listCarSearch?.length === 0){
      listRefetch(); 
    } else if (searchValue.length === 0 && listCarSearch && listCarSearch?.length > 0){

      listRefetch(); 
    }
  }, [debonceSearchValue]);

  return {
    page,
    items,
    carListings,
    isPendingListingQuery,
    isFetchingCarList,
    isErrorListingQuery,
    listingQueryError,
    setPage,
    setSearchValue,
    setItems,
    listRefetch

  };
};
