import React, { useState } from "react";
import "./index.scss";
import TierCard from "./TierCard";
interface Props {
  onCallBack?: (tier: any) => void;
  mainClass?:string
}
export default function TierCards(props: Props) {
  const [select, setSelect] = useState<number | null>(null);

  const tiers = [
    {
      id: "FMO_DEALER_BASIC_TIER",
      name: "Tier 1",
      type: "The Basics",
      note: "No Subscription Required",
      price: 0,
      features: [
        "Inventory Management",
        "DMV/Titling and Shipping",
        "Captivating Vehicle Listings",
      ],
    },
    {
      id: "FMO_DEALER_ESSENTIALS_TIER",
      name: "Tier 2",
      type: "Dealer Essentials",
      note: "per month + $49 Setup",
      price: 49,
      features: ["All Tier 1 Features", "Bundled Pricing", "Mobile App"],
    },
    {
      id: "FMO_DEALER_COMPLETE_TIER",
      name: "Tier 3",
      type: "Dealer Complete",
      note: "per month + $99 Setup 110/user/month",
      price: 99,
      features: [
        "All Tier 1 and 2 Features",
        "Integrated CRM",
        "Dedicated Listing Manager",
      ],
    },
    {
      id: "FMO_DEALER_ENTERPRISE_TIER",
      name: "Tier 4",
      type: "Dealer Enterprise",
      note: "per month + $149 Setup 99/user/month",
      price: 149,
      features: [
        "All Tier 1, 2, and 3 Features",
        "Free Web Hosting",
        "Automated Inquiry Management",
      ],
    },
  ];
  console.log("select", select);
  return (
    <div>
      <div className="">
        <div className={props?.mainClass ||"justify-items-center w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 place-items-center gap-16 2xl:gap-72"}>
          {tiers.map((tier, key) => {
            return (
              <TierCard
                key={tier.id}
                tier={tier}
                isSelected={key === select}
                handleSelect={() => setSelect(key)}
                onCallBack={() => props.onCallBack && props.onCallBack(tier)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
