import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import ChatHelp from "../chathelp/ChatHelp";
import Notification from "../notification/Notification";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { PATH_APP } from "../../routers/path.router";
import { ToolsMenu } from "./tools-menu/ToolsMenu";

interface NavBarProps {
  type: string;
  children?: React.ReactNode;
}

const NavBar: React.FC<NavBarProps> = ({ type, children }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [chatToggle, setChatToggle] = useState(false);
  const [notifyToggle, setNotifyToggle] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [ty, setTy] = useState(type);
  const [toggleToolsMenu, setToggleToolsMenu] = useState(false);
  const [status, setStatus] = useState();
  const childRef = useRef(null);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex flex-row"
          onClick={() => navigate("/user-profile")}
        >
          <h1>View/Edit Profile</h1>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="flex flex-row"
          onClick={() => navigate("/user-membership")}
        >
          <h1>View/Edit Login/Membership</h1>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="flex flex-row p-8"
          onClick={() => {
            localStorage.clear();
            navigate("/dealer-login");
          }}
        >
          <h1> Log out</h1>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      if (window.innerWidth < 726) {
        setShowMobile(true);
    
      } else {
        setShowMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after mount

  return (
    <div className="relative">
      {
        <ChatHelp
          curr={status}
          childRef={childRef}
          setChatToggle={setChatToggle}
          chatToggle={chatToggle}
          openChat={false}
          setOpenChat={setChatToggle}
        />
      }
      {notifyToggle && (
        <Notification
          setNotifyToggle={setNotifyToggle}
          notifyToggle={notifyToggle}
        />
      )}

      <div className="">
        {!showMobile && (
          <div className="left-header">
            <img
              className="hamburger"
              src={process.env.PUBLIC_URL + "/assets/icon/hamburger.svg"}
              alt="hamburger"
              onClick={() => setIsVisible((isVisible) => !isVisible)}
            />
            <img
              src={process.env.PUBLIC_URL + "/assets/icon/LOGO-SVG-1.svg"}
              alt="logo"
            />
          </div>
        )}
        <div className="navbar-main ">
          {!showMobile && (
            <div
              className="navbar-left"
              style={!isVisible ? { width: "79px" } : {}}
            >
              <div
                className="navbar-content"
                style={!isVisible ? { gap: "31px", width: "79px" } : {}}
              >
                <div
                  className="content-detail"
                  style={ty == "dashboard" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/dealer-dashboard")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/lucide_home.svg"
                    }
                    alt="logo"
                  />
                  {isVisible && <div className="detail-text">Dashboard</div>}
                </div>
                {isVisible && <div className="content-line"></div>}
                <div
                  className="content-detail"
                  style={ty == "enter-scan" ? { opacity: "1" } : {}}
                  onClick={() => navigate("/dealer-dashboard/enter-vin")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/ph_scan-fill.svg"
                    }
                    alt="logo"
                  />
                  {isVisible && (
                    <div className="detail-text">Enter Vin Number</div>
                  )}
                </div>
                {isVisible && <div className="content-line"></div>}

                <div
                  className="content-detail"
                  style={ty == "manage-inventory" ? { opacity: "0.9" } : {}}
                  onClick={() => {
                    setTy("manage-inventory");
                    navigate("/dealer-dashboard/manage-inventory");
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icon/mingcute_inventory-line.svg"
                    }
                    alt="logo"
                  />
                  {isVisible && (
                    <div className="detail-text">Manage Inventory</div>
                  )}
                </div>
                {isVisible && <div className="content-line"></div>}
                <div
                  className="content-detail"
                  style={ty == "dmv-titling" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/dealer-dmvTitling")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icon/tabler_steering-wheel.svg"
                    }
                    alt="tabler_steering-wheel"
                  />
                  {isVisible && (
                    <div className="detail-text">DMV / Titling</div>
                  )}
                </div>
                {isVisible && <div className="content-line"></div>}
                <div
                  className="content-detail"
                  style={ty == "shipping-quotes" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/shipping-quotes")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/shipping_quote.svg"
                    }
                    alt="shipping_quote"
                  />
                  {isVisible && (
                    <div className="detail-text">Shipping Quotes</div>
                  )}
                </div>
                {isVisible && <div className="content-line"></div>}
                <div
                  className="content-detail"
                  style={ty == "profit-loss" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/dealer-dashboard/profit-loss")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/tabler_cash.svg"
                    }
                    alt="logo"
                  />
                  {isVisible && (
                    <div className="detail-text">Profit and Loss</div>
                  )}
                </div>
                {isVisible && <div className="content-line"></div>}
                <div
                  className="content-detail"
                  style={ty == "tools" ? { opacity: "0.9" } : {}}
                  onClick={() => setToggleToolsMenu(!toggleToolsMenu)}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/codicon_tools.svg"
                    }
                    alt="logo"
                  />
                  {isVisible && (
                    <>
                      <div className="detail-text">Tools</div>
                      <img
                        className="dorp-down"
                        src={process.env.PUBLIC_URL + "/assets/icon/down.svg"}
                        alt="logo"
                      />
                    </>
                  )}
                </div>
                {toggleToolsMenu && <ToolsMenu />}
              </div>
            </div>
          )}

          {showMobile && isVisible && (
            <div className="navbar-left-mobile">
              <div className="flex">
                <img
                  className="p-4 h-12 w-12 ml-auto cursor-pointer"
                  src={process.env.PUBLIC_URL + "/assets/icons/close.svg"}
                  alt="close"
                  onClick={() => setIsVisible(false)}
                />
              </div>
              <div className="navbar-content">
                <div
                  className="content-detail"
                  style={ty == "dashboard" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/dealer-dashboard")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/lucide_home.svg"
                    }
                    alt="logo"
                  />
                  <div className="detail-text">Dashboard</div>
                </div>
                <div className="content-line"></div>
                <div
                  className="content-detail"
                  style={ty == "enter-scan" ? { opacity: "1" } : {}}
                  onClick={() => navigate("/dealer-dashboard/enter-vin")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/ph_scan-fill.svg"
                    }
                    alt="logo"
                  />

                  <div className="detail-text">Enter Vin Number</div>
                </div>
                <div className="content-line"></div>

                <div
                  className="content-detail"
                  style={ty == "manage-inventory" ? { opacity: "0.9" } : {}}
                  onClick={() => {
                    setTy("manage-inventory");
                    navigate("/dealer-dashboard/manage-inventory");
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icon/mingcute_inventory-line.svg"
                    }
                    alt="logo"
                  />
                  <div className="detail-text">Manage Inventory</div>
                </div>
                <div className="content-line"></div>
                <div
                  className="content-detail"
                  style={ty == "dmv-titling" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/dealer-dmvTitling")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icon/tabler_steering-wheel.svg"
                    }
                    alt="tabler_steering-wheel"
                  />
                  <div className="detail-text">DMV / Titling</div>
                </div>
                <div className="content-line"></div>
                <div
                  className="content-detail"
                  style={ty == "shipping-quotes" ? { opacity: "0.9" } : {}}
                  // onClick={() => navigate('/dealer-shippingQuotes')}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/shipping_quote.svg"
                    }
                    alt="shipping_quote"
                  />

                  <div className="detail-text">Shipping Quotes</div>
                </div>
                <div className="content-line"></div>
                <div
                  className="content-detail"
                  style={ty == "profit-loss" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate("/dealer-dashboard/profit-loss")}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/tabler_cash.svg"
                    }
                    alt="logo"
                  />
                  <div className="detail-text">Profit and Loss</div>
                </div>
                <div className="content-line"></div>
                <div
                  className="content-detail"
                  style={ty == "tools" ? { opacity: "0.9" } : {}}
                  onClick={() => navigate(PATH_APP.tools.paymentCalculator)}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/codicon_tools.svg"
                    }
                    alt="logo"
                  />
                  <div className="detail-text">Tools</div>
                  <img
                    className="dorp-down"
                    src={process.env.PUBLIC_URL + "/assets/icon/down.svg"}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-full">
            {showMobile ? (
              <div className="navbar-header-mobile">
                <div className="flex items-center">
                  <img
                    onClick={() => setIsVisible(true)}
                    className="cursor-pointer w-6 h-6 mr-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icon/mobile-toggle-btn.svg"
                    }
                    alt="search-solid"
                  />

                  <div className="header-search">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icon/basil_search-solid.svg"
                      }
                      alt="search-solid"
                    />
                    <input placeholder="Ask me anything..."></input>
                  </div>
                </div>
                <div
                  className="header-btn"
                  onClick={() => setNotifyToggle((prev) => !prev)}
                >
                  <div className="header-notification">
                    <img
                      className="w-8 h-8"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icon/mi_notification.svg"
                      }
                      alt="mi_notification"
                    />
                  </div>
                  <div
                    className="header-help"
                    onClick={() => setChatToggle((prev) => !prev)}
                  >
                    <img
                      className="w-8 h-8"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icon/material-symbols_help-outline.svg"
                      }
                      alt="material-symbols_help-outline"
                    />
                  </div>
                  <img
                    className="header-avatar w-8 h-8"
                    src={process.env.PUBLIC_URL + "/assets/images/avatar/1.svg"}
                    alt="avatar-1"
                  />
                </div>
              </div>
            ) : (
              <div
                className="navbar-header"
                style={
                  !isVisible
                    ? { paddingLeft: "226px", paddingRight: "115px" }
                    : {}
                }
              >
                <div className="header-search">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icon/basil_search-solid.svg"
                    }
                    alt="search-solid"
                  />
                  <input placeholder="Ask me anything..."></input>
                </div>
                <div className="header-btn w-full max-w-40 lg:max-w-96">
                  <div
                    className="header-notification"
                    onClick={() => setNotifyToggle((prev) => !prev)}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icon/mi_notification.svg"
                      }
                      className="h-10 w-10"
                      alt="mi_notification"
                    />
                    <div className="hidden lg:block ">Notifications</div>
                  </div>
                  <div
                    className="header-help"
                    onClick={() => setChatToggle((prev) => !prev)}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icon/material-symbols_help-outline.svg"
                      }
                      className="h-10 w-10"
                      alt="material-symbols_help-outline"
                    />
                    <div className="hidden lg:block ">Help</div>
                  </div>
                  <div className="header-line hidden lg:block "></div>
                  <div
                    className="header-name hidden lg:block "
                    style={{ cursor: "pointer" }}
                  >
                    Ajith
                  </div>
                  <Dropdown
                    menu={{ items }}
                    placement="bottomLeft"
                    arrow={{ pointAtCenter: true }}
                  >
                    <img
                      className="header-avatar w-10 h-10"
                      style={{ cursor: "pointer" }}
                      src={
                        process.env.PUBLIC_URL + "/assets/images/avatar/1.svg"
                      }
                      alt="avatar-1"
                    ></img>
                  </Dropdown>
                </div>
              </div>
            )}
            {ty === "manage-inventory" && (
              <div className="navbar-right mt-[130px]">{children}</div>
            )}
            {ty === "shipping-quote" && (
              <div className="navbar-right mt-[130px]">{children}</div>
            )}
            {ty === "dashboard" && (
              <div className="navbar-right mt-[130px]">{children}</div>
            )}
            {ty === "enter-scan" && (
              <div className="navbar-right-full mt-[130px] flex items-center ">
                {children}
              </div>
            )}
            {ty === "profit-loss" && (
              <div className="navbar-right mt-[130px]  ">{children}</div>
            )}
            {ty === "" && (
              <div className="navbar-right mt-[130px]  ">{children}</div>
            )}
            {ty === "dmv-titling" && (
              <div className="navbar-right mt-[130px]  ">{children}</div>
            )}
            {ty === "tools" && (
              <div className="mt-[130px] flex items-center ">{children}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
