import { Image } from "@nextui-org/react";
import { socialMedia } from "../contact-us.data";
import { LinkedInIcon } from "assets/svgs/icons/linked-in-icon";
const ContactUsFooter = () => (
  <div className="flex flex-col items-center justify-center gap-4 text-center text-lg font-[Metropolis]">
    <h1 className="font-bold text-lg font-[Metropolis]">
      We're always here to help!
    </h1>
    <p className="text-left  lg:text-left text-medium">
      Don't hesitate to reach out through live chat or submit a support ticket.
      We're dedicated to resolving your issues efficiently and ensuring you have
      a smooth and enjoyable experience with findmyoptions.com.
    </p>
    <h1 className="font-semibold">Connect with Us on Social Media:</h1>
    <div className="flex gap-4">
      {socialMedia.map(({ src, alt, url }: any, index: any) =>
        src ? (
          <img
            src={src}
            alt={alt}
            className="w-[55px] h-auto cursor-pointer object-cover"
            onClick={() => window.open(url, "_blank")}
          />
        ) : (
          <LinkedInIcon
            className="w-[55px] h-auto cursor-pointer"
            onClick={() => window.open(url, "_blank")}
          />
        )
      )}
    </div>
  </div>
);

export default ContactUsFooter;
