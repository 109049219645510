import axios from "axios";
import { env } from "config/env";

export default async function GetListingDescription(carModel: string) {

  return await axios
    .post(
      `${env.AI_URL}}get-listing-description?car=${carModel}`
    )
    .then((res) => res)
    .catch((error) => {
      console.error("Error fetching listing description:", error);
    });
}


export  async function getCarPartDetailForListing(formData: FormData) {


  return await axios
    .post(
      `${env.AI_URL}detect`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => res)
    .catch((error) => {
      console.error("Error fetching listing description:", error);
    });
}
