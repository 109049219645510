import { Card } from "@nextui-org/react";

interface CardProp {
  card: any;
}

const ContactUsCard = ({ card }: CardProp) => {
  const { cardNumber, title,  icon } = card;
  return (
    <Card className="cursor-default	 relative items-center px-4 py-6 text-center bg-gradient-to-b from-[#018ffd] to-[#070c84] text-white gap-4  h-[240px] w-full ">
      <h1 className="text-xl leading-10">
        {cardNumber}
        <br /> {title}
      </h1>
      {icon}
    </Card>
  );
};

export default ContactUsCard;
