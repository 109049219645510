import { z } from "zod";

export const ContactSchema = z.object({
  email: z
    .string()
    .min(1, "Email is Required")
    .email({ message: "Please provide a valid Email" }),
  phone: z
    .string({ required_error: "Phone Number is Required" })
    .trim()
    .min(1, "Phone Number is Required")
    .refine(
      (value) =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
      "Please provide valid US Phone Number"
    ),
  name: z
    .string()
    .trim()
    .min(1, "Full Name is Required")
    .min(2, "At least 2 Characters is Required")
    .refine(
      (value) => /^[a-zA-Z\s]+$/.test(value),
      "Name can only contains Alphabetic Characters."
    ),
  zipCode: z
    .string()
    .min(1, {
      message: "Zip is required",
    })
    .length(5, "Zip needs to have a length of 5"),
});
