import React, { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
} from "@nextui-org/react";

import { Button, SelectNextUiCarSearch, Typography } from "components";
import Loading from "components/Loading/Loading";
import Input from "components/ui/input";
import {
  ListingVehicleRequest,
  ListingVehicleResponse,
  useGetListingsVehicles,
} from "services";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounceValue } from "usehooks-ts";
import { localStorageFn } from "utils/localstorage";
import SearchCarDetails from "./components/SearchCarDetails";

export const FilterButtons = [
  {
    label: "Year",
    key: "year",
    type: "range",
    opt: { useGrouping: false },
  },
  {
    label: "Distance",
    key: "distance",
    type: "range",
    isSingle: true,
  },
  {
    label: "Make",
    key: "make",
  },
  {
    label: "Body Class",
    key: "BodyClass",
  },
  {
    label: "Model",
    key: "model",
  },
  {
    label: "Number of Seats",
    key: "seats",
  },
  {
    label: "Trim",
    key: "trim",
  },
  {
    label: "Transmission",
    key: "transmission",
  },
  {
    label: "Mileage",
    key: "mileage",
    type: "range",
  },
  {
    label: "Price",
    key: "price",
    type: "range",
    opt: {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
];

export const SearchCarPage = () => {
  const srcOpt: ListingVehicleRequest[] | undefined =
    localStorageFn.getItem("srcOpt");
  const filterVal: ListingVehicleResponse[] | undefined =
    localStorageFn.getItem("filter_val");

  const [optionCach, setOptionCach] = useState<ListingVehicleResponse[]>([]);
  const [page, setPage] = useState<number | null>(1);

  const [items, setItem] = useState<ListingVehicleResponse[]>([]);

  const [filterValue, setFilterValue] = React.useState<ListingVehicleRequest[]>(
    []
  );

  const [callFilterApi, setCallFilterApi] = useState<number>(0);
  const [debouncedValue] = useDebounceValue(callFilterApi, 700);

  const {
    data: curCarData,
    refetch: listingRefetchPage,
    isFetchedAfterMount,
    isFetching,
  } = useGetListingsVehicles(
    srcOpt || filterValue,
    (newData) => {
      setItem((prev) => (page === 1 ? newData : [...prev, ...newData]));
      if (newData.length < 0) {
        setPage(null);
      }
    },
    page || 1,
    20
  );

  React.useEffect(() => {
    if (isFetchedAfterMount) {
      setFilterValue(srcOpt || []);
      if (filterVal && filterVal?.length > optionCach.length) {
        setOptionCach(filterVal);
      }
      localStorageFn.removeItem("srcOpt");
      localStorageFn.removeItem("filter_val");
    }
    // localStorageFn.setItem("searchItem",fiterData)

    // listingRefetchPage();
  }, [isFetchedAfterMount]);

  React.useEffect(() => {
    if (page === 1) {
      listingRefetchPage();
    }
  }, [page, debouncedValue]);

  React.useEffect(() => {
    if (items.length > optionCach.length) {
      setOptionCach(items);
    }
  }, [items]);

  const handleSearchText = async (text: string) => {
    const payload =
      text.length > 0
        ? [
            {
              term: "text",
              value: text,
              secondaryValue: "",
              isRange: false,
            },
            ...filterValue.filter((i) => i.term !== "text"),
          ]
        : filterValue.filter((i) => i.term !== "text");
    setFilterValue(payload);
    setPage(1);
  };

  const handleFilterRange = async (key: string, value: number | number[]) => {
    if (Array.isArray(value)) {
      const payload =
        value[0] === getMixValue(key) && value[1] === getMaxValue(key)
          ? filterValue.filter((t) => t.term !== key)
          : [
              {
                term: key,
                value: String(value[0]),
                secondaryValue: String(value[1]),
                isRange: true,
              },
              ...filterValue.filter((t) => t.term !== key),
            ];
      setFilterValue(payload);
      setPage(1);
    }
  };

  const handleFilterRangeDistance = async (
    key: string,
    value: number | number[]
  ) => {
    if (!Array.isArray(value)) {
      const payload =
        value === 5000
          ? filterValue.filter((t) => t.term !== key)
          : [
              {
                term: key,
                value: String(value),
                secondaryValue: "",
                isRange: false,
              },
              ...filterValue.filter((t) => t.term !== key),
            ];
      setFilterValue(payload);
      setPage(1);
    }
  };
  const handleMultiSelectValue = async (key: string, value: string[]) => {
    const payload = [
      ...[...value].map((el) => ({
        term: key,
        value: el,
        secondaryValue: "",
        isRange: false,
      })),
      ...filterValue.filter((t) => t.term !== key),
    ];

    setFilterValue(payload);
    setPage(1);
  };

  const handleDropdownData: (key: string) => { label: any; value: any }[] = (
    key: string
  ) => {
    switch (key) {
      case "make": {
        const makeOpt = optionCach
          .filter((item) => item.listingDetail.basicDetail.make) // Filter out optionCach without a valid year
          .map((item) => item.listingDetail.basicDetail.make) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .map((make) => ({ label: make, value: make })); // Map to the desired format

        return makeOpt;
      }
      case "BodyClass": {
        const bodyClass = optionCach
          .filter(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "BodyClass"
              )?.value
          ) // Filter out optionCach without a valid year
          .map(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "BodyClass"
              )?.value
          ) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .map((make) => ({ label: make, value: make })); // Map to the desired format

        return bodyClass;
      }
      case "model": {
        const modelOpt = optionCach
          .filter((item) => item.listingDetail.basicDetail.model) // Filter out optionCach without a valid year
          .map((item) => item.listingDetail.basicDetail.model) // Extract the years
          .filter((model, index, arr) => arr.indexOf(model) === index) // Remove duplicate
          .map((model) => ({ label: model, value: model })); // Map to the desired format

        return modelOpt;
      }

      case "seats": {
        const numberOfSeat = optionCach
          .filter(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "Seats"
              )?.value
          ) // Filter out optionCach without a valid year
          .map(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "Seats"
              )?.value
          ) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .sort((a, b) => Number(b) - Number(a)) // Sort the years in ascending order
          .map((make) => ({ label: make, value: make })); // Map to the desired format
        return numberOfSeat;
      }

      case "trim": {
        const trimOpt = optionCach
          .filter((item) => item.listingDetail.basicDetail.trim) // Filter out optionCach without a valid year
          .map((item) => item.listingDetail.basicDetail.trim) // Extract the years
          .filter((trim, index, arr) => arr.indexOf(trim) === index) // Remove duplicate
          .map((trim) => ({ label: trim, value: trim })); // Map to the desired format

        return trimOpt;
      }

      case "transmission": {
        const transValue = optionCach
          .filter(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "TransmissionStyle"
              )?.value
          ) // Filter out optionCach without a valid year
          .map(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "TransmissionStyle"
              )?.value
          ) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .map((make) => ({ label: make, value: make })); // Map to the desired format

        return transValue;
      }

      default: {
        return [{ label: "", value: "" }];
      }
    }
  };

  const getMaxValue = (key: string) => {
    switch (key) {
      case "year": {
        const yearOpt = optionCach
          .filter((item) => item.listingDetail.basicDetail.year) // Filter out optionCach without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.year)); // Extract the years
        return Math.max(...yearOpt, 0);
      }

      case "distance": {
        return 10000;
      }

      case "mileage": {
        const maxMileage = optionCach
          .filter((item) => item.listingDetail.basicDetail.mileage) // Filter out optionCach without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.mileage)); // Extract the years

        return Math.max(...maxMileage, 0);
      }

      case "price": {
        const maxPrice = optionCach
          .filter((item) => item.listingDetail.basicDetail.price) // Filter out optionCach without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.price)); // Extract the years

        return Math.max(...maxPrice, 0);
      }
      default: {
        return 500;
      }
    }
  };

  const getMixValue = (key: string) => {
    switch (key) {
      case "year": {
        const yearOpt = optionCach
          .filter((item) => item.listingDetail.basicDetail.year) // Filter out optionCach without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.year)); // Extract the years
        return Math.min(...yearOpt, new Date().getFullYear());
      }
      case "distance": {
        return 0;
      }
      case "mileage": {
        const minMileage = optionCach
          .filter((item) => item.listingDetail.basicDetail.mileage) // Filter out optionCach without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.mileage)); // Extract the years
        return Math.min(...minMileage, 30000);
      }
      case "price": {
        const minPrice = optionCach
          .filter((item) => item.listingDetail.basicDetail.price) // Filter out optionCach without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.price)); // Extract the years
        return Math.min(...minPrice, 30000);
      }

      default: {
        return 0;
      }
    }
  };

  const removeFilterValue = (key: string) => {
    const updatedState = filterValue.filter((item) => item.term !== key);
    setFilterValue(updatedState);
    setCallFilterApi((t) => t + 1);
  };
  return (
    <InfiniteScroll
      dataLength={items.length}
      next={() => {
        setPage((p) => (p ? p + 1 : null));
      }}
      hasMore={page !== null && items.length >= 19}
      scrollThreshold={0.8}
      className="overflow-visible"
      style={{ overflow: "visible" }}
      loader={
        curCarData && curCarData?.length > 0 ? (
          <p className="flex justify-center items-center">
            <b>Loading...</b>
          </p>
        ) : (
          ""
        )
      }
    >
      <div className="px-0  md:px-6 min-[1024px]:px-0  min-[1324px]:px-4 min-[1500px]:pr-1 min-[1500px]:pl-0">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 justify-items-center items-center">
          <Input
            placeholder="Search by keywords or options"
            className="col-span-4"
            onChange={async (e) => {
              // await setSearchValue(e.target.value);
              // setCalledSearch(1);
              await handleSearchText(e.target.value);
              setCallFilterApi((t) => t + 1);
            }}
          />
          <Button
            customVariant="custom"
            className="col-span-4 md:col-span-1 text-[15px] bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full tracking-wide px-6 rounded-xl md:h-full h-[60px]"
            type="button"
            onClick={() => setCallFilterApi(0)}
          >
            Search
          </Button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mt-5">
          <div
            id={"vehicle-search-filter"}
            className="relative col-span-4 md:col-span-1 "
          >
            <Typography variant="custom" className="text-medium font-[700]">
              Filtered Search
            </Typography>
            <div className="mt-2 relative grid grid-cols-2 sm:grid-cols-1 gap-2">
              {FilterButtons.map((button, index) => (
                <div className="relative">
                  {button.type === "range" ? (
                    <>
                      <Popover
                        placement="bottom"
                        showArrow={true}
                        className=" relative "
                        portalContainer={
                          document.getElementById(`vehicle-search-filter`) ||
                          document.body
                        }
                        style={{
                          position: "absolute",
                          zIndex: 9999, // Adjust as needed
                        }}
                      >
                        <PopoverTrigger>
                          <div
                            key={index}
                            className={`font-[Metropolis-semibold] font-[500] text-[14px]  cursor-pointer text-[#71717A] ${
                              filterValue
                                .map((i) => i.term)
                                .includes(button.key)
                                ? "border-2"
                                : "border"
                            } rounded-2xl border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors   py-4  flex items-center justify-center text-center`}
                            id={`${button.key}-${index}`}
                            onClick={() => getLocation(button.key)}
                          >
                            {button.label}
                            {filterValue
                              .map((i) => i.term)
                              .includes(button.key) && (
                              <div
                                className="absolute right-3.5 z-40 text-base hover:text-blue-500 text-black"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.isPropagationStopped();
                                  e.stopPropagation(); // Prevent the popover from opening
                                  removeFilterValue(button.key);
                                  return false;
                                }}
                              >
                                X
                              </div>
                            )}
                          </div>
                        </PopoverTrigger>

                        <PopoverContent
                          style={{
                            position: "absolute",
                            zIndex: 9999, // Adjust as needed
                          }}
                        >
                          <div className="notification-container px-2 overflow-hidden py-6 ">
                            {button.isSingle ? (
                              <>
                                <Slider
                                  label={button.label}
                                  step={1}
                                  value={Number(
                                    filterValue.find(
                                      (i) => i.term === button.key
                                    )?.value || 5000
                                  )}
                                  minValue={0}
                                  maxValue={5000}
                                  formatOptions={button?.opt || {}}
                                  onChange={async (e) => {
                                    await handleFilterRangeDistance(
                                      button.key,
                                      e
                                    );
                                    await setCallFilterApi((t) => t + 1);
                                  }}
                                  className="max-w-md"
                                />
                                <div className="my-2">
                                  <Input
                                    variant="form"
                                    placeholder="zip code"
                                    size="compact"
                                  ></Input>
                                </div>
                              </>
                            ) : (
                              <Slider
                                label={button.label}
                                step={1}
                                value={[
                                  Number(
                                    filterValue.find(
                                      (i) => i.term === button.key
                                    )?.value || getMixValue(button.key)
                                  ),
                                  Number(
                                    filterValue.find(
                                      (i) => i.term === button.key
                                    )?.secondaryValue
                                  ) || getMaxValue(button.key),
                                ]}
                                formatOptions={button?.opt || {} as any}
                                minValue={getMixValue(button.key)}
                                maxValue={getMaxValue(button.key)}
                                // formatOptions={{}}
                                onChange={async (e) => {
                                  await handleFilterRange(button.key, e);
                                  await setCallFilterApi((t) => t + 1);
                                }}
                                className="max-w-md"
                              />
                            )}
                          </div>
                        </PopoverContent>
                      </Popover>
                    </>
                  ) : (
                    <SelectNextUiCarSearch
                      key={`${button.key}-${index}`}
                      placeholder="Select Vehicle Type"
                      label={`select-${button}${index}`}
                      portalId="vehicle-search-filter"
                      aria-label={`select-${button}${index}`}
                      // portalId={""}
                      data={handleDropdownData(button.key)}
                      handleClearSelect={() => removeFilterValue(button.key)}
                      selectedKeys={filterValue
                        .filter((i) => i.term === button.key)
                        .map((i) => i.value)}
                      register={{
                        onSelectionChange: (e: string[]) => {
                          handleMultiSelectValue(button.key, e);
                          setCallFilterApi((t) => t + 1);
                        },
                        size: "md",
                        placeholder: button.label,
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="col-span-4   pb-20 overflow-y-auto mt-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
              {isFetching && <Loading />}
              {items?.length === 0 && (
                <Typography
                  variant="custom"
                  className="col-span-4 font-bold text-2xl mt-16 items-center text-center"
                >
                  No Cars Found!
                </Typography>
              )}
              {items?.map((car, index) => (
                <SearchCarDetails car={car} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </InfiniteScroll>
  );
};

export const getLocation = (key: string) => {
  if (key === "distance" && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        localStorageFn.setItem("latlong", {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      (error) => {
        console.log(error.message);
      }
    );
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
};
