import { useMutation, useQuery } from "@tanstack/react-query";
import { ListingPaymentCustomOpt } from "pages/public/view-car/accordion-view-car.component";
import { FeeQuotesResponse } from "services";
import { tilingEndpoint } from "services/endpoints/tiling/tiling.endpoint";
import { viewCarEndpoint } from "services/endpoints/view-car/view-car.endpoint";
import {
  FMOListingPaymentReportResponse,
  SellerDetailsData,
  WaretyLead,
} from "services/endpoints/view-car/view-car.type";

export const useQueryGetSellerDetailsByListingId = (listingId: string) => {
  return useQuery<SellerDetailsData, Error>({
    queryKey: ["seller-details-by-id"],
    queryFn: async () => {
      const res = await viewCarEndpoint.getSellerDetailsByListingId(listingId);
      return res;
    },
    enabled: !!listingId,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
  });
};



export const useGetATCFeeQuotesListing = (listingId: string, vin: string) => {
  return useMutation<FeeQuotesResponse, Error, any>({
    mutationFn: async ({ place, qId, zipValue, vehicle, basePrice }: any) => {
      const payload = {
        type: "FeeQuote",
        requestFrom: "ATC",
        requestFor: "",
        verbosityLevel: "4",
        granularity: "2",
        quoteType: "A",
        isProcessing: "1",
        state: place.state || "",
        county: place.county || "",
        city: place.city || "",
        zipCode: zipValue,
        outsideCityLimits: "0",
        vehicle: {
          vin: vin?.toUpperCase() ?? "",
          basePrice: basePrice || "0",
          customerType: "D",
          fuelType: "G",
          cylinders: "6",
          finance: {
            downPayment: "0",
            monthlyPayment: "0",
            monthsInTerm: "0",
            capCostReduction: "0",
          },
          rimSize: "0",
          tires: "0",
          axles: "0",
          gvw: "0",
          gvwr: "0",
          length: "0",
          mpg: "0",
          registrationMonths: "0",
          cc: "0",
          lienStatus: "1",
          year: vehicle?.year ?? "",
          msrp: "1",
          plate: {
            isTransfer: "1",
            plateType: "R",
          },
          registrationOption: "B",
          daysSincePurchase: "138",
          titleStatus: "T",
          transactionType: "P",
          vehicleType: "PV",
          warranties: {},
          weight: "3458",
          rebates: {
            dealer: "0",
            manufacturer: "0",
          },
          additions: {
            addition: {
              name: "Add on",
              content: "0",
            },
          },
          deliveryFees: {
            deliveryFee: {
              name: "DeliveryFees",
              content: "0.00",
            },
          },
          discounts: {
            discount: {
              name: "Discount",
              content: "0.00",
            },
          },
          docFees: {
            docFee: {
              name: "Doc Fees",
              content: "0.00",
            },
          },
          dealerServiceFees: {
            dealerServiceFee: {
              name: "DealerServiceFee",
              content: "0.00",
            },
          },
          extendedWarranties: {},
          insurancePackages: {},
          protectionPackages: {},
          taxes: {},
          theftProtections: {},
          taxesPaidToAnotherState: "0",
        },
      };
      const res = await tilingEndpoint.getATCFeeQuotesAsync(
        payload,
        qId || "0",
        listingId
      );
      return res;
    },
  });
};

export const useProcessFMOReportSubscription = (
  listingId: string,
  vin: string
) => {
  return useMutation<
    FMOListingPaymentReportResponse,
    Error,
    ListingPaymentCustomOpt
  >({
    mutationFn: async (subKey: ListingPaymentCustomOpt) => {
      const res = await viewCarEndpoint.processFMOReportSubscription(
        listingId,
        vin,
        subKey
      );
      return res;
    },
  });
};

export const useGetWarrantyLead = (
) => {
  return useMutation<
    any,
    Error,
    WaretyLead
  >({
    mutationFn: async (payload: WaretyLead) => {
      const res = await viewCarEndpoint.getWarrantyLead(
        payload
      );
      return res;
    }
  });
};
