import { Typography } from "components";
import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className=" pr-4 pl-4 mt-2">
      {/* <Typography variant="custom" className="pb-5">
        <p className="text-4xl font-bold text-center">Privacy Notice</p>
      </Typography> */}
      <Typography variant="custom" className="mt-3">
        <p className="text-lg font-bold">Registration information</p>
        <p className="text-lg mt-1">
          When you create an account or subscribe to one of our services, we may
          collect information, such as but not limited to your first and last
          name, country of residence, email address, license number, username,
          and password.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Transaction information </p>
        <p className="text-lg mt-1">
          This information is processed when you engage with certain services on
          our digital properties, and may include but is not limited to your
          postal address, telephone number and payment information. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">User Activity Information</p>
        <p className="text-lg mt-1">
          This may include information about your use, and the use by any
          person(s) you authorize through your account, of our digital
          properties, such as the content you view or post, how often you use
          our services, and your preferences. This can also include information
          you post in public forums on our digital properties, such as your
          forum posts or blog comments, it could also include certain
          information provided using our messaging chat or other similar
          services where we are permitted by applicable law to collect this
          information. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Third-Party Information </p>
        <p className="text-lg mt-1">
          This includes insights about the use of our digital properties whether
          hosted by us, or on third-party platforms or devices. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Location information </p>
        <p className="text-lg mt-1">
          This may include location information provided by a mobile or other
          device interacting with our digital properties (including through
          beacon technologies), or associated with your IP address, where we are
          permitted by law to process this information. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Technical Data  Usage </p>
        <p className="text-lg mt-1">
          viewing, technical, and device data when you visit our digital
          properties whether hosted by findmyoptions.com LLC or third-party
          sites or platforms, or open emails we send, including your browser or
          device type, unique device identifier, and IP address.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">How We Collect Personal Information</p>
        <p className="text-lg mt-1">
          The following describes the different ways we might collect your
          personal information, whether from you directly or automatically when
          interacting with our digital properties.
        </p>
      </Typography>

      <Typography variant="custom" className="mt-8">
        <p className="text-lg mt-1">
          a. Personal Information You Share Directly   When Requesting to be
          Contacted by a Car Seller  If you request to be contacted by a car
          seller, both findmyoptions.com LLC and the seller may collect personal
          information provided by you through the text and chat functionalities
          that we provide and on the contact forms on our site, which may
          include but is not limited to your name, email address, postal code,
          and telephone number, so that the seller can contact you to consider a
          car purchase or request that you submit a dealer review.  When Using
          Our Site to Buy or Sell a Vehicle  If you are an individual user (and
          not acting on behalf of a car dealer) and you want to use our services
          to buy or sell a vehicle, we and/or our business partners may collect
          information provided by you in connection with that transaction, for
          example to create your vehicle listing, to verify vehicle ownership,
          to confirm funds availability, to process payment, to populate
          purchase and sale documents, and to provide user support. We may
          collect this information from entry forms on our site or third-party
          sites we integrate with, or from a picture of your ID that you upload.
          This information may include your name, address, email address,
          telephone number, driver's license number, photo, gender, date of
          birth, the last four digits of your social security number, bank name,
          and bank account and routing numbers.  When creating an account with
          findmyoptions.com; Subscribing  If you create an account with us or
          subscribe to receive specific information or services on our site, we
          will also collect registration information, such as name, email
          address, mailing address, telephone number, username and password. 
          Creating an account with us also gives you the ability to personalize
          your findmyoptions.com site experience. The benefits of registering
          will increase over time as we get to know you better and introduce new
          features. We encourage you to register so that you can experience
          everything that findmyoptions.com has to offer! 
        </p>
      </Typography>

      <Typography variant="custom" className="mt-8">
        <p className="text-lg mt-1">
          b. Automatic Collection of Personal Information  In addition to the
          personal information we may collect from you directly, we also collect
          data from your browser or device using tracking technologies (See VI.
          Technologies We Use to Automatically Collect Personal Information)
          that is considered personal information when you engage with our
          digital properties. We automatically collect certain browser or device
          technical data in order to deliver our services to you, and to help
          make sure our digital properties are functioning properly. This
          typically includes data about your browser or device's IP address,
          user agent string, your internet service provider or mobile carrier
          and the type of handheld or mobile device you use.
        </p>
      </Typography>

      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          Other Ways Personal Information is Collected
        </p>
        <p className="text-lg mt-1">
          a. Applications and Widgets for Social Media  We may display
          applications or widgets from social media providers that allow
          interaction or content sharing by their users. These widgets, such as
          the Facebook® "Share" or "Like" button, are visible to you on the page
          that you visit. This may allow the social media networks in which you
          participate to collect information about you, even when you do not
          explicitly activate the network's application or widget while on our
          digital property. Please visit the applicable social media network's
          privacy notice to better understand their data collection practices
          and the choices they make available to you. In addition to social
          media networks, our digital properties also contain references and
          links to third-party sites that may offer information of interest.
          This Privacy Notice does not apply to those sites, and we recommend
          reviewing those sites' privacy notices individually.
        </p>
      </Typography>

      <Typography variant="custom" className="mt-8">
        <p className="text-lg mt-1">
          b. Information Collected from Other Sources  We may process personal
          information from third-party sources to update or supplement the
          information that you provide or that we collect. For instance, we may
          collect vehicle registration information from public databases to
          support a service you have engaged us for. Other sources of
          information include third-party advertising partners, data providers
          or aggregators, and search information providers.  We use this
          information to help us maintain the accuracy of the information we
          collect, personalize your experience with the site, target our
          communications and advertisements so that we can inform you of
          products and services or other offers that may be of interest to you,
          provide private purchase and sale transaction services, prevent fraud,
          and for internal business analysis or other business purposes. To
          learn more about our advertising activities, we encourage you to
          review our Interest-Based Ads Policy , and our section on Targeted
          Advertising below.
        </p>
      </Typography>

      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold pt-2 pb-5">
          How We Use Your Personal Information  
        </p>
        <p className="text-lg mt-1">
          We may process your personal information in order to: 
        </p>
        <p className="text-lg mt-1">
          send information to car sellers to complete transactions per your
          instruction;
        </p>
        <p className="text-lg mt-1">process and collect your payments; </p>
        <p className="text-lg mt-1">
          send you promotional marketing material about us and our services;
        </p>
        <p className="text-lg mt-1">
          customize, analyze, adjust and improve the site;
        </p>
        <p className="text-lg mt-1">
          provide you with important administrative information regarding the
          site, such as changes to this Privacy Notice and our Terms of Use and
          other policies; 
        </p>
        <p className="text-lg mt-1">
          prevent fraud and other prohibited or illegal activities;
        </p>
        <p className="text-lg mt-1">
          comply with requests from law enforcement or relevant data protection
          agencies; provide technical and customer support; 
        </p>
        <p className="text-lg mt-1">
          seek your opinion or feedback on our services or industry questions; 
        </p>
        <p className="text-lg mt-1">
          facilitate access to our applications to which you have subscribed; 
        </p>
        <p className="text-lg mt-1">
          enforce our legal rights or comply with legal requirements; 
        </p>
        <p className="text-lg mt-1">
          provide improved website and product experience and communications;
          or, comply with a legal or regulatory obligation.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          With Whom We Share Your Personal Information and Why 
        </p>
        <p className="text-lg mt-1">
          Mainly to provide our services, we may share your personal information
          with the following entities: 
        </p>
        <p className="text-lg font-bold">Affiliates </p>
        <p className="text-lg mt-1">
          We may share information about you, including personal information,
          with our corporate affiliates for specific business purposes.  Car
          Sellers and Manufacturers/Requests 
        </p>
        <p className="text-lg mt-1">
          Upon your instruction, we may share your personal information with car
          sellers and manufacturers who use that information for purposes of
          marketing their own products or services to you directly. 
        </p>
        <p className="text-lg font-bold">Lead generation </p>
        <p className="text-lg mt-1">
          When you submit your e-mail address and/or phone number to a
          particular seller through the site, you agree to being contacted by
          the seller, including by phone at the number provided, text message,
          email, automatic telephone dialing system and/or an artificial or
          pre-recorded voice. 
        </p>
        <p className="text-lg font-bold">Vendors </p>
        <p className="text-lg mt-1">
          We may provide third-party vendors (such as market research firms,
          marketing partners, advertising agencies, and payment processing
          partners) access to your personal information to perform services on
          our behalf. We contractually require our vendors to protect and limit
          the use of such personal information solely for the purposes of
          providing the specified services on our behalf. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          Other Providers of Products and Services  
        </p>
        <p className="text-lg mt-1">
          Upon your direction, we may disclose your personal information to
          other third-party providers of products and services (for example
          financing providers). We contractually require such providers to
          protect your personal information and to limit the use of such
          information for the purposes you agreed to when submitting such
          personal information on our site. By submitting personal  information
          on our site in connection with receiving products and/or services from
          these providers, you acknowledge that your request to have your
          personal information transmitted to such providers means it will be
          subject to their privacy notices, and that you should contact them
          directly for more information concerning their personal information
          processing activities.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Legal Authorities </p>
        <p className="text-lg mt-1">
          We cooperate with legal authorities and may in some instances be
          required to disclose personal information in response to requests from
          law enforcement authorities, or in response to a subpoena or other
          legal process. We also share information about you if we believe we
          should in order to: (i) prevent a violation of the law; (ii) protect
          or defend our interests and our legal rights or property; (iii)
          protect the rights, interests, safety and security of users of the
          site or members of the public; (iv) protect against fraud or for risk
          management purposes; or (v) comply with other applicable law or legal
          process. We also may share your information in connection with a
          corporate transaction, such as a divestiture, merger, consolidation,
          or asset sale, and in the unlikely event of bankruptcy.  Your Privacy
          Rights and Choices below to learn about how you can exercise your
          privacy rights with findmyoptions.com LLC. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          Technologies We Use to Automatically Collect Personal Information 
        </p>
        <p className="text-lg mt-1">
          Along with companies we partner with, we employ pixel tags, cookies,
          java scripts, SDKs, local shared objects such as Flash (sometimes
          called “flash cookies”), and similar technology (“tracking
          technologies”) on our digital properties, and third-party digital
          properties we integrate with. These technologies may create unique
          advertising identifiers (e.g. cookie identifiers or mobile advertising
          identifiers) that reside on your browser or mobile device, and in some
          cases server-side. Tracking technologies may transmit data about your
          use of our digital properties, including which pages you interacted
          with, your vehicle search history, the advertisements shown to you or
          that you may have clicked on, other page view/click data, the date and
          time of your visit, all associated with your browser or mobile
          device's advertising identifier. These identifiers typically persist
          over time. You can set some cookie preferences through your device or
          browser settings but doing so may affect the functionality of the
          site. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-5 pb-5">
        <p className="text-lg mt-1">
          To learn more about how we employ these technologies and the choices
          you have concerning our processing of your personal information for
          digital advertising purposes, see VIII. Your Privacy Rights and
          Choices below.  Consistent with IV. How We Use Your Personal
          Information above, the purposes for which we use tracking technologies
          include:
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Processes</p>
        <p className="text-lg mt-1">
          Make digital properties work in the way that it is expected. For
          example, we use a cookie that tells us whether you have already signed
          up for an account.  Authentication, Security, and Compliance Prevent
          fraud, protect user data from unauthorized parties, and for compliance
          with legal requirements. For example, we use cookies to determine if a
          suspicious IP address (based on geography) is logged in through your
          account.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Preferences</p>
        <p className="text-lg mt-1">
          Track how our digital properties are used and remember preferences.
          For example, we monitor and store your browsing activity to customize
          your experience and better understand your vehicle shopping interests.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Notifications</p>
        <p className="text-lg mt-1">
          Allow notices of information or options that we think could improve
          the use of the site or app. For example, send out notifications of
          price drop in a vehicle that you previously showed interest in.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Advertising</p>
        <p className="text-lg mt-1">
          Intended to help us understand how visitors use our digital
          properties. For example, we use a cookie that tells us how our search
          suggestions correlate to your browsing while on the site or app.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Additional U.S. State Disclosures </p>
        <p className="text-lg mt-1">
          This section supplements the above by providing additional information
          about how we may process your personal information. Unless stated
          otherwise, all terms defined in our Privacy Notice have the same
          meaning below.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Sensitive Information</p>
        <p className="text-lg mt-1">
          In order to support the services we provide, we may process the
          following types of personal information, which are generally
          considered sensitive under certain U.S. state privacy laws:
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          Social security number, driver's license number, and passport number; 
        </p>
        <p className="text-lg mt-1">
          Credit/debit card number plus expiration date and security code (CVV),
          and financial account number and routing number;
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          Username and password; and  Precise geolocation data. 
        </p>
        <p className="text-lg mt-1">
          We may process this information for the purposes set out above (See
          IV. How We Use Your Personal Information), consistent with our
          obligations under applicable law.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">
          De-Identified or Aggregate Information 
        </p>
        <p className="text-lg mt-1">
          We may at times receive or process personal information to create data
          sets that can no longer reasonably be used to infer information about,
          or otherwise be linked to, a particular individual or household. Where
          we maintain de-identified or aggregate information, we will maintain
          and use the data in de-identified or aggregate form and not attempt to
          re-identify the data. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">a. California </p>
        <p className="text-lg mt-1">
          We share this notice of collection to comply with the California
          Consumer Privacy Act of 2018 (CCPA). Any terms defined in the CCPA
          have the same meaning when used in this Privacy Notice. 
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Sharing Personal Information </p>
        <p className="text-lg mt-1">
          findmyoptions.com LLC does not generally sell information as the term
          “sell” is traditionally understood. However, to the extent a “sale”
          under the CCPA is interpreted to include advertising technology
          activities such as those disclosed in this notice as a “sale,” we
          comply with applicable law as to such activity.
        </p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Information We Collect:   </p>
        <p className="text-lg mt-1">Categories </p>
        <p className="text-lg mt-1">Examples </p>
        <p className="text-lg mt-1">Do we sell?</p>
      </Typography>
      <Typography variant="custom" className="mt-8">
        <p className="text-lg font-bold">Identifiers</p>
        <p className="text-lg mt-1">
          Name, alias, postal address, unique personal identifier, online
          identifier, Internet Protocol (IP) address, email address, account
          name, social security number, driver's license number, or other
          similar identifiers 
        </p>
        <p className="text-lg mt-1">Yes</p>
        <p className="text-lg font-bold mt-8">Customer records information </p>
        <p className="text-lg mt-1">
          Name, signature, social security number, physical characteristics or
          description, address, telephone number, driver's license or state
          identification card number, bank account number, credit or debit card
          number, other financial information 
        </p>
        <p className="text-lg mt-1">Yes</p>
        <p className="text-lg mt-1">
          Characteristics of protected classifications under California or
          federal law Race, religion, sexual orientation, gender identity,
          gender expression, age No 
        </p>
      </Typography>
      <Typography variant="custom">
        <p className="text-lg font-bold mt-8">Commercial information  </p>
        <p className="text-lg mt-1">
          Records of personal property, products or services purchased,
          obtained, or considered, or other purchasing or consuming histories or
          tendencies 
        </p>
        <p className="text-lg mt-1">No</p>
        <p className="text-lg font-bold mt-8">
          Internet or other electronic network activity information  
        </p>
        <p className="text-lg mt-1">
          Browsing history, search history, and information regarding a
          consumer's interaction with an Internet website, application, or
          advertisement 
        </p>
        <p className="text-lg mt-1">Yes</p>
        <p className="text-lg mt-1">Geolocation data</p>
        <p className="text-lg mt-1">Physical location </p>
        <p className="text-lg mt-1">Yes</p>
        <p className="text-lg mt-1">
          Professional or employment-related information: 
        </p>
        <p className="text-lg mt-1">Current or past job history </p>
        <p className="text-lg mt-1">No</p>
        <p className="text-lg mt-1">Inferences</p>
        <p className="text-lg mt-1">
          Profile reflecting a person's preferences, characteristics, aptitudes 
        </p>
        <p className="text-lg mt-1">Yes</p>
        <p className="text-lg mt-1">
            The sources from which we collect personal information are described
          in sections II. How We Collect Personal Information and III. Other
          Ways Personal Information is Collected; the business or commercial
          purposes for which we use this information are described in sections
          We Use Your Personal Information and VI. Technologies We Use to
          Automatically Collect  Personal Information ; and the categories of
          third parties to whom we may disclose this information are described
          in section Who We Share Your Personal Information With and Why of this
          Privacy Notice. 
        </p>
        <p className="text-lg mt-1">b. Nevada </p>
        <p className="text-lg mt-1">
          Nevada law (NRS 603A.340) requires each business to establish a
          designated request address where Nevada consumers may submit requests
          directing the business not to sell certain kinds of personal
          information that the business has collected or will collect about the
          consumer. A sale under Nevada law is the exchange of personal
          information for monetary consideration by the business to a third
          party for the third party to license or sell the personal information
          to other third parties. If you are a Nevada consumer and wish to
          submit a request relating to our compliance with Nevada law, please
          contact us at support@findmyoptions.com 
        </p>
        <p className="text-lg font-bold mt-8">
          Your Privacy Rights and Choices 
        </p>
        <p className="text-lg mt-1">
          You have certain rights to the personal information that we process
          about you as part of the services we provide. Below is a detailed
          description of those rights, including additional state-specific
          rights. 
        </p>
        <p className="text-lg font-bold mt-8">Verification</p>
        <p className="text-lg mt-1">
          Consistent with applicable law, please understand that, depending on
          the type of request you submit, and to protect the confidentiality of
          yours and others' personal information, we will only complete your
          request when your identity has been verified. We will seek to match
          the information in your request to the personal information we
          maintain about you. As part of our verification process, we may ask
          you to: submit additional information, use identity verification
          services to assist, or, if you have set up an account on our website,
          to sign into your account as part of our identity verification
          process. 
        </p>

        <p className="text-lg font-bold mt-8">Right to an Authorized Agent</p>
        <p className="text-lg mt-1">
          In certain states, consumers may designate an authorized agent to
          exercise their privacy rights. You may designate an authorized agent
          to submit requests on your behalf. However, we may require written
          proof of the agent's permission to do so and verify your identity
          directly. 
        </p>

        <p className="text-lg font-bold mt-8">Right to Non-Discrimination </p>
        <p className="text-lg mt-1">
          You also have the right to not receive retaliatory or discriminatory
          treatment in connection with a request to exercise your rights.
          However, the exercising of the rights described below may result in a
          different price, rate, or quality/level of product or service where
          that difference is reasonably related to the impact the right has on
          our relationship with you or is otherwise permitted by law. 
        </p>

        <p className="text-lg font-bold mt-8">a. Your Privacy Rights </p>
        <p className="text-lg mt-1">
          You may have the right to exercise some or all the following rights: 
        </p>
        <p className="text-lg font-bold mt-8">Access/Right to Know </p>
        <p className="text-lg mt-1">
          Consistent with applicable law, you may have the right to confirm in a
          portable and (if technically feasible) readily usable form, after
          making a verifiable request whether we are processing your personal
          information and, in some cases, to obtain certain personalized details
          about the personal information we have collected about you,
          including: 
        </p>
        <p className="text-lg">
          The categories of personal information collected; 
        </p>
        <p className="text-lg ">
          The categories of sources of the personal information; 
        </p>
        <p className="text-lg ">
          The purposes for which the personal information were collected; 
        </p>
        <p className="text-lg ">
          The categories of personal information disclosed to third parties (if
          any), and the categories of recipients to whom the personal
          information were disclosed; 
        </p>
        <p className="text-lg ">
          The categories of personal information shared for targeted advertising
          or CCBA purposes(if any), and the categories of recipients to whom the
          data were disclosed for those purposes; and The categories of personal
          information sold (if any), and the categories of third parties to whom
          the data were sold. 
        </p>
        <p className="text-lg font-bold mt-8">Correction</p>
        <p className="text-lg mt-1">
          Consistent with applicable law, you may have the right after making a
          verifiable request to correct inaccurate personal information,
          considering the nature of the data itself and the processing
          activities it supports. 
        </p>
        <p className="text-lg font-bold mt-8">Deletion </p>
        <p className="text-lg mt-1">
          Consistent with applicable law, you may have the right, after making a
          verifiable request to have your personal information that is
          maintained by findmyoptions.com deleted. 
        </p>
        <p className="text-lg font-bold mt-8">Opt-Out Rights </p>
        <p className="text-lg mt-1">
          The right to direct findmyoptions.com LLC to not “sell” your personal
          information for monetary or other valuable consideration, or “share”
          your personal information for targeted advertising or Cross-Context
          Behavioral Advertising (“CCBA”) purposes. You may opt out of such
          processing by navigating to the findmyoptions.com Opt out form.  See
          Targeted Advertising for additional information about how we may
          process your personal information for marketing and advertising
          purposes. 
        </p>
        <p className="text-lg mt-1">FOR CALIFORNIA RESIDENTS </p>
        <p className="text-lg mt-1">“Shine the Light”   </p>
        <p className="text-lg mt-1">
          California residents that have an established business relationship
          with us have the right to know how their personal information is
          disclosed to third parties for their direct marketing purposes under
          California's “Shine the Light” law, or the right to opt out of such
          practices (Civ. Code § 1798.83).  Limit Use and/or Disclosure of
          Sensitive Personal Information  findmyoptions.com LLC does not create
          consumer profiles either for purposes of targeted advertising or for
          decision-making purposes that produce legal or similarly significant
          effects. We may, however, share your personal information for targeted
          advertising consistent with applicable law and this privacy notice.
          You may opt out of such processing by navigating to the
          findmyoptions.com LLC Opt out form. 
        </p>
        <p className="text-lg mt-1">
          FOR COLORADO, CONNECTICUT OR VIRGINIA RESIDENTS   
        </p>
        <p className="text-lg mt-1">
          Right to Opt Out for the Purposes of Profiling for Decisions Producing
          Legal or Similarly Significant Effect 
        </p>
        <p className="text-lg mt-1">
            findmyoptions.com LLC does not create consumer profiles either for
          purposes of targeted advertising or for decision-making purposes that
          produce legal or similarly significant effects. We may, however, share
          your personal information for targeted advertising consistent with
          applicable law and this privacy notice. You may opt out of such
          processing by navigating to the findmyoptions.com LLC Opt out form. 
        </p>

        <p className="text-lg font-bold mt-8">
          ORight to Appeal Privacy Rights Request   
        </p>
        <p className="text-lg mt-1">
          To appeal a refusal to take action on your request, please see the
          instructions in our response to your request or submit your appeal in
          writing by contacting privacy@findmyoptions.com LLC.com with the
          subject “Appeal of Consumer Privacy Rights Request.” 
        </p>
        <p className="text-lg font-bold mt-8">b. Choices </p>
        <p className="text-lg">
          To exercise your right to Know, Delete, Correct or Opt Out rights,
          please submit a request by visiting our My Privacy Rights page.  Or
          contact us with questions or requests regarding this Privacy Notice
          at:
        </p>
        <p className="text-lg ">Address: </p>
        <p className="text-lg ">findmyoptions.com LLC </p>
        <p className="text-lg">6844 Bardstown Rd #5178 </p>
        <p className="text-lg">Louisville, KY 40291 </p>
        <p className="text-lg ">support@findmyoptions.com </p>
        <p className="text-lg ">
          You may also opt out of any or all future marketing emails from us
          here or by clicking on the unsubscribe link we place at the footer of
          every email we send that is not in response to an action taken by the
          user. Please note that you cannot opt out of non-promotional emails,
          such as those about your account, transactions or servicing. 
        </p>

        <p className="text-lg font-bold mt-8">Targeted Advertising   </p>
        <p className="text-lg ">
           {" "}
          <span>
            Also referred to as “interest based,” “online behavioral,”
            “personalized” advertising, as well as “Cross-Context Behavioral
            Advertising,” targeted advertising is the serving of digital
            advertisements based on predictions generated over time from your
            visits across different websites, devices, mobile applications, as
            well as our own. We encourage you to consult our detailed policy on
            Interest Based Advertising here.  We may work with advertising
            technology partners who allow us to personalize our ads based on
            your browsing behavior on our digital properties. Many of these
            companies are participants of the Digital Advertising Alliance
            (“DAA”) and/or members of the Network Advertising Initiative
            (“NAI”). In addition to your right under your state's privacy law to
            opt out from targeted advertising by us and our own advertising
            partners, you can learn more about targeted ads provided by these
            companies as well as others we are not partnered with, and how to
            opt out of receiving certain targeted ads from them by visiting:
          </span>
           {" "}
          <span>
            <a href="https://www.aboutads.info/choices" target="_blank">
              {" "}
              https://www.aboutads.info/choices
            </a>
          </span>{" "}
          - For targeted ads from DAA participants on websites;{" "}
          <span className="text-blue-900">
            <a
              href="https://optout.networkadvertising.org/?c=1"
              target="_blank"
            >
              https://optout.networkadvertising.org/?c=1
            </a>
          </span>{" "}
          - For targeted ads from NAI members on websites and to learn more
          about how to opt out from receiving targeted ads on your other
          connected devices.  Opting out only means that the selected
          participants should no longer deliver certain targeted ads to the
          specific browser or device on which you made your request, it does not
          mean you will no longer receive any targeted content and/or ads from
          other advertising technology companies or on your other browsers or
          devices that have not been opted out.  We also partner with Amazon to
          deliver advertisements. You can opt out of delivery of targeted
          advertising to you by Amazon here. Please note that even if you opt
          out, you will continue to receive advertisements, but they will not be
          tailored to your specific interests. We may also display
          interest-based ads to you when you are using Facebook and other social
          media platforms or websites. 
        </p>
        <p className="text-lg font-bold mt-8">Publicly Posted Information </p>
        <p className="text-lg ">
          Your member profile will be publicly viewable and identifiable via
          your username. findmyoptions.com LLC will not share your email address
          with any other members or display it publicly. Please consider
          carefully before making any information public as you are solely
          responsible for any information you make public. Once you have posted
          information, you may not be able to edit or delete it.  If you choose
          to participate in our member-to-member communications programs, you
          may be contacted by other members, using us as an intermediary. All
          members have the option to turn off the member-to-member communication
          features at any time.  Information that you make available to us and
          others via social media networks, forums, blogs, list serves, chat
          rooms or similar functionality is public information that we or others
          may share or use in accordance with the law. 
        </p>
        <p className="text-lg  font-bold  mt-8">
          Storing and Retaining Your Personal Information 
        </p>

        <p className="text-lg">
          Your personal information is stored on servers in the U.S. Also, some
          of our service providers may store information in servers hosted in
          countries different from where you reside. As such, your personal
          information may be subject to the laws of other countries, where the
          data protection and other laws may not be as comprehensive as your
          country of residence.  Consistent with our recordkeeping policies and
          practices, we may retain your personal information so long as it is
          necessary to fulfill the purposes outlined in this Privacy Notice,
          unless a longer retention period is required by law. Once we no longer
          have a legitimate business reason to retain your personal information,
          it is either destroyed, aggregated or deidentified, however we may
          retain inactive archival copies consistent with applicable law. 
        </p>
        <p className="text-lg font-bold mt-8">
          Security - Steps we take to protect your personal information 
        </p>
        <p className="text-lg">
          We have implemented administrative, technical, personnel, and physical
          security measures designed to protect the personal information stored
          in our systems against loss, theft and unauthorized use, disclosure or
          modification. We also employ processes (such as password hashing,
          login auditing, and idle session termination, as appropriate) designed
          to protect against unauthorized access to your personal information.
          While we endeavor to create secure and reliable digital properties for
          users, the confidentiality of any communication or material
          transmitted to/from a findmyoptions.com LLC digital property, and the
          security of your personal information, cannot be guaranteed. We
          encourage you to take steps to protect your personal information
          online.
        </p>
        <p className="text-lg font-bold mt-8">International Transfer </p>
        <p className="text-lg">
          {" "}
          We are based in the U.S. and the information we collect is governed by
          U.S. law. If you are  accessing our digital properties from outside of
          the U.S., please be aware that information  collected may be
          transferred to, processed, stored, and used in the U.S. and other
          jurisdictions.  Data protection laws in the U.S. and other
          jurisdictions may be different from those of your  country of
          residence. Your use of this digital property or provision of any
          information therefore  constitutes your consent to the transfer to and
          from, processing, usage, sharing, and storage of  information about
          you in the U.S. and other jurisdictions as set out in this
        </p>
        <p className="text-lg font-bold mt-8"> Privacy Notice. Children </p>
        <p className="text-lg">
          findmyoptions.com LLC digital properties are intended for a general
          audience and are not  directed at children under (13) years of age. 
          We do not knowingly gather personal information (as defined by the
          U.S. Children’s Online Privacy Protection Act, or “COPPA”) in a manner
          not permitted by COPPA. If you are a parent or guardian and you
          believe we have collected information from your child in a manner not
          permitted by law, contact us at support@findmyoptions.com. We will
          remove the data to the extent required by applicable laws.  We do not
          knowingly “sell” the personal information of minors under 16 years old
          who are California residents without their affirmative authorization. 
          If you are a California resident under 18 years old and registered to
          use a findmyoptions.com LLC digital property, you can ask us to remove
          any content or information you have posted. To make a request, email
          us at the email address set out in “Contact Us” section with
          “California Under 18 Content Removal Request” in the subject line, and
          tell us what you want removed. We will make reasonable good faith
          efforts to remove the post from prospective public view, although we
          cannot ensure the complete or comprehensive removal of the content and
          may retain the content as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements. 
        </p>
        <p className="text-lg font-bold mt-8">
          Changes to this Privacy Notice {" "}
        </p>
        <p className="text-lg">
          We may occasionally update this Privacy Notice to reflect changes in
          our practices. When we post modifications to this Privacy Notice, we
          will revise the "Last Updated" date at the top of this page. The
          modified Privacy Notice will be effective immediately upon posting.
          Your continued use of our digital properties after the posting of the
          modified Privacy Notice constitutes your agreement to abide and be
          bound by it. We encourage you to periodically review this page for the
          latest information on our privacy practices. If you object to any
          modification, your sole recourse is to stop using the digital
          property.
        </p>
      </Typography>
    </div>
  );
}
