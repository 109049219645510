import React from "react";
import "../index.scss";
export default function User({ content }: any) {
  return (
    <div className="-mt-[2px] relative w-full px-5 md:px-7 ">
      <div className="flex items-center justify-end mb-[10px]">
        <div
          className={`break-normal flex flex-col px-[10px] md:px-[20px] py-2 md:pt-1 md:pb-5 max-w-[100%] min-h-[50px] md:min-h-[79px] justify-center chat-sender `}
        >
          <div className="flex justify-end text-[#070C84] font-bold text-[9px] md:text-[14px]">
            {"You"}
          </div>
          <div className="text-[10px] md:text-[15px]">{content}</div>
        </div>
      </div>
    </div>
  );
}
