import Header from "components/header";
import { Outlet } from "react-router-dom";
import UserDashboardSidebar from "./user-dashboard-sidebar";
import { useState } from "react";
import { AppFooter } from "components/footer/app-footer";
import useUserData from "modules/user/store/user-data/use-user-data";

export default function UserDashboardLayout() {
  useUserData();
  const [toggle, setToggle] = useState(true);

  return (
    <>
      <main className="min-h-dvh flex flex-col font-[Metropolis-SemiBold]" id="App" >
        <Header
          handleToggle={() => {
            setToggle((prev) => !prev);
          }}
          toggle={toggle}
        />
        <UserDashboardSidebar
          toggle={toggle}
          handleToggle={() => {
            setToggle((prev) => !prev);
          }}
          openSideBar={() => setToggle(true)}
        />
        <div
          className={`w-full px-4 pt-10 sm:px-6 lg:px-8 xl:px-12 ${
            toggle ? "lg:ps-64 xl:ps-[272px]" : "lg:ps-24 xl:ps-28"
          }`}
        >
          <Outlet />
        </div>
      </main>
      <footer className="w-full mt-10">
        <AppFooter />
      </footer>
    </>
  );
}
