// RMF - sayuj
import React, { useState, useEffect } from "react";
import CloseIcon from "../../../shared/components/CloseIcon";
import "./notification.scss";
const Notification = ({ setNotifyToggle, notifyToggle }) => {
  const [isUnmounting, setIsUnmounting] = useState(false);

  useEffect(() => {
    // Add slide out animation when unmounting
    if (isUnmounting) {
      const timer = setTimeout(() => {
        setIsUnmounting(false);
        setNotifyToggle(false);
      }, 500); // Adjust duration to match animation duration
      // Clean up timeout to avoid memory leaks
      return () => clearTimeout(timer);
    }
  }, [isUnmounting]);

  const handleScreenChange = (screen) => {
    setIsUnmounting(true);
    // setCurrScreen(screen);
  };

  const notifications = [
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
    {
      title: "Pending Integration",
      message: "Pending Integration",
    },
  ];

  return (
    <div
      className="absolute bg-gray-900 bg-opacity-50 w-full z-[200] chat-modal"
      style={{ fontFamily: "Metropolis-SemiBold" }}
    >
      <div
        className={`w-full min-h-screen animate__animated ${
          !isUnmounting ? "animate__slideInRight" : "animate__slideOutRight"
        }`}
      >
        <div className="flex justify-end min-h-screen ">
          <div className="w-full h-[100vh] rounded-none m-0 md:m-5 bg-[#fff] min-[768px]:w-[400px] min-[768px]:h-[550px] min-[768px]:rounded-[50px] min-[768px]:mt-32 min-[768px]:mr-8">
            <div className="flex justify-end mr-[9px] mt-[15px] md:mr-[39px] md:mt-[39px]">
              <div
                className="inline-block w-[8px] h-[8px] md:w-[17px] md:h-[17px] cursor-pointer"
                onClick={() => handleScreenChange()}
              >
                <CloseIcon stroke="#000" strokeWidth="4.028" />
              </div>
            </div>
            <div className="text-[26px] md:text-[25px] flex justify-center mb-[20px] font-semibold">
              Notifications
            </div>

            <div class="notification-container px-[25px] min-[768px]:h-[400px]">
              {notifications?.map((notifcation, idx) => (
                <React.Fragment key={idx}>
                  <div class="notification">
                    <div class="avatar">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/icon/fmo_avatar.svg"
                        }
                        alt="Avatar"
                      />
                    </div>
                    <div class="notification-content">
                      <h3 class="notification-title text-[#313131]-700">
                        {notifcation.title}
                      </h3>
                      <p class="notification-message text-justify">
                        {notifcation.message}
                      </p>
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
