import { Button, Card, CardBody, Image } from "@nextui-org/react";
import React, { useRef } from "react";
import { TextErrorHelper, Typography } from "components";
import { VehicleRepairSubscriptionPayload } from "modules/user/services/endpoints/repair/repair-agreement.type";
import { useSFUpdateOdometerStatus } from "modules/user/hooks/api/mutations/benefits/repair-agreement";
import { getUserId } from "utils/get-user-id";

interface Props {
  handleSubmit: () => void;
  vin?: any;
  OdometerVerification?:number 
}

export default function OdometerVerification({
  handleSubmit,
  vin,
  OdometerVerification
}: Props) {
  const [selectedFiles, setSelectedFiles] = React.useState<{
    file: File | null;
    error: boolean;
  }>({ file: null, error: false });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    mutateAsync: mutSFUpdateOdometerStatusAsync,
    isPending: isPendingToSFUpdateOdometerStatus,
    error:sfFileErrorMessage
  } = useSFUpdateOdometerStatus(getUserId() || 0, vin || "");

  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  

  const handleSubmitOrdometer = async () => {
    try{
    const formData = new FormData();
    if ( checkFileType(selectedFiles.file) === "other") {
      setSelectedFiles((p) => ({ ...p, error: true }));
      return;
    }
    selectedFiles.file && formData.append("odometerFile", selectedFiles.file);
    await mutSFUpdateOdometerStatusAsync(formData);
    handleSubmit()
  }catch(e){
    console.log(e)
  }
  };

  return (
    <div
      className="flex flex-col gap-8"
      // className="grid grid-cols-1 justify-items-center py-3 w-full h-full p-20 gap-16"
    >
      <div className="flex flex-col gap-7 items-center justify-center">
        <Typography
          variant="title"
          className="font-[Metropolis] text-2xl lg:text-3xl font-bold text-black text-center 
        bg-gradient-to-r from-blue-600 to-indigo-900 text-transparent bg-clip-text"
        >
          Odometer Verification
        </Typography>
        <div className=" w-full  flex justify-between ">
          <div></div>
          <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl"
            type="button"
            size="md"
            onClick={() => handleSubmit()}
          >
            <Typography className="font-bold" variant="body">
              {" "}
              View All
            </Typography>
          </Button>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center  gap-12">
        <Card
          className="min-w-[370px] max-w-[48rem] w-full  bg-no-repeat"
          style={{
            backgroundImage: "url(assets/images/header-background.png)",
          }}
        >
          <CardBody className="flex justify-center items-center mt-2 p-4 md:p-24">
            <Typography
              variant="custom"
              className=" font-[600] col-span-2 text-wrap mt-6"
            >
              Please contact support for help with oil and filter change
              inspection, the service center needs to be per authorized by FMO
              before you proceed. We can sometimes also offer discounts for this
              service. Once its approved please upload the results here.
            </Typography>
            {(selectedFiles.error || sfFileErrorMessage?.message) && (
              <div className="">
                <div className="flex flex-row gap-2 mt-8">
                  {/* <Image src="/assets/icons/error-outline.svg" alt="error" /> */}
                  <TextErrorHelper>{sfFileErrorMessage?.message? sfFileErrorMessage.message :"Please upload the document in the correct format."}</TextErrorHelper>
                </div>
              </div>
            )}
            <div className="flex w-full flex-col md:flex-row gap-2 mt-8 items-center justify-center md:justify-between">
              <label htmlFor="odameter-files" className="w-full md:w-[48%]">
                <Button
                  onClick={onHandleFileClick}
                  className={`bg-gradient-to-r w-full w-max-[12rem]  overflow-hidden whitespace-nowrap overflow-ellipsis from-[#018ffd] to-[#070c84] text-white text-lg  py-8 tracking-wide px-6 rounded-xl ${
                    selectedFiles.error &&
                    "border-3 border-[#f31260] text-[#f31260]"
                  } `}
                >
                  {(selectedFiles?.file?.name || (OdometerVerification == 0)) ? `File Uploaded` : "Upload"}
                </Button>

                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".pdf,.doc,.docx,image/*"
                  onChange={(e: any) =>
                    setSelectedFiles((p) => ({
                      ...p,
                      file: e?.target?.files[0],
                      error: false,
                    }))
                  }
                  style={{ display: "none" }}
                  id="odameter-files"
                />
              </label>

              <Button
                onClick={handleSubmitOrdometer}
                className="w-full md:w-[48%]  bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg py-8 tracking-wide px-6 rounded-xl "
                isLoading={isPendingToSFUpdateOdometerStatus}
                isDisabled={!selectedFiles?.file?.name || OdometerVerification === 0}
              >
                {OdometerVerification === 0 ? "Pending Review":"Submit"}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}


function checkFileType(file:File | null) {
  // Get the file extension
  if(!file){
    return "other"
  }
  let fileType = file.type;

  // Check if the file type is PDF
  if (fileType === 'application/pdf') {
      return 'pdf';
  }

  // Check if the file type is an image
  if (fileType.startsWith('image/')) {
      return 'image';
  }

  // If not PDF or image, consider it as other file types
  return 'other';
}

