import ListingLoopView from "modules/dealer/components/manage-listing/ListingLoopView";
import React, { useEffect, useState } from "react";
import { useGetVindataDecode } from "services";
import VinDataItem from "./vin-data-item";

interface VinDataProps {
  vinNumber: string;
}

export default function VinData({ vinNumber }: VinDataProps) {
  const { data } = useGetVindataDecode(vinNumber);
  const [title, setTitle] = useState("");

  const updateTitle = () => {
    const year =
      data?.message.find((item) => item.code === "ModelYear")?.value || "";
    const make =
      data?.message.find((item) => item.code === "Make")?.value || "";
    const model =
      data?.message.find((item) => item.code === "Model")?.value || "";

    setTitle(`${year} ${make} ${model}`);
  };

  useEffect(() => updateTitle(), [data]);

  return (
    <div className="w-full flex flex-col items-center gap-4 mb-36">
      <div className="text-2xl font-bold">{title}</div>
      <div className="font-bold text-lg"> VIN Data</div>
      <div className=" flex flex-col items-center w-full md:px-8 h-[750px] overflow-y-scroll">
        {data?.message?.length &&
          data.message
            .slice(5)
            .map(({ key, variable, value }: any, idx: number) => {
              return (
                <VinDataItem
                  key={key}
                  variable={variable}
                  idx={idx}
                  value={value}
                />
              );
            })}
      </div>
    </div>
  );
}
