import { http } from "services/http/service.http";
import { VinDataDecodeResponse } from "./vin-data.type";

class VinDataEndpoint {
  public async getVinDataDecodeAsync(
    vin: string
  ): Promise<VinDataDecodeResponse> {
    const res = await http.get(`VinData/DecodeVinData?vin=${vin}`);
    return res.data;
  }
}

export const vinDataEndpoint = new VinDataEndpoint();
