import { LinkedInIcon } from "assets/svgs/icons/linked-in-icon";

import { Typography } from "components";
import { Modal } from "modules/shared/components";
import PrivacyPolicy from "modules/shared/components/modal/PrivacyPolicy";
import TermsConditions from "modules/shared/components/modal/TermsConditions";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CookieBanner from "../cookie-banner/cookie-banner";
import { getUserId } from "utils/get-user-id";
import { WarrentyBenefic } from "pages/public/membership-advantage/repair-assistance/repair-assistance";
import "./footer.scss";
import { ResponsiveModal } from "modules/shared/components/modal/ResponsiveModalHeader";
//
const footerContent = [
  {
    title: "BUY",
    children: [
      {
        label: "New/Used Search",
        path: "/listing/search-car",
      },

      {
        label: "Nationwide Shipping Quotes",
        path: "/listing/tools/vehicle-shipping-quote",
      },
      {
        label: "DMV/Titling services",
        path: "/listing/private-party-services/titling-services",
      },
      {
        label: "Free Basic Vin Search",
        path: "/listing/tools/free-basic-vin-search",
      },
      {
        label: "Factory Installed Options",
        path: "/listing/factory-installed-options",
      },
      {
        label: "Vehicle History Reports",
        path: "/listing/vehicle-history-reports",
      },
      // {
      //   label: "Sell your Car Instant Quote",
      //   path: "/listing/private-party-services/sell-instant-quote",
      // },
      // {
      //   label: "Nationwide Shipping",
      //   path: "/listing/private-party-services/nationwide-shipping",
      // },
      // {
      //   label: "DMV/Titling ServicesLoan",
      //   path: "/listing/private-party-services/titling-services",
      // },
      // {
      //   label: "Loan or Lien on Title",
      //   path: "/listing/private-party-services/loan-and-lien",
      // },
    ],
  },
  {
    title: "SELL",
    children: [
      {
        label: "List Your Car For Sale",
        path: "/listing/finance/list-your-car-for-sale",
      },
      {
        label: "Sell Your Car Instant Quote",
        path: "/listing/private-party-services/sell-instant-quote",
      },
      
      {
        label: "Loan or Lien on Title",
        path: "/listing/private-party-services/loan-and-lien",
      },
      {
        label: "Dealer Services",
        subLink: [
          {
            label: "What We Do",
            path: "/listing/dealer-services/what-we-do",
          },
          // {
          //   label: "Pricing",
          //   path: "/listing/dealer-services/dealer-services-pricing",
          // },

          {
            label: "How to Become Dealer",
            path: "/listing/dealer-services/become-a-dealer",
          },
        ],
      },

      // {
      //   label: "Used Car Warranties",
      //   path: "/listing/membership-advantage/used-car-warranty",
      // },
      // {
      //   label: "Repair/Service Shops",
      //   path: "/listing/membership-advantage/shop-partnerships",
      // },
      // {
      //   label: "FMO Membership",
      //   path: "/listing/membership-advantage/fmo-membership",
      // },
      // {
      //   label: "Giving Back",
      //   path: "/listing/membership-advantage/giving-back",
      // },
    ],
  },
  //
  {
    title: "FINANCE",
    children: [
      {
        label: "Bank/Lender Financing ",
        path: "/listing/finance/buy-here-pay-here-financing",
      },
      {
        label: "In House Financing",
        path: "/listing/finance/traditional-financing",
      },
      { label: "Get Pre Approved ", path: "/listing/finance/pre-approval" },
      // {
      //   label: "How to Become a Dealer ",
      //   path: "/listing/dealer-services/become-a-dealer",
      // },
      
      {
        label: "Auto Loan and Lease Calculator",
        path: "/listing/tools/auto-loan-lease-calculator",
      },
    ],
  },
  {
    title: "COMPANY INFORMATION",
    children: [
      { label: "About Us ", path: "/listing/about-us" },
      { label: "Contact Us", path: "/listing/contact-us" },
      { label: "Careers ", path: "/listing/careers" },
    ],
  },

  // {
  //   title: "TOOLS",
  //   children: [
  //     {
  //       label: "Free Basic VIN Search ",
  //       path: "/listing/tools/free-basic-vin-search",
  //     },
  //     {
  //       label: "Factory Installed Options",
  //       path: "/listing/factory-installed-options",
  //     },
  //     {
  //       label: "Vehicle History Reports ",
  //       path: "/listing/vehicle-history-reports",
  //     },
  //     {
  //       label: "Auto Loan and Lease Calculator ",
  //       path: "/listing/tools/auto-loan-lease-calculator",
  //     },
  //   ],
  // },
  {
    title: "PARTNER WITH US",
    children: [
      {
        label: "For Dealers ",
        path: "/listing/dealer-services/what-we-do",
      },
      { label: "For Advertisers", path: "/listing/partner/advertisers" },
      { label: "For Investors ", path: "/listing/partner/investors" },
      { label: "Affiliate Program ", path: "/listing/partner/affiliates" },
    ],
  },
];
export const PublicFooter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const policy = searchParams.get("policy");
  const [currentPopupValue, setCurrentPopupValue] = useState("");
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("cookiePreferences") ? false : true
  );
  const navigate = useNavigate();
  const location = useLocation();

  const handlePreferences = (preference: number) => {
    const cookiePreferences = {
      user: getUserId(),
      preference,
    };
    localStorage.setItem(
      "cookiePreferences",
      JSON.stringify(cookiePreferences)
    );
    setShowBanner(false);
  };

  const redirectToExternalSite = (url: string) => {
    // Open the URL in a new tab/window
    window.open(url, "_blank");
  };

  useEffect(() => {
    // Check if cookiePreferences exists in localStorage
    const storedPreferences = localStorage.getItem("cookiePreferences");
    storedPreferences ? setShowBanner(false) : setShowBanner(true);
  }, []);

  useEffect(() => {
    if (policy) {
      setCurrentPopupValue(
        policy === "terms"
          ? "terms-condition"
          : policy === "privacy"
          ? "privacy-policy"
          : ""
      );
    }
  }, [policy]);
  const handleRemoveParam = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("policy");

    setSearchParams(newSearchParams); // Update component state (optional)
    // navigate({ search: newSearchParams.toString(), replace: true });
  };

  const handleAddParam = (paramName: string, paramValue: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.append(paramName, paramValue);
    setSearchParams(newSearchParams); // Update component state (optional)
    navigate({ search: newSearchParams.toString() });
  };

  return (
    <div className="w-full flex flex-col items-center footer-public">
      {location.pathname ===
        "/listing/membership-advantage/used-car-warranty" && (
        <div className="flex flex-col items-center ">
          <div className="warrenty-third-sec py-2 flex flex-col  px-4 pt-4 sm:px-6 md:px-8 lg:px-[60px] xl:px-[150px] 2xl:px-[250px]">
            <Typography
              variant="custom"
              className="font-[Metropolis] text-xl font-[700] md:text-4xl mb-4 mt-4 text-center text-white"
            >
              Benefits of Choosing Us
            </Typography>

            <div className=" m-auto grid grid-cols-1 md:grid-cols-2 justify-items-center border-none shadow-none">
              {WarrentyBenefic.map((item, i) => {
                return (
                  <div
                    className={` flex items-center justify-center gap-4 border-2 border-white w-[98%] rounded-3xl p-5 my-2 ml-0 mr-0 ${
                      i % 2 ? "md:ml-2" : "md:mr-2"
                    }`}
                    key={i}
                  >
                    {item.icon}
                    <Typography
                      variant="custom"
                      className="text-[12px] md:text-[14px] font-[500] text-white mt-2 md:mt-4 "
                    >
                      {item.label}
                      <br />
                      {item.content}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center ">
        <div className="grid md:grid-cols-3 pt-10 w-full pt-16 pb-12 md:gap-x-6 lg:gap-x-32 xl:gap-x-48">
          {footerContent.map(({ title, children }, idx) => {
            return (
              <div className="row-span-1  py-2 " key={idx}>
                <div>
                  <Typography
                    variant="custom"
                    className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-sm font-[700] md:mt-5 md:text-xs lg:text-sm text-center md:text-left"
                  >
                    {title}
                  </Typography>
                  {children.map(({ label, path, subLink }, idx) => {
                    return (
                      <>
                        <Typography
                          variant="custom"
                          className="text-[#2F2F2F] text-sm  font-[600] md:mt-2 text-center md:text-left w-full  md:text-xs lg:text-sm "
                          key={idx}
                        >
                          <p
                            className="cursor-pointer"
                            onClick={() => {
                              if (path) {
                                window.scroll(0, 0);
                                navigate(path);
                              }
                            }}
                          >
                            {label}
                          </p>
                        </Typography>
                        {subLink &&
                          subLink.map(({ label, path }, indn) => {
                            return (
                              <ul className="list-disc list-inside ml-2 ">
                                <li className="text-[#2F2F2F] text-sm  font-[600] md:mt-2 text-center md:text-left w-full  md:text-xs lg:text-sm ">
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (path) {
                                        window.scroll(0, 0);
                                        navigate(path);
                                      }
                                    }}
                                  >
                                    {label}
                                  </span>
                                </li>
                              </ul>
                            );
                          })}
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-center w-full">
        <div className="md:flex flex-row items-center justify-center gap-3 mt-2">
          <div className="flex gap-3 items-center">
            <img
              src="/assets/icons/fb-footer.svg"
              alt="facebook"
              height={45}
              width={45}
              className="w-[45px] md:w-[30px]  lg:w-[45px] cursor-pointer"
              onClick={() =>
                redirectToExternalSite("https://www.facebook.com/findmyoptions")
              }
            />
            <img
              src="/assets/icons/insta-footer.svg"
              alt="insta"
              height={45}
              width={45}
              className="w-[45px] md:w-[30px]  lg:w-[45px] cursor-pointer"
              onClick={() =>
                redirectToExternalSite(
                  "https://www.instagram.com/findmyoptions/"
                )
              }
            />
            <img
              src="/assets/icons/x-footer.svg"
              alt="x"
              height={45}
              width={45}
              className="w-[45px] md:w-[30px]  lg:w-[45px] cursor-pointer"
              onClick={() =>
                redirectToExternalSite("https://twitter.com/findmyoptions")
              }
            />
            <LinkedInIcon
              className="w-[45px] md:w-[30px]  lg:w-[45px] cursor-pointer"
              onClick={() =>
                redirectToExternalSite(
                  "https://www.linkedin.com/company/findmyoptions"
                )
              }
            />
            <img
              src="/assets/icons/youtube-footer.svg"
              alt="youtube"
              height={45}
              width={45}
              className="w-[45px] md:w-[30px]  lg:w-[45px] cursor-pointer"
              onClick={() =>
                redirectToExternalSite("https://www.youtube.com/@findmyoptions")
              }
            />
          </div>

          <div className="flex gap-1 md:gap-3 mt-5 md:mt-0">
            <img
              src="/assets/images/app-store-footer.png"
              alt="app-store"
              height={45}
              className="w-[135px] md:h-[30px] md:w-[90px] lg:h-[45px] lg:w-[135px] cursor-pointer"
            />
            <img
              src="/assets/images/google-play-footer.png"
              alt="google-play"
              height={45}
              className="w-[135px] md:h-[30px] md:w-[90px] lg:h-[45px] lg:w-[135px] cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div>
        <div
          className={`grid grid-cols-1 px-8 xl:px-[150px]  2xl:px-[250px] mt-5
        `}
        >
          <div className=" rounded-3xl p-5 mb-10 border-[#018FFD] border-1">
            <Typography
              variant="custom"
              className="text-md md:text-[19px] font-[600] text-[#018FFD] text-center md:text-left "
            >
              Footnotes
            </Typography>
            <Typography
              variant="custom"
              className="text-[12px] md:text-[14px] font-[500] text-black mt-2 md:mt-4 text-start"
            >
              <span className="text-[14px] md:text-[16px] font-[600]">
                Artificial Intelligence (AI) Disclaimer:
              </span>{" "}
              This website utilizes Artificial Intelligence (AI) technology to
              provide informative vehicle information and data. However, the
              final decision-making process remains with the user.
            </Typography>
            <Typography
              variant="custom"
              className="text-[12px] md:text-[14px] font-[500] text-black mt-2 md:mt-4 text-start"
            >
              <span className="text-[14px] md:text-[16px] font-[600]">
                Data Accuracy:{" "}
              </span>
              The information contained on this website, while intended to be
              accurate, is provided by from a combination of, user inputted
              information, findmyoptions.com LLC's proprietary databases, and
              AI-powered algorithms. While we strive for accuracy, the
              information may not be exhaustive or error-free. It is recommended
              that users independently verify any information from our website
              before making any decisions based on it. The final decision-making
              process remains with the end user.
            </Typography>
            <Typography
              variant="custom"
              className="text-[12px] md:text-[14px] font-[500] text-black mt-2 md:mt-4 text-start pb-5"
            >
              <span className="text-[14px] md:text-[16px] font-[600]">
                {" "}
                Copyright Notice:{" "}
              </span>
              All content on this website, including text, graphics, logos, and
              images, is the property of findmyoptions.com LLC and is protected
              by copyright laws. Unauthorized use, reproduction, or distribution
              of any of this content is strictly prohibited.
            </Typography>
          </div>
        </div>
        <div className=" bg-white">
          <div
            className={`flex flex-col bg-gradient-to-r from-[#018ffd] to-[#070c84] py-10 px-8 xl:px-[155px] 2xl:px-[260px]  ${
              !showBanner ? "pb-28" : ""
            }`}
          >
            <div className="grid md:grid-cols-6 gap-2 md:gap-4 grid-cols-1 justify-items-center md:justify-items-stretch">
              <div className="col-span-3">
                <Typography
                  variant="custom"
                  className="text-white text-xs lg:text-[16px] font-bold"
                >
                  © 2024 findmyoptions.com LLC, All Rights Reserved
                </Typography>
              </div>
              <div className="col-span-3 justify-end">
                <div className="flex flex-col md:flex-row gap-6 justify-center items-center md:justify-end md:items-end text-center md:text-center pt-4 md:pt-0">
                  <Typography
                    variant="custom"
                    className="text-white text-xs lg:text-[16px] font-[600] cursor-pointer"
                  >
                    <p
                      onClick={() => {
                        setCurrentPopupValue("privacy-policy");
                        handleAddParam("policy", "privacy");
                      }}
                    >
                      Privacy Policy
                    </p>
                  </Typography>
                  <Typography
                    variant="custom"
                    className="text-white text-xs lg:text-[16px] font-[600] cursor-pointer"
                  >
                    <p
                      onClick={() => {
                        setCurrentPopupValue("terms-condition");
                        handleAddParam("policy", "terms");
                      }}
                    >
                      Terms of Use
                    </p>
                  </Typography>

                  <Typography
                    variant="custom"
                    className="text-white text-xs lg:text-[16px] font-[600] cursor-pointer"
                  >
                    <p
                    // onClick={() =>{ setCurrentPopupValue("terms-condition"); handleAddParam("policy","terms")}}
                    >
                      Do Not Sell My Information
                    </p>
                  </Typography>
                </div>
              </div>
            </div>
            {showBanner && (
              <div className="fixed z-10 bottom-0 left-0 w-full bg-gradient-to-r from-[#018ffd] to-[#070c84] p-5 pb-10">
                <CookieBanner onCallback={handlePreferences} />
              </div>
            )}
          </div>
        </div>

        {currentPopupValue && (
          <ResponsiveModal
            isOpen={true}
            onClose={() => {
              setCurrentPopupValue("");
              handleRemoveParam();
            }}
            ContentComponent={getComponent(currentPopupValue)}
            headerTitle={
              currentPopupValue === "privacy-policy"
                ? "Privacy Notice"
                : "Terms and Conditions"
            }
            children={<></>}
          />
        )}
      </div>
    </div>
  );
};

const getComponent = (currentButtonValue: string) => {
  switch (currentButtonValue) {
    case "privacy-policy":
      return <PrivacyPolicy />;
    case "terms-condition":
      return <TermsConditions />;
  }
};
