import { Typography } from "components";
import React from "react";
import checkIcon from "../../careers/assets/svg/check-icon.svg";

export default function ShopPartnershipsPage() {
  return (
    <div>
      <div className="md:p-4 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            Repair/Service Shop Partnerships{" "}
          </p>
          <p className=" text-2xl font-bold pt-5">
            Build Your Business & Gain Trust: Become a findmyoptions.com Trusted
            Service Partner
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-center">
          <p className="text-xl">
            <span className="font-semibold">
              {" "}
              Expand your reach, connect with new customers, and establish your
              business as a trusted auto repair
            </span>{" "}
            solution by partnering with findmyoptions.com! Our platform boasts a
            rapidly growing community of{" "}
            <span className="font-semibold">
              informed and engaged car owners{" "}
            </span>
            seeking reliable service and repair advice.
          </p>
        </Typography>

        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Why Partner with findmyoptions.com?
          </p>

          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Target Qualified Leads:{" "}
              </span>
              Gain access to a pre-qualified pool of potential customers
              actively seeking local repair services.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Increased Visibility:{" "}
              </span>
              Get your business listed on our platform,{" "}
              <span className="font-semibold">
                enhancing your online presence
              </span>{" "}
              and attracting new clients.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Build Trust & Credibility:{" "}
              </span>
              Become a <span className="font-semibold">"Trusted Partner"</span>{" "}
              on our platform, fostering trust and confidence with potential
              customers
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Cost-Effective Marketing:{" "}
              </span>
              Reach a wider audience for a low monthly fee of only $0.99, making
              it a budget-friendly marketing solution
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Streamlined Referral System:{" "}
              </span>
              We handle the customer connection process,{" "}
              <span className="font-semibold">
                referring members directly to your business
              </span>{" "}
              based on their specific needs and location.
            </p>
          </div>
        </Typography>

        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Benefits of Our Partnership:
          </p>

          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                {" "}
                Increased Customer Base:{" "}
              </span>
              Gain access to a{" "}
              <span className="font-semibold">
                larger pool of potential customers
              </span>{" "}
              actively seeking your services.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Positive Reputation Management:{" "}
              </span>
              Our rigorous vetting process ensures only{" "}
              <span className="font-semibold">
                reputable and reliable businesses
              </span>{" "}
              are listed, benefiting your reputation by association.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Efficient Lead Generation:{" "}
              </span>
              Save time and resources on traditional marketing efforts by
              leveraging our{" "}
              <span className="font-semibold">established member base.</span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Simple and Affordable:{" "}
              </span>
              Our <span className="font-semibold">low monthly fee</span> makes
              partnering with us an{" "}
              <span className="font-semibold">
                accessible and cost-effective
              </span>{" "}
              way to grow your business.
            </p>
          </div>
        </Typography>

        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Become a Partner Today!
          </p>

          <p className="text-xl">
            Join our network of trusted service partners and{" "}
            <span className="font-semibold">
              take your business to the next level.
            </span>{" "}
            Partnering with findmyoptions.com offers a
            <span className="font-semibold"> cost-effective and efficient</span>{" "}
            way to connect with potential customers, build trust, and{" "}
            <span className="font-semibold">
              grow your local automotive repair business.
            </span>
          </p>
          <p className="mt-10 mb-5 text-2xl font-bold bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text">
            Here's how to get started:{" "}
          </p>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Contact Us:{" "}
              </span>
              Reach out to our team at{" "}
              <span
                className="cursor-pointer text-blue-900 font-semibold underline"
                onClick={() => {
                  window.location.href = "mailto:support@findmyoptions.com";
                }}
              >
                support@findmyoptions.com
              </span>{" "}
              to discuss the partnership and learn more about the benefits.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Complete the Application:{" "}
              </span>
              Submit a simple application form outlining your business details
              and qualifications.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Vetting Process:{" "}
              </span>
              We will review your application and conduct a brief vetting
              process to ensure your business meets our standards.
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-semibold text-black bg-clip-text">
                Become a Partner:{" "}
              </span>
              Once approved, you will gain access to our platform and start
              receiving referrals from our members.
            </p>
          </div>
        </Typography>

        <Typography variant="custom" className="mt-10 mb-10 text-wrap">
          <p className="text-xl mt-2 font-bold">
            Partner with findmyoptions.com and build a lasting relationship with
            a reliable and engaged customer base!
          </p>
        </Typography>
      </div>
    </div>
  );
}
