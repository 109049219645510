import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AddCardMethodPayload,
  PaymentCardInfo,
} from "services/endpoints/shipping-quotes/shipping.type";
import Form from "components/ui/form";
import { AddPaymentValidationSchema } from "pages/shipping-quotes/new-order/new-order.validation";
import Input from "components/ui/input";
import { Button } from "@nextui-org/react";

interface Props {
  showmodal?: boolean;
  handleNext?: (data: AddCardMethodPayload) => void;
  savedMethod?: PaymentCardInfo | null;
  payAmmount?: string;
  isPending?: boolean;
}

const AddPaymentForm: React.FC<Props> = ({
  handleNext,
  savedMethod,
  payAmmount,
  isPending,
}) => {
  
  const handleSubmit = (e: any) => {
    handleNext && handleNext(e);
  };
  return (
    <div className="m-auto  max-w-screen-sm px-4 ">
      <div className="bg-white rounded-xl p-6">
        <Form
          id="new-order-shipping-form"
          schema={AddPaymentValidationSchema}
          onSubmit={handleSubmit}
          className="px-10"
        >
          {({ formState: { errors, defaultValues }, register, getValues }) => {
            return (
              <>
                {[
                  { id: "name", label: "Name on card" },
                  { id: "cardNumber", label: "Card Number" },
                ].map(({ id, label }) => {
                  return (
                    <div className="mb-4" key={id}>
                      <label
                        htmlFor={id}
                        className="text-xs block mb-2 font-bold"
                      >
                        {label}
                      </label>
                      <Input
                        variant="form"
                        size="compact"
                        errorMessage={
                          errors[id as keyof typeof errors]?.message
                        }
                        type="text"
                        id={id}
                        {...register(id as any)}
                        classNames={{
                          input:
                            "font-bold  w-full focus:outline-none text-center",
                          inputWrapper: !!errors[id as keyof typeof errors]
                            ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                            : "",
                        }}
                      />
                    </div>
                  );
                })}
                <div className="flex flex-wrap -mx-2 mb-4">
                  {[
                    {
                      id: "expiryDate",
                      label: "Expiry Date",
                      style: "w-full md:w-1/2 px-2 mb-4 md:mb-0",
                      placeholder: "MM/YYYY",
                      type:"text"
                    },
                    {
                      id: "cvv",
                      label: "CVV",
                      style: "w-full md:w-1/2 px-2",
                      placeholder: "",
                      type:"password"
                    },
                  ].map(({ id, label, style, placeholder,type }) => {
                    return (
                      <div className={style} key={id}>
                        <label
                          htmlFor={id}
                          className="text-xs block mb-2 font-bold"
                        >
                          {label}
                        </label>

                        <Input
                          variant="form"
                          size="compact"
                          errorMessage={
                            errors[id as keyof typeof errors]?.message
                          }
                          type={type}
                          placeholder={placeholder}
                          id={id}
                          {...register(id as any)}
                          classNames={{
                            input:
                              "font-bold  w-full focus:outline-none text-center",
                            inputWrapper: !!errors[id as keyof typeof errors]
                              ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                              : "",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className="mb-4">
                  <Button
                    startContent={"Xx"}
                    type="submit"
                    isLoading={isPending}
                    className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl "
                  >
                    Add Card
                  </Button>
                </div>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
};

export default AddPaymentForm;
function addSpaceEveryFourChars(input: string) {
  let output = "";
  for (let i = 0; i < input.length; i++) {
    if (i > 0 && i % 4 === 0) {
      output += " ";
    }
    output += input.charAt(i);
  }
  return output;
}
