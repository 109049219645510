import { SVGProps } from "react";

export const GarageIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.4609 8.74935C28.1693 7.87435 27.2943 7.29102 26.4193 7.29102H10.3776C9.35677 7.29102 8.6276 7.87435 8.33594 8.74935L5.27344 17.4993V29.166C5.27344 29.8952 6.0026 30.6243 6.73177 30.6243H8.1901C9.0651 30.6243 9.64844 29.8952 9.64844 29.166V27.7077H27.1484V29.166C27.1484 29.8952 27.8776 30.6243 28.6068 30.6243H30.0651C30.7943 30.6243 31.5234 29.8952 31.5234 29.166V17.4993L28.4609 8.74935ZM10.8151 10.2077H25.8359L27.4401 14.5827H9.35677L10.8151 10.2077ZM28.6068 24.791H8.1901V17.4993H28.6068V24.791ZM11.8359 18.9577C13.0026 18.9577 14.0234 19.9785 14.0234 21.1452C14.0234 22.3118 13.0026 23.3327 11.8359 23.3327C10.6693 23.3327 9.64844 22.3118 9.64844 21.1452C9.64844 19.9785 10.6693 18.9577 11.8359 18.9577ZM24.9609 18.9577C26.1276 18.9577 27.1484 19.9785 27.1484 21.1452C27.1484 22.3118 26.1276 23.3327 24.9609 23.3327C23.7943 23.3327 22.7734 22.3118 22.7734 21.1452C22.7734 19.9785 23.7943 18.9577 24.9609 18.9577Z"
      fill="white"
    />
  </svg>
);
