import React, { useState, FormEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import qs from "query-string";
import FMO_logo from "./assets/images/FMO_logo.png";
import eye_icon from "./assets/images/eye_icon.png";
import question_icon from "./assets/images/question_icon.png";
// import './Reset.css';
import Input from "components/ui/input";
import PassChange from "./PassChange";
import { Button } from "@nextui-org/react";
import { env } from "config/env";
interface BlobProps {
  position: string;
}

const Blob: React.FC<BlobProps> = ({ position }) => {
  return <div className={`blob ${position}`}></div>;
};
const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = qs.parse(location.search);
  const email = queryParams.email as string;
  const emailToken = queryParams.code as string;

  const [showPass, setShowPass] = useState(false);
  const navigateToPass = () => {
    setShowPass(true);
  };

  const login = () => {
    navigate("/");
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validatePassword = (password: string): boolean => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not Match.");
      return;
    }
    if (!validatePassword(newPassword)) {
      setError(
        "Password must contain at least 1 uppercase, 1 lowercase, 1 special character, and 1 number."
      );
      return;
    }
    setLoading(!isLoading);
    try {
      await axios
        .post(`${env.BASE_API_URL}User/reset-email/`, {
          email,
          emailToken,
          newPassword,
          confirmPassword,
        })
        .then(() => {
          setLoading(!isLoading);
          setSuccess(!isSuccess);
        });
    } catch (error) {
      setError("An error occurred while resetting the password.");
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Blob position="left-top" />
        <Blob position="right-bottom" />
      </div>

      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-[15px] border border-[1.5px] border-[rgba(1,143,253,1)] flex justify-center mt-[55px] items-center p-[50px_60px]">
        <div>
          <center>
            <img
              className="h-[70px] w-[150px] cursor-pointer"
              src={FMO_logo}
              alt=""
              onClick={() => navigate("/")}
            />
          </center>
          {!isSuccess ? (
            <div>
              <h1 className="font-semibold text-center text-[25px] px-[20px] pt-[10px] pb-[40px]">
                Reset Password
              </h1>
              {error && (
                <div className="flex items-center break-words justify-center w-[650px] ml-[-62px] text-center h-[38px] text-[12px] text-red-600 mb-[22px] font-[Metropolis-SemiBold]">
                  {" "}
                  <img
                    className="mb-[-15px] pr-[2px] h-[18px] ml-[60px] w-[18px] mt-[-18px]"
                    src={question_icon}
                    alt=""
                  />
                  {error}
                </div>
              )}
              <div className="relative mb-4 top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] text-center font-[Metropolis-SemiBold] ">
                <label>
                  <Input
                    variant="form"
                    startContent={<div className="pl-0"></div>}
                    endContent={
                      <img
                        className="absolute flex items-center justify-center text-gray-300 right-[25px] top-[15px] h-[25px] w-[25px] cursor-pointer"
                        src={eye_icon}
                        onClick={togglePasswordVisibility}
                        alt=""
                      />
                    }
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    classNames={{
                      input: "data-[has-start-content=false]:ps-8",
                      inputWrapper: "",
                    }}
                  />
                </label>
              </div>
              <div className="relative mb-4 top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] text-center font-[Metropolis-SemiBold] ">
                <label>
                  <Input
                    variant="form"
                    startContent={<div className="pl-0"></div>}
                    endContent={
                      <img
                        className="absolute flex items-center justify-center text-gray-300 right-[25px] top-[15px] h-[25px] w-[25px] cursor-pointer"
                        src={eye_icon}
                        onClick={togglePasswordVisibility}
                        alt=""
                      />
                    }
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    classNames={{
                      input: "data-[has-start-content=false]:ps-8",
                      inputWrapper: "",
                    }}
                  />
                </label>
              </div>
              <div className="w-[500px] leading-[20px] text-center ml-[25px]">
                <h5 className="font-[Metropolis-SemiBold] mt-[-25px] text-[14px] text-blue-600 break-words">
                  At least 8 Characters with 1 Uppercase, 1 Lowercase, 1 Number
                  and 1 Special Characters is Required
                </h5>
              </div>
              <Button
                className="flex items-center justify-center m-auto w-[270px] h-[45px] text-white bg-gradient-to-r from-blue-600 to-indigo-700 rounded-[9px] gap-[12px] text-[15px] leading-[27px] mt-[40px] font-[Metropolis-SemiBold] cursor-pointer"
                type="button"
                onClick={handleSubmit}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </div>
          ) : (
            <Button
              className="flex items-center justify-center m-auto w-[270px] h-[45px] text-white bg-gradient-to-r from-blue-600 to-indigo-700 rounded-[9px] gap-[12px] text-[15px] leading-[27px] mt-[40px] font-[Metropolis-SemiBold] cursor-pointer"
              type="button"
              onClick={login}
            >
              Back to Landing Page
            </Button>
          )}
          {showPass && (
            <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-black bg-opacity-50 z-[9999]">
              <PassChange
                setCurrScreen={() => setShowPass(false)}
                handleScreenChange={() => setShowPass(false)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
