import * as React from "react";

import CloseIcon from "../CloseIcon";
import "./modal.css";

interface PayModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  headerTitle?: string;
 
}

export const PayModal: React.FC<PayModalProps> = ({
  isOpen,
  onClose,
  headerTitle,children
}) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div style={{zIndex:999}} className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50 dark:bg-opacity-60 md:inset-0">
          <div className="relative p-4 w-full max-w-4xl max-h-full ">
            <div className="modal-border-radius relative bg-white shadow">
                <button
                  type="button"
                  className="ms-auto absolute right-8 top-8  dark:hover:text-white w-4 h-4"
                  onClick={closeModal}
                  tabIndex={-1}
                >
                  <CloseIcon />
                </button>
              <div className="flex items-center justify-center p-8 md:p-5pb-10">
              {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
