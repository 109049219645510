import { Card } from "@nextui-org/react";
import { WhiteCheckTick } from "../assets/white-check-tick.icon";
import { Typography } from "components";

interface CardProp {
  card: { id: string, title: string; description?:string};
}

const LabelInfoCardGradiant = ({ card }: CardProp) => {
  const { title,  description } = card;
  return (
    <Card className="cursor-default	 relative items-center px-4 py-6 text-left bg-gradient-to-b from-[#018ffd] to-[#070c84] text-white gap-4   w-full ">
       <div className="flex items-start   relative">
            <WhiteCheckTick
              height={16}
              width={16}
              className="absolute top-1"
            />

            <Typography variant="custom" className={`px-6  text-white font-light text-xl`}>
              <span className="font-semibold text-white bg-clip-text">
                {title}
              </span>
              <span className="font-[300] text-lg">
              {description}
              </span>
            </Typography>
          </div>
    </Card>
  );
};

export default LabelInfoCardGradiant;
