import { Link } from "react-router-dom";
import { CashIcon } from "assets/svgs/icons/cash-icon";
import { HomeIcon } from "assets/svgs/icons/home-icon";
import { InventoryLineIcon } from "assets/svgs/icons/inventory-line-icon";
import { ScanBoxFilledIcon } from "assets/svgs/icons/scanbox-filled-icon";
import { ShippingTruckIcon } from "assets/svgs/icons/shipping-truck-icon";
import { SteeringWheelIcon } from "assets/svgs/icons/steering-wheel-icon";
import { LogoColoured } from "assets/svgs/logo-coloured";
import { Accordion, AccordionItem } from "@nextui-org/react";
import { HamburgerLineIcon } from "assets/svgs/icons/hamburger-line-icon";
import { useWindowSize } from "usehooks-ts";
import { useState } from "react";

interface Props {
  toggle: boolean;
  handleToggle: () => void;
  openSideBar: () => void;
}

export default function DealerDashboardSidebar({
  toggle,
  handleToggle,
  openSideBar,
}: Props) {
  const { width = 0 } = useWindowSize();
  const [hoverOnSidebar, setHoverOnSidebar] = useState(false);
  const SidebarNavLinks = [
    {
      href: "/dealer/dashboard",
      label: "Dashboard",
      icon: <HomeIcon className="size-7" />,
    },
    {
      href: "/dealer/enter-vin",
      label: "Enter Vin Number",
      icon: <ScanBoxFilledIcon className="size-7" />,
      subnav: [
        {
          href: "/dealer/enter-vin",
          label: "Single Listing",
        },
        {
          href: "/dealer/bulk-enter-vin",
          label: "Bulk Listing",
        },
        {
          href: "/dealer/car-research",
          label: "Research",
        },
      ],
    },
    {
      href: "/dealer/manage-inventory",
      label: "Manage Inventory",
      icon: <InventoryLineIcon className="size-7" />,
      subnav: [
        {
          href: "/dealer/manage-inventory/live-inventory",
          label: "Live Inventory",
        },
        {
          href: "/dealer/manage-inventory/archived-and-sold",
          label: "Archived/Sold",
        },
        {
          href: "/dealer/manage-inventory/pending-sales",
          label: "Pending Sales",
        },
      ],
    },
    {
      href: "/dealer/dmv-titling",
      label: "DMV / Titling",
      icon: <SteeringWheelIcon className="size-7" />,
      subnav: [
        {
          href: "/dealer/dmv-titling/new-order",
          label: "New Order",
        },
        {
          href: "/dealer/dmv-titling/order-history",
          label: "Order History",
        },
      ],
    },
    {
      href: "/shipping-quotes",
      label: "Shipping Quotes",
      icon: <ShippingTruckIcon className="size-7" />,
      subnav: [
        {
          href: "/shipping-quotes/select-vehicle",
          label: "New Order",
        },
        {
          href: "/shipping-quotes/order-history",
          label: "Order History",
        },
      ],
    },
    {
      href: "/dealer/profit-loss",
      label: "Profit and Loss",
      icon: <CashIcon className="size-7" />,
    },
  ];

  const toggleSidebarMenu = () => {
    if (width < 1023) {
      const sidebar = document.querySelector("#application-sidebar");
      const sidebarOverlay = document.querySelector(
        "#application-sidebar-backdrop"
      );
      sidebar?.classList.toggle("translate-x-0");
      sidebarOverlay?.classList.toggle("hidden");
    }
  };

  function MobileHamburgerMenu() {
    return (
      <div className="flex gap-4 me-5  h-16">
        <button
          type="button"
          className="text-gray-500 hover:text-gray-600 mt-auto"
          aria-label="Toggle navigation"
          onClick={toggleSidebarMenu}
        >
          <span className="sr-only">Toggle Navigation</span>
          <HamburgerLineIcon className="h-7 w-7" onClick={handleToggle} />
        </button>
      </div>
    );
  }

  const winToggle = toggle || width < 1023 || hoverOnSidebar;
  return (
    <>
      <div
        id="application-sidebar"
        data-open={winToggle}
        className={` -translate-x-full ease-in-out transition-all duration-300 transform fixed top-0 start-0 bottom-0 z-50 ${
          winToggle ? "w-56" : "w-16"
        } overflow-hidden bg-white border-e border-gray-200 pt-7 pb-4 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300`}
      >
        <div className="flex items-end justify-between px-4 pr-12">
          <MobileHamburgerMenu />
          <Link
            className="flex-none text-xl font-semibold"
            to="/listing/landing-page"
            aria-label="Brand"
          >
            {winToggle && <LogoColoured />}
          </Link>
        </div>

        <nav
          className="flex flex-col flex-wrap w-full pt-6 pb-3 lg:pt-10"
          onMouseEnter={() => setHoverOnSidebar(true)}
          onMouseLeave={() => setHoverOnSidebar(false)}
        >
          <ul>
            {SidebarNavLinks.map(({ href, icon, label, subnav }, index) => {
              const hasSubmenu = subnav && subnav.length > 0;

              return (
                <div key={index}>
                  {hasSubmenu ? (
                    <Accordion className="px-0">
                      <AccordionItem
                        aria-label={label}
                        title={winToggle ? label : ""}
                        classNames={{
                          title: "text-slate-700 text-base",
                          indicator:
                            "-rotate-[90deg] data-[open=true]:rotate-90", //To rotate the indicator icon
                        }}
                        className="w-full py-1 pl-3.5 pr-2 border-gray-200 opacity-100 pointer-events-auto hover:bg-gray-100 lg:py-2 border-b-1"
                        startContent={<span onClick={openSideBar}>{icon}</span>}
                        hideIndicator={!hasSubmenu}
                        isDisabled={!hasSubmenu}
                      >
                        {subnav.map(
                          ({ href: subHref, label: subLabel }, subIndex) => (
                            <Link
                              to={subHref}
                              key={subIndex}
                              onClick={toggleSidebarMenu}
                            >
                              {winToggle && (
                                <li className="px-1 hover:bg-gray-200 lg:py-2">
                                  <div className="w-full text-start flex items-center text-base gap-x-3.5 py-1 px-2.5 text-slate-700 rounded-lg">
                                    {subLabel}
                                  </div>
                                </li>
                              )}
                            </Link>
                          )
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <Link to={href} onClick={toggleSidebarMenu}>
                      <div className="py-2 border-gray-200 hover:bg-gray-100 lg:py-4 border-b-1">
                        <li className="px-1">
                          <div className="w-full text-start flex items-center text-base gap-x-3.5 py-2 px-2.5 text-slate-700 rounded-lg">
                            {icon}
                            {winToggle ? label : ""}
                          </div>
                        </li>
                      </div>
                    </Link>
                  )}
                </div>
              );
            })}
          </ul>
        </nav>
      </div>

      <div
        id="application-sidebar-backdrop"
        data-test-id
        className="fixed z-[49] inset-0 hidden transition bg-gray-900 bg-opacity-50 duration dark:bg-opacity-80"
        onClick={() => {
          const sidebar = document.querySelector("#application-sidebar");
          const sidebarOverlay = document.querySelector(
            "#application-sidebar-backdrop"
          );
          sidebar?.classList.toggle("translate-x-0");
          sidebarOverlay?.classList.toggle("hidden");
        }}
      ></div>
    </>
  );
}
