export const formatDateToShortDate = (
  date: Date | null,
  separator?: string
) => {
  if (!date) return "";
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month > 9 ? month : "0" + month}${separator || "/"}${day}${
    separator || "/"
  }${year}`;
};

export function getFormatedDateToday() {
  // Get current date
  const currentDate = new Date();

  // Get day, month, and year
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Note: Month starts from 0, so we add 1
  const year = currentDate.getFullYear();

  // Format day, month, and year with leading zeros if necessary
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;

  // Construct the date string in dd/mm/yyyy format
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

  // Print the formatted date\
  return formattedDate;
}

export function cc_expires_format(string: string) {
  return string
    .replace(
      /[^0-9]/g,
      "" // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      "0$1" // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      "0$1/$2" // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      "0" // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      "$1/$2" // To handle 113 > 11/3
    );
}
