import React from "react";
import { Card, CardBody, Image } from "@nextui-org/react";

import { Button, Typography } from "components";
import { env } from "config/env";

import { money } from "utils";
import { useNavigate } from "react-router-dom";
import {
  ListingVehicleResponse,
  useGetUserSavedListing,
  useRemoveUserListing,
  useSaveUserListing,
} from "services";
import { getUserId } from "utils/get-user-id";
import { localStorageFn } from "utils/localstorage";
import { CheckRounded } from "assets/svgs/check-rounded.svg";
import { CircleRounded } from "assets/svgs/circle-rounded.svg";
interface Props {
  car: ListingVehicleResponse;
}
export default function SearchCarDetails({ car }: Props) {
  const { mutateAsync: saveUserListing, isPending: saveUserListingPending } =
    useSaveUserListing();
  const { data: saveVehicleListing, refetch: refetchSaveVehicleList } =
    useGetUserSavedListing(!!localStorageFn.getItem("access_token"));
  const { mutateAsync: removeUserListing, isPending: removeListingPending } =
    useRemoveUserListing();
  const navigate = useNavigate();
  const handleSaveVehicleListing = async (car: ListingVehicleResponse) => {
    const payload = {
      listingId: Number(car?.listingDetail.basicDetail.listingId),
      userId: getUserId(),
    };
    await saveUserListing(payload);
    refetchSaveVehicleList();
  };

  const handleRemoveVehicleListing = async (car: ListingVehicleResponse) => {
    const payload = {
      listingId: Number(car?.listingDetail.basicDetail.listingId),
      userId: getUserId(),
    };
    await removeUserListing(payload);
    refetchSaveVehicleList();
  };
  const viewCar: number[] = localStorageFn.getItem("view-car") || [];
  const isViewed = viewCar?.includes(car?.listingDetail.basicDetail.listingId);
  return (
    <Card
      className="border border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors"
      shadow="none"
      fullWidth
    >
      <CardBody className="flex flex-col">
        <div
          className="flex flex-col"
          // className=" grid grid-cols-1 gap-2"
        >
          <div className="col-span-2">
            <Image
              removeWrapper
              src={`${env.BASE_API_URL}Listings/image/${car.listingDetail.basicDetail.listingId}`}
              alt="car"
              onClick={() => {
                const listingId = car.listingDetail.basicDetail.listingId;
                localStorageFn.setItem(
                  "view-car",
                  viewCar.includes(listingId)
                    ? viewCar
                    : [...viewCar,listingId]
                );
                // navigate(`/listing/view-listing/${listingId}`);
                window.open(`/#/listing/view-listing/${listingId}`,"_blank")
              }}
              className="w-full h-56 object-cover cursor-pointer"
            />
          </div>
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="text-xl text-center font-[800] text-[#263238] min-h-6 overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {`${car.listingDetail.basicDetail.year || ""} ${
                car.listingDetail.basicDetail.make
              } ${car.listingDetail.basicDetail.model}`}
            </Typography>
            <Typography
              variant="custom"
              className="text-center text-md font-[600] text-[#263238] py-1 min-h-7"
            >
              {car.listingDetail.basicDetail.trim}
            </Typography>
            <Typography
              variant="custom"
              className="text-center text-3xl font-[800] text-[#263238] h-8"
            >
              ${money(car.listingDetail.basicDetail.price)}
            </Typography>
            <div className="flex gap-6 items-center justify-center   mt-2 font-[800]">
              <Typography className="">
                {money(Number(car.listingDetail.basicDetail.mileage)) || 0} mi
              </Typography>
              {/* <Typography className="text-center">Days Listed: 0</Typography> */}
              <Typography className="text-center flex items-center">
                Viewed?{" "}
                {isViewed ? (
                  <CheckRounded className="ml-1" />
                ) : (
                  <CircleRounded className="ml-1" />
                )}
              </Typography>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center mt-auto gap-4 pt-4">
          <Button
            customVariant="gradient"
            className="w-full w-max-[12rem] overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            <Typography
              variant="label"
              className="text-lg md:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-[900] overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {car.sellerDetail?.city || car.sellerDetail?.state
                ? car.sellerDetail?.city +
                  ", " +
                  car.sellerDetail?.state
                : "N/A"}
            </Typography>
          </Button>
          <Button
            customVariant="gradient"
            className="w-full "
            onClick={() =>
              saveVehicleListing?.some(
                (res) =>
                  res?.listingId === car?.listingDetail.basicDetail.listingId
              ) === true
                ? handleRemoveVehicleListing(car)
                : handleSaveVehicleListing(car)
            }
            isLoading={saveUserListingPending}
          >
            <Typography
              variant="label"
              className="text-lg md:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-[900]"
            >
              {saveVehicleListing?.some(
                (res) =>
                  res?.listingId === car?.listingDetail.basicDetail.listingId
              ) === true
                ? "Saved Vehicle"
                : "Save Vehicle"}
            </Typography>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
