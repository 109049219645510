import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Card,
  CardBody,
  Spacer,
  Image,
  Checkbox,
} from "@nextui-org/react";

import { Typography, TextErrorHelper } from "components";
import { usePostSellerLead } from "hooks/api/mutations/view-cars/view-cars";
import { useQueryGetSellerDetailsByListingId } from "hooks/api/queries/view-cars/view-cars";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ListingDetails } from "services";
import { z } from "zod";

interface BidViewCarProps {
  listing: ListingDetails | undefined;
}

interface IFinaceState {
  cash_Price: number;
  down_Payment: number;
  loan_Term: number;
  interest_Rate: number;
  monthly_Payment: number;
}
const validationSchema = z.object({
  name: z.string().min(1, { message: "Not a valid name" }),
  phone: z.string().min(14, { message: "Not a valid phone" }).regex(/^[0-9\-\(\)\s]+$/, {
    message: "String can only contain numbers, hyphens, and parentheses",
  }),
  email: z
    .string()
    .min(1, { message: "Not a valid email" })
    .email("Not valid email"), // VIN is optional
  note: z.string().optional(), // VIN is optional
});
export type IContactSeller = z.infer<typeof validationSchema>;

export const ApplyFinancePublic: React.FC<BidViewCarProps> = ({ listing }) => {
  // const [showError, setShowError] = useState(0);
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
    trigger,
    getValues,
  } = useForm<IContactSeller>({
    resolver: zodResolver(validationSchema),
  });
  const { mutateAsync:postleadasync, isPending:isPendingPostlead, error:postleadError } =usePostSellerLead()
  
  const { data: sellerData } = useQueryGetSellerDetailsByListingId(listing?.listingId?.toString() || "");
  const [sellerFinanceOffer, setSellerFinanceOffer] = useState<IFinaceState>({
    cash_Price: 0,
    down_Payment: 0,
    loan_Term: 0,
    interest_Rate: 0,
    // listingId: 0,
    monthly_Payment: 0,
    // userId: 0,
    // down_Payment_error: false,
    // interest_Rate_error: false,
    // loan_Term_error:false
  });

  const onSubmit = async (data: IContactSeller) => {
    try{
      await postleadasync({
        "name": data.name,
        "email": data.email,
        "phone": data.phone,
        "note": data?.note ||"",
        "sellerEmail": sellerData?.email||""
      })
      setOpenModal(1)
      reset()
      setChecked(false)
    }catch(e){
      console.log(e)
    }
  };

  useEffect(() => {
    const loanAmount =
      Number(sellerFinanceOffer?.cash_Price) -
      Number(sellerFinanceOffer.down_Payment);

    const loanTermMonths = Number(sellerFinanceOffer.loan_Term);

    const monthlyInterestRate =
      Number(sellerFinanceOffer.interest_Rate) / 100 / 12;

    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));

    setSellerFinanceOffer((prev) => ({
      ...prev,
      monthly_Payment: +monthlyPayment.toFixed(2),
    }));
  }, [
    sellerFinanceOffer.cash_Price,
    sellerFinanceOffer.down_Payment,
    //   sellerFinanceOffer.estimated_Credit_Score,
    sellerFinanceOffer.interest_Rate,
    sellerFinanceOffer.loan_Term,
  ]);
  
  useEffect(()=>{
    if(listing){
      setSellerFinanceOffer({
        cash_Price: listing?.financingPrice,
        down_Payment: listing?.downPayment,
        loan_Term: listing?.term,
        interest_Rate: listing?.interestRate ,
        monthly_Payment: listing?.monthlyPayment,
      })
    }
  },[listing])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        className="my-4 grid grid-cols-1 md:grid-cols-2 justify-items-center"
        fullWidth
        isPressable={false}
      >
        <CardBody className="flex justify-center items-center my-4">
          <div className="w-[98%] border-solid border-blue-900 bg-gradient-to-br from-blue-500 to-blue-900 backdrop-blur p-4 h-full border rounded-2xl">
            <div>
              <Typography
                variant="subtitle"
                className="text-white text-center font-bold text-base md:text-xl xl:text-2xl "
              >
                Contact Seller
              </Typography>
            </div>
            <Spacer y={4} />
            <div className="flex flex-col gap-2">
              {[
                { label: "Name*", id: "name" },
                { label: "Email*", id: "email" },
                { label: "Phone Number*", id: "phone" },
              ].map(({ label, id }, ind) => {
                return (
                  <div key={ind}>
                    <label
                      className={`${
                        !errors[id as keyof IContactSeller]
                          ? "text-white"
                          : "text-red-500"
                      }  text-sm`}
                    >
                      {label}
                    </label>
                    {
                      id=== "phone" ? 
                      <input
                        className={`bg-transparent text-white w-full ${
                          !errors[id as keyof IContactSeller]
                            ? "border-white"
                            : "border-red-500"
                        } border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left outline-none`}
                        {...register(id as keyof IContactSeller)}
                        maxLength={14}
                        value={getValues("phone")}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          let formattedValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                          if (formattedValue.length < 4) {
                            formattedValue = inputValue;
                          } else if (formattedValue.length < 7) {
                            formattedValue = `(${formattedValue.slice(
                              0,
                              3
                            )}) ${formattedValue.slice(3)}`;
                          } else {
                            formattedValue = `(${formattedValue.slice(
                              0,
                              3
                            )}) ${formattedValue.slice(
                              3,
                              6
                            )}-${formattedValue.slice(6)}`;
                          }
    
                          setValue(`phone`, formattedValue);
                          trigger(`phone`);
                        }}
                      />:
                      
                    <input
                    className={`bg-transparent text-white w-full ${
                      !errors[id as keyof IContactSeller]
                        ? "border-white"
                        : "border-red-500"
                    } border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left outline-none`}
                    {...register(id as keyof IContactSeller)}
                  />
                    }
                    

                  </div>
                );
              })}

              <div>
                <label className="text-white text-sm">{}</label>
                <textarea
                  placeholder="Note (250 Characters)"
                  rows={4}
                  className={`bg-transparent text-white w-full placeholder:text-sm text-sm placeholder:text-white placeholder:px-0 px-4 py-2 border-white border-1.5 rounded-xl mb-1 h-[5rem]  text-left outline-none scrollbar-hide`}
                  {...register("note")}
                />
              </div>
            </div>
            <Spacer y={4} />
            <div className="relative flex items-center   -bottom-2 flex-col justify-center">
              <Button
                className="w-full h-14 bg-white"
                type="submit"
                // onClick={() => handleButtonClick(1)}
                isDisabled={!checked}
                isLoading={isPendingPostlead}
              >
                <Typography
                  variant="custom"
                  className="font-[Metropolis] text-xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text font-[700]"
                >
                  Send
                </Typography>
              </Button>
              <div className="flex justify-center mt-4 mb-2  relative  items-center mx-2">
                <Checkbox
                  onChange={(e) => setChecked(e.target.checked)}
                  checked={checked}
                  size="md"
                  className="border-black absolute  -left-2 top-1  "
                  radius="sm"
                />
                <Typography
                  variant="custom"
                  className="font-[Metropolis] text-[10px] pl-6 text-justify  text-white bg-clip-text"
                >
                  By submitting my contact information on{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://findmyoptions.com/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    className="underline cursor-pointer"
                  >
                    findmyoptions.com
                  </span>{" "}
                  , I agree to receive communications from findmyoptions, from
                  the vehicle’s seller, and from the seller’s agent(s). If I
                  include my phone number, I agree to receive calls and text
                  messages (including via automation). I can opt out at any
                  time.Standard message and data rates may apply. I also agree
                  to{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.findmyoptions.com/#/listing/landing-page?policy=privacy",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    className="underline cursor-pointer"
                  >
                    the Privacy Policy
                  </span>
                  .
                </Typography>
              </div>
            </div>
          </div>

          {postleadError?.message && (
            <div className="flex flex-row justify-center pt-2 px-2">
              <TextErrorHelper>
                {postleadError.message}
              </TextErrorHelper>
            </div>
          )}
        </CardBody>
        <CardBody className="my-4  ">
          <div className="flex flex-col  h-full items-center w-[98%] border-1.5 rounded-2xl border-[#070C84] p-4">
            <div className="">
              <Typography
                variant="title"
                className="font-bold text-base md:text-xl xl:text-2xl text-[#070C84]"
              >
                Financing Terms
              </Typography>
            </div>

            <div className="flex  mt-9 flex-col gap-4 md:gap-8 w-full">
              {[
                { label: "Cash/Financing Price", id: "cash_Price" },
                { label: "Down Payment (Min 500)", id: "down_Payment" },
                { label: "Interest Rate", id: "interest_Rate" },
                { label: "Term", id: "loan_Term" },
                { label: "Monthly Payment", id: "monthly_Payment" },
              ].map(({ label, id }, ind) => {
                return (
                  <div
                    key={ind}
                    className="flex justify-between gap-4 items-center"
                  >
                    <label className="text-[#263238] text-sm w-[12rem]">
                      {label}
                    </label>
                    <input
                      type="number"
                      className={`bg-transparent ${
                        id === "monthly_Payment" ? "opacity-50" : ""
                      }  w-full border-[#070C84] border-1.5 rounded-xl mb-1 h-[3rem] px-2 text-left outline-none`}
                      value={
                        sellerFinanceOffer[`${id as keyof IFinaceState}`] || ""
                      }
                      name={id}
                      disabled={id === "monthly_Payment"}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const data = {
                          ...sellerFinanceOffer,
                          [e.target.name]: value,
                        };
                        setSellerFinanceOffer(data);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
      </Card>
      <CustomizableHeaderModel
          size="2xl"
        isOpen={openModal !== 0}
        onOpen={() => {
          setOpenModal(0);
        }}
        Component={
          
            <div className="mt-8 py-6">
            <ThankYou buttonText=""  onClick={()=> setOpenModal(0)} />
            </div>
          
        }
      />
    </form>
  );
};
