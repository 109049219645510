import { SelectNextUi, TextErrorHelper, Typography } from "components";
import Input from "components/ui/input";

const type = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Used",
    value: "Used",
  },
];
interface NewUsedVinSectionProps {
  register: any;
  errors: any;
  params: any;
}
export const NewUsedVinSection: React.FC<NewUsedVinSectionProps> = ({
  register,
  errors,
  params,
}) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      <Typography
        variant="custom"
        className="text-[#263238] font-[600] col-span-2 text-left"
      >
        New/Used
      </Typography>
      <div className="col-span-3">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-5 md:justify-items-center">
          <SelectNextUi
            placeholder="Select Transfer Type"
            aria-label="Select Transfer Type"
            className="col-span-3 md:col-span-2"
            defaultSelectedKeys={["New"]}
            data={type}
            register={register("newUsed")}
          />
          <div className="col-span-3 md:flex flex-row items-center gap-4">
            <Typography
              variant="custom"
              className="text-[#263238] font-[600] text-left"
            >
              VIN
            </Typography>
            <Input
              isReadOnly
              variant="form"
              size="lg"
              classNames={{
                input: "px-4 text-center",
              }}
              defaultValue={params?.id?.toUpperCase() || ""}
              errorMessage={errors.errors.vin?.message}
              {...register("vin")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
