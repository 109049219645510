import { accordions } from "../contact-us.data";
import ContactUsAccordion from "./accordion.component";

const ContactUsAccordions = () => {
  return (
    <div className="flex flex-col gap-16">
      {accordions.map((item) => (
        <ContactUsAccordion item={item} />
      ))}
    </div>
  );
};

export default ContactUsAccordions;
