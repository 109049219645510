import {  Typography } from "components";
import Loading from "components/Loading/Loading";
import {
  ListingDetailsResponse,
  useGetUserSavedListing,
} from "services";
import { getUserId } from "utils/get-user-id";
import GarageDetails from "./component/GarageDetails";
import { PaymentModal } from "pages/shared";
import { useState } from "react";
import { Button } from "@nextui-org/react";
import { RequestedLoanTerms } from "../apply-finance/RequestedLoanTerms";
import { CustomizableHeaderModel } from "pages/shared/apply-finance/modal";
import { IBhphRequestType, saveBHPHOfferType } from "modules/dealer/store/seller-offer-price";
import { useDispatch } from "react-redux";
import { ShowBhphAppovalFlow } from "./component/controcts/Approve-bhphf-agreement-flow";


export const SavedVehiclesPage = () => {
  const userId = getUserId();
  const dispatch = useDispatch() 

  const [openModal, setOpenModal] = useState<IBhphRequestType | "Select_Financing_Option" | "ApprovedSmall" | "ApprovedBig" | null>(null);
  const [selectedCar,setSelectedCar] = useState<ListingDetailsResponse['listing'] | null>(null) 
  
  const { data, isLoading, isPending, refetch } = useGetUserSavedListing(true);
  const hadleViewSaveListing = async (id: string) => {
    window.open(`#/listing/view-listing/${id}`, "_blank");
  };

  const handleAppyFinacing = (car:ListingDetailsResponse["listing"]) => {
    setOpenModal("Select_Financing_Option")
    setSelectedCar(car)
  }
  
  const handleAppyApproval = (car:ListingDetailsResponse["listing"]) => {
    setOpenModal("ApprovedSmall")
    setSelectedCar(car)
  }
  
  const selectBhphOfferFinaceType = (type:IBhphRequestType) =>{
    setOpenModal(type)
    dispatch(saveBHPHOfferType({requestType: type}))
  }
  if (isLoading) return <Loading />;

  return (
    <div className=" mb-20">
      <div className="flex flex-col gap-4 items-center justify-center mb-6">
        <Typography variant="pageTitle">Saved Vehicles</Typography>
      </div>
      {data?.length === 0 ? (
        <div className="flex justify-center items-center">
          <Typography
            variant="custom"
            className="px-2 py-1.5 w-full h-full text-foreground-400 text-center"
          >
            No items.
          </Typography>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 gap-4">
          <GarageDetails
              car={{"userId":138,"listingId":1188}}
              key={"0"}
              from="save-listing"
              userId={userId}
              refetch={refetch}
              loading={isPending}
              btnLabel="Remove From Saved"
              status="Approved"
              handleClickChip={handleAppyApproval}
            />
          {data?.map((car, index) => (
            <GarageDetails
              car={car}
              key={index}
              from="save-listing"
              userId={userId}
              refetch={refetch}
              loading={isPending}
              btnLabel="Remove From Saved"
              status="Apply For Financing"
              handleClickChip={handleAppyFinacing}
            />
            // <SearchCarCard
            //   car={car}
            //   line1={`${car?.year ?? ""} ${car?.make ?? ""} ${car?.model ?? ""}`}
            //   line2={car?.trim ?? ""}
            //   line3={`${car.mileage} mi`}
            //   onSelect={() => {}}
            //   onClick={()=>hadleViewSaveListing(car.listingId)}
            //   btnLabel="Remove From "
            // />
          ))}
        </div>
      )}


<CustomizableHeaderModel
        size={(openModal === "Select_Financing_Option" || openModal === "ApprovedSmall"  )? "2xl" : "5xl"}
        isOpen={openModal !== null}
        onOpen={() =>{ setOpenModal(null); setSelectedCar(null)}}
        Component={
          (openModal === "ApprovedSmall" || openModal === "ApprovedBig") ? (<ShowBhphAppovalFlow makeModalBig={()=>{setOpenModal("ApprovedBig")}}/>) : openModal === "Select_Financing_Option" ? (
            <SelectFinanceOpt selectBhphOfferFinaceType={selectBhphOfferFinaceType} />
          ) : (
            <div className="relative ">
            <RequestedLoanTerms isPreRequest={false} financeOption={openModal as any} vin={selectedCar?.vin||""} listing={selectedCar} />
            </div>
            // <ThankYou textNote="Payment Successful" onClick={() => setShowPayModal(3)} />
          )
        }
      />
    </div>
  );
};

const SelectFinanceOpt = ({selectBhphOfferFinaceType}:any) => {
  return (<div className="w-full  py-20 px-4 sm:px-20 flex flex-col items-center">
    <Typography
      variant="custom"
      className=" text-xl md:text-2xl mb-8 font-[Metropolis] font-semibold text-center"
    >
     Select Financing Option
    </Typography>
    <div className="w-full max-w-52 flex flex-col items-center ">
      <Button
        onClick={() =>selectBhphOfferFinaceType("Standard_Financing")
          
         
        }
        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg  w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
      >
        Standard Financing
      </Button>
      <Button
        onClick={() =>selectBhphOfferFinaceType("In_House_Financing")}
      
        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
      >
        In House Financing
      </Button>
      <Typography
        variant="custom"
        className={`font-semibold text-base md:text-lg mt-5`}
      >
        (for challenged credit)
      </Typography>
    </div>
  </div>)
}