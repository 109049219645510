interface VinDataItemProps {
  idx: number;
  key?: string;
  variable?: string;
  value?: string;
}

export default function VinDataItem({
  idx,
  key,
  variable,
  value,
}: VinDataItemProps) {
  return (
    <div
      key={idx}
      className={`flex justify-between w-full  bg-[#EBEBEB] rounded-lg dark:bg-gray-600 mb-1 p-3 px-7 `}
    >
      <p className="w-full flex justify-between  items-center">
        <span className="text-[#070C84] text-xs lg:text-base pr-8 ">
          {key ?? variable}
        </span>
        <span className="text-black text-xs lg:text-base text-right">
          {value ? value : "-"}
        </span>
      </p>
    </div>
  );
}
