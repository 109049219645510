import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type IBhphRequestType = "In_House_Financing" | "Standard_Financing";

export interface SellerOfferPrice {
  offerData: { totalPrice: number; vin: string } | null;
  bnphOffer: { requestType: IBhphRequestType } | null;
}

const initialState: SellerOfferPrice = {
  offerData: null,
  bnphOffer: null
};

export const sellerOfferPriceSlice = createSlice({
  name: "sellerOffer",
  initialState,
  reducers: {
    saveBHPHOfferType: (
      state,
      action: PayloadAction<{ requestType: IBhphRequestType }>
    ) => {
      state.bnphOffer = action.payload;
    },
    
    changeForSellerOfferPrice: (
      state,
      action: PayloadAction<{ totalPrice: number; vin: string }>
    ) => {
      state.offerData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeForSellerOfferPrice,saveBHPHOfferType } = sellerOfferPriceSlice.actions;

export default sellerOfferPriceSlice.reducer;
