import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ISubmitResearchVehicleFormPayload, ListingListResponse } from "services";
import { RowComponent } from "./RowComponent";
import { RowComponentForDelete } from "./DeleteRowComponent";

export const TableLabel = ["VIN", "Mileage", "Name", "Phone Number", "Action"];

export const TableComponentForPayment = ({
    // data,
    selectedStatus,
    formSfdata,
    dataListing,
    page,
    cancelRefetch,
    setPage,
    ContinueRefetch,
    continuePending,
  }: {
    // data?: IsaveType[];
    formSfdata?: ISubmitResearchVehicleFormPayload[];
    selectedStatus: number;
    dataListing?: ListingListResponse[];
    setPage: React.Dispatch<React.SetStateAction<number | null>>;
    page: number | null;
    cancelRefetch: () => void;
    ContinueRefetch: () => void;
    continuePending: boolean;
  }) => {
    const [items, setItem] = useState<ListingListResponse[]>([]);
  
    useEffect(() => {
      if (dataListing) {
        setItem((prev) => (page === 1 ? dataListing : [...prev, ...dataListing]));
      }
    }, [dataListing]);
  
    return (
      <div className="my-6  py-4">
        {selectedStatus === 1 ? (
          <>
            <div className="relative overflow-x-auto w-full rounded-xl border-[#C9C9C9]  border-2">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-lg text-white  border-[#C9C9C9] ">
                  <tr>
                    {TableLabel.map((txt) => {
                      return (
                        <th
                          key={txt}
                          scope="col"
                          className="px-6 py-3 text-center border-l border-[#C9C9C9] bg-[#070C84] "
  
                          // className="px-6 py-3 text-center border-l border-[#C9C9C9] bg-gradient-to-r from-[#018FFD] to-[#070C84] "
                        >
                          {txt}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {formSfdata && formSfdata.length > 0 ? (
                    formSfdata.map((row, idx) => (
                      <RowComponent
                        data={row}
                        continuePending={continuePending}
                        ContinueRefetch={ContinueRefetch}
                        selectedStatus={selectedStatus}
                        refetchListingData={() => {
                          setPage(1);
                          cancelRefetch();
                        }}
                      />
                    ))
                  ) : (
                    <tr>
                      {" "}
                      <td
                        className="px-6 py-4 text-center border-l text-xl border-[#C9C9C9]"
                        colSpan={5}
                      >
                        {formSfdata?.length === 0
                          ? "No Data Found!"
                          : "Loading..."}{" "}
                      </td>{" "}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <InfiniteScroll
            dataLength={items.length}
            next={() => {
              setPage((p) => (p ? p + 1 : null));
            }}
            hasMore={page !== null && items.length >= 19}
            scrollThreshold={0.8}
            className="overflow-visible"
            style={{ overflow: "visible" }}
            loader={
              dataListing && dataListing?.length > 0 ? (
                <p className="flex justify-center items-center">
                  <b>Loading...</b>
                </p>
              ) : (
                ""
              )
            }
          >
            <div className="relative overflow-x-auto w-full rounded-xl border-[#C9C9C9]  border-2">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-lg text-white  border-[#C9C9C9] ">
                  <tr>
                    {TableLabel.map((txt) => {
                      return (
                        <th
                          key={txt}
                          scope="col"
                          className="px-6 py-3 text-center border-l border-[#C9C9C9] bg-[#070C84] "
  
                          // className="px-6 py-3 text-center border-l border-[#C9C9C9] bg-gradient-to-r from-[#018FFD] to-[#070C84] "
                        >
                          {txt}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <>
                    {items && items.length > 0 ? (
                      items.map((row, idx) => (
                        <RowComponentForDelete
                          listingDetailsData={row}
                          selectedStatus={selectedStatus}
                          refetchListingData={() => {
                            setPage(1);
                            cancelRefetch();
                          }}
                        />
                      ))
                    ) : (
                      <tr>
                        {" "}
                        <td
                          className="px-6 py-4 text-center border-l text-xl border-[#C9C9C9]"
                          colSpan={5}
                        >
                          {dataListing?.length === 0
                            ? "No Data Found!"
                            : "Loading..."}{" "}
                        </td>{" "}
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        )}
      </div>
    );
  };
  