import { SVGProps } from "react";

export const CheckRounded = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M10.054 5.11963L6.21004 8.96355L4.44824 7.20175L4.8999 6.75009L6.21004 8.05702L9.60229 4.66797L10.054 5.11963Z"
        fill="#263238"
      />
      <rect
        x="2.1539"
        y="2.15194"
        width="9.69231"
        height="9.69231"
        rx="4.84615"
        stroke="#263238"
        stroke-width="0.717949"
      />
    </svg>
  );
};
