import { Typography } from "components";
import React from "react";
import checkIcon from "../careers/assets/svg/check-icon.svg";
export default function AdvertisersPage() {
  return (
    <div className="flex items-center justify-center">
      <div className="md:p-4 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            For Advertisers
          </p>
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-2xl font-bold mb-5">
            Target Automotive Buyers, Achieve Remarkable Results.
          </p>
          <p className=" text-xl text-start ">
            Reach passionate car enthusiasts at every stage of their buying
            journey through our targeted advertising solutions. We offer a
            variety of ad formats and campaigns to engage your audience and
            drive brand awareness.
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Why Advertise with Us:
          </p>

          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                {" "}
                Precise Targeting:{" "}
              </span>
              <span className="font-semibold">
                Reach buyers based on specific demographics, interests, and
                purchase intent.
              </span>
            </p>
          </div>

          <div className="flex items-start mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Multiple Ad Formats:{" "}
              </span>
               
              <span className="font-semibold">
                Choose from display, video, search, and native advertising
                options.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Measurable Results: {" "}
              </span>
              <span className="font-semibold">
                Track your campaign performance with detailed analytics and
                reporting.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Dedicated Account Management:{" "}
              </span>
              <span className="font-semibold">
                 Receive personalized support to optimize your campaigns.
              </span>
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-5 text-wrap">
          <p className="text-xl font-bold pt-5 ">
            Ready to Reach More Customers?
          </p>
          <p className="text-xl  ">
            Contact our advertising team at 
            <span
              className="underline cursor-pointer"
              onClick={() => {
                window.location.href = "mailto:support@findmyoptions.com";
              }}
            >
              support@findmyoptions.com
            </span>
             to explore our ad solutions and create a customized campaign for
            your brand.
          </p>
        </Typography>
      </div>
    </div>
  );
}
