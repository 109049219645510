import { SVGProps } from "react";

export const StateDealerIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M15.3016 16.5758C14.2391 16.5758 13.3359 16.2039 12.5922 15.4602C11.8484 14.7164 11.4766 13.8133 11.4766 12.7508C11.4766 11.6883 11.8484 10.7852 12.5922 10.0414C13.3359 9.29766 14.2391 8.92578 15.3016 8.92578C16.3641 8.92578 17.2672 9.29766 18.0109 10.0414C18.7547 10.7852 19.1266 11.6883 19.1266 12.7508C19.1266 13.8133 18.7547 14.7164 18.0109 15.4602C17.2672 16.2039 16.3641 16.5758 15.3016 16.5758ZM7.65156 29.3258V19.4764C6.84406 18.5839 6.21719 17.5639 5.77094 16.4164C5.32469 15.2689 5.10156 14.047 5.10156 12.7508C5.10156 9.90328 6.08969 7.49141 8.06594 5.51516C10.0422 3.53891 12.4541 2.55078 15.3016 2.55078C18.1491 2.55078 20.5609 3.53891 22.5372 5.51516C24.5134 7.49141 25.5016 9.90328 25.5016 12.7508C25.5016 14.047 25.2784 15.2689 24.8322 16.4164C24.3859 17.5639 23.7591 18.5839 22.9516 19.4764V29.3258L15.3016 26.7758L7.65156 29.3258ZM15.3016 20.4008C17.4266 20.4008 19.2328 19.657 20.7203 18.1695C22.2078 16.682 22.9516 14.8758 22.9516 12.7508C22.9516 10.6258 22.2078 8.81953 20.7203 7.33203C19.2328 5.84453 17.4266 5.10078 15.3016 5.10078C13.1766 5.10078 11.3703 5.84453 9.88281 7.33203C8.39531 8.81953 7.65156 10.6258 7.65156 12.7508C7.65156 14.8758 8.39531 16.682 9.88281 18.1695C11.3703 19.657 13.1766 20.4008 15.3016 20.4008ZM10.2016 25.5327L15.3016 24.2258L20.4016 25.5327V21.5802C19.6578 22.0052 18.8554 22.3401 17.9944 22.5849C17.1333 22.8297 16.2357 22.9516 15.3016 22.9508C14.3666 22.9508 13.4685 22.8284 12.6075 22.5836C11.7464 22.3388 10.9445 22.0043 10.2016 21.5802V25.5327Z"
      fill="#313131"
    />
  </svg>
);
