import { Button } from "@nextui-org/react";

interface Props {
  handleContinue: () => void;
}
export default function BhphfContractFour({ handleContinue }: Props) {
  return (
    <div className="p-2 md:p-8">
      <h2 className="my-4 font-bold text-center">
        Contract Cancellation Option
      </h2>
      <div>
        <p className="my-2">
          Date: [Date] Contract Cancellation Option Seller: [Your Dealership
          Name] Address: [Dealership Address]
        </p>
        <p className="my-2 mb-4">Buyer1: [Buyer1 Name] Buyer2: [Buyer2 Name]</p>
        <p className="my-2 mb-4">Vehicle: [Year] [Make] [Model] VIN: [VIN]</p>
      </div>

      <div>
        <ul className="list-none  ml-4">
          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">Cancellation Window: </span>If you
            decide to purchase this option, you have{" "}
            <span className="font-bold">2</span> business days (excluding
            Sundays and holidays) following the Contract date to return the
            Vehicle and receive a FULL REFUND. This right cannot be exercised
            earlier than the dealer's close of business on the
            <span className="font-bold">SECOND</span> day following the day the
            vehicle was delivered to you.
          </li>
          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">Cancellation Fee:</span> In exchange for
            the Contract Cancellation Option, you agree to pay a non-refundable
            fee of SEE BELOW to Seller. This fee is determined based on the cash
            purchase price of the Vehicle (excluding registration, transfer,
            titling, and license fees, the California tire fee, and any charge
            to electronically register or transfer the vehicle) according to the
            following chart:
            <ul className="list-none ml-16 my-2 ">
              {[
                "Vehicle Cash Price of $5,000 or less = $75",
                "Vehicle Cash Price between $5,001 and $10,000 = $150",
                "Vehicle Cash Price between $10,001 and $30,000 = $250",
                "Vehicle Cash Price between $30,001 and $40,000 = 1% of Vehicle Cash Purchase Price",
              ].map((txt, key) => {
                return (
                  <li className="relative " key={key}>
                    <span className="bg-white border-black border-1.5 rounded-full p-1 absolute  top-2 -left-4"></span>
                    {txt}
                  </li>
                );
              })}
            </ul>
          </li>

          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">Maximum Mileage:  </span>
            You shall return the Vehicle to Seller in the same condition it was received,
The Vehicle must not have exceeded <span className="font-bold">250</span> miles since its original delivery by the dealer.
          </li>
          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">Restocking Fee:</span>  In addition to the Cancellation Fee, a restocking fee will be applied upon return
            of the Vehicle, the cancellation fee will be deducted from this restocking fee:
            <ul className="list-none ml-16 my-2 ">
              {[
                 "Vehicle Cash Price of $5,000 or less = $175",
                 "Vehicle Cash Price between $5,001 and $9,999.99 = $350",
                 "Vehicle Cash Price between $10,000 or more = $500"
              ].map((txt, key) => {
                return (
                  <li className="relative " key={key}>
                    <span className="bg-white border-black border-1.5 rounded-full p-1 absolute  top-2 -left-4"></span>
                    {txt}
                  </li>
                );
              })}
            </ul>
          </li>
          
          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">Seller Inspection:  </span> Upon return of the Vehicle, Seller shall conduct a thorough inspection to
assess its condition. You shall be responsible for any damage to the Vehicle beyond reasonable
wear and tear. The cost of repairs for such damage shall be deducted from any refund due to you
under this Agreement.
          </li>
          
          <li className="relative my-4 -left-4 font-bold">
          Do you want to purchase the Contract Cancellation Option with the terms and conditions outlined
          above?
          </li>
          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">[]  </span> Yes, I want to purchase the Contract Cancellation Option
          </li>
          
          <li className="relative my-4">
            <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
            <span className="font-bold">[]  </span> No, I do not want to purchase the Contract Cancellation Option
          </li>
          <li className="relative my-4 -left-4 font-bold">
          By signing below, you acknowledge that you have reviewed and understood the terms and
conditions of this Contract Cancellation Option Agreement.
          </li>
        </ul>
      </div>
      <div className="my-10 font-bold">
        <p className="text-left flex  gap-6 flex-wrap ">
          <p>
            Buyer1 Signature
            <span className="border-gray-500 border-b px-12 sm:px-28" />
          </p>

          <p>
            {" "}
            Buyer2 Signature
            <span className="border-gray-500 border-b px-12 sm:px-28"></span>
          </p>
        </p>
      </div>

      <div className="my-6 flex justify-center">
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
          type="button"
          size="md"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

const EmpltyRowCreate = ({ arr }: { arr: string[] }) => {
  return (
    <>
      {arr.map((item) => {
        return (
          <tr key={item}>
            <td className="border border-gray-400 p-6" />
            <td className="border border-gray-400 p-4" />
            <td className="border border-gray-400 p-4" />
          </tr>
        );
      })}
    </>
  );
};
