
export const cards = [
  {
    id: "01",
    title: "Gather Your Documents: ",
    description:"Have basic information like your income, employment details, and residence ready.",
  
  },
  {
    id: "02",
    title: "Complete the Online Application: " ,
    description: "Create an Account, login and click the pre-approval Link."  
  },
  {
    id: "03",
    title: "Get a Decision Quickly: ",
    description:"Our team will review your application and provide you with a pre-approval decision ASAP."
  },
];




export const BenefitsOfPreApproval = [
  {
    name: "Know Your Budget: : ",
    description:
      "Pre-approval gives you a clear understanding of your loan amount and estimated monthly payments before you start shopping",
  },
  {
    name: "Streamlined Car Shopping: ",
    description:
    "Knowing your pre-approved limit allows you to focus on cars that fit your budget, saving you time and frustration."      
  },
  {
    name: "Stronger Negotiation Position: ",
    description:
    "Walking into a dealership with pre-approval demonstrates your seriousness as a buyer and can give you an edge during negotiations."
  }
];
