import React from "react";
import { Button, Card, CardBody } from "@nextui-org/react";
import Form from "components/ui/form";
import { Typography } from "components";
import { VehicleOptPaymentValidationSchema } from "./VehicleOptPaymentValidationSchema";
import Input from "components/ui/input";
import paymentBg from "../../../pages/dealer/offer-accepted/assets/payment-bg.png";

interface Props {
  onCallback: () => void;
  priceData: { base:string, fees:string, total:string}
  loading?:boolean
}

export default function EscrowPaymentForm({onCallback,priceData,loading}: Props) {
  return (
    <Card
      className="min-w-[340px] "
      style={{
        backgroundImage: `url(${paymentBg})`,
        backgroundSize: "cover",
      }}
    >
      <CardBody>
        <div
         
         
          className="p-16 px-16 "
        >
             <div className="mt-10 ">
              {[
                {
                  label: "Vehicle Base Price",
                  name: "vehicleBasePrice",
                editable: false,
                defaultVal: priceData?.base
                },
                {
                  label: "Vehicle Taxes & Fees",
                  name: "vehicleTaxesFees",
                  
                editable: false,
                defaultVal: priceData?.fees
                },
                {
                  label: "Total",
                  name: "total",
                  
                editable: false,
                defaultVal: priceData?.total
                },
              ].map(({ name, label,editable,defaultVal }, idx) => {
                return (
                  <div
                    className=" flex flex-col md:flex-row justify-between gap-4 mb-4"
                    key={idx}
                  >
                    <Typography
                      variant="textFieldLabel"
                      className="mt-2 text-bold min-w-[10rem]"
                    >
                      {label}
                    </Typography>

                    <Input
                      variant="form"
                      size="compact"
                      type="number"
                      isDisabled={!editable}
                      defaultValue={defaultVal}
                      className=""
                      classNames={{
                        input: "data-[has-start-content=true]:ps-16 p-5",
                       
                      }}
                    />
                  </div>
                );
              })}

              <div className="flex flex-col md:flex-row  gap-4 justify-between  ">
                <div className="min-w-[10rem]"></div>
                <Button
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm 2xl:text-lg w-full py-6 tracking-wide  xl:px-8 2xl:px-6 rounded-xl mt-5"
                  onClick={onCallback}
                  isLoading={loading}
                >
                  Escrow.com
                </Button>
               
              </div>

              <div className="text-xl mt-10">
                If using{" "}
                <a href="https://www.escrow.com/" className="underline">
                  Escrow.com
                </a>
                , once payment is received you will be redirected to our site to
                complete the transaction.
              </div>
            </div>
        </div>
      </CardBody>
    </Card>
  );
}
const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1  md:grid-cols-1 xl:grid-cols-2 justify-items-left mb-5 align-baseline  w-full ">
      {children}
    </div>
  );
};
