import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";

import { env } from "config/env";
import { UserResponse } from "services";
import { localStorageFn } from "utils/localstorage";
import { getUserRoles } from "utils/get-user-id";
interface DropdownProps {
  name: string;
  userId: number;
  profilePictureData: UserResponse | undefined;
}

export default function ProfileDropdown({
  name,
  userId,
  profilePictureData,
}: DropdownProps) {
  const userRole = getUserRoles();
  const navigate = useNavigate()
  let items = [
    {
      key: "View/Edit Profile",
      label: "View Profile",
      link: userRole === "Dealer" ? "/user-profile":"/user/user-profile",
    },
    {
      key: "View/Edit/Login/Membership",
      label: "View Membership",
      link: "/user-membership",
    },
    {
      key: "Log Out",
      label: "Log Out",
      link: userRole === "Dealer" ? "/login" : "/login/user",
    },
  ];

  if (userRole === "User") {
    items = items.filter((item) => item.key !== "View/Edit/Login/Membership");
  }

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button className="bg-transparent flex gap-4 p-0">
          <span className="text-lg font-[500]">{name}</span>
          <img
            className="inline-block size-[38px] rounded-full object-fill"
            src={
              profilePictureData
                ? `${env.BASE_API_URL}User/image/${userId}`
                : "/assets/images/avatar/user.jpg"
            }
            alt="Img Description"
          ></img>
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Dynamic Actions" className="px-4" items={items}>
        {(item) => (
          <DropdownItem
            key={item.key}
            onClick={
              item.key === "Log Out"
                ? () => {
                    localStorageFn.removeItem("access_token");
                    localStorageFn.removeItem("refresh_token");
                  navigate( userRole === "Dealer" ? "/login" : "/login/user")
                  }
                : () => {}
            }
          >
            <Link
              to={item.link}
              type="button"
              className="text-base font-[500] w-full"
            >
              {item.label}
            </Link>
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
