import {
  Button,
  Image,
  Modal,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";

import { TextErrorHelper, Typography } from "components";
import Input from "components/ui/input";
import { useState } from "react";
import { HistoryDataResponse, usePurchasedReports } from "services";
import { getUserId } from "utils/get-user-id";
import SaveReportsDetails from "./components/SaveReportsDetails";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { VehicleFactoryInstalled } from "pages/shared";

export const SaveReportsPage = () => {
  const [subscriptionKey, setSubscriptionKey] = useState("FMO_MARKET_VALUE");
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [factoryData, setFactoryData] = useState<any>({});
  const [vehicleHistoryData, setVehicleHistoryData] = useState<
    HistoryDataResponse | undefined
  >(undefined);
  // const [errorVehicleHistoryData, setErrorVehicleHistoryData] = useState<any>(
  //   {}
  // );
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { data } = usePurchasedReports(getUserId(), subscriptionKey);
  const newPlugin = defaultLayoutPlugin();

  const handleView = (
    pdfUrl: string | null,
    factoryData: any,
    vehicleHistoryData: HistoryDataResponse | undefined,
    errorVehicleHistoryData: any
  ) => {
    if (subscriptionKey === "FMO_MARKET_VALUE") {
      setPdfUrl(pdfUrl);
      setFactoryData([]);
      setVehicleHistoryData(undefined);
      // setErrorVehicleHistoryData({});
    } else {
      setPdfUrl(null);
      setFactoryData(factoryData);
      setVehicleHistoryData(vehicleHistoryData);
      // setErrorVehicleHistoryData(errorVehicleHistoryData);
    }
    onOpen();
  };

  return (
    <div className="mb-20">
      <div className="flex flex-col gap-4 items-center justify-center mb-10">
        <Typography variant="pageTitle">Saved Reports</Typography>
      </div>
      <div className="md:flex justify-between items-center w-full">
        <div className="flex gap-4">
          <Button
            className={` ${
              subscriptionKey === "FMO_MARKET_VALUE" ? "selected" : "default"
            } w-full `}
            onClick={() => setSubscriptionKey("FMO_MARKET_VALUE")}
          >
            <Typography
              variant="custom"
              className="font-[600] text-md text-white"
            >
              History Reports
            </Typography>
          </Button>
          <Button
            className={`${
              subscriptionKey === "FMO_OPTIONS_DATA_VALUE"
                ? "selected"
                : "default"
            } w-full `}
            onClick={() => setSubscriptionKey("FMO_OPTIONS_DATA_VALUE")}
          >
            <Typography
              variant="custom"
              className="font-[600] text-md text-white"
            >
              Options Reports
            </Typography>
          </Button>
        </div>
        <Input
          variant="gradient"
          className="lg:col-span-2 md:w-1/3 mt-5 md:mt-0"
          classNames={{
            input: "ml-2 text-left pt-1",
            inputWrapper: "w-full h-[54px]",
          }}
          placeholder="Search Keywords"
          defaultValue=""
          startContent={
            <Image
              src="/assets/icons/search-bar.svg"
              className="w-[20px] h-[20px]"
              removeWrapper
              radius="none"
            />
          }
        />
      </div>
      {/* <div className="relative overflow-x-auto w-full rounded-xl border-[#070C84] border-2 mt-8">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-lg text-white bg-[#070c84]">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
              >
                Vin
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
              >
                Make
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
              >
                Model
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center border-l border-[#C9C9C9] text-base md:text-xl"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data?.map(({ vin, make, model, name, listingId }) => (
                <SaveReportsDetails
                  listingId={listingId}
                  vin={vin}
                  handleView={handleView}
                />
              ))}
          </tbody>
        </table>
        {data && data.length === 0 && (
          <div className="text-center md:text-4xl p-3">No Save Reports</div>
        )}
      </div> */}
      <div className="col-span-4  pb-20 overflow-y-auto mt-8">
        {data && data.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 4xl:grid-cols-8 gap-4">
            {data?.map((car) => {
              return (
                <SaveReportsDetails
                  listingId={car.listingId}
                  vin={car.vin}
                  handleView={handleView}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center mt-8">
            <Typography variant="subtitle" className="pb-16">
              No Saved Reports Found!
            </Typography>
          </div>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        size="5xl"
        placement="center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              {subscriptionKey === "FMO_MARKET_VALUE" ? (
                <div className="w-full h-screen  lg:h-[700px] 2x:h-[800px] p-10">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdfUrl || ""} plugins={[newPlugin]} />
                  </Worker>
                </div>
              ) : vehicleHistoryData ? (
                <div className="flex flex-row gap-2 p-10 gap-4 h-full lg:h-[800px]">
                  <VehicleFactoryInstalled data={factoryData} />
                </div>
              ) : (
                <div className="flex flex-row gap-2 p-10 gap-4 h-[500px] lg:h-[800px] items-center justify-center">
                  <Image
                    src="/assets/icons/error-outline.svg"
                    alt="error"
                    className="min-w-[24px] min-h-[24px]"
                  />
                  <TextErrorHelper className="text-lg">
                    We’re on it! An automated report wasn’t available for this
                    vin, however, we are looking to find the options for you, a
                    support ticket has been opened
                  </TextErrorHelper>
                </div>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
