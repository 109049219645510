import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CounterState {
  pricing: string | null
}

const initialState: CounterState = {
  pricing: null,
}

export const dmvSlice = createSlice({
  name: 'dmv',
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    changePricingForDmv: (state, action: PayloadAction<string|null>) => {
      state.pricing = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {  changePricingForDmv } = dmvSlice.actions

export default dmvSlice.reducer