import * as React from "react";
import { Button, Card, CardBody, CardFooter } from "@nextui-org/react";

import { Typography } from "components";
import Form from "components/ui/form";
import {
  EnterMissingInformationValidationSchema,
  EnterMissingInformationValidationSchemaForQuotes,
} from "./validation";
import { VehicleTitledIn } from "./missing-information/vehicle-titled-in.component";
import { DoesVehicleHaveLoan } from "./missing-information/does-vehicle-have-loan.component";
import { BuyerOneDetails } from "./missing-information/buyer-one-details.component";
import { BuyerTwoDetails } from "./missing-information/buyer-two-details.component";
import { SellerOneDetails } from "./missing-information/seller-one-details.component";
import { SellerTwoDetails } from "./missing-information/seller-two-details.component";
import { NewUsedVinSection } from "./missing-information/new-used-vin-section.component";
import { UploadFileSection } from "./missing-information/upload-file-section.component";
import { ChooseYourPlanModal } from "./choose-your-plan-modal.component";
import { RequiredDetails } from "./missing-information/required-details.component";
import { useDmvTilting } from "./use-dmv-tilting.hook";
import { useUploadDMVTitlingFiles } from "services/tiling.service";

interface Props {
  isHideTitle?: boolean;
  onCallback?: () => void;
  fullService?: boolean;
  navigateOnSubmit?: boolean 
}

export interface IFileInput {
  license: File | null;
  insurance: File | null;
}
export const EnterMissingInformationForm = (props: Props) => {
  const { fullService = false } = props;
  const [selectedFiles, setSelectedFiles] = React.useState<IFileInput>({
    license: null,
    insurance: null,
  });
  
  const {
    isHideTitle,
    isOpen,
    loading,
    onOpenChange,
    transferPlates,
    doesHaveLoan,
    buyerTwo,
    sellerTwo,
    place,
    handleTransferPlates,
    listingDetailsData,
    sellerDetailsData,
    setZipValueBuyerOne,
    handleSubmit,
    setDoesHaveLoan,
    setBuyerTwo,
    setSellerTwo,
    setPlace,
    setZipValue,
    zipcodeData,
    params,
  } = useDmvTilting({ ...props, selectedFiles });
  // const { mutateAsync: uploadDMVTitlingFilesAsycn } =
  //   useUploadDMVTitlingFiles();
  // const handleAtcUploadDoc = (atcData: any) => {
  //   const formData = new FormData();
  //   selectedFiles.insurance &&
  //     formData.append("currentInsurance", selectedFiles.insurance);
  //   selectedFiles.license &&
  //     formData.append("driverLicense", selectedFiles.license);

  //   uploadDMVTitlingFilesAsycn({
  //     qId: atcData?.quoteId || "0",
  //     payload: formData,
  //   });
  // };
  const handleChangeDMVDoc = (event: any, fileType: string) => {
    setSelectedFiles({
      ...selectedFiles,
      [fileType]: event.target.files[0],
    });
  };

  return (
    <div className=" md:px-10 xl:px-22 ">
      {!isHideTitle && (
        <div className="flex flex-col gap-4 items-center justify-center">
          <Typography variant="pageTitle">Enter Missing Information</Typography>
        </div>
      )}
      <div className="grid xl:grid-cols-8 gap-2 items-baseline">
        <div className="hidden"></div>
        <div className="col-span-8">
          <div className="mt-10">
            <Form
              id="dmv-missing-details-form"
              schema={
                fullService
                  ? EnterMissingInformationValidationSchema
                  : EnterMissingInformationValidationSchemaForQuotes
              }
              onSubmit={handleSubmit}
            >
              {({ formState: errors, register, setValue, getValues }) => {
                console.log("eerrrer", errors);
                return (
                  <Card
                    isPressable={false}
                    radius="lg"
                    className="max-w-[58rem] w-full bg-[#F1F1F180] m-auto bg-no-repeat"
                    style={{
                      backgroundImage:
                        "url(assets/images/header-background.png)",
                    }}
                    id="dmvformdatails"
                  >
                    <CardBody className="flex my-6 md:px-16  overflow-y-auto gap-4 px-4">
                      <VehicleTitledIn
                        register={register}
                        errors={errors}
                        transferPlates={transferPlates}
                        handleTransferPlates={handleTransferPlates}
                        setZipValue={setZipValue}
                        zipcodeData={zipcodeData}
                        setPlace={setPlace}
                        place={place}
                      />
                      <DoesVehicleHaveLoan
                        register={register}
                        errors={errors}
                        doesHaveLoan={doesHaveLoan}
                        setDoesHaveLoan={setDoesHaveLoan}
                        fullService={fullService}
                      />
                      <RequiredDetails
                        register={register}
                        errors={errors}
                        vehiclePrice={Number(
                          listingDetailsData?.listing.actualSalePrice
                        )}
                      />
                      {fullService && (
                        <>
                          <BuyerOneDetails
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                          />
                          <BuyerTwoDetails
                            register={register}
                            errors={errors}
                            buyerTwo={buyerTwo}
                            setBuyerTwo={setBuyerTwo}
                          />
                          <SellerOneDetails
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            sellerDetailsData={sellerDetailsData}
                          />
                          <SellerTwoDetails
                            register={register}
                            errors={errors}
                            sellerTwo={sellerTwo}
                            setSellerTwo={setSellerTwo}
                          />
                          <NewUsedVinSection
                            register={register}
                            errors={errors}
                            params={params}
                          />
                          <UploadFileSection
                            handleChangeDMVDoc={handleChangeDMVDoc}
                            selectedFiles={selectedFiles}
                          />
                        </>
                      )}
                      <FormControl>
                        <div className="col-span-2"></div>
                        <Button
                          isLoading={loading}
                          className="col-span-3 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
                          type="submit"
                        >
                          Continue
                        </Button>
                      </FormControl>
                      <div className="mb-20" />
                    </CardBody>
                    <CardFooter
                      className="h-60 bg-cover bg-center bg-[#F1F1F180] -mt-64"
                      style={{
                        backgroundImage:
                          "url(assets/images/footer-background.png)",
                      }}
                    ></CardFooter>
                  </Card>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
      {/* 
      <ChooseYourPlanModal
        open={isOpen && !isHideTitle}
        onOpenChange={onOpenChange}
        listingId={params.listingId || ""}
        onATCSelect={handleAtcUploadDoc}
        /> */}
    </div>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
