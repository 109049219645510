import React from "react";
import { Button, Card, CardBody } from "@nextui-org/react";
import Form from "components/ui/form";
import { Typography } from "components";
import { PaymentFinancingValidationSchema } from "./VehicleOptPaymentValidationSchema";
import Input from "components/ui/input";
import paymentBg from "../../../pages/dealer/offer-accepted/assets/payment-bg.png";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

interface Props {
  onCallback: () => void;
  // onApplyOnline: () => void;
  btnLabel: string;
  messageTop?: string;
  messageMid?: string;
  messageBottom?: string;
  priceData?: { ship: string; base: string };
}

export default function FinancingOfferPaymentsForm({
  btnLabel,
  onCallback,
  messageTop,
  messageMid,
  messageBottom,
  priceData
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ DownPayment: string }>({
    resolver: zodResolver(PaymentFinancingValidationSchema),
  });

  const onSubmit = async (data: { DownPayment: string }) => {
    onCallback();
  };
  return (
    <Card
      className="min-w-[340px] "
      style={{
        backgroundImage: `url(${paymentBg})`,
        backgroundSize: "cover",
      }}
    >
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)} className="p-16 px-16 ">
          <div className="mt-10 ">
            {[
              {
                label: "Vehicle Base Price",
                name: "vehicleBasePrice",
                editable: false,
                defaultVal: priceData?.base
              },
              {
                label: "Shipping Fees",
                name: "ShippingFees",
                editable: false,
                defaultVal: priceData?.ship
              },
              {
                label: "- Down Payment",
                name: "DownPayment",
                editable: true,
              },
            ].map(({ name, label, editable,defaultVal }, idx) => {
              return (
                <div
                  className=" flex flex-col md:flex-row justify-between gap-4 mb-4"
                  key={idx}
                >
                  <Typography
                    variant="custom"
                    className={`mt-2  min-w-[10rem] ${
                      editable ? "font-bold text-black" : ""
                    }`}
                  >
                    {label}
                  </Typography>

                  <Input
                    variant="form"
                    size="compact"
                    type="number"
                    errorMessage={errors[name as keyof typeof errors]?.message}
                    isDisabled={!editable}
                    defaultValue={defaultVal}
                    className=""
                    classNames={{
                      input: "data-[has-start-content=true]:ps-16 p-5",
                      inputWrapper: !!errors[name as keyof typeof errors]
                        ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                        : "",
                    }}
                    {...(editable ? register("DownPayment") : {})}
                  />
                </div>
              );
            })}
            <div>{messageTop}</div>
            <div className="flex flex-col  flex-wrap  ">
              {/* <div className="min-w-[10rem]"></div> */}
              {messageMid}
              <Button
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm 2xl:text-lg w-full py-6 tracking-wide  xl:px-8 2xl:px-6 rounded-xl mt-5"
                type="submit"
                isLoading={false}
              >
                {btnLabel}
              </Button>
            </div>
            <div className="mt-4">{messageBottom}</div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
