import { SVGProps } from "react";

export const FileThumbnailIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      {...props}
    >
      <path
        d="M25.5938 21.125C25.5938 20.8018 25.4653 20.4918 25.2368 20.2632C25.0082 20.0347 24.6982 19.9062 24.375 19.9062H14.625C14.3018 19.9062 13.9918 20.0347 13.7632 20.2632C13.5347 20.4918 13.4062 20.8018 13.4062 21.125C13.4062 21.4482 13.5347 21.7582 13.7632 21.9868C13.9918 22.2153 14.3018 22.3438 14.625 22.3438H24.375C24.6982 22.3438 25.0082 22.2153 25.2368 21.9868C25.4653 21.7582 25.5938 21.4482 25.5938 21.125ZM25.5938 27.625C25.5938 27.3018 25.4653 26.9918 25.2368 26.7632C25.0082 26.5347 24.6982 26.4062 24.375 26.4062H14.625C14.3018 26.4062 13.9918 26.5347 13.7632 26.7632C13.5347 26.9918 13.4062 27.3018 13.4062 27.625C13.4062 27.9482 13.5347 28.2582 13.7632 28.4868C13.9918 28.7153 14.3018 28.8438 14.625 28.8438H24.375C24.6982 28.8438 25.0082 28.7153 25.2368 28.4868C25.4653 28.2582 25.5938 27.9482 25.5938 27.625Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.375 3.65625C10.1898 3.65625 9.05317 4.12706 8.21512 4.96512C7.37706 5.80317 6.90625 6.93981 6.90625 8.125V30.875C6.90625 32.0602 7.37706 33.1968 8.21512 34.0349C9.05317 34.8729 10.1898 35.3438 11.375 35.3438H27.625C28.8102 35.3438 29.9468 34.8729 30.7849 34.0349C31.6229 33.1968 32.0938 32.0602 32.0938 30.875V12.948C32.0938 12.3289 31.8923 11.7276 31.5185 11.2336L26.6468 4.78562C26.3817 4.43481 26.0389 4.15025 25.6453 3.95429C25.2516 3.75833 24.8179 3.65631 24.3783 3.65625H11.375ZM9.34375 8.125C9.34375 7.00375 10.2538 6.09375 11.375 6.09375H23.1562V13.2389C23.1562 13.9116 23.7022 14.4576 24.375 14.4576H29.6562V30.875C29.6562 31.9963 28.7463 32.9062 27.625 32.9062H11.375C10.2538 32.9062 9.34375 31.9963 9.34375 30.875V8.125Z"
        fill="white"
      />
    </svg>
  );
};
