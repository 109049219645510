import {  Card, CardBody } from "@nextui-org/react";
import React, { useState }  from "react";
import {  Button, DateTextFieldWithStartDate, Toggle, Typography } from "components";
import Input from "components/ui/input";
import { formatDateToShortDate } from "utils";
import { VehicleRepairSubscriptionPayload } from "modules/user/services/endpoints/repair/repair-agreement.type";
import { useUpdateVehicleServiceContract } from "modules/user/hooks/api/mutations/benefits/repair-agreement";
import { useDebounceCallback } from "usehooks-ts";


interface Props {
  vscStatus?:VehicleRepairSubscriptionPayload 
  handleViewAll:()=>void; 
}

export default function VehicleServiceContractDetails({vscStatus,handleViewAll}:Props) {
  const [data, setData] = useState<{odaMeterVerified:boolean,oilChangeDate:Date|null}>({ odaMeterVerified: vscStatus?.isOdometerVerified || false,oilChangeDate:null })
  const {
    mutateAsync: UpdateVehicleServiceContractAsync,
  } = useUpdateVehicleServiceContract();

  const hadleUpdate = useDebounceCallback(async (key1:string,value1: boolean,key2:string,value2:string)=>{
    const payloadData = {
      userId: vscStatus?.userId,
      vin: vscStatus?.vin,
      state: vscStatus?.state,
      agreedMiles: vscStatus?.agreedMiles,
      agreementAmount: vscStatus?.agreementAmount,
      // isOdometerVerified: !data.odaMeterVerified,
      listingId: vscStatus?.listingId,
      paymentStatus: vscStatus?.paymentStatus,
      // oilChangeInspection: data.oilChangeDate?.toISOString() ?? vscStatus?.oilChangeInspection,
      contractStartDate: vscStatus?.contractStartDate,
      expiryDate: vscStatus?.expiryDate,
      subscriptionKey: vscStatus?.subscriptionKey,
      monthsRemaining: vscStatus?.monthsRemaining,
      [key1]:value1,
      [key2]:value2,

    };
    await UpdateVehicleServiceContractAsync(payloadData)
  },1000)
 
  return (
    <div   className="flex flex-col gap-8"
    >
      <div className="flex flex-col gap-7 items-center justify-center">
        <Typography
          variant="title"
          className="font-[Metropolis] text-2xl lg:text-3xl font-bold text-black text-center 
        bg-gradient-to-r from-blue-600 to-indigo-900 text-transparent bg-clip-text"
        >
          Vehicle Service Contract
        </Typography>
        <div className=" w-full  flex justify-between ">
          <div></div>
          <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl"
            type="button"
            size="md"
            onClick={() => handleViewAll()}
          >
            <Typography className="font-bold" variant="body">
              {" "}
              View All
            </Typography>
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center  gap-12">
        <Card
          className="min-w-[370px] max-w-[48rem] w-full  bg-no-repeat"
          style={{
            backgroundImage: "url(assets/images/header-background.png)",
          }}
        >
          <CardBody className="flex justify-center items-center mt-2 md:p-24">
            <div className="grid">
              <FormControl>
                <Typography
                  variant="custom"
                  className=" font-[600] col-span-2 text-left"
                >
                  Odometer Verified?
                </Typography>
                <Toggle
                  size="sm"
                  checked={data.odaMeterVerified}
                  defaultSelected={vscStatus?.isOdometerVerified}
                  isDisabled={true}
                  onChange={async (e)=> { 
                    await setData((p)=>({...p,odaMeterVerified:e.target.checked}));
                   await hadleUpdate("isOdometerVerified",e.target.checked,"oilChangeInspection", vscStatus?.oilChangeInspection||"")}}
                  color="default"
                />
              </FormControl>
              <FormControl>
                <Typography
                  variant="custom"
                  className=" font-[600] col-span-2 text-left"
                >
                  Most Recent Oil Change Inspection
                </Typography>
                <div className="col-span-2">
                <DateTextFieldWithStartDate 
                          
                          initDate={new Date(vscStatus?.oilChangeInspection||"")}
                          onDateChange={async(date) => {
                          await setData((p)=>({...p,oilChangeDate: date }));
                          await hadleUpdate("isOdometerVerified", vscStatus?.isOdometerVerified || false ,"oilChangeInspection",date?.toISOString() || vscStatus?.oilChangeInspection||"")
                          }}
                        />
                </div>
              </FormControl>{" "}
              <FormControl>
                <Typography
                  variant="custom"
                  className=" font-[600] col-span-2 text-left"
                >
                  Contract Start Date
                </Typography>
                <div className="col-span-2">
                
                  <Input
                  disabled
                  variant="form"
                  color="disabled"
                  size="compact"
                  className="col-span-2 text-center"
                  classNames={{ input:"text-center"}}
                  value={String(formatDateToShortDate(new Date(vscStatus?.contractStartDate||"")))}
                />
                </div>
              </FormControl>
              <FormControl>
                <Typography
                  variant="custom"
                  className=" font-[600] col-span-2 text-left"
                >
                  Months Remaining
                </Typography>
                <Input
                  disabled
                  variant="form"
                  color="disabled"
                  size="compact"
                  className="col-span-2 text-center"
                  classNames={{ input:"text-center"}}
                  value={String(vscStatus?.monthsRemaining||0)}
                />
              </FormControl>
            </div>
            <Typography
              variant="custom"
              className=" font-[600] col-span-2 text-left mt-6"
            >
              Please open a support case for mechanical breakdown, if you need
              roadside assistance please chat with out team live 24/7
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>

  );
}

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-5 justify-items-stretch mb-5 align-baseline items-center">
      {children}
    </div>
  );
};
