import React from "react";
import { Card, CardBody, Chip, Image } from "@nextui-org/react";

import { Button, Typography } from "components";
import { env } from "config/env";
import { ViewIcon } from "./ViewIcon";
import { EditIcon } from "./EidtIcon";

interface Props {
  car: any;
  imgURl: string;
  onSelect: (data: any) => void;
  onSelect2?: (data: any) => void;
  onClick?: (data: any) => void;
  cardOnClick?: (data: any) => void;
  btnLabel?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  line5?: string;
  status?:string;
}
export default function SearchCarCard({
  car,
  onSelect,
  onSelect2,
  onClick,
  cardOnClick,
  btnLabel = "Select",
  line1,
  line2,
  line3,
  line4,
  line5,
  imgURl,
  status
}: Props) {
  return (
    <Card
      className="border border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors rounded-2xl"
      shadow="none"
      fullWidth
      isPressable={cardOnClick ? true : false}
      onPress={() => cardOnClick && cardOnClick(car)}
    >
      <CardBody className="flex flex-col">
        <div className="bg-white rounded-3xl h-full w-full">
          <div className="relative col-span-2">
            {imgURl && (
              <Image
                removeWrapper
                src={imgURl}
                alt="car"
                onClick={() => onClick && onClick(car)}
                className="w-full h-56 xl:h-40 2xl:h-48 object-cover cursor-pointer"
              />
            )}
            {btnLabel === "pendingSales" && status && (
              <Chip className="absolute top-0 right-0 z-10 text-xs m-2 bg-white border border-blue-500  font-[Metropolis]">
                {status}
              </Chip>
            )}
          </div>
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="pt-4 text-xl sm:text-sm lg:text-xl xl:text-base text-center font-[800] text-[#263238] min-h- overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {line1}
            </Typography>
            <Typography
              variant="custom"
              className="font-bold text-center text-xl  text-[#263238] h-8  sm:text-sm lg:text-xl xl:text-base "
            >
              {line2}
            </Typography>

            <Typography
              variant="custom"
              className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm"
            >
              {line3}
            </Typography>
            {line4 && (
              <Typography
                variant="custom"
                className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm"
              >
                {line4}
              </Typography>
            )}
            {line5 && (
              <Typography
                variant="custom"
                className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm"
              >
                {line5}
              </Typography>
            )}
            {/* <div className="grid grid-cols-3 justify-items-center mt-2 font-[800]">
              <Typography>
                {money(Number(car.listingDetail.basicDetail.mileage)) || 0} mi
              </Typography>
              <Typography className="text-center">
                Days Listed:
                <br /> 0
              </Typography>
              <Typography className="text-center">
                Viewed?
                <br /> Y
              </Typography>
            </div> */}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center mt-auto gap-4 ">
          {/* <Button
            customVariant="gradient"
            className="w-full w-max-[12rem] overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            <Typography
              variant="label"
              className="text-lg md:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-[900] overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {car.sellerDetail?.city || car.sellerDetail?.state
                ? car.sellerDetail?.city +
                  ", " +
                  car.sellerDetail?.state?.toLocaleUpperCase()
                : "N/A"}
            </Typography>
          </Button> */}
          {btnLabel === "pendingSales" ? (
            <ViewIcon
              onClick={() => onSelect(car)}
              className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
            />
          ) : btnLabel === "soldAndArchived" ? (
            <div className="relative flex items-center justify-center">
              <ViewIcon
                onClick={() => onSelect(car)}
                className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
              />
              <div className="pl-6">
                <EditIcon
                  onClick={() => onSelect2 && onSelect2(car)}
                  className="h-[40px] w-[40px] cursor-pointer hover:scale-110"
                />
              </div>
            </div>
          ) : (
            <Button
              customVariant="gradient"
              className=" w-auto h-auto sm:py-1 rounded-xl sm:rounded-md lg:rounded-lg py-2 px-8"
              onClick={() => onSelect(car)}
              // isLoading={saveUserListingPending}
            >
              <Typography
                variant="custom"
                className="text-sm sm:text-xs lg:text-base xl:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-bold"
              >
                {btnLabel}
              </Typography>
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
