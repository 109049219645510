import * as React from "react";
import { Accordion, AccordionItem } from "@nextui-org/accordion";

import "./index.scss";
import { PayNow, PaymentModal, VehicleFactoryInstalled } from "pages/shared";
import {
  useGetPriceAdvanceDataPdf,
  useGetVehicleHistoryData,
  useGetVindataDecode,
} from "services";
import downArrow from "assets/svgs/gradient-down-arrow.svg";
import { transformData } from "utils/transform-data";
import ListingLoopView from "modules/dealer/components/manage-listing/ListingLoopView";
import { PaymentCardInfo } from "services/endpoints/shipping-quotes/shipping.type";
import { useLocation } from "react-router-dom";
import { localStorageFn } from "utils/localstorage";
import { useAddPaymenTransactiontMethod } from "hooks/api/mutations/shipping/use-shipping-quotes";
import { useProcessFMOReportSubscription } from "hooks/api/queries/view-cars/view-cars";
import { ListingPaymentCustomOpt } from "pages/public/view-car/accordion-view-car.component";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import { Image } from "@nextui-org/react";
import { TextErrorHelper, Typography } from "components";
import VehicleConditions from "pages/public/private-party-services/sell-instant-quote/components/VehicleConditions";

interface AccordionCarDataProps {
  vinNumber: string;
  listingId: string;
  isCardAdded: PaymentCardInfo | undefined;
  listingAdvanceDataRefetch: () => void;
  refetchPaymentForCardDetail: () => void;
}
export const AccordionCarData: React.FC<AccordionCarDataProps> = ({
  vinNumber,
  listingId,
  isCardAdded,
  listingAdvanceDataRefetch,
  refetchPaymentForCardDetail,
}) => {
  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);
  const [accordionClick, setAccordionClick] = React.useState(false);
  const [factoryData, setFactoryData] = React.useState<any>([]);
  // const useOpt: { factory?: boolean; pdf?: boolean } =
  //   localStorageFn.getItem("usOpt") || {};

  const location = useLocation();
  const [showPayModal, setShowPayModal] = React.useState<{
    show: boolean;
    ammount: string;
    tierId: ListingPaymentCustomOpt;
  }>({
    show: false,
    ammount: "",
    tierId: "",
  });

  const { data: priceAdvanceData, refetch: refetchPriceAdvanceData, isLoading: isPendingPdf, error:errorPDF } =
    useGetPriceAdvanceDataPdf(vinNumber, location.state?.usOpt ? false: true);
  

  const { data: vinDecodeData } = useGetVindataDecode(vinNumber);
  const {
    data: vehicleHistoryData,
    refetch: refetchVehicleHistoryData,
    error: errorVehicleHistoryData,
    isLoading: isPendingOptions
  } = useGetVehicleHistoryData(vinNumber, location.state?.usOpt ? false: true);

  //payment related hooks
  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error:AddPaymentError
  } = useAddPaymenTransactiontMethod();
  const {
    mutateAsync: mutateProcessFMOReportSubscription,
    isPending: isPendingToProcessFMOReportSubscription,
    error: fmoReportProcessError
  } = useProcessFMOReportSubscription(listingId, vinNumber);

  
  React.useEffect(()=>{
    if(location.state?.usOpt && listingId){
      try{
        const callPaymentAPI = async() =>{         
        await mutateProcessFMOReportSubscription("FMO_MARKET_VALUE"as any);
        await mutateProcessFMOReportSubscription("FMO_OPTIONS_DATA_VALUE");
        await mutateProcessFMOReportSubscription("FMO_VIN_AUDIT_JSON");
        }
        callPaymentAPI().finally(()=>{
          
          refetchPriceAdvanceData()
          refetchVehicleHistoryData()
          listingAdvanceDataRefetch();
        })
        
      }catch(e){
        console.log(e)
      }
      
    }
  },[])
  React.useEffect(() => {
    if (priceAdvanceData) {
      const blob = new Blob([priceAdvanceData], { type: "application/pdf" });
      setPdfUrl(URL.createObjectURL(blob));
    }
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [priceAdvanceData]);

  React.useEffect(() => {
    if (vehicleHistoryData)
      setFactoryData(transformData(vehicleHistoryData) || {});
  }, [vehicleHistoryData]);

  const onClickAccordion = () => {
    setTimeout(() => {
      setAccordionClick(!accordionClick);
    }, 100);
  };

  const handlePaymentOnLisingOption = async (
    ammount: string,
    key: ListingPaymentCustomOpt
  ) => {
    setShowPayModal({ ammount: ammount, show: true, tierId: key });
  };
  const handleConfirmPaymentForOpt = async (e: any) => {
    try {
      if (isCardAdded) {
        await mutateProcessFMOReportSubscription(showPayModal.tierId);        
      } else {
        await mutateAddPaymenTransactiontMethod(e);
        await mutateProcessFMOReportSubscription(showPayModal.tierId);
        refetchPaymentForCardDetail();
      }
      //  setSelect(showPayModal.tierId as ListingPaymentCustomOpt)
      setShowPayModal((p) => ({ ...p, show: false }));
      if (showPayModal.tierId === "FMO_OPTIONS_DATA_VALUE") {
        refetchVehicleHistoryData();
      }

      if (showPayModal.tierId === "FMO_MARKET_VALUE") {
        refetchPriceAdvanceData();
        await mutateProcessFMOReportSubscription("FMO_VIN_AUDIT_JSON");
        listingAdvanceDataRefetch();
      }
    } catch (e) {
      console.log("Error :", e);
    }
  };
  
console.log("errorVehicleHistoryData",)
  return (
    <>
      <Accordion
        variant="splitted"
        className="flex mt-10 h-full px-0"
        showDivider
        fullWidth
        selectionMode="multiple"
        itemClasses={{
          base: "p-2",
          heading:
            "text-xl font-[600] bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text",
          content: "",
          indicator: "text-[#018ffd]",
          title:
            "text-transparent font-bold bg-gradient-to-br from-[#018ffd] to-[#070c84] bg-clip-text text-xl font-[600]",
          startContent: "text-lg font-bold",
        }}
      >
        <AccordionItem
          key="2"
          aria-label="Vin Data"
          title="Vin Data"
          className="bg-[#F1F1F180]"
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
        >
          <ListingLoopView
            data={
              vinDecodeData && vinDecodeData?.message?.length > 0
                ? vinDecodeData?.message?.slice(5)
                : []
            }
          />
          {/* <VehicleHistoryReport
      data={
        vinDecodeData && vinDecodeData?.message?.length > 0
          ? vinDecodeData?.message?.slice(5)
          : []
      }
    /> */}
        </AccordionItem>
        <AccordionItem
          key="1"
          aria-label="View Full History Report"
          title="View Full History Report"
          className="bg-[#F1F1F180]"
          classNames={{
            content: "flex flex-col items-center justify-center",
          }}
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
        >
          {pdfUrl ? (
            <iframe
              title="PDF Viewer"
              src={pdfUrl}
              width="100%"
              height="500px"
            ></iframe>
          ) : (
            <div className="flex flex-col justify-center items-center max-w-[42rem] w-full   ">
              <PayNow
                title="Vehicle History Report"
                price={4.99}
                isLoading={isPendingPdf}
                onPay={() =>
                  handlePaymentOnLisingOption("4.99", "FMO_MARKET_VALUE")
                }
              />
              {(errorVehicleHistoryData?.response?.status !== 402 || location.state?.usOpt) && <Typography variant="error">
                {fmoReportProcessError?.message ||errorPDF?.message  }
              </Typography>}
            </div>
          )}
        </AccordionItem>

        <AccordionItem
          key="3"
          aria-label="Factory Installed Options"
          title="Factory Installed Options"
          className="bg-[#F1F1F180]"
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
          onPressStart={onClickAccordion}
          classNames={{
            content: "flex flex-col items-center justify-center",
          }}
        >
          {vehicleHistoryData ? (
            factoryData?.Options  ?<VehicleFactoryInstalled data={factoryData} />:<div className="flex flex-row gap-2 mr-2 p-4">
            <Image src="/assets/icons/error-outline.svg" alt="error" />
            <TextErrorHelper className="text-lg">
              Failed to Load Data! please try again.
            </TextErrorHelper>
          </div>
          ) : errorVehicleHistoryData?.response?.status === 404 ? (
            <div className="flex flex-row gap-2 mr-2 p-4">
              <Image src="/assets/icons/error-outline.svg" alt="error" />
              <TextErrorHelper className="text-lg">
                We’re on it! An automated report wasn’t available for this vin,
                however, we are looking to find the options for you, a support
                ticket has been opened
              </TextErrorHelper>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center max-w-[42rem] w-full   ">
              <PayNow
                title="Factory Installed Options"
                price={0.99}
                isLoading={isPendingOptions}
                onPay={() =>
                  handlePaymentOnLisingOption("0.99", "FMO_OPTIONS_DATA_VALUE")
                }
              />
              
              {(location.state?.usOpt || errorVehicleHistoryData?.response?.status !== 402) &&<Typography variant="error">
                {fmoReportProcessError?.message ||errorVehicleHistoryData?.message  }
              </Typography>}
            </div>
          )}
          {/* <VehicleFactoryInstalled data={factoryData} /> */}
          {/* <div
          className={`px-6 w-full overflow-y-auto  h-[500px] p-2 lg:p-5  factory-shadow-fade-in mt-[-20px] pt-3 pb-1 ${accordionClick ? "fade-in" : "fade-out"}`}
        >
          <p className="w-full flex flex-col justify-between  items-start p-1 lg:p-3 gap-2">
            <span className="text-white gradient-text ">Color</span>
            <span className="text-black bg-[#f1f1f1]/50 p-4 w-full rounded-full">
              {factoryData?.Color}
            </span>
          </p>

          <p className="w-full flex flex-col justify-between  items-start  p-1 lg:p-3 gap-2">
            <span className="gradient-text ">Interior</span>
            <span className="text-black bg-[#f1f1f1]/50 p-4 w-full rounded-full">
              {factoryData?.Interior}
            </span>
          </p>
          <p className="w-full flex justify-between  items-center p-1 lg:p-3">
            <span className="text-white gradient-text ">Options:</span>
          </p>
          <div>
            {factoryData?.Options &&
              factoryData?.Options.map((vin: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    className={`flex justify-between w-full py-2 ${vin?.variable ? "bg-white" : "bg-[#f1f1f1]/50"} rounded-full dark:bg-gray-600 mb-3 p-2`}
                  >
                    <p className="w-full flex justify-between  items-center p-1 lg:p-3">
                      <span className="text-black">{vin}</span>
                    </p>
                  </div>
                );
              })}
          </div>
        </div> */}
        </AccordionItem>
        <AccordionItem
          key="4"
          aria-label="Vehicle Condition"
          title="Vehicle Condition"
          className="bg-[#F1F1F180]"
          indicator={
            <img
              src={downArrow}
              alt="down arrow"
              className="w-6 h-6 cursor-pointer"
            />
          }
        >
          <div className="gap-10 overflow-auto h-[400px]">
            <VehicleConditions isManageListing={true} />
          </div>
        </AccordionItem>
      </Accordion>
      <PaymentModal
        isOpen={showPayModal.show}
        onOpen={() => {
          setShowPayModal((p) => ({ ...p, show: false }));
        }}
        Component={
          <PaymentFormWithDetails
            handleNext={handleConfirmPaymentForOpt}
            payAmmount={showPayModal.ammount}
            savedMethod={isCardAdded}
            isPending={
              isPendingToProcessFMOReportSubscription ||
              isPendingToAddPaymenTransactiontMethod
            }
            showAmmount={showPayModal.tierId !== ""}
            errorMessage={AddPaymentError?.message || fmoReportProcessError?.message}
          />
        }
      />
    </>
  );
};
