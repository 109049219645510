import { GradientModal, Typography } from "components";
import { useEffect, useState } from "react";
import "../index.scss";
import { Button, Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import OtherOfferPaymentsFormCashZelle from "components/form/vehicle-transacttion-payments/OtherOfferPaymentsForm";
import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import paymentBg from "../assets/payment-bg.png";
import {
  DmvFeeAndViewPdfDocs,
  EnterMissingInformationForm,
} from "../../dmv-titling";
import { PayModal } from "modules/shared/components/modal/PayModal";
import ApproveSale from "./approve-sale";
import LogoIcon from "../assets/logo.svg";
import {
  useAcceptPriceRateShipping,
  useAddPaymenTransactiontMethod,
  useConfirmShippingPayment,
  useTransactionsCheckForPaymentMethod,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { useSelectAtcProcedure } from "services/tiling.service";
import { getUserId, isThisDealer } from "utils/get-user-id";
import { TilingSelectAtcProcedureRequest } from "services";
import { useNavigate, useParams } from "react-router-dom";
import { selectedProcedureSignal } from "../../dmv-titling/store";
import { AddCardMethodPayload } from "services/endpoints/shipping-quotes/shipping.type";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import DmvTransitStatus from "modules/dealer/components/dmv-title/DmvTransitStatus";
import { NewOrderForm } from "pages/shipping-quotes/new-order/new-order.form";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";
import PurchaseCompletedComponent from "./PurchaseCompletedComponent";

const steps = [
  {
    step: "Payment_1",
    title: "Payment",
    isSuccessPayment: false,
  },
  {
    step: "ShipPay",
    title: "Payment for Shipping",
    isSuccessPayment: false,
  },
  {
    step: "EnterMissingInformationForm",
    title: "Enter Missing Information",
    isSuccessPayment: false,
  },
  {
    step: "DmvFeeAndViewPdfDocs",
    title: "Documents Required",
    isSuccessPayment: false,
  },
  {
    step: "DmvTransitStatus",
    title: "Shipping Tracking",
    isSuccessPayment: false,
  },
  {
    step: "ApproveSale",
    title: "Approve Sale",
    isSuccessPayment: false,
  },
];

interface Props {}

export default function StepsForDebitCreditOffer({}: Props) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const { currectSale: saleOffer, paymentInfo } = useSelector(
    (state: RootState) => state.acceptOffer
  );
  const [activeSteps, setActiveSteps] = useState<(typeof steps)[number]>(
    paymentInfo?.shipmentRequire
      ? {
          step: "ShipPay",
          title: "Payment for Shipping",
          isSuccessPayment: false,
        }
      : {
          step: "Payment_1",
          title: "Payment",
          isSuccessPayment: false,
        }
  );

  const [showPayModal, setShowPayModal] = useState(false);
  const [acceptData, setAcceptData] = useState<{ ship: any } | null>(null);

  const params = useParams();
  const navigate = useNavigate();
  const selectedCar = useSelector((state: RootState) => state.shipping);

  // payment related hooks
  const {
    mutateAsync: mutateAsyncAccceptPayment,
    data: checkIfPaymentExist,
    isPending: isPendingToAccceptPayment,
    error: checkIfPaymentExistError,
  } = useTransactionsCheckForPaymentMethod();

  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: AddPaymentCardError,
  } = useAddPaymenTransactiontMethod();
  // shippping related hooks
  const {
    mutateAsync: mutateConfirmShippingPayment,
    isPending: isPendingToConfirmShippingPayment,
    error: shippingPaymentProcessError,
  } = useConfirmShippingPayment();

  const {
    mutateAsync: mutateAsyncAcceptPriceRateShipping,
    isPending: isPendingToAcceptPriceRateShipping,
    error: acceptShipRateError,
  } = useAcceptPriceRateShipping();

  // DMV relate hooks
  const {
    mutateAsync: selectAtcProcedureMutateAsync,
    data: atcProcedureData,
    isPending: isPendingToAtcProcedure,
  } = useSelectAtcProcedure();

  const handleOnConfirm = () => {
    const userId = getUserId();
    const payload: TilingSelectAtcProcedureRequest = {
      listingId: Number(params?.listingId ?? ""),
      quoteId: 0,
      tier1: false,
      tier2: false,
      tier3: true,
      userId,
    };
    selectAtcProcedureMutateAsync(payload);
  };

  const handleConfirmPayment = async (e: any) => {
    try {
      if (checkIfPaymentExist) {
      } else {
        const paymentData = await mutateAddPaymenTransactiontMethod(e);
        console.log("paymentData", paymentData);
      }
      window.scrollTo(0, 0);
      onOpen();
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handlePayment = async (e: any) => {
    switch (activeSteps.step) {
      case "ShipPay":
        handleConfirmPaymentForShipping(e);
        break;
      case "Payment_1":
        setActiveSteps({
          step: "EnterMissingInformationForm",
          title: "Enter Missing Information",
          isSuccessPayment: false,
        });

        setShowPayModal(false);
        onOpen();
        break;
      default:
        setShowPayModal(false);
        onOpen();
    }
  };
  const getPayAmmount = () => {
    switch (activeSteps.step) {
      case "ShipPay":
        return String(acceptData?.ship?.TotalPrice || 0);
      default:
        const base = Number(
          saleOffer?.previousCashOffer.enterYourOfferHere
        );
        const shipPay = paymentInfo?.shipmentRequire
          ? Number(acceptData?.ship?.TotalPrice) || 0
          : 0;
        const dmvFee =
          Number(saleOffer?.previousCashOffer.salesTax || 0) +
          Number(saleOffer?.previousCashOffer.dmVandFees || 0);

        return String(base+shipPay+dmvFee);
    }
  };

  const handleConfirmPaymentForShipping = async (
    data: AddCardMethodPayload
  ) => {
    try {
      if (checkIfPaymentExist) {
         await mutateConfirmShippingPayment(
          acceptData?.ship?.QuoteID || ""
        );
         await mutateAsyncAcceptPriceRateShipping(
          acceptData?.ship?.QuoteID || ""
        );
        setShowPayModal(false);
        setActiveSteps({
          step: "EnterMissingInformationForm",
          title: "Enter Missing Information",
          isSuccessPayment: false,
        });
        onOpen();
        
      } else {
        const paymentData = await mutateAddPaymenTransactiontMethod(data);
        const confirmShippingPayment = await mutateConfirmShippingPayment(
          acceptData?.ship?.QuoteID || ""
        );

        const res = await mutateAsyncAcceptPriceRateShipping(
          acceptData?.ship?.QuoteID || ""
        );

        console.log("paymentData", paymentData);
        if (res !== "Invalid quote id" && confirmShippingPayment >= 0) {
          setShowPayModal(false);
          onOpen();
        }
      }
    } catch (e) {
      console.log("Error :", e);
    }
  };

  useEffect(() => {
    mutateAsyncAccceptPayment();
    if (!saleOffer) {
      navigate(
        isThisDealer()
          ? "/dealer/manage-inventory/pending-sales"
          : "/user/garage/pending-sales"
      );
    }
  }, []);

  useEffect(() => {
    if (atcProcedureData && !isPendingToAtcProcedure) {
      selectedProcedureSignal.value = atcProcedureData;
      window.scrollTo(0, 0);
      setActiveSteps({
        step: "DmvFeeAndViewPdfDocs",
        title: "Documents Required",
        isSuccessPayment: false,
      });
    }
  }, [atcProcedureData, isPendingToAtcProcedure]);

  return (
    <>
      <div className="md:flex ">
        {/* <div className="mb-4 md:mt-36">
          <PendingSteps activeStepCount={activeSteps.step} stepsArray={steps} />
        </div> */}
        <div className="md:ml-5 pb-4  w-full flex flex-col items-center justify-center relative ">
          <div className="w-full flex justify-center flex-col items-center">
            <Typography
              variant="custom"
              className={`text-center  my-4 md:mb-16 md:ml-8 font-bold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
            >
              {activeSteps.title}
            </Typography>
            <div className={`${"md:pl-8"} w-full`}>
              {(() => {
                switch (activeSteps.step) {
                  case "ShipPay":
                    return (
                      <div className="w-full  max-w-[42rem] m-auto">
                        <NewOrderForm
                          handleSubmit={(res) => {
                            setAcceptData({ ship: res });
                            // window.scrollTo(0, 0);
                            // setShowPayModal(true);
                            setActiveSteps({
                              step: "Payment_1",
                              title: "Payment",
                              isSuccessPayment: false,
                            });
                          }}
                        />
                      </div>
                    );
                  case "Payment_1":
                    const base = Number(
                      saleOffer?.previousCashOffer.enterYourOfferHere
                    );
                    const shipPay = paymentInfo?.shipmentRequire
                      ? Number(acceptData?.ship?.TotalPrice) || 0
                      : 0;
                    const dmvFee =
                      Number(saleOffer?.previousCashOffer.salesTax || 0) +
                      Number(saleOffer?.previousCashOffer.dmVandFees || 0);

                    return (
                      <div className="flex items-end justify-center w-full max-w-[52rem] m-auto ">
                        <OtherOfferPaymentsFormCashZelle
                          onCallback={() => {
                            window.scrollTo(0, 0);
                            setShowPayModal(true);
                            // setActiveSteps({
                            //   step: "EnterMissingInformationForm",
                            //   title: "Enter Missing Information",
                            //   isSuccessPayment: false,
                            // });
                          }}
                          priceData={{
                            total: String(base + shipPay + dmvFee),
                            ship: String(shipPay),
                            fees: String(dmvFee),
                            base: String(base),
                          }}
                          messageBottom={
                            "If using credit/Debit, 3% Fee will be applied"
                          }
                          btnLabel={"Continue"}
                        />
                      </div>
                    );

                  case "EnterMissingInformationForm":
                    return (
                      <div className="relative  w-full">
                        <EnterMissingInformationForm
                          isHideTitle={true}
                          onCallback={() => {
                            handleOnConfirm();
                          }}
                          navigateOnSubmit={false}
                          fullService={true}
                        />
                      </div>
                    );
                  case "DmvFeeAndViewPdfDocs":
                    return (
                      <div className="w-full flex items-center flex-col">
                        <Typography
                          variant="custom"
                          className="text-medium text-red-500 font-[500] text-center"
                        >
                          Please note: If some documents are not available for
                          this VIN, we could provide them to you. Please create
                          a support ticket for assistance.
                        </Typography>
                        <div className="text-center">
                          <DmvFeeAndViewPdfDocs showInternalSubmit={false} />
                        </div>
                        <Button
                          className="col-span-3 bg-gradient-to-r max-w-[24rem] m-auto from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
                          type="submit"
                          onClick={() => {
                            if (paymentInfo?.shipmentRequire) {
                              setActiveSteps({
                                step: "DmvTransitStatus",
                                title: "Shipping Tracking",
                                isSuccessPayment: false,
                              });
                            } else {
                              // showmodel

                              setActiveSteps({
                                step: "PurchaseCompleted",
                                title: "",
                                isSuccessPayment: false,
                              });
                            }
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    );
                  case "DmvTransitStatus":
                    return (
                      <div className="w-full   max-w-[42rem] m-auto">
                        {/* <ShippingTracking /> */}
                        <DmvTransitStatus isForDmv={true} />
                        <div
                          className="text-center cursor-pointer mt-5"
                          onClick={() => {
                            setActiveSteps({
                              step: "ApproveSale",
                              title: "Approve Sale",
                              isSuccessPayment: false,
                            });
                          }}
                        >
                          SKIP
                        </div>
                      </div>
                    );
                  case "ApproveSale":
                    return (
                      <ApproveSale
                        onConfirm={() => {
                          setActiveSteps({
                            step: "PurchaseCompleted",
                            title: "",
                            isSuccessPayment: false,
                          });
                        }}
                      />
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        placement="center"
        size="3xl"
        closeButton={
          <BlackCloseXIcon width={"32px"} height={"32px"} tabIndex={-2} />
        }
      >
        <ModalContent>
          {(onClose) => (
            <>
              <div
                className="p-14"
                style={{
                  backgroundImage: `url(${paymentBg})`,
                  backgroundSize: "cover",
                }}
              >
                <PaymentSuccess
                  navigatePath={``}
                  buttonText="Continue"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onClose();
                  }}
                />
              </div>
            </>
          )}
        </ModalContent>
      </Modal>
      <PayModal isOpen={showPayModal} onClose={() => setShowPayModal(false)}>
        <div>
          <div className="w-full flex justify-center">
            <img src={LogoIcon} alt="" />
          </div>
          <PaymentFormWithDetails
            payAmmount={getPayAmmount()}
            showmodal={true}
            savedMethod={checkIfPaymentExist}
            isPending={
              isPendingToAccceptPayment ||
              isPendingToAddPaymenTransactiontMethod ||
              isPendingToConfirmShippingPayment ||
              isPendingToAcceptPriceRateShipping
            }
            handleNext={(e) => {
              handlePayment(e);
            }}
            errorMessage={
              checkIfPaymentExistError?.message ||
              AddPaymentCardError?.message ||
              shippingPaymentProcessError?.message ||
              acceptShipRateError?.message
            }
          />
        </div>
      </PayModal>
      <GradientModal
        isOpen={activeSteps.step === "PurchaseCompleted"}
        onOpenChange={() => {
          if (paymentInfo?.shipmentRequire) {
            setActiveSteps({
              step: "ApproveSale",
              title: "Approve Sale",
              isSuccessPayment: false,
            });
          } else {
            setActiveSteps({
              step: "DmvFeeAndViewPdfDocs",
              title: "Documents Required",
              isSuccessPayment: false,
            });
          }
        }}
        size="2xl"
      >
        <PurchaseCompletedComponent />
      </GradientModal>
    </>
  );
}
