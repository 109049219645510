import { CardBody, Image, Card, Chip } from "@nextui-org/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  ListingDataRequest,
  ListingDetailsSnapshot,
  useGetListingDetails,
  useRemoveUserListing,
  useUpdateListingData,
  useUpdateListingStatus,
} from "services";
import { findImage, money } from "utils";
import { env } from "config/env";
import { getUserId } from "utils/get-user-id";
import { Button, Typography } from "components";

export default function GarageDetails({
  car,
  userId,
  refetch,
  from,
  loading,
  btnLabel = "Remove From Garage",
  fromGarage= false,
  status="",
  handleClickChip
}: any) {
  const {
    mutateAsync: mutateAsyncUpdateListingData,
    isPending: isPendingUpdateListing,
  } = useUpdateListingStatus(Number(car?.listingId), 3);
  const { mutateAsync: removeUserListing, isPending: removeListingPending } =
    useRemoveUserListing();
  const { data: listingDetailsData } = useGetListingDetails(
     fromGarage? 0 :Number(car?.listingId) ?? 0
  );
  // const [images, setImages] = useState<string[]>([]);

  const listing = (fromGarage ? car :listingDetailsData?.listing);

  // const plotImages = useCallback(
  //   (snapshot: ListingDetailsSnapshot[] | undefined) => {
  //     if (snapshot) {
  //       for (const ss of snapshot) {
  //         if (ss.value) {
  //           const filteredImages = findImage(ss?.value);
  //           if (filteredImages)
  //             setImages((prev: any[]) => prev.concat(filteredImages));
  //         }
  //       }
  //     }
  //   },
  //   []
  // );
  const hadleRemoveFromSaveListing = async () => {
    const payload = {
      listingId: Number(car?.listingId),
      userId: getUserId(),
    };
    await removeUserListing(payload);
    refetch();
  };
  const hadleRemoveFromGarage = async () => {
    await mutateAsyncUpdateListingData();
    refetch();
  };

  // useEffect(() => {
  //   plotImages(snapshot);
  // }, [plotImages, snapshot, snapshot?.length]);

  const handleClick = () => {
    window.open(
      `#/user/manage-listing/${listing?.vin}/${listing?.mileage}/${listing?.listingId}`,
      "_blank"
    );
  };

  const hadleViewSaveListing = async () => {
    window.open(`#/listing/view-listing/${listing?.listingId}`, "_blank");
  };

  return (
    <Card
      className="border border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors rounded-2xl"
      shadow="none"
      fullWidth
    >
      <CardBody className="flex flex-col">
        <div className="bg-white rounded-3xl h-full w-full">
          <div className="col-span-2">
            <Image
              removeWrapper
              src={`${env.BASE_API_URL}Listings/image/${car.listingId}`}
              alt="car"
              onClick={() =>
                from === "garage" ? handleClick() : hadleViewSaveListing()
              }
              className="w-full h-56 xl:h-40 2xl:h-48 object-cover cursor-pointer"
            />
            {status && (
              <Chip onClick={()=>{handleClickChip && handleClickChip(listing)} } className="absolute cursor-pointer top-2 right-2 z-10 text-xs m-2 bg-white border border-blue-500  font-[Metropolis]">
                {status}
              </Chip>
            )}
          </div>
          <div className="col-span-2">
            <Typography
              variant="custom"
              className="pt-4 text-xl sm:text-sm lg:text-xl xl:text-base text-center font-[800] text-[#263238] min-h- overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
              {listing?.year ?? ""} {listing?.make ?? ""} {listing?.model ?? ""}
            </Typography>
            {/* <Typography
              variant="custom"
              className="text-center text-md font-[600] text-[#263238] py-1 min-h-7"
            >
              {listing?.trim ?? ""}
            </Typography> */}

            {/* <Typography
              variant="custom"
              className="font-bold text-center text-xl  text-[#263238] h-8  sm:text-sm lg:text-xl xl:text-base "
            >
              4 door 4WD
            </Typography> */}
            <Typography
              variant="custom"
              className="text-center text-sm  text-[#263238] h-6 sm:h-6 lg:h-8 sm:text-xs lg:text-base xl:text-sm"
            >
              {money(listing?.mileage)} mi
            </Typography>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center mt-auto gap-4 ">
          <Button
            customVariant="gradient"
            className=" w-auto h-auto sm:py-1 rounded-xl sm:rounded-lg lg:rounded-xl"
            onClick={() =>
              from === "save-listing"
                ? hadleRemoveFromSaveListing()
                : hadleRemoveFromGarage()
            }
            isLoading={loading}
          >
            <Typography
              variant="label"
              className="text-sm sm:text-xs lg:text-base xl:text-sm bg-gradient-to-r from-blue-500 to-blue-900 text-transparent bg-clip-text font-[900]"
            >
              {isPendingUpdateListing ? "Removing..." : btnLabel}
            </Typography>
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
