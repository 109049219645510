import React from "react";
import CloseIcon from "../../../shared/components/CloseIcon";
import "./chathelp.css";

const Help = ({ setCurrScreen, handleScreenChange }) => {
  return (
    <div
      className="flex justify-end max-[768px]:min-h-screen "
      style={{ fontFamily: "Metropolis-SemiBold" }}
    >
      <div className="w-full max-[768px]:h-[100vh] rounded-none m-0 md:rounded-t-[25px] bg-[#fff] min-[768px]:w-[400px] min-[768px]:h-[550px] min-[768px]:rounded-[25px] ">
        <div className="flex justify-end mr-[9px] mt-[15px] md:mr-[39px] md:mt-[39px] md:rounded-t-[25px]">
          <div
            className="inline-block w-[8px] h-[8px] md:w-[17px] md:h-[17px] cursor-pointer"
            onClick={() => handleScreenChange()}
          >
            <CloseIcon stroke="#000" strokeWidth="4.028" />
          </div>
        </div>
        <div className=" text-[16px] min-[768px]:w-[400px] min-[768px]:mt-[5%] text-center min-[768px]:text-[25px]">
          How can we help you?
        </div>
        <div className="flex items-center flex-col justify-center h-[70%]">
          <button
            className="text-center text-white w-[85%] h-[80px] rounded-[18px] md:w-[250px] md:h-[80px] min-[768px]:text-[15px] font-bold md:rounded-[18px] chatsuppbtn "
            onClick={() => setCurrScreen("case")}
          >
            <span className="">Create Support Ticket</span>
          </button>
          <button
            className="mt-[36px] text-center text-white w-[85%] h-[80px] rounded-[18px] md:w-[250px] md:h-[80px] min-[768px]:text-[15px] font-bold md:rounded-[18px] chatsuppbtn "
            onClick={() => setCurrScreen("support-chat")}
          >
            <span className="">24/7 Chat Support</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Help;
