import { SearchField } from "components";
import ListingOfTheDay from "./listing-of-the-day.component";
import { useEffect, useRef, useState } from "react";
import { useTurboChatMutation } from "hooks/api/mutations/chat/turbo-ai/use-turbo-mutation";
import NewChatBotTurbo from "./components/NewChatBotTurbo";
import { useGetListingOfTheDay } from "services";
import { SearchIcon } from "assets/svgs/icons/search-icon";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  useDisclosure,
} from "@nextui-org/react";
import MobileNewChatBotTurbo from "./components/MobileNewChatBotTurbo";

interface MainSectionLandingPageProps {}

export const MainSectionLandingPage: React.FC<
  MainSectionLandingPageProps
> = () => {
  const { data } = useGetListingOfTheDay();
  console.log(data, "<<<<<<<<<<<<<<<<<<<");
  const [isHover, setHover] = useState(false);

  const [messages, setMessages] = useState<any[]>([]);

  const { mutate } = useTurboChatMutation();
  const [message, setMessage] = useState("");

  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const aiAssistantRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (message) {
      scrollToBottom();
      handleSubmit();
    }
  }, [message]);
  const handleSubmit = async () => {
    // setturbochatLoading(true);
    if (message !== " ") {
      const updatedMessages = Array.from([
        ...messages,
        { content: message, role: "user" },
      ]);

      setMessages((prev) => [...prev, { content: message, role: "user" }]);
      mutate(updatedMessages, {
        onSuccess: (response: any) => {
          setMessages((prev) => [
            ...prev,
            { content: response.answer, role: "assistant" },
          ]);
          // setturbochatLoading(false);
          scrollToBottom();
        },
        onError: (error) => {
          console.error("Error:", error);
          // setturbochatLoading(false);
        },
      });
    }
    setMessage("");
  };

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        aiAssistantRef.current &&
        !aiAssistantRef.current.contains(event.target)
      ) {
        setHover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className="flex justify-between 2xl:justify-around  items-center border-none shadow-none landing-page-main-struck">
      <div className="flex   justify-center items-start my-10 p-0">
        <div className=" border-solid py-10 ">
          <div>
            <div className="flex">
              <div className="flex flex-col gap-2 text-left max-w-[7rem] ">
                <p className="text-left font-[Metropolis] text-lg md:text-[2.05rem] w-full font-bold max-w-[7rem] md:ml-0 ml-8">
                  Meet
                </p>
                <p className="flex max-w-[7rem] w-full min-h-[180px] h-full">
                  <p className=" md:mt-[5rem] font-[Metropolis]   md:-ml-[8rem] text-[4rem] md:text-[7rem] -rotate-90  font-bold bg-gradient-to-r from-blue-600 to-indigo-900 text-transparent bg-clip-text">
                    {"Turbo!".split("").map((txt) => (
                      <span key={txt} className="">
                        {txt}
                      </span>
                    ))}
                  </p>
                </p>
              </div>
              <div className="flex items-center justify-center ">
                <div className="mt-8 md:mt-12" ref={aiAssistantRef}>
                  <h1 className="text-xl md:text-4xl font-bold font-[Metropolis]">
                    Our <span className="text-2xl md:text-[3.5rem]">AI</span>{" "}
                    Buyer assistant!
                  </h1>
                  <h2 className="text-lg md:text-3xl font-bold">
                    You can ask anything
                  </h2>
                  <h2 className="text-lg md:text-3xl font-bold">
                    vehicle related!
                  </h2>

                  <div className="hidden md:block">
                    <div
                      onClick={() => {
                        setHover(true);
                      }}
                      className=" flex items-center justify-around border-1 mt-4 md:mt-8 text-lg rounded-4xl  w-[260px] md:w-[360px] xl:w-[400px] rounded-2xl h-[60px]"
                    >
                      <SearchIcon />
                      <span className="text-sm">
                        Ask me anything car related!
                      </span>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <NewChatBotTurbo
                      onCloseCallBack={() => setHover(false)}
                      isHover={isHover}
                      from="landing"
                    />
                  </div>

                  <div className="block md:hidden ">
                    <div
                      onClick={() => {
                        onOpen();
                      }}
                      className=" flex items-center justify-around border-1 mt-4 md:mt-8 text-lg rounded-2xl  w-full p-1"
                    >
                      <SearchIcon />
                      <span className="text-xs md:text-sm">
                        Ask me anything car related!
                      </span>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

                    <div
                      id="authentication-modal"
                      tabIndex={-1}
                      aria-hidden="true"
                      className={`${
                        isOpen ? "" : "hidden"
                      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
                    >
                      <div className="relative sm:left-[25%] p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                          <button
                            type="button"
                            className="start-2.5 float-end text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                            onClick={onOpenChange}
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                          <MobileNewChatBotTurbo isHover={isOpen} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data?.listingId ? (
        <ListingOfTheDay listing={data} />
      ) : (
        <Image
          className={`w-[300px] h-[300px]`}
          radius="none"
          src={""}
          alt="Car Image"
        />
      )}
    </div>
  );
};
