import { Button } from "@nextui-org/react";
import { Typography } from "components";
import { useEffect, useState } from "react";
import {
  useGetListingList,
  useGetResearchVehicleForms,
} from "services";
import { getUserId } from "utils/get-user-id";


import { TableComponentForPayment } from "./components/ContinueTable";
import { useNavigate } from "react-router-dom";

export type IsaveType = { userId: number; listingId: number };

export default function CarResearch() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<number>(1);

  const [page, setPage] = useState<number | null>(1);

  const {
    data: dataListing,
    isLoading: isLoadingList,
    refetch: cancelRefetch,
  } = useGetListingList(getUserId(), 3, page || 1, 20);

  const {
    data,
    isPending: continuePending,
    refetch: ContinueRefetch,
  } = useGetResearchVehicleForms();
  useEffect(() => {
    if (page && page > 1) {
      cancelRefetch();
    }
  }, [page]);
  return (
    <>
      <div className="flex flex-col gap-8">
        <div className=" text-center w-full">
          <Typography
            variant="pageTitle"
            className="gradient-text text-white text-3xl font-bold"
          >
            Research
          </Typography>
        </div>
        <div
          className={`w-full ${"flex flex-col md:flex-row"}  justify-between`}
        >
          <div className="w-full flex  items-center gap-4 mb-4 md:mb-0">
            <Button
              className={`
                    ${
                      selectedType === 1 ? "selected" : "default"
                    }  text-[15px] lg:text-[20px] px-5  text-center  rounded-xl w-full md:w-40
                `}
              onClick={() => {
                setSelectedType(1);
              }}
            >
              Current
            </Button>
            <div className="flex items-center justify-cente r   h-[54px] w-full">
              <Button
                // name=""
                className={`${
                  selectedType === 3 ? "selected" : "default"
                } text-[15px] lg:text-[20px] px-5 text-center  rounded-xl w-full md:w-40`}
                onClick={() => {
                  setSelectedType(3);
                }}
              >
                Cancelled
              </Button>
            </div>
          </div>
          <Button
            className="bg-gradient-to-r text-[15px] lg:text-[20px] from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
            type="button"
            size="md"
            // onClick={() => setOpenModal("NEW")}
            onClick={()=>navigate("/dealer/new-research")}
          >
            New
          </Button>
        </div>
        <div className=" ">
          <TableComponentForPayment
            formSfdata={data}
            dataListing={dataListing}
            selectedStatus={selectedType}
            page={page}
            setPage={setPage}
            cancelRefetch={cancelRefetch}
            ContinueRefetch={ContinueRefetch}
            continuePending={continuePending}
          />
        </div>
      </div>
      {/* <CustomizableHeaderModel
        size={"2xl"}
        isOpen={openModal !== null}
        onOpen={() => {
          setOpenModal(null);
        }}
        Component={
          <NewReasearchComponent
            onClose={() => setOpenModal(null)}
            ContinueRefetch={ContinueRefetch}
          />
        }
      /> */}
    </>
  );
}




