import React from "react";
import card_logo from "./assets/card-logo.png";
import { useNavigate } from "react-router-dom";
import master_card_logo from "./assets/Mastercard_Logo.svg";
import {
  AddCardMethodPayload,
  PaymentCardInfo,
} from "services/endpoints/shipping-quotes/shipping.type";
import Form from "components/ui/form";
import { AddPaymentValidationSchema } from "pages/shipping-quotes/new-order/new-order.validation";
import Input from "components/ui/input";
import { Button } from "@nextui-org/react";
import { getFormatedDateToday } from "utils";

interface Props {
  showmodal?: boolean;
  handleNext?: (data: AddCardMethodPayload) => void;
  savedMethod?: PaymentCardInfo | null;
  payAmmount?: string;
  isPending?: boolean;
}

const PaymentForm: React.FC<Props> = ({
  showmodal = false,
  handleNext,
  savedMethod,
  payAmmount,
  isPending,
}) => {
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    savedMethod && e.preventDefault();
    if (showmodal && handleNext) {
      handleNext(e);
    } else {
      navigate("/shipping-quotes/send-otp");
    }
  };
  return (
    <div className="w-full ">
      <div className="m-auto  max-w-screen-sm px-4 pt-5 pb-5">
        <div className="text-xs font-bold text-center mb-4">
          Payment Amount
          <div className="text-3xl font-bold mt-2 text-blue-900">
            {`$${payAmmount}`}
          </div>
        </div>
        <div className=" rounded-xl p-6">
          {savedMethod ? (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="cardNumber"
                  className="text-xs block mb-2 font-bold"
                >
                  Saved Payment Methods
                </label>
                <div className=" flex items-center justify-between px-4 border-2 border-black rounded-xl py-2 w-full focus:outline-none ">
                  <div className="flex  items-center ">
                    <input
                      id="cardNumber"
                      name="cardNumber"
                      type="radio"
                      value=""
                      checked={true}
                      // onChange={handleChange}
                      className="w-6 h-6 outline-none"
                    />
                    <img
                      src={master_card_logo}
                      alt="master-card"
                      className="max-w-16 max-h-16"
                    />
                  </div>
                  <p className="font-bold text-base md:text-2xl text-center">
                    {addSpaceEveryFourChars(
                      savedMethod?.payment?.Item?.cardNumber || "N/A"
                    )}
                  </p>
                </div>
              </div>
              <div className="text-right mb-4">
                <button
                  type="button"
                  className="text-gray-500 text-xs font-bold"
                >
                  Use another Card
                </button>
              </div>
              <div className="mb-4">
                <Button
                  type="submit"
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl "
                  isLoading={isPending}
                >
                  Pay{"  $"}
                  {`${payAmmount}`}
                </Button>
              </div>
            </form>
          ) : (
            <Form
              id="new-order-shipping-form"
              schema={AddPaymentValidationSchema}
              onSubmit={handleSubmit}
              className="px-10"
            >
              {({
                formState: { errors, defaultValues },
                register,
                getValues,
              }) => {
                return (
                  <>
                    {[
                      { id: "name", label: "Name on card" },
                      { id: "cardNumber", label: "Card Number" },
                    ].map(({ id, label }) => {
                      return (
                        <div className="mb-4" key={id}>
                          <label
                            htmlFor={id}
                            className="text-xs block mb-2 font-bold"
                          >
                            {label}
                          </label>
                          <Input
                            variant="form"
                            size="compact"
                            errorMessage={
                              errors[id as keyof typeof errors]?.message
                            }
                            type="text"
                            id={id}
                            {...register(id as any)}
                            classNames={{
                              input:
                                "font-bold  w-full focus:outline-none text-center",
                              inputWrapper: !!errors[id as keyof typeof errors]
                                ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                : "",
                            }}
                          />
                        </div>
                      );
                    })}
                    <div className="flex flex-wrap -mx-2 mb-4">
                      {[
                        {
                          id: "expiryDate",
                          label: "Expiry Date",
                          style: "w-full md:w-1/2 px-2 mb-4 md:mb-0",
                          placeholder: "MM/YY",
                          type: "text",
                        },
                        {
                          id: "cvv",
                          label: "CVV",
                          style: "w-full md:w-1/2 px-2",
                          placeholder: "",
                          type: "password",
                        },
                      ].map(({ id, label, style, placeholder, type }) => {
                        return (
                          <div className={style} key={id}>
                            <label
                              htmlFor={id}
                              className="text-xs block mb-2 font-bold"
                            >
                              {label}
                            </label>

                            <Input
                              variant="form"
                              size="compact"
                              errorMessage={
                                errors[id as keyof typeof errors]?.message
                              }
                              type={type}
                              placeholder={placeholder}
                              id={id}
                              {...register(id as any)}
                              classNames={{
                                input:
                                  "font-bold  w-full focus:outline-none text-center",
                                inputWrapper: !!errors[
                                  id as keyof typeof errors
                                ]
                                  ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                  : "",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="text-right mb-4">
                      <button
                        type="button"
                        className="text-gray-500 text-xs font-bold"
                      >
                        Save Card
                      </button>
                    </div>
                    <div className="mb-4">
                      <Button
                        type="submit"
                        isLoading={isPending}
                        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl "
                      >
                        Pay {`$${payAmmount}`}
                      </Button>
                    </div>
                  </>
                );
              }}
            </Form>
          )}
          <div className="text-xs text-center">
            By Selecting Pay, I accept the Terms of Service and have read and
            acknowledge the Privacy Statement. I also allow Intuit to charge $
            {payAmmount} to my card on {getFormatedDateToday()}
          </div>
        </div>
        <div className="mt-4 text-center mb-8">
          <img className="h-12 inline-block" src={card_logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
function addSpaceEveryFourChars(input: string) {
  let output = "";
  for (let i = 0; i < input.length; i++) {
    if (i > 0 && i % 4 === 0) {
      output += " ";
    }
    output += input.charAt(i);
  }
  return output;
}
