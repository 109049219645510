import { z } from "zod";

export const AutoLeaseCalculatorSchema = z.object({
  totalCostOfVehicle: z
    .string()
    .min(1, {
      message: "required fields!",
    })
    .regex(/^\d*\.?\d+$/, { message: "Value must be a positive number." })
    .refine((value) => parseFloat(value) >= 0, {
      message: "Value must be a positive number.",
    }),
  residualValue: z
    .string()
    .min(1, {
      message: "required fields!",
    })
    .regex(/^\d*\.?\d+$/, { message: "Value must be a positive number." })
    .refine((value) => parseFloat(value) >= 0, {
      message: "Value must be a positive number.",
    }),
  countrySalesTaxRate: z
    .string()
    .min(1, {
      message: "required fields",
    })
    .regex(/^100(\.0{1,2})?$|^\d{1,2}(\.\d{1,2})?$|^0(\.\d{1,2})?$/, {
      message: "Value must be a number between 0 and 100.",
    })
    .refine((value) => parseFloat(value) >= 0 && parseFloat(value) < 100, {
      message: "Value must be a number between 0 and 100.",
    }),
  discounts: z
    .string()
    .min(1, {
      message: "required fields",
    })
    .regex(/^\d*\.?\d+$/, { message: "Value must be a positive number." })
    .refine((value) => parseFloat(value) >= 0, {
      message: "Value must be a positive number.",
    }),
  estimatedDownPayment: z
    .string()
    .min(1, {
      message: "required fields",
    })
    .regex(/^\d*\.?\d+$/, { message: "Value must be a positive number." })
    .refine((value) => parseFloat(value) >= 0, {
      message: "Value must be a positive number.",
    }),
  loanTerm: z
    .string()
    .min(1, {
      message: "required fields",
    })
    .regex(/^\d+$/, {
      message: "Value must be a whole number.",
    })
    .refine(
      (value) => {
        const numberValue = parseInt(value, 10);
        return Number.isInteger(numberValue);
      },
      {
        message: "Value must be a whole number.",
      }
    ),
  interestRate: z
    .string()
    .min(1, {
      message: "required fields",
    })
    .regex(/^100(\.0{1,2})?$|^\d{1,2}(\.\d{1,2})?$|^0(\.\d{1,2})?$/, {
      message: "Value must be a number between 0 and 100.",
    })
    .refine((value) => parseFloat(value) >= 0 && parseFloat(value) < 100, {
      message: "Value must be a number between 0 and 100.",
    }),
});
