import { z } from "zod";

export const SendSellerCashOfferSchema = z.object({
  userZip: z
    .string()
    .min(1, {
      message: "Destination Zip is required",
    })
    .length(5, "Destination Zip needs to have a length of 5"),
  userOffer: z.string().min(1, {
    message: "Your offer is required",
  }),
  userMessage: z.string().optional(),
  isShippingNeeded: z.boolean().optional(),
});

export const SendSellerFinanceOfferSchema = z.object({
  down_Payment: z.string().min(1, {
    message: "Down Payment is required",
  }),

  interest_Rate: z.string().min(1, {
    message: "Interest Rate is required",
  }),
  estimated_Credit_Score: z.string().min(1, {
    message: "Credit Score is required",
  }),
  loan_Term: z.string().min(1, {
    message: "Loan Term is required",
  }),
  send_Note_To_Seller: z.string().optional(),
});
