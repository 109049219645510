import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../../components/navbar/index";
// import DmvPriceingModals from "./DmvPriceingModals";
import DownloadIcon from "./assets/download_icon.svg";
import UploadIcon from "./assets/upload_file_icon.svg";
import GreenYesIcon from "./assets/green_yes_icon.svg";
import RedCloseIcon from "./assets/red_close_icon.svg";
import { useNavigate } from "react-router-dom";
import DmvTitleSuccess from "./DmvTitleSuccess";

const DmvDocStatus: React.FC = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  // const [data, setData] = useState({
  //   State: "",
  //   County: "",
  //   City: "",
  //   "ZIP Code": "",
  // });
  const navigate = useNavigate();
  const handleContinueTitle = () => {
    navigate("/dealer-dmvTitling/dvm-transit-status");
  };

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setData((prevdata) => ({
  //     ...prevdata,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   setOpenPriceModal(true);
  // };

  return (
    <React.Fragment>
      <Navbar type={"dmv-titling"}>
        <div className="flex flex-col items-center mx-8 dmv-title">
          <h1 className="text-3xl font-bold mb-4 font-[Metropolis-Bold] mt-8">
            Full Service
          </h1>
          <p className="font-[Metropolis-semibold] text-center text-md text-gray-700 w-full px-4 md:px-32 max-w-[64rem]">
            Pending Integration
          </p>
          <div className="justify-center items-center  sm:w-full md:w-full lg:max-w-[64rem] mt-8">
            {[
              {
                id: 0,
                mainText: "DD414 Form",
                subText: "Buyer needs to sign DD414 form with all details",
                process: 100,
              },
              {
                id: 1,
                mainText: "162026 Form",
                subText: "Seller is missing form 16206 Signature section",
                process: 50,
              },
              {
                id: 2,
                mainText: "DD414 Form",
                subText: "Buyer needs to sign DD414 form with all details",
                process: 100,
              },
              {
                id: 3,
                mainText: "162026 Form",
                subText: "Seller is missing form 16206 Signature section",
                process: 50,
              },
              {
                id: 4,
                mainText: "DD414 Form",
                subText: "Buyer needs to sign DD414 form with all details",
                process: 100,
              },
            ].map((list) => {
              return (
                <div
                  className="bg-slate-50 px-6 py-6 border-[#018FFD] border-2 rounded-lg  mb-4"
                  key={list.id}
                >
                  <div className="flex flex-col md:flex-row items-center justify-between  w-full ">
                    <div className="mt-2 mb-4 text-center md:text-left font-[Metropolis-bold] text-[#263238] md:min-w-[26rem] md:pl-10">
                      <h1 className="text-xl">{list.mainText}</h1>
                      <p className="">{list.subText}</p>
                    </div>
                    <div className=" font-[Metropolis-bold] flex flex-col justify-center   mb-2 sm:mb-0 md:mr-10">
                      <div className="flex gap-4 justify-center">
                        <div className="flex flex-col justify-center items-center ">
                          <img
                            className="w-8 h-8"
                            src={DownloadIcon}
                            alt={"Download"}
                          />
                          <p>Download</p>
                        </div>
                        <div className="flex flex-col justify-center items-center ">
                          <img
                            className="w-8 h-8"
                            src={UploadIcon}
                            alt={"Upload"}
                          />
                          <p>Uploads</p>
                        </div>

                        {list.process === 50 ? (
                          <div className="flex flex-col justify-center items-center ">
                            <img
                              className="w-8 h-8"
                              src={RedCloseIcon}
                              alt={"Pending"}
                            />
                            <p>Pending</p>
                          </div>
                        ) : (
                          <div className="flex flex-col justify-center items-center ">
                            <img
                              className="w-8 h-8"
                              src={GreenYesIcon}
                              alt={"Completed"}
                            />
                            <p>Completed</p>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-center mt-4">
                        <div className="loader-container">
                          <div
                            className="loader-progress"
                            style={{ width: `${list.process}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="flex mb-8 justify-center flex-col md:flex-row">
              <button
                onClick={handleContinueTitle}
                className="mt-8  md:ml-auto text-xl font-[Metropolis-bold] block px-12 py-4 text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] md:mr-10"
              >
                Titling Status
              </button>
              <button
                onClick={() => setOpenSuccessModal(true)}
                className="mt-8  text-xl font-[Metropolis-bold] block px-16 py-4 text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <DmvTitleSuccess
          open={openSuccessModal}
          handleClose={() => {
            setOpenSuccessModal(false);
          }}
          handleContinue={handleContinueTitle}
        />
      </Navbar>
    </React.Fragment>
  );
};

export default DmvDocStatus;
