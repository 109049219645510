import "../index.css";
import CloseIcon from "../../../../shared/components/CloseIcon";
import { FileThumbnailIcon } from "assets/svgs/file_thumbnail.svg";
import { Button } from "@nextui-org/react";
import { useState } from "react";
import { env } from "config/env";
interface Props {
  // icon: React.ReactNode;
  file?: any;
  // name?: string;
  // parts?: string | boolean;
  handleRemove?: () => void;
  // isFirst?: boolean;
  handleMakeFirst?: () => void;
  showBtns: boolean;
}
export const ViewLoadedDocumentListing = (props: Props) => {
  // const [imageUrl, setImageUrl] = useState<string>("");
  const [show,setShow]=useState(true);
  // useEffect(() => {
  //   if (props?.file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       if (typeof reader.result === "string") {
  //         setImageUrl(reader.result);
  //       }
  //     };
  //     reader.readAsDataURL(props.file);
  //   }
  // }, [props.file]);
  async function handleDownload() {
    try {
      const res = await fetch(
        `${env.BASE_API_URL}Listings/GetListingDocumentFileName?fileName=${props?.file?.name}`
      );
      const blob = await res.blob()
      const url = window.URL.createObjectURL(blob);
      const link:HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.setAttribute('download', props?.file?.name || 'filename.ext'); // You can specify the filename here
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  }
  
  return (<>
    {show && 
    <div
      onClick={props?.handleMakeFirst}
      className={`flex   cursor-pointer p-4 modal-preview-files relative  ${
        props.showBtns ? "flex-col items-center justify-center text-center md:flex-row " : ""
      }`}
    >
      <div className="bg-[#018FFD] rounded-md p-2 flex w-fit  items-center justify-center">
        <FileThumbnailIcon className="m-auto" />
      </div>
      <div className={`${ props.showBtns ?"w-full md:w-2/3":"w-4/5"} px-4 py-2 `}>
        <div className="">
          <h3 className="w-full text-left  overflow-hidden whitespace-nowrap overflow-ellipsis">
            {props?.file && (props.file?.name || "test.doc")}
          </h3>
          {!props?.file && (
            <div className="w-full bg-gray-200 rounded-full h-1.5 mt-4 dark:bg-gray-600">
              <div className="loader-line-manage-list-img h-full w-full"></div>
            </div>
          )}
        </div>
      </div>
      {props.showBtns && (
        <div className="flex gap-2 items-center justify-center ">
          
            <Button
              // name="Archived"
              className={`
                bg-gradient-to-r w-full from-[#018ffd] to-[#070c84] text-white text-lg  tracking-wide rounded-xl
                `}
              onClick={() => {
                // setSearchCars([]);
                // setSelectedType(0);
                handleDownload()
              }}
            >
              View
            </Button>
              <Button
                className={`bg-gradient-to-r w-full from-[#018ffd] to-[#070c84] text-white text-lg  tracking-wide  rounded-xl`}
                onClick={() => {
                  // setSearchCars([]);
                  // setSelectedType(2);
                  setShow(false)
                }}
              >
                Remove
              </Button>
        </div>
      )}
      {!props.showBtns && (
        <div className="absolute modal-border-radius right-4 top-2 bg-white shadow">
          <button
            type="button"
            className="absolute top-[10] right-[0] hover:scale-125 w-3 h-3"
            data-modal-hide="default-modal"
            onClick={() => {
              props?.handleRemove && props.handleRemove();
            }}
          >
            <CloseIcon />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
      )}
    </div>}</>
  );
};
