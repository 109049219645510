import { Card, CardBody, CardHeader, Divider } from "@nextui-org/react";
import "./sold-expense-chart.scss";
import { Typography } from "components";
import SoldExpenseChartDropdown from "./sold-expense-chart-dropdown";
import SoldExpenseGraph from "./sold-expense-graph";
import { formatWholeCurrency } from "modules/user/utils/formatter";

interface SoldExpenseChartProps {
  item: any;
  isProfit: boolean;
  onSelect: (option: number) => void;
}

const SoldExpenseChart = ({
  item,
  isProfit,
  onSelect,
}: SoldExpenseChartProps) => {
  const { title, month, total, inventoryRevenue, soldExpense } = item;
  return (
    <Card className="border-gradient ">
      <CardHeader className="flex justify-between px-6">
        <Typography
          variant="custom"
          className="text-lg font-semibold text-[#070C84]"
        >
          {title}
        </Typography>
        <SoldExpenseChartDropdown onSelect={onSelect} selectedOption={month} />
      </CardHeader>
      <Divider />
      <CardBody className="gap-8 pt-8 pb-16 px-6">
        <div>
          <Typography
            variant="custom"
            className="text-lg font-semibold text-[#070C84]"
          >
            {total.title}
          </Typography>
          <Typography
            variant="custom"
            className="text-2xl font-bold text-[#070C84]"
          >
            {isProfit ? formatWholeCurrency(total.amount) : total.amount}
          </Typography>
        </div>
        <SoldExpenseGraph
          amount={inventoryRevenue.amount}
          label={inventoryRevenue.title}
          percent={inventoryRevenue.percent}
          isMoney={isProfit}
        />
        <SoldExpenseGraph
          amount={soldExpense.amount}
          label={soldExpense.title}
          percent={soldExpense.percent}
          isMoney={isProfit}
        />
      </CardBody>
    </Card>
  );
};

export default SoldExpenseChart;
