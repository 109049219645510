import { Button, Card, CardBody } from "@nextui-org/react";
import { Typography } from "components";
import Input from "components/ui/input";
import React, { useState } from "react";
import paymentBg from "../assets/payment-bg.png";
import { useDispatch } from "react-redux";
import { changeTicketView } from "modules/dealer/store/user-information";

interface Props {
  onConfirm:()=>void
}

export default function ApproveSale({onConfirm}:Props) {
  const [transitStatus, setTransitStatus] = useState("Order Received");
  const dispatch = useDispatch()
  const handleReportInaccuracy = () => {
    dispatch(changeTicketView("1"));
  };
  return (
    <Card
      className=" max-w-[55rem] m-auto w-full bg-no-repeat rounded-3xl"
      style={{
        backgroundImage: `url(${paymentBg})`,
        backgroundSize: "cover",
      }}
    >
      <CardBody className="grid grid-row-4 py-20 px-8 md:p-20 gap-10">
        <p className="text-[#070C84]  text-justify font-semibold text-lg">
          By Checking below you agree to the condition of the vehicle and accept
          the vehicle as is. Note- once you click approve this transaction will
          be closed.
        </p>
        <div className="grid md:grid-cols-4 items-center">
          <Typography
            variant="custom"
            className="text-[#263238] font-[600] text-left text-lg"
          >
            Driver License Number
          </Typography>
          <Input
            variant="form"
            size="lg"
            className=" md:col-span-3"
            classNames={{
              input: "px-4 text-center",
            }}
            defaultValue={""}
          />
        </div>
        <Button
          className="ml-auto bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-base xl:text-lg py-8 rounded-2xl w-1/2 xl:w-1/3 "
          style={{ fontFamily: "Metropolis-SemiBold" }}
          onClick={onConfirm}
        >
          Confirm Sale
        </Button>
        <div className="grid md:grid-cols-2 xl:grid-cols-3 items-center pt-20 pb-20 md:pb-0">
          <p className="text-[#070C84] xl:col-span-2 xl:pr-8 text-lg md:text-base">
            If you have any questions or an issue with your transaction please
            open a support ticket here
          </p>
          <Button
            className="ml-auto bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-base xl:text-lg py-8 rounded-2xl w-1/2 md:w-full "
            style={{ fontFamily: "Metropolis-SemiBold" }}
            onClick={handleReportInaccuracy}
          >
            Open Support Ticket
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
