import { Outlet } from "react-router-dom";
import { BgTriangle } from "assets/svgs/bg-triangle";
import { useState } from "react";

import Contact from "components/contact-modal/Contact";
import { AuthFooter } from "components/footer/auth-footer";

export default function AuthLayout() {
  const [showTicket, setShowTicket] = useState(false);
  const navigateToContactUs = () => {
    setShowTicket(true);
  };

  return (
    <main className="min-h-dvh flex flex-col font-[Metropolis-SemiBold]">
      <div className="p-5">
        <div className="border border-[#018FFD] rounded-2xl text-center w-full px-5 py-6  md:px-9 md:py-7 font-medium text-sm mx-auto md:w-2/5 tracking-wide">
          Sign up is limited only for approved beta users at the moment, Please{" "}
          <div
            className="inline-block text-[#018FFD] my-0 cursor-pointer"
            onClick={navigateToContactUs}
          >
            contact us
          </div>{" "}
          to join the beta list.
        </div>
      </div>
      <div className="flex-1">
        {showTicket && (
          <div className="ticket-overlay">
            <Contact
              setCurrScreen={() => setShowTicket(false)}
              handleScreenChange={() => setShowTicket(false)}
            />
          </div>
        )}

        <Outlet />
      </div>
      {/* <div className="hidden lg:block absolute bottom-0 w-[50%] -z-10">
        <BgTriangle />
      </div> */}
      <AuthFooter />
    </main>
  );
}
