import { Button } from "@nextui-org/react";

interface Props {
  handleContinue: () => void;
}
export default function BhphfContractFive({ handleContinue }: Props) {
  return (
    <div className="p-2 md:p-8">
      <h2 className="my-4 font-bold text-left">
        Electronic Tracking and Engine Demobilization Disclosure for Buy Here
        Pay Here Sales
      </h2>
      <div>
        <p className="my-2">
          This disclosure applies to the vehicle you are purchasing under a Buy
          Here Pay Here financing agreement.
        </p>
      </div>

      <div>
        {[
          {
            label: "Electronic Tracking System:",
            option: [
              "Our dealership may install or have already installed an electronic tracking system (often referred to as a GPS) on the vehicle you are purchasing.",
              "This tracking system allows us to locate the vehicle in the event of a default on your loan payments or repossession.",
              "The system may also provide features such as mileage tracking or odometer readings.",
            ],
          },
          {
            label: "Engine Demobilization:",
            option: [
              "We reserve the right to equip the vehicle with a remote engine demobilization system.",
              "This system allows us to electronically disable the vehicle's engine remotely in the event of a loan default or repossession.",
              "We will only activate the engine demobilization system as a last resort after following all legal requirements for repossession.",
            ],
          },
          {
            label: "Your Responsibilities:",
            option: [
              "You are responsible for the costs associated with the installation, maintenance, and removal (if applicable) of the electronic tracking system. These costs may be included in your financing agreement or charged separately.",
              "You agree not to tamper with, disable, or remove the electronic tracking system without our prior written consent. Tampering with the system may be considered a breach of your loan agreement and could result in legal action.",
            ],
          },
          {
            label: "Your Rights:",
            option: [
              "You have the right to inquire about the specific features and functionalities of the electronic tracking system installed on your vehicle",
              "California law requires us to provide you with a written notice before activating the engine demobilization system. This notice will outline the specific steps you can take to prevent repossession.",
            ],
          },
          {
            label: "Dispute Resolution:",
            option: [
              "If you have any questions or concerns regarding the electronic tracking system or engine demobilization, please do not hesitate to contact our dealership management team.",
              "In the event of a dispute, you have the right to pursue legal action in accordance with California consumer protection laws.",
            ],
          },
        ].map(({ label, option }, key) => {
          return (
            <div key={key}>
              <h2 className="font-bold">{label}</h2>
              <ul className="list-none  ml-12">
                {option.map((txt, kindx) => {
                  return (
                    <li className="relative my-2" key={"ch-" + kindx}>
                      <span className="bg-black rounded-full p-1 absolute  top-2 -left-4"></span>
                      {txt}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className="my-10 font-bold ">
      <h1 className="mb-2">
        By signing your BHPH financing agreement, you acknowledge that you have read and understood
      this disclosure regarding electronic tracking and engine demobilization.
        </h1>
        <p className="text-left flex  gap-6 flex-wrap ">
          <p>
            Buyer1 
            <span className="border-gray-500 border-b px-12 sm:px-28" />
          </p>

          <p>
            {" "}
            Buyer2 
            <span className="border-gray-500 border-b px-12 sm:px-28"></span>
          </p>
          <p>
            {" "}
            Date:
            
          </p>
        </p>
      </div>

      <div className="my-6 flex justify-center">
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
          type="button"
          size="md"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

const EmpltyRowCreate = ({ arr }: { arr: string[] }) => {
  return (
    <>
      {arr.map((item) => {
        return (
          <tr key={item}>
            <td className="border border-gray-400 p-6" />
            <td className="border border-gray-400 p-4" />
            <td className="border border-gray-400 p-4" />
          </tr>
        );
      })}
    </>
  );
};
