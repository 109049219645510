import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";

import {
  filesSignalLintingImg,
} from "./upload-photos.component";
import {  Typography } from "components";
import { ListingDetails, ListingDetailsSnapshot, usePostSubmitResearchVehicleForm, useUpdateListingStatusMulti } from "services";
import { getUserId } from "utils/get-user-id";
import "./index.scss";
import { useCallback, useEffect, useState } from "react";
import ThankYou from "modules/shared/features/UsedCarForm/ThankYou";
import { useNavigate } from "react-router-dom";
import { capitalizeForDashOrWhiteSpace } from "utils";
import { UploadDocumentsModal } from "./upload-documents.component";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";
import { UseFormGetValues } from "react-hook-form";
import { IManageListingValidationSchema } from "./manage-listing.validation";
import { RootState } from "modules/dealer/store/store";
import { useSelector } from "react-redux";
interface IActionResearch {
  isItCreate: boolean;
  listing: ListingDetails | undefined;
  params: any;
  isPendingUpdateListing: boolean;
  actionBtn: number;
  seActionType: React.Dispatch<React.SetStateAction<number>>;
  getValues: UseFormGetValues<IManageListingValidationSchema>
  
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  snapshot?: ListingDetailsSnapshot[];
  refetchListingData: ()=> void;
  cancelData : {
    nameWithTrim: string,
    year:string
    trim:string,
    model: string,
    make: string
  }
  mutateAsyncUpdateListingData: (data: any)=>void
}

export const ActionResearch: React.FC<IActionResearch> = ({
  isItCreate,
  listing,
  params,
  isPendingUpdateListing,
  seActionType,
  actionBtn,
  getValues,
  showPreview,
  setShowPreview,
  snapshot,
  refetchListingData,
  cancelData,
  mutateAsyncUpdateListingData
}) => {
  // const { isOpen, onOpen, onOpenChange, } = useDisclosure({onClose:()=>setShowPreview(false)});
  const [isOpen, setIsOpen] = useState(showPreview);
  // const [modalType, setModalType] = useState<  "document" | null>(null);
  const [initDoc, setInitDoc] = useState<string[]>([]);
  const navigate =  useNavigate()
  const researchInfo = useSelector((state: RootState) => state.list.research);
  // const {
  //   mutateAsync: submitResearchMutate,
  //   isPending: initIsPendingTosubmitReseach,
  //   error: errResearch,
  // } = usePostSubmitResearchVehicleForm();
  
  const {
    mutateAsync: softDeleteListingMutAsyc,
    isPending: isPendingSoftDeleteListing,
  } = useUpdateListingStatusMulti(Number(listing?.listingId || 0));

  useEffect(() => {
 
    plotImages(snapshot);
  }, [snapshot]);

  const plotImages = useCallback(
    (snapshot: ListingDetailsSnapshot[] | undefined) => {
      if (snapshot) {
        for (const ss of snapshot) {
         if (ss.code.startsWith("Document") && ss.value) {
            setInitDoc((prev: any[]) => prev.concat(ss.value));
          }
        }
      }
    },
    []
  );


  const onConverToLive = async (status: string) => {
    seActionType(Number(status))
    try {
  const userId = getUserId();
    const {nameWithTrim,make,model, trim, year, } = cancelData;
    const price = getValues("price") || "0"
    const mileage = getValues("mileage") || "0"
    
      const formDataPayload = new FormData();
      formDataPayload.append("listingInput.userId", userId?.toString());
      formDataPayload.append(
        "listingInput.listingId",
        params?.listingId || "0"
      );
      formDataPayload.append("listingInput.tenantId", userId?.toString());
      formDataPayload.append(
        "listingInput.description",
        "{}"
      );
      formDataPayload.append("listingInput.name", researchInfo?.name || nameWithTrim);
      formDataPayload.append(
        "listingInput.make",
        capitalizeForDashOrWhiteSpace(make)
      );
      formDataPayload.append("listingInput.model", model);
      formDataPayload.append("listingInput.trim", trim);
      formDataPayload.append("listingInput.year", year);
      formDataPayload.append("listingInput.vin", params?.vin || "");
      formDataPayload.append("listingInput.licensePlate", researchInfo?.phoneNumber||"");
      formDataPayload.append("listingInput.price", price || "0");  formDataPayload.append("listingInput.purchasePrice", getValues("purchasePrice")?.toString()||"0");
      formDataPayload.append("listingInput.repairCost", getValues("repairCost")?.toString()||"0");
      formDataPayload.append("listingInput.reconditioningCost", getValues("reconditioningCost")?.toString()||"0");
      formDataPayload.append("listingInput.actualSalePrice", getValues("actualSalePrice")?.toString()||"0");
      formDataPayload.append("listingInput.financingPrice", getValues("financingPrice")?.toString()||"0");
    formDataPayload.append("listingInput.downPayment", getValues("downPayment")?.toString()||"0");
    formDataPayload.append("listingInput.interestRate", getValues("interestRate")?.toString()||"0");
    formDataPayload.append("listingInput.financeType", getValues("financeType")?.toString()||"0");
    formDataPayload.append("listingInput.term", getValues("term")?.toString()||"0");
    formDataPayload.append("listingInput.monthlyPayment", getValues("monthlyPayment")?.toString()||"0");
   
      formDataPayload.append("listingInput.status", status);
      
      formDataPayload.append(
        "listingInput.mileage",
        mileage || "0"
      );

      // Add auctionDetails properties
      formDataPayload.append(
        "auctionDetails.listingId",
        params?.listingId || "0"
      );
      formDataPayload.append("auctionDetails.listingPrice", "0");
      formDataPayload.append("auctionDetails.enableAuction", "false");
      formDataPayload.append("auctionDetails.startingBid", "0");
      formDataPayload.append("auctionDetails.reservePrice", "0");
      formDataPayload.append(
        "auctionDetails.biddingEndDate",
        "2025-02-02T00:00:00"
      );
      // console.log("filesSignalLintingImg.value", filesSignalLintingImg.value);
      // filesSignalLintingImg.value.forEach((flx, index) => {
      //   formDataPayload.append(`uploadfiles`, flx.file, flx.part + index);
      // });

      // const vehicleConditionWithListingId: any = {
      //   ...vehicleCondition,
      //   listingId: Number(params?.listingId),
      // };

      // mutateAsyncVehicleCondition(vehicleConditionWithListingId).then((e) => {
      //   console.error("error :", e);
      // });
      const res = await mutateAsyncUpdateListingData({payload: formDataPayload,  hasPositionalImages:false,replaceImages:false});
      await softDeleteListingMutAsyc(+status)
      // if(researchInfo){
      //   await submitResearchMutate(researchInfo as any)
      // }
      refetchListingData();
      if(status === "1"){

        navigate("/dealer/manage-inventory/live-inventory")
      }else{
          seActionType(3)
    setShowPreview(true)
    refetchListingData()
   
      }
      
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    if (showPreview) {
      setIsOpen(true);
    }
  }, [showPreview]);
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-items-center gap-4 mt-10">
      <Button
          type="button"
          
          onClick={() => {
            window.open(
              `https://mmr.manheim.com/ui-mmr/?country=US&vin=${listing?.vin}&mid=201401746560735&excludeBuild=true`,
              "_blank"
            );
          }}
          className="bg-gradient-to-r from-[#018ffd] w-full  overflow-hidden whitespace-nowrap overflow-ellipsis to-[#070c84] text-white text-lg md:text-[15px]  py-8 px-6 rounded-2xl"
        >
         <p className="w-full  overflow-hidden whitespace-nowrap overflow-ellipsis"> Manheim Market Report</p>
        </Button>
        <Button
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
        >
          Upload Documents
        </Button>
        <Button
          
          className={`bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl `}
          type="button"
          
          onClick={()=>onConverToLive("3")}
          isLoading={actionBtn === 3 && (isPendingUpdateListing || isPendingSoftDeleteListing)}
          isDisabled={
            isPendingUpdateListing || (listing?.status === 3 )
          }
        >
          Cancel
        </Button>
        <Button
        
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:text-[15px] w-full py-8 px-6 rounded-2xl"
          // onClick={handleReportInaccuracy}
          isDisabled={
            isPendingUpdateListing || (listing?.status === 1)
          }
          isLoading={actionBtn === 1 && (isPendingUpdateListing || isPendingSoftDeleteListing)}
          type="button"
          onClick={()=>onConverToLive("1")}
        >
          Convert to Listing
        </Button>
    
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={() => {
          setIsOpen((p) => !p);
          setShowPreview(false);
        }}
        scrollBehavior="outside"
        shadow="lg"
        backdrop="opaque"
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
          base: " max-[768px]:mt-0",
        }}
        size={showPreview ?"2xl" : "3xl"}
        closeButton={<BlackCloseXIcon tabIndex={-1} width={"32px"} height={"32px"} />}
      >
        <ModalContent className="add-photo-model">
          {(onClose) => (
             showPreview ? (
              <div className="h-full min-h-[600] pt-36">
                <ThankYou
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onClose()
                  }}
                  buttonText=""
                  textNote="Cancelled Successfully"
                />
              </div>
            ) :
            <>
              { (

                <>
                  <ModalHeader className="flex items-center justify-center">
                    <Typography
                      variant="custom"
                      className="text-2xl font-[600] mt-2 -mb-4"
                    >
                      View/Upload Documents
                    </Typography>
                  </ModalHeader>
                  <ModalBody className="">
                    <UploadDocumentsModal
                      // aiOnState={aiOnState}
                      // setAiOnState={setAiOnState}
                      onClose={onClose}
                      isItCreate={isItCreate}
                      listingId={Number(params?.listingId)}
                      initDoc={initDoc}
                      onSubmit={refetchListingData}
                      // images={images}
                      // coverImg={intiat_cover.current}
                    />
                  </ModalBody>
                </>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
