import { z } from "zod";

export const UserUpdateValidationSchema = z.object({
  userName: z.string().trim().optional(),
  firstName: z
    .string()
    .trim()
    .min(1, "First Name is Required")
    .refine((value) => {
      if (!value) return true;
      const firstname = /^[a-zA-Z]+$/.test(value || "");
      return firstname;
    }, "Name can only contains Alphabetic Characters."),
  lastName: z
    .string()
    .trim()
    .min(1, "Last Name is Required")
    .refine((value) => {
      if (!value) return true;
      const lastname = /^[a-zA-Z]+$/.test(value || "");
      return lastname;
    }, "Name can only contains Alphabetic Characters."),
  phoneNumber: z
    .string({ required_error: "Phone Number is Required" })
    .trim()
    .min(1, "Phone Number is Required")
    .refine(
      (value) =>
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value),
      "Please provide valid US Phone Number"
    ),
  dob: z
    .string()
    .min(1, "Date of Birth is Required")
    .refine(
      (value) =>
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/.test(value),
      {
        message: "DOB should be in MM/DD/YYYY format",
      }
    )
    .refine(
      (value) => {
        if (!value) return true;
        const dobDate = new Date(value.replace(/-/g, "/")); // Convert dashes to slashes if needed
        const year = dobDate.getFullYear();
        const currentYear = new Date().getFullYear();
        return year >= 1920 && year <= currentYear;
      },
      {
        message:
          "Date of Birth must be between the years 1920 and the current year",
      }
    )
    .refine(
      (value) => {
        if (!value) return true;
        const dobDate = new Date(value.replace(/-/g, "/"));
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < dobDate.getDate())
        ) {
          age--;
        }
        return age >= 18;
      },
      {
        message: "User must be 18 years or older",
      }
    ),

  email: z
    .string()
    .min(1, "Email is Required")
    .email({ message: "Please provide a valid Email" }),
  password: z.string().optional(),
  // .trim()
  // .min(1, "Password is Required")
  // .refine((value) => {
  //   if (!value) return true;
  //   const passwordRegex =
  //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  //   return passwordRegex.test(value);
  // }, "At least 8 Characters with 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Characters is Required"),
  address: z.string().optional(),
  username:z.string().min(1, "Username is Required"),
});

// TODO: Remove this after we're done pushing the commit to trigger the testing build
