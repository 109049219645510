import React, { useEffect, useState } from "react";
import "./DmvTitle.scss";
import white_check from "./assets/white_check.svg";
import gray_check from "./assets/gray_check.svg";
import step1active from "./assets/step_1_active.svg";
import step2active from "./assets/step_2_active.svg";
import step3active from "./assets/step_3_active.svg";
import step4active from "./assets/step_4_active.svg";
import step2base from "./assets/step_2_base.svg";
import step2base_real from "./assets/step_2_base_real.png";

import step3base from "./assets/step_3_base.svg";
import step4base from "./assets/step_4_base.svg";
import { useParams } from "react-router";
import { shippingEndpoint } from "services/endpoints/shipping-quotes/shipping.endpoint";
import { useWindowSize } from "usehooks-ts";
interface Props {
  isForDmv?: boolean;
}
const stepsForDmv = [
  {
    label: "Pending Documents",
    step: 1,
    activeIcon: step2active,
    baseIcon: "",
    activeLabelClass: "w-10 h-10",
  },
  {
    label: "Sent to Processing",
    step: 2,
    baseIcon: step2base_real,
    activeIcon: step1active,

    activeLabelClass: "w-14 h-14",
  },
  {
    label: "Shipped to State",
    step: 3,
    activeIcon: step3active,
    baseIcon: step3base,

    activeLabelClass: "w-12 h-12",
  },
  {
    label: "Completed",
    step: 4,
    activeIcon: step4active,
    baseIcon: step4base,

    activeLabelClass: "w-10 h-10",
  },
];
const stepsForShipping = [
  {
    label: "Order Received",
    step: 1,
    activeIcon: step2active,
    activeLabelClass: "w-10 h-10",
  },
  {
    label: "Driver Assigned",
    step: 2,
    baseIcon: step2base_real,
    activeIcon: step1active,
    activeLabelClass: "w-14 h-14",
  },
  {
    label: "In Transit",
    step: 3,
    activeIcon: step3active,
    baseIcon: step3base,

    activeLabelClass: "w-12 h-12",
  },
  {
    label: "Delivered",
    step: 4,
    activeIcon: step4active,
    baseIcon: step4base,

    activeLabelClass: "w-10 h-10",
  },
];

const DmvTransitStatus = ({ isForDmv }: Props) => {
  const [activeStep, setActiveStep] = useState(1);
  const { width:winWidth = 0 } = useWindowSize();
  const steps = isForDmv ? stepsForDmv : stepsForShipping;
  const totalSteps = steps.length;
  const { id } = useParams();
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
  useEffect( () => {
    try
    {
      shippingEndpoint.getShippingStatus(id).then((res)=>{
        setActiveStep(res||1);
      })
    }catch (e){
      console.log("error :",e)
    }

   }, [id]);

  return (
    <React.Fragment>
      <div className="dmv-title flex justify-center items-center">
        <div className="section shadow rounded-lg pt-2 mb-8 pb-2 px-16" >
          <div className="flex justify-center mb-8">
            <h5
              style={{
                background:
                  "linear-gradient(95deg, #018FFD 18.97%, #070C84 94.41%)",
              }}
              className=" py-2 rounded-lg px-8  text-white  text-2xl"
            >
              {isForDmv ? "Titling Statuses" : "Transit Statuses"}
            </h5>
          </div>
          <div className="process-bar ">
            <div className="main-container ">
              <div className="step-container" style={{ width: "100%" }}>
                {steps.map(
                  ({ step, label, activeIcon, activeLabelClass, baseIcon }) => (
                    <div className="step-wrapper" key={step}>
                      <div
                        className={`${
                          activeStep >= step ? "step-style-check" : "step-style"
                        }`}
                        data-step={
                          activeStep >= step ? "completed" : "incomplete"
                        }
                      >
                        {activeStep >= step ? (
                          <div className="p-2">
                            <img src={white_check} alt="white" />
                          </div>
                        ) : (
                          <div className="step-count p-2">
                            <img src={gray_check} alt="gray" />
                          </div>
                        )}
                      </div>
                      <div className="step-label-container">
                        <div className="step-label" key={step}>
                          <div className="flex flex-col justify-center items-center text-center step-img-texts">
                            {activeStep >= step ? (
                              <>
                                <img
                                  className={`${activeLabelClass} min-h-14 h-full`}
                                  src={activeIcon}
                                  alt={"done"}
                                />
                                <p className="p-1 text-sm md:text-xl font-bold text-[#070C84] ">
                                  {label}
                                </p>
                              </>
                            ) : (
                              <>
                                <img
                                  className={`${activeLabelClass} min-h-14 h-full`}
                                  src={baseIcon}
                                  alt={"Pending"}
                                />
                                <p className="text-nowrap text-sm md:text-xl font-bold  text-[#D9D9D9]">
                                  {label}
                                </p>
                              </>
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div
                  className="progress-bar-load"
                  style={{ width: winWidth < 724? `${(512* (+width/100))}px` :width }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DmvTransitStatus;
