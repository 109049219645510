export const capitalizeForDashOrWhiteSpace = (str: string) => {
  const lowerCaseStr = str.toLowerCase();

  if (lowerCaseStr.includes("-") || lowerCaseStr.includes(" ")) {
    const splitStr = lowerCaseStr.split(/[-\s]/);
    return splitStr
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  } else {
    return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
  }
};

export const capitalizeForDelimiter = (str: string, delimiter: string) => {
  const lowerCaseStr = str.toLowerCase();
  if (lowerCaseStr.includes(delimiter)) {
    const splitStr = lowerCaseStr.split(delimiter);
    return splitStr
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(delimiter);
  } else {
    return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
  }
};

export const capitalizeAllBeforeDash = (str: string) => {
  if (str.includes("-")) {
    const splitStr = str.split("-");
    const firstWord = splitStr[0];
    const secondWord = splitStr[1];
    return `${firstWord.charAt(0).toUpperCase() + firstWord.slice(1)}-${
      secondWord.charAt(0).toUpperCase() + secondWord.slice(1)
    }`;
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

export const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const makeKeyAsTitle = (data: { [key: string]: any }) => {
  const keys = Object.keys(data);
  const newObject: any = {};
  keys.forEach((key) => {
    newObject[capitalizeWords(key)] = data[key];
  });
  return newObject;
};

export const removeHtmlTags = (htmlString: string) => {
  const htmlRegex = /<[^>]+>/g;
  const dashUnderscoreRegex = /-{3,}\s|_{3,}\s|-{3,}$|_{3,}$/g;
  const cleanText = htmlString.replace(htmlRegex, "");
  if (dashUnderscoreRegex.test(cleanText)) {
    return null;
  }
  return cleanText;
};

export const camelCase = (str: string) => {
  //convert this to camel casing should remove spaces "Buyer First Name" to buyerFirstName
  const splitStr = str.split(" ");
  const firstWord = splitStr[0].toLowerCase();
  const restOfTheWords = splitStr
    .slice(1)
    .map((word) => capitalizeForDashOrWhiteSpace(word))
    .join("");
  return firstWord + restOfTheWords;
};

export const makeDecimalAPercentage = (value: number) => {
  const decimalValue = value.toFixed(2);
  return `${decimalValue}%`;
};

export const extractNumberWithComma = (input: string): number | null => {
  const pattern = /(\d{1,3}(,\d{3})+)/;
  const match = input.match(pattern);
  if (match) {
    const numberWithComma = match[0];
    const numberWithCommaWithoutComma = numberWithComma.replace(/,/g, "");
    return parseInt(numberWithCommaWithoutComma, 10);
  } else {
    return null;
  }
};

export const removeHTMLTags = (text: any) => {
  return text.replace(/<[^>]+>/g, "");
};

export const formatPhoneNumber = (
  inputValue: string,
  field: { onChange: (value: string) => void }
): void => {
  const formattedValue: string = inputValue.replace(/\D/g, "");

  if (formattedValue.length < 4) {
    field.onChange(formattedValue);
  } else if (formattedValue.length < 7) {
    field.onChange(
      `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`
    );
  } else {
    field.onChange(
      `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
        3,
        6
      )}-${formattedValue.slice(6)}`
    );
  }
};
