import React, {  useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
} from "@nextui-org/react";

import { GradientModal,  Typography } from "components";
import Input from "components/ui/input";
import { getCurrUsername } from "utils/get-user-id";
// import { EyeIcon } from "assets/svgs/icons/eye-icon";
// import { EyeSlashedIcon } from "assets/svgs/icons/eye-slash-icon";
import EditUserName from "modules/shared/features/Edit-Details/EditUsername.components";
import EditPassword from "modules/shared/features/Edit-Details/EditPassword.components";
import ForgotPassword from "modules/shared/features/Edit-Details/ForgotPass.component";
import { RootState } from "modules/dealer/store/store";
import { useSelector } from "react-redux";

interface Props {
 }

export const LoginDetails: React.FC = ({}:Props) => {
  const [openModal, setOpenModal] = useState<number | null>(null);

  const userInfo = useSelector((state: RootState) => state.user.info);
  
  

  return (
    <>
      <Card fullWidth className="h-full" isPressable={false}>
        <CardHeader className="flex flex-row justify-between px-4 py-6">
          <Typography
            variant="custom"
            className="text-xl md:text-2xl font-[600] text-[#070C84]"
          >
            Login Details
          </Typography>
          {/* {openModal ? (
      <Button
        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg md:mr-2 h-full py-2"
        size="sm"
        disableAnimation
        type={
          dirtyFields["oldPassword"] || dirtyFields["newPassword"]
            ? "submit"
            : "button"
        }
        isLoading={isPending}
      >
        <Typography
          variant="custom"
          className="text-xs font-[600]"
        >
          Save
        </Typography>
      </Button>
    ) : (
      <Button
        className="bg-transparent md:mr-5"
        size="sm"
        disableAnimation
        onClick={() => setOpenModal(!openModal)}
        type="button"
      >
        <Image
          src="/assets/icons/user-edit-pencil.svg"
          height="30"
          width="30"
          className="cursor-pointer"
        />
      </Button>
    )} */}
        </CardHeader>
        <CardBody className=" pb-4 px-6 md:px-24 justify-center ">
          {/* <Form
      onSubmit={handleSubmit}
      schema={LoginDetailsValidationSchema}
      className="grid grid-cols-8 gap-5"
    >
      {({ formState: { errors, dirtyFields }, register }) => {
        return ( */}
          <div className="mb-8 ">
            <div className="flex flex-col h-full justify-center ">
              <FormControl>
                <Input
                  size="compact"
                  className="col-span-5"
                  classNames={{
                    input: "ml-2 text-left ",
                  }}
                  startContent={
                    <Image
                      src="/assets/icons/username-pencil-edit.svg"
                      className="w-[20px] h-[20px]"
                      removeWrapper
                      radius="none"
                    />
                  }
                  endContent={
                    <Button
                      className="bg-transparent cursor-pointer -mr-4"
                      size="sm"
                      disableAnimation
                      onClick={() => setOpenModal(1)}
                      type="button"
                    >
                      <Image
                        src="/assets/icons/user-edit-pencil.svg"
                        height="25"
                        width="25"
                        className="cursor-pointer"
                      />
                    </Button>
                  }
                  // isDisabled={!openModal}
                  radius="xl"
                  value={userInfo?.username || ""}                  
                />
              </FormControl>
              <FormControl>
                <Input
                  // isDisabled={!openModal}
                  size="compact"
                  variant="custom"
                  className="col-span-5 cursor-none"
                  classNames={{
                    input: "ml-2 text-left pt-1",
                  }}
                  startContent={
                    <Image
                      src="/assets/icons/password-edit.svg"
                      className="w-[20px] h-[20px]"
                      removeWrapper
                      radius="none"
                    />
                  }
                  endContent={
                    <Button
                      className="bg-transparent cursor-pointer -mr-4"
                      size="sm"
                      disableAnimation
                      onClick={() => setOpenModal(2)}
                      type="button"
                    >
                      <Image
                        src="/assets/icons/user-edit-pencil.svg"
                        height="25"
                        width="25"
                        className="cursor-pointer"
                      />
                    </Button>
                  }
                  radius="xl"
                  value="Password"
                  defaultValue={""}
                  // errorMessage={errors?.oldPassword?.message}
                  type={"text"}
                />
              </FormControl>
              {/* <FormControl>
      <Input
        isDisabled={!openModal}
        size="compact"
        className="col-span-5"
        classNames={{
          input: "ml-2 text-left pt-1",
        }}
        startContent={
          <Image
            src="/assets/icons/password-edit.svg"
            className="w-[20px] h-[20px]"
            removeWrapper
            radius="none"
          />
        }
        radius="xl"
        placeholder="New Password"
        defaultValue={""}
        errorMessage={errors?.newPassword?.message}
        type={isVisible2 ? "text" : "password"}
        endContent={
          <button
            className="absolute right-3 focus:outline-none"
            type="button"
            onClick={
              isVisible2
                ? () => setIsVisible2(false)
                : () => setIsVisible2(true)
            }
          >
            {isVisible2 ? (
              <EyeIcon className="text-3xl pointer-events-none text-default-600" />
            ) : (
              <EyeSlashedIcon className="text-3xl pointer-events-none text-default-600" />
            )}
          </button>
        }
        {...register("newPassword")}
      />
    </FormControl> */}
            </div>
          </div>
          {/* );
  }}
</Form> */}
          {/* <CardFooter className="text-center w-full flex justify-center">
            {data?.message && (
              <TextErrorHelper
                className={`w-10/12 ${
                  data?.statusCode === 200 ? "text-green-500" : ""
                }`}
              >
                {data?.message}
              </TextErrorHelper>
            )}
          </CardFooter> */}
        </CardBody>
      </Card>
      <GradientModal
        isOpen={openModal !== null}
        onOpenChange={() => setOpenModal(null)}
      >
        {openModal === 1 ? (
          <EditUserName type="USER" username={userInfo?.username || ""}  onClose={()=>setOpenModal(null)}/>
        ) : openModal === 2 ? (
          <EditPassword type="USER" handleForgatePass={()=>setOpenModal(3)} />
        ) : (
          <ForgotPassword />
        )}
      </GradientModal>
    </>
  );
};

const FormControl = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-5 justify-items-stretch mb-3 align-baseline items-center">
      {children}
    </div>
  );
};
