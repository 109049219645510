import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  MouseEvent,
} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactDOMServer from "react-dom/server";
import { AxiosError } from "axios";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";
import { Link } from "react-router-dom";
import EmailTheme from "./EmailTheme";
import Input from "components/ui/input";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Button, Checkbox } from "@nextui-org/react";
import { env } from "config/env";
import { Typography } from "components";
import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";
interface ForgotProps {
  setCurrScreen: (screen: string) => void;
  handleScreenChange: () => void;
}

const ForgotPass: React.FC<ForgotProps> = ({
  setCurrScreen,
  handleScreenChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);

  const [isSent, setIsSent] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const ticketContentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      ticketContentRef.current &&
      !ticketContentRef.current.contains(event.target as Node)
    ) {
      handleScreenChange();
    }
  };

  useEffect(() => {
    // The 'mousedown' event is of type 'any' to avoid type mismatch.
    document.addEventListener("mousedown", handleClickOutside as any);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside as any);
    };
  }, [ticketContentRef, handleScreenChange]);

  const navigateToLogin = () => {
    navigate("/");
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    if (email.trim() === "") {
      setMessage("Field is empty");
      return;
    }
    setLoading(!isLoading);
    try {
      const response = await axios.post(
        `${env.BASE_API_URL}User/send-reset-email/${email}`,
        {
          email: email,
        }
      );
      console.log("Email sent:", email);
      console.log(response.status, response.data);
      setIsSent(true);
      setLoading(!isLoading);
    } catch (error) {
      setLoading(!isLoading);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          setMessage(`Error: ${error.response.data.message}`);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }

        setMessage("Failed to send email. Please try again.");
      }
    }
  };

  return (
    <>
      {!isOpen && (
        <div className="bg-gradient-to-r relative rounded-large from-blue-600 to-indigo-700 max-[540px]:h-[400px] max-[540px]:w-[300px] min-[768px]:h-[400px] min-[768px]:w-[500px] max-[768px]:h-[400px] max-[768px]:w-[500px]  max-[768px]:p-4  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-[Metropolis-Bold]">
          <BlackCloseXIcon
            height={"20px"}
            width={"20px"}
            onClick={navigateToLogin}
            tabIndex={-1}
            fill="white"
            className="absolute z-30 border-1 rounded-full p-[2px]  top-0 mt-4 right-4  text-white cursor-pointer "
          />

          <div
            ref={ticketContentRef}
            className="text-center  max-[540px]:w-full min-[768px]:w-full max-[768px]:w-full  max-[540px]:p-4 min-[768px]:p-2 max-[768px]:p-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          >
            {isSent ? (
              <div className="flex items-center justify-center text-white text-[150px] -mt-4 max-[768px]:text-[120px]">
                <IoIosCheckmarkCircleOutline />
              </div>
            ) : (
              <div>
                <h1 className="text-white text-md lg:text-2xl">
                  Forgot Your Password?
                </h1>
                <div className=" text-white text-xs lg:text-sm mt-2 mb-5">
                  Please provide your email or phone number and we will send you
                  a password reset link.
                </div>
              </div>
            )}
            <div>
              {isSent ? (
                <div className="flex flex-col items-center justify-center mt-8 font-[Metropolis-Bold]">
                  <h1 className="text-[22px] text-white mt-[-20px] ">
                    Reset Password Email Sent
                  </h1>
                  <h1 className="text-[15px] text-white mt-[4px] w-[450px] leading-[17px]">
                    You should soon receive an email allowing you to reset your
                    password. Please make sure to check your spam and trash if
                    you can't find the email.
                  </h1>
                </div>
              ) : (
                <div className="flex items-center justify-center p-2">
                  <Input
                    variant="form"
                    placeholder="Enter Email or Phone Number"
                    // startContent={
                    //   <span className="relative top-0 max-[768px]:top-0 flex items-center justify-center text-gray-900 min-[768px]:text-2xl max-[768px]:text-xl">
                    //     <MdOutlineMail />
                    //   </span>
                    // }
                    type="email"
                    name="email"
                    value={email}
                    classNames={{
                      input: "data-[has-start-content=true]:ps-2 text-center",
                      inputWrapper: "",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              )}

              {!isSent && (
                <>
                  {message && (
                    <div className="text-red-600 font-semibold">{message}</div>
                  )}

                  <div className="flex justify-center mt-6 mb-2  items-center mx-2 md:mx-8">
                    <Checkbox
                      onChange={(e) => setChecked(e.target.checked)}
                      checked={checked}
                      size="lg"
                      className="border-black relative  -left-2 -top-5"
                      radius="sm"
                    />{" "}
                    <Typography
                      variant="custom"
                      className="text-[9.5px] text-white text-left font-[500]"
                    >
                      By submitting my contact information on{" "}
                      <span
                        onClick={() =>
                          window.open(
                            "https://findmyoptions.com/",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                        className="underline cursor-pointer"
                      >
                        findmyoptions.com
                      </span>{" "}
                      , I agree to receive communications from findmyoptions,
                      from the vehicle’s seller, and from the seller’s agent(s).
                      If I include my phone number, I agree to receive calls and
                      text messages (including via automation). I can opt out at
                      any time.Standard message and data rates may apply. I also
                      agree to{" "}
                      <span
                        onClick={() =>
                          window.open(
                            "https://www.findmyoptions.com/#/listing/landing-page?policy=privacy",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                        className="underline cursor-pointer"
                      >
                        the Privacy Policy
                      </span>
                      .
                    </Typography>
                  </div>
                  <Button
                    className="text-blue-500 max-[540px]:w-20 max-[540px]:h-8 min-[768px]:w-28 min-[768px]:h-12 max-[768px]:w-20 max-[768px]:h-8 m-auto min-[768px]:mt-4 max-[768px]:mt-4 bg-white rounded-xl max-[768px]:rounded-md cursor-pointer"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    isDisabled={!checked}
                  >
                    Send
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPass;
