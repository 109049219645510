import { Button } from "@nextui-org/react";

interface Props {
  handleContinue: () => void;
}
export default function BhphfContractOne({ handleContinue }: Props) {
  return (
    <div className="p-2 md:p-8">
      <h1 className="text-center font-bold text-lg">
        PRE-COMPUTED INTEREST VEHICLE SALES CONTRACT AND SECURITY AGREEMENT
      </h1>
      <h2 className="mt-4 font-semibold">Itemization of Amount Financed</h2>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full border-collapse border border-gray-400">
          <thead>
            <tr>
              <th className="border border-gray-400 p-2">Fee Type</th>
              <th className="border border-gray-400 p-2">Description</th>
              <th className="border border-gray-400 p-2">Subtotals</th>
              <th className="border border-gray-400 p-2">Totals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-400 p-2">
                1. Total Cash Price
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 pl-4">
                A. Cash Price Motor Vehicle and Accessories
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">
                1. Cash Price Vehicle
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">
                2. Cash Price Accessories
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">3. Other</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <EmpltyRowCreate arr={["B1"]} />
            
            <tr>
              <td className="border border-gray-400 p-2">
                B. Document Processing Charge
              </td>
              <td className="border border-gray-400 p-2">
                (Not a Governmental Fee)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                C. Emissions Testing Charge
              </td>
              <td className="border border-gray-400 p-2">
                (Not a Governmental Fee)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                D. (Optional) Theft Deterrent Device(s)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <EmpltyRowCreate arr={["E1", "E2", "E3"]} />
            <tr>
              <td className="border border-gray-400 p-2">
                E. (Optional) Surface Protection Product(s)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <EmpltyRowCreate arr={["F1", "F2", "F3"]} />

            <tr>
              <td className="border border-gray-400 p-2">
                F. EV Charging Station
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                G. Sales Tax (On Taxable Items)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                H. Electronic Vehicle Registration or Transfer Charge
              </td>
              <td className="border border-gray-400 p-2">
                (Not a Governmental Fee)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                I. (Optional) Service Contract (To Whom Paid)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                J. Prior Credit Lease Balance (e) Paid by seller to (see down
                payment calculation)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-4">Vehicle 1</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-4">Vehicle 2</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                K. (Optional) Guaranteed Asset Protection Waiver (GAP)
              </td>
              <td className="border border-gray-400 p-2">
                A separate GAP agreement must be signed by Buyer if applicable
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                L. Used Vehicle Contract Cancellation
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">M. Other (For)</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <EmpltyRowCreate arr={["m1"]} />
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto mt-4">
        <table className="min-w-full border-collapse border border-gray-400">
        
          <tbody>
            <tr>
              <td className="border border-gray-400 p-2">
                1. Total Cash Price (A through M)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-4" />
              <td className="border border-gray-400 p-4" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
                2. Amounts Paid to Public Officials
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
           
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">
              a. License Fees
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">
              b. Registration/Transfer/Titling Fees
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">c. Tire Fees</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-8">d. Other</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2  pl-4" >Total Official Fees (a through d)</td>
              <td className="border border-gray-400 p-2 "/>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            
            
            <tr>
              <td className="border border-gray-400 p-2">
              3. Amounts Paid to Insurance Companies
              </td>
              <td className="border border-gray-400 p-2">
                
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>


            <tr>
              <td className="border border-gray-400 p-2">
                
              </td>
              <td className="border border-gray-400 p-2">
              Procured by Dealer Excluding GAP
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>

            <tr>
              <td className="border border-gray-400 p-2">
                4. State Emissions Certification Fee
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
          
            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              State Emissions Exemption Fee    </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            

            <tr>
              <td className="border border-gray-400 p-2">
              5. Subtotal (1 through 4)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2">
              6. Total Down Payment
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>

            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              A. Trade In Values
              </td>
              <td className="border border-gray-400 p-2">
                
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>

            <tr>
              <td className="border border-gray-400 p-2">
              Vehicle 1 Vin
              </td>
              <td className="border border-gray-400 p-2" >yr,mk,md,odo</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            
            <tr>
              <td className="border border-gray-400 p-2">
              Vehicle 2 Vin
              </td>
              <td className="border border-gray-400 p-2" >yr,mk,md,odo</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              B. Less Prior Credit or Lease Balance (e)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-4">Vehicle 1</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-4">Vehicle 2</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>

            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              C. Net Trade in (A less B)
              </td>
              <td className="border border-gray-400 p-2">
              (Indicate if Negative Number)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-4">Vehicle 1</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2 pl-4">Vehicle 2</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>

            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              D. Deferred Down Payment
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            
            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              E. Manufacturers Rebate
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              F. Other
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 pl-8">
              G. Remaining Cash Down Payment
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 font-bold pl-8">
              Total Down Payment (6C through G)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
            <tr>
              <td className="border border-gray-400 p-2 ">
              
              </td>
              <td className="border border-gray-400 p-2" >(If negative, enter zero on line 6 then enter
the amount less than zero as a positive
number on line 1J above)</td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>

            <tr>
              <td className="border border-gray-400 font-bold p-2 ">
              7. Amount Financed (5 less 6)
              </td>
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            </tr>
        
          </tbody>
        </table>
      </div>
      <div className="my-6 flex justify-center">
      <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 font-bold tracking-wide px-6 rounded-xl h-[54px]"
            type="button"
            size="md"
            onClick={handleContinue}
          >
            Continue
          </Button>
      </div>
    </div>
  );
}

const EmpltyRowCreate = ({ arr }: { arr: string[] }) => {
  return (
    <>
      {arr.map((item) => {
        return (
          <tr key={item}>
            
              <td className="border border-gray-400 p-4" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
              <td className="border border-gray-400 p-2" />
            
          </tr>
        );
      })}
    </>
  );
};
