import { useMutation, useQuery } from "@tanstack/react-query";
import { userEndpoint } from "./endpoints/user/user.endpoint";
import {
  ChangePasswordRequest,
  UserResponse,
  UserUpdateRequest,
  UsersResponse,
} from "./endpoints/user/user.type";

const api = userEndpoint;
export const useUpdateUser = () => {
  return useMutation<UserResponse | { message : string}, Error, UserUpdateRequest>({
    mutationFn: async (payload) => {
      const res = await api.updateUserByIdAsync(payload);
      return res;
    },
  });
};

export const useGetAllUser = () => {
  return useQuery<UsersResponse[], Error>({
    queryKey: ["all-user"],
    queryFn: async () => {
      const res = await api.getUsersAsync();
      return res;
    },
  });
};

// export const useGetCurrentUser = (id: number) => {
//   return useQuery<UserResponse, Error>({
//     queryKey: ["current-user", id],
//     queryFn: async () => {
//       const res = await api.getCurrentUserByIdAsync(id);
//       return res;
//     },
//     retry: 0,
//     refetchOnMount: false,
//     refetchOnWindowFocus: false,
//   });
// };

export const useGetCurrentUserForBoth = (
  id: number,
  isThisDealer: boolean = true
) => {
  return useQuery<UserResponse, Error>({
    queryKey: ["current-user-dealer"],
    queryFn: async () => {
      if (isThisDealer) {
        const res = await api.getDealerByIdAsync(id);
        return res;
      } else {
        const res = await api.getCurrentUserByIdAsync(id);
        return res;
      }
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

// postUserProfilePictureAsync
export const usePostUserProfilePicture = (id: number) => {
  return useMutation<UserResponse, Error, FormData>({
    mutationFn: async (payload) => {
      const res = await api.postUserProfilePictureAsync(payload, id);
      return res;
    },
  });
};

//getUserProfilePictureAsync
export const useGetUserProfilePicture = (id: number) => {
  return useQuery<UserResponse, Error>({
    queryKey: ["user-profile-picture", id],
    queryFn: async () => {
      const res = await api.getUserProfilePictureAsync(id);
      return res;
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

//getDealerByIdAsync
export const useGetDealerById = (id: number) => {
  return useQuery<UserResponse, Error>({
    queryKey: ["dealer-by-id", id],
    queryFn: async () => {
      const res = await api.getDealerByIdAsync(id);
      return res;
    },
  });
};

//updateDealerByIdAsync
export const useUpdateDealerById = () => {
  return useMutation<UserResponse, Error, UserUpdateRequest>({
    mutationFn: async (payload) => {
      const res = await api.updateDealerByIdAsync(payload);
      return res;
    },
  });
};

//changePasswordAsync
export const useChangePassword = (type?: "user" | "dealer") => {
  return useMutation<any, Error, ChangePasswordRequest>({
    mutationFn: async (payload) => {
      const res = await api.changePasswordAsync(payload, type);
      return res;
    },
  });
};
