import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";


import { BlackCloseXIcon } from "assets/svgs/icons/black-x-icon";

interface ModalProps {
  isOpen: boolean;
  onOpen: () => void;
  Component: React.ReactNode;
  size: "5xl" | "2xl" | "xl"
}
export const CustomizableHeaderModel: React.FC<ModalProps> = ({
  isOpen,
  onOpen,
  Component,
  size ="xl"
}) => {
  return (
    <div>
      <Modal
        isDismissable={false}
        isOpen={isOpen}
        onOpenChange={onOpen}
        scrollBehavior="inside"
        shadow="lg"
        backdrop="opaque"
        placement="center"
        classNames={{
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
          base: " lg:mb-40 ",
          wrapper: "",
          closeButton:"mr-4"
        }}
        size={size}
        closeButton={<BlackCloseXIcon width={"32px"} height={"32px"} className="ml-8" />}
      >
        <ModalContent className="overflow-auto h-fit ">
          <ModalBody   style={{
              backgroundImage: "url(assets/images/payment-bg-1.png)",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover"
            }}
           className={` bg-transparent `}>{Component}</ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
