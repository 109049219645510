import Input from "components/ui/input";
import { Image } from "@nextui-org/react";
import Car from "../components/Car";
import searchIcon from "../assets/svg/search-icon-gray.svg";
import Loading from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { Typography } from "components";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCarlistSearch } from "./use-carlist-search.hook";

export default function ManageInventory() {
  const navigate = useNavigate();
  const { page, setPage,setSearchValue,setItems,listRefetch, items, carListings, isPendingListingQuery, isErrorListingQuery,listingQueryError,isFetchingCarList} = useCarlistSearch({})
  // useEffect(() => {
  //   if (searchValue) {
  //     setCars((listCarSearch as ListingDetails[]) || []);

  //     return;
  //   }
  //   if (prevAction === "search") {
  //     setCars((carListings as ListingDetails[]) || []);
  //     setPrevAction("list");
  //   } else {
  //     const newCars = (carListings as ListingDetails[]) || [];
  //     const oldCars = Array.isArray(cars) ? cars : [];
  //     const uniqueCars = Array.from(new Set([...oldCars, ...newCars]));
  //     setCars(uniqueCars);
  //     setPrevAction("list");
  //   }
  // }, [carListings, debonceSearchValue, listCarSearch, page]);

  // const handleScroll = () => {
  //   if (
  //     infiniteScroll.current &&
  //     window.innerHeight + window.scrollY >=
  //       infiniteScroll.current.offsetTop + infiniteScroll.current.clientHeight
  //   ) {
  //     let count = page + 1;
  //     setPage(count);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  if (isPendingListingQuery) {
    // TODO: Replace with skeleton
    return <div>Loading...</div>;
  }

  if (isErrorListingQuery) {
    return <div>Error: {listingQueryError?.message}</div>;
  }

  return (
    <InfiniteScroll
      dataLength={items?.length || 0}
      next={() => {
        setPage((p) => (p ? p + 1 : null));
      }}
      hasMore={page !== null && (carListings?.length || 0) >= 9}
      scrollThreshold={0.8}
      className="overflow-visible"
      style={{ overflow: "visible" }}
      loader={
        carListings && carListings?.length > 0 ? (
          <p className="flex justify-center items-center">
            <b>Loading...</b>
          </p>
        ) : (
          ""
        )
      }
    >
      {isFetchingCarList && <Loading />}

      <Typography variant="pageTitle" className="pb-[64px]">
        Manage Inventory
      </Typography>
      <div className="mb-[50px]">
        <div className="flex flex-wrap flex-col-reverse md:flex-row py-2 md:pb-8 text-[11px] md:text-[15px]">
          <div className="md:flex w-full md:flex-wrap justify-between">
            <button
              className="bg-gradient-to-t from-[#070c84] to-[#018ffd] text-white rounded-[10px] py-4 px-6 font-bold mt-5 md:mt-0 w-full md:w-[190px]"
              onClick={() => {
                navigate("/dealer/enter-vin");
              }}
            >
              Create New Listing
            </button>
            <Input
              variant="custom"
              className="w-full md:w-[400px] mt-[30px] md:mt-0"
              placeholder="Search Keywords"
              classNames={{ inputWrapper: "h-[54px] ", input:"placeholder:px-0", base:"placeholder:px-0" }}
           
              onChange={(event: any) => {
                const search = event.target.value;
                setSearchValue(search);
              }}
              startContent={
                <Image
                  shadow="none"
                  removeWrapper
                  radius="none"
                  className="w-4 h-4 lg:w-7 lg:h-7 cursor-pointer"
                  src={searchIcon}
                />
              }
            />
          </div>
        </div>
        

        {items?.length ? (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 gap-4">
            {items.map((car, index) => {
              return (
              <Car
                  key={index}
                  car={car}
                  refetch={() => {
                    setItems([]);
                    setPage(1);
                    listRefetch();
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className="mt-[100px] text-4xl text-center">
            No live inventory data available
          </div>
        )}
        {/* {pageSize % (cars?.length ?? 0) === 0 && <div ref={infiniteScroll} />} */}
      </div>
    </InfiniteScroll>
  );
}
