import { SVGProps } from "react";

export const NoGarageIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="249"
    height="129"
    viewBox="0 0 249 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M80.1896 127.063H30.604V129H80.1896V127.063Z" fill="#E0E0E0" />
    <path d="M155.343 127.063H105.757V129H155.343V127.063Z" fill="#E0E0E0" />
    <path d="M231.658 127.063H182.072V129H231.658V127.063Z" fill="#E0E0E0" />
    <path
      d="M87.9831 81.2131C82.7486 79.9001 78.174 76.5899 75.6314 72.2759C74.3749 70.1524 73.6068 67.7758 73.3827 65.3186C73.1586 62.8614 73.4842 60.3851 74.3358 58.0693C75.6835 54.4569 78.2852 51.2787 79.4001 47.6003C80.6645 43.4321 79.9107 39 79.1292 34.7311C78.3477 30.4622 77.5523 26.0474 78.7402 21.8584C79.9281 17.6693 83.6586 13.7304 88.4659 13.4317C92.9224 13.1573 97.035 16.1202 98.8863 19.7708C100.738 23.4214 100.755 27.6 100.321 31.5806C99.8867 35.5612 99.0427 39.5696 99.3066 43.5641C99.845 51.6712 104.899 59.3059 104.305 67.4095C103.996 71.6298 102.179 74.3287 99.067 77.5034C96.3854 80.2405 91.9255 82.0398 87.9762 81.1992"
      fill="#EBEBEB"
    />
    <path
      d="M89.578 121.388C89.5294 121.388 89.4946 103.427 89.4946 81.2763C89.4946 59.1259 89.5294 41.1611 89.578 41.1611C89.6266 41.1611 89.6613 59.119 89.6613 81.2763C89.6613 103.434 89.6058 121.388 89.578 121.388Z"
      fill="#E0E0E0"
    />
    <path
      d="M89.3173 67.1978C89.0442 67.0011 88.7875 66.7828 88.5496 66.5448C88.0911 66.128 87.4659 65.5375 86.8129 64.8636C86.1599 64.1898 85.552 63.5923 85.1144 63.1547C84.8625 62.9329 84.6301 62.6901 84.4197 62.4287C84.6916 62.6268 84.9482 62.8451 85.1873 63.0817C85.6458 63.4985 86.271 64.089 86.9241 64.7629C87.5771 65.4367 88.1849 66.0342 88.6226 66.4718C88.8724 66.6957 89.1046 66.9384 89.3173 67.1978Z"
      fill="#E0E0E0"
    />
    <path
      d="M103.739 118.057H75.8506V121.336H103.739V118.057Z"
      fill="#E0E0E0"
    />
    <path
      d="M89.8209 50.3936C90.4226 50.2539 90.9983 50.0194 91.5264 49.6989C92.0682 49.3572 92.5331 48.9065 92.8914 48.3755C93.3168 47.7575 93.573 47.039 93.6348 46.2914C93.6348 46.2636 93.6348 46.2393 93.6348 46.2185L93.732 46.2983C92.9492 46.43 92.2295 46.8099 91.6792 47.3821C91.2373 47.8259 90.8523 48.323 90.5329 48.8618C90.2585 49.3133 90.0675 49.6954 89.9355 49.9559C89.8763 50.0981 89.8054 50.2352 89.7236 50.3658C89.7591 50.2172 89.8103 50.0728 89.8765 49.9351C90.0368 49.5487 90.2212 49.1728 90.4287 48.8097C90.7392 48.255 91.1211 47.7436 91.5646 47.2883C92.1356 46.6844 92.8881 46.2832 93.7077 46.1455H93.8119V46.2393V46.3122C93.7512 47.0892 93.4824 47.8355 93.0339 48.4727C92.6604 49.0178 92.1719 49.4743 91.6028 49.81C91.2105 50.049 90.7825 50.2238 90.335 50.3276C90.2128 50.3586 90.0886 50.3807 89.9633 50.3936C89.8695 50.397 89.8244 50.4005 89.8209 50.3936Z"
      fill="#E0E0E0"
    />
    <path
      d="M89.821 50.3931C90.1698 49.8966 90.5615 49.4317 90.9916 49.0037C91.3873 48.535 91.8206 48.0994 92.2872 47.7012C91.9411 48.1987 91.5505 48.6638 91.1201 49.0906C90.7256 49.5615 90.2909 49.9973 89.821 50.3931Z"
      fill="#E0E0E0"
    />
    <path
      d="M169.93 83.0964C164.219 81.846 159.225 78.6886 156.449 74.576C153.674 70.4634 153.146 65.4511 155.036 61.0294C156.505 57.5872 159.346 54.5549 160.562 51.0536C161.951 47.073 161.121 42.8562 160.267 38.7887C159.412 34.7213 158.547 30.5149 159.839 26.517C160.624 24.2836 162.049 22.331 163.937 20.9033C165.825 19.4756 168.092 18.6361 170.454 18.4898C175.317 18.2258 179.808 21.0532 181.837 24.5301C183.865 28.0071 183.879 31.9946 183.407 35.7981C182.934 39.6015 182.017 43.405 182.299 47.2223C182.886 54.9508 188.405 62.2243 187.756 69.9458C187.408 73.9681 185.432 76.5454 182.038 79.5674C179.11 82.1759 174.244 83.8918 169.93 83.0895"
      fill="#EBEBEB"
    />
    <path
      d="M171.653 121.388C171.601 121.388 171.559 104.271 171.559 83.1588C171.559 62.047 171.601 44.9297 171.653 44.9297C171.705 44.9297 171.743 62.0435 171.743 83.1588C171.743 104.274 171.702 121.388 171.653 121.388Z"
      fill="#E0E0E0"
    />
    <path
      d="M171.389 69.7436C171.093 69.5581 170.812 69.3503 170.548 69.1219C170.048 68.7224 169.367 68.1597 168.651 67.5171C167.936 66.8745 167.262 66.3049 166.797 65.8881C166.53 65.6765 166.28 65.4443 166.05 65.1934C166.345 65.3789 166.626 65.5867 166.89 65.8151C167.391 66.2146 168.071 66.7773 168.79 67.4199C169.509 68.0624 170.18 68.6321 170.642 69.0454C170.908 69.2586 171.158 69.492 171.389 69.7436Z"
      fill="#E0E0E0"
    />
    <path
      d="M187.131 118.213H156.689V121.336H187.131V118.213Z"
      fill="#E0E0E0"
    />
    <path
      d="M171.937 53.7277C172.583 53.5969 173.209 53.3783 173.796 53.0782C174.374 52.7641 174.882 52.335 175.289 51.8173C175.746 51.2437 176.027 50.55 176.099 49.82V49.7506L176.206 49.827C175.372 49.9424 174.592 50.3054 173.966 50.869C173.49 51.2818 173.069 51.7546 172.715 52.2758C172.417 52.7065 172.205 53.0712 172.062 53.3178C171.996 53.4538 171.918 53.584 171.83 53.7069C171.87 53.5644 171.926 53.4269 171.996 53.297C172.172 52.9255 172.374 52.5668 172.601 52.2237C172.945 51.686 173.363 51.1986 173.841 50.7752C174.492 50.1845 175.307 49.8044 176.178 49.6846H176.293V49.7749V49.8444C176.219 50.5999 175.923 51.3167 175.442 51.9041C175.016 52.4343 174.484 52.8687 173.879 53.1789C173.441 53.4064 172.973 53.5723 172.49 53.6721C172.355 53.7007 172.22 53.7216 172.083 53.7346C172.034 53.7372 171.986 53.7349 171.937 53.7277Z"
      fill="#E0E0E0"
    />
    <path
      d="M171.938 53.7276C172.321 53.2459 172.747 52.8006 173.212 52.3973C173.647 51.9431 174.122 51.5281 174.629 51.1572C174.245 51.6381 173.819 52.0833 173.355 52.4876C172.917 52.9394 172.443 53.3542 171.938 53.7276Z"
      fill="#E0E0E0"
    />
    <path
      d="M224.249 19.3202C225.068 15.0997 222.309 11.0149 218.089 10.1966C213.868 9.37837 209.784 12.1365 208.965 16.357C208.147 20.5776 210.905 24.6624 215.126 25.4806C219.346 26.2989 223.431 23.5408 224.249 19.3202Z"
      fill="#EBEBEB"
    />
    <path
      d="M215.189 34.759C215.106 33.689 215.079 32.6155 215.106 31.5426C215.079 30.4697 215.106 29.3962 215.189 28.3262C215.27 29.3963 215.296 30.4698 215.269 31.5426C215.296 32.6154 215.27 33.6889 215.189 34.759Z"
      fill="#E0E0E0"
    />
    <path
      d="M210.695 26.5134C210.733 26.5342 210 27.8784 209.059 29.5145C208.117 31.1505 207.322 32.453 207.277 32.4322C207.232 32.4113 207.972 31.0671 208.916 29.4311C209.861 27.7951 210.657 26.4786 210.695 26.5134Z"
      fill="#E0E0E0"
    />
    <path
      d="M206.085 23.6406C205.514 24.1995 204.904 24.7182 204.262 25.1933C203.655 25.7116 203.013 26.1875 202.341 26.6174C202.911 26.0581 203.519 25.5393 204.161 25.0647C204.77 24.5472 205.412 24.0714 206.085 23.6406Z"
      fill="#E0E0E0"
    />
    <path
      d="M222.959 31.5873C222.866 31.3317 222.797 31.0676 222.754 30.7989C222.643 30.3056 222.483 29.6318 222.286 28.8884C222.088 28.1451 221.883 27.4991 221.733 27.0023C221.638 26.7462 221.565 26.4825 221.514 26.2139C221.653 26.4504 221.764 26.7022 221.844 26.9641C222.022 27.4365 222.24 28.1 222.442 28.8468C222.643 29.5936 222.789 30.2778 222.869 30.7745C222.93 31.0412 222.96 31.3139 222.959 31.5873Z"
      fill="#E0E0E0"
    />
    <path
      d="M230.875 25.9784C230.073 25.3865 229.304 24.7499 228.572 24.0715C227.804 23.435 227.07 22.7579 226.374 22.043C227.177 22.6353 227.947 23.2719 228.68 23.9499C229.45 24.5836 230.183 25.2608 230.875 25.9784Z"
      fill="#E0E0E0"
    />
    <path
      d="M233.904 18.2667C233.588 18.2447 233.274 18.203 232.963 18.1417C232.386 18.0444 231.573 17.9193 230.702 17.8186C229.83 17.7179 229.007 17.6554 228.423 17.6206C228.106 17.6166 227.791 17.5887 227.478 17.5373C227.794 17.4944 228.112 17.4827 228.43 17.5025C229.017 17.5025 229.819 17.5546 230.719 17.6554C231.619 17.7561 232.411 17.902 232.987 18.027C233.3 18.0748 233.608 18.1551 233.904 18.2667Z"
      fill="#E0E0E0"
    />
    <path
      d="M233.915 10.6809C233.915 10.7261 232.237 11.1741 230.146 11.6847C228.055 12.1953 226.346 12.5705 226.325 12.5288C226.304 12.4871 228.003 12.0356 230.094 11.5249C232.185 11.0143 233.904 10.6392 233.915 10.6809Z"
      fill="#E0E0E0"
    />
    <path
      d="M225.102 1.84421C225.14 1.87199 224.335 3.15718 223.299 4.72372C222.264 6.29025 221.396 7.53376 221.358 7.5025C221.32 7.47124 222.129 6.18952 223.164 4.62646C224.199 3.06339 225.064 1.81642 225.102 1.84421Z"
      fill="#E0E0E0"
    />
    <path
      d="M216.359 0C216.386 0.356129 216.386 0.7137 216.359 1.06983C216.359 1.72979 216.339 2.64331 216.339 3.65062C216.339 4.65793 216.339 5.56798 216.366 6.22794C216.393 6.58407 216.393 6.94164 216.366 7.29777C216.302 6.94587 216.267 6.58916 216.262 6.23142C216.221 5.57146 216.186 4.65793 216.186 3.65062C216.186 2.64331 216.217 1.72632 216.255 1.06636C216.26 0.708627 216.295 0.351941 216.359 0Z"
      fill="#E0E0E0"
    />
    <path
      d="M209.739 8.24591C209.08 7.32919 208.468 6.37971 207.905 5.40114C206.929 3.80681 206.168 2.49731 206.21 2.473C206.252 2.44868 207.068 3.72345 208.044 5.31778C209.02 6.9121 209.777 8.2216 209.739 8.24591Z"
      fill="#E0E0E0"
    />
    <path
      d="M205.783 11.6084C205.502 11.5948 205.224 11.5505 204.953 11.4764C204.446 11.3722 203.751 11.202 202.994 10.9763C202.236 10.7505 201.556 10.5178 201.073 10.3337C200.805 10.245 200.548 10.1286 200.305 9.98633C200.58 10.0393 200.85 10.116 201.111 10.2156C201.604 10.3754 202.282 10.5907 203.039 10.813C203.796 11.0353 204.48 11.2229 204.98 11.3584C205.254 11.4201 205.522 11.5038 205.783 11.6084Z"
      fill="#E0E0E0"
    />
    <path
      d="M204.053 17.5997C203.232 17.7603 202.401 17.867 201.566 17.9193C200.736 18.0283 199.899 18.0816 199.062 18.0791C199.883 17.9186 200.714 17.8107 201.549 17.756C202.379 17.6491 203.216 17.5969 204.053 17.5997Z"
      fill="#E0E0E0"
    />
    <path
      d="M63.3602 13.2205C63.513 12.1229 61.9708 11.2232 60.8002 11.1364C60.2479 11.0912 59.6713 11.1746 59.1469 10.9905C58.0076 10.5945 57.5525 9.12526 56.441 8.65634C55.6109 8.30899 54.6556 8.61813 53.8498 9.02453C53.044 9.43093 52.252 9.93805 51.3524 9.9971C50.5674 10.0457 49.7198 9.75396 49.0356 10.136C48.525 10.4209 48.2575 11.0114 47.7886 11.3552C47.2989 11.6584 46.7318 11.8129 46.1561 11.7998C45.5845 11.7849 45.0153 11.878 44.4784 12.0742C43.9573 12.3104 43.5266 12.8592 43.6343 13.422L63.3602 13.2205Z"
      fill="#EBEBEB"
    />
    <path
      d="M218.791 50.7923C218.94 49.6947 217.402 48.7916 216.231 48.7082C215.675 48.6631 215.099 48.7499 214.574 48.5658C213.435 48.1698 212.983 46.6971 211.872 46.2316C211.042 45.8843 210.087 46.1934 209.281 46.5964C208.475 46.9993 207.679 47.5133 206.78 47.5689C205.998 47.621 205.147 47.3293 204.463 47.7113C203.956 47.9962 203.688 48.5832 203.216 48.9305C202.727 49.2326 202.161 49.3859 201.587 49.3717C201.014 49.3587 200.444 49.4517 199.906 49.6461C199.388 49.8857 198.958 50.4345 199.065 50.9972L218.791 50.7923Z"
      fill="#EBEBEB"
    />
    <path
      d="M53.4779 30.8371C53.7384 28.9475 51.0708 27.3914 49.0666 27.2316C48.1079 27.1517 47.118 27.3011 46.2114 26.985C44.2489 26.2903 43.4639 23.7651 41.55 22.9592C40.1189 22.3549 38.4725 22.8933 37.0831 23.5914C35.6937 24.2896 34.3252 25.1684 32.776 25.2656C31.4283 25.3525 29.9625 24.8523 28.7815 25.5123C27.9027 26.002 27.4442 27.0163 26.6314 27.5963C25.8186 28.1764 24.8079 28.291 23.8214 28.357C22.8349 28.423 21.8207 28.4196 20.9245 28.8294C20.0284 29.2393 19.2885 30.1876 19.4761 31.1567L53.4779 30.8371Z"
      fill="#F5F5F5"
    />
    <path
      d="M145.241 25.5646C145.466 23.9216 143.156 22.567 141.402 22.4385C140.572 22.3725 139.707 22.4975 138.919 22.2231C137.213 21.6291 136.532 19.4235 134.865 18.7218C133.622 18.1973 132.187 18.6628 130.982 19.2706C129.777 19.8785 128.582 20.66 127.231 20.7295C126.06 20.8059 124.799 20.3822 123.757 20.9414C122.989 21.3686 122.59 22.2509 121.885 22.7719C121.18 23.2929 120.297 23.3867 119.453 23.4353C118.609 23.484 117.717 23.4874 116.935 23.8452C116.154 24.203 115.511 25.0262 115.671 25.8668L145.241 25.5646Z"
      fill="#F5F5F5"
    />
    <path
      d="M248.315 122.082C248.315 122.131 192.722 122.173 124.163 122.173C55.6034 122.173 0 122.131 0 122.082C0 122.034 55.5756 121.992 124.163 121.992C192.75 121.992 248.315 122.03 248.315 122.082Z"
      fill="#263238"
    />
    <path
      d="M24.0989 122.082H18.5796L19.3021 119.4H24.0989V122.082Z"
      fill="#E0E0E0"
    />
    <path
      d="M24.0997 122.083C24.0945 122.016 24.0945 121.948 24.0997 121.881V121.339L24.065 119.401L24.131 119.471L19.3341 119.492L19.421 119.426L19.3168 119.822C19.0979 120.62 18.8895 121.392 18.695 122.104L18.5804 121.999C20.1643 121.999 21.5328 122.024 22.5124 122.041L23.669 122.065C23.8129 122.064 23.9567 122.071 24.0997 122.086C23.9696 122.103 23.8384 122.11 23.7072 122.107L22.5714 122.131C21.5954 122.149 20.2025 122.166 18.5804 122.173H18.4692L18.497 122.062L19.1084 119.776L19.216 119.38V119.314H19.2855L24.0824 119.339H24.1518V119.405C24.1518 120.249 24.1275 120.926 24.1136 121.385V121.913C24.1176 121.97 24.113 122.027 24.0997 122.083Z"
      fill="#263238"
    />
    <path
      d="M241.778 122.082H236.259L236.982 119.4H241.778V122.082Z"
      fill="#E0E0E0"
    />
    <path
      d="M241.778 122.082C241.773 122.015 241.773 121.948 241.778 121.881C241.778 121.735 241.778 121.558 241.778 121.339C241.778 120.853 241.757 120.196 241.743 119.401L241.809 119.47L237.012 119.491L237.099 119.425C237.065 119.55 237.03 119.686 236.995 119.821C236.776 120.62 236.568 121.391 236.373 122.103L236.29 121.996C237.874 121.996 239.242 122.02 240.222 122.037L241.379 122.062C241.522 122.06 241.666 122.067 241.809 122.082C241.679 122.099 241.548 122.106 241.417 122.103L240.281 122.128C239.305 122.145 237.912 122.162 236.29 122.169H236.158L236.186 122.058L236.797 119.773C236.835 119.637 236.87 119.502 236.905 119.377V119.311H236.974L241.771 119.335H241.841V119.401C241.841 120.245 241.816 120.922 241.802 121.381C241.802 121.593 241.802 121.766 241.802 121.909C241.801 121.967 241.793 122.026 241.778 122.082Z"
      fill="#263238"
    />
    <path
      d="M96.5241 119.4H93.2452H91.7273H88.4483L87.7258 122.082H91.7273H93.2452H97.2466L96.5241 119.4Z"
      fill="#E0E0E0"
    />
    <path
      d="M96.5246 119.401C96.548 119.456 96.5677 119.513 96.5836 119.571L96.7364 120.089C96.8754 120.568 97.0664 121.235 97.3026 122.065L97.3235 122.138H97.247C95.163 122.138 91.9917 122.169 88.4522 122.169H87.6082L87.6394 122.055C87.893 121.12 88.1396 120.217 88.3654 119.377L88.3827 119.311H88.4487L94.2564 119.345L95.9376 119.373H96.3787C96.4272 119.368 96.4761 119.368 96.5246 119.373H96.3613H95.9063L94.2356 119.401L88.4487 119.436L88.5321 119.373C88.3063 120.214 88.0667 121.11 87.8131 122.051L87.7263 121.94H88.4522C91.9917 121.94 95.163 121.94 97.247 121.971L97.1915 122.044C96.9796 121.221 96.8129 120.558 96.6913 120.078C96.6392 119.859 96.594 119.682 96.5628 119.543C96.5474 119.497 96.5346 119.449 96.5246 119.401Z"
      fill="#263238"
    />
    <path
      d="M171.93 119.4H168.655H167.133H163.858L163.135 122.082H167.133H168.655H172.653L171.93 119.4Z"
      fill="#E0E0E0"
    />
    <path
      d="M171.931 119.401C171.956 119.455 171.976 119.512 171.99 119.571C172.031 119.703 172.08 119.877 172.146 120.089C172.281 120.568 172.472 121.235 172.709 122.065L172.729 122.138H172.653C170.569 122.138 167.401 122.169 163.858 122.169H163.018L163.045 122.055C163.302 121.12 163.546 120.217 163.775 119.377L163.792 119.311H163.858L169.662 119.345L171.344 119.373H171.785C171.833 119.368 171.882 119.368 171.931 119.373H171.767H171.316L169.641 119.401L163.858 119.436L163.942 119.373L163.216 122.103L163.129 121.992H163.851C167.394 121.992 170.562 121.992 172.646 122.023L172.59 122.096C172.382 121.273 172.212 120.61 172.09 120.13C172.038 119.911 171.997 119.734 171.962 119.595C171.946 119.531 171.936 119.466 171.931 119.401Z"
      fill="#263238"
    />
    <path d="M182.827 108.578H173.74V122.083H182.827V108.578Z" fill="#263238" />
    <path
      d="M232.988 108.578H223.901V122.083H232.988V108.578Z"
      fill="#263238"
    />
    <path
      d="M179.51 84.7012C179.51 84.7012 176.155 87.2924 175.894 88.199C175.622 88.5489 175.476 88.9813 175.481 89.4251C175.426 90.0855 175.32 90.7406 175.162 91.3841C175.162 91.3841 173.644 94.2984 173.484 95.7329C173.324 97.1675 173.484 110.464 173.484 110.464H232.984V94.6006L231.335 90.5783C231.335 90.5783 230.921 88.1017 230.407 87.3792C229.893 86.6567 226.086 84.3886 226.086 84.3886L223.617 77.8584C222.762 75.9098 220.543 73.4159 217.326 73.4297H189.049C186.645 73.4297 183.96 75.5138 183.171 77.0491L179.51 84.7012Z"
      fill="#00DC58"
    />
    <g opacity="0.3">
      <path
        d="M173.414 100.745H232.984V110.464H173.484L173.414 100.745Z"
        fill="black"
      />
    </g>
    <path
      d="M182.532 84.3331H223.891C223.891 84.3331 223.373 74.8574 217.17 74.8574C210.966 74.8574 189.208 75.038 189.208 75.038C189.208 75.038 186.495 74.5205 184.203 78.3795C183.339 79.8603 182.808 81.5114 182.647 83.2181L182.532 84.3331Z"
      fill="#455A64"
    />
    <g opacity="0.3">
      <path
        d="M192.022 75.0176L199.556 84.3334H204.297L196.346 75.0176H192.022Z"
        fill="white"
      />
    </g>
    <g opacity="0.3">
      <path
        d="M189.208 75.0384L196.346 84.3334H197.531L190.417 75.0176L189.208 75.0384Z"
        fill="white"
      />
    </g>
    <path
      d="M223.891 84.333C223.891 84.333 223.95 84.4303 224.054 84.6283L224.52 85.5036C224.926 86.2851 225.523 87.4279 226.298 88.9041V88.9249C226.298 90.5783 226.298 92.6242 226.319 94.9931C226.319 96.1776 226.319 97.4454 226.319 98.7861C226.316 99.5189 226.028 100.222 225.514 100.745C225.001 101.268 224.304 101.569 223.571 101.586H203.317H187.937H184.463C183.901 101.586 183.359 101.586 182.803 101.565C182.527 101.533 182.257 101.463 182.001 101.357C181.5 101.145 181.073 100.791 180.771 100.339C180.625 100.124 180.508 99.8902 180.424 99.6441C180.298 99.1545 180.254 98.6476 180.292 98.1435C180.292 97.1675 180.292 96.2331 180.292 95.337C180.292 93.5516 180.292 91.9364 180.309 90.5158C180.309 89.8211 180.309 89.1438 180.292 88.5359C180.279 88.2389 180.338 87.9432 180.465 87.6745C180.577 87.4175 180.691 87.1778 180.813 86.959C181.292 86.0767 181.73 85.4341 182.022 84.9964L182.369 84.5032L182.494 84.34C182.494 84.34 182.459 84.4025 182.386 84.5136C182.313 84.6248 182.199 84.7985 182.06 85.0173C181.775 85.4584 181.365 86.108 180.882 86.9902C180.768 87.2125 180.653 87.4487 180.535 87.7023C180.414 87.9616 180.359 88.2468 180.375 88.5324C180.396 89.1438 180.399 89.8037 180.406 90.5123C180.406 91.9364 180.406 93.5516 180.406 95.337C180.406 96.2331 180.406 97.1687 180.406 98.1435C180.367 98.6336 180.411 99.1268 180.535 99.6024C180.615 99.8375 180.725 100.062 180.861 100.269C181.006 100.478 181.178 100.668 181.372 100.832C181.774 101.166 182.266 101.372 182.786 101.426C183.314 101.454 183.876 101.426 184.432 101.426H187.906H203.286H223.537C224.23 101.412 224.891 101.128 225.378 100.635C225.866 100.141 226.141 99.4764 226.145 98.7827C226.145 97.4419 226.145 96.1741 226.145 94.9896C226.145 92.6207 226.145 90.5748 226.145 88.9215V88.9423L224.408 85.5209L223.964 84.6352C223.936 84.4407 223.891 84.333 223.891 84.333Z"
      fill="#263238"
    />
    <g opacity="0.3">
      <path
        d="M187.809 97.5914L194.137 90.9883H212.71L219.174 97.5914L212.467 92.923H194.339L187.809 97.5914Z"
        fill="black"
      />
    </g>
    <path
      d="M231.831 92.9229C231.831 92.9229 231.831 93.0271 231.831 93.2321C231.831 93.437 231.831 93.7461 231.831 94.1386C231.831 94.9375 231.831 96.1046 231.852 97.5913V97.6364H231.807L222.64 97.6607H219.74H219.174C219.174 97.6781 219.045 97.6225 219.045 97.6086L218.966 97.5461C218.771 97.3725 218.59 97.1988 218.403 97.0112L217.291 95.8962C215.801 94.3714 214.242 92.7423 212.619 91.0334L212.685 91.0611H194.13L194.193 91.0334C191.931 93.3884 189.806 95.6045 187.857 97.633L187.836 97.6573H187.801L174.126 97.6225H174.085V97.5809C174.085 96.2887 174.102 95.2953 174.106 94.6145C174.106 94.2834 174.106 94.0298 174.106 93.8538C174.106 94.024 174.106 94.2845 174.106 94.6145C174.106 95.3092 174.106 96.2887 174.126 97.5809L174.085 97.5392L187.76 97.501L187.704 97.5253L194.026 90.9257L194.05 90.9014H212.696L212.724 90.9292C214.339 92.6312 215.895 94.2637 217.382 95.792L218.49 96.9105C218.674 97.0911 218.858 97.2578 219.042 97.4385L219.111 97.494L219.132 97.5114C219.132 97.5114 219.132 97.5114 219.132 97.5357V97.5183H222.606L231.772 97.5461L231.727 97.5913C231.727 96.1046 231.744 94.9375 231.748 94.1386C231.748 93.7473 231.748 93.4451 231.748 93.2321C231.821 93.0271 231.831 92.9229 231.831 92.9229Z"
      fill="#263238"
    />
    <path
      d="M211.682 92.9229H194.853V96.9521H211.682V92.9229Z"
      fill="#455A64"
    />
    <path
      d="M173.289 108.161L175.398 105.605L231.789 105.479L232.991 109.061V112.423L228.035 115.4H179.364C179.364 115.4 173.55 113.142 173.282 110.61L173.289 108.161Z"
      fill="#263238"
    />
    <g opacity="0.3">
      <path
        d="M224.863 109.981H182.532C182.116 109.981 181.704 110.063 181.32 110.221C180.936 110.38 180.587 110.613 180.292 110.907C179.998 111.201 179.765 111.55 179.605 111.934C179.446 112.318 179.364 112.73 179.364 113.146V115.254L181.539 115.41H227.618V112.732C227.618 112.371 227.547 112.013 227.408 111.679C227.27 111.345 227.067 111.041 226.811 110.786C226.555 110.53 226.251 110.328 225.917 110.19C225.583 110.052 225.225 109.981 224.863 109.981Z"
        fill="black"
      />
    </g>
    <path
      d="M183.943 113.271C183.943 113.6 183.846 113.922 183.664 114.196C183.481 114.47 183.222 114.684 182.918 114.81C182.614 114.937 182.279 114.97 181.956 114.906C181.633 114.843 181.337 114.684 181.104 114.452C180.871 114.219 180.712 113.923 180.647 113.6C180.583 113.277 180.615 112.942 180.741 112.638C180.867 112.334 181.08 112.074 181.354 111.891C181.628 111.708 181.95 111.61 182.279 111.61C182.719 111.611 183.141 111.786 183.453 112.097C183.765 112.409 183.941 112.83 183.943 113.271Z"
      fill="#455A64"
    />
    <path
      d="M187.496 113.271C187.497 113.6 187.4 113.922 187.217 114.196C187.035 114.47 186.775 114.684 186.471 114.811C186.166 114.937 185.832 114.97 185.509 114.906C185.185 114.842 184.889 114.684 184.656 114.451C184.423 114.218 184.264 113.921 184.2 113.598C184.136 113.275 184.169 112.94 184.296 112.636C184.422 112.332 184.636 112.072 184.91 111.889C185.184 111.707 185.506 111.61 185.836 111.61C186.276 111.611 186.698 111.786 187.009 112.098C187.32 112.409 187.495 112.831 187.496 113.271Z"
      fill="#455A64"
    />
    <path
      d="M188.871 115.254C188.78 114.427 188.75 113.595 188.781 112.763C188.75 111.933 188.78 111.102 188.871 110.276C188.992 111.933 188.992 113.597 188.871 115.254Z"
      fill="#263238"
    />
    <path
      d="M193.39 115.254C193.299 114.427 193.269 113.595 193.3 112.763C193.268 111.933 193.299 111.102 193.39 110.276C193.511 111.933 193.511 113.597 193.39 115.254Z"
      fill="#263238"
    />
    <path
      d="M197.909 115.254C197.818 114.427 197.788 113.595 197.819 112.763C197.787 111.933 197.818 111.102 197.909 110.276C198.03 111.933 198.03 113.597 197.909 115.254Z"
      fill="#263238"
    />
    <path
      d="M202.425 115.254C202.304 113.597 202.304 111.933 202.425 110.276C202.516 111.102 202.546 111.933 202.515 112.763C202.546 113.595 202.516 114.427 202.425 115.254Z"
      fill="#263238"
    />
    <path
      d="M206.944 115.254C206.823 113.597 206.823 111.933 206.944 110.276C207.035 111.102 207.065 111.933 207.034 112.763C207.065 113.595 207.035 114.427 206.944 115.254Z"
      fill="#263238"
    />
    <path
      d="M211.462 115.254C211.342 113.597 211.342 111.933 211.462 110.276C211.554 111.102 211.584 111.933 211.553 112.763C211.584 113.595 211.554 114.427 211.462 115.254Z"
      fill="#263238"
    />
    <path
      d="M215.982 115.254C215.861 113.597 215.861 111.933 215.982 110.276C216.102 111.933 216.102 113.597 215.982 115.254Z"
      fill="#263238"
    />
    <path
      d="M220.501 115.254C220.38 113.597 220.38 111.933 220.501 110.276C220.621 111.933 220.621 113.597 220.501 115.254Z"
      fill="#263238"
    />
    <path
      d="M224.992 115.254C224.872 113.597 224.872 111.933 224.992 110.276C225.083 111.102 225.114 111.933 225.082 112.763C225.114 113.595 225.083 114.427 224.992 115.254Z"
      fill="#263238"
    />
    <path
      d="M174.512 106.667L182.977 106.695V105.587L175.39 105.604L174.512 106.667Z"
      fill="#FF725E"
    />
    <path
      d="M232.165 106.612H224.158V105.479H231.782L232.165 106.612Z"
      fill="#FF725E"
    />
    <path
      d="M225.878 84.3784C225.878 84.427 216.048 84.4687 203.922 84.4687C191.796 84.4687 181.966 84.427 181.966 84.3784C181.966 84.3298 191.796 84.2881 203.922 84.2881C216.048 84.2881 225.878 84.3298 225.878 84.3784Z"
      fill="#263238"
    />
    <path
      d="M232.984 111.131C232.982 111.173 232.977 111.215 232.967 111.256L232.894 111.603C232.768 112.067 232.555 112.503 232.265 112.888C231.533 113.93 229.89 114.972 227.625 115.452L203.387 115.483L181.08 115.455C179.784 115.464 178.5 115.21 177.305 114.708C176.403 114.326 175.579 113.782 174.873 113.104C174.428 112.679 174.037 112.201 173.71 111.679C173.623 111.545 173.544 111.406 173.473 111.263C173.45 111.214 173.429 111.164 173.411 111.113L173.491 111.256C173.543 111.349 173.623 111.488 173.741 111.662C174.079 112.17 174.475 112.636 174.922 113.051C175.627 113.712 176.444 114.242 177.336 114.615C178.521 115.094 179.791 115.331 181.07 115.309L203.377 115.285L227.614 115.32C229.851 114.854 231.487 113.833 232.224 112.829C232.514 112.453 232.733 112.027 232.87 111.572C232.905 111.422 232.936 111.304 232.957 111.224C232.978 111.145 232.984 111.131 232.984 111.131Z"
      fill="#455A64"
    />
    <path
      d="M175.46 89.6577C175.46 89.6577 176.381 88.3586 181.518 88.3586H225.391C225.391 88.3586 229.122 88.1641 231.23 89.9842L230.772 92.1377H220.699L217.017 89.7653H190.146L186.325 92.1377H175.905L175.46 89.6577Z"
      fill="#FF725E"
    />
    <path
      d="M226.18 89.0014C226.18 89.0535 215.533 89.0918 202.404 89.0918C189.274 89.0918 178.628 89.0535 178.628 89.0014C178.628 88.9493 189.27 88.9111 202.404 88.9111C215.537 88.9111 226.18 88.9667 226.18 89.0014Z"
      fill="white"
    />
    <g opacity="0.3">
      <path
        d="M182.755 83.7916C182.914 82.085 183.444 80.4336 184.308 78.9531C186.603 75.0906 189.313 75.6116 189.313 75.6116C189.313 75.6116 211.071 75.4275 217.274 75.4275C222.557 75.4275 223.718 82.298 223.943 84.337H224.002C224.002 84.337 223.485 74.8613 217.281 74.8613C211.078 74.8613 189.32 75.042 189.32 75.042C189.32 75.042 186.61 74.5244 184.314 78.3834C183.452 79.8645 182.922 81.5156 182.762 83.222L182.647 84.337H182.703L182.755 83.7916Z"
        fill="black"
      />
    </g>
    <path
      d="M196.878 75.5316C196.878 75.6793 196.936 75.8211 197.04 75.9258C197.144 76.0306 197.286 76.0899 197.433 76.0909H208.764C208.837 76.0909 208.91 76.0764 208.978 76.0483C209.046 76.0202 209.107 75.979 209.159 75.9271C209.211 75.8751 209.252 75.8135 209.281 75.7456C209.309 75.6778 209.323 75.6051 209.323 75.5316V75.4552C209.323 75.3816 209.309 75.3088 209.28 75.2409C209.252 75.173 209.21 75.1115 209.158 75.0598C209.106 75.0081 209.044 74.9673 208.975 74.9398C208.907 74.9122 208.834 74.8985 208.76 74.8995L197.43 74.9794C197.284 74.9803 197.144 75.0387 197.04 75.1421C196.937 75.2455 196.879 75.3854 196.878 75.5316Z"
      fill="#FF725E"
    />
    <path
      d="M108.971 104.815H99.5232V122.082H108.971V104.815Z"
      fill="#263238"
    />
    <path
      d="M161.782 104.815H151.893V122.082H161.782V104.815Z"
      fill="#263238"
    />
    <path d="M102.51 77.8896H99.9502V80.0119H102.51V77.8896Z" fill="#263238" />
    <path
      d="M97.6337 81.6201C97.2953 81.6201 96.9601 81.5534 96.6474 81.4239C96.3347 81.2944 96.0506 81.1045 95.8113 80.8652C95.572 80.6259 95.3821 80.3418 95.2526 80.0291C95.1231 79.7164 95.0564 79.3812 95.0564 79.0428V75.8576C95.0564 75.174 95.3279 74.5185 95.8113 74.0352C96.2946 73.5518 96.9502 73.2803 97.6337 73.2803C98.3173 73.2803 98.9728 73.5518 99.4562 74.0352C99.9395 74.5185 100.211 75.174 100.211 75.8576V79.0428C100.211 79.3812 100.144 79.7164 100.015 80.0291C99.8853 80.3418 99.6955 80.6259 99.4562 80.8652C99.2168 81.1045 98.9327 81.2944 98.62 81.4239C98.3073 81.5534 97.9722 81.6201 97.6337 81.6201Z"
      fill="#263238"
    />
    <path
      d="M157.353 80.0117H159.913V77.8894H157.353V80.0117Z"
      fill="#263238"
    />
    <path
      d="M162.23 81.6201C162.569 81.6201 162.904 81.5534 163.216 81.4239C163.529 81.2944 163.813 81.1045 164.053 80.8652C164.292 80.6259 164.482 80.3418 164.611 80.0291C164.741 79.7164 164.807 79.3812 164.807 79.0428V75.8576C164.807 75.174 164.536 74.5185 164.053 74.0352C163.569 73.5518 162.914 73.2803 162.23 73.2803C161.547 73.2812 160.892 73.5531 160.41 74.0364C159.927 74.5196 159.656 75.1746 159.656 75.8576V79.0428C159.656 79.7257 159.927 80.3807 160.41 80.864C160.892 81.3472 161.547 81.6192 162.23 81.6201Z"
      fill="#263238"
    />
    <path
      d="M110.624 53.0436C109.986 53.0532 109.36 53.216 108.798 53.5182C108.235 53.8204 107.754 54.2533 107.394 54.7804C106.986 55.3368 106.662 55.95 106.432 56.6005C105.984 58.0836 103.549 63.8948 101.618 78.4521C100.923 82.9259 100.34 88.0841 99.8393 93.593L99.2454 98.7754L161.073 99.0325C161.073 99.0325 159.298 81.4532 158.888 78.4486C158.479 75.4441 153.696 55.225 153.696 55.225C153.696 55.225 153.835 53.6445 150.569 53.0679C147.721 52.5712 118.409 52.936 110.624 53.0436Z"
      fill="#DEDADA"
    />
    <g opacity="0.3">
      <path
        d="M113.588 87.9038H147.107L159.872 86.8965L161.081 98.7167L146.492 99.0328L99.2461 98.7758L99.9859 92.006L100.507 86.8965L113.588 87.9038Z"
        fill="black"
      />
    </g>
    <path
      d="M108.103 62.8739C108.103 62.8739 105.616 62.9711 104.661 67.6534C103.705 72.3356 102.941 79.8904 102.941 79.8904H157.711L154.932 66.3161C154.932 66.3161 154.564 63.9924 151.407 62.8739C148.249 61.7554 109.461 61.5818 108.103 62.8739Z"
      fill="#455A64"
    />
    <path
      d="M124.925 61.9736H135.369V62.6683C135.369 63.0792 135.206 63.4732 134.916 63.7638C134.625 64.0543 134.231 64.2175 133.82 64.2175H126.474C126.063 64.2175 125.669 64.0543 125.378 63.7638C125.088 63.4732 124.925 63.0792 124.925 62.6683V61.9736Z"
      fill="#263238"
    />
    <path
      d="M102.941 79.8904L103.16 77.8896H157.176L157.711 79.8904H102.941Z"
      fill="#263238"
    />
    <path
      d="M153.682 78.5459H153.334C153.334 77.1954 152.798 75.9002 151.843 74.9452C150.888 73.9903 149.593 73.4538 148.242 73.4538C146.892 73.4538 145.596 73.9903 144.642 74.9452C143.687 75.9002 143.15 77.1954 143.15 78.5459H142.803C142.803 77.1033 143.376 75.7197 144.396 74.6996C145.416 73.6795 146.8 73.1064 148.242 73.1064C149.685 73.1064 151.068 73.6795 152.088 74.6996C153.109 75.7197 153.682 77.1033 153.682 78.5459Z"
      fill="#263238"
    />
    <path
      d="M141.472 78.1984C141.472 78.1984 142.226 76.809 144.418 76.7395C146.45 76.684 151.611 76.7395 151.611 76.7395C151.611 76.7395 154.13 76.8507 154.859 77.8893V78.5458H141.472V78.1984Z"
      fill="#263238"
    />
    <path
      d="M106.599 78.2504C106.38 78.5283 106.703 74.1656 107.203 72.2517C107.259 72.0372 107.384 71.8474 107.56 71.7121C107.735 71.5769 107.951 71.504 108.172 71.5049H117.096C117.334 71.5046 117.564 71.5895 117.745 71.7443C117.926 71.8991 118.046 72.1136 118.082 72.3489C118.287 73.6654 118.836 76.1941 118.958 78.2539L106.599 78.2504Z"
      fill="#263238"
    />
    <path
      d="M115.504 66.7324H109.919C109.29 66.7324 108.78 67.2425 108.78 67.8717V69.7856C108.78 70.4148 109.29 70.9249 109.919 70.9249H115.504C116.134 70.9249 116.644 70.4148 116.644 69.7856V67.8717C116.644 67.2425 116.134 66.7324 115.504 66.7324Z"
      fill="#263238"
    />
    <path
      d="M122.507 78.2504C122.288 78.5282 122.615 74.1656 123.112 72.2517C123.168 72.0378 123.294 71.8486 123.469 71.7136C123.645 71.5785 123.859 71.5052 124.081 71.5049H132.997C133.236 71.5044 133.467 71.5892 133.648 71.744C133.83 71.8988 133.95 72.1133 133.987 72.3489C134.192 73.6654 134.737 76.1941 134.862 78.2538L122.507 78.2504Z"
      fill="#263238"
    />
    <path
      d="M131.414 66.7324H125.828C125.199 66.7324 124.689 67.2425 124.689 67.8717V69.7856C124.689 70.4148 125.199 70.9249 125.828 70.9249H131.414C132.043 70.9249 132.553 70.4148 132.553 69.7856V67.8717C132.553 67.2425 132.043 66.7324 131.414 66.7324Z"
      fill="#263238"
    />
    <path
      d="M138.93 78.2504C138.711 78.5283 139.034 74.1656 139.534 72.2517C139.59 72.0375 139.716 71.848 139.891 71.7129C140.066 71.5777 140.282 71.5046 140.503 71.5049H149.426C149.665 71.5039 149.895 71.5885 150.077 71.7435C150.258 71.8984 150.377 72.1133 150.413 72.3489C150.618 73.6654 151.167 76.1941 151.288 78.2539L138.93 78.2504Z"
      fill="#263238"
    />
    <path
      d="M147.833 66.7324H142.247C141.618 66.7324 141.108 67.2425 141.108 67.8717V69.7856C141.108 70.4148 141.618 70.9249 142.247 70.9249H147.833C148.462 70.9249 148.972 70.4148 148.972 69.7856V67.8717C148.972 67.2425 148.462 66.7324 147.833 66.7324Z"
      fill="#263238"
    />
    <path
      d="M157.711 79.8906C157.711 79.8906 157.711 79.9636 157.711 80.1025C157.711 80.2415 157.711 80.4499 157.711 80.7312C157.711 81.2904 157.676 82.0998 157.648 83.1418C157.582 85.2433 157.492 88.2721 157.377 92.0096V92.0721H157.311L130.371 92.0964L102.951 92.0721H102.885V92.0061C102.885 88.4111 102.91 85.5003 102.917 83.4822C102.917 82.4818 102.917 81.7003 102.917 81.1654C102.917 80.9049 102.917 80.7034 102.917 80.561C102.917 80.4186 102.917 80.3561 102.917 80.3561C102.917 80.3561 102.917 80.4255 102.917 80.561C102.917 80.6965 102.917 80.9084 102.917 81.1654C102.917 81.7003 102.917 82.4818 102.934 83.4822C102.934 85.5003 102.951 88.4111 102.962 92.0061L102.899 91.9436L130.319 91.9158L157.259 91.9401L157.193 92.0061C157.332 88.2687 157.447 85.2398 157.541 83.1383C157.582 82.0963 157.617 81.287 157.641 80.7312C157.641 80.4603 157.662 80.2519 157.669 80.1025C157.676 79.9532 157.711 79.8906 157.711 79.8906Z"
      fill="#263238"
    />
    <path
      d="M147.106 87.9038C147.04 88.1018 147.106 94.5902 147.106 94.5902C147.106 94.5902 158.037 95.1842 160.482 92.4714L159.871 86.8965L147.106 87.9038Z"
      fill="#E0E0E0"
    />
    <path
      d="M147.106 87.9032C147.183 87.8877 147.261 87.8773 147.339 87.8719L148.034 87.8059L150.663 87.5732L159.864 86.8125H159.948V86.8924C160.139 88.6291 160.347 90.5048 160.566 92.4499V92.4916L160.538 92.5194C160.292 92.7758 160.01 92.9946 159.701 93.169C158.62 93.7134 157.457 94.0782 156.259 94.2492C155.148 94.4312 154.028 94.5541 152.903 94.6174C150.968 94.7319 149.028 94.7411 147.092 94.6452H147.026V94.5827C147.026 92.5229 147.026 90.8487 147.026 89.692C147.026 89.1154 147.026 88.65 147.051 88.3582C147.046 88.2031 147.06 88.0481 147.092 87.8962C147.092 88.1359 147.092 88.643 147.092 89.692C147.11 90.8626 147.127 92.5298 147.155 94.5792L147.089 94.5132C149.02 94.5905 150.954 94.5708 152.883 94.4541C154 94.3859 155.113 94.2595 156.217 94.0755C157.395 93.9075 158.537 93.5497 159.6 93.0161C159.894 92.8508 160.162 92.6437 160.396 92.4013L160.371 92.4708C160.16 90.5083 159.955 88.6256 159.767 86.9132L159.857 86.9862L150.691 87.6809L148.044 87.865L147.349 87.9066H147.165L147.106 87.9032Z"
      fill="#263238"
    />
    <path
      d="M113.383 87.9032C113.449 88.1012 113.383 94.5897 113.383 94.5897C113.383 94.5897 102.452 95.1836 99.9993 92.4743L100.611 86.8994L113.383 87.9032Z"
      fill="#E0E0E0"
    />
    <path
      d="M113.383 87.9033H113.317H113.133L112.438 87.8616L109.791 87.6775L100.625 86.9828L100.694 86.9168C100.507 88.6535 100.298 90.5257 100.086 92.4744L100.062 92.4049C100.297 92.6476 100.566 92.8547 100.861 93.0197C101.923 93.5538 103.064 93.9116 104.241 94.0791C105.346 94.2631 106.46 94.3895 107.579 94.4577C109.506 94.5744 111.439 94.5941 113.369 94.5168L113.306 94.5828C113.331 92.5334 113.352 90.8661 113.365 89.6956C113.365 88.6535 113.365 88.1395 113.365 87.8998C113.397 88.0516 113.411 88.2067 113.407 88.3618C113.407 88.6674 113.428 89.119 113.435 89.6956C113.435 90.8523 113.435 92.5265 113.435 94.5862V94.6488H113.369C111.433 94.7447 109.493 94.7354 107.558 94.621C106.434 94.5577 105.314 94.4348 104.202 94.2528C103.005 94.0822 101.843 93.7173 100.764 93.1725C100.453 92.9992 100.169 92.7802 99.923 92.523L99.8953 92.4952V92.4744C100.114 90.5119 100.323 88.6258 100.514 86.9168V86.8369H100.593L109.798 87.5976L112.424 87.8303L113.119 87.8963C113.207 87.8922 113.295 87.8945 113.383 87.9033Z"
      fill="#263238"
    />
    <path
      d="M99.2458 98.7753C99.2458 98.7753 98.6414 106.855 100.031 111.766C100.253 112.536 100.72 113.214 101.361 113.695C102.002 114.176 102.782 114.436 103.584 114.434H158.007C158.583 114.436 159.147 114.263 159.623 113.938C160.099 113.613 160.466 113.151 160.674 112.614C161.539 110.384 162.467 106.618 161.091 98.7197L144.991 98.7788L99.2458 98.7753Z"
      fill="#263238"
    />
    <g opacity="0.3">
      <path
        d="M100.766 101.422C100.937 101.54 101.461 105.055 103.924 105.573C106.386 106.09 110.523 105.802 110.523 105.802V101.422H100.766Z"
        fill="black"
      />
    </g>
    <path
      d="M108.45 103.607C108.45 104.002 108.333 104.388 108.113 104.717C107.894 105.045 107.581 105.301 107.216 105.452C106.851 105.603 106.449 105.643 106.062 105.565C105.674 105.488 105.318 105.297 105.039 105.018C104.76 104.738 104.57 104.382 104.494 103.994C104.417 103.606 104.457 103.205 104.609 102.84C104.76 102.475 105.017 102.163 105.346 101.944C105.675 101.725 106.061 101.609 106.456 101.609C106.718 101.609 106.978 101.661 107.22 101.761C107.462 101.862 107.682 102.009 107.867 102.195C108.053 102.38 108.199 102.6 108.299 102.843C108.399 103.085 108.451 103.345 108.45 103.607Z"
      fill="white"
    />
    <g opacity="0.3">
      <path
        d="M157.583 101.471C157.409 101.585 156.888 105.104 154.422 105.639C151.956 106.174 147.823 105.868 147.823 105.868V101.471H157.583Z"
        fill="black"
      />
    </g>
    <path
      d="M149.896 103.652C149.895 104.047 150.011 104.434 150.23 104.763C150.449 105.091 150.761 105.348 151.126 105.5C151.491 105.651 151.893 105.691 152.28 105.615C152.668 105.538 153.024 105.348 153.304 105.069C153.583 104.79 153.774 104.434 153.851 104.046C153.929 103.659 153.889 103.257 153.738 102.892C153.587 102.527 153.331 102.215 153.003 101.995C152.674 101.775 152.288 101.658 151.893 101.658C151.364 101.658 150.856 101.868 150.482 102.242C150.107 102.616 149.896 103.123 149.896 103.652Z"
      fill="white"
    />
    <path
      d="M115.23 101.422V104.854C115.23 105.744 115.583 106.597 116.213 107.226C116.842 107.856 117.695 108.209 118.585 108.209H141.858C142.748 108.209 143.601 107.856 144.23 107.226C144.86 106.597 145.213 105.744 145.213 104.854V101.609L115.23 101.422Z"
      fill="#455A64"
    />
    <g opacity="0.3">
      <path
        d="M141.872 106.851H118.6C118.159 106.851 117.723 106.765 117.316 106.597C116.909 106.429 116.539 106.182 116.227 105.87C115.916 105.559 115.669 105.189 115.5 104.783C115.331 104.376 115.244 103.939 115.244 103.499V104.913C115.244 105.353 115.33 105.79 115.499 106.197C115.667 106.605 115.914 106.975 116.226 107.286C116.538 107.598 116.908 107.845 117.315 108.014C117.722 108.182 118.159 108.269 118.6 108.268H141.872C142.313 108.269 142.749 108.182 143.157 108.014C143.564 107.845 143.934 107.598 144.246 107.286C144.557 106.975 144.804 106.605 144.973 106.197C145.141 105.79 145.228 105.353 145.227 104.913V103.499C145.227 103.939 145.141 104.376 144.972 104.783C144.803 105.189 144.556 105.559 144.244 105.87C143.933 106.182 143.563 106.429 143.156 106.597C142.749 106.765 142.313 106.851 141.872 106.851Z"
        fill="black"
      />
    </g>
    <path
      d="M117.064 92.0059C117.064 92.0059 115.491 92.6415 115.23 95.0452C114.914 97.9802 118.27 98.7757 118.27 98.7757H143.765C144.631 97.7166 145.14 96.4109 145.22 95.0452C145.283 92.8951 143.258 92.0059 143.258 92.0059H117.064Z"
      fill="#263238"
    />
    <path
      d="M153.508 55.7807C153.508 55.8294 143.064 55.871 130.187 55.871C117.311 55.871 106.863 55.8294 106.863 55.7807C106.863 55.7321 117.304 55.6904 130.187 55.6904C143.071 55.6904 153.508 55.7286 153.508 55.7807Z"
      fill="#263238"
    />
    <path
      d="M161.08 98.7266C161.08 98.7266 161.344 99.9562 161.604 102.2C161.762 103.572 161.835 104.952 161.823 106.333C161.826 108.168 161.56 109.994 161.035 111.752C160.955 111.995 160.875 112.242 160.781 112.485C160.697 112.739 160.58 112.98 160.434 113.204C160.12 113.664 159.681 114.026 159.17 114.246C158.907 114.362 158.628 114.437 158.343 114.468C158.055 114.492 157.766 114.5 157.478 114.493H155.72H148.079H130.614H113.142H105.522H103.761C103.184 114.512 102.611 114.407 102.079 114.184C101.583 113.967 101.14 113.646 100.78 113.242C100.421 112.838 100.153 112.36 99.9954 111.842C99.5374 110.069 99.2616 108.253 99.1722 106.424C99.0749 104.812 99.068 103.419 99.0819 102.283C99.0958 101.148 99.1374 100.269 99.1722 99.6713C99.193 99.3761 99.2069 99.1503 99.2208 98.9767C99.2347 98.803 99.2451 98.7474 99.2451 98.7474C99.2451 98.7474 99.2451 98.8273 99.2451 98.9801C99.2451 99.133 99.2277 99.3622 99.2173 99.6575C99.193 100.255 99.1617 101.137 99.1583 102.27C99.1548 103.402 99.1756 104.795 99.2833 106.403C99.3801 108.224 99.6617 110.03 100.124 111.794C100.279 112.293 100.541 112.753 100.892 113.141C101.242 113.53 101.672 113.838 102.152 114.045C102.668 114.259 103.224 114.357 103.781 114.333H105.543H113.163H130.635H148.1H155.741H157.499C157.781 114.343 158.064 114.337 158.346 114.316C158.746 114.269 159.132 114.136 159.476 113.928C159.821 113.719 160.116 113.439 160.344 113.107C160.489 112.891 160.606 112.658 160.691 112.412C160.785 112.172 160.868 111.929 160.948 111.69C161.477 109.945 161.749 108.132 161.757 106.309C161.777 104.931 161.715 103.553 161.57 102.183C161.518 101.616 161.452 101.116 161.396 100.686C161.34 100.255 161.288 99.8867 161.243 99.5915C161.198 99.2962 161.167 99.0739 161.142 98.9176C161.118 98.7613 161.08 98.7266 161.08 98.7266Z"
      fill="#455A64"
    />
    <path
      d="M161.08 98.727C161.08 98.7757 148.02 98.8173 131.903 98.8173C115.786 98.8173 102.726 98.7757 102.726 98.727C102.726 98.6784 115.783 98.6367 131.903 98.6367C148.023 98.6367 161.08 98.6645 161.08 98.727Z"
      fill="#455A64"
    />
    <path
      d="M32.4772 87.681H27.4997C27.1313 87.681 26.7779 87.5346 26.5173 87.274C26.2567 87.0135 26.1104 86.6601 26.1104 86.2916C26.1104 85.7315 26.3329 85.1943 26.7289 84.7982C27.125 84.4022 27.6621 84.1797 28.2222 84.1797H31.2928C31.4463 84.1797 31.5984 84.21 31.7402 84.2689C31.882 84.3277 32.0108 84.414 32.1193 84.5228C32.2277 84.6315 32.3136 84.7606 32.372 84.9026C32.4305 85.0446 32.4603 85.1967 32.4599 85.3502L32.4772 87.681Z"
      fill="#018FFD"
    />
    <path
      d="M78.0039 87.681H82.9779C83.3464 87.681 83.6998 87.5346 83.9604 87.274C84.2209 87.0135 84.3673 86.6601 84.3673 86.2916C84.3673 85.7315 84.1448 85.1943 83.7488 84.7982C83.3527 84.4022 82.8155 84.1797 82.2554 84.1797H79.1849C78.8744 84.1797 78.5767 84.303 78.3572 84.5225C78.1377 84.7421 78.0143 85.0398 78.0143 85.3502L78.0039 87.681Z"
      fill="#018FFD"
    />
    <path
      d="M36.4722 108.546H26.5103V121.891H36.4722V108.546Z"
      fill="#263238"
    />
    <path
      d="M84.5933 108.546H74.6313V121.891H84.5933V108.546Z"
      fill="#263238"
    />
    <path
      d="M27.524 90.8106L30.4208 88.4834L35.0023 75.9024C35.697 74.3359 37.0031 72.3213 40.0354 72.0156L71.6058 72.2935C72.4794 72.3018 73.3298 72.5762 74.0434 73.0801C74.7571 73.584 75.3002 74.2935 75.6003 75.114L79.7025 86.3472L80.8279 88.9002L83.5025 90.7724C84.0064 91.1236 84.4182 91.5911 84.703 92.1353C84.9877 92.6795 85.1371 93.2843 85.1385 93.8985V111.926C85.1385 111.926 85.0065 115.243 81.5608 115.243H29.9901C29.9901 115.243 26.6764 115.375 26.145 111.53C25.7247 108.48 25.9713 97.9173 26.0929 93.6832C26.1088 93.1292 26.2451 92.5854 26.4922 92.0893C26.7393 91.5933 27.0914 91.157 27.524 90.8106Z"
      fill="#018FFD"
    />
    <g opacity="0.3">
      <path
        d="M25.8467 103.697C26.2427 103.795 85.1389 103.697 85.1389 103.697V111.728C85.1389 113.684 83.7495 115.25 81.7835 115.25H30.324C29.7421 115.266 29.1631 115.162 28.623 114.945C28.0828 114.728 27.5928 114.403 27.1833 113.989C26.7738 113.575 26.4534 113.082 26.2421 112.539C26.0307 111.997 25.9328 111.417 25.9544 110.835L25.8467 103.697Z"
        fill="black"
      />
    </g>
    <path
      d="M27.8856 92.1267L39.3133 93.5161V100.216C39.3133 100.216 26.6664 99.8691 25.9092 98.181L26.0794 93.6655C26.0883 93.4428 26.1433 93.2244 26.2408 93.0239C26.3383 92.8235 26.4762 92.6454 26.6458 92.5009C26.8155 92.3564 27.0132 92.2485 27.2266 92.1841C27.4399 92.1197 27.6643 92.1002 27.8856 92.1267Z"
      fill="#E0E0E0"
    />
    <path
      d="M27.8853 92.127C27.72 92.1241 27.5549 92.1369 27.3921 92.1652C27.1617 92.2135 26.9449 92.312 26.757 92.4537C26.5691 92.5954 26.4147 92.7768 26.3049 92.9849C26.1575 93.2918 26.0929 93.6318 26.1173 93.9714C26.1173 94.3465 26.093 94.7564 26.0791 95.194C26.0478 96.0763 26.0166 97.0732 25.9784 98.1708V98.143C26.2479 98.4606 26.6138 98.6814 27.0204 98.7717C27.4546 98.9125 27.8964 99.0284 28.3438 99.1191C29.2677 99.3032 30.2577 99.4491 31.2962 99.5672C33.3803 99.8034 35.6277 99.9562 37.9862 100.064L39.3304 100.116L39.2366 100.206C39.2366 97.8235 39.2366 95.5622 39.2366 93.5059L39.3095 93.5893L31.0705 92.5473L28.7259 92.2416L28.0972 92.1652C28.0246 92.1575 27.9526 92.1447 27.8818 92.127C27.9547 92.1215 28.0278 92.1215 28.1006 92.127L28.7224 92.186L31.0705 92.4465L39.32 93.4122H39.3929V93.5164C39.3929 95.5727 39.3929 97.8339 39.3929 100.217V100.31H39.2991L37.9549 100.258C35.5929 100.151 33.3282 99.9944 31.2511 99.7478C30.209 99.6262 29.2191 99.4803 28.2882 99.2858C27.8349 99.1963 27.3872 99.0804 26.9475 98.9385C26.7361 98.8661 26.5306 98.7779 26.3326 98.6745C26.1267 98.5755 25.954 98.4186 25.8359 98.2229V98.2056C25.8846 97.1079 25.9262 96.1215 25.961 95.2288C25.9818 94.7911 25.9992 94.3813 26.0166 94.0061C26.0002 93.6541 26.0744 93.3037 26.2319 92.9884C26.3479 92.7647 26.5138 92.5706 26.7168 92.4212C26.9197 92.2718 27.1544 92.1712 27.4025 92.127C27.5619 92.0957 27.7259 92.0957 27.8853 92.127Z"
      fill="#263238"
    />
    <path
      d="M83.1624 92.1271L71.7346 93.5165V100.217C71.7346 100.217 84.1349 99.7792 84.8921 98.098L84.9686 93.6797C84.962 93.4557 84.9088 93.2355 84.8123 93.0331C84.7158 92.8308 84.5782 92.6509 84.4082 92.5047C84.2382 92.3586 84.0396 92.2496 83.8251 92.1845C83.6106 92.1195 83.3849 92.1 83.1624 92.1271Z"
      fill="#E0E0E0"
    />
    <path
      d="M83.1622 92.1267C83.3261 92.0932 83.4951 92.0932 83.6589 92.1267C83.8973 92.1677 84.1235 92.2614 84.3212 92.4008C84.5189 92.5401 84.683 92.7218 84.8017 92.9325C84.9718 93.2382 85.0457 93.5881 85.0136 93.9364C85.0136 94.308 85.0136 94.7144 85.0136 95.1486C85.0136 96.017 84.9893 97.0069 84.9754 98.0906C84.8593 98.2842 84.6907 98.4408 84.4891 98.5422C84.2937 98.6469 84.0905 98.7363 83.8813 98.8097C83.4459 98.9609 83.0016 99.0851 82.5509 99.1813C81.627 99.3793 80.6405 99.5287 79.6089 99.6641C77.5456 99.9212 75.2948 100.098 72.9468 100.22L71.7588 100.272H71.665V100.178C71.665 97.7989 71.665 95.5376 71.665 93.4779V93.4049H71.738L79.9701 92.474L82.3217 92.2135L82.9469 92.1545C83.0186 92.1491 83.0906 92.1491 83.1622 92.1545C83.0926 92.1721 83.0218 92.1848 82.9504 92.1927L82.3286 92.283L79.984 92.5887L71.7449 93.6307L71.8179 93.5473C71.8179 95.6071 71.8179 97.8683 71.8179 100.248L71.7241 100.157L72.912 100.105C75.2566 99.9837 77.5005 99.8135 79.5603 99.5634C80.6023 99.4384 81.5714 99.2855 82.4884 99.0945C82.9323 99.0039 83.3707 98.8879 83.8014 98.7471C84.2009 98.6498 84.5588 98.4271 84.8226 98.1115V98.1393C84.8469 97.0521 84.8677 96.0552 84.8886 95.1972C84.8886 94.7631 84.9094 94.3601 84.9163 93.985C84.9541 93.6519 84.8899 93.3152 84.7323 93.0194C84.621 92.815 84.4669 92.6372 84.2804 92.4981C84.0939 92.359 83.8796 92.2619 83.652 92.2135C83.4913 92.1719 83.3275 92.1429 83.1622 92.1267Z"
      fill="#263238"
    />
    <path
      d="M43.7037 93.5167C42.8426 93.5117 42.0081 93.815 41.3513 94.3718C40.6944 94.9287 40.2586 95.7022 40.1225 96.5525C40.0399 97.1275 40.0155 97.7093 40.0496 98.2892C40.251 102.294 41.0499 107.202 42.9534 108.401H56.0693V93.5167H43.7037Z"
      fill="#263238"
    />
    <path
      d="M66.9374 93.5167C67.7986 93.511 68.6335 93.8141 69.2905 94.371C69.9475 94.928 70.3831 95.7019 70.5185 96.5525C70.6011 97.1275 70.6256 97.7093 70.5914 98.2892C70.3935 102.294 69.5911 107.202 67.6876 108.401H54.5718V93.5167H66.9374Z"
      fill="#263238"
    />
    <path
      d="M70.2405 98.7927H40.5284C40.3165 98.7927 40.1133 98.7085 39.9635 98.5587C39.8137 98.4089 39.7295 98.2057 39.7295 97.9938C39.729 97.8886 39.7494 97.7843 39.7893 97.687C39.8293 97.5897 39.888 97.5012 39.9623 97.4266C40.0365 97.3521 40.1247 97.2929 40.2219 97.2526C40.319 97.2122 40.4232 97.1914 40.5284 97.1914H70.2405C70.3457 97.1914 70.4499 97.2122 70.547 97.2526C70.6442 97.2929 70.7324 97.3521 70.8066 97.4266C70.8809 97.5012 70.9396 97.5897 70.9796 97.687C71.0195 97.7843 71.0399 97.8886 71.0394 97.9938C71.0394 98.2057 70.9552 98.4089 70.8054 98.5587C70.6556 98.7085 70.4524 98.7927 70.2405 98.7927Z"
      fill="#455A64"
    />
    <path
      d="M68.685 95.6321H41.5919C41.4866 95.6321 41.3822 95.6113 41.2849 95.571C41.1875 95.5307 41.0991 95.4716 41.0246 95.3971C40.9501 95.3226 40.891 95.2341 40.8506 95.1368C40.8103 95.0394 40.7896 94.9351 40.7896 94.8297C40.7896 94.7243 40.8103 94.62 40.8506 94.5227C40.891 94.4253 40.9501 94.3369 41.0246 94.2624C41.0991 94.1878 41.1875 94.1287 41.2849 94.0884C41.3822 94.0481 41.4866 94.0273 41.5919 94.0273H68.685C68.8972 94.0283 69.1004 94.1132 69.2502 94.2636C69.3999 94.414 69.4839 94.6175 69.4839 94.8297C69.4839 95.0419 69.3999 95.2455 69.2502 95.3959C69.1004 95.5462 68.8972 95.6312 68.685 95.6321Z"
      fill="#455A64"
    />
    <path
      d="M70.237 100.759H40.8375C40.3944 100.759 40.0352 101.117 40.0352 101.559C40.0352 102.002 40.3944 102.36 40.8375 102.36H70.237C70.6802 102.36 71.0394 102.002 71.0394 101.559C71.0394 101.117 70.6802 100.759 70.237 100.759Z"
      fill="#455A64"
    />
    <path
      d="M69.2159 105.924H41.5011C41.2892 105.924 41.086 105.839 40.9362 105.69C40.7863 105.54 40.7022 105.337 40.7022 105.125C40.7017 105.019 40.722 104.915 40.762 104.818C40.8019 104.721 40.8607 104.632 40.9349 104.557C41.0092 104.483 41.0974 104.424 41.1945 104.383C41.2917 104.343 41.3959 104.322 41.5011 104.322H69.2159C69.3211 104.322 69.4253 104.343 69.5224 104.383C69.6196 104.424 69.7078 104.483 69.7821 104.557C69.8563 104.632 69.9151 104.721 69.955 104.818C69.995 104.915 70.0153 105.019 70.0148 105.125C70.0148 105.337 69.9307 105.54 69.7808 105.69C69.631 105.839 69.4278 105.924 69.2159 105.924Z"
      fill="#455A64"
    />
    <path
      d="M32.6128 86.5488C32.6128 86.5488 35.923 75.4892 37.5034 74.0859H73.7526C73.7526 74.0859 76.8788 80.9322 78.0042 86.5488H32.6128Z"
      fill="#455A64"
    />
    <path
      d="M41.8623 110.353V111.315C41.8623 111.877 42.0855 112.416 42.4829 112.813C42.8802 113.21 43.4192 113.434 43.9811 113.434H67.1179C67.6799 113.434 68.2188 113.21 68.6162 112.813C69.0135 112.416 69.2368 111.877 69.2368 111.315V110.353H41.8623Z"
      fill="#263238"
    />
    <path
      d="M73.8777 107.678V111.891C73.8777 111.891 82.346 113.086 83.586 107.678H73.8777Z"
      fill="#263238"
    />
    <path
      d="M38.3611 107.678V111.891C38.3611 111.891 29.8928 113.086 28.6562 107.678H38.3611Z"
      fill="#263238"
    />
    <path
      d="M80.8279 88.8998C80.8163 88.8784 80.8058 88.8563 80.7967 88.8338L80.7099 88.6288C80.6369 88.4413 80.5292 88.1738 80.3938 87.8334C80.1159 87.1387 79.7199 86.0967 79.2163 84.815C78.2124 82.2133 76.7848 78.528 75.1141 74.1688L75.1905 74.2209H63.4606L43.9917 74.1688L38.0868 74.1305L36.4821 74.1097H38.0868L43.9917 74.0889H63.4606L75.1905 74.1132H75.2461L75.2669 74.1653L79.3066 84.8358C79.7929 86.1314 80.1749 87.1596 80.4424 87.8716C80.5674 88.219 80.6647 88.483 80.7376 88.6775L80.8071 88.8859C80.8245 88.8755 80.8314 88.8998 80.8279 88.8998Z"
      fill="#263238"
    />
    <path
      d="M78.0042 86.549C78.0042 86.5976 67.8443 86.6462 55.3085 86.6532C42.7727 86.6601 32.6128 86.6289 32.6128 86.5698C32.6128 86.5108 42.7727 86.4726 55.3085 86.4656C67.8443 86.4587 78.0042 86.5003 78.0042 86.549Z"
      fill="#263238"
    />
    <path
      d="M77.6913 86.4825C77.7226 86.4442 79.1884 87.4932 80.9633 88.8271C82.7383 90.1609 84.1555 91.2759 84.1242 91.3175C84.0929 91.3592 82.6306 90.3068 80.8557 88.9729C79.0807 87.6391 77.6635 86.5241 77.6913 86.4825Z"
      fill="#263238"
    />
    <path
      d="M32.6127 86.5488C32.6439 86.587 31.4143 87.6847 29.8652 89.0011C28.316 90.3175 27.0343 91.3561 27.003 91.3179C26.9718 91.2797 28.2014 90.1821 29.7471 88.8656C30.6622 88.0451 31.6187 87.2718 32.6127 86.5488Z"
      fill="#263238"
    />
    <path
      d="M33.0331 81.3146C33.0783 81.3146 32.5329 82.9506 31.8105 84.9305C31.088 86.9104 30.4662 88.5012 30.4211 88.4839C30.3759 88.4665 30.9212 86.8478 31.6403 84.868C32.3593 82.8881 32.9845 81.2972 33.0331 81.3146Z"
      fill="#263238"
    />
    <path
      d="M73.0057 93.3535C72.9769 93.3571 72.9477 93.3571 72.9189 93.3535L72.6653 93.3709L71.6858 93.4265C70.8313 93.4716 69.6017 93.5237 68.0768 93.5654C65.0271 93.6592 60.8138 93.7078 56.1628 93.6939C51.5118 93.68 47.295 93.621 44.2488 93.5862L40.6364 93.5341L39.6569 93.5133H40.6364H44.2488L56.1628 93.5689C60.8138 93.5689 65.0237 93.5689 68.0734 93.4959L71.6823 93.4126L72.6618 93.3917H73.0092L73.0057 93.3535Z"
      fill="#263238"
    />
    <path
      d="M50.1953 74.0859H59.921V74.6382C59.921 75.0491 59.7578 75.4431 59.4673 75.7336C59.1768 76.0242 58.7827 76.1874 58.3719 76.1874H51.7445C51.3336 76.1874 50.9396 76.0242 50.6491 75.7336C50.3585 75.4431 50.1953 75.0491 50.1953 74.6382V74.0859Z"
      fill="#263238"
    />
    <path
      d="M73.9052 86.3546H73.5578C73.5244 85.1225 73.0115 83.9522 72.1283 83.0925C71.245 82.2329 70.0612 81.7519 68.8287 81.7519C67.5962 81.7519 66.4123 82.2329 65.5291 83.0925C64.6458 83.9522 64.1329 85.1225 64.0995 86.3546H63.7522C63.7868 85.0312 64.3369 83.7737 65.2851 82.8499C66.2334 81.9261 67.5049 81.4092 68.8287 81.4092C70.1525 81.4092 71.424 81.9261 72.3723 82.8499C73.3205 83.7737 73.8706 85.0312 73.9052 86.3546Z"
      fill="#263238"
    />
    <path
      d="M62.2175 86.1596C62.2175 86.1596 62.9122 84.8536 64.9616 84.8015C66.8546 84.7494 71.6584 84.8015 71.6584 84.8015C71.6584 84.8015 74.003 84.9057 74.6804 85.8713V86.4826H62.2175V86.1596Z"
      fill="#263238"
    />
    <path
      d="M38.0413 86.6767C37.8225 86.906 38.1421 83.3144 38.6353 81.7374C38.7084 81.5471 38.8398 81.3848 39.0107 81.2737C39.1817 81.1627 39.3834 81.1085 39.587 81.1192H48.361C48.5784 81.1051 48.7939 81.1666 48.9711 81.2932C49.1484 81.4198 49.2764 81.6037 49.3336 81.8139C49.5351 82.8976 50.0734 84.9817 50.195 86.6767H38.0413Z"
      fill="#263238"
    />
    <path
      d="M46.8124 77.1914H41.3556C40.7264 77.1914 40.2163 77.7015 40.2163 78.3307V79.5047C40.2163 80.134 40.7264 80.644 41.3556 80.644H46.8124C47.4417 80.644 47.9517 80.134 47.9517 79.5047V78.3307C47.9517 77.7015 47.4417 77.1914 46.8124 77.1914Z"
      fill="#263238"
    />
    <path
      d="M59.209 86.5482C58.9936 86.7775 59.3826 83.0053 59.8724 81.4283C59.9469 81.2392 60.0788 81.0783 60.2495 80.968C60.4202 80.8577 60.6211 80.8037 60.8241 80.8135H69.5981C69.8155 80.7994 70.031 80.8609 70.2082 80.9875C70.3855 81.1141 70.5135 81.298 70.5707 81.5082C70.7722 82.5954 71.3106 84.8462 71.4321 86.5482H59.209Z"
      fill="#263238"
    />
    <path
      d="M68.049 77.1914H62.5922C61.963 77.1914 61.4529 77.7015 61.4529 78.3307V79.5047C61.4529 80.134 61.963 80.644 62.5922 80.644H68.049C68.6782 80.644 69.1883 80.134 69.1883 79.5047V78.3307C69.1883 77.7015 68.6782 77.1914 68.049 77.1914Z"
      fill="#263238"
    />
  </svg>
);
