import { z } from "zod";

export const VehicleOptPaymentValidationSchema = z.object({
  vehicleBasePrice: z.string().min(1, {
    message: "Vehicle Base Price is required",
  }),
  vehicleTaxesFees: z.string().min(1, {
    message: "Vehicle Taxes & Fees is required",
  }),
  total: z.string().optional(),
});

export const PaymentFinancingValidationSchema = z.object({
  DownPayment: z.string().min(1, {
    message: "Down Payment is required",
  }),
// vehicleTaxesFees: z.string().min(1, {
//   message: "Vehicle Taxes & Fees is required",
// }),
  // total: z.string().optional(),
});
