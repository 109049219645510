import { GradientModal, Typography } from "components";
import { useEffect, useState } from "react";
import "../index.scss";
import { Button } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import {
  DmvFeeAndViewPdfDocs,
  EnterMissingInformationForm,
} from "../../dmv-titling";
import ApproveSale from "./approve-sale";
import { useSelectAtcProcedure } from "services/tiling.service";
import { getUserId, isThisDealer } from "utils/get-user-id";
import { TilingSelectAtcProcedureRequest } from "services";
import { useNavigate, useParams } from "react-router-dom";
import { selectedProcedureSignal } from "../../dmv-titling/store";
import DmvTransitStatus from "modules/dealer/components/dmv-title/DmvTransitStatus";
import { NewOrderForm } from "pages/shipping-quotes/new-order/new-order.form";
import FinancingOfferPaymentsForm from "components/form/vehicle-transacttion-payments/FinancingOfferPaymentsForm";
import AppyOnlineVT from "components/form/vehicle-transacttion-payments/AppyOnlineVT";
import PurchaseCompletedComponent from "./PurchaseCompletedComponent";
import { PaymentModal } from "pages/shared";

const steps = [
  {
    step: "Payment_1",
    title: "Payment Options",
    isSuccessPayment: false,
  },
  {
    step: "ShipPay",
    title: "Payment for Shipping",
    isSuccessPayment: false,
  },
  {
    step: "AppyOnlineVT",
    title: "Apply Online",
    isSuccessPayment: false,
  },
  {
    step: "EnterMissingInformationForm",
    title: "Enter Missing Information",
    isSuccessPayment: false,
  },
  {
    step: "DmvFeeAndViewPdfDocs",
    title: "Documents Required",
    isSuccessPayment: false,
  },
  {
    step: "DmvTransitStatus",
    title: "Shipping Tracking",
    isSuccessPayment: false,
  },
  {
    step: "ApproveSale",
    title: "Approve Sale",
    isSuccessPayment: false,
  },
];

interface Props {}

export default function StepsForFinancingOffer({}: Props) {
  const { currectSale: saleOffer, paymentInfo } = useSelector(
    (state: RootState) => state.acceptOffer
  );

  const [activeSteps, setActiveSteps] = useState<(typeof steps)[number]>(
    paymentInfo?.shipmentRequire
      ? {
          step: "ShipPay",
          title: "Payment for Shipping",
          isSuccessPayment: false,
        }
      : {
          step: "Payment_1",
          title: "Payment",
          isSuccessPayment: false,
        }
  );

  const [showPayModal, setShowPayModal] = useState(false);
  const [acceptData, setAcceptData] = useState<{ ship: any } | null>(null);

  const params = useParams();
  const navigate = useNavigate();
  // const selectedCar = useSelector((state: RootState) => state.shipping);

 
  // DMV relate hooks
  const {
    mutateAsync: selectAtcProcedureMutateAsync,
    data: atcProcedureData,
    isPending: isPendingToAtcProcedure,
  } = useSelectAtcProcedure();

  const handleOnConfirm = () => {
    const userId = getUserId();
    const payload: TilingSelectAtcProcedureRequest = {
      listingId: Number(params?.listingId ?? ""),
      quoteId: 0,
      tier1: false,
      tier2: false,
      tier3: true,
      userId,
    };
    selectAtcProcedureMutateAsync(payload);
  };


  useEffect(() => {
    if (!saleOffer) {
      navigate(
        isThisDealer()
          ? "/dealer/manage-inventory/pending-sales"
          : "/user/garage/pending-sales"
      );
    }
  }, []);

  useEffect(() => {
    if (atcProcedureData && !isPendingToAtcProcedure) {
      selectedProcedureSignal.value = atcProcedureData;
      window.scrollTo(0, 0);
      setActiveSteps({
        step: "DmvFeeAndViewPdfDocs",
        title: "Documents Required",
        isSuccessPayment: false,
      });
    }
  }, [atcProcedureData, isPendingToAtcProcedure]);

  return (
    <>
      <div className="md:flex ">
        {/* <div className="mb-4 md:mt-36">
          <PendingSteps activeStepCount={activeSteps.step} stepsArray={steps} />
        </div> */}
        <div className="md:ml-5 pb-4  w-full flex flex-col items-center justify-center relative ">
          <div className="w-full flex justify-center flex-col items-center">
            <Typography
              variant="custom"
              className={`text-center  my-4 md:mb-16 md:ml-8 font-bold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
            >
              {activeSteps.title}
            </Typography>
            <div className={`${"md:pl-8"} w-full`}>
              {(() => {
                switch (activeSteps.step) {
                  case "ShipPay":
                    return (
                      <div className="w-full  max-w-[42rem] m-auto">
                        <NewOrderForm
                          handleSubmit={(res) => {
                            setAcceptData({ ship: res });
                            // window.scrollTo(0, 0);
                            // setShowPayModal(true);
                            setActiveSteps({
                              step: "Payment_1",
                              title: "Payment",
                              isSuccessPayment: false,
                            });
                          }}
                        />
                      </div>
                    );
                  case "Payment_1":
                    const base = Number(
                      saleOffer?.previousCashOffer.enterYourOfferHere
                    );
                    const shipPay = paymentInfo?.shipmentRequire
                      ? Number(acceptData?.ship?.TotalPrice) || 0
                      : 0;

                    return (
                      <div className="flex items-end justify-center w-full max-w-[52rem] m-auto ">
                        <FinancingOfferPaymentsForm
                          onCallback={() => {
                            window.scrollTo(0, 0);
                            // setShowPayModal(true);
                            setActiveSteps({
                              step: "AppyOnlineVT",
                              title: "Apply Online",
                              isSuccessPayment: false,
                            });
                          }}
                          btnLabel={"Continue"}
                          priceData={{
                            base: String(base),
                            ship: String(shipPay),
                          }}
                        />
                      </div>
                    );
                  case "AppyOnlineVT":
                    return (
                      <div className="flex items-end justify-center w-full max-w-[68rem] m-auto ">
                        <AppyOnlineVT
                          onCallback={() => {
                            window.scrollTo(0, 0);
                            setShowPayModal(true);
                            //  setActiveSteps(steps[activeSteps.step]);
                          }}
                          onUploadDocuments={() => {}}
                          SalesData={saleOffer}
                        />
                      </div>
                    );

                  case "EnterMissingInformationForm":
                    return (
                      <div className="relative  w-full">
                        <EnterMissingInformationForm
                          isHideTitle={true}
                          onCallback={() => {
                            handleOnConfirm();
                          }}
                          navigateOnSubmit={false}
                          fullService={true}
                        />
                      </div>
                    );
                  case "DmvFeeAndViewPdfDocs":
                    return (
                      <div className="w-full flex items-center flex-col">
                        <Typography
                          variant="custom"
                          className="text-medium text-red-500 font-[500] text-center"
                        >
                          Please note: If some documents are not available for
                          this VIN, we could provide them to you. Please create
                          a support ticket for assistance.
                        </Typography>
                        <div className="text-center">
                          <DmvFeeAndViewPdfDocs showInternalSubmit={false} />
                        </div>
                        <Button
                          className="col-span-3 bg-gradient-to-r max-w-[24rem] m-auto from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-xl mt-5"
                          type="submit"
                          onClick={() => {
                            if (paymentInfo?.shipmentRequire) {
                              setActiveSteps({
                                step: "DmvTransitStatus",
                                title: "Shipping Tracking",
                                isSuccessPayment: false,
                              });
                            } else {
                              // showmodel
                              setActiveSteps({
                                step: "PurchaseCompleted",
                                title: "",
                                isSuccessPayment: false,
                              });
                            }
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    );
                  case "DmvTransitStatus":
                    return (
                      <div className="w-full   max-w-[42rem] m-auto">
                        {/* <ShippingTracking /> */}
                        <DmvTransitStatus isForDmv={true} />
                        <div
                          className="text-center cursor-pointer mt-5"
                          onClick={() => {
                            setActiveSteps({
                              step: "ApproveSale",
                              title: "Approve Sale",
                              isSuccessPayment: false,
                            });
                          }}
                        >
                          SKIP
                        </div>
                      </div>
                    );
                  case "ApproveSale":
                    return (
                      <ApproveSale
                        onConfirm={() => {
                          setActiveSteps({
                            step: "PurchaseCompleted",
                            title: "",
                            isSuccessPayment: false,
                          });
                        }}
                      />
                    );
                }
              })()}
            </div>
          </div>
        </div>
      </div>


      <PaymentModal
        isOpen={showPayModal}
        onOpen={() => setShowPayModal(false)}
        hideHeader
        Component={
          <div className="w-full pb-20 flex flex-col items-center">
            <Typography
              variant="custom"
              className=" text-xl font-[Metropolis] font-semibold text-center"
            >
              Pending Dealer Approval
            </Typography>
            <div className="w-full max-w-52">
              <Button
                onClick={() => {
                  setActiveSteps({
                    step: "EnterMissingInformationForm",
                    title: "Enter Missing Information",
                    isSuccessPayment: false,
                  });
                  setShowPayModal(false);
                }}
                className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
              >
                Continue
              </Button>
            </div>
          </div>
        }
      />
      <GradientModal
        isOpen={activeSteps.step === "PurchaseCompleted"}
        onOpenChange={() => {
          if (paymentInfo?.shipmentRequire) {
            setActiveSteps({
              step: "ApproveSale",
              title: "Approve Sale",
              isSuccessPayment: false,
            });
          } else {
            setActiveSteps({
              step: "DmvFeeAndViewPdfDocs",
              title: "Documents Required",
              isSuccessPayment: false,
            });
          }
        }}
        size="2xl"
      >
        <PurchaseCompletedComponent />
      </GradientModal>
    </>
  );
}
