import { useState, useEffect } from "react";

export default function useIsSmallScreen() {
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const smallScreenThreshold = 768; // Adjust this threshold as needed
      const isSmall = window.innerWidth <= smallScreenThreshold;
      setIsSmall(isSmall);
    };

    // Call handleResize initially
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array to run this effect only once after the component mounts

  return isSmall;
}
