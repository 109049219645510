import React, { useState } from "react";
import { Button, Image } from "@nextui-org/react";
// import { signal } from "@preact/signals-react";

// import { UploadPhotosBulk } from "./upload-photos-bulk.component";
// import { TextErrorHelper, Toggle } from "components";
// import { UploadPhotosSingle } from "./upload-photos-single.component";
// import { useUploadCoverFiles } from "services";
import { UploadDocumentsBulk } from "./upload-document-bulk.component";
import { ViewLoadedDocumentListing } from "modules/dealer/features/Listings/components/ViewLoadedDocumentListing";
import { UploadTypesForDoc } from "modules/dealer/features/Listings/enum/UploadTypes";
import { TextErrorHelper, Typography } from "components";
import { useUploadFiles } from "services";
import { signal } from "@preact/signals-react";

interface UploadPhotosModalProps {
  onClose?: () => void;
  listingId?: number | undefined;
  isItCreate:boolean,
  initDoc:string[]
  // aiOnState:boolean;
  onSubmit?: ()=>void
  // setAiOnState:React.Dispatch<React.SetStateAction<boolean>>
  // images:File[],
  // coverImg: File | null
}

export const filesSignalLintingDocuments = signal<File[]>([]);
// export const aiImgDetationON = signal<boolean>(false);
// export const signalhasDoneBulkUpload = signal(false);

export const UploadDocumentsModal: React.FC<UploadPhotosModalProps> = ({
  onClose,
  listingId,
  isItCreate,
  initDoc,
  onSubmit
  // aiOnState,
  // setAiOnState,
  // images,
  // coverImg
}) => {
  const [uploadType, setUploadType] = useState<UploadTypesForDoc>(UploadTypesForDoc.upload);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  // const [mainImg, setMainImg] = useState<File|null>();
  const [isEdited, setIsEdited] = useState(false);
  const [canCreateList, setCanCreateList] = useState<boolean>(false);
  
  // const [aiCarDetails, setCarAiDetails] = useState<
  //   { file: File; part: string }[]
  // >([]);


  const { mutateAsync:uploadFileAnsc,error:errorForUploadFiles  } = useUploadFiles(); 
  
  const handleCreateList = async () => {
   
    if (listingId && files.length >= 0) {
      setIsLoading(true);
        // try{

          const formData = new FormData();
          formData.append("listingId", String(listingId));
          files.forEach((flx, index) => {
            formData.append(`uploadfiles`, flx);
          });
          await  uploadFileAnsc(formData)
          onClose && onClose();
          setCanCreateList(false);
          setIsLoading(false);
          filesSignalLintingDocuments.value = files
        // }catch(e){
        //   console.log(e)
        // }
      // signalhasDoneBulkUpload.value = true;
      // filesSignalLintingImg.value = moveFileToFirst(aiOnState?aiCarDetails:files.map((file)=>({ file:file,part:"" })),mainImg);
      // aiImgDetationON.value = aiOnState
      // });
      onSubmit && onSubmit()
    }
  };
  const getActiveClassName = (type: UploadTypesForDoc.upload | UploadTypesForDoc.view) => {
    return uploadType === type
      ? "bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg py-6 md:py-8 tracking-wide px-6 rounded-xl  w-full md:w-auto"
      : "  w-full md:w-auto text-blue-500 text-sm md:text-lg py-6  md:py-8 tracking-wide px-6 rounded-xl";
  };
  return (
    <div className="w-full overflow-y-auto">
      <div className="w-full flex items-center justify-between md:justify-center mb-8 gap-4">
        <Button
          onClick={() => setUploadType(UploadTypesForDoc.upload)}
          className={getActiveClassName(UploadTypesForDoc.upload)}
          type="button"
        >
          Upload Documents
        </Button>

        <Button
          onClick={() => setUploadType(UploadTypesForDoc.view)}
          className={getActiveClassName(UploadTypesForDoc.view)}
          type="button"
        >
          View Documents
        </Button>
      </div>
      {uploadType === UploadTypesForDoc.upload ? (
        <UploadDocumentsBulk
          files={files}
          // aiCarDetails={aiCarDetails}
          // setCarAiDetails={setCarAiDetails}
          setFiles={setFiles}
          // aiOnState={aiOnState}
          canCreateList={canCreateList}
          setCanCreateList={(canCreate: boolean) => setCanCreateList(canCreate)}
          listingId={listingId}
          // mainImg={mainImg}
          // setMainImg={setMainImg}
          setIsEdited={setIsEdited}
        />
      ) : (
        <div>
          {initDoc.length === 0 && <div className="flex p-8"><Typography variant="subtitle" className="m-auto">No Documents!</Typography></div> }
        {initDoc.map((file=>{
          return(<ViewLoadedDocumentListing  file={{name:file}} showBtns={true}/>)
        }))}
        </div>
      )}
      
      <div className="mt-2 md:mt-10 pb-10 md:pb-5 flex flex-col gap-2 items-center justify-center">
        {/* {(errorForUploadFiles?.message) && (
          <div className="flex flex-row gap-2 mr-2">
            <Image src="/assets/icons/error-outline.svg" alt="error" />
            <TextErrorHelper>{errorForUploadFiles.message}</TextErrorHelper>
          </div>
        )} */}
      {uploadType === UploadTypesForDoc.upload &&  <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg py-6 md:py-8 tracking-wide px-10 rounded-xl"
          isLoading={isLoading}
          isDisabled={isItCreate?(isLoading|| files.length < 1) :( isLoading|| files.length < 1 || !isEdited)}
          // isDisabled={isItCreate ?((aiOnState?aiCarDetails.length <4 :files.length < 4) || isLoading || !canCreateList) : ((mainImg?.name === coverImg?.name && !isEdited ) || isLoading  || (aiOnState?aiCarDetails.length <4 :files.length < 4))}
          onClick={handleCreateList}
        >
          {isItCreate? "Upload Documents": "Edit Documents" }
        </Button>}
      </div>
    </div>
  );
};


