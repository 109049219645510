import { Typography } from "components";
import React, { useState } from "react";
import check from "./assets/svg/icon_check.svg";
import checkBlue from "./assets/svg/icon_check_blue.png";
import "./index.scss";
import Button from "modules/shared/components/button/Button";
import tierBG from "./assets/svg/pricing-bg.png";
import tierBGHover from "./assets/svg/pricing-bg-hover.png";
import { localStorageFn } from "utils/localstorage";
import { getUserRoles } from "utils/get-user-id";
import { useNavigate } from "react-router-dom";

interface Props {
  tier: Tier;
  handleSelect?: () => void;
  isSelected?: boolean;
  onCallBack?: () => void;
}

interface Tier {
  id: string;
  name: string;
  type: string;
  note: string;
  price: number;
  features: string[];
}

export default function TierCard(props: Props) {
  const { name, type, note, price, features } = props.tier;
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const handleSelect = () => {
    const userRole = getUserRoles();
    if (userRole === "Dealer") {
      navigate("/user-membership");
      props?.handleSelect && props?.handleSelect();
    } else {
      navigate("/register");
      props?.onCallBack && props?.onCallBack();
    }
  };

  return (
    <div
      className={`px-3 mb-4 mb:mt-0 rounded-xl relative card  ${
        isHovered || props.isSelected ? "hover-scale" : "scale-95"
      } `}
      style={{
        backgroundImage: `url(${
          isHovered || props.isSelected ? tierBGHover : tierBG
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        transition: "background-image 0.9s ease-in-out",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="card-content">
        <div className="mt-5 text-white">
          <Typography
            variant="custom"
            className="text-[15px] xl:text-[18px] font-semibold mb-2"
          >
            {name}
          </Typography>
          <Typography
            variant="custom"
            className={`font-semibold
          ${isHovered || props.isSelected ? "text-[24px] text-nowrap" : "text-[20px]"}`}
          >
            {type}
          </Typography>
          <div className="flex justify-center items-center">
            <span className="-mt-2">$</span>
            <Typography
              variant="custom"
              className={`${
                isHovered || props.isSelected ? "text-[45px]" : "text-[35px]"
              } font-semibold
              transition duration-300`}
            >
              {price}
            </Typography>
          </div>
          <div className="flex justify-center items-center -mt-2">
            <Typography
              variant="custom"
              className={`  font-semibold  max-w-[200px] ${
                isHovered || props.isSelected ? "text-[14px]" : "text-[12px]"
              } `}
            >
              {note}
            </Typography>
          </div>
        </div>
        <div className="feature-list-pricing ">
          {features.map((res, index) => (
            <div key={index} className="feature">
              <img
                src={isHovered || props.isSelected ? checkBlue : check}
                alt="check"
                className="w-4 h-4 cursor-pointer -mt-1"
              />
              <Typography
                variant="custom"
                className="text-[18px] font-semibold px-2"
              >
                {res}
              </Typography>
            </div>
          ))}
        </div>
        {!props?.isSelected && (
          <Button
            name="Select"
            data-modal-hide="default-modal"
            className={`absolute bottom-10  left-1/2  -translate-x-1/2 rounded-xl w-[200px] h-[40px] text-white font-bold text-lg color 
          ${
            !(isHovered || props.isSelected)
              ? " bg-gradient-to-br from-blue-500 to-indigo-800 "
              : "bg-[#018FFD]"
          }`}
            onClick={handleSelect}
          />
        )}
      </div>
    </div>
  );
}
