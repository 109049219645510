import * as React from "react";
import { Card, Button, CardBody, CardFooter, Image } from "@nextui-org/react";
import { z } from "zod";
import { CameraOutlined } from "@ant-design/icons";
import "./profile.scss";
import {
  UserUpdateRequest,
  useGetCurrentUserForBoth,
  useGetUserProfilePicture,
  useGetZipCodeDetails,
  usePostUserProfilePicture,
  useUpdateUser,
} from "services";
import { getUserId } from "utils/get-user-id";
import Loading from "components/Loading/Loading";
import { formatDateToShortDate } from "utils";
import { env } from "config/env";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddressInput, Typography } from "components";
import { UserUpdateValidationSchema } from "../dashboard/validation";
import { useNavigate } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";
import { LoginDetails } from "pages/dealer/dealer-membership/login-details.component";
import { CreditCard } from "pages/dealer/dealer-membership/credit-card.component";
import { useState } from "react";
import {
  useAddPaymenTransactiontMethod,
  useGetCreditCardMethodStatusData,
} from "hooks/api/mutations/shipping/use-shipping-quotes";
import { PaymentModal } from "pages/shared";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";
import Input from "components/ui/input";
export const UserProfilePage = () => {
  // const navigate = useNavigate();
  const userId = getUserId();
  const {
    data: userData,
    isLoading,
    refetch: getUserDataAgain,
  } = useGetCurrentUserForBoth(userId, false);

  const [isEditClicked, setIsEditClicked] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [file, setFile] = React.useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [zipValue, setZipValue] = React.useState(userData?.zipCode || "");
  const [debouncedValue] = useDebounceValue(zipValue, 300);
  const { mutateAsync: zipcodeMutateAsync, data: zipcodeData } =
    useGetZipCodeDetails(debouncedValue);
  const [showPayModal, setShowPayModal] = useState(false);
  const { data: checkIfPaymentExist, refetch: refreshCardInfo } =
    useGetCreditCardMethodStatusData(true);
  const {
    mutateAsync: mutateAddPaymenTransactiontMethod,
    isPending: isPendingToAddPaymenTransactiontMethod,
    error: addCardErrorMessage,
  } = useAddPaymenTransactiontMethod();

  const handleAddCard = async (e: any) => {
    try {
      await mutateAddPaymenTransactiontMethod(e);
      refreshCardInfo();
      setShowPayModal(false);
    } catch (e) {
      console.log("Error :", e);
    }
  };

  // useEffect(() => {
  //   mutateAsyncCheckPayment();
  // }, []);

  React.useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitted },
    setError,
  } = useForm({
    resolver: zodResolver(UserUpdateValidationSchema),
  });

  const { mutateAsync, data, isPending } = useUpdateUser();
  // React.useEffect(() => {
  if (data && isEditClicked) {
    // setIsEditClicked(false);
    // refetch();
  }
  // }, [data, isEditClicked,]);

  const {
    mutateAsync: updateProfilePicMutateAsync,
    isPending: isPendingProfileUpload,
  } = usePostUserProfilePicture(userId);
  React.useEffect(() => {
    const uploadProfilePic = async () => {
      if (file.length > 0) {
        const formData = new FormData();
        formData.append("file", file[0]);
        const res = await updateProfilePicMutateAsync(formData);
        if (res) window.location.reload();
      }
    };
    uploadProfilePic();
  }, [file, updateProfilePicMutateAsync]);

  const { data: profilePictureData, isLoading: isLoadingProfilePicture } =
    useGetUserProfilePicture(userId);

  const handleOnSubmit = async (formData: any) => {
    if (!isEditClicked || (!zipcodeData && !userData?.zipCode)) return;

    try {
      const dob = new Date(formData.dob);
      const dobString = dob.toISOString();

      const payload: UserUpdateRequest = {
        address: formData.address || "",
        firstName: formData.firstName || "",
        lastName: formData.lastName || "",
        email: formData.email || "",
        phoneNumber: formData.phoneNumber || "",
        dateOfBirth: dobString,
        id: userId,
        city: zipcodeData?.city || userData?.city || "",
        dealershipType: "",
        contactPerson: "",
        description: description || "",
        inventorySize: "",
        licenseNumber: "",
        username: formData.username,
        // middleName: "",
        state: zipcodeData?.state || userData?.state || "",
        zipCode: zipValue.length < 1 ? userData?.zipCode || "" : zipValue,
      };
      const res = await mutateAsync(payload);
      if ("message" in res) {
        setError("username", {
          type: "duplicate",
          message: "Username Already Exist",
        });
      } else {
        getUserDataAgain();
        setIsEditClicked(false);
      }
    } catch (e) {
      console.log(e);
    }
    // if (res) window.location.reload();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFile = Array.from(event.target.files);
      setFile((prevFile) => [...prevFile, ...newFile]);
    }
  };

  const onHandleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formatNumberToString = (input: string) => {
    let cleaned = ("" + input).replace(/\D/g, "");

    let formatted = "";

    if (cleaned.length > 0) {
      formatted = "(" + cleaned.substring(0, 3);
    }
    if (cleaned.length >= 3) {
      formatted += ")";
    }
    if (cleaned.length > 3) {
      formatted += " " + cleaned.substring(3, 6);
    }
    if (cleaned.length >= 6) {
      formatted += "-" + cleaned.substring(6, 10);
    }

    setValue("phoneNumber", formatted);
  };

  if (isLoading || isLoadingProfilePicture) return <Loading />;
  return (
    <div className="mt-5">
      <div className="lg:flex gap-20 lg:gap-10 xl:gap-20">
        <div className="flex justify-center">
          <Card radius="none" shadow="none" className="w-[320px] h-[320px]">
            <CardBody className="overflow-hidden p-0">
              <Image
                removeWrapper
                alt="profile-image"
                radius="lg"
                className="z-0  h-[320px] w-[320px]  bg-black/90 object-cover"
                loading="eager"
                isLoading={isPendingProfileUpload}
                src={
                  file.length > 0
                    ? URL.createObjectURL(file[0])
                    : profilePictureData
                    ? `${env.BASE_API_URL}User/image/${userId}`
                    : "/assets/images/avatar/user.jpg"
                }
              />
              {!isLoading && !isLoadingProfilePicture && (
                <CardFooter className="absolute bottom-2 -left-2 justify-end">
                  <input
                    onChange={handleFileChange}
                    type="file"
                    style={{ display: "none" }}
                    className=""
                    ref={fileInputRef}
                  />
                  <div className="upload-button" onClick={onHandleFileClick}>
                    <CameraOutlined />
                  </div>
                </CardFooter>
              )}
            </CardBody>
          </Card>
        </div>
        {!isEditClicked ? (
          <div className="w-full">
            <div className="pt-4 lg:pt-0 grid grid-cols-1 md:grid-cols-2 place-items-start gap-x-20 lg:gap-x-10 xl:gap-x-20">
              <div className="flex justify-center w-[320px] pb-5 justify-self-center md:justify-start md:justify-self-start">
                <div className="font-normal text-2xl 2xl:text-4xl">
                  <span>{userData?.firstName} </span>
                  <span className="ml-3">{userData?.lastName}</span>
                </div>
                <div className="block md:hidden">
                  <Button
                    className="bg-transparent "
                    size="sm"
                    disableAnimation
                    onClick={() => setIsEditClicked(!isEditClicked)}
                    type="button"
                  >
                    <Image
                      src="/assets/icons/user-edit-pencil.svg"
                      height="30"
                      width="30"
                      className="cursor-pointer ml-2"
                    />
                  </Button>
                </div>
              </div>

              <div className="hidden md:block ">
                <Button
                  className="bg-transparent md:mr-5 -ml-5"
                  size="sm"
                  disableAnimation
                  onClick={() => setIsEditClicked(!isEditClicked)}
                  type="button"
                >
                  <Image
                    src="/assets/icons/user-edit-pencil.svg"
                    height="30"
                    width="30"
                    className="cursor-pointer"
                  />
                </Button>
              </div>

              <div className="flex py-5 items-center text-lg lg:text-md  2xl:text-xl w-full">
                <Image
                  src="/assets/icons/user-username.svg"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  radius="none"
                />
                <span className="truncate w-full">{userData?.username}</span>
              </div>

              <div className="flex py-5 items-center text-lg lg:text-md  2xl:text-xl">
                <Image
                  src="/assets/icons/user-dob.svg"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  radius="none"
                />
                <span>
                  {userData?.dateOfBirth
                    ? formatDateToShortDate(
                        new Date(userData?.dateOfBirth),
                        "/"
                      )
                    : ""}
                </span>
              </div>

              <div className="flex py-5 items-center text-lg lg:text-md  2xl:text-xl">
                <Image
                  src="/assets/icons/user-password.svg"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  radius="none"
                />
                <span>*********</span>
              </div>

              <div className="flex py-5 items-center text-lg lg:text-md  2xl:text-xl">
                <Image
                  src="/assets/icons/user-phone-number.svg"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  radius="none"
                />
                <span>{userData?.phoneNumber}</span>
              </div>
              <div className="flex py-5 items-center text-lg  lg:text-md 2xl:text-xl w-full">
                <Image
                  src="/assets/icons/user-email.svg"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  radius="none"
                />
                <span className="truncate">{userData?.email}</span>
              </div>
              <div className="flex py-5 items-center text-lg  lg:text-md 2xl:text-xl">
                <Image
                  src="/assets/icons/address-icon.png"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  alt="zipcode"
                  radius="none"
                />
                <span>{userData?.zipCode}</span>
              </div>
              {/* </div> */}

              <div className="md:col-span-2 flex pt-5 items-center text-lg   lg:text-md 2xl:text-xl">
                <Image
                  src="/assets/icons/address-icon.png"
                  className="w-[20px] h-[20px] mr-3"
                  removeWrapper
                  radius="none"
                />
                <span>{userData?.address}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="pt-4 lg:pt-0 md:grid grid-cols-1 md:grid-cols-2 lg:gap-x-10 xl:gap-x-20  gap-x-20">
                <div className="flex  gap-2 font-normal  text-lg lg:text-md  2xl:text-xl ">
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <div className="w-full flex flex-col  items-center pb-3">
                      <div className=" input-gradient-border w-full  px-2 py-1">
                        <input
                          {...register("firstName")}
                          className=" focus:outline-none focus:border-none "
                          placeholder="First Name"
                          defaultValue={userData?.firstName}
                        />
                      </div>
                      {errors.firstName?.message && (
                        <p className="text-xs ml-3 mt-2 w-full text-red-500">
                          {errors.firstName.message as string}
                        </p>
                      )}
                    </div>
                    <div className="w-full flex flex-col items-center pb-3">
                      <div className="input-gradient-border w-full  px-2 py-1">
                        <input
                          {...register("lastName")}
                          className="  focus:outline-none focus:border-none "
                          placeholder="Last Name"
                          defaultValue={userData?.lastName}
                        />
                      </div>

                      {errors.lastName?.message && (
                        <p className="text-xs ml-3 mt-2 w-full text-red-500">
                          {errors.lastName.message as string}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="block md:hidden ">
                    <Button
                      className="mr-2 bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg  h-[40px] sm:ml-5 ml-0 lg:ml-0  md:w-[100px]"
                      size="sm"
                      disableAnimation
                      type="submit"
                      isLoading={isPending}
                    >
                      Save
                    </Button>
                  </div>
                </div>

                <div className="hidden md:block ">
                  <Button
                    className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg  h-[40px]  2xl:w-[100px]"
                    size="sm"
                    disableAnimation
                    type="submit"
                    isLoading={isPending}
                  >
                    Save
                  </Button>
                </div>
                <div className="w-full">
                  <div className="flex items-center mt-6 md:mt-3 p-2 input-gradient-border text-lg  lg:text-md 2xl:text-xl">
                    <Image
                      src="/assets/icons/user-username.svg"
                      className="w-[20px] h-[20px] mr-3"
                      removeWrapper
                      radius="none"
                    />
                    <input
                      {...register("username")}
                      className=" w-full focus:outline-none focus:border-none text-sm md:text-lg"
                      placeholder="Username"
                      defaultValue={userData?.username}
                    />
                  </div>
                  {errors.username?.message && (
                    <p className=" text-sm mt-2  w-full text-red-500">
                      {errors.username.message as string}
                    </p>
                  )}
                </div>

                <div className="w-full">
                  <div className="flex items-center mt-3 p-2 input-gradient-border text-lg  lg:text-md 2xl:text-xl">
                    <Image
                      src="/assets/icons/user-dob.svg"
                      className="w-[20px] h-[20px] mr-3"
                      removeWrapper
                      radius="none"
                    />

                    <input
                      {...register("dob")}
                      className=" focus:outline-none focus:border-none text-sm md:text-lg rounded-r-full"
                      placeholder="DOB"
                      defaultValue={
                        userData?.dateOfBirth
                          ? formatDateToShortDate(
                              new Date(userData?.dateOfBirth),
                              "/"
                            )
                          : ""
                      }
                    />
                  </div>
                  {errors.dob?.message && (
                    <p className="text-sm  w-full text-red-500">
                      {errors.dob.message as string}
                    </p>
                  )}
                </div>

                <div className="flex py-5 items-center text-lg lg:text-md  2xl:text-xl">
                  <Image
                    src="/assets/icons/user-password.svg"
                    className="w-[20px] h-[20px] mr-3"
                    removeWrapper
                    radius="none"
                  />
                  <span>*********</span>
                </div>

                <div className="w-full">
                  <div className="flex items-center mt-3 p-2 input-gradient-border text-lg  lg:text-md 2xl:text-xl">
                    <Image
                      src="/assets/icons/user-phone-number.svg"
                      className="w-[20px] h-[20px] mr-3"
                      removeWrapper
                      radius="none"
                    />
                    <input
                      {...register("phoneNumber")}
                      className="focus:outline-none focus:border-none text-sm md:text-lg"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        formatNumberToString(e.target.value);
                        return { ...register("phoneNumber").onChange(e) };
                      }}
                      defaultValue={userData?.phoneNumber}
                    />
                  </div>
                  {errors.phoneNumber?.message && (
                    <p className=" text-sm mt-2  w-full text-red-500">
                      {errors.phoneNumber.message as string}
                    </p>
                  )}
                </div>

                <div className="w-full">
                  <div className="flex items-center mt-6 md:mt-3 p-2 input-gradient-border text-lg  lg:text-md 2xl:text-xl">
                    <Image
                      src="/assets/icons/user-email.svg"
                      className="w-[20px] h-[20px] mr-3"
                      removeWrapper
                      radius="none"
                    />
                    <input
                      {...register("email")}
                      className=" w-full focus:outline-none focus:border-none text-sm md:text-lg"
                      placeholder="Email"
                      defaultValue={userData?.email}
                    />
                  </div>
                  {errors.email?.message && (
                    <p className=" text-sm mt-2  w-full text-red-500">
                      {errors.email.message as string}
                    </p>
                  )}
                </div>

                <div className="w-full">
                  <div className="flex items-center mt-6 md:mt-3 p-2 input-gradient-border text-lg  lg:text-md 2xl:text-xl">
                    <Image
                      src="/assets/icons/address-icon.png"
                      className="w-[20px] h-[20px] mr-3"
                      removeWrapper
                      radius="none"
                    />
                    <input
                      // {...register("address")}
                      className="focus:outline-none focus:border-none text-sm md:text-lg"
                      placeholder="Zip Code"
                      defaultValue={userData?.zipCode}
                      maxLength={5}
                      onChange={(e) => {
                        const formattedValue = e.target.value?.replace(
                          /\D/g,
                          ""
                        );
                        setZipValue(formattedValue);
                      }}
                    />
                  </div>
                  {!zipcodeData && isSubmitted && !userData?.zipCode && (
                    <p className=" text-sm mt-2   w-full text-red-500">
                      ZIP Code is Invalid
                    </p>
                  )}
                </div>

                <div className="w-full col-span-2">
                  <div className="flex items-center mt-6 md:mt-3 w-full p-2 input-gradient-border text-lg  lg:text-md 2xl:text-xl">
                    <Image
                      src="/assets/icons/address-icon.png"
                      className="w-[20px] h-[20px] mr-3"
                      removeWrapper
                      radius="none"
                    />

                    <AddressInput
                      zipData={zipcodeData}
                      handleSelect={(value) => setValue("address", value)}
                      placeholder=""
                      InputComponent={(props) => {
                        return (
                          <input
                            {...register("address")}
                            className="w-full focus:outline-none focus:border-none text-sm md:text-lg "
                            placeholder="Address"
                            defaultValue={userData?.address}
                            onChange={(e) => {
                              props.onChange(e);
                              return register("address").onChange(e);
                            }}
                          />
                        );
                      }}
                    />

                    {errors.address?.message && (
                      <p className=" text-sm mt-2 w-full text-red-500">
                        {errors.address.message as string}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="grid grid-cols-8 gap-10 my-10  items-center">
        <div className="flex items-center justify-center col-span-8 border-2 border-blue-400 rounded-3xl p-5 w-full h-[360px] md:h-auto">
          {isEditClicked ? (
            <Input
              defaultValue={userData?.description}
              onChange={(e) => setDescription(e.target.value)}
              variant={"underlined"}
              placeholder="Put some information about you and or express yourself a little bit"
              className="text-md md:text-[19px] font-[600] p-8 text-center "
            />
          ) : (
            <Typography
              variant="custom"
              className="text-md md:text-[19px] font-[600] p-8 text-center "
            >
              {userData?.description
                ? userData.description
                : " Put some information about you and or express yourself a little bit"}
            </Typography>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-10">
        <LoginDetails />
        <CreditCard
          handleAddCard={() => {
            setShowPayModal(true);
          }}
          cardData={checkIfPaymentExist}
          username={userData?.firstName + " " + userData?.lastName}
        />
      </div>
      <PaymentModal
        isOpen={showPayModal}
        onOpen={() => {
          setShowPayModal(false);
        }}
        Component={
          <PaymentFormWithDetails
            handleNext={handleAddCard}
            isPending={isPendingToAddPaymenTransactiontMethod}
            showAmmount={false}
            errorMessage={addCardErrorMessage?.message}
          />
        }
      />
    </div>
  );
};
