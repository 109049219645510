import { SVGProps } from "react";

export const ContactIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      d="M12.7523 20.4016H20.4023M25.5023 7.65156V22.9516C25.5023 23.6279 25.2337 24.2765 24.7555 24.7547C24.2772 25.2329 23.6286 25.5016 22.9523 25.5016H10.2023C9.52604 25.5016 8.87744 25.2329 8.39922 24.7547C7.921 24.2765 7.65234 23.6279 7.65234 22.9516V7.65156C7.65234 6.97526 7.921 6.32666 8.39922 5.84844C8.87744 5.37022 9.52604 5.10156 10.2023 5.10156H22.9523C23.6286 5.10156 24.2772 5.37022 24.7555 5.84844C25.2337 6.32666 25.5023 6.97526 25.5023 7.65156Z"
      stroke="#313131"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.10156 10.2031H8.92656M5.10156 15.3031H8.92656M5.10156 20.4031H8.92656M14.0266 14.0281C14.0266 14.7044 14.2952 15.353 14.7734 15.8312C15.2517 16.3095 15.9003 16.5781 16.5766 16.5781C17.2529 16.5781 17.9015 16.3095 18.3797 15.8312C18.8579 15.353 19.1266 14.7044 19.1266 14.0281C19.1266 13.3518 18.8579 12.7032 18.3797 12.225C17.9015 11.7468 17.2529 11.4781 16.5766 11.4781C15.9003 11.4781 15.2517 11.7468 14.7734 12.225C14.2952 12.7032 14.0266 13.3518 14.0266 14.0281Z"
      stroke="#313131"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
