import { Button } from "@nextui-org/react";
import { CarIcon } from "assets/svgs/icons/car-icon";
import { ContactIcon } from "assets/svgs/icons/contact-icon";
import { EmailIcon } from "assets/svgs/icons/email-icon";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { EyeIcon } from "assets/svgs/icons/eye-icon";
import { EyeSlashedIcon } from "assets/svgs/icons/eye-slash-icon";
import { LockIcon } from "assets/svgs/icons/lock-icon";
import { PenAndPaperIcon } from "assets/svgs/icons/pen-and-paper-icon";
import { PhoneIcon } from "assets/svgs/icons/phone-icon";
import { StateDealerIcon } from "assets/svgs/icons/state-dealer-icon";
import { UserIcon } from "assets/svgs/icons/user-icon";
import { LogoColoured } from "assets/svgs/logo-coloured";
import Form from "components/ui/form";
import Input from "components/ui/input";
import { env } from "config/env";
import { useRegisterDealerMutation } from "hooks/api/mutations/use-auth-mutation";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { DealerRegisterSchema } from "schemas/auth.schema";
import { z } from "zod";
import ChoosePlanSelection from "./components/ChoosePlanSelection";
import { PaymentModal } from "pages/shared";
import PaymentForm from "./components/PaymentForm";
import { useProcessUserTierRegistration } from "hooks/api/mutations/user-membeship/use-user-membership";
import { useAddPaymenTransactiontMethodRegistration } from "hooks/api/mutations/shipping/use-shipping-quotes";
import PaymentSuccess from "modules/dealer/features/shipping-quotes/PaymentSuccess";
import Loading from "components/Loading/Loading";
import { AddressInput, PhoneNumberInput } from "components";
import { LocationZipIcon } from "assets/svgs/icons/location-zip-icon";
import { useDebounceValue } from "usehooks-ts";
import { useGetZipCodeDetails } from "services";
import PaymentFormWithDetails from "modules/shared/features/payments/PaymentWithDetailsForm";

export default function DealerRegister() {
  const { mutateAsync, isPending: tierLoading, error:processPaymentTierError } =
    useProcessUserTierRegistration();
  const {
    mutateAsync: mutateAddPaymenTransactiontMethodRegistration,
    isPending: paymentPending,
    error: paymentAddError
  } = useAddPaymenTransactiontMethodRegistration();

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [zipValue, setZipValue] = useState("");

  const [selectedTier, setSelectedTier] = useState<any>();
  const [captchaState, setCaptchaState] = useState<{
    state: "error" | "success" | "default";
  }>(() => {
    return {
      state: "default",
    };
  });
  const {
    mutate: registerMutation,
    isPending: registerPending,
    isError,
    error,
  } = useRegisterDealerMutation();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const navigateToLogin = () => {
    navigate("/login");
  };

  function onCaptchaChange() {
    setCaptchaState({
      state: "success",
    });
  }

  const [debouncedValue] = useDebounceValue(zipValue, 300);
  const { mutateAsync: zipcodeMutateAsync, data: zipcodeData } =
    useGetZipCodeDetails(debouncedValue);
  useEffect(() => {
    if (zipValue.length > 0) {
      const fetchZipCode = async () => {
        await zipcodeMutateAsync();
      };
      fetchZipCode();
    }
  }, [debouncedValue]);

  const handleSubmit = (data: z.infer<typeof DealerRegisterSchema>) => {
    
    if (captchaState.state !== "success") {
      setCaptchaState({ state: "error" });
      return;
    }
    if (!zipcodeData) return;
    registerMutation(data, {
      onSuccess: (response: any) => {
        // setActiveStep(2);
        navigate("/login");
    
      },
      onError: (error) => {},
    });
  };
  const processPaymentFunc = (e: any) => {
    console.log("payment processing ....");
    mutateAddPaymenTransactiontMethodRegistration(e, {
      onSuccess: (response: any) => {
        setPaymentPopup(false);
        handleChooseTierFunc(selectedTier.id);
        console.log("success");
      },
      onError: (error) => {
        console.log({ error });
      },
    });
  };

  const handleChooseTierFunc = async (tier: string) => {
    try {
      await mutateAsync(selectedTier.id, {
        onSuccess: (response) => {
          localStorage.clear();
          setSuccessPopup(true);
        },
        onError: (error) => {
          console.log({ error });
        },
      });
    } catch (error) {
      console.error("Error processing user tier:", error);
    }
  };

  const FormFieldsData = [
    { id: "dealershipName", label: "Dealership Name", icon: <CarIcon /> },
    { id: "firstName", label: "First/Last Name", icon: <UserIcon /> },
    { id: "phoneNumber", label: "Phone Number", icon: <PhoneIcon /> },
    {
      id: "stateLicenseNumber",
      label: "State Dealer License Number",
      icon: <StateDealerIcon />,
    },
    { id: "email", label: "Email", icon: <EmailIcon /> },
    { id: "username", label: "Desired Username", icon: <PenAndPaperIcon /> },
    {
      id: "zipCode",
      label: "Zip Code",
      icon: <LocationZipIcon className="size-5" />,
    },
    {
      id: "password",
      label: "Password",
      icon: <LockIcon className="size-5" />,
    },
    { id: "physicalAddress", label: "Physical Address", icon: <ContactIcon /> },
  ] as const;

  return (
    <div>
      {(tierLoading || paymentPending) && <Loading />}
      {activeStep === 1 && (
        <div>
          <div>
            <Link
              className="flex items-center justify-center"
              to="/listing/landing-page"
              aria-label="Brand"
            >
              <LogoColoured className="size-14 md:size-28" />
            </Link>
          </div>
          <div className="flex items-center justify-center py-5">
            <div className=" px-8 py-2 rounded-[12px] md:border-[1px] border-[#DBDBDB] bg-[#f1f1f1]/50 md:shadow-lg md:min-w-[700px] md:max-w-[700px] ">
              <h2 className="my-2 font-semibold text-center text-md">
                <div className="flex md:size-0 place-content-center">
                  {/* <LogoColoured2 /> */}
                </div>
                Create an Account
              </h2>
              <Form schema={DealerRegisterSchema} onSubmit={handleSubmit}>
                {({ register, formState: { errors ,isSubmitted}, control, setValue }) => (
                  <>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      {FormFieldsData.map(({ id, label, icon }, index) => {
                        switch (id) {
                          case "password":
                            return (
                              <div
                                className="mb-[5px] relative w-full"
                                key={index}
                              >
                                <div className="flex items-center h-full">
                                  <Input
                                    variant="form"
                                    size="compact"
                                    radius="base"
                                    placeholder="Desired Password"
                                    type={isVisible ? "text" : "password"}
                                    isInvalid={!!errors.password}
                                    classNames={{
                                      inputWrapper: !!errors.password
                                        ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                        : "",
                                    }}
                                    startContent={
                                      <span className="absolute left-3">
                                        {icon}
                                      </span>
                                    }
                                    endContent={
                                      <button
                                        className="absolute right-3 focus:outline-none"
                                        type="button"
                                        onClick={toggleVisibility}
                                      >
                                        {isVisible ? (
                                          <EyeIcon className="text-3xl pointer-events-none text-default-600" />
                                        ) : (
                                          <EyeSlashedIcon className="text-3xl pointer-events-none text-default-600" />
                                        )}
                                      </button>
                                    }
                                    {...register("password")}
                                  />
                                </div>
                                {errors.password && (
                                  <div className="flex ml-[15px] mt-1">
                                    <ErrorInfoIcon className="-mt-[4px] w-[12px] h-[12px] mr-[2px]" />
                                    <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold -mt-[4px]">
                                      {errors.password.message}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );
                          case "phoneNumber":
                            return (
                              <div
                                className="mb-[5px] relative w-full"
                                key={index}
                              >
                                <div className="flex items-center h-full">
                                  <PhoneNumberInput
                                    key={index}
                                    control={control}
                                    iconClass="absolute left-3"
                                    id={"phoneNumber"}
                                    icon={icon}
                                    placeholder="Phone Number"
                                    error={!!errors.phoneNumber}
                                  />
                                </div>
                                {errors.phoneNumber && (
                                  <div className="flex ml-[15px] mt-1">
                                    <ErrorInfoIcon className="-mt-[4px] w-[12px] h-[12px] mr-[2px]" />
                                    <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold -mt-[4px]">
                                      {errors.phoneNumber.message}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );
                          case "zipCode":
                            return (
                              <div
                                className="mb-[5px] relative w-full"
                                key={index}
                              >
                                <div className="flex items-center h-full">
                                  <Controller
                                    control={control}
                                    name="zipCode"
                                    render={({ field }) => (
                                      <>
                                        <Input
                                          variant="form"
                                          size="compact"
                                          radius="base"
                                          placeholder="Zip Code"
                                          isInvalid={
                                            !!errors.zipCode || (!zipcodeData && isSubmitted)
                                          }
                                          classNames={{
                                            inputWrapper:
                                              !!errors.zipCode || (!zipcodeData && isSubmitted)
                                                ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                                : "",
                                          }}
                                          maxLength={5}
                                          startContent={
                                            <span className="absolute left-3">
                                              {icon}
                                            </span>
                                          }
                                          {...field}
                                          onChange={(e) => {
                                            const formattedValue =
                                              e.target.value?.replace(
                                                /\D/g,
                                                ""
                                              );
                                            setZipValue(formattedValue);
                                            return field.onChange(e);
                                          }}
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                                {(errors.zipCode || (!zipcodeData && isSubmitted)) && (
                                  <div className="flex ml-[15px] mt-1">
                                    <ErrorInfoIcon className="-mt-[4px] w-[12px] h-[12px] mr-[2px]" />
                                    <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold -mt-[4px]">
                                      {zipcodeData
                                        ? errors?.zipCode?.message
                                        : "Zip Code is invalid"}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );

                          case "physicalAddress":
                            return (
                              <div
                                className="mb-[5px] relative w-full md:col-span-2"
                                key={index}
                              >
                                <div className="flex items-center h-full">
                                  <AddressInput
                                    zipData={zipcodeData}
                                    handleSelect={(value) =>
                                      setValue("physicalAddress", value)
                                    }
                                    placeholder=""
                                    InputComponent={(props) => {
                                      return (
                                        <Controller
                                          control={control}
                                          name="physicalAddress"
                                          render={({ field }) => (
                                            <>
                                              <Input
                                                variant="form"
                                                size="compact"
                                                radius="base"
                                                placeholder="Physical Address"
                                                isInvalid={
                                                  !!errors.physicalAddress
                                                }
                                                classNames={{
                                                  inputWrapper:
                                                    !!errors.physicalAddress
                                                      ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                                      : "",
                                                }}
                                                startContent={
                                                  <span className="absolute left-3">
                                                    {icon}
                                                  </span>
                                                }
                                                {...field}
                                                onChange={(e) => {
                                                  props.onChange(e);
                                                  return field.onChange(e);
                                                }}
                                              />
                                            </>
                                          )}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                                {errors.physicalAddress && (
                                  <div className="flex ml-[15px] mt-1">
                                    <ErrorInfoIcon className="-mt-[4px] w-[12px] h-[12px] mr-[2px]" />
                                    <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold -mt-[4px]">
                                      {errors?.physicalAddress?.message}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );
                          default:
                            return (
                              <div key={index}>
                                <div className="mb-[5px] relative w-full">
                                  <div className="flex items-center h-full">
                                    <Input
                                      variant="form"
                                      size="compact"
                                      radius="base"
                                      placeholder={label}
                                      isInvalid={!!errors[id]}
                                      classNames={{
                                        inputWrapper: !!errors[id]
                                          ? "[background-image:linear-gradient(white,white),linear-gradient(to_right,red,red)]"
                                          : "",
                                      }}
                                      startContent={
                                        <span className="absolute left-3">
                                          {icon}
                                        </span>
                                      }
                                      {...register(id)}
                                    />
                                  </div>
                                  {errors[id] && (
                                    <div className="flex ml-[15px] mt-1">
                                      <ErrorInfoIcon className="-mt-[4px] w-[12px] h-[12px] mr-[2px]" />
                                      <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold -mt-[4px]">
                                        {errors[id]?.message}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                        }
                      })}
                    </div>
                    <div className="flex flex-col gap-1 place-content-center md:mt-[5px]">
                      <ReCAPTCHA
                        sitekey={env.RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaChange}
                        className="flex place-content-center"
                      />

                      {captchaState.state === "error" && (
                        <div className="flex ml-[10px] md:ml-[180px] mt-1">
                          <ErrorInfoIcon className="-mt-[4px] w-[12px] h-[12px] mr-[2px]" />
                          <p className="text-[#FF0A0A] font-[Metropolis] text-[10px] font-bold -mt-[3px]  text-left">
                            {"Please Select Captcha!"}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-center">
                      <Button
                        className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-1/2  tracking-wide px-6 rounded-xl"
                        type="submit"
                        isLoading={registerPending}
                        onClick={() => {
                          if (captchaState.state === "default")
                            setCaptchaState({ state: "error" });
                        }}
                      >
                        {"Sign Up"}
                      </Button>
                    </div>
                  </>
                )}
              </Form>

              {isError && (
                <div className="flex flex-col items-center mt-[22px] text-red-500 text-sm lg:text-md py-2">
                  {error.message}
                </div>
              )}

              <p className="mt-2 text-center text-gray-600">
                Already have an account?{" "}
                <span
                  className="font-bold text-[#018FFD] hover:text-[#3a80b6] cursor-pointer"
                  onClick={navigateToLogin}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      {activeStep === 2 && (
        <div className="px-28">
          <ChoosePlanSelection
            onCallBack={(tier) => {
              setSelectedTier(tier);
              setPaymentPopup(true);
            }}
          />
          <PaymentModal
            isOpen={paymentPopup}
            onOpen={() => {
              handleChooseTierFunc(selectedTier.id);
            }}
            Component={
              <>
                <PaymentFormWithDetails
                  payAmmount={selectedTier?.price ?? 0}
                  handleNext={(e) => {
                    processPaymentFunc(e);
                  }}
                  isPending={paymentPending || tierLoading}
                  errorMessage={paymentAddError?.message || processPaymentTierError?.message}
                />
              </>
            }
          />
          <PaymentModal
            isOpen={successPopup}
            onOpen={() => {}}
            Component={
              <>
                <PaymentSuccess
                  onClick={() => navigateToLogin()}
                  navigatePath="/login"
                  buttonText="Login"
                  disable={tierLoading}
                />
              </>
            }
          />
        </div>
      )}
    </div>
  );
}
