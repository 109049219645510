import { useMutation } from "@tanstack/react-query";
import { env } from "config/env";
import { TurboChatSchema } from "schemas/aichat.schema";

import { api, authApi } from "utils/api";
import { z } from "zod";

// User Login Mutation
type TurboChatSchemaType = z.infer<typeof TurboChatSchema>;


const TurboChat = async (
  data: TurboChatSchemaType
): Promise<{
  answer: string;
}[]> => {
  
  return api
  .post("chat", {
    prefixUrl:
    env.AI_URL,
    json:data,
  }).json()
};

export function useTurboChatMutation() {

  return useMutation({
    mutationFn:TurboChat,
    onSuccess: (data) => {
      console.log(data)
    },
    retry:2
  });
}