import * as React from "react";
import {
  Button,
  Card,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

import { SelectNextUiCarSearch, Typography } from "components";
import { ListingVehicleRequest, useGetListingsVehicles } from "services";
import { localStorageFn } from "utils/localstorage";
import { getLocation } from "../search-car";
import Loading from "components/Loading/Loading";
import { useEffect, useState } from "react";
import Input from "components/ui/input";
import { classNames } from "@react-pdf-viewer/core";

interface ManualSearchProps {}

export const FilterButtons = [
  {
    label: "Year",
    key: "year",
    type: "range",
    opt: { useGrouping: false },
  },
  {
    label: "Make",
    key: "make",
  },
  {
    label: "Model",
    key: "model",
  },
  {
    label: "Trim",
    key: "trim",
  },
  {
    label: "Body Class",
    key: "BodyClass",
  },

  // {
  //   label: "Mileage Range",
  //   key: "Seats",
  // },
  {
    label: "Mileage",
    key: "mileage",
    type: "range",
  },
  {
    label: "Distance",
    key: "distance",
    type: "range",
    isSingle: true,
  },
  {
    label: "Price",
    key: "price",
    type: "range",
    opt: {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
];

export const ManualSearch: React.FC<ManualSearchProps> = () => {
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = React.useState<ListingVehicleRequest[]>(
    []
  );
  const {
    data: curCarData,
    refetch: refetchVehicleSearch,
    isFetching,
  } = useGetListingsVehicles([], () => {}, 1, 20);

  const handleSearchTemp = () => {
    navigate("/listing/search-car");
    window.scrollTo(0, 0);
    localStorageFn.setItem("srcOpt", filterValue);
    localStorageFn.setItem("filter_val", curCarData);
  };

  React.useEffect(() => {
    refetchVehicleSearch();
  }, []);

  const handleFilterRange = async (key: string, value: number | number[]) => {
    if (Array.isArray(value)) {
      const payload =
        value[0] === getMixValue(key) && value[1] === getMaxValue(key)
          ? filterValue.filter((t) => t.term !== key)
          : [
              {
                term: key,
                value: String(value[0]),
                secondaryValue: String(value[1]),
                isRange: true,
              },
              ...filterValue.filter((t) => t.term !== key),
            ];
      setFilterValue(payload);
    }
  };
  const handleFilterRangeDistance = async (
    key: string,
    value: number | number[]
  ) => {
    if (!Array.isArray(value)) {
      const payload =
        value === 5000
          ? filterValue.filter((t) => t.term !== key)
          : [
              {
                term: key,
                value: String(value),
                secondaryValue: "",
                isRange: false,
              },
              ...filterValue.filter((t) => t.term !== key),
            ];
      setFilterValue(payload);
    }
  };

  const handleMultiSelectValue = async (key: string, value: string[]) => {
    const payload = [
      ...[...value].map((el) => ({
        term: key,
        value: el,
        secondaryValue: "",
        isRange: false,
      })),
      ...filterValue.filter((t) => t.term !== key),
    ];

    setFilterValue(payload);
  };

  const handleDropdownData: (key: string) => { label: any; value: any }[] = (
    key: string
  ) => {
    switch (key) {
      case "make": {
        const makeOpt = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.make) // Filter out (curCarData||[]) without a valid year
          .map((item) => item.listingDetail.basicDetail.make) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .map((make) => ({ label: make, value: make })); // Map to the desired format

        return makeOpt;
      }
      case "BodyClass": {
        const bodyClass = (curCarData || [])
          .filter(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "BodyClass"
              )?.value
          )
          .map(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "BodyClass"
              )?.value
          ) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .map((make) => ({ label: make, value: make })); // Map to the desired format

        return bodyClass;
      }
      case "model": {
        const modelOpt = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.model) // Filter out (curCarData||[]) without a valid year
          .map((item) => item.listingDetail.basicDetail.model) // Extract the years
          .filter((model, index, arr) => arr.indexOf(model) === index) // Remove duplicate
          .map((model) => ({ label: model, value: model })); // Map to the desired format

        return modelOpt;
      }

      case "seats": {
        const numberOfSeat = (curCarData || [])
          .filter(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "Seats"
              )?.value
          ) // Filter out (curCarData||[]) without a valid year
          .map(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "Seats"
              )?.value
          ) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .sort((a, b) => Number(b) - Number(a)) // Sort the years in ascending order
          .map((make) => ({ label: make, value: make })); // Map to the desired format
        return numberOfSeat;
      }

      case "trim": {
        const trimOpt = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.trim) // Filter out (curCarData||[]) without a valid year
          .map((item) => item.listingDetail.basicDetail.trim) // Extract the years
          .filter((trim, index, arr) => arr.indexOf(trim) === index) // Remove duplicate
          .map((trim) => ({ label: trim, value: trim })); // Map to the desired format

        return trimOpt;
      }

      case "transmission": {
        const transValue = (curCarData || [])
          .filter(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "TransmissionStyle"
              )?.value
          ) // Filter out (curCarData||[]) without a valid year
          .map(
            (item) =>
              item.listingDetail.dataSnapshot.find(
                (item) => item.code === "TransmissionStyle"
              )?.value
          ) // Extract the years
          .filter((make, index, arr) => arr.indexOf(make) === index) // Remove duplicates
          .map((make) => ({ label: make, value: make })); // Map to the desired format

        return transValue;
      }

      default: {
        return [{ label: "", value: "" }];
      }
    }
  };

  const getMaxValue = (key: string) => {
    switch (key) {
      case "year": {
        const yearOpt = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.year) // Filter out (curCarData||[]) without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.year)); // Extract the years

        return Math.max(...yearOpt, 0);
      }

      case "distance": {
        return 10000;
      }

      case "mileage": {
        const maxMileage = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.mileage) // Filter out (curCarData||[]) without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.mileage)); // Extract the years

        return Math.max(...maxMileage, 0);
      }

      case "price": {
        const maxPrice = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.price) // Filter out (curCarData||[]) without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.price)); // Extract the years

        return Math.max(...maxPrice, 0);
      }
      default: {
        return 500;
      }
    }
  };

  const getMixValue = (key: string) => {
    switch (key) {
      case "year": {
        const yearOpt = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.year) // Filter out (curCarData||[]) without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.year)); // Extract the years
        return Math.min(...yearOpt, 2012);
      }
      case "distance": {
        return 0;
      }
      case "mileage": {
        const minMileage = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.mileage) // Filter out (curCarData||[]) without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.mileage)); // Extract the years
        return Math.min(...minMileage, 0);
      }
      case "price": {
        const minPrice = (curCarData || [])
          .filter((item) => item.listingDetail.basicDetail.price) // Filter out (curCarData||[]) without a valid year
          .map((item) => Number(item.listingDetail.basicDetail.price)); // Extract the years
        return Math.min(...minPrice, 30000);
      }

      default: {
        return 0;
      }
    }
  };

  const removeFilterValue = (key: string) => {
    const updatedState = filterValue.filter((item) => item.term !== key);
    setFilterValue(updatedState);
  };
  return (
    <div className="relative" id="manual-search">
      <Typography
        className="mt-24  mb-4 text-2xl md:text-4xl bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text"
        variant="title"
      >
        Quick Search
      </Typography>

      <Card
        className="relative  grid grid-cols-1 md:grid-cols-2  gap-4 justify-items-center border-none shadow-none"
        fullWidth
        isPressable={false}
      >
        {isFetching && <Loading />}

        {FilterButtons.map((button, index) => (
          <div
            className="relative col-span-1 w-full  "
            id={`${button.key}-${index}`}
          >
            {button.type === "range" ? (
              <div className="relative">
                <Popover
                  key={index}
                  className=" relative z-20"
                  placement="bottom"
                  showArrow={true}
                  portalContainer={
                    document.getElementById(`manual-search`) || document.body
                  }
                >
                  <PopoverTrigger>
                    <div
                      key={index}
                      className={` w-full font-[Metropolis-semibold] font-[500] text-[14px] text-[#71717A] h-[3.5rem]  cursor-pointer ${
                        filterValue.map((i) => i.term).includes(button.key)
                          ? "border-2"
                          : "border"
                      } rounded-2xl border-transparent bg-origin-border [background-clip:padding-box,border-box] [background-image:linear-gradient(white,white),linear-gradient(to_right,#018ffd,#070c84)] transition-colors   py-4 text-lg  flex items-center justify-center text-center`}
                      onClick={() => getLocation(button.key)}
                      id={`${button.key}-${index}`}
                    >
                      {/* <Typography
                        variant="custom"
                        className="font-[Metropolis] font-[500] text-[14px] text-center"
                      > */}
                      {button.label}
                      {/* </Typography> */}
                      {filterValue.map((i) => i.term).includes(button.key) && (
                        <div
                          className="absolute right-3.5 text-base hover:text-blue-500 text-black"
                          onClick={() => {
                            removeFilterValue(button.key);
                          }}
                        >
                          X
                        </div>
                      )}
                    </div>
                  </PopoverTrigger>

                  <PopoverContent
                    className=""
                    style={{
                      position: "absolute",
                      zIndex: 9999, // Adjust as needed
                    }}
                  >
                    <div className="mx-auto overflow-y-auto w-56 md:w-96 px-2 py-6 overflow-hidden">
                      {button.isSingle ? (
                        <>
                          <Slider
                            label={button.label}
                            step={1}
                            value={Number(
                              filterValue.find((i) => i.term === button.key)
                                ?.value || 5000
                            )}
                            minValue={0}
                            maxValue={5000}
                            formatOptions={{}}
                            onChange={async (e) => {
                              await handleFilterRangeDistance(button.key, e);
                            }}
                            className="max-w-md"
                          />
                          <div className="my-2">
                            <Input
                              variant="form"
                              placeholder="zip code"
                              size="compact"
                            ></Input>
                          </div>
                        </>
                      ) : (
                        <Slider
                          label={button.label}
                          step={1}
                          value={[
                            Number(
                              filterValue.find((i) => i.term === button.key)
                                ?.value || getMixValue(button.key)
                            ),
                            Number(
                              filterValue.find((i) => i.term === button.key)
                                ?.secondaryValue
                            ) || getMaxValue(button.key),
                          ]}
                          minValue={getMixValue(button.key)}
                          maxValue={getMaxValue(button.key)}
                          formatOptions={button?.opt || {} as any}
                          onChange={async (e) => {
                            await handleFilterRange(button.key, e);
                          }}
                          className="max-w-md"
                        />
                      )}
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            ) : (
              <SelectNextUiCarSearch
                key={`${button.key}-${index}`}
                placeholder="Select Vehicle Type"
                label={`select-${button}${index}`}
                className="h-[3.5crem] "
                textSize="text-lg"
                aria-label={`select-${button}${index}`}
                portalId={"manual-search"}
                data={handleDropdownData(button.key)}
                handleClearSelect={() => removeFilterValue(button.key)}
                selectedKeys={filterValue
                  .filter((i) => i.term === button.key)
                  .map((i) => i.value)}
                register={{
                  onSelectionChange: (e: string[]) => {
                    handleMultiSelectValue(button.key, e);
                  },
                  size: "lg",
                  placeholder: button.label,
                  key: `${button.key}-${index}`,
                  // classNames:{
                  //   input:"text-lg"
                  // }
                }}
              />
            )}
          </div>
        ))}
      </Card>
      <Button
        className="bg-gradient-to-r float-right h-[3.5rem] rounded-2xl  max-w-52 from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 mt-4"
        onClick={handleSearchTemp}
      >
        Search
      </Button>
    </div>
  );
};
