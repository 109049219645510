import React, { useEffect, useState } from "react";
import {
  Navbar as NavbarNext,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Image,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { Typography } from "./typography";
import { localStorageFn } from "utils/localstorage";
import { safeDecode } from "utils/jwt";
import { HamburgerLineIcon } from "assets/svgs/icons/hamburger-line-icon";
import { CaretArrowRight } from "assets/svgs/caret-arrow-right.svg";
import { useDebounceValue } from "usehooks-ts";

const MENU_ITEMS = [
  {
    id: 0,
    name: "Buy",
    style: "-ml-12",
    children: [
      {
        subName: "New/Used Search",
        path: "/listing/search-car",
      },

      // {
      //   subName: "Nationwide Shipping",
      //   path: "/listing/private-party-services/nationwide-shipping",
      // },
      {
        subName: "Nationwide Shipping Quotes",
        path: "/listing/tools/vehicle-shipping-quote",
      },
      {
        subName: "DMV/Titling Services",
        path: "/listing/private-party-services/titling-services",
      },
      {
        subName: "Free Basic Vin Search",
        path: "/listing/tools/free-basic-vin-search",
      },
      {
        subName: "Factory Installed Options",
        path: "/listing/factory-installed-options",
      },
      {
        subName: "Vehicle History Reports",
        path: "/listing/vehicle-history-reports",
      },
      // {
      //   subName: "Vehicle Shipping Quotes",
      //   path: "/listing/tools/vehicle-shipping-quote",
      // },
      // {
      //   subName: "Loan or Lien on Title",
      //   path: "private-party-services/loan-and-lien",
      // },
      // { subName: "Create an Account" },
    ],
  },
  {
    id: 1,
    name: "Sell",
    style: "-ml-12",
    children: [
      {
        subName: "List Your Car For Sale",
        path: "/listing/finance/list-your-car-for-sale",
      },
      {
        subName: "Sell Your Car Instant Quote",
        path: "/listing/private-party-services/sell-instant-quote",
      },
      {
        subName: "Loan or Lien on Title",
        path: "/listing/private-party-services/loan-and-lien",
      },
      {
        subId: 1,
        subName: "Dealer Services",
        // path: "dealer-services/what-we-do",
        modal: true,
        subLink: [
          {
            subName: "What We Do",
            path: "/listing/dealer-services/what-we-do",
          },
          // {
          //   subName: "Pricing",
          //   path: "/listing/dealer-services/dealer-services-pricing",
          // },

          {
            subName: "How to Become Dealer",
            path: "/listing/dealer-services/become-a-dealer",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Finance",
    style: "-ml-0",
    children: [
      {
        subName: "Bank/Lender Financing",
        path: "/listing/finance/buy-here-pay-here-financing",
      },
      {
        subName: "In House Financing",
        path: "/listing/finance/traditional-financing",
      },
      {
        subName: "Get Pre Approved",
        path: "/listing/finance/pre-approval",
      },

      {
        subName: "Auto Loan and Lease Calculator",
        path: "/listing/tools/auto-loan-lease-calculator",
      },
      // {
      //   subName: "Used Car Warranty",
      //   path: "membership-advantage/used-car-warranty",
      // },
      // {
      //   subName: "Repair/Service Shop Partnerships",
      //   path: "membership-advantage/shop-partnerships",
      // },
      // {
      //   subName: "FMO Membership",
      //   path: "membership-advantage/fmo-membership",
      // },
      // {
      //   subName: "Giving Back",
      //   path: "membership-advantage/giving-back",
      // },
    ],
  },
  // all used
  // {
  //   id: 3,
  //   name: "Tools",
  //   children: [
  //     {
  //       subName: "Free Basic Vin Search",
  //       path: "tools/free-basic-vin-search",
  //     },
  //     {
  //       subName: "Factory Installed Options",
  //       path: "factory-installed-options",
  //     },
  //     {
  //       subName: "Vehicle History Reports",
  //       path: "vehicle-history-reports",
  //     },
  //     {
  //       subName: "Vehicle Shipping Quotes",
  //       path: "tools/vehicle-shipping-quote",
  //     },
  //     {
  //       subName: "Auto Loan and Lease Calculator",
  //       path: "tools/auto-loan-lease-calculator",
  //     },
  //   ],
  // },
];
export const Navbar = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedSubGroup, setSelectedSubGroup] = useState<any>({});
  const [closePop, setClosePop] = useState(false);
  const [debouncedValue] = useDebounceValue(closePop, 700);
  const userToken: any = String(localStorageFn.getItem("access_token"));
  const userData: any = safeDecode(userToken);

  const toggleSidebarMenu = () => {
    const sidebar = document.querySelector("#application-sidebar");
    const sidebarOverlay = document.querySelector(
      "#application-sidebar-backdrop"
    );
    sidebar?.classList.toggle("translate-x-0");
    sidebarOverlay?.classList.toggle("hidden");
  };
  function MobileHamburgerMenu() {
    return (
      <div className="flex h-11 ">
        <button
          type="button"
          className="text-gray-500 hover:text-gray-600 mt-auto"
          aria-label="Toggle navigation"
          onClick={toggleSidebarMenu}
        >
          <span className="sr-only">Toggle Navigation</span>
          <HamburgerLineIcon
            className="h-7 w-7"
            //  onClick={handleToggle}
          />
        </button>
      </div>
    );
  }

  useEffect(() => {
    
      if (closePop) {
        setSelectedItem({});
        setSelectedSubGroup({});
      
    }
  }, [debouncedValue]);
  return (
    <NavbarNext
      shouldHideOnScroll={false}
      height={120}
      position="static"
      maxWidth="full"
      className="px-0 md:px-8 xl:px-[140px] 2xl:px-[230px] min-h-20 max-h-20"
      // onMenuOpenChange={setIsMenuOpen}
      onMouseOver={() => setClosePop(false)}
      onMouseLeave={() => setClosePop(true)}
      // onMouseLeave={() => { setSelectedItem({});setSelectedSubGroup({}) }}
    >
      <NavbarContent className="xl:hidden" justify="start">
        {/* <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="xl:hidden"
        /> */}
        <MobileHamburgerMenu />
        <NavbarBrand>
          <Image
            src="assets/icons/new-logo.svg"
            className="w-20 h-20 cursor-pointer"
            removeWrapper
            radius="none"
            shadow="none"
            onClick={() => navigate("/listing/landing-page")}
          />
        </NavbarBrand>
      </NavbarContent>
      <div className="hidden xl:flex items-center max-w-28 min-h-24 max-h-24 min-w-28">
        <Image
          src="assets/icons/new-logo.svg"
          className="w-26 h-16 max-h-[6rem] cursor-pointer"
          removeWrapper
          radius="none"
          onClick={() => {
            navigate(`/listing/landing-page`);
          }}
        />
      </div>

      <NavbarContent
        className="hidden xl:flex basis-11/12 gap-4 sm:gap-4 md:gap-6  xl:gap-15"
        justify="center"
      >
        {MENU_ITEMS.map((item, index) => (
          <NavbarItem key={`${item.name}-${index}`} className=" relative">
            <Link
              size="lg"
              onClick={() => {
                setSelectedItem(item);
              }}
              className={`text-xl cursor-pointer ${
                selectedItem.id === item.id ? "gradient-text" : "text-black"
              }`}
            >
              <Typography
                variant="subtitle"
                className={`mr-1 2xl:mr-5 md:text-base lg:text-xl text-black`}
              >
                {" "}
                {item.name}
              </Typography>
            </Link>
            <div className="absolute top-8 -left-[100%]">
              {selectedItem && selectedItem.id === item.id && (
                <div
                  className={`${item.style} bg-white text-black mt-1 p-2 rounded shadow-lg text-center min-w-[250px]`}
                >
                  {item?.children?.map((res, index) => (
                    <div className="relative text-black" key={index}>
                      <Link
                        className="py-1 text-black hover:text-blue-700  whitespace-normal hover:fill-blue-500 cursor-pointer"
                        onClick={() => {
                          if (!res.modal) {
                            navigate(`${res.path}`);
                            window.scrollTo(0, 0);
                            setSelectedItem({});
                          } else {
                            setSelectedSubGroup(res);
                          }
                        }}
                      >
                        {res.subName}{" "}
                        {res.subLink && (
                          <CaretArrowRight className="relative left-10 font-bold  w-3 h-3" />
                        )}
                      </Link>
                      {selectedItem &&
                        res.subLink &&
                        selectedSubGroup.subId === 1 && (
                          <div
                            className={`left-[150px] top-8 absolute bg-white text-black  p-2 rounded shadow-lg text-center min-w-[250px]`}
                          >
                            {res.subLink.map((res, index) => (
                              <div className="text-black" key={index}>
                                <Link
                                  className="py-1 text-black hover:text-blue-700  whitespace-normal cursor-pointer"
                                  onClick={() => {
                                    navigate(`${res.path}`);
                                    window.scrollTo(0, 0);
                                    setSelectedItem({});
                                    setSelectedSubGroup({});
                                  }}
                                >
                                  {res.subName}
                                </Link>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </NavbarItem>
        ))}
      </NavbarContent>

      <NavbarContent className="basis-1/4" justify="end">
        {userData?.role ? (
          <Button
            className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white ptoLowerCase()y-3 tracking-wide  rounded-xl"
            onClick={() =>
              navigate(`/${userData?.role.toLowerCase()}/dashboard`)
            }
          >
            Dashboard
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className="w-[45px] bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 tracking-wide px-6 rounded-xl"
                  variant="bordered"
                >
                  Login
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem
                  key="user-login"
                  onClick={() => navigate("/login/user")}
                >
                  Login as Private Party
                </DropdownItem>
                <DropdownItem key="login" onClick={() => navigate("/login")}>
                  Login as Dealer
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  className="w-[45px] bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white py-3 tracking-wide  rounded-xl"
                  variant="bordered"
                >
                  Register
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem
                  key="user-register"
                  onClick={() => navigate("/register/user")}
                >
                  Register as Private Party
                </DropdownItem>
                <DropdownItem
                  key="register"
                  onClick={() => navigate("/register")}
                >
                  Register as Dealer
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </NavbarContent>

      {/* <NavbarMenu>
        {MENU_ITEMS.map((item, index) => (
          <NavbarMenuItem key={`${item.name}-${index}`}>
            <Link
              onClick={() => {
                setSelectedItem(item);
              }}
              className="w-full"
              color={
                index === 2
                  ? "warning"
                  : index === MENU_ITEMS.length - 1
                  ? "danger"
                  : "foreground"
              }
              size="lg"
            >
              <Typography variant="body">{item.name}</Typography>
            </Link>
            {selectedItem && selectedItem.id === item.id && (
              <div className="-ml-5 absolute  z-50 bg-white text-black mt-1 p-2 rounded shadow-lg text-center">
                {item?.children?.map((res, index) => (
                  <div className="text-black">
                    <Link
                      key={index}
                      className="py-1 text-black"
                      href={`/#/listing/${res.path}`}
                      onClick={() => {
                        setSelectedItem({});
                        setIsMenuOpen(!isMenuOpen);
                      }}
                    >
                      {res.subName}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </NavbarMenuItem>
        ))}
      </NavbarMenu> */}
    </NavbarNext>
  );
};
