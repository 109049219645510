import { Typography } from "components";
import React, { useEffect, useState } from "react";
import backgroundImage from "./assets/sell-quote-bg.svg";
import backIcon from "./assets/back-icon.svg";
import bgWhite from "./assets/white-bg.png";
import "./SellInstantQuote.scss";
import { EnterVIN } from "pages/dealer/enter-vin/enter-vin";
import { ErrorInfoIcon } from "assets/svgs/icons/error-info-icon";
import { EnterMileage } from "modules/dealer/components/enter-vin/type";
import Form from "components/ui/form";
import { ContactSchema } from "./schema/ContactSchema";
import { Button, Image } from "@nextui-org/react";
import ContactForm from "./components/ContactForm";
import useIsSmallScreen from "../utils/isSmallScreen";
import apiClient from "modules/dealer/utils/apiClient";
import { capitalizeForDashOrWhiteSpace, capitalizeAllBeforeDash } from "utils";
import { findVehicleBy } from "utils/find-vehicle-by";
import {
  SFInstantQuoteLead,
  useInitiateListingVIN,
  useSFInstantQuoteLead,
} from "services";
import VehicleConditions from "./components/VehicleConditions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import { getUserId } from "utils/get-user-id";
import { useNavigate } from "react-router-dom";
import { clearVehicleCondition } from "modules/dealer/store/vehicle-condition";

const steps = [
  {
    step: 1,
    label: "Enter VIN",
    isValid: false,
  },
  {
    step: 2,
    label: "Enter Details",
    isValid: false,
  },
  {
    step: 3,
    label: "Best Contact",
  },
];

export default function SellInstantQuotePage() {
  const { mutateAsync, isPending } = useSFInstantQuoteLead();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState({
    step: 1,
    label: "Enter VIN Number",
  });
  const [carName, setCarName] = useState("");
  const [vin, setVin] = useState("");
  const isSmallScreen = useIsSmallScreen();

  const [vinNumber, setVinNumber] = useState<EnterVIN>({
    data: "",
    error: false,
  });
  const [mileage, setMileage] = useState<EnterMileage>({
    mileage: 0,
    error: false,
  });

  const handleInputFocus = (e: any) => {
    e.target.placeholder = "";
  };

  const handleInputBlur = (e: any) => {
    if (!e.target.value) {
      e.target.placeholder = "0";
    }
  };

  const { data: listingId, isLoading: initiateListingLoading } =
    useInitiateListingVIN(
      vin,
      getUserId() > 0 ? getUserId() : 0,
      String(mileage.mileage)
    );

  const fetchData = async (vin: string) => {
    const response = await apiClient.get(`/VinData/DecodeVinData?vin=${vin}`, {
      headers: {
        Accept: "*/*",
      },
    });
    const message = response.data.message;
    const make = findVehicleBy(message ?? [], "Make")?.value ?? "";
    const model = findVehicleBy(message ?? [], "Model")?.value ?? "";
    const year = findVehicleBy(message ?? [], "ModelYear")?.value ?? "";
    const listingId = findVehicleBy(message ?? [], "ListingId")?.value ?? "";
    console.log({ listingId });
    const trim =
      findVehicleBy(message ?? [], "Trim")?.value ??
      findVehicleBy(message ?? [], "Series")?.value ??
      "";
    const nameWithTrim = `${year} ${capitalizeForDashOrWhiteSpace(
      make
    )} ${capitalizeAllBeforeDash(model)} ${trim?.toUpperCase()}`;
    setCarName(nameWithTrim);
  };

  const width = `${(100 / (steps.length - 1)) * (activeStep.step - 1)}%`;

  const onChangeVIN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinNumber((prev) => ({ ...prev, data: e.target.value.trim() }));
    const len = e.target.value.trim().length;
    if (len !== 17) {
      setVinNumber((prev) => ({ ...prev, error: len !== 0 }));
    } else {
      setVinNumber((prev) => ({ ...prev, error: false }));
      fetchData(e.target.value.trim());
      fetchData(e.target.value.trim());
    }
  };
  const vehicleCondition = useSelector(
    (state: RootState) => state.vehicleCondition.vehicleCondition
  );

  const handleSubmit = async (event: any) => {
    const payload: SFInstantQuoteLead = {
      ...vehicleCondition,
      listingId: String(listingId),
      vin: vinNumber.data,
      ...event,
    };
    try {
      await mutateAsync(payload);
      dispatch(clearVehicleCondition());
      navigate("/listing/private-party-services/sell-instant-quote/success");
    } catch (error) {
      console.error("Failed to submit data:", error);
      alert("There was a problem submitting your request. Please try again.");
    }
  };

  return (
    <div>
      <div  className="md:p-2 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text 2xl:text-5xl text-3xl font-bold mb-10">
          Sell Your Car Instant Quote
          </p>
        </Typography>
        <p className="text-center py-3">Sell your vehicle in 3 easy steps!</p>
        <div className={`flex items-center justify-center `}>
          <div
            className={`relative flex flex-col items-center ${
              activeStep.step === 1 || activeStep.step === 3
                ? "w-full lg:w-2/3"
                : "w-full"
            } min-h-[600px] rounded-2xl `}
            style={{
              backgroundImage: `url(${
                !isSmallScreen
                  ? backgroundImage
                  : activeStep.step === 2 || activeStep.step === 3
                  ? bgWhite
                  : backgroundImage
              })`,
              backgroundSize: "cover",
            }}
          >
            {!isSmallScreen
              ? activeStep.step !== 1 && (
                  <div
                    className="absolute left-0 px-14 py-5 cursor-pointer "
                    onClick={() => {
                      const prevStepNumber =
                        activeStep.step > 1 ? activeStep.step - 1 : 1;
                      const prevStep = steps.find(
                        (step) => step.step === prevStepNumber
                      );
                      if (prevStep) {
                        setActiveStep(prevStep);
                      }
                    }}
                  >
                    <Image src={backIcon} className="w-8 h-8 hover:scale-125" />
                  </div>
                )
              : activeStep.step !== 1 && (
                  <div
                    className={`absolute left-0 px-14 py-5 cursor-pointer `}
                    onClick={() => {
                      const prevStepNumber =
                        activeStep.step > 1 ? activeStep.step - 1 : 1;
                      const prevStep = steps.find(
                        (step) => step.step === prevStepNumber
                      );
                      if (prevStep) {
                        setActiveStep(prevStep);
                      }
                    }}
                  >
                    <Image src={backIcon} className="w-8 h-8 hover:scale-125" />
                  </div>
                )}
            <Typography
              variant="custom"
              className={`mt-10  font-semibold text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
            >
              {activeStep.label}
            </Typography>
            <Typography variant="custom" className="mt-3 font-semibold text-xl">
              Step {activeStep.step}
            </Typography>
            <div className="process-bar2 w-[200px] md:w-[300px]">
              <div className="main-container2 ">
                <div className="step-container2">
                  {steps.map(({ step }: any) => (
                    <div>
                      <div className="step-wrapper2" key={step}>
                        <div
                          className={`${
                            activeStep.step >= step
                              ? "step-style-check2"
                              : "step-style2 bg-gray"
                          }`}
                        >
                          {activeStep.step >= step ? (
                            <div className="p-3 text-white">{step}</div>
                          ) : (
                            <div className="step-count2 p-3 text-black">
                              {step}
                            </div>
                          )}
                        </div>
                      </div>
                      {step < steps.length - 1 && (
                        <div className="w-full h-1 bottom-[12px] absolute bg-gray-300" />
                      )}
                      <div
                        className="progress-bar-load2"
                        style={{ width: width }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {activeStep.step > 1 && (
              <Typography variant="pageTitle" className="mt-5 lg:w-[70%]">
                {carName}
              </Typography>
            )}
            <div className={`${activeStep.step === 3 ? "mt-10" : "mt-10"} `}>
              {activeStep.step === 1 && (
                <div className="mt-28">
                  <div className="relative w-full text-center">
                    <input
                      type="text"
                      id="yourInput1"
                      name="yourInput1"
                      onChange={onChangeVIN}
                      value={vinNumber.data ?? ""}
                      className={`w-full text-center border-none focus:outline-none bg-transparent ${
                        vinNumber.error ? "text-red-500" : "text-black"
                      } font-semibold text-2xl md:text-4xl `}
                    />
                    <div
                      className={`absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-full h-[2px]  ${
                        vinNumber.error ? "bg-red-500" : "bg-gray-400"
                      }`}
                    ></div>
                    {vinNumber.error && (
                      <div
                        className={`absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-full
                  flex  items-center justify-center text-black`}
                      >
                        <ErrorInfoIcon className="-mt-[4px] w-[16px] h-[16px] mr-[2px]" />
                        <label className="text-[16px]">
                          Please enter Valid VIN
                        </label>
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className={` ml-auto block p-3 w-[100px] text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] ${
                      vinNumber.error ? "mt-12" : "mt-8"
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      if (!vinNumber.data) {
                        setVinNumber((prev) => ({ ...prev, error: true }));
                        return;
                      }
                      setActiveStep(steps[activeStep.step]);
                    }}
                  >
                    Next
                  </button>
                </div>
              )}
              {activeStep.step === 2 && (
                <div className="p-2 md:p-5 md:mt-0 mt-14 flex flex-col items-center justify-center">
                  <div className="flex justify-between  w-full  items-center mb-5">
                    <div
                      className={`text-center mb-1 text-black font-bold  lg:text-lg md:text-md text-sm`}
                    >
                      Mileage
                    </div>
                    <div>
                      <input
                        type="number"
                        id="yourInput1"
                        name="yourInput1"
                        min={1}
                        placeholder={"0"}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/^0+/, "");
                          if (Number(value) && Number(value) > 0) {
                            setMileage((prev) => ({
                              error: false,
                              mileage: Number(value),
                            }));
                          } else {
                            setMileage((prev) => ({
                              ...prev,
                              mileage: 0,
                            }));
                          }
                        }}
                        className={`w-[100px] md:w-[150px] text-center ${
                          !mileage.error
                            ? "border-gray-400"
                            : "border-red-500 border-3"
                        } border focus:outline-none rounded-xl h-[46px] font-semibold lg:text-lg md:text-md text-sm placeholder-black input-placeholder`}
                      />
                    </div>
                  </div>
                  <VehicleConditions vehicleConditionProps={vehicleCondition} />
                  <div className="flex justify-center mb-16">
                    <button
                      type="submit"
                      className="mt-8 mb-8 block p-3 w-[100px] text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] "
                      onClick={() => {
                        window.scrollTo(0, 0);
                        if (mileage.mileage <= 0) {
                          setMileage((prev) => ({ ...prev, error: true }));
                          return;
                        }
                        setVin(vinNumber.data);
                        setActiveStep(steps[activeStep.step]);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {activeStep.step === 3 && (
                <div>
                  <div className="">
                    <div>
                      <Form schema={ContactSchema} onSubmit={handleSubmit}>
                        {({ register, formState: { errors }, control }) => (
                          <div>
                            <ContactForm
                              register={register}
                              errors={errors}
                              control={control}
                            />
                            <div className="flex justify-center">
                              <Button
                                isLoading={isPending}
                                className="mt-8 mb-8 block p-3 w-[130px] text-white rounded-lg bg-gradient-to-r from-[#018FFD] to-[#070C84] "
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
