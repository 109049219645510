import * as React from "react";
import { Card, Image, useDisclosure } from "@nextui-org/react";

import { ModalViewCar } from "./modal-view-car.component";
import { env } from "config/env";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { motion } from "framer-motion";
import { ListingDetailsSnapshot } from "services";
interface ViewCarDetailsProps {
  imagesData: ListingDetailsSnapshot[];
}
export const ViewCarDetails: React.FC<ViewCarDetailsProps> = ({ imagesData }) => {
  const image = imagesData.map(img=>img.value)
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [viewCar, setViewCar] = React.useState<string>(imagesData.find(img=> img.code === "Primary_Image")?.value || image[0]);

  const handleVieCarFromRightCollection = (car: string) => {
    setViewCar(car);
  };
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const smoothScroll = (heightDelta: any, duration: any) => {
    if (!scrollRef.current) return;

    let start = scrollRef.current.scrollTop;
    let startTime: any = null;

    const step = (timestamp: any) => {
      if (!scrollRef.current) return;
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const part = elapsed / duration;

      const easeInOutQuad = (t: any) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      const ease = easeInOutQuad(part);

      scrollRef.current.scrollTop = start + heightDelta * ease;

      if (elapsed < duration) {
        window.requestAnimationFrame(step);
      } else {
        scrollRef.current.scrollTop = start + heightDelta;
      }
    };

    window.requestAnimationFrame(step);
  };

  const scrollDown = () => {
    smoothScroll(300, 500);
  };

  const scrollUp = () => {
    smoothScroll(-300, 500);
  };

  const variants = {
    visible: { opacity: 1, translateY: 0 },
    hidden: { opacity: 0, translateY: 100 },
  };


  return (
    <>
      <div className="grid grid-rows-3 lg:grid-rows-2 lg:grid-cols-4">
        <div className="row-span-2 lg:col-span-3 ">
          <Card
            className="hover:cursor-pointer h-[236px] md:h-[470px] lg:h-[660px]"
            radius="none"
            shadow="none"
            fullWidth
            isBlurred
          >
            <Image
              removeWrapper
              radius="none"
              alt="Relaxing app background"
              className="z-0 h-full w-full bg-black/90 object-contain"
              src={`${env.BASE_API_URL}Listings/GetListingImage?fileName=${viewCar}`}
              onClick={onOpen}
              
            />
            {/* <CardFooter className="absolute bg-black/40 bottom-0 z-10">
              <Typography variant="subtitle" className="text-white">
                {CARS[0].side}
              </Typography>
            </CardFooter> */}
          </Card>
        </div>

        <div className="relative row-span-2 lg:col-span-1 h-[120px] md:h-[220px] lg:h-[660px] lg:w-full lg:shadow lg:shadow-lg lg:shadow-left pt-0 md:pt-2 lg:pt-0">
          <div
            className=" hidden lg:flex  absolute top-2 left-0 right-0  items-center justify-center z-20 w-10 h-10 mx-auto cursor-pointer hover:scale-110 ease-in-out duration-300"
            onClick={scrollUp}
          >
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded-full opacity-50"></div>
            <FaChevronUp size={24} color="white" />
          </div>
          <div
            ref={scrollRef}
            className="overflow-y-hidden md:overflow-y-auto   smooth-scroll scrollbar-hide w-full h-[120px] md:h-[220px] lg:h-[660px] lg:w-full flex  lg:block"
          >
            {[
              ...image.slice(image.indexOf(viewCar) + 1),
              ...image.slice(0, image.indexOf(viewCar) + 1),
            ].map((car, idx) => {
              return (
                <motion.div
                  className="flex-none w-1/3 lg:w-full"
                  key={idx}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={variants}
                >
                  <Card
                    key={idx}
                    className={`hover:cursor-pointer h-[120px] md:h-[220px] w-full lg:h-[220px] lg:w-full`}
                    radius="none"
                    shadow="none"
                  >
                    <Image
                      removeWrapper
                      radius="none"
                      alt="Relaxing app background"
                      className="z-0 h-full w-full bg-black/90 object-cover"
                      src={`${env.BASE_API_URL}Listings/GetListingImage?fileName=${car}`}
                      onClick={() => handleVieCarFromRightCollection(car)}
                    />
                  </Card>
                </motion.div>
              );
            })}
          </div>
          <div
            className="hidden lg:flex absolute bottom-2 left-0 right-0  items-center justify-center z-20 w-10 h-10 mx-auto cursor-pointer hover:scale-110 ease-in-out duration-300"
            onClick={scrollDown}
          >
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded-full opacity-50"></div>
            <FaChevronDown size={24} color="white" />
          </div>
        </div>
      </div>
      <ModalViewCar
        isOpen={isOpen}
        onOpen={onOpenChange}
        cars={[
          ...image.slice(image.indexOf(viewCar)),
          ...image.slice(0, image.indexOf(viewCar)),
        ]}
      />
    </>
  );
};
