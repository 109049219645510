import React, { useState } from "react";
import { Button, Image } from "@nextui-org/react";
import { signal } from "@preact/signals-react";

import { UploadTypes } from "modules/dealer/features/Listings/enum/UploadTypes";
import { UploadPhotosBulk } from "./upload-photos-bulk.component";
import { TextErrorHelper, Toggle } from "components";
import { UploadPhotosSingle } from "./upload-photos-single.component";
import { useUploadCoverFiles } from "services";

interface UploadPhotosModalProps {
  onClose?: () => void;
  listingId?: number | undefined;
  aiOnState: boolean;
  setAiOnState: React.Dispatch<React.SetStateAction<boolean>>;
  images: File[];
  isItCreate: boolean;
  coverImg: File | null;
  updateListingAPICall:( hasPositionalImages:boolean,replaceImages:boolean)=>void

}

export const filesSignalLintingImg = signal<{ file: File; part: string }[]>([]);
// export const aiImgDetationON = signal<boolean>(false);
export const signalhasDoneBulkUpload = signal(false);

export const UploadPhotosModal: React.FC<UploadPhotosModalProps> = ({
  onClose,
  listingId,
  aiOnState,
  setAiOnState,
  images,
  isItCreate,
  coverImg,
  updateListingAPICall
}) => {
  // const [uploadType, setUploadType] = useState<UploadTypes>(UploadTypes.Bulk);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>(images);
  const [mainImg, setMainImg] = useState<File | null>(coverImg);
  const [isEdited, setIsEdited] = useState(false);

  const [aiCarDetails, setCarAiDetails] = useState<
    { file: File; part: string }[]
  >([]);

  const [canCreateList, setCanCreateList] = useState<boolean>(false);

  const { mutateAsync: uploadCoverFile, error: errorForUploadCoverImgMessage } =
    useUploadCoverFiles(String(listingId || 0));

  const handleCreateList = async () => {
    if (listingId && files.length >= 4) {
      setIsLoading(true);
      if (mainImg?.name !== coverImg?.name && !isEdited && !isItCreate) {
        await uploadCoverFile(mainImg?.name || "");
      }
      

      signalhasDoneBulkUpload.value = true;
      filesSignalLintingImg.value = moveFileToFirst(
        aiOnState
          ? aiCarDetails
          : files.map((file) => ({ file: file, part: "" })),
        mainImg
      );
      // aiImgDetationON.value = aiOnState
      // });
      if(isEdited && !isItCreate){
        await updateListingAPICall(aiOnState,true)
      }
      
      onClose && onClose();
      setFiles([]);
      setCarAiDetails([]);
      setCanCreateList(false);
      setIsLoading(false);
    }
  };
  // const handleSingleFileUpload = async () => {
  //   if (listingId && aiCarDetails.length >= 4) {
  //     setIsLoading(true);
  //     onClose && onClose();
  //     setCanCreateList(false);
  //     setIsLoading(false);
  //     signalhasDoneBulkUpload.value = true;
  //     filesSignalLintingImg.value = aiCarDetails;
  //     // setAiOnState(true)
  //     // aiImgDetationON.value = aiOnState
  //     // });
  //   }
  // };
  // const getActiveClassName = (type: UploadTypes.Bulk | UploadTypes.Single) => {
  //   return uploadType === type
  //     ? "bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg py-6 md:py-8 tracking-wide px-6 rounded-xl  w-full md:w-auto"
  //     : "  w-full md:w-auto text-blue-500 text-sm md:text-lg py-6  md:py-8 tracking-wide px-6 rounded-xl";
  // };
  return (
    <div className="w-full overflow-y-auto">
      {/* <div className="w-full flex items-center justify-between md:justify-center mb-8 gap-4">
        <div
          // onClick={() => setUploadType(UploadTypes.Bulk)}
          className={"bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg py-4 tracking-wide px-6 rounded-xl  w-full md:w-auto"}
          // type="button"
        >
          Bulk Upload
        </div>
        </div> */}
      {/* <div className="w-full flex items-center justify-between md:justify-center mb-8 gap-4">
        <Button
          onClick={() => setUploadType(UploadTypes.Bulk)}
          className={getActiveClassName(UploadTypes.Bulk)}
          type="button"
        >
          Bulk Upload
        </Button>

        <Button
          onClick={() => setUploadType(UploadTypes.Single)}
          className={getActiveClassName(UploadTypes.Single)}
          type="button"
        >
          Single Upload
        </Button>
      </div> */}
      {/* {uploadType === UploadTypes.Bulk ? ( */}
        <UploadPhotosBulk
          files={files}
          aiCarDetails={aiCarDetails}
          setCarAiDetails={setCarAiDetails}
          setFiles={setFiles}
          aiOnState={aiOnState}
          canCreateList={canCreateList}
          setCanCreateList={(canCreate: boolean) => setCanCreateList(canCreate)}
          listingId={listingId}
          mainImg={mainImg}
          setMainImg={setMainImg}
          setIsEdited={setIsEdited}
        />
      {/* ) : (
        <UploadPhotosSingle
          aiCarDetails={aiCarDetails}
          setIsEdited={setIsEdited}
          setCarAiDetails={setCarAiDetails}
        />
      )} */}
      {/* {uploadType === UploadTypes.Bulk && ( */}
        <div className="flex items-center justify-center gap-2 mt-2 md:mb-0 mb-2 ">
          <label
            htmlFor={"ai_on"}
            className="text-xs  block  font-bold ml-auto"
          >
            {"Utilize AI image detection (beta)"}
          </label>
          <Toggle
            size="sm"
            checked={aiOnState}
            // checked={aiImgDetationON.value}
            onChange={(e) => setAiOnState(e.target.checked)}
          />
        </div>
      {/* )} */}
      <div className="mt-2 md:mt-10 pb-10 md:pb-5 flex flex-col gap-2 items-center justify-center">
        {(
           files.length < 4 ||
          errorForUploadCoverImgMessage?.message) && (
          <div className="flex flex-row gap-2 mr-2">
            <Image src="/assets/icons/error-outline.svg" alt="error" />
            <TextErrorHelper>
              {errorForUploadCoverImgMessage?.message
                ? errorForUploadCoverImgMessage.message
                : "Minimum Four Images Required"}
            </TextErrorHelper>
          </div>
        )}
        <Button
          className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg py-6 md:py-8 tracking-wide px-10 rounded-xl"
          isLoading={isLoading}
          isDisabled={
            isItCreate
              ?  (aiOnState ? aiCarDetails.length < 4 : files.length < 4) ||
                  isLoading ||
                  !canCreateList
                
              : ((mainImg?.name === coverImg?.name && !isEdited) ||
                isLoading ||
                (aiOnState ? aiCarDetails.length < 4 : files.length < 4))
          }
          onClick={
             handleCreateList
          }
        >
          {isItCreate
            ? "Upload Images"
            : isEdited
            ? "Upload/Update"
            : mainImg?.name !== coverImg?.name
            ? "Update Cover"
            : "Upload/Update"}
        </Button>
      </div>
    </div>
  );
};

const moveFileToFirst = (
  filesArray: { file: File; part: string }[],
  fileToMove: File | null
) => {
  const fileIndex = filesArray.findIndex((item) => item.file === fileToMove);

  if (fileIndex === -1) return filesArray; // File not found in the array

  const updatedArray = [...filesArray];
  const [fileObject] = updatedArray.splice(fileIndex, 1); // Remove the file object from its current position
  updatedArray.unshift(fileObject); // Insert the file object at the beginning

  return updatedArray; // Update the state with the new array
};
