import { Progress } from "@nextui-org/react";
import Typography from "antd/es/typography/Typography";
import { formatWholeCurrency } from "modules/user/utils/formatter";
import React from "react";

interface SoldExpenseGraphProps {
  amount: number;
  label: string;
  percent: number;
  isMoney: boolean;
}

const SoldExpenseGraph = ({
  amount,
  label,
  percent,
  isMoney,
}: SoldExpenseGraphProps) => {
  return (
    <div className="grid grid-cols-5 items-center">
      <div>
        <Typography className="font-bold text-sm sm:text-lg md:text-sm lg:text-lg">
          {isMoney ? formatWholeCurrency(amount) : amount}
        </Typography>
        <Typography className="text-xs sm:text-sm md:text-xs lg:text-sm">
          {label}
        </Typography>
      </div>
      <Progress
        aria-label="Loading..."
        value={percent}
        radius="none"
        classNames={{
          base: "w-full col-span-4",
          track: "rounded-none min-h-10",
          indicator: "bg-gradient-to-l from-[#018FFD] to-[#070C84] ",
        }}
      />
    </div>
  );
};

export default SoldExpenseGraph;
