import { Axios, AxiosResponse, responseEncoding } from "axios";

export const shouldRetryOnError = (failureCount:number, error:any) => {
    // Do not retry if status code is 401
    if (error?.response?.status === 401) {
      return false;
    }
    // Retry up to 3 times for other errors
    return failureCount < 3;
  };

export const throwNotErrorSuccessResponse =  (res:AxiosResponse<any, any>,message : any) => {
  if(res.status !== 200){
    throw new Error( message)
  }
}