import React, { useState } from "react";
import { Button } from "@nextui-org/react";
import { GradientModal, Typography } from "components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "modules/dealer/store/store";
import { getUserId, isThisDealer } from "utils/get-user-id";
import { pendingSalesData } from "services/endpoints/pending-sales/pending-sales.type";
import patmentbg from "../assets/payment-bg.png";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentModal } from "pages/shared";
import { changeDataForPaymentInfo } from "modules/user/store/accept-offer-slice";
interface Props {
  // onCallback: () => void;
  // saleOffer: pendingSalesData | null;
}

export default function OfferAccepted({}: Props) {
  const [openModal, setOpenModal] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const navigateToSteps = (type: string) => {
    // if(isThisDealer()){
    navigate(
      `${
        isThisDealer() ? "/dealer/manage-inventory/" : "/user/garage/"
      }offer-accepted-steps/${params.id}/${params.listingId}/${type}`
    );
    // }
  };

  const handleShipNavigate = (to: string, requiredShipment: boolean) => {
    dispatch(
      changeDataForPaymentInfo({ shipmentRequire: requiredShipment, type: to })
    );
    navigateToSteps(to);
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center lg:-mt-12">
        <div className="text-center">
          <Typography
            variant="custom"
            className={` -mt-8 md:mt-10  font-bold text-[22px] md:text-3xl bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text `}
          >
            Offer Accepted!
          </Typography>
          <Typography
            variant="custom"
            className={`font-semibold text-xl  mt-5`}
          >
            How would you like to pay for your new car! 
          </Typography>
        </div>

        <section className="py-4 md:py-16 font-[Metropolis] w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div
              className="flex flex-col  border-[#070C84] border-2 rounded-[20px] items-center justify-center p-4 md:p-10"
              style={{
                backgroundImage: `url(${patmentbg})`,
                backgroundSize: "cover",
              }}
            >
              <Typography
                variant="custom"
                className=" md:text-2xl text-[#070C84]  font-bold  mb-4 md:mb-10"
              >
                Payment Options
              </Typography>
              <div className="w-full">
                <Button
                  onClick={() => setOpenModal("CashZelle")}
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                >
                  Cash/Zelle
                </Button>
                <Button
                  onClick={() => setOpenModal("CreditDebit")}
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                >
                  Credit or Debit
                </Button>
              </div>
            </div>
            <div className="">
              <div
                className="flex flex-col mb-10  border-[#070C84] border-2 rounded-[20px] items-center justify-center p-4 md:p-10"
                style={{
                  backgroundImage: `url(${patmentbg})`,
                  backgroundSize: "cover",
                }}
              >
                <Typography
                  variant="custom"
                  className=" md:text-2xl text-[#070C84]  font-bold  mb-2 md:mb-5"
                >
                  Escrow
                </Typography>
                <div className="w-full">
                  <Button
                    onClick={() => navigateToSteps("escrow")}
                    className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                  >
                    Escrow.com
                  </Button>
                </div>

                <Typography
                  variant="custom"
                  className={`font-semibold text-base md:text-xl  mt-5`}
                >
                  Vehicle Shipping Required
                </Typography>
              </div>
              <div
                className="flex flex-col  border-[#070C84] border-2 rounded-[20px] items-center justify-center p-4 md:p-10"
                style={{
                  backgroundImage: `url(${patmentbg})`,
                  backgroundSize: "cover",
                }}
              >
                <Typography
                  variant="custom"
                  className=" md:text-2xl text-[#070C84]  font-bold  mb-2 md:mb-6"
                >
                  Financing
                </Typography>
                <div className="w-full">
                  <Button
                    onClick={() => setOpenModal("Financing")}
                    className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                  >
                    Apply Online
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <GradientModal
        isOpen={openModal !== null}
        onOpenChange={() => setOpenModal(null)}
        size="2xl"
      >
        <div className="flex items-center justify-center my-auto w-full h-full p-4 md:p-10 pb-20">
          
        <Typography variant="custom" className="text-center  text-xl text-white  m-auto md:text-3xl ">Please send full amount to omar@findmyoptions.com on zelle</Typography>
        </div>
      </GradientModal> */}
      <PaymentModal
        isOpen={openModal !== null}
        onOpen={() => setOpenModal(null)}
        hideHeader={true}
        Component={
          openModal === "CashZelle" || openModal === "CreditDebit" ? (
            <div className="w-full pb-20 flex flex-col items-center">
              <Typography
                variant="custom"
                className=" text-xl font-[Metropolis] font-semibold text-center"
              >
                Will you be shipping this vehicle?
              </Typography>
              <div className="w-full max-w-52">
                <Button
                  onClick={() =>
                    handleShipNavigate(
                      openModal === "CashZelle" ? "cash-zelle" : "credit-debit",
                      true
                    )
                  }
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                >
                  Yes
                </Button>
                <Button
                  onClick={() =>
                    handleShipNavigate(
                      openModal === "CashZelle" ? "cash-zelle" : "credit-debit",
                      false
                    )
                  }
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                >
                  No
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full pb-20 flex flex-col items-center">
              <div className="w-full px-4 md:px-16">
                <Button
                  onClick={() => handleShipNavigate("financing", true)}
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                >
                  Continue with Shipping
                </Button>
                <Button
                  onClick={() => handleShipNavigate("financing", false)}
                  className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-white text-sm md:text-lg w-full py-8 tracking-wide px-6 rounded-3xl mt-5"
                >
                  Continue with Local pickup
                </Button>
              </div>
            </div>
            // <ThankYou textNote="Payment Successful" onClick={() => setShowPayModal(3)} />
          )
        }
      />
    </>
  );
}
