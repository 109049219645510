import { useMutation, useQuery } from "@tanstack/react-query";
import { pendingSalesEndpoint } from "services/endpoints/pending-sales/pending-sales.endpoint";
import { EscrowTransaction, pendingSalesData } from "services/endpoints/pending-sales/pending-sales.type";

  


  export const useGetPendingSalesData = (page?: number, updateState?:(newData:pendingSalesData[])=>void) => {
    return useQuery<pendingSalesData[], Error>({
      queryKey: ["pending-sales-data"],
      queryFn: async () => {
        const res = await pendingSalesEndpoint.getPendingSalesData(page);
        updateState && updateState(res)
        return res;
      },
      enabled: !!page,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchInterval: false,
    });
  };

  export const useGetInitiateEscrowTransaction = (listId:number) => {
    return useQuery<EscrowTransaction, Error>({
      queryKey: ["pending-sales-data"],
      queryFn: async () => {
        const res = await pendingSalesEndpoint.getInitiateEscrowTransaction(listId);
        return res;
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
    });
  };