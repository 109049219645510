import { Typography } from "components";
import React from "react";
import checkIcon from "../careers/assets/svg/check-icon.svg";
export default function AffiliatesPage() {
  return (
    <div className="flex items-center justify-center">
      <div className="md:p-4 mt-10">
        <Typography variant="custom" className="text-center">
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-3xl 2xl:text-5xl font-bold mb-10">
            Affiliate Program
          </p>
          <p className="bg-gradient-to-r from-[#018ffd] to-[#070c84] text-transparent bg-clip-text text-2xl font-bold mb-5">
            Earn by Sharing, Build Your Income.
          </p>
          <p className=" text-xl text-start ">
            Join our affiliate program and monetize your traffic by referring
            buyers to our automotive marketplace. Earn attractive commissions
            generated through your unique coupon codes.
          </p>
        </Typography>
        <Typography variant="custom" className="mt-10 text-wrap">
          <p className="bg-gradient-to-r from-blue-500 to-indigo-900 text-transparent bg-clip-text text-2xl font-bold pt-5 ">
            Why Join Our Affiliate Program:
          </p>

          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                {" "}
                High Conversion Rates:{" "}
              </span>
              <span className="font-semibold">
                 Benefit from our user-friendly platform with a smooth buying
                process and high conversion rates.
              </span>
            </p>
          </div>

          <div className="flex items-start mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Competitive Commissions:{" "}
              </span>
              <span className="font-semibold">
                Earn generous commissions on every completed transaction you
                refer.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Flexible Tools & Resources:{" "}
              </span>
              <span className="font-semibold">
                Utilize a variety of marketing materials and reporting tools to
                optimize your campaigns.
              </span>
            </p>
          </div>
          <div className="flex items-start  mt-5 ">
            <img src={checkIcon} height={16} width={16} className="mt-[16px]" />
            <p className={`px-3 pt-3 text-black font-light text-xl`}>
              <span className="font-bold text-black bg-clip-text">
                Dedicated Support:{" "}
              </span>
              <span className="font-semibold">
                 Our team is here to help you achieve success with the program.
              </span>
            </p>
          </div>
        </Typography>
        <Typography variant="custom" className="mt-5 text-wrap">
          <p className="text-xl font-bold pt-5 ">Ready to Start Earning?</p>
          <p className="text-xl  ">
            Sign up for our free affiliate program today at 
            <span
              className="underline cursor-pointer"
              onClick={() => {
                window.location.href = "mailto:support@findmyoptions.com";
              }}
            >
              support@findmyoptions.com
            </span>
            and start promoting our platform to your audience.
          </p>
        </Typography>
      </div>
    </div>
  );
}
