import React, {
  useState,
  useRef
} from "react";
import axios from "axios";
import { MdOutlineMail } from "react-icons/md";
import Input from "components/ui/input";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Button } from "@nextui-org/react";
import { env } from "config/env";
interface ForgotProps {}

const ForgotPassword: React.FC<ForgotProps> = ({
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const ticketContentRef = useRef<HTMLDivElement>(null);


  const handleSubmit = async () => {
    // e.preventDefault();
    if (email.trim() === "") {
      setMessage("Field is empty");
      return;
    }
    setLoading(p=>!p);
    try {
      const response = await axios.post(
        `${env.BASE_API_URL}User/send-reset-email/${email}`,
        {
          email: email,
        }
      );
      console.log("Email sent:", email);
      console.log(response.status, response.data);
      setIsSent(true);
      setLoading(p=>!p);
      setMessage('')
    } catch (error) {
      setLoading(p=>!p);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          setMessage(`Error: ${error.response.data.message}`);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }

        setMessage("Failed to send email. Please try again.");
      }
    }
  };

  return (
    <>
      {!isOpen && (
        <div className=" font-[Metropolis-Bold]">
          <div
            ref={ticketContentRef}
            className="text-center  max-[540px]:w-full min-[768px]:w-full max-[768px]:w-full  max-[540px]:p-4 min-[768px]:p-2 max-[768px]:p-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          >
            {isSent ? (
              <div className="flex items-center justify-center text-white text-[150px] -mt-4 max-[768px]:text-[120px]">
                <IoIosCheckmarkCircleOutline />
              </div>
            ) : (
              <div>
                <h1 className="text-white text-md lg:text-2xl">
                  Forgot Your Password?
                </h1>
                <div className=" text-white text-xs lg:text-sm mt-2 mb-5">
                Please provide your email or phone number and we will send you a password reset link.
                </div>
              </div>
            )}
            <div className="flex flex-col items-center justify-center">
              {isSent ? (
                <div className="flex flex-col items-center justify-center mt-8 font-[Metropolis-Bold]">
                  <h1 className="text-[22px] text-white mt-[-20px] ">
                    Reset Password Email Sent
                  </h1>
                  <h1 className="text-[15px] text-white mt-[4px] w-3/4 leading-[17px]">
                    You should soon receive an email allowing you to reset your
                    password. Please make sure to check your spam and trash if
                    you can't find the email.
                  </h1>
                </div>
              ) : (
                <div className="flex items-center justify-center p-2 md:w-1/2">
                  <Input
                    variant="form"
                    placeholder="Enter Email or Phone Number"
                    startContent={
                      <span className="relative top-0 max-[768px]:top-0  flex items-center justify-center text-gray-900 min-[768px]:text-2xl max-[768px]:text-xl">
                        <MdOutlineMail />
                      </span>
                    }
                    type="email"
                    name="email"
                    value={email}
                    classNames={{
                      input: "data-[has-start-content=true]:ps-2",
                      inputWrapper: "",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              )}
              {message && (
                <div className="text-red-600 font-semibold">{message}</div>
              )}
              {!isSent && (
                <Button
                  className="text-blue-500 max-[540px]:w-20 max-[540px]:h-8 min-[768px]:w-28 min-[768px]:h-12 max-[768px]:w-20 max-[768px]:h-8 m-auto min-[768px]:mt-4 max-[768px]:mt-4 bg-white rounded-xl max-[768px]:rounded-md cursor-pointer"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  Send
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
